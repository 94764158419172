import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Logo from "../../assets/icons/logoOlin.png";
import NotFound from "../../assets/images/not_found.png";
import Success from "../../assets/images/success-img.jpg";

const PaymentStatus = () => {
  const { status } = useParams();
  const { detail } = useParams();
  const navigate = useNavigate();

  const total = detail ? Number(atob(detail.split("-")[0])) : "";
  const date = detail ? atob(detail.split("-")[1]) : "";

  return (
    <div className="h-screen overflow-hidden">
      <div className="shadow-md py-6">
        <div className="w-[310px] flex items-center">
          <img
            src={Logo}
            alt="logo"
            className="ml-16 h-[7%] w-[25%] flex items-center"
          />
        </div>
      </div>
      <div className="mx-auto text-center my-auto h-full">
        <div className="flex justify-center my-4 mx-auto w-[300px]">
          <img
            src={status === "success" ? Success : NotFound}
            alt={status === "success" ? "Success_img" : "NotFound_img"}
            className="object-contain h-1/4"
          />
        </div>
        {status === "success" ? (
          <p className="font-bold my-3">
            Terima Kasih! <br /> Pembayaran Berhasil
          </p>
        ) : (
          <div className="my-3">
            <p className="font-bold text-xl">Menunggu Pembayaran</p>{" "}
            <p className="font-bold">
              Mohon lakukan pembayaran sebesar
              {total.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}{" "}
              sebelum tanggal {moment(date).format("DD MMMM YYYY")}
            </p>
          </div>
        )}

        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 hover-shadow-md"
          onClick={() => {
            status === "success" ? navigate("/billing/unpaid") : navigate(-1);
          }}
        >
          {status === "success"
            ? "Kembali ke Halaman Billing"
            : "Selesaikan Pembayaran"}
        </button>
      </div>
    </div>
  );
};

export default PaymentStatus;
