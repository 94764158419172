import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FaChevronLeft, FaUser } from "react-icons/fa";
import { getPatientCareDoctor } from "../../../services/patientCare";
import { useEffect } from "react";

const PopoverDoctorList = ({
  anchorElDoctor,
  setAnchorElDoctor,
  searchDoctor,
  setSearchDoctor,
  doctorArr,
  setDoctorArr,
  debounceDoctor,
}) => {
  const {
    mutate: mutateListDoctor,
    data: dataListDoctor,
    isLoading: isLoadingDoctor,
  } = useMutation({
    mutationFn: getPatientCareDoctor,
  });

  const handleCloseDoctor = () => {
    setAnchorElDoctor(null);
    setSearchDoctor("");
  };

  useEffect(() => {
    mutateListDoctor({
      search: debounceDoctor,
      limit: 10,
      offset: 1,
      specialization_id: 0,
    });
  }, [debounceDoctor]);

  return (
    <Popover
      open={Boolean(anchorElDoctor)}
      anchorEl={anchorElDoctor}
      onClose={handleCloseDoctor}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ width: "100%", minWidth: "50vw" }}
    >
      <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
        <div className="sticky top-0 bg-white">
          <div className="px-8 py-3">
            <div className="grid grid-cols-3 items-center mb-2 gap-4">
              <FaChevronLeft
                className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                onClick={handleCloseDoctor}
              />
              <p className="text-center font-bold text-lg">List Dokter</p>
            </div>
            <TextField
              fullWidth
              sx={{ marginBottom: 1 }}
              placeholder="Find Doctor"
              value={searchDoctor}
              onChange={(e) => setSearchDoctor(e.target.value)}
              inputProps={{ style: { padding: 8 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <hr />
        </div>
        {dataListDoctor &&
        !isLoadingDoctor &&
        dataListDoctor.doctor &&
        dataListDoctor.count > 0 ? (
          dataListDoctor.doctor.map((item) => (
            <div
              className={`px-8 py-2 border-b-2 last:border:none flex gap-4 cursor-pointer hover:bg-gray-100 ${
                doctorArr.map((item) => item.id).includes(item.id)
                  ? "bg-gray-200"
                  : ""
              }`}
              onClick={() => {
                const index = doctorArr.findIndex((doc) => doc.id === item.id);
                setDoctorArr(
                  index < 0
                    ? // ? [...doctorArr, item]
                      // : [
                      //     ...doctorArr.slice(0, index),
                      //     ...doctorArr.slice(index + 1),
                      //   ]
                      [item]
                    : []
                );
              }}
            >
              <div
                className={`rounded-md w-[48px] h-[48px] bg-gray-200 ${
                  item.image === "" ? "p-2" : ""
                }`}
              >
                {item.image === "" ? (
                  <FaUser className="w-full h-full text-white" />
                ) : (
                  <img
                    src={item.image}
                    className="w-full h-full rounded-md object-cover"
                  />
                )}
              </div>
              <div className="w-full">
                <p className="font-bold mb-2">{item.name}</p>
                <div className="flex gap-2">
                  <p className="w-1/3">Email</p>
                  <p>:</p>
                  <p>{item.email === "" ? "-" : item.email}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Phone Number</p>
                  <p>:</p>
                  <p>{item.phone === "" ? "-" : item.phone}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">No SIP</p>
                  <p>:</p>
                  <p>{item.sip === "" ? "-" : item.sip}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">No KTP</p>
                  <p>:</p>
                  <p>{item.ktp === "" ? "-" : item.ktp}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Specialization</p>
                  <p>:</p>
                  <p>
                    {item.specialization === "" ? "-" : item.specialization}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Address</p>
                  <p>:</p>
                  <p>{item.address === "" ? "-" : item.address}</p>
                </div>
              </div>
            </div>
          ))
        ) : isLoadingDoctor ? (
          <div className="flex justify-center py-2">
            <CircularProgress size={20} />
          </div>
        ) : (
          <div className="flex justify-center py-2 text-gray-300">
            <p>No Doctors Found</p>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default PopoverDoctorList;
