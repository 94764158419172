import { Backdrop, CircularProgress } from "@mui/material";

const LoadingBackdrop = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: "100vh",
      }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default LoadingBackdrop;
