import { useState, createContext } from "react";

export const VoucherProductContext = createContext();

const VoucherProductProvider = ({ children }) => {
  const [selectedVoucher, setSelectedVoucher] = useState({
    voucherId: [],
    voucherPayment: [],
    voucherPrice: [],
  });
  return (
    <VoucherProductContext.Provider
      value={{ selectedVoucher, setSelectedVoucher }}
    >
      {children}
    </VoucherProductContext.Provider>
  );
};

export default VoucherProductProvider;
