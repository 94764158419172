import React from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";

import { updateCustomer } from "../../../services/customerApi";

const validationSchema = yup.object({
  id: yup.number().required("ID di perlukan"),
  name: yup.string().required("Nama wajib diisi"),
  phone: yup.string().required("No Telpon wajib diisi"),
  email: yup.string().email().required("file wajib diisi"),
  address: yup.string().required("Alamat wajib diisi"),
});

export default function ModalChangeCustomer(props) {
  const { showModal, setShowModal, header, updateValue } = props;

  const queryClient = useQueryClient();

  const { mutate: mutateUpdateCustomer } = useMutation({
    mutationFn: updateCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"]);
      setShowModal(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      id: updateValue.id,
      name: updateValue.name,
      phone: updateValue.phone,
      email: updateValue.email,
      address: updateValue.address,
    },
    validationSchema,
    onSubmit: (values) => {
      mutateUpdateCustomer(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      {showModal ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="text-center p-3 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl text-center font-semibold">
                    {header}
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="grid grid-cols-1">
                    {/* <TextField id="outlined-basic" label={label} variant="outlined" className="mt-3" value={value} key={key} disabled /> */}
                    <TextField
                      id="outlined-basic"
                      label="name"
                      variant="outlined"
                      className="mt-3"
                      onChange={(e) =>
                        formik.setFieldValue("name", e.target.value)
                      }
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      id="outlined-basic"
                      label="email"
                      variant="outlined"
                      className="mt-3"
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      id="outlined-basic"
                      label="phone"
                      variant="outlined"
                      className="mt-3"
                      onChange={(e) =>
                        formik.setFieldValue("phone", e.target.value)
                      }
                      value={formik.values.phone}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                      id="outlined-basic"
                      label="address"
                      variant="outlined"
                      className="mt-3"
                      onChange={(e) =>
                        formik.setFieldValue("address", e.target.value)
                      }
                      value={formik.values.address}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </div>
                </div>
                {/*footer*/}

                {/* <p className="text-center font-medium text-[30px] mb-3">
                  Apakah anda yakin ingin <br /> produk ini?
                </p> */}

                <div className="flex items-center justify-center p-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </form>
      ) : null}
    </>
  );
}
