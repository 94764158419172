import React, { useState, useCallback, useEffect } from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  FormHelperText,
  Snackbar,
  IconButton,
} from "@mui/material";
import {
  CalendarMonth as CalendarMonthIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import cloneDeep from "lodash/cloneDeep";
import { useMutation, useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import { createDiscount } from "../../../services/DiscountApi";
import { getBank } from "../../../services/paymentApi";
import { getProductPos } from "../../../services/InventoryAPI";
import { useNavigate } from "react-router-dom";
import { week } from "../../constants/constant";
import useDebounce from "../../hooks/useDebounce";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 1;

const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const toApiTime = (date) => moment(date).format("HH:mm");

const AddDiscount = () => {
  const navigate = useNavigate();

  const [minimumPurchase, setMinimumPurchase] = useState(false);
  const validationSchema = yup.object({
    promo_name: yup.string().required("nama promo wajib diisi"),
    discount_method: yup
      .number()
      .required("metode promo wajib diisi")
      .test("notZero", "nilai tidak bisa 0", (value) => value !== 0),
    discount_type: yup
      .number()
      .required("silahkan pilih tipe diskon")
      .test("notZero", "nilai tidak bisa 0", (value) => value !== 0),
    discount_value: yup
      .mixed()
      .test("notZero", "nilai tidak bisa 0", (value) => value !== 0)
      .when("discount_type", {
        is: (value) => value === 1 || value === 2,
        then: yup
          .mixed()
          .required("nilai promo wajib diisi")
          .test("notZero", "nilai tidak bisa 0", (value) => value !== 0),
        otherwise: yup.number(),
      }),
    minimum_purchase: yup.number().when("minimumPurchase", {
      is: (value) => value === true,
      then: yup.number().required("minimal pembelian wajib dipilih dan diisi"),
    }),
    customer_type: yup
      .number()
      .test("notZero", "nilai tidak bisa 0", (value) => value !== 0)
      .when("discount_method", {
        is: (value) => value === 1,
        then: yup.number().required("tipe pelanggan wajib diisi"),
      }),
    product_id: yup.array().when("discount_method", {
      is: (value) => value === 2,
      then: yup
        .array()
        .required("Wajib memilih produk")
        .min(1, "minimal memilih 1 produk"),
    }),
    payment_id: yup.array().when("discount_method", {
      is: (value) => value === 3,
      then: yup
        .array()
        .required("Wajib memilih tipe pembayaran")
        .min(1, "minimal memilih 1 tipe pembayaran"),
    }),
    start_date: yup
      .string()
      .required("Tanggal mulai/berakhir promo wajib diisi"),
    end_date: yup.string().required("Tanggal mulai/berakhir promo wajib diisi"),
    start_time: yup.string(),
    end_time: yup.string(),
    discount_day: yup
      .array()
      .required("hari promo wajib diisi")
      .min(1, "minimal memilih 1 hari promo"),
    type_usage: yup
      .number()
      .required("tipe pemakai wajib diisi")
      .test("notZero", "tipe pemakai wajib diisi", (value) => value !== 0),
    usage_limit: yup
      .number()
      .test("notZero", "nilai tidak bisa 0", (value) => value !== 0)
      .when("type_usage", {
        is: (value) => value === 2,
        then: yup
          .number()
          .required()
          .test("notZero", "nilai tidak bisa 0", (value) => value !== 0),
        otherwise: yup.number(),
      }),
  });

  const [isEverydaySelected, setIsEverydaySelected] = useState(false);
  const [promoTime, setPromoTime] = useState(true);
  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState([]);
  const [productValue, setProductValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [isDateChosen, setIsDateChosen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");

  const debounce = useDebounce(search, 500);

  const { data: bank } = useQuery({
    queryKey: ["bank"],
    queryFn: () => getBank(),
    keepPreviousData: true,
  });

  const { data: products, isLoading: isProductLoading } = useQuery({
    queryKey: ["product-pos", debounce, open],
    queryFn: () => getProductPos(FETCH_LIMIT, 1, 1, debounce),
    enabled: open,
  });

  const { mutate: mutateCreateDiscount } = useMutation({
    mutationFn: createDiscount,
    onSuccess: () => {
      navigate("/discount");
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const formik = useFormik({
    initialValues: {
      promo_name: "",
      discount_method: 0,
      discount_type: 0,
      discount_value: null,
      minimum_purchase: 0,
      customer_type: 1,
      usage_limit: 0,
      type_usage: 0,
      start_date: "",
      end_date: "",
      discount_day: [],
      product_id: [],
      payment_id: [],
      start_time: "",
      end_time: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutateCreateDiscount({
        ...values,
        discount_value:
          values.discount_type === 1
            ? parseFloat(values.discount_value.replace(",", "."))
            : values.discount_value,
      });
    },
  });

  const handleSelectDay = useCallback(
    (selectedDay) => {
      const selectedValue = formik.values.discount_day.findIndex(
        (value) => value === selectedDay,
      );
      let updatedDay = cloneDeep(formik.values.discount_day);

      if (selectedValue !== -1) {
        updatedDay.splice(selectedDay, 1);
      } else {
        updatedDay.push(selectedDay);
      }
      formik.setFieldValue("discount_day", updatedDay);
    },
    [formik.values.discount_day],
  );

  const handleDate = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      formik.setValues({
        ...formik.values,
        start_date: toApiDate(start),
        end_date: toApiDate(end),
      });
    },
    [formik],
  );

  useEffect(() => {
    if (isEverydaySelected)
      formik.setFieldValue("discount_day", [0, 1, 2, 3, 4, 5]);
    else formik.setFieldValue("discount_day", []);
  }, [isEverydaySelected]);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Add New Promo
      </h1>
      <h1 className="font-semibold mt-10">
        Pelanggan akan menggunakan kode promo ini pada saat checkout
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-4 grid grid-cols-1 gap-4">
          <TextField
            id="outlined-basic"
            label="Nama Promo"
            variant="outlined"
            value={formik.values.promo_name}
            fullWidth
            error={
              formik.touched.promo_name && Boolean(formik.errors.promo_name)
            }
            helperText={formik.touched.promo_name && formik.errors.promo_name}
            onChange={(e) => {
              formik.setFieldValue("promo_name", e.target.value);
            }}
          />

          <div>
            <h3 className="font-semibold text-[20px]">Discount</h3>
            <hr className="border-black mt-2" />
            <div className="flex flex-wrap justify-between mt-2">
              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "discount_method",
                        parseInt(e.target.value, 10),
                      );
                    }}
                    checked={formik.values.discount_method === 1}
                  />
                }
                label="Promo Global"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.discount_method === 2}
                    value={2}
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        discount_method: parseInt(e.target.value, 10),
                        customer_type: 2,
                      });
                    }}
                  />
                }
                label="Promo Produk"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.discount_method === 3}
                    value={3}
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        discount_method: parseInt(e.target.value, 10),
                        customer_type: 2,
                      });
                    }}
                  />
                }
                label="Promo Metode Pembayaran"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
            </div>
            {formik.touched.discount_method &&
            Boolean(formik.errors.discount_method) ? (
              <FormHelperText sx={{ textAlign: "center", color: "#D32F2F" }}>
                Promo metode wajib dipilih
              </FormHelperText>
            ) : null}
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 w-full">
            <div className="">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Tipe Diskon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Discount Type"
                  value={formik.values.discount_type}
                  error={
                    formik.touched.discount_type &&
                    Boolean(formik.errors.discount_type)
                  }
                  onChange={(e) => {
                    // formik.setFieldValue(
                    //   "discount_type",
                    //   parseInt(e.target.value, 10),
                    // );
                    formik.setValues((prevValue) => ({
                      ...prevValue,
                      discount_type: parseInt(e.target.value, 10),
                      discount_value: null,
                    }));
                  }}
                >
                  <MenuItem
                    value={0}
                    sx={{ display: "none" }}
                    disabled
                  ></MenuItem>
                  <MenuItem value={1}>Percent (%)</MenuItem>
                  <MenuItem value={2}>Fixed (Rp)</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.discount_type &&
              Boolean(formik.errors.discount_type) ? (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  Tipe Diskon wajib diisi
                </FormHelperText>
              ) : null}
            </div>
            {formik.values.discount_type !== 0 ? (
              <div className="flex">
                <TextField
                  fullWidth
                  label={
                    formik.values.discount_type === 1 &&
                    formik.values.discount_type !== 0
                      ? "Discount Percent"
                      : "Discount Rupiah"
                  }
                  id="outlined-start-adornment"
                  value={formik.values.discount_value}
                  error={
                    formik.touched.discount_value &&
                    Boolean(formik.errors.discount_value)
                  }
                  helperText={
                    formik.touched.discount_value &&
                    formik.errors.discount_value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {formik.values.discount_type === 1 &&
                        formik.values.discount_type !== 0
                          ? "%"
                          : "Rp."}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (
                      formik.values.discount_type === 1 &&
                      formik.values.discount_type !== 0
                    ) {
                      const value = e.target.value;

                      if (/^[\d,]*$/.test(value)) {
                        const parts = value.split(",");
                        if (
                          parts.length <= 2 &&
                          (parts[1] === undefined || parts[1].length <= 2)
                        ) {
                          const numericValue = parseFloat(
                            value.replace(",", "."),
                          );

                          if (numericValue <= 100) {
                            formik.setFieldValue("discount_value", value);
                          }
                        }
                      }
                    } else {
                      const numericValue = e.target.value;
                      if (numericValue === "") {
                        return formik.setFieldValue("discount_value", 0);
                      }
                      formik.setFieldValue(
                        "discount_value",
                        parseInt(numericValue.replace(/[^0-9]/g, 0), 10),
                      );
                    }
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="flex flex-wrap">
            <FormControlLabel
              control={
                <Checkbox
                  value={minimumPurchase}
                  onChange={(e) => {
                    setMinimumPurchase(e.target.checked);
                    formik.setFieldValue("minimum_purchase", 0);
                  }}
                />
              }
              label="Minimum Pembelian"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            {minimumPurchase === true ? (
              <div className="flex items-center">
                <TextField
                  label="Minimum Pembelian"
                  id="outlined-start-adornment"
                  value={formik.values.minimum_purchase}
                  error={
                    formik.touched.minimum_purchase &&
                    Boolean(formik.errors.minimum_purchase)
                  }
                  onChange={(e) => {
                    const numericValue = e.target.value;
                    if (numericValue === "") {
                      return formik.setFieldValue("minimum_purchase", 0);
                    }
                    formik.setFieldValue(
                      "minimum_purchase",
                      parseInt(numericValue.replace(/[^0-9]/g, 0), 10),
                    );
                  }}
                />
                <div className="flex bg-blue-500 items-center rounded-r-lg">
                  <p className="p-3 text-white font-bold">Rp</p>
                </div>
              </div>
            ) : null}
          </div>
          {formik.touched.minimum_purchase &&
          Boolean(formik.errors.minimum_purchase) ? (
            <FormHelperText sx={{ color: "#D32F2F" }}>
              {formik.errors.minimum_purchase}
            </FormHelperText>
          ) : null}

          {formik.values.discount_method === 1 ? (
            <>
              <h3 className="font-semibold text-[20px]">
                Customer & Settings *
              </h3>
              <hr className="border-black" />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Customer Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Customer Type"
                  value={formik.values.customer_type}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "customer_type",
                      parseInt(e.target.value, 10),
                    );
                  }}
                >
                  <MenuItem
                    value={0}
                    sx={{ display: "none" }}
                    disabled
                  ></MenuItem>
                  <MenuItem value={1}>Member</MenuItem>
                  <MenuItem value={2}>Everyone</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : formik.values.discount_method === 2 ? (
            <>
              <h3 className="font-semibold text-[20px]">Product *</h3>
              <hr className="border-black" />
              <Autocomplete
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                multiple
                filterSelectedOptions
                loading={isProductLoading}
                id="tags-outlined"
                value={productValue}
                options={
                  products && products !== undefined
                    ? products.map((data) => {
                        return {
                          id: data.product_detail_id,
                          name: data.product_name,
                        };
                      })
                    : []
                }
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearch(onInputValue);
                  }
                }}
                onChange={(_, value) => {
                  setProductValue(
                    value.map((v) => {
                      return {
                        id: v.id,
                        name: v.name,
                      };
                    }),
                  );
                  formik.setFieldValue(
                    "product_id",
                    value.map((v) => v.id),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pilih Produk"
                    placeholder="Pilih Produk"
                  />
                )}
              />
            </>
          ) : formik.values.discount_method === 3 ? (
            <>
              <h3 className="font-semibold text-[20px]">Payment Method *</h3>
              <hr className="border-black" />
              <Autocomplete
                multiple
                filterSelectedOptions
                id="tags-outlined"
                value={value}
                options={
                  bank && bank !== undefined
                    ? bank.map((b) => {
                        return {
                          payment_id: b.id,
                          name: b.name,
                          type: b.type_id,
                        };
                      })
                    : []
                }
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={(_, value) => {
                  setValue(
                    value.map((v) => {
                      return {
                        payment_id: v.payment_id,
                        type: v.type,
                        name: v.name,
                      };
                    }),
                  );
                  formik.setFieldValue(
                    "payment_id",
                    value.map((v) => v.payment_id),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Metode Pembayaran"
                    placeholder="Pilih Bank"
                  />
                )}
              />
            </>
          ) : null}
          <div className="mt-3">
            <h3 className="font-semibold text-[20px]">Usage Limit *</h3>
            <hr className="border-black mt-2" />
            <div className="flex flex-wrap mt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    value={1}
                    checked={formik.values.type_usage === 1}
                    onChange={() =>
                      formik.setValues({
                        ...formik.values,
                        type_usage: 1,
                        usage_limit: -1,
                      })
                    }
                  />
                }
                label="Unlimited"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.type_usage === 2}
                    value={2}
                    onChange={() =>
                      formik.setValues({
                        ...formik.values,
                        type_usage: 2,
                        usage_limit: 0,
                      })
                    }
                  />
                }
                label="Limited"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
              />
              {formik.values.type_usage === 2 ? (
                <>
                  <TextField
                    label="Limit"
                    id="outlined-start-adornment"
                    value={formik.values.usage_limit}
                    error={
                      formik.touched.usage_limit &&
                      Boolean(formik.errors.usage_limit)
                    }
                    helperText={
                      formik.touched.usage_limit && formik.errors.usage_limit
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          Transaksi
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const numericValue = e.target.value;
                      if (numericValue === "") {
                        return formik.setFieldValue("usage_limit", 0);
                      }
                      formik.setFieldValue(
                        "usage_limit",
                        parseInt(numericValue.replace(/[^0-9]/g, 0), 10),
                      );
                    }}
                  />
                </>
              ) : null}
            </div>
            {formik.touched.type_usage && Boolean(formik.errors.type_usage) ? (
              <FormHelperText sx={{ color: "#D32F2F" }}>
                {formik.errors.type_usage}
              </FormHelperText>
            ) : null}
          </div>
          <div>
            <h3 className="font-semibold text-[20px]">Time *</h3>
            <hr className="border-black my-3" />
            <p className="font-medium">Tanggal Mulai - Tanggal Berakhir</p>
            <TextField
              onClick={() => setIsDateChosen(true)}
              value={
                startDate !== null && endDate !== null
                  ? formik.values.start_date + " - " + formik.values.end_date
                  : ""
              }
              error={
                (formik.touched.start_date &&
                  Boolean(formik.errors.start_date)) ||
                (formik.touched.end_date && Boolean(formik.errors.end_date))
              }
              helperText={
                (formik.touched.start_date && formik.errors.start_date) ||
                (formik.touched.end_date && formik.errors.end_date)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex flex-wrap  gap-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEverydaySelected}
                  onChange={(e) => setIsEverydaySelected(e.target.checked)}
                />
              }
              label="Setiap Hari"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            {isEverydaySelected === true ? null : (
              <>
                {week.map((w, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={() => {
                          handleSelectDay(w.value);
                        }}
                        value={w.value}
                      />
                    }
                    label={w.day}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
                  />
                ))}
              </>
            )}
          </div>
          {formik.touched.discount_day &&
          Boolean(formik.errors.discount_day) ? (
            <FormHelperText sx={{ textAlign: "center", color: "#D32F2F" }}>
              Hari Promo berlaku wajib diisi
            </FormHelperText>
          ) : null}
          <hr className="border-black" />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  value={1}
                  checked={promoTime}
                  onChange={(e) => {
                    setPromoTime(e.target.checked);
                  }}
                />
              }
              label="Set Promo Time (24 Hours Format)"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            />
            {promoTime === true ? null : (
              <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
                <div>
                  <p className="font-medium">Start Time</p>
                  <DatePicker
                    className="rounded-md p-2 border-2 border-black"
                    selected={timeStart}
                    onChange={(time) => {
                      if (promoTime) {
                        return formik.setFieldValue("start_time", "");
                      }
                      setTimeStart(time);
                      formik.setFieldValue("start_time", toApiTime(time));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm"
                  />
                </div>
                <div>
                  <p className="font-medium">End Time</p>
                  <DatePicker
                    className="rounded-md p-2 border-2 border-black"
                    selected={timeEnd}
                    onChange={(time) => {
                      if (promoTime) {
                        return formik.setFieldValue("end_time", "");
                      }
                      setTimeEnd(time);
                      formik.setFieldValue("end_time", toApiTime(time));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 mt-20 gap-12">
          <button
            className="bg-white text-black border shadow-md font-semibold p-3 rounded-lg"
            type="button"
          >
            Batal
          </button>
          <button
            className="bg-[#007AF1] text-white shadow-md font-semibold p-3 rounded-lg"
            type="submit"
          >
            Konfirmasi
          </button>
        </div>
      </form>
      <Dialog
        open={isDateChosen}
        onClose={() => {
          setIsDateChosen(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              className="border-2 border rounded-md p-2"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDate}
              inline
            />
          </div>
          {startDate !== null && endDate !== null ? (
            <button
              className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
              onClick={() => {
                setIsDateChosen(false);
              }}
            >
              ok
            </button>
          ) : null}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default AddDiscount;
