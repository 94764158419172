import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { AiFillInstagram } from "react-icons/ai";

import { getInvoice } from "../../../../services/ReportingApi";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const DetailInvoiceReportSales = () => {
  const { data: invoiceData } = useQuery({
    queryFn: () => getInvoice("TRX-02277152023"),
    queryKey: ["invoice"],
  });

  return (
    <div className="w-full h-full flex justify-center items-center">
      {invoiceData && invoiceData !== undefined ? (
        <div className="w-[60%] h-[90%] border-2 border-black flex justify-center">
          {invoiceData.detail_invoice !== null ? (
            <div className="flex items-center w-full h-full flex-col">
              <img
                src={invoiceData.detail_invoice.logo}
                className="w-[20%] h-[20%]"
              />
              <p className="mt-1 text-[20px] font-semibold mb-3">
                {invoiceData.detail_invoice.name_outlet}
              </p>
              <div className="flex items-start flex-col">
                <div className="flex flex-row">
                  <span className="w-[40px] pr-[0] mr-[0] font-bold">SIA </span>
                  : {invoiceData.detail_invoice.sia}
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[80px] pr-[0] mr-[0] font-bold">
                    Apoteker{" "}
                  </span>
                  : {invoiceData.detail_invoice.nama_apoteker}
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[40px] pr-[0] mr-[0] font-bold">SIPA</span>
                  : {invoiceData.detail_invoice.sipa}
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[80px] pr-[0] mr-[0] font-bold">
                    Alamat
                  </span>
                  : {invoiceData.detail_invoice.address}
                  <br />
                </div>
                <div className="w-full">
                  <hr className="border-2 border-solid border-black" />
                </div>
                <div className="w-full">
                  <div className="flex justify-between">
                    <p>Nomor Order</p>
                    <p>test</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Tanggal Order</p>
                    <p>
                      {invoiceData.detail_payment !== null
                        ? toDate(invoiceData.detail_payment[0].created_at)
                        : null}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p>Kasir</p>
                    <p>{invoiceData.detail_invoice.nama_kasir}</p>
                  </div>
                  <div className="w-full">
                    <hr className="border-2 border-solid border-black" />
                  </div>
                  <div className="flex justify-between font-bold">
                    <p>Racikan</p>
                    <p>Jumlah</p>
                    <p>Harga</p>
                  </div>
                  {invoiceData.list_medicine !== null
                    ? invoiceData.list_medicine.map((list, index) => (
                        <div className="flex justify-between" key={index}>
                          <p>{list.unit}</p>
                          <p>{list.quantity}</p>
                          <p>Rp. {list.price}</p>
                        </div>
                      ))
                    : null}
                </div>
                <div className="w-full">
                  <hr className="border-2 border-solid border-black" />
                </div>
                {invoiceData.subtotal !== null ? (
                  <div className="w-full">
                    <div className="flex justify-between">
                      <p>{invoiceData.detail_invoice.subtotal_alias}</p>
                      <p>Rp. {invoiceData.subtotal.subtotal}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Diskon total</p>
                      <p>Rp. {invoiceData.subtotal.discount}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>{invoiceData.detail_invoice.total_alias}</p>
                      <p>Rp. {invoiceData.subtotal.total}</p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="flex justify-center flex-col">
                <p className="mt-1 text-[20px] font-semibold mb-3">
                  {invoiceData.detail_invoice.footer}
                </p>
                <p className="mt-1 font-semibold mb-3">
                  <AiFillInstagram className="text-[30px]"/>
                  </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <p>data tidak ditemukan</p>
      )}
    </div>
  );
};

export default DetailInvoiceReportSales;
