import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { FaChevronLeft } from "react-icons/fa";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import moment from "moment";
import AutocompleteTextfield from "./AutocompleteTextfield";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCityRME,
  getDetailPatient,
  getDistrictRME,
  getProvinceRME,
  getSubdistrictRME,
  setPatient,
} from "../../../../services/patientCare";
import ModalSavePatientCare from "../ModalSavePatientCare";

const validationSchema = yup.object({
  patient_name: yup.string().required(),
  date_of_birth: yup.string().required(),
  gender: yup.number(),
  phone_number: yup.string(),
  email: yup.string().email(),
  nik: yup.string().required(),
  province: yup.string().required(),
  city: yup.string().required(),
  district: yup.string().required(),
  sub_district: yup.string().required(),
  detail_address: yup.string().required(),
  member: yup.boolean(),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toDate = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const ModalAddPatient = ({
  open,
  setOpen,
  patient,
  setPatientCarePatient,
  refetch,
}) => {
  const [openSave, setOpenSave] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setIsSave(false);
    if (patient) setPatientCarePatient(null);
  };

  const {
    data: dataDetail,
    isFetching: isFetchingDetail,
    error,
  } = useQuery({
    queryKey: ["detail-patient", patient, open],
    queryFn: () => getDetailPatient(patient.id),
    enabled: patient !== null && patient !== undefined && open,
    refetchOnWindowFocus: false,
    retryDelay: 60000,
  });

  const { mutate: mutatePatient, isLoading } = useMutation({
    mutationFn: setPatient,
    onSuccess: () => {
      if (refetch !== undefined) refetch();
      setOpenSave(false);
      setOpenConfirm(true);
      formik.resetForm();
    },
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const formik = useFormik({
    initialValues: {
      patient_name: dataDetail ? dataDetail.patient_name : "",
      date_of_birth: dataDetail ? dataDetail.date_of_birth : "",
      gender: dataDetail ? dataDetail.gender : 1,
      phone_number: dataDetail ? dataDetail.phone_number : "",
      email: dataDetail ? dataDetail.email : "",
      nik: dataDetail ? dataDetail.nik : "",
      province: dataDetail ? dataDetail.code_province : "",
      city: dataDetail ? dataDetail.code_city : "",
      district: dataDetail ? dataDetail.code_district : "",
      sub_district: dataDetail ? dataDetail.code_sub_district : "",
      detail_address: dataDetail ? dataDetail.detail_address : "",
      member: dataDetail ? dataDetail.member : true,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {
      setOpenSave(true);
    },
  });

  const { data: dataProvince, isFetching: isFetchingProvince } = useQuery({
    queryKey: ["province"],
    queryFn: () => getProvinceRME(),
    enabled: open,
    retryDelay: 60000, // one minute delay
    refetchOnWindowFocus: false,
  });

  const {
    data: dataCity,
    isFetching: isFetchingCity,
    refetch: refetchCity,
  } = useQuery({
    queryKey: ["city", formik.values.province],
    queryFn: () => getCityRME(formik.values.province),
    // enabled: formik.values.province !== "" && open && isFetchingProvince,
    enabled: false,
    retryDelay: 60000, // one minute delay
    refetchOnWindowFocus: false,
  });

  const {
    data: dataDistrict,
    isFetching: isFetchingDistrict,
    refetch: refetchDistrict,
  } = useQuery({
    queryKey: ["district", formik.values.city],
    queryFn: () => getDistrictRME(formik.values.city),
    // enabled: formik.values.city !== "" && open && isFetchingCity,
    enabled: false,
    retryDelay: 60000, // one minute delay
    refetchOnWindowFocus: false,
  });

  const {
    data: dataSubdistrict,
    isFetching: isFetchingSubdistrict,
    refetch: refetchSubdistrict,
  } = useQuery({
    queryKey: ["subdistrict", formik.values.district],
    queryFn: () => getSubdistrictRME(formik.values.district),
    // enabled: formik.values.district !== "" && open && isFetchingDistrict,
    enabled: false,
    retryDelay: 60000, // one minute delay
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSave) {
      patient
        ? mutatePatient({ id: patient.id, ...formik.values })
        : mutatePatient(formik.values);
    }
  }, [isSave]);

  useEffect(() => {
    if (errorMessage !== "") setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (dataProvince && formik.values.province !== "")
      setTimeout(function () {
        refetchCity();
      }, 800);
  }, [dataProvince, formik.values.province]);

  useEffect(() => {
    if (dataCity && formik.values.city !== "")
      setTimeout(function () {
        refetchDistrict();
      }, 800);
  }, [dataCity, formik.values.city]);

  useEffect(() => {
    if (dataDistrict && formik.values.district !== "")
      setTimeout(function () {
        refetchSubdistrict();
      }, 800);
  }, [dataDistrict, formik.values.district]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "80%",
            overflowY: "auto",
            overflowX: "wrap",
            minHeight: "80vh",
            maxHeight: "90vh",
            padding: 0,
          }}
          className="text-left text-base"
        >
          <div className="px-8 pt-4">
            <div className="grid grid-cols-3 items-center">
              <FaChevronLeft
                className="hover:text-red-800 cursor-pointer w-[14px] h-full"
                onClick={handleClose}
              />
              <p className="text-center font-bold text-lg">
                {patient ? "Edit" : "Tambah"} Pasien
              </p>
            </div>
          </div>
          <hr className="my-4" />
          {patient === undefined ||
          patient === null ||
          (patient && dataDetail && !isFetchingDetail) ? (
            <form onSubmit={formik.handleSubmit}>
              <div className="px-8 overflow-y-auto">
                <p className="font-bold mb-4">Personal Information</p>
                <div className="flex items-center gap-2 mb-4">
                  <TextField
                    fullWidth
                    placeholder="Nama Pasien"
                    label={
                      <p>
                        Nama Pasien<span className="text-red-800"> *</span>
                      </p>
                    }
                    value={formik.values.patient_name}
                    onChange={(e) => {
                      if (e.target.value.length <= 50)
                        formik.setFieldValue("patient_name", e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth
                    placeholder="Phone Number"
                    label="Phone Number"
                    value={formik.values.phone_number}
                    onChange={(e) => {
                      if (e.target.value === "")
                        formik.setFieldValue("phone_number", "");
                      if (
                        Number(e.target.value[0]) === 0 &&
                        e.target.value.length <= 20
                      )
                        if (
                          e.target.value.length === 1 ||
                          !isNaN(Number(e.target.value.slice(1)))
                        )
                          formik.setFieldValue("phone_number", e.target.value);
                    }}
                  />
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <DesktopDatePicker
                    className="w-full"
                    onChange={(birthDate) =>
                      formik.setFieldValue(
                        "date_of_birth",
                        toDateApi(birthDate)
                      )
                    }
                    value={
                      formik.values.date_of_birth === ""
                        ? null
                        : formik.values.date_of_birth
                    }
                    label={
                      <p>
                        Tanggal Lahir <span className="text-red-800">*</span>
                      </p>
                    }
                    renderInput={(params) => {
                      return <TextField {...params} />;
                    }}
                  />
                  <TextField
                    fullWidth
                    placeholder="Email"
                    label="Email"
                    value={formik.values.email}
                    onChange={(e) => {
                      if (e.target.value.length <= 50)
                        formik.setFieldValue("email", e.target.value);
                    }}
                  />
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="gender-label">
                      <p>
                        Gender <span className="text-red-800">*</span>
                      </p>
                    </InputLabel>
                    <Select
                      labelId="gender-label"
                      sx={{ width: "100%" }}
                      value={formik.values.gender}
                      onChange={(e) =>
                        formik.setFieldValue("gender", e.target.value)
                      }
                      label="Gender *"
                    >
                      <MenuItem value={1}>Laki-laki</MenuItem>
                      <MenuItem value={2}>Perempuan</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    placeholder="NIK"
                    label={
                      <p>
                        NIK<span className="text-red-800"> *</span>
                      </p>
                    }
                    value={formik.values.nik}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 16 &&
                        !isNaN(Number(e.target.value))
                      )
                        formik.setFieldValue("nik", e.target.value);
                    }}
                  />
                </div>

                <p className="font-bold mb-4">Address</p>
                <div className="flex items-center gap-2 mb-4">
                  <Autocomplete
                    fullWidth
                    loading={isFetchingProvince}
                    // disabled={!dataProvince}
                    disablePortal
                    options={dataProvince ? dataProvince : []}
                    value={
                      formik.values.province !== "" && dataProvince
                        ? dataProvince.find(
                            (prov) => prov.code === formik.values.province
                          )
                        : null
                    }
                    onChange={(_, option) => {
                      formik.setValues({
                        ...formik.values,
                        province: option ? option.code : "",
                        city: "",
                        district: "",
                        sub_district: "",
                        detail_address: "",
                      });
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : "N/A"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Province"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isFetchingProvince ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    loading={isFetchingDistrict}
                    disablePortal
                    disabled={formik.values.city === "" || !dataDistrict}
                    options={dataDistrict ? dataDistrict : []}
                    value={
                      formik.values.district !== "" && dataDistrict
                        ? dataDistrict.find(
                            (district) =>
                              district.code === formik.values.district
                          )
                        : null
                    }
                    onChange={(_, option) => {
                      formik.setValues({
                        ...formik.values,
                        district: option ? option.code : "",
                        sub_district: "",
                        detail_address: "",
                      });
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : "N/A"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isFetchingDistrict ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <Autocomplete
                    fullWidth
                    loading={isFetchingCity}
                    disablePortal
                    disabled={formik.values.province === "" || !dataCity}
                    options={dataCity ? dataCity : []}
                    value={
                      formik.values.city !== "" && dataCity
                        ? dataCity.find(
                            (city) => city.code === formik.values.city
                          )
                        : null
                    }
                    onChange={(_, option) => {
                      formik.setValues({
                        ...formik.values,
                        city: option ? option.code : "",
                        district: "",
                        sub_district: "",
                        detail_address: "",
                      });
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : "N/A"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="City"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isFetchingCity ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    loading={isFetchingSubdistrict}
                    disablePortal
                    disabled={formik.values.district === "" || !dataSubdistrict}
                    options={dataSubdistrict ? dataSubdistrict : []}
                    value={
                      formik.values.sub_district !== "" && dataSubdistrict
                        ? dataSubdistrict.find(
                            (sub) => sub.code === formik.values.sub_district
                          )
                        : null
                    }
                    onChange={(_, option) => {
                      formik.setValues({
                        ...formik.values,
                        sub_district: option ? option.code : "",
                        detail_address: "",
                      });
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : "N/A"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subdistrict"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isFetchingSubdistrict ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                <TextField
                  fullWidth
                  placeholder="Detail Address"
                  label={
                    <p>
                      Detail Address<span className="text-red-800"> *</span>
                    </p>
                  }
                  multiline
                  rows={4}
                  sx={{ marginBottom: 2 }}
                  value={formik.values.detail_address}
                  onChange={(e) => {
                    if (e.target.value.length <= 200)
                      formik.setFieldValue("detail_address", e.target.value);
                  }}
                />
                <div className="flex items-center justify-between mb-4">
                  <p>Add to member</p>
                  <Switch
                    checked={formik.values.member}
                    onChange={(_, checked) =>
                      formik.setFieldValue("member", checked)
                    }
                  />
                </div>
                <p className="text-red-800 mb-4">{errorMessage}</p>
              </div>
              <div className="px-8 pb-4">
                <button
                  className="w-full text-white bg-blue-800 disabled:bg-gray-300 p-2 rounded-md"
                  type="submit"
                  disabled={!formik.isValid || isLoading}
                >
                  Save
                </button>
              </div>
            </form>
          ) : patient !== null &&
            isFetchingDetail &&
            //     isFetchingProvince ||
            //     isFetchingCity ||
            //     isFetchingDistrict ||
            //     isFetchingSubdistrict) &&
            !error ? (
            <div className="flex justify-center">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="flex justify-center">No Data Found</div>
          )}
        </Box>
      </Modal>

      <ModalSavePatientCare
        open={openSave}
        setOpen={setOpenSave}
        setConfirm={setIsSave}
        title="Simpan Data Pasien"
        message="Simpan data pasien yang baru dibuat?"
      />

      <ModalConfirmationAction
        open={openConfirm}
        setOpen={setOpenConfirm}
        setOpenParent={setOpen}
        message="Data Pasien Disimpan"
      />
    </>
  );
};

export default ModalAddPatient;
