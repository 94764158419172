import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiMoney } from "react-icons/bi";

import { fetchDetailVoucher } from "../../../../../services/purchaseApi";

const VoucherPurchaseDetail = (props) => {
  const { voucherId } = props;
  const { mutate: mutateFetchDetailVoucher, data: voucherDetailData } =
    useMutation({
      mutationFn: fetchDetailVoucher,
    });

  useEffect(() => {
    mutateFetchDetailVoucher(voucherId);
  }, [voucherId]);

  return (
    <>
      {voucherDetailData && voucherDetailData !== undefined ? (
        <div className="flex flex-col justify-center">
          <div className="flex justify-center w-full">
            <img src={voucherDetailData.voucher_cover} className="w-full" />
          </div>
          <p className="text-[32px]">{voucherDetailData.name}</p>
          <div className="flex my-2">
            <div className="flex items-center">
              <AiOutlineClockCircle />
              <p>Berlaku hingga</p>
            </div>
            <p className="ml-10">{voucherDetailData.expired}</p>
          </div>
          <div className="flex">
            <div className="flex items-center">
              <BiMoney />
              <p>Minimum Pembelian</p>
            </div>
            <p className="ml-10">
              {voucherDetailData.minimum_purchase.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
          <p className="text-[32px] mt-10">Syarat dan ketentuan</p>
          {voucherDetailData.terms.map((term, index) => (
            <p key={index}>
              {index + 1}. {term}
            </p>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default VoucherPurchaseDetail;
