import * as yup from 'yup';

export const validationSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required("Nama wajib di isi"),
  email: yup.string().email().required("Email wajib untuk diisi"),
  phoneNumber: yup.string().required("No. telpon wajib untuk diisi"),
  address: yup.string().required("Alamat wajib untuk diisi"),
});

export const initialValues = {
  id: null, 
  name: "",
  email: "",
  phoneNumber: "",
  address: "",
};