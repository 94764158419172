import { useParams } from "react-router-dom";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import AddDoctorAnalysis from "../../../components/screen/patientCare/patientCareDoctorAnalysis/AddDoctorAnalysis";

const AddDoctorAnalysisPage = ({ isDetail }) => {
  const { id } = useParams();
  return (
    <PageTemplate
      header={`${id ? (isDetail ? "Detail" : "Edit") : "Add"} Doctor Analysis`}
      mainComponent={<AddDoctorAnalysis isDetail={isDetail} />}
    />
  );
};

export default HOC(AddDoctorAnalysisPage);
