import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import moment from "moment";

const toDate = (date) => moment(date).format("DD/MM/YY");

const DetailStockOpnameModal = (props) => {
  const { stockOpnameEndSessionDetail } = props;

  return (
    <div>
      {stockOpnameEndSessionDetail &&
      stockOpnameEndSessionDetail !== undefined ? (
        <div>
          <p>{stockOpnameEndSessionDetail[0].product_name}</p>
          <div className="flex justify-between">
            <div>
              <p>Sesi Stock Opname</p>
              <p className="text-[20px]">
                {stockOpnameEndSessionDetail[0].session_name}
              </p>
              <div className="border border-black w-full"></div>
            </div>
            <div>
              <p>User</p>
              <p className="text-[20px]">
                {stockOpnameEndSessionDetail[0].created_by}
              </p>
              <div className="border border-black w-full"></div>
            </div>
          </div>
          <TableContainer component={Paper} fullWidth sx={{ marginTop: 3 }}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    No.Batch
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Expired date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock Awal
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock Sebenarnya
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Status Stock
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockOpnameEndSessionDetail.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{data.batch}</TableCell>
                    <TableCell align="center">
                      {toDate(data.expired_date)}
                    </TableCell>
                    <TableCell align="center">{data.stock}</TableCell>
                    <TableCell align="center">{data.actual_stock}</TableCell>
                    <TableCell align="center">
                      {data.stock === data.actual_stock
                        ? "Tidak Berubah"
                        : "Berubah"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
    </div>
  );
};

export default DetailStockOpnameModal;
