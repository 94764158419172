import React from "react";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import StockOpnameRecap from "../../../components/screen/inventory/StockOpname/StockOpnameRecap";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const StockOpnameRecapPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Stock Opname {isWarehouse ? "Central Warehouse" : ""} Recap
          </h1>
          <StockOpnameRecap isWarehouse={isWarehouse} />
        </div>
      </div>
    </div>
  );
};

export default StockOpnameRecapPage;
