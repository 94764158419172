import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CircularProgress } from "@mui/material";

import {
  getProductDetailPurchase,
  addToCartPurchase,
} from "../../../../services/purchaseApi";

const DetailPurchaseProduct = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const [isAddToCartVisible, setIsAddToCartVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const { data: productDetailPurchase, isLoading: isProductDetailLoading } =
    useQuery({
      queryKey: ["detail-purchase"],
      queryFn: () => getProductDetailPurchase(parseInt(id, 10), 1),
    });

  const { mutate: mutateAddToCart, isLoading: isAddToCartLoading } =
    useMutation({
      mutationFn: addToCartPurchase,
    });

  useEffect(() => {
    if (productDetailPurchase && productDetailPurchase !== undefined) {
      setQuantity(productDetailPurchase.quantity);
      setIsAddToCartVisible(productDetailPurchase.quantity !== 0);
    }
  }, [productDetailPurchase]);

  return (
    <div>
      {isProductDetailLoading ? (
        <div className="h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex gap-3 text-[#007AF1] font-bold my-3 p-3">
            <p
              className="hover:underline hover:cursor-pointer"
              onClick={() => navigate("/purchase")}
            >
              Purchase
            </p>
            <p>/</p>
            <p className="hover:underline hover:cursor-pointer">
              Detail Produk
            </p>
          </div>
          {productDetailPurchase && productDetailPurchase !== undefined ? (
            <div className="mt-8">
              <div className="flex justify-center lg:grid grid-cols-2">
                <img
                  src={productDetailPurchase.image}
                  alt="nood-pics"
                  className="rounded-lg border-3 border-[#F0F2F4] w-[70%]"
                />
                <div className="rounded-lg shadow-md p-3">
                  <p className="text-[32px] font-bold">
                    {productDetailPurchase.product_name}
                  </p>
                  <p className="text-[24px] font-semibold">
                    {productDetailPurchase.price.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                  <p className="text-[16px] font-medium">
                    Jumlah Produk {productDetailPurchase.quantity}
                  </p>
                </div>
              </div>
              <div className="flex mt-3">
                <img
                  src={productDetailPurchase.image}
                  alt="nood-pics"
                  className="rounded-lg border-3 border-[#F0F2F4] w-[10%]"
                />
                <img
                  src={productDetailPurchase.image}
                  alt="nood-pics"
                  className="rounded-lg border-3 border-[#F0F2F4] w-[10%] mx-3"
                />
                <img
                  src={productDetailPurchase.image}
                  alt="nood-pics"
                  className="rounded-lg border-3 border-[#F0F2F4] w-[10%]"
                />
              </div>
              <div className="mt-3">
                <p className="font-bold text-[20px]">Deskripsi Produk</p>
                <div className="border border-black"></div>
                <div className="mt-3">
                  <p className="font-bold">Kandungan/Komposisi</p>
                  <p>
                    {productDetailPurchase.komposisi !== null
                      ? productDetailPurchase.komposisi
                      : "-"}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-bold">Kemasan</p>
                  <p>-</p>
                </div>
                <div className="mt-3">
                  <p className="font-bold">Kategori Produk</p>
                  <p>
                    {productDetailPurchase.kategori_produk !== null
                      ? productDetailPurchase.kategori_produk
                      : "-"}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-bold">Golongan</p>
                  <p>
                    {productDetailPurchase.golongan !== null
                      ? productDetailPurchase.golongan
                      : "-"}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-bold">Principal</p>
                  <p>
                    {productDetailPurchase.principal !== null
                      ? productDetailPurchase.principal
                      : "-"}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="font-bold">Nomor Izin Edar ( NIE )</p>
                  <p>
                    {productDetailPurchase.nie !== null
                      ? productDetailPurchase.nie
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-[80px]">
                <div className="fixed bottom-0 z-20 bg-white p-3 rounded-md w-full h-[7%] drop-shadow-lg">
                  <div className="flex justify-center w-full">
                    {isAddToCartVisible ? (
                      <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg w-[77%] p-1">
                        {isAddToCartLoading ? (
                          <div className="bg-[#EAEAEA] flex justify-center font-medium rounded-lg text-center w-full p-1 text-center">
                            Loading
                          </div>
                        ) : (
                          <>
                            <button
                              className="bg-[#FF7A5D] px-3 rounded-lg"
                              onClick={() => {
                                if (quantity === 1)
                                  setIsAddToCartVisible(false);
                                setQuantity((prevQty) => prevQty - 1);
                                mutateAddToCart({
                                  price: productDetailPurchase.price,
                                  product_id: productDetailPurchase.id,
                                  quantity: quantity - 1,
                                  flag: false,
                                  type: 1,
                                  param: 1,
                                });
                              }}
                            >
                              -
                            </button>
                            <p>{quantity}</p>
                            <button
                              className="bg-[#5DFFBB] px-3 rounded-lg"
                              onClick={() => {
                                setQuantity((prevQty) => prevQty + 1);
                                mutateAddToCart({
                                  price: productDetailPurchase.price,
                                  product_id: productDetailPurchase.id,
                                  quantity: quantity + 1,
                                  flag: false,
                                  type: 1,
                                  param: 1,
                                });
                              }}
                            >
                              +
                            </button>
                          </>
                        )}
                      </div>
                    ) : (
                      <button
                        className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-[77%] p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                        onClick={() => {
                          setQuantity(1);
                          setIsAddToCartVisible(true);
                          mutateAddToCart({
                            price: productDetailPurchase.price,
                            product_id: productDetailPurchase.id,
                            quantity: 1,
                            flag: false,
                            type: 1,
                            param: 1,
                          });
                        }}
                      >
                        <AiOutlineShoppingCart />
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DetailPurchaseProduct;
