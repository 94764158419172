import { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Pagination,
} from "@mui/material";

const ReportingModal = (props) => {
  const {
    param,
    setParam,
    setReqBody,
    setIsModalListProductOpen,
    setNameBuffer,
    listProductReporting,
    isListProductReportingLoading,
  } = props;

  const pageCount = useMemo(() => {
    if (listProductReporting && listProductReporting !== undefined) {
      return Math.ceil(listProductReporting.count / param.limit);
    }
  }, [listProductReporting, param.limit]);

  return (
    <div>
      <TextField
        fullWidth
        sx={{ marginY: 3 }}
        label="Cari Produk"
        onChange={(e) =>
          setParam((prevValue) => ({
            ...prevValue,
            name: e.target.value,
          }))
        }
        value={param.name}
      />

      {isListProductReportingLoading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {listProductReporting && listProductReporting !== undefined ? (
            <>
              {listProductReporting.data !== null ? (
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple-table">
                    <TableHead className="text-[10px]">
                      <TableRow
                        sx={{
                          backgroundColor: "#007AF1",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Nama Produk
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Kuantitas
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listProductReporting.data.map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">
                            <p
                              className="text-blue-500 hover:underline cursor-pointer"
                              onClick={() => {
                                setReqBody((prevValue) => ({
                                  ...prevValue,
                                  product_name: data.product_name,
                                }));
                                setNameBuffer(data.product_name);
                                setIsModalListProductOpen(false);
                              }}
                            >
                              {data.product_name}
                            </p>
                          </TableCell>
                          <TableCell align="center">{data.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="flex justify-center items-center my-5">
                  <p>Tidak ada Produk!</p>
                </div>
              )}
            </>
          ) : null}
          {listProductReporting.data !== null ? (
            <div className="flex justify-center mt-[10px]">
              <Pagination
                count={pageCount}
                page={param.offset}
                onChange={(_, value) =>
                  setParam((prevValue) => ({
                    ...prevValue,
                    offset: value,
                  }))
                }
                shape="rounded"
                size="large"
                color="primary"
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
export default ReportingModal;
