import { Box, Modal } from "@mui/material";
import { FaRegTrashAlt } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import { useMutation } from "@tanstack/react-query";
import { deletePatient } from "../../../../services/patientCare";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeletePatient = ({ open, setOpen, patient, refetch }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: mutateDelete, isLoading } = useMutation({
    mutationFn: deletePatient,
    onSuccess: () => {
      refetch();
      setOpen(false);
      setOpenConfirm(true);
    },
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutateDelete(patient.id);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "50vh",
            padding: 0,
          }}
          className="text-left text-base w-3/4 lg:w-1/2"
        >
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex justify-between items-center px-8 py-4">
              <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
                <div className="w-full h-full bg-red-300 p-2 rounded-full">
                  <FaRegTrashAlt className="w-full h-full text-red-600" />
                </div>
              </div>
              <RxCross1
                className="cursor-pointer hover:text-red-500"
                onClick={handleClose}
              />
            </div>

            <div className="px-8 mb-4">
              <p className="font-bold text-lg ">Hapus Data Pasien</p>
              <p>Apakah anda yakin ingin menghapus data pasien?</p>
            </div>

            <p className="text-red-500 mb-4">{errorMessage}</p>

            <div className="px-8 mb-4 flex gap-2 items-center">
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={
                  patient === undefined ||
                  (patient && patient.id === undefined) ||
                  isLoading
                }
                className="w-full p-2 rounded-md border-2 border-red-500 bg-red-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-gray-300"
              >
                Delete
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <ModalConfirmationAction
        open={openConfirm}
        setOpen={setOpenConfirm}
        setOpenParent={setOpen}
        message="Data Pasien Dihapus"
      />
    </>
  );
};

export default ModalDeletePatient;
