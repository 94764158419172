import { useState, useCallback, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  CircularProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import { AiFillPlusCircle, AiOutlineUpload } from "react-icons/ai";
import * as yup from "yup";

import {
  fetchComplainPage,
  addComplain,
  uploadComplainMedia,
} from "../../../../../services/purchaseApi";

const validationSchema = yup.object({
  order_id: yup.string().required("wajib"),
  reason: yup.string().required("Alasan pengajuan wajib diisi"),
  solution: yup.string().required("Solusi wajib dipilih"),
  product: yup
    .array()
    .min(1, "Setidaknya 1 produk dipilih")
    .required("produk yang ingin diajukan wajib pilih"),
  note: yup.string().required("Mohon berikan alasan pengajuan"),
  media: yup.array(),
});

const ComplainPurchase = () => {
  const { invoice = "" } = useParams();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const {
    mutate: mutateComplainPage,
    data: complainData,
    isLoading: isComplainDataLoading,
  } = useMutation({
    mutationFn: fetchComplainPage,
  });

  const { mutate: mutateAddComplain } = useMutation({
    mutationFn: addComplain,
    onSuccess: () => navigate("/purchase/list-transaction"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateUploadMedia, data: media } = useMutation({
    mutationFn: uploadComplainMedia,
  });

  const formik = useFormik({
    initialValues: {
      order_id: invoice,
      reason: "",
      media: [],
      solution: "",
      product: [],
      note: "",
    },
    validationSchema,
    onSubmit: (values) => mutateAddComplain(values),
  });

  const handleSelectList = useCallback(
    (selectedItem) => {
      const selectedValue = formik.values.product.findIndex(
        (data) => data.product_id === selectedItem.id
      );

      let updatedList = cloneDeep(formik.values.product);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push({
          id: selectedItem.product_id,
          quantity: selectedItem.quantity,
        });
      }
      formik.setFieldValue("product", updatedList);
    },
    [formik.values.product]
  );

  useEffect(() => {
    mutateComplainPage({ invoice: invoice });
  }, [invoice]);

  useEffect(() => {
    if (media && media !== undefined)
      formik.setFieldValue("media", [...formik.values.media, ...media]);
    else return;
  }, [media]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="font-medium">
        {complainData && complainData !== undefined ? (
          <div>
            <p className="text-[48px] font-medium">
              Pengembalian Barang & Dana
            </p>
            <div className="flex gap-3 text-[#007AF1] font-bold my-3 p-3">
              <p
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/purchase")}
              >
                Purchase
              </p>
              <p>/</p>
              <p
                className="hover:underline hover:cursor-pointer"
                onClick={() => navigate("/purchase/list-transaction")}
              >
                List Pembelian
              </p>
              <p>/</p>
              <p className="hover:underline hover:cursor-pointer">
                Pengembalian Barang & Dana
              </p>
            </div>
            <p>
              Pilih jenis product dan input qty untuk pengembalian barang/Dana
            </p>
            <div className="mb-2">
              <p>Pilih Alasan Pengembalian Barang</p>
              <FormControl
                fullWidth
                error={formik.touched.reason && Boolean(formik.errors.reason)}
                sx={{ marginTop: 2 }}
              >
                <InputLabel>Pilih alasan pengembalian barang</InputLabel>
                <Select
                  label="Pilih alasan pengembalian barang"
                  onChange={(e) =>
                    formik.setFieldValue("reason", e.target.value)
                  }
                  value={formik.values.reason}
                >
                  {complainData.reason.map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                  <MenuItem
                    sx={{ display: "none" }}
                    disabled
                    value=""
                  ></MenuItem>
                </Select>
              </FormControl>
              {formik.touched.reason && Boolean(formik.errors.reason) ? (
                <FormHelperText sx={{ color: "#D32F2F" }}>
                  {formik.touched.reason && formik.errors.reason}
                </FormHelperText>
              ) : null}
            </div>
            {isComplainDataLoading ? (
              <div className="h-screen flex justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="w-full">
                  <p>Pilih produk yang dikomplain</p>
                  {complainData.product && complainData.product !== undefined
                    ? complainData.product.regular?.map((data, index) => (
                        <div
                          className={`rounded-md p-3 font-bold my-3 ${
                            formik.touched.product &&
                            Boolean(formik.errors.product)
                              ? "border-2 border-[#D32F2F]"
                              : "border border-black"
                          }`}
                          key={index}
                        >
                          <div className="flex justify-between w-full">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                onClick={() => handleSelectList(data)}
                              />
                              <img
                                src={data.image}
                                alt=""
                                className="border border-black rounded-md w-[150px] h-auto bg-cover"
                              />
                              <div>
                                <p>{data.product_name}</p>
                                <p>jumlah: {data.quantity}</p>
                                <p>
                                  {data.price && data.price !== undefined
                                    ? data.price.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })
                                    : 0}
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-center items-center">
                              <div className="h-full border border-black mr-3"></div>
                              <div className="p-4 text-center">
                                <p>Total</p>
                                <p className="font-bold">
                                  {data.total && data.total !== undefined
                                    ? data.total.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })
                                    : 0}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                  {formik.touched.product && Boolean(formik.errors.product) ? (
                    <FormHelperText sx={{ color: "#D32F2F" }}>
                      {formik.touched.product && formik.errors.product}
                    </FormHelperText>
                  ) : null}
                  <div className="font-medium">
                    <div>
                      <p>Alasan</p>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        value={formik.values.note}
                        onChange={(e) =>
                          formik.setFieldValue("note", e.target.value)
                        }
                        rows={4}
                        sx={{ width: "100%" }}
                        error={
                          formik.touched.note && Boolean(formik.errors.note)
                        }
                        helperText={formik.touched.note && formik.errors.note}
                      />
                    </div>
                    <div className="my-3">
                      <p>Upload Bukti Foto dan Video</p>
                      <p className="text-[#7D8A9C] text-[16px]">
                        Maks 5 foto atau 4 foto + 1 video, format .jpg .mov .mp4
                        .png .mkv .3gp .jpeg
                      </p>
                      {formik.values.media.length === 0 ? (
                        <div className="flex items-end justify-center p-6 border-2 rounded-md w-full h-[250px]">
                          <div
                            className="border-2 border-[#007AF1] rounded-md p-2 hover:cursor-pointer flex items-center"
                            onClick={() => {
                              if (formik.values.media.length === 5) return;
                              fileInputRef.current.click();
                            }}
                          >
                            <input
                              hidden
                              type="file"
                              ref={fileInputRef}
                              accept="image/jpeg, image/png, image/jpg, video/mp4"
                              onChange={(e) =>
                                mutateUploadMedia(e.target.files[0])
                              }
                            />
                            <AiFillPlusCircle className="mr-2 text-[30px] text-[#007AF1]" />
                            <p>Upload Foto / Video</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center border-2 rounded-md h-[250px]">
                          {formik.values.media.map((value, index) =>
                            value.includes(".mp4") ? (
                              <video
                                alt=""
                                className="border border-black rounded-md w-[300px] h-auto object-cover mr-3"
                                src={value}
                                key={index}
                                controls
                              />
                            ) : (
                              <img
                                alt=""
                                className="border border-black rounded-md w-[150px] h-auto object-cover mr-3"
                                src={value}
                                key={index}
                              />
                            )
                          )}
                          <div
                            className="border border-black rounded-md w-[100px] h-[100px] flex items-center justify-center hover:cursor-pointer"
                            onClick={() => {
                              if (formik.values.media.length === 5) return;
                              fileInputRef.current.click();
                            }}
                          >
                            <input
                              hidden
                              type="file"
                              ref={fileInputRef}
                              accept="image/jpeg, image/png, image/jpg, video/mp4"
                              onChange={(e) =>
                                mutateUploadMedia(e.target.files[0])
                              }
                            />
                            <AiOutlineUpload className="text-[50px]" />
                          </div>
                        </div>
                      )}
                      {/* <div className="flex items-start justify-center p-3 border border-black rounded-md"></div> */}
                    </div>
                    <div className="w-full">
                      <p>Pilih solusi yang anda inginkan</p>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: 2 }}
                        id="solution"
                        error={
                          formik.touched.solution &&
                          Boolean(formik.errors.solution)
                        }
                      >
                        <InputLabel>Pilih Solusi Pengembalian</InputLabel>
                        <Select
                          value={formik.values.solution}
                          label="Pilih Solusi Pengembalian"
                          onChange={(e) =>
                            formik.setFieldValue("solution", e.target.value)
                          }
                        >
                          {complainData.solution.map((value, index) => (
                            <MenuItem value={value} key={index}>
                              {value}
                            </MenuItem>
                          ))}
                          <MenuItem
                            sx={{ display: "none" }}
                            disabled
                            value=""
                          ></MenuItem>
                        </Select>
                      </FormControl>
                      {formik.touched.solution &&
                      Boolean(formik.errors.solution) ? (
                        <FormHelperText sx={{ color: "#D32F2F" }}>
                          {formik.touched.solution && formik.errors.solution}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <button
                    className="text-white font-bold rounded-md bg-[#007AF1] p-2 w-full"
                    type="submit"
                  >
                    Kirim Pengajuan
                  </button>
                  <button
                    className="font-bold border border-black rounded-md p-2 w-full mt-2"
                    onClick={() => navigate("/purchase/list-transaction")}
                  >
                    Batalkan
                  </button>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") return;
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </form>
  );
};

export default ComplainPurchase;
