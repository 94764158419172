import { useNavigate } from "react-router-dom";

const AccountProfileNavigation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full border-2 my-3 text-sm lg:text-base grid grid-cols-2">
      <button
        onClick={() => navigate("/profile")}
        className={
          window.location.pathname === "/profile"
            ? "py-2 bg-blue-500 text-white w-full"
            : "py-2 w-full"
        }
      >
        Detail Akun
      </button>
      <button
        onClick={() => navigate("/subscription")}
        className={
          window.location.pathname === "/subscription"
            ? "py-2 bg-blue-500 text-white w-full"
            : "py-2 w-full"
        }
      >
        Langganan
      </button>
    </div>
  );
};

export default AccountProfileNavigation;
