import { statusTransaction } from "../../../../constants/constant";

const StatusTransaction = (props) => {
  const { status } = props;
  return (
    <div>
      {status && status !== undefined ? (
        <div className="font-bold w-fit">
          {(status === "Menunggu Konfirmasi" ||
            status === "Tiba Di Tujuan" ||
            status === "Diproses" ||
            status === "Dikirim") && (
            <div
              className={`bg-[${statusTransaction.processed}] text-white p-2 rounded-md`}
            >
              <p>{status}</p>
            </div>
          )}
          {(status === "Dibatalkan" || status === "Dikomplain") && (
            <div
              className={`bg-[${statusTransaction.cancelledOrComplained}] text-white p-2 rounded-md`}
            >
              <p>{status}</p>
            </div>
          )}
          {status === "Selesai" && (
            <div className="bg-[#00CD52] text-white p-2 rounded-md">
              <p>{status}</p>
            </div>
          )}
          {status === "Menunggu Pembayaran" && (
            <div
              className={`bg-[${statusTransaction.waitingPayment}] text-white p-2 rounded-md`}
            >
              <p>{status}</p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default StatusTransaction;
