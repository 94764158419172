import { useEffect, useState } from "react";
import { Button, CircularProgress, Collapse, Snackbar } from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ContentCopy,
  ErrorRounded,
} from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import BillingBreadcrumb from "./BillingBreadcrumb";
import OlinLogo from "../../../assets/icons/olinlogo.png";
import CollapsibleBox from "../../common/particles.jsx/CollapsibleBox";
import {
  getBillingPaymentDetail,
  getBillingStatus,
} from "../../../services/billingAPI";
import moment from "moment";

const BillingPayment = () => {
  const { va } = useParams();
  const navigate = useNavigate();

  const [collapsePo, setCollapsePo] = useState(true);
  const [collapsePrice, setCollapsePrice] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [collapseInstruction, setCollapseInstruction] = useState([]);
  const [timeDiff, setTimeDiff] = useState(moment.duration());
  const [isPressed, setIsPressed] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(0);
  const [countdownMinutes, setCountdownMinutes] = useState(0);
  const [countdownHours, setCountdownHours] = useState(0);

  const changeHour = () => {
    setCountdownMinutes(59);
    if (countdownHours > 0) setCountdownHours(countdownHours - 1);
  };

  const { mutate: mutateDetailPayment, data } = useMutation({
    mutationFn: getBillingPaymentDetail,
  });

  const { mutate: mutatePaymentStatus, data: dataPaymentStatus } = useMutation({
    mutationFn: getBillingStatus,
  });

  useEffect(() => {
    mutateDetailPayment(atob(va));
  }, []);

  useEffect(() => {
    if (!data) return;

    if (data) {
      const timeDiffDuration = moment.duration(
        moment(data.payment.due_date).diff(moment())
      );
      setCollapseInstruction([
        ...Array(data.payment_instruction.length).fill(false),
      ]);
      setTimeDiff(timeDiffDuration);

      if (timeDiffDuration.hours() > 0) {
        setCountdownHours(timeDiffDuration.hours());
        setCountdownMinutes(timeDiff.minutes());
        setCountdownSeconds(timeDiff.seconds());
      }
      setIsPressed(true);
    }
  }, [data]);

  useEffect(() => {
    if (dataPaymentStatus) {
      dataPaymentStatus.sukses
        ? navigate("/billing/payment-status/success")
        : navigate(
            "/billing/payment-status/unsuccessful/" +
              btoa(data.total) +
              "-" +
              btoa(data.payment.due_date)
          );
    }
  }, [dataPaymentStatus]);

  useEffect(() => {
    if (!isPressed) return;

    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setIsPressed(false);
          setCountdownSeconds(59);
          countdownMinutes > 0
            ? setCountdownMinutes(countdownMinutes - 1)
            : changeHour();
          return prevCount;
        }
        return prevCount - 1;
      });
      return () => clearInterval(interval);
    }, 1000);
  }, [isPressed]);

  useEffect(() => {
    setIsPressed(true);
  }, [countdownMinutes, countdownHours]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0 mb-24">
      <BillingBreadcrumb />
      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>
      {data && data !== null ? (
        <>
          <div className="mt-3  shadow-md rounded-xl p-3 border-2 justify-between w-full cursor-pointer">
            <div className="flex w-full justify-center items-center mb-8">
              <img src={OlinLogo} className="w-[48px] object-contain mr-4" />
              <p className="font-bold font-xl">Billing</p>
            </div>

            <div className="w-full">
              <div
                className="flex justify-between w-full"
                onClick={() => setCollapsePo(!collapsePo)}
              >
                <div className="w-full font-bold">No. PO</div>
                <div className="w-full text-right">
                  {collapsePo ? (
                    <ExpandLess sx={{ width: "fit-content" }} />
                  ) : (
                    <ExpandMore sx={{ width: "fit-content" }} />
                  )}
                </div>
                <hr className="my-2" />
              </div>
            </div>

            {collapsePo ? <hr className="my-2" /> : null}

            <div>
              <Collapse in={collapsePo} timeout="auto" unmountOnExit>
                <div className="my-2">
                  <div>
                    <>
                      {data.transactions_id.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full flex justify-between"
                          >
                            <p
                              className="text-blue-500"
                              onClick={() =>
                                navigate(
                                  `/billing/waiting-payment/payment-summary/${va}/detail/${item.bill_id}`
                                )
                              }
                            >
                              {item.transaction_id}
                            </p>
                            <p className="basis-1/4 font-bold">
                              {item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="w-full">
              <div
                className="flex justify-between w-full"
                onClick={() => setCollapsePrice(!collapsePrice)}
              >
                <div className="w-full font-bold">Detail Harga</div>
                <div className="w-full text-right">
                  {collapsePrice ? (
                    <ExpandLess sx={{ width: "fit-content" }} />
                  ) : (
                    <ExpandMore sx={{ width: "fit-content" }} />
                  )}
                </div>
              </div>
            </div>

            {collapsePrice ? <hr className="my-2" /> : null}

            <div>
              <Collapse in={collapsePrice} timeout="auto" unmountOnExit>
                <div className="my-2">
                  <div className="flex justify-between w-full my-2">
                    <p>Subtotal</p>
                    <p className="basis-1/4 font-bold">
                      {data.subtotal.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                  <div className="flex justify-between w-full my-2">
                    <p>Tanggal Pembayaran</p>
                    <p className="basis-1/4 font-bold">
                      {moment(data.tanggal_pembayaran).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-between w-full my-2">
                    <p>Biaya Penanganan</p>
                    <p className="basis-1/4 font-bold">
                      {data.handling_fee.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                </div>
              </Collapse>
            </div>

            <hr className="my-2" />
            <div className="w-full flex justify-between mt-2 mb-4">
              <p className="font-bold">Total Harga</p>
              <p className="font-bold basis-1/4">
                {data.total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
          </div>

          <div className="w-full my-8">
            <p className="text-lg font-bold mb-4">
              Transfer ke Virtual Account
            </p>
            <div
              className={`absolute right-0 bg-blue-200 left-0 lg:left-[310px]`}
            >
              <div className="flex items-center justify-between px-8 py-4">
                <div className="w-full flex items-center">
                  <img
                    src={data.payment.detail.logo}
                    className="w-[56px] mr-4 object-contain"
                  />
                  <p className="font-bold" id="vaNum">
                    {data.payment.va}
                  </p>
                </div>
                <div className="w-fit">
                  <ContentCopy
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        document.getElementById("vaNum").innerHTML
                      );
                      setIsCopied(true);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center w-full mt-[calc(100px+2rem)]">
              <ErrorRounded className="mx-2 text-amber-500" />
              <div className="w-full">
                <p>
                  Pastikan nomor virtual account/pelanggan yang kamu masukan
                  benar. OLIN <b>Tidak Bertanggung Jawab</b> jika Terjadi
                  kesalahan pengisian data.
                </p>
              </div>
            </div>

            <div className="flex w-full mt-8 items-center justify-between">
              <p className="w-full">Tagihan Kamu</p>
              <p className="w-full text-right font-bold text-xl">
                {data.total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>

            <hr className="my-4" />

            <div className="flex w-full items-center justify-between">
              <p className="w-full">Jatuh Tempo</p>
              <div className="w-full text-right">
                <p className="text-red-500">
                  {countdownHours > 0
                    ? countdownHours +
                      " jam " +
                      countdownMinutes +
                      " menit " +
                      countdownSeconds +
                      " detik"
                    : "Expired"}
                </p>
                <p>
                  Mohon lakukan pembayaran sebelum{" "}
                  {moment(data.payment.due_date).format("DD MMMM YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div className="w-full my-8">
            <p className="text-lg font-bold mb-6">Instruksi Pembayaran</p>
            <>
              {data.payment_instruction.map((inst, i) => {
                return (
                  <div className="my-2" key={i}>
                    <CollapsibleBox
                      collapse={collapseInstruction}
                      setCollapse={setCollapseInstruction}
                      title={inst.payment_source}
                      content={
                        <ol>
                          {inst.payment_instruction.map((item, index) => (
                            <li className="py-2" key={index}>
                              {item}
                            </li>
                          ))}
                        </ol>
                      }
                      index={i}
                    />
                  </div>
                );
              })}
            </>
          </div>
        </>
      ) : data && data === null ? (
        <></>
      ) : (
        <CircularProgress size={20} />
      )}
      <Snackbar
        open={isCopied}
        onClose={() => setIsCopied(false)}
        message={"Virtual Account Number Copied"}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />

      <div className="bg-white fixed left-0 lg:left-[310px] right-0 bottom-0 p-4 drop-shadow-xl">
        <Button
          fullWidth
          variant="contained"
          onClick={() => mutatePaymentStatus(atob(va))}
        >
          Cek Status Pembayaran
        </Button>
      </div>
    </div>
  );
};

export default BillingPayment;
