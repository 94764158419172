import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { getDetailPayment } from "../../../services/authApi";
import logoOlin from "../../../assets/icons/logoOlin.png";
import moment from "moment";

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const InvoiceSubscription = () => {
  const { billNumber, invoiceType } = useParams();

  const { data: detailPayment } = useQuery({
    queryKey: ["detail-payment"],
    queryFn: () => getDetailPayment(billNumber, parseInt(invoiceType, 10)),
  });

  return (
    <div className="h-screen flex items-center justify-center">
      {detailPayment && detailPayment !== undefined ? (
        <div className="border border-black p-3 rounded-md w-[70%]">
          <img src={logoOlin} className="h-[15%] w-[25%] mb-3" />
          <div>
            <p>Bill To:</p>
            <p>{detailPayment.company_name}</p>
            <p>{detailPayment.address}</p>
            <p>{detailPayment.business_address}</p>
            <p>
              {detailPayment.city}, {detailPayment.province},{" "}
              {detailPayment.kode_pos}
            </p>
          </div>
          <div className="flex my-3">
            <div>
              <p>Invoice Number</p>
              <p>Transaction Date</p>
              <p>Payment Date</p>
              <p>Payment Method</p>
            </div>
            <div className="mx-3">
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div>
              <p>{detailPayment.sub_id}</p>
              <p>{toDate(detailPayment.tanggal_transaksi)}</p>
              <p>{toDate(detailPayment.tanggal_dibayar)}</p>
              <p>{detailPayment.payment_name}</p>
            </div>
          </div>
          <div className="overflow-x-auto p-4">
            <table className="table w-full border">
              <thead>
                <tr className="text-center font-bold">
                  <th className="border-collapse border border-black">
                    Description
                  </th>
                  <th className="border-collapse border border-black">QTY</th>
                  <th className="border-collapse border border-black">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td className="border-collapse border border-black">
                    {detailPayment.subscription_name}
                  </td>
                  <td className="border-collapse border border-black">
                    {detailPayment.quantity}
                  </td>
                  <td className="border-collapse border border-black">
                    {detailPayment.total_price.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
            <p>This invoice is Paid</p>
            <p>Payment Information: {detailPayment.payment_name}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceSubscription;
