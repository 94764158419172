import { Add, Remove } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  fetchProductList,
  getDetailInventory,
} from "../../../../services/InventoryAPI";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import PrescriptionPreview from "./PrescriptionPreview";
import { fetchProductListPatientCare } from "../../../../services/patientCare";

const FETCH_LIMIT = 10;
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");
const filter = createFilterOptions();

const DoctorConcoction = ({
  formik,
  isDetail,
  setIsFormView,
  isFormView,
  selectedDoctor,
  selectedPatient,
}) => {
  const [initialRegular, setInitialRegular] = useState(null);
  const [initialConcoction, setInitialConcoction] = useState(null);
  const [searchProduct, setSearchProduct] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const debounce = useDebounce(searchProduct, 500);

  const filterRegular = (arr) => {
    return arr.filter(
      (item) => item.product_name !== "" && item.uom !== "" && item.quantity > 0
    );
  };

  const filterConcoction = (arr) => {
    return arr.filter((conc) =>
      conc.product.every(
        (prod) =>
          prod.product_name !== "" && prod.uom !== "" && prod.quantity > 0
      )
    );
  };

  const handleSave = () => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      regular: filterRegular(prevValues.regular),
      concoction: filterConcoction(prevValues.concoction),
    }));

    setIsFormView(true);
  };

  const fetchProducts = (searchStr, id) => {
    mutateActiveProducts({
      offset: 1,
      limit: FETCH_LIMIT,
      name: searchStr,
    });
  };

  const {
    mutate: mutateActiveProducts,
    data: products,
    isLoading,
  } = useMutation({
    mutationFn: fetchProductListPatientCare,
  });

  const {
    data: dataDetailProduct,
    isFetching: isFetchingDetailProduct,
    refetch: refetchDetailProduct,
  } = useQuery({
    queryKey: ["detail_product", selectedProduct],
    queryFn: () => getDetailInventory(selectedProduct),
    enabled: !!selectedProduct,
  });

  useEffect(() => {
    if (!isFormView) {
      setInitialRegular(formik.values.regular);
      setInitialConcoction(formik.values.concoction);
    }
  }, [isFormView]);

  useEffect(() => {
    fetchProducts(debounce);
  }, [debounce]);

  useEffect(() => {
    if (selectedProduct) {
      refetchDetailProduct();
    }
  }, [selectedProduct]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
        <div className="w-full mb-4">
          {/* <TextField
            fullWidth
            label="Prescription Code"
            sx={{ marginBottom: 2 }}
          /> */}
          <DesktopDatePicker
            label="Prescription Date"
            sx={{ width: "100%" }}
            value={formik.values.prescription_date}
            onChange={(newValue) =>
              formik.setFieldValue("prescription_date", toDateApi(newValue))
            }
            renderInput={(params) => {
              return <TextField {...params} fullWidth />;
            }}
          />

          <div className="w-full my-4 rounded-md border-2 p-2">
            {formik.values.regular.map((item, i) => (
              <div className="rounded-md border-2 p-2 mb-2" key={i}>
                <div className="w-full flex justify-end">
                  <div
                    className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 rounded-md p-1 text-white mb-2"
                    onClick={() =>
                      formik.setFieldValue("regular", [
                        ...formik.values.regular.slice(0, i),
                        ...formik.values.regular.slice(i + 1),
                      ])
                    }
                  >
                    <AiOutlineMinusCircle className="w-full h-full" />
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <span className="mr-2 font-bold" style={{ width: "30px" }}>
                    R/
                  </span>
                  <div className="flex-grow mr-2">
                    <Autocomplete
                      fullWidth
                      // freeSolo
                      loading={isLoading}
                      disablePortal
                      options={
                        products && products.product_data
                          ? products.product_data
                          : []
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.product_name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            product_name: inputValue,
                            product_id: 0,
                          });
                        }

                        return filtered;
                      }}
                      value={
                        formik.values.regular[i]?.product_name
                          ? {
                              product_name:
                                formik.values.regular[i].product_name,
                              product_id: formik.values.regular[i].product_id,
                            }
                          : null
                      }
                      onChange={(event, newValue) => {
                        formik.setFieldValue(`regular[${i}]`, {
                          ...formik.values.regular[i],
                          product_id: newValue ? newValue.product_id : "",
                          product_name: newValue ? newValue.product_name : "",
                          uom: "",
                          quantity: 0,
                        });
                      }}
                      onOpen={() => fetchProducts("")}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option.product_name || ""
                      }
                      renderOption={(props, option) => (
                        <>
                          {typeof option === "string" ? (
                            <div>option</div>
                          ) : (
                            (
                              <li {...props}>
                                <div>
                                  <p>{option.product_name}</p>
                                  <p className="text-gray-500">
                                    {option.packing.total_box +
                                      " " +
                                      option.packing.type_box}{" "}
                                    -{" "}
                                    {option.packing.total_strip +
                                      " " +
                                      option.packing.type_strip}{" "}
                                    -{" "}
                                    {option.packing.total_box +
                                      " " +
                                      option.packing.type_box}
                                  </p>
                                </div>
                              </li>
                            ) || null
                          )}
                        </>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.product_id === value.product_id
                      }
                      onInputChange={(event, value) => {
                        if (value !== null) {
                          setSearchProduct(value);
                        }
                      }}
                      componentsProps={{
                        paper: { sx: { wordBreak: "break-word" } },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="mr-2">
                    <Autocomplete
                      freeSolo
                      options={
                        dataDetailProduct && !isFetchingDetailProduct
                          ? [
                              dataDetailProduct.packing.type_box,
                              dataDetailProduct.packing.type_strip,
                              dataDetailProduct.packing.type_unit,
                            ]
                          : []
                      }
                      value={formik.values.regular[i].uom}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(`regular[${i}].uom`, newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        formik.setFieldValue(
                          `regular[${i}].uom`,
                          newInputValue
                        );
                      }}
                      onOpen={() => {
                        setSelectedProduct(
                          formik.values.regular[i].product_id
                            ? formik.values.regular[i].product_id
                            : null
                        );
                      }}
                      onClose={() => setSelectedProduct(null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="UOM"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <IconButton
                      disabled={formik.values.regular[i].quantity === 0}
                      color="error"
                      onClick={() =>
                        formik.setFieldValue(
                          `regular[${i}].quantity`,
                          formik.values.regular[i].quantity - 1
                        )
                      }
                    >
                      <AiOutlineMinusCircle />
                    </IconButton>

                    <input
                      value={formik.values.regular[i].quantity}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value)))
                          formik.setFieldValue(
                            `regular[${i}].quantity`,
                            Number(e.target.value)
                          );
                      }}
                      className="max-w-[24px] text-center"
                    />
                    <IconButton
                      color="primary"
                      onClick={() =>
                        formik.setFieldValue(
                          `regular[${i}].quantity`,
                          formik.values.regular[i].quantity + 1
                        )
                      }
                    >
                      <AiOutlinePlusCircle />
                    </IconButton>
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <span className="mr-2 font-bold" style={{ width: "30px" }}>
                    S
                  </span>
                  <div className="flex-grow">
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formik.values.regular[i].signa}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `regular[${i}].signa`,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end mb-2">
                  <div className="w-1/2 flex items-center gap-2">
                    <p className="w-full text-right">Iter Resep</p>
                    <TextField
                      value={formik.values.regular[i].iter}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value)))
                          formik.setFieldValue(
                            `regular[${i}].iter`,
                            Number(e.target.value)
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-end">
              <div
                className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={() => {
                  formik.setFieldValue("regular", [
                    ...formik.values.regular,
                    {
                      product_id: 0,
                      product_name: "",
                      uom: "",
                      quantity: 0,
                      signa: "",
                      iter: 0,
                    },
                  ]);
                }}
              >
                <p className="w-full text-right">Add More</p>
                <AiOutlinePlusCircle />
              </div>
            </div>
          </div>
          <div className="w-full my-4 rounded-md border-2 p-2">
            {formik.values.concoction.map((item, i) => (
              <div className="rounded-md border-2 p-2 mb-2" key={i}>
                <div className="w-full flex justify-between mb-2">
                  <p className="font-bold">Racikan {i + 1}</p>
                  <div
                    className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 rounded-md p-1 text-white mb-2"
                    onClick={() =>
                      formik.setFieldValue("concoction", [
                        ...formik.values.concoction.slice(0, i),
                        ...formik.values.concoction.slice(i + 1),
                      ])
                    }
                  >
                    <AiOutlineMinusCircle className="w-full h-full" />
                  </div>
                </div>
                {item.product.map((concProd, productIndex) => (
                  <>
                    <div className="flex items-center mb-2">
                      <span
                        className="mr-2 font-bold"
                        style={{ width: "30px" }}
                      >
                        {productIndex === 0 ? "R/" : ""}
                      </span>
                      <div className="flex-grow mr-2">
                        <Autocomplete
                          fullWidth
                          loading={isLoading}
                          disablePortal
                          options={
                            products && products.product_data
                              ? products.product_data
                              : []
                          }
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some(
                              (option) => inputValue === option.product_name
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                product_name: inputValue,
                                product_id: 0,
                              });
                            }

                            return filtered;
                          }}
                          onOpen={() => fetchProducts("")}
                          value={
                            concProd?.product_name
                              ? {
                                  product_name:
                                    formik.values.concoction[i].product[
                                      productIndex
                                    ].product_name,
                                  product_id:
                                    formik.values.concoction[i].product[
                                      productIndex
                                    ].product_id,
                                }
                              : null
                          }
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `concoction[${i}].product[${productIndex}]`,
                              {
                                ...formik.values.concoction[i].product[
                                  productIndex
                                ],
                                product_id: newValue ? newValue.product_id : "",
                                product_name: newValue
                                  ? newValue.product_name
                                  : "",
                                uom: "",
                                quantity: 0,
                              }
                            );
                          }}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.product_name || ""
                          }
                          renderOption={(props, option) => (
                            <>
                              {typeof option === "string" ? (
                                <div>option</div>
                              ) : (
                                (
                                  <li {...props}>
                                    <div>
                                      <p>{option.product_name}</p>
                                      <p className="text-gray-500">
                                        {option.packing.total_box +
                                          " " +
                                          option.packing.type_box}{" "}
                                        -{" "}
                                        {option.packing.total_strip +
                                          " " +
                                          option.packing.type_strip}{" "}
                                        -{" "}
                                        {option.packing.total_box +
                                          " " +
                                          option.packing.type_box}
                                      </p>
                                    </div>
                                  </li>
                                ) || null
                              )}
                            </>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.product_id === value.product_id
                          }
                          onInputChange={(event, value) => {
                            if (value !== null) {
                              setSearchProduct(value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="mr-2">
                        {/* <Select
                          defaultValue=""
                          displayEmpty
                          inputProps={{ "aria-label": "UOM" }}
                          variant="outlined"
                        >
                          <MenuItem value="">UOM</MenuItem>
                          <MenuItem value={10}>Unit 1</MenuItem>
                          <MenuItem value={20}>Unit 2</MenuItem>
                        </Select> */}
                        <Autocomplete
                          freeSolo
                          options={
                            dataDetailProduct && !isFetchingDetailProduct
                              ? [
                                  dataDetailProduct.packing.type_box,
                                  dataDetailProduct.packing.type_strip,
                                  dataDetailProduct.packing.type_unit,
                                ]
                              : []
                          }
                          value={
                            formik.values.concoction[i].product[productIndex]
                              .uom
                          }
                          onChange={(event, newValue) => {
                            formik.setFieldValue(
                              `concoction[${i}].product[${productIndex}].uom`,
                              newValue
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            formik.setFieldValue(
                              `concoction[${i}].product[${productIndex}].uom`,
                              newInputValue
                            );
                          }}
                          onOpen={() => {
                            setSelectedProduct(
                              formik.values.concoction[i].product[productIndex]
                                .product_id
                                ? formik.values.concoction[i].product[
                                    productIndex
                                  ].product_id
                                : null
                            );
                          }}
                          onClose={() => setSelectedProduct(null)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="UOM"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <IconButton
                          disabled={
                            formik.values.concoction[i].product[productIndex]
                              .quantity === 0
                          }
                          color="error"
                          onClick={() =>
                            formik.setFieldValue(
                              `concoction[${i}].product[${productIndex}].quantity`,
                              formik.values.concoction[i].product[productIndex]
                                .quantity - 1
                            )
                          }
                        >
                          <AiOutlineMinusCircle />
                        </IconButton>

                        <input
                          value={
                            formik.values.concoction[i].product[productIndex]
                              .quantity
                          }
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value)))
                              formik.setFieldValue(
                                `concoction[${i}].product[${productIndex}].quantity`,
                                Number(e.target.value)
                              );
                          }}
                          className="max-w-[24px] text-center"
                        />
                        <IconButton
                          color="primary"
                          onClick={() =>
                            formik.setFieldValue(
                              `concoction[${i}].product[${productIndex}].quantity`,
                              formik.values.concoction[i].product[productIndex]
                                .quantity + 1
                            )
                          }
                        >
                          <AiOutlinePlusCircle />
                        </IconButton>
                      </div>
                      <div
                        className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 text-white p-1 rounded-md"
                        onClick={() => {
                          formik.setFieldValue(`concoction[${i}].product`, [
                            ...formik.values.concoction[i].product.slice(
                              0,
                              productIndex
                            ),
                            ...formik.values.concoction[i].product.slice(
                              productIndex + 1
                            ),
                          ]);
                        }}
                      >
                        <FaRegTrashAlt className="w-full h-full" />
                      </div>
                    </div>
                  </>
                ))}
                <div className="flex items-center justify-end mb-4">
                  <div
                    className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                    onClick={() => {
                      formik.setFieldValue(`concoction[${i}].product`, [
                        ...formik.values.concoction[i].product,
                        {
                          product_id: 0,
                          product_name: "",
                          uom: 0,
                          quantity: 0,
                        },
                      ]);
                    }}
                  >
                    <p className="w-full text-right">Add More</p>
                    <AiOutlinePlusCircle />
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <span className="mr-2 font-bold" style={{ width: "30px" }}>
                    m.f
                  </span>
                  <div className="flex-grow">
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formik.values.concoction[i].misce_fiat}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `concoction[${i}].misce_fiat`,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex items-center mb-4">
                  <span className="mr-2 font-bold" style={{ width: "30px" }}>
                    S
                  </span>
                  <div className="flex-grow">
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formik.values.concoction[i].signa}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `concoction[${i}].signa`,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end mb-2">
                  <div className="w-1/2 flex items-center gap-2">
                    <p className="w-full text-right">Iter Resep</p>
                    <TextField
                      value={formik.values.concoction[i].iter}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value)))
                          formik.setFieldValue(
                            `concoction[${i}].iter`,
                            Number(e.target.value)
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-end">
              <div
                className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={() => {
                  formik.setFieldValue("concoction", [
                    ...formik.values.concoction,
                    {
                      product: [
                        {
                          product_id: 0,
                          product_name: "",
                          uom: 0,
                          quantity: 0,
                        },
                      ],
                      misce_fiat: "",
                      signa: "",
                      iter: 0,
                    },
                  ]);
                }}
              >
                <p className="w-full text-right">Add More</p>
                <AiOutlinePlusCircle />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <p className="mb-4">Preview</p>
          <div className="p-2">
            <PrescriptionPreview
              formik={formik}
              selectedDoctor={selectedDoctor}
              selectedPatient={selectedPatient}
            />
          </div>
        </div>
      </div>
      <div className="w-1/4 flex justify-end gap-2 float-right">
        <button
          type="button"
          className="w-full px-12 border-2 rounded-md py-2 hover:bg-gray-100"
          onClick={() => {
            formik.setFieldValue("regular", filterRegular(initialRegular));
            formik.setFieldValue(
              "concoction",
              filterConcoction(initialConcoction)
            );
            setIsFormView(true);
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="w-full px-12 rounded-md py-2 bg-blue-500 disabled:bg-gray-300 text-white hover:bg-blue-700"
          onClick={handleSave}
          // disabled={!formik.isValid || isLoading}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default DoctorConcoction;
