import React, { useState, useEffect, useMemo } from "react";
import { usePapaParse } from "react-papaparse";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Paper,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";

import { sipnapTable, fetchSipnap } from "../../../../services/ReportingApi";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const FETCH_LIMIT = 10;

const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const Sipnap = () => {
  const { jsonToCSV } = usePapaParse();
  const [offset, setOffset] = useState(1);
  const [drugCategory, setDrugCategory] = useState(1);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { data: sipnapTableData, mutate: mutateSipnapTable } = useMutation({
    mutationFn: sipnapTable,
  });

  const {
    data: sipnapData,
    mutate: mutateSipnap,
    isLoading: isMutateSipnapLoading,
  } = useMutation({
    mutationFn: fetchSipnap,
  });

  const pageCount = useMemo(() => {
    if (sipnapTableData !== undefined)
      return Math.ceil(sipnapTableData.count / FETCH_LIMIT);
    else return 0;
  }, [sipnapTableData]);

  const handleJsonToCsvData = (csvData) => {
    const csv = jsonToCSV(csvData);
    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "history-shift-OLIN.csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    mutateSipnapTable({
      kategori_obat: drugCategory,
      start_date: startDate,
      end_date: endDate,
      limit: FETCH_LIMIT,
      offset,
    });
  }, [startDate, endDate, offset, drugCategory]);

  useEffect(() => {
    mutateSipnap({
      kategori_obat: drugCategory,
      start_date: startDate,
      end_date: endDate,
      limit: FETCH_LIMIT,
      offset,
    });
  }, [startDate, endDate, offset, drugCategory]);

  return (
    <div className="container w-full">
      <LoadingBackdrop isLoading={isMutateSipnapLoading} />
      <div className="grid  grid-cols-1 lg:grid-cols-2 mt-4 justify-center gap-5">
        {sipnapData && sipnapData !== undefined ? (
          <div className="p-3 border rounded-lg">
            <p className="text-blue-500 font-bold">Penjualan Obat</p>
            <hr />
            <div className="flex flex-row justify-between items-center gap-5 mt-3">
              <p>Kategori Obat</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Kategori Obat
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="category_id"
                  label="Kategori Obat"
                  value={drugCategory}
                  onChange={(e) =>
                    setDrugCategory(parseInt(e.target.value, 10))
                  }
                >
                  <MenuItem value={1}>Semua Obat</MenuItem>
                  <MenuItem value={2}>Narkotika</MenuItem>
                  <MenuItem value={3}>Psikotropika</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-wrap justify-between items-center mt-3">
              <p>Periode Pelaporan</p>
            </div>
            <div className="flex justify-between items-center text-[13px] md:text-base text-center rounded-md">
              <p>Tanggal Mulai</p>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={startDate}
                  onChange={(newDate) => setStartDate(toApiDate(newDate))}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex justify-between items-center text-[13px] md:text-base text-center rounded-md mt-[10px]">
              <p>Tanggal Akhir</p>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={endDate}
                  onChange={(newDate) => setEndDate(toApiDate(newDate))}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </LocalizationProvider>
            </div>
            <p className="text-center my-4 font-bold text-blue-500 text-[30px]">{`${sipnapData.total_obat} Items`}</p>
            <div className="flex flex-row items-center justify-between font-bold">
              <p>{`Narkotika : ${
                Object.keys(sipnapData.narkotika).length !== 0
                  ? sipnapData.narkotika.total
                  : 0
              }`}</p>
            </div>
            <div className="flex flex-row items-center justify-between font-bold mt-4">
              <p>{`Psikotropika : ${
                Object.keys(sipnapData.psikotropika).length !== 0
                  ? sipnapData.psikotropika.total
                  : 0
              }`}</p>
            </div>
          </div>
        ) : null}
        <div className="p-3 border rounded-lg">
          <p className="text-blue-500 font-bold">Narkotika</p>
          <hr />
          {sipnapData &&
          sipnapData !== undefined &&
          sipnapData?.list_narkotika !== null ? (
            sipnapData.list_narkotika.map((item, index) => {
              return (
                <div
                  className="flex flex-row items-center justify-between my-2"
                  key={index}
                >
                  <p>{item.product_name}</p>
                  <p>{item.stock}</p>
                </div>
              );
            })
          ) : (
            <p>tidak ada data</p>
          )}

          <p className="text-blue-500 font-bold mt-3">Psikotropika</p>
          <hr />

          {sipnapData &&
          sipnapData !== undefined &&
          sipnapData?.list_psikotropika !== null ? (
            sipnapData.list_psikotropika.map((item, index) => {
              return (
                <div
                  className="flex flex-row items-center justify-between my-2"
                  key={index}
                >
                  <p>{item.product_name}</p>
                  <p>{item.stock}</p>
                </div>
              );
            })
          ) : (
            <p> tidak ada data</p>
          )}
        </div>
      </div>

      {sipnapTableData &&
      sipnapTableData !== undefined &&
      sipnapTableData?.data !== null ? (
        <>
          <div className="mt-4 flex flex-col lg:flex-row justify-between items-center gap-3">
            <h1 className="font-bold text-center lg:text-start">
              Laporan Data Sediaan Narkotika & Psikotropika
            </h1>
            <button
              className="p-2 px-5 bg-[#007AF1] text-white rounded-lg mb-[10px]"
              onClick={() => handleJsonToCsvData(sipnapTableData?.data)}
            >
              Export
            </button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="text-[10px]">
                <TableRow sx={{ backgroundColor: "#007AF1" }}>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Bulan
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Tahun
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Jenis
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Jumlah Pemasukan
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Jumlah Pengeluaran
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    Stock Akhir
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sipnapTableData.data.map((data, index) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {data.bulan}
                    </TableCell>
                    <TableCell align="center">{data.tahun}</TableCell>
                    <TableCell align="center">{data.produk}</TableCell>
                    <TableCell align="center">{data.jenis}</TableCell>
                    <TableCell align="center">{data.total_pemasukan}</TableCell>
                    <TableCell align="center">
                      {data.total_pengeluaran}
                    </TableCell>
                    <TableCell align="center">{data.stok_akhir}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex justify-center mt-[10px]">
            <Pagination
              count={pageCount}
              page={offset}
              onChange={(_, value) => setOffset(value)}
              shape="rounded"
              size="large"
              color="primary"
            />
          </div>
        </>
      ) : (
        <div className="flex justify-center mt-3">
          <p>tidak ada data</p>
        </div>
      )}
    </div>
  );
};

export default Sipnap;
