import { useState, useMemo, useCallback, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  AiFillCheckCircle,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import {
  getDetailSubscription,
  getSubscription,
  verifySubscriptionCode,
} from "../../../services/authApi";
import AccountProfileNavigation from "./AccountProfileNavigation";
import SubscriptionHistory from "./SubscriptionHistory";
import { Button, CircularProgress, TextField } from "@mui/material";

const FETCH_LIMIT = 3;

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const Subscription = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [activationCode, setActivationCode] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data: dataCode, isFetching } = useQuery({
    queryKey: ["verify-code", isCheck, activationCode],
    queryFn: () => verifySubscriptionCode(activationCode),
    enabled: isCheck && activationCode !== "",
    refetch: false,
    onSuccess: () => {
      setIsCheck(false);
      setSuccessMessage("Voucher berhasil digunakan");
      setErrorMessage("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    onError: () => {
      setIsCheck(false);
      setErrorMessage("Voucher tidak dapat digunakan");
      setSuccessMessage("");
    },
  });

  const { data: subscription } = useQuery({
    queryKey: ["subscription", offset],
    queryFn: () => getSubscription(offset, FETCH_LIMIT),
    keepPreviousData: true,
  });

  const { data: detailSubscription } = useQuery({
    queryKey: ["detail-subscription"],
    queryFn: () => getDetailSubscription(),
  });

  const isNextCarouselDisabled = useMemo(() => {
    if (!subscription) return true;

    return !subscription.hash_next;
  }, [offset, subscription]);

  const isPreviousCarouselDisabled = useMemo(
    () => offset === 1,
    [offset, subscription]
  );

  const handleNextCarousel = useCallback(() => {
    setOffset((previousValue) => previousValue + 1);
  }, []);

  const handlePreviousCarousel = useCallback(() => {
    setOffset((previousValue) => previousValue - 1);
  }, []);

  return (
    <div>
      {detailSubscription ? (
        <div>
          <AccountProfileNavigation />
          <div className="bg-[#F0F2F4] w-full font-bold p-3 rounded-lg">
            <p>
              Masa Berlangganan Anda akan berakhir dalam{" "}
              {detailSubscription.will_end}
            </p>
          </div>
          <div className="w-full mt-4">
            <p className="font-bold text-[20pxa]">Langganan Aktif</p>
            <div className="border border-black w-full"></div>
          </div>
          <div className="flex p-3">
            <div className="flex flex-col font-bold">
              <p>Paket langganan saat ini</p>
              <p>Pembayaran Terakhir</p>
              <p>Tanggal Kadaluarsa</p>
            </div>
            <div className="ml-3 flex flex-col">
              <p>:&nbsp;&nbsp;&nbsp;{detailSubscription.type_subs}</p>
              <p>
                :&nbsp;&nbsp;&nbsp;{toDate(detailSubscription.last_payment)}
              </p>
              <p>
                :&nbsp;&nbsp;&nbsp;{toDate(detailSubscription.expired_date)}
              </p>
            </div>
          </div>

          <div className="mt-4">
            <p className="font-bold text-[20px]">Kode Aktivasi Langganan</p>
            <div className="mt-2 border-2 p-2 flex gap-2">
              <TextField
                fullWidth
                placeholder="Masukan kode aktivasi langganan"
                onChange={(e) => {
                  setActivationCode(e.target.value);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
              <Button
                variant="contained"
                onClick={() => setIsCheck(true)}
                disabled={isFetching}
              >
                {isFetching ? <CircularProgress size={20} /> : "Verifikasi"}
              </Button>
            </div>
          </div>
          <p
            className={`mt-2 mb-4 ${
              errorMessage !== "" && successMessage === ""
                ? "text-red-500"
                : "text-green-500"
            }`}
          >
            {errorMessage || successMessage}
          </p>

          <div>
            <p className="font-bold text-[20px]">Paket Langganan</p>
            <div className="border border-black"></div>
          </div>
          <div className="w-full mt-3 flex justify-center">
            {subscription && subscription !== undefined
              ? subscription.detail?.map((subs, index) => (
                  <div
                    className="w-[30%] mt-3 border border-black p-3 rounded-lg flex flex-col mx-3"
                    key={index}
                  >
                    <div className="h-full flex">
                      <img src={subs.media} className="mb-3" />
                    </div>
                    <div className="font-bold">
                      <p className="text-[20px]">{subs.name}</p>
                      <p className="line-through text-[12px] my-2 text-[#404040]">
                        {subs.price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                      <p className="text-[#00CD52]">
                        {subs.price_after_discount.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}{" "}
                        /{subs.periode_name}
                      </p>
                      <p className="my-3 text-[12px] text-[#404040]">
                        Diskon {subs.discount}% Hemat{" "}
                        {(
                          subs.price - subs.price_after_discount
                        ).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                      {subs.description.map((desc, idx) => (
                        <div className="flex items-center" key={idx}>
                          <AiFillCheckCircle className="mr-2 text-[#407BFF] text-[25px]" />
                          <p className="text-[12px]">{desc}</p>
                        </div>
                      ))}
                    </div>
                    <button
                      className="bg-[#007AF1] text-white font-bold p-2 rounded-md mt-3"
                      onClick={() =>
                        navigate(`/subscription/checkout/${subs.id}`)
                      }
                    >
                      Langganan
                    </button>
                  </div>
                ))
              : null}
          </div>
          <div className="flex justify-center items-center mt-[10px]">
            <button
              onClick={handlePreviousCarousel}
              disabled={isPreviousCarouselDisabled}
              className="p-2 bg-blue-500 rounded-md text-white disabled:bg-gray-500"
            >
              <AiOutlineArrowLeft className="font-bold" />
            </button>
            <p className="mx-3">{offset}</p>
            <button
              onClick={handleNextCarousel}
              disabled={isNextCarouselDisabled}
              className="p-2 bg-blue-500 rounded-md text-white disabled:bg-gray-500"
            >
              <AiOutlineArrowRight className="font-bold" />
            </button>
          </div>
          <SubscriptionHistory />
        </div>
      ) : null}
    </div>
  );
};

export default Subscription;
