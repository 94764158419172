import React, { useState, createContext } from "react";

export const PaymentContext = createContext();

const PaymentStatusProvider = ({ children }) => {
  const [paymentPayload, setPaymentPayload] = useState({
    sukses: false,
    status: 1,
    total: 0,
    bayar_sebelum: "",
  });

  const [transaction, setTransaction] = useState({});
  const [billNumber, setBillNumber] = useState("");
  const [invoiceType, setInvoiceType] = useState(0);

  return (
    <PaymentContext.Provider
      value={{
        paymentPayload,
        setPaymentPayload,
        transaction,
        setTransaction,
        billNumber,
        setBillNumber,
        invoiceType,
        setInvoiceType,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentStatusProvider;
