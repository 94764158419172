import React, { useCallback, useContext } from "react";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TableRow,
  Paper,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { getCategory } from "../../../services/InventoryAPI";

const Category = (props) => {
  const { offset, status, listId, setListId } = props;
  const { data: categories } = useQuery({
    queryKey: ["category"],
    queryFn: () => getCategory(10000, offset, status),
  });

  const handleSelectList = useCallback(
    (selectedId) => {
      const selectedValue = listId.findIndex((id) => id === selectedId);

      let updatedList = cloneDeep(listId);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setListId(updatedList);
    },
    [listId]
  );

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-category="simple table">
        <TableBody>
          {categories && categories !== undefined ? (
            <>
              {categories.map((category) => (
                <TableRow
                  key={category.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ paddingY: 0 }}>
                    <Checkbox
                      onClick={() => {
                        handleSelectList(category.id);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0 }}
                    component="th"
                    scope="category"
                  >
                    {category.name}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Category;
