import { useState, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Pagination,
  IconButton,
  TextField,
  Snackbar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  getStockOpnameSession,
  createStockOpnameSession,
} from "../../../../services/InventoryAPI";
import BreadcrumbInventory from "../BreadcrumbInventory";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD/MM/YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const StockOpname = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();
  const [offset, setOffset] = useState(1);
  const [status, setStatus] = useState(0);
  const [period, setPeriod] = useState("");
  const [isAddStockModalOpen, setIsAddStockModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [periodBuffer, setPeriodBuffer] = useState(0);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  const {
    data: stockOpnameSessionData,
    isLoading: isStockOpnameSessionLoading,
    refetch: refetchStockOpname,
  } = useQuery({
    queryKey: ["stock-opname", offset, status, period, location.pathname],
    queryFn: () =>
      getStockOpnameSession(
        offset,
        FETCH_LIMIT,
        status,
        toApiDate(startDate) === "Invalid date" ? "" : toApiDate(startDate),
        toApiDate(endDate) === "Invalid date" ? "" : toApiDate(endDate),
        period,
        isWarehouse ? isWarehouse : false
      ),
    enabled: period === 7 || startDate === "" || endDate === "",
  });

  const { mutate: mutateCreateSessionStockopname } = useMutation({
    mutationFn: createStockOpnameSession,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stock-opname"] });
      setIsAddStockModalOpen(false);
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const pageCount = useMemo(() => {
    if (stockOpnameSessionData && stockOpnameSessionData !== undefined)
      return Math.ceil(stockOpnameSessionData.Count / FETCH_LIMIT);
    else return 0;
  }, [stockOpnameSessionData]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <BreadcrumbInventory isWarehouse={isWarehouse} />
        <div>
          <button
            className="bg-[#007AF1] font-bold py-2 px-4 rounded-md text-white"
            onClick={() => {
              //              if (stockOpnameSessionData?.title_id)
              setIsAddStockModalOpen(true);
            }}
          >
            Buka Sesi Baru
          </button>
        </div>
      </div>
      {isStockOpnameSessionLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full my-3 ">
            <FormControl fullWidth sx={{ marginRight: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <MenuItem value={0}>Semua Status</MenuItem>
                <MenuItem value={1}>Completed</MenuItem>
                <MenuItem value={2}>In Progress</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Periode</InputLabel>
              <Select
                label="Periode"
                value={periodBuffer}
                onChange={(e) => {
                  if (e.target.value === 7) {
                    setIsDateModalOpen((prevBool) => !prevBool);
                  } else if (e.target.value !== 7) {
                    setIsDateModalOpen(false);
                    setStartDate("");
                    setEndDate("");
                  } else if (e.target.value === 10) {
                    return;
                  }
                  setPeriod(e.target.value);
                  setPeriodBuffer(e.target.value);
                }}
              >
                <MenuItem value={0}>Semua Tanggal</MenuItem>
                <MenuItem value={1}>Minggu Berjalan</MenuItem>
                <MenuItem value={2}>Minggu Lalu</MenuItem>
                <MenuItem value={3}>Bulan Berjalan</MenuItem>
                <MenuItem value={4}>Bulan Lalu</MenuItem>
                <MenuItem value={5}>Tahun Berjalan</MenuItem>
                <MenuItem value={6}>Tahun Lalu</MenuItem>
                <MenuItem value={7}>Pilih Tanggal</MenuItem>
                <MenuItem value={8}>Hari ini</MenuItem>
                <MenuItem value={9}>Kemarin</MenuItem>
                <MenuItem value={10} sx={{ display: "none" }} disabled>
                  {toDate(startDate) + " - " + toDate(endDate)}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {stockOpnameSessionData &&
          stockOpnameSessionData !== undefined &&
          stockOpnameSessionData?.Data !== null ? (
            <div>
              <TableContainer component={Paper} fullWidth>
                <Table aria-label="simple table">
                  <TableHead
                    sx={{
                      backgroundColor: "#007AF1",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        ID
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Tanggal Penyesuaian
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Nama Sesi Stock Opname
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Waktu Selesai
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ color: "white", fontWeight: "bold" }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stockOpnameSessionData.Data.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ color: "#007AF1" }} align="center">
                          <p
                            className="hover:cursor-pointer hover:underline"
                            onClick={() =>
                              navigate(
                                `${
                                  isWarehouse ? "/central-warehouse" : ""
                                }/inventory/stock-opname/stock-opname-recap/${data.ps_id}`
                              )
                            }
                          >
                            {data.ps_id}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {toDate(data.created_at)}
                        </TableCell>
                        <TableCell align="center">
                          {data.session_name}
                        </TableCell>
                        <TableCell align="center">
                          {data.end_date === "-"
                            ? data.end_date
                            : toDate(data.end_date)}
                        </TableCell>
                        <TableCell align="center">
                          {data.status === 1 ? "Completed" : "In Progress"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <>
                            <button
                              className="bg-red-500 rounded-md p-2 text-white mr-3 disabled:bg-gray-500"
                              disabled={data.status === 1}
                              onClick={() =>
                                navigate(
                                  `${
                                    isWarehouse ? "/central-warehouse" : ""
                                  }/inventory/stock-opname/stock-opname-recap/${data.ps_id}`
                                )
                              }
                            >
                              Rekap SO
                            </button>
                            <button
                              className="bg-blue-500 rounded-md p-2 text-white disabled:bg-gray-500"
                              disabled={data.status === 1}
                              onClick={() =>
                                navigate(
                                  `${
                                    isWarehouse ? "/central-warehouse" : ""
                                  }/inventory/stock-opname/create/${data.ps_id}`
                                )
                              }
                            >
                              Mulai SO
                            </button>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex justify-center mt-[10px]">
                <Pagination
                  count={pageCount}
                  page={offset}
                  onChange={(_, value) => setOffset(value)}
                  shape="rounded"
                  size="large"
                  color="primary"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center w-full mt-3">
              <p>Tidak ditemukan</p>
            </div>
          )}
        </>
      )}

      {stockOpnameSessionData && stockOpnameSessionData !== undefined ? (
        <Dialog
          open={isAddStockModalOpen}
          onClose={() => {
            setIsAddStockModalOpen(false);
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <p>New SO Session</p>
            <p
              className="hover:cursor-pointer"
              onClick={() => {
                setIsAddStockModalOpen(false);
              }}
            >
              X
            </p>
          </DialogTitle>
          <DialogContent>
            <div className="mt-3">
              {stockOpnameSessionData.title_id ? (
                <div className="flex w-full mt-3">
                  <p>Fitur ini hanya bisa diakses oleh Owner</p>
                </div>
              ) : (
                <div className="flex w-full mt-3">
                  <TextField
                    fullWidth
                    label="Nama Session"
                    value={sessionName}
                    onChange={(e) => setSessionName(e.target.value)}
                  />
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex justify-end w-full">
              {stockOpnameSessionData.title_id ? (
                <button
                  className="bg-[#007AF1] rounded-md px-3 py-1.5 text-white font-bold"
                  onClick={() => setIsAddStockModalOpen(false)}
                >
                  Ok
                </button>
              ) : (
                <button
                  className="bg-[#007AF1] rounded-md px-3 py-1.5 text-white font-bold disabled:bg-gray-200"
                  disabled={sessionName.length <= 0}
                  onClick={() =>
                    mutateCreateSessionStockopname({
                      session_name: sessionName,
                      warehouse: isWarehouse ? isWarehouse : false,
                    })
                  }
                >
                  Save
                </button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      ) : null}
      <Dialog
        open={isAddStockModalOpen}
        onClose={() => {
          setIsAddStockModalOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <p>New SO Session</p>
          <p
            className="hover:cursor-pointer"
            onClick={() => {
              setIsAddStockModalOpen(false);
            }}
          >
            X
          </p>
        </DialogTitle>
        <DialogContent>
          {stockOpnameSessionData && stockOpnameSessionData !== undefined ? (
            <div className="mt-3">
              {stockOpnameSessionData.title_id ? (
                <div className="flex w-full mt-3">
                  <p>Fitur ini hanya bisa diakses oleh Owner</p>
                </div>
              ) : (
                <div className="flex w-full mt-3">
                  <TextField
                    fullWidth
                    label="Nama Session"
                    value={sessionName}
                    onChange={(e) => setSessionName(e.target.value)}
                  />
                </div>
              )}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          {stockOpnameSessionData && stockOpnameSessionData !== undefined ? (
            <div className="flex justify-end w-full">
              {stockOpnameSessionData.title_id ? (
                <button
                  className="bg-[#007AF1] rounded-md px-3 py-1.5 text-white font-bold"
                  onClick={() => setIsAddStockModalOpen(false)}
                >
                  Ok
                </button>
              ) : (
                <button
                  className="bg-[#007AF1] rounded-md px-3 py-1.5 text-white font-bold disabled:bg-gray-200"
                  disabled={sessionName.length <= 0}
                  onClick={() =>
                    mutateCreateSessionStockopname({
                      session_name: sessionName,
                      warehouse: isWarehouse ? isWarehouse : false,
                    })
                  }
                >
                  Save
                </button>
              )}
            </div>
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
          setPeriodBuffer(0);
          setPeriod(0);
        }}
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              refetchStockOpname();
              setIsDateModalOpen(false);
              setPeriodBuffer(10);
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default StockOpname;
