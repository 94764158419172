import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";

const Categories = () => {
  const navigate = useNavigate();

  const { categoriesProduct } = useContext(SelectedProductContext);

  return (
    <div>
      <div className="bg-gradient-to-r from-[#FFEDA0] to-[#FFA585] p-3 mt-3 rounded-lg">
        <div className="flex justify-between items-center mb-3">
          <h1 className="font-bold text-[20px]">Berdasarkan Kategori</h1>
          <p
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={() => navigate("/purchase/categories")}
          >
            Lihat semua
          </p>
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          navigation
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          modules={[Navigation]}
          className="flex justify-center items-center w-full grid grid-cols-1"
        >
          {categoriesProduct && categoriesProduct !== undefined
            ? categoriesProduct.map((category, index) => (
                <SwiperSlide
                  //className="bg-white p-3 rounded-lg flex justify-center items-center flex-col"
                  className={`bg-white p-3 rounded-lg flex justify-center items-center flex-wrap border border-black hover:cursor-pointer ${
                    category.logo !== "" ? "content-between" : "content-end"
                  }`}
                  key={index}
                >
                  <img
                    src={category.logo}
                    alt=""
                    className="w-[80%] rounded-lg hover:cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/purchase/category-detail/${category.product_category_id}`,
                      )
                    }
                  />
                  <p className="mt-3 font-bold text-center">
                    {category.category_name}
                  </p>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>
    </div>
  );
};

export default Categories;
