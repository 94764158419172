import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { Search as SearchIcon } from "@mui/icons-material";
import moment from "moment";

import { getOperationalCostList } from "../../../../services/operationalCostApi";
import useDebounce from "../../../hooks/useDebounce";

const FETCH_LIMIT = 10;

const toDate = (date) =>
  moment.utc(date, "YYYY-MM-DD HH:mm:ss.SSS").format("DD MMMM YYYY");
const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const OperationalCost = () => {
  const navigate = useNavigate();
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [resBody, setResBody] = useState({
    limit: FETCH_LIMIT,
    offset: 1,
    operational_name: "",
    start_date: "",
    end_date: "",
    period: 0,
  });
  const [periodBuffer, setPeriodBuffer] = useState(0);

  const debounce = useDebounce(resBody.operational_name, 500);

  const { mutate: mutateOperationCostList, data: operationalCostData } =
    useMutation({
      mutationFn: getOperationalCostList,
    });

  const pageCount = useMemo(() => {
    if (operationalCostData && operationalCostData !== undefined)
      return Math.ceil(operationalCostData.count / FETCH_LIMIT);
    else return 0;
  }, [operationalCostData]);

  useEffect(() => {
    mutateOperationCostList({
      ...resBody,
      operational_name: debounce,
    });
  }, [debounce, mutateOperationCostList, resBody.period, resBody.offset]);

  return (
    <div>
      <div className="flex mb-3">
        <TextField
          value={resBody.operational_name}
          onChange={(e) => {
            setResBody((prevValue) => ({
              ...prevValue,
              operational_name: e.target.value,
            }));
          }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="search operational cost (by Operational Name)"
        />
        <FormControl fullWidth sx={{ marginX: 3 }}>
          <InputLabel>Period</InputLabel>
          <Select
            value={periodBuffer}
            label="Period"
            onChange={(e) => {
              setPeriodBuffer(e.target.value);
              if (e.target.value === 2) {
                setResBody((prevValue) => ({
                  ...prevValue,
                  start_date: "",
                  end_date: "",
                }));
                setIsDateModalOpen(true);
                return;
              }
              setResBody((prevValue) => ({
                ...prevValue,
                period: e.target.value,
              }));
            }}
          >
            <MenuItem value={0}>Terbaru</MenuItem>
            <MenuItem value={1}>Terlama</MenuItem>
            <MenuItem value={2}>Pilih Tanggal</MenuItem>
            <MenuItem value={3} disabled sx={{ display: "none" }}>
              {toDate(resBody.start_date) + " - " + toDate(resBody.end_date)}
            </MenuItem>
          </Select>
        </FormControl>
        <button
          className="bg-blue-500 rounded-md text-white font-bold px-4 py-2 w-[60%]"
          onClick={() => navigate("/operational/create")}
        >
          Create Operational Cost
        </button>
      </div>
      {operationalCostData &&
      operationalCostData !== undefined &&
      operationalCostData?.data !== null ? (
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#007AF1" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Operational Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Tanggal Operational
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Operational Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {operationalCostData.data.map((data, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    align="center"
                    sx={{ color: "#007AF1" }}
                  >
                    {data.operational_name}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {toDate(data.operational_date)}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {data.operational_cost.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {operationalCostData &&
      operationalCostData !== undefined &&
      operationalCostData?.data !== null ? (
        <div className="flex justify-center mt-3">
          <Pagination
            count={pageCount}
            page={resBody.offset}
            onChange={(_, value) =>
              setResBody((prevValue) => ({
                ...prevValue,
                offset: value,
              }))
            }
            shape="rounded"
            size="large"
            color="primary"
          />
        </div>
      ) : null}
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
        }}
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={resBody.start_date}
            endDate={resBody.end_date}
            onChange={(dates) => {
              const [start, end] = dates;
              setResBody((prevValue) => ({
                ...prevValue,
                start_date: start,
                end_date: end,
              }));
            }}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              mutateOperationCostList({
                ...resBody,
                start_date: toApiDate(resBody.start_date),
                end_date: toApiDate(resBody.end_date),
                period: 2,
              });
              setIsDateModalOpen(false);
              setResBody((prevValue) => ({
                ...prevValue,
                start_date: toApiDate(prevValue.start_date),
                end_date: toApiDate(prevValue.end_date),
                period: 2,
              }));
              setPeriodBuffer(3);
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OperationalCost;
