import React, { useState, useCallback, useContext } from "react";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { TextField } from "@mui/material";
import { cloneDeep } from "lodash";
import { VoucherProductContext } from "../../../../context/VoucherProductProvider";

const VoucherPurchaseCart = (props) => {
  const {
    voucherPurchaseData,
    setIsDetailVoucherOpen,
    setVoucherId,
    cartDetailData,
    countCart,
  } = props;

  const [isMultipleVoucherSelected, setIsMultipleVoucherSelected] =
    useState(false);

  const { selectedVoucher, setSelectedVoucher } = useContext(
    VoucherProductContext
  );

  const dataPrincipalId =
    cartDetailData && cartDetailData !== undefined
      ? cartDetailData.cart_regular?.map((data) => data.principal_id)
      : [];

  const dataProductId =
    cartDetailData && cartDetailData !== undefined
      ? cartDetailData.cart_regular?.map((data) => data.product_id)
      : [];

  const useableVoucher =
    voucherPurchaseData && voucherPurchaseData !== undefined
      ? voucherPurchaseData.filter(
          (data) =>
            data.voucher_combine === true &&
            data.voucher_value_detail?.voucher_min <= countCart?.total &&
            data.voucher_type !== 3
        )
      : [];

  const uncombinedVoucher =
    voucherPurchaseData && voucherPurchaseData !== undefined
      ? voucherPurchaseData.filter(
          (data) =>
            data.voucher_combine === false &&
            data.voucher_value_detail?.voucher_min <= countCart?.total
        )
      : [];

  const unuseableVoucher =
    voucherPurchaseData && voucherPurchaseData !== undefined
      ? voucherPurchaseData.filter(
          (data) =>
            data.voucher_value_detail?.voucher_min > countCart?.total ||
            data.voucher_product?.some((id) =>
              dataPrincipalId?.includes(id)
            ) === false ||
            data.voucher_product?.some((id) => dataProductId?.includes(id)) ===
              false
        )
      : [];

  const handleSelectVoucherCombined = useCallback(
    (voucher) => {
      const selectedValue = selectedVoucher.voucherId.findIndex(
        (selectedIndex) => selectedIndex === voucher.voucher_id
      );
      let updatedId = cloneDeep(selectedVoucher.voucherId);
      let updatedPrice = cloneDeep(selectedVoucher.voucherPrice);
      if (selectedValue !== -1) {
        updatedId.splice(selectedValue, 1);
        updatedPrice.splice(selectedValue, 1);
        setSelectedVoucher((prevValue) => ({
          ...prevValue,
          voucherPayment: prevValue.voucherPayment.filter(
            (item) => !voucher.voucher_payment.includes(item)
          ),
        }));
      } else {
        updatedId.push(voucher.voucher_id);
        updatedPrice.push(voucher.voucher_value_detail?.voucher_value);
        setSelectedVoucher((prevValue) => ({
          ...prevValue,
          voucherPayment: prevValue.voucherPayment.concat(
            voucher.voucher_payment
          ),
        }));
      }
      setSelectedVoucher((prevValue) => ({
        ...prevValue,
        voucherId: updatedId,
        voucherPrice: updatedPrice,
      }));
    },
    [selectedVoucher]
  );

  return (
    <div className="text-[12px]">
      {voucherPurchaseData && voucherPurchaseData !== undefined ? (
        <React.Fragment>
          <div className="flex w-full my-3">
            <TextField
              fullWidth
              label="Kode Promo"
              // onChange={(e) => {
              //   setSelectedPromo({
              //     ...selectedPromo,
              //     voucherCode: e.target.value,
              //   });
              // }}
              // value={selectedPromo.voucherCode}
              // inputProps={{ style: { textTransform: "uppercase" } }}
              // helperText={trigger ? "test" : null}
              // error={trigger}
            />
            <button
              // className={`font-bold p-3 rounded-md ml-5 ${
              //   selectedPromo.voucherCode === ""
              //     ? "bg-gray-100 text-gray-400"
              //     : "bg-[#007AF1] text-white"
              // }`}
              className="font-bold p-3 rounded-md ml-5 bg-gray-100 text-gray-400"
            >
              Pakai
            </button>
          </div>
          <div>
            {useableVoucher.map((data, index) => (
              <div
                className={`w-full rounded-md p-2 font-semibold my-3 flex justify-between items-center hover:cursor-pointer ${
                  selectedVoucher.voucherId.includes(data.voucher_id)
                    ? "bg-[#E3F1FF] border-2 border-[#007AF1]"
                    : "bg-white border border-black"
                }`}
                key={index}
                onClick={() => {
                  if (isMultipleVoucherSelected) {
                    setSelectedVoucher((prevValue) => ({
                      ...prevValue,
                      voucherId: [data.voucher_id],
                      voucherPrice: [data.voucher_value_detail?.voucher_value],
                      voucherPayment: prevValue.voucherPayment.concat(
                        data.voucher_payment
                      ),
                    }));
                    setIsMultipleVoucherSelected(false);
                  } else {
                    handleSelectVoucherCombined(data);
                  }
                }}
              >
                <div>
                  <p>{data.voucher_name}</p>
                  <div className="flex my-2">
                    <p>Kode Voucher:&nbsp;</p>
                    <p className="text-[#007AF1]">{data.voucher_code}</p>
                  </div>
                  <div className="flex items-center w-full">
                    <BiTimeFive className="text-[25px]" />
                    <p className="mx-2">
                      Berakhir dalam {data.voucher_end} hari lagi
                    </p>
                    <p
                      className="text-[#007AF1]"
                      onClick={() => {
                        setIsDetailVoucherOpen(true);
                        setVoucherId(data.voucher_id);
                      }}
                    >
                      Lihat Detail
                    </p>
                  </div>
                  <div className="flex items-center w-full">
                    <BsFillExclamationTriangleFill />
                    <p className="mx-2">PROMO {data.voucher_message}</p>
                  </div>
                </div>
                {selectedVoucher.voucherId.includes(data.voucher_id) ? (
                  <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                ) : null}
              </div>
            ))}
            <p>
              Voucher tidak dapat digunakan bersamaan dengan voucher yang lain
            </p>
            {uncombinedVoucher.map((data, index) => (
              <div
                className={`w-full rounded-md p-2 font-semibold my-3 flex justify-between items-center hover:cursor-pointer ${
                  selectedVoucher.voucherId.includes(data.voucher_id)
                    ? "bg-[#E3F1FF] border-2 border-[#007AF1]"
                    : "bg-white border border-black"
                }`}
                key={index}
                onClick={() => {
                  if (selectedVoucher.voucherId.includes(data.voucher_id))
                    setSelectedVoucher((prevValue) => ({
                      ...prevValue,
                      voucherId: [],
                      voucherPrice: [0],
                      voucherPayment: [],
                    }));
                  else
                    setSelectedVoucher((prevValue) => ({
                      ...prevValue,
                      voucherId: [data.voucher_id],
                      voucherPrice: [data.voucher_value_detail?.voucher_value],
                      voucherPayment: data.voucher_payment,
                    }));
                  setIsMultipleVoucherSelected(true);
                }}
              >
                <div>
                  <p>{data.voucher_name}</p>
                  <div className="flex my-2">
                    <p>Kode Voucher:&nbsp;</p>
                    <p className="text-[#007AF1]">{data.voucher_code}</p>
                  </div>
                  <div className="flex items-center w-full">
                    <BiTimeFive className="text-[25px]" />
                    <p className="mx-2">
                      Berakhir dalam {data.voucher_end} hari lagi
                    </p>
                    <p
                      className="text-[#007AF1]"
                      onClick={() => {
                        setIsDetailVoucherOpen(true);
                        setVoucherId(data.voucher_id);
                      }}
                    >
                      Lihat Detail
                    </p>
                  </div>
                  <div className="flex items-center w-full">
                    <BsFillExclamationTriangleFill />
                    <p className="mx-2">PROMO {data.voucher_message}</p>
                  </div>
                </div>
                {selectedVoucher.voucherId.includes(data.voucher_id) ? (
                  <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                ) : null}
              </div>
            ))}
            <p>Voucher belum bisa dipakai</p>
            {unuseableVoucher.map((data, index) => (
              <div
                className="w-full rounded-md p-2 font-semibold my-3 flex justify-between items-center bg-gray-300 border border-black"
                key={index}
              >
                <div>
                  <p>{data.voucher_name}</p>
                  <div className="flex my-2">
                    <p>Kode Voucher:&nbsp;</p>
                    <p className="text-[#007AF1]">{data.voucher_code}</p>
                  </div>
                  <div className="flex items-center w-full">
                    <BiTimeFive className="text-[25px]" />
                    <p className="mx-2">
                      Berakhir dalam {data.voucher_end} hari lagi
                    </p>
                    <p
                      className="text-[#007AF1] hover:cursor-pointer"
                      onClick={() => {
                        setIsDetailVoucherOpen(true);
                        setVoucherId(data.voucher_id);
                      }}
                    >
                      Lihat Detail
                    </p>
                  </div>
                  <div className="flex items-center w-full">
                    <BsFillExclamationTriangleFill className="text-[25px]" />
                    <p className="mx-2">PROMO {data.voucher_message}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default VoucherPurchaseCart;
