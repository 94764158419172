import { TextField, InputAdornment } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { BiLockAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import VerificationOTP from "../../../../assets/images/verifikasi OTP 1.jpg";
import { changePassword } from "../../../../services/authApi";

const validationSchema = yup.object({
  email: yup.string().required("Email wajib diisi"),
  oldPassword: yup.string().required().min(8, "Password minimal 8 karakter"),
  new_password: yup
    .string()
    .min(8, "Password minimal 8 karakter")
    .required("Password wajib diisi"),
  current_password: yup
    .string()
    .required("Password wajib diisi")
    .oneOf([yup.ref("oldPassword"), null], "Password harus sesuai"),
});

const RequestPasswordChange = () => {
  const navigate = useNavigate;
  const email = sessionStorage.getItem("email");

  const { mutate: mutateChangePassword } = useMutation({
    mutationFn: changePassword,
    onSuccess: () => navigate("/success"),
  });

  const formik = useFormik({
    initialValues: {
      email,
      oldPassword: "",
      new_password: "",
      current_password: "",
    },
    validationSchema,
    onSubmit: (value) => {
      mutateChangePassword({
        email,
        new_password: value.new_password,
        current_password: value.current_password,
      });
    },
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      <img src={VerificationOTP} className="w-[50%]" />
      <div className="flex flex-col justify-center">
        <p className="font-bold text-[35px]">Masukkan Kata Sandi Baru</p>
        <p className="text-[#007AF1] font-bold">Password Lama</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            type="password"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <BiLockAlt className="text-[30px] mr-5" />
                </InputAdornment>
              ),
            }}
            value={formik.values.oldPassword}
            onChange={(e) =>
              formik.setFieldValue("oldPassword", e.target.value)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
          />

          <div className="my-3">
            <p className="text-[#007AF1] font-bold">Password Baru</p>
            <TextField
              type="password"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <BiLockAlt className="text-[30px] mr-5" />
                  </InputAdornment>
                ),
              }}
              value={formik.values.new_password}
              onChange={(e) =>
                formik.setFieldValue("new_password", e.target.value)
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
              error={
                formik.touched.new_password &&
                Boolean(formik.errors.new_password)
              }
            />
          </div>

          <p className="text-[#007AF1] font-bold">Ketik Ulang Password Baru</p>
          <TextField
            type="password"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <BiLockAlt className="text-[30px] mr-5" />
                </InputAdornment>
              ),
            }}
            value={formik.values.current_password}
            onChange={(e) =>
              formik.setFieldValue("current_password", e.target.value)
            }
            helperText={
              formik.touched.current_password && formik.errors.current_password
            }
            error={
              formik.touched.current_password &&
              Boolean(formik.errors.current_password)
            }
          />
        </form>
        <button
          className="bg-[#007AF1] p-3 text-white font-bold rounded rounded-md mt-3"
          type="submit"
        >
          Selanjutnya
        </button>
      </div>
    </div>
  );
};

export default RequestPasswordChange;
