import { CircularProgress } from "@mui/material";
import { AiOutlineCheck } from "react-icons/ai";

const PurchasePaymentMethod = (props) => {
  const {
    paymentMethodData,
    isPaymentMethodLoading,
    selectedMethod,
    setSelectedMethod,
    payment,
    setPayment,
  } = props;
  return (
    <>
      {isPaymentMethodLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {paymentMethodData && paymentMethodData !== undefined ? (
            <>
              {paymentMethodData.ewallet !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via E-Wallet</p>
                  {paymentMethodData.ewallet.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedMethod({
                          ...selectedMethod,
                          payment_id: pay.id,
                          payment_method: pay.pg_name,
                          fee: pay.bank_fee
                        });
                        setPayment({
                          ...payment,
                          logo: pay.logo,
                          name: pay.pg_bank,
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedMethod.payment_id === pay.id ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentMethodData.credit !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via Kartu Kredit</p>
                  {paymentMethodData.credit.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedMethod({
                          ...selectedMethod,
                          payment_id: pay.id,
                          payment_method: pay.pg_name,
                          fee: pay.bank_fee
                        });
                        setPayment({
                          ...payment,
                          logo: pay.logo,
                          name: pay.pg_bank,
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedMethod.payment_id === pay.id ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentMethodData.emoney !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via E-Money</p>
                  {paymentMethodData.emoney.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedMethod({
                          ...selectedMethod,
                          payment_id: pay.id,
                          payment_method: pay.pg_name,
                          fee: pay.bank_fee
                        });
                        setPayment({
                          ...payment,
                          logo: pay.logo,
                          name: pay.pg_bank,
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedMethod.payment_id === pay.id ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentMethodData.retail !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via Retail</p>
                  {paymentMethodData.retail.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedMethod({
                          ...selectedMethod,
                          payment_id: pay.id,
                          payment_method: pay.pg_name,
                          fee: pay.bank_fee
                        });
                        setPayment({
                          ...payment,
                          logo: pay.logo,
                          name: pay.pg_bank,
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedMethod.paymen_id === pay.id ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentMethodData.virtual_accounts !== null && (
                <>
                  <p className="font-bold my-3">
                    Pembayaran via Virtual Account
                  </p>
                  {paymentMethodData.virtual_accounts.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedMethod({
                          ...selectedMethod,
                          payment_id: pay.id,
                          payment_method: pay.pg_name,
                          fee: pay.bank_fee
                        });
                        setPayment({
                          ...payment,
                          logo: pay.logo,
                          name: pay.pg_bank,
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedMethod.payment_id === pay.id ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <p>kosong</p>
          )}
        </>
      )}
    </>
  );
};

export default PurchasePaymentMethod;
