import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { RxDoubleArrowDown, RxDoubleArrowUp } from "react-icons/rx";
import { FaSort, FaSortUp } from "react-icons/fa";

import { usePapaParse } from "react-papaparse";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import zoomPlugin from "chartjs-plugin-zoom";
import { useMutation } from "@tanstack/react-query";
import CircularLoading from "../../../common/particles.jsx/CircularLoading";
import {
  fetchReportingData,
  getReportingTable,
} from "../../../../services/ReportingApi";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const toDate = (date) => moment(date).format("DD MMM YYYY");

const FETCH_LIMIT = 10;

const toApiDate = (date) => moment(date).format("YYYY-MM-DD");

const Penjualan = () => {
  const { jsonToCSV } = usePapaParse();
  const [method, setMethod] = useState(2);
  const [date, setDate] = useState(false);
  const [period, setPeriod] = useState(8);
  const [periodDummy, setPeriodDummy] = useState(8);
  const [rows, setRows] = useState([]);
  const [sortBy, setSortBy] = useState({
    column: ["quantity", "total"],
    order: "asc",
  });
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");

  const [offset, setOffset] = useState(1);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  const {
    mutate: mutatePenjualan,
    data: penjualan,
    isLoading: isReportingLoading,
  } = useMutation({
    mutationFn: fetchReportingData,
  });

  const {
    mutate: mutateReportingTable,
    data: reportingTable,
    isLoading: isReportingTableLoading,
  } = useMutation({
    mutationFn: getReportingTable,
  });

  const pageCount = useMemo(() => {
    if (reportingTable === undefined) return 0;
    else if (reportingTable.count === undefined) return 0;
    else return Math.ceil(reportingTable.count / FETCH_LIMIT);
  }, [reportingTable]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

  let offlineData = penjualan?.chart?.offline_order
    ? penjualan?.chart?.offline_order.map((data) => data.total_order)
    : [];
  let onlineData = [];
  let offlineLabels = penjualan?.chart?.offline_order
    ? penjualan?.chart?.offline_order.map((data) => toApiDate(data.date))
    : [];
  let onlineLabels = [];

  if (penjualan?.chart?.online_order) {
    onlineData = penjualan?.chart?.online_order
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((data) => data.total_order);
    onlineLabels = penjualan?.chart?.online_order.map((data) =>
      toApiDate(data.date)
    );
  }

  const labels = [].concat(onlineLabels, offlineLabels);

  const datas = {
    labels: labels,
    datasets: [
      {
        label: "Penjualan Offline",
        data: offlineData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Penjualan Online",
        data: onlineData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    mantainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      // zoom: {
      //   pan: {
      //     enabled: true,
      //     mode: 'x',
      //   },
      //   zoom: {
      //     pinch: {
      //       enabled: true, // Enable pinch zooming
      //     },
      //     wheel: {
      //       enabled: true, // Enable wheel zooming
      //     },
      //     mode: 'x',
      //   },
      // },
    },
  };

  const options2 = {
    chart: {
      type: "donut",
    },
    series: {
      data: penjualan?.top?.payment?.map((data) => data.total),
    },
    labels: penjualan?.top?.payment?.map((data) => data.name),
  };

  const handleDownload = () => {
    const canvas = document.getElementById("a");
    canvas.toBlob(function (blob) {
      saveAs(blob, "test.png");
    });
  };

  const handleSort = (column) => {
    // setLoading(true);

    setSortBy((prevSortBy) => {
      return {
        ...prevSortBy,
        column: [column],
        order: prevSortBy.order === "asc" ? "desc" : "asc",
      };
    });

    const newSortBy = {
      column: column,
      order: sortBy.order === "asc" ? "desc" : "asc",
    };
    setSortBy(newSortBy);
    const sortedRows = [...rows].sort((a, b) => {
      if (sortBy.order === "asc") {
        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
      } else {
        if (a[column] > b[column]) {
          return -1;
        }
        if (a[column] < b[column]) {
          return 1;
        }
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const handleJsonToCsvExisting = (csvExisting) => {
    const csv = jsonToCSV(csvExisting.data_table);
    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "hasil_reporting_OLIN.csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateExportCsv, isLoading: exportCsvLoading } = useMutation({
    mutationFn: getReportingTable,
    onSuccess: (data) => handleJsonToCsvExisting(data),
  });

  useEffect(() => {
    mutateReportingTable({
      method,
      period,
      limit: FETCH_LIMIT,
      offset,
      start_date: tanggalMulai,
      end_date: tanggalAkhir,
    });
  }, [method, period, offset, tanggalMulai, tanggalAkhir]);

  useEffect(() => {
    mutatePenjualan({
      period,
      method,
      start_date: tanggalMulai,
      end_date: tanggalAkhir,
    });
  }, [method, period, tanggalMulai, tanggalAkhir]);

  useEffect(() => {
    if (
      toApiDate(startDate) === "Invalid date" ||
      toApiDate(endDate) === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(toApiDate(startDate));
      setTanggalAkhir(toApiDate(endDate));
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full">
      <LoadingBackdrop isLoading={exportCsvLoading || isReportingLoading} />
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-center gap-5 mt-4">
        <div className="p-3 border rounded-lg">
          <p className="text-blue-500 font-bold">Penjualan Obat</p>
          <hr />
          <div className="flex flex-row justify-between items-center gap-5">
            <p>Metode Penjualan</p>
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Semua Penjualan
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category_id"
                label="Tambah Kategori"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <MenuItem value={1}>Semua Penjualan</MenuItem>
                <MenuItem value={3}>Order Online</MenuItem>
                <MenuItem value={2}>Order Offline</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-row justify-between items-center gap-5">
            <p>Period Penjualan</p>
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Period Penjualan
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category_id"
                label="Tambah Kategori"
                value={periodDummy}
                onChange={(e) => {
                  if (e.target.value === 7) {
                    setDate(true);
                    return;
                  } else if (e.target.value !== 7) {
                    setDate(false);
                  }
                  setPeriod(e.target.value);
                  setPeriodDummy(e.target.value);
                }}
              >
                <MenuItem value={8}>Hari ini</MenuItem>
                <MenuItem value={9}>Kemarin</MenuItem>
                <MenuItem value={1}>Minggu Berjalan</MenuItem>
                <MenuItem value={2}>Minggu Lalu</MenuItem>
                <MenuItem value={3}>Bulan Berjalan</MenuItem>
                <MenuItem value={4}>Bulan Lalu</MenuItem>
                <MenuItem value={5}>Tahun Berjalan</MenuItem>
                <MenuItem value={6}>Tahun Lalu</MenuItem>
                <MenuItem value={7}>Pilih Tanggal</MenuItem>
                <MenuItem value={10} disabled sx={{ display: "none" }}>
                  {tanggalMulai + " - " + tanggalAkhir}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <p className="text-center my-4 font-bold text-blue-500 text-[30px]">
            {penjualan?.sales_medicine?.total === null ||
            penjualan?.sales_medicine?.total === undefined
              ? 0
              : penjualan?.sales_medicine?.total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
          </p>
          <div className="flex flex-row items-center justify-between font-bold">
            <p>Order Online :</p>
            <p>
              {penjualan?.sales_medicine?.total_order_online === null ||
              penjualan?.sales_medicine?.total_order_online === undefined
                ? 0
                : penjualan?.sales_medicine?.total_order_online.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between font-bold mt-4">
            <p>Order Offline :</p>
            <p>
              {penjualan?.sales_medicine?.total_order_offline === null ||
              penjualan?.sales_medicine?.total_order_offline === undefined
                ? 0
                : penjualan?.sales_medicine?.total_order_offline.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}
            </p>
          </div>
        </div>

        <div className="p-3 border rounded-lg">
          <div className="flex flex-row justify-between items-center">
            <p className="text-blue-500 font-bold">Online Order</p>
            <p className="text-xs font-semibold">Total Penjualan</p>
          </div>
          <hr />

          {/* {penjualan?.sales_total?.online_order.map((item) => {
            return (
              <>
                <div className="flex flex-row items-center justify-between my-2">
                  <p>{item.name_platform}</p>
                  <p>{item.total}</p>
                </div>
              </>
            );
          })} */}

          <div className="flex flex-row justify-between items-center mt-8">
            <p className="text-blue-500 font-bold">Offline Order</p>
            <p className="text-xs font-semibold">Total Penjualan</p>
          </div>

          <hr />

          <div className="flex flex-row items-center justify-between my-2">
            <p>On-Site</p>
            <p>{penjualan?.sales_total?.offline_order}</p>
          </div>
          <p className="text-end mt-5 text-blue-500 font-medium hover:underline cursor-pointer">
            <a href="#drop1">Lihat Detail</a>
          </p>
        </div>
      </div>
      <>
        {penjualan && penjualan !== undefined ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-5 justify-center gap-3 mt-3 ">
              <div className="p-3 border rounded-lg">
                <p>Pendapatan</p>
                <p className="mt-4 font-bold text-[25px]">
                  {penjualan?.total_income?.total === null
                    ? 0
                    : penjualan?.total_income?.total.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                </p>

                <div className="flex flex-wrap gap-3 mt-2">
                  {penjualan?.total_income?.decreasing >
                  penjualan?.total_income?.increasing ? (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowDown className="text-red-500" />
                      <p>{penjualan?.total_income?.decreasing}% Penurunan</p>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowUp className="text-green-500" />
                      <p>{penjualan?.total_income?.increasing}% Peningkatan</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="p-3 border rounded-lg">
                <p>Rata - rata per transaksi</p>
                <p className="mt-4 font-bold text-[25px]">
                  Rp{" "}
                  {penjualan?.average_transaction?.total === null
                    ? 0
                    : penjualan?.average_transaction?.total.toLocaleString(
                        "id-ID",
                        {
                          useGrouping: true,
                        }
                      )}
                </p>

                <div className="flex flex-wrap gap-3 mt-2">
                  {penjualan?.average_transaction?.decreasing >
                  penjualan?.average_transaction?.increasing ? (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowDown className="text-red-500" />
                      <p>
                        {penjualan?.average_transaction?.decreasing}% Penurunan
                      </p>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowUp className="text-green-500" />
                      <p>
                        {String(
                          penjualan?.average_transaction?.increasing
                        ).slice(0, 5)}
                        % Peningkatan
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="p-3 border rounded-lg">
                <p>Transaksi</p>
                <p className="mt-4 font-bold text-[25px]">{`${
                  penjualan?.count_transaction?.total === undefined
                    ? "Tidak ada Transaksi"
                    : penjualan?.count_transaction?.total.toLocaleString(
                        "id-ID",
                        {
                          useGrouping: true,
                        }
                      )
                }`}</p>

                <div className="flex flex-wrap gap-3 mt-2">
                  {penjualan?.count_transaction?.decreasing >
                  penjualan?.count_transaction?.increasing ? (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowDown className="text-red-500" />
                      <p>
                        {penjualan?.count_transaction?.decreasing}% Penurunan
                      </p>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center ">
                      <RxDoubleArrowUp className="text-green-500" />
                      <p>
                        {penjualan?.count_transaction?.increasing}% Peningkatan
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="p-3 border rounded-lg col-span-2">
                <p className="font-bold underline text-end">
                  Top {penjualan.top?.payment?.length} Payment Method
                </p>
                <div className="flex flex-wrap gap-3 mt-2">
                  <ReactApexChart
                    options={options2}
                    labels={options2.labels}
                    series={options2.series.data}
                    type="donut"
                    height={450}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>

            {penjualan && penjualan !== undefined ? (
              <div className="mt-5">
                {penjualan.top && penjualan.top !== undefined ? (
                  <div className="flex">
                    <div className="p-3 rounded-md border border-black w-[35%]">
                      <p className="text-blue-500 text-center font-bold">
                        Top {penjualan.top.product.length} Products
                      </p>
                      <div className="border border-black my-3"></div>
                      {penjualan.top.product.map((data, index) => (
                        <p className="mt-3 font-bold" key={index}>
                          {index + 1}. {data.name} - {data.total} pcs
                        </p>
                      ))}
                    </div>
                    <div className="p-3 rounded-md border border-black w-[35%] mx-3">
                      <p className="text-blue-500 text-center font-bold">
                        Top {penjualan.top.value.length} Products by Transaction
                      </p>
                      <div className="border border-black my-3"></div>
                      {penjualan.top.value.map((data, index) => (
                        <p className="mt-3 font-bold" key={index}>
                          {index + 1}. {data.name} -
                          {data.total.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      ))}
                    </div>
                    <div className="p-3 rounded-md border border-black w-[35%]">
                      <p className="text-blue-500 text-center font-bold">
                        Top {penjualan.top.category.length} Categories
                      </p>
                      <div className="border border-black my-3"></div>
                      {penjualan.top.category.map((data, index) => (
                        <p className="mt-3 font-bold" key={index}>
                          {index + 1}. {data.name} - {data.total} pcs
                        </p>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* Graph recharts*/}

            {reportingTable && reportingTable.data_table !== null ? (
              <>
                <div className="mt-5 w-full">
                  <div className="flex flex-row justify-between items-center">
                    <p>Grafik Penjualan</p>
                    <button
                      onClick={handleDownload}
                      className="p-2 px-5 bg-blue-500 text-white rounded-lg"
                    >
                      Download Chart
                    </button>
                  </div>
                  <div className="relative m-auto h-[50vh] w-[60vw]">
                    <Line data={datas} options={options} id="a" />
                  </div>
                </div>
                <div>
                  <div className="flex flex-row justify-between mt-5">
                    <p className="font-bold" id="drop1">
                      Laporan Penjualan
                    </p>
                    {/* <CSVLink data={dataCSV} headers={headerCSV} target="_blank"></CSVLink> */}
                    <button
                      className="p-2 px-5 bg-blue-500 text-white rounded-lg"
                      onClick={() =>
                        mutateExportCsv({
                          method,
                          period,
                          limit: 0,
                          offset: 0,
                          start_date: tanggalMulai,
                          end_date: tanggalAkhir,
                        })
                      }
                    >
                      Export
                    </button>
                  </div>
                  <div>
                    {isReportingTableLoading ? (
                      <div className="flex items-center justify-center my-28">
                        <CircularLoading />
                      </div>
                    ) : (
                      <>
                        <TableContainer sx={{ marginTop: 3 }} component={Paper}>
                          <Table
                            sx={{ width: "100%" }}
                            aria-label="simple-table"
                          >
                            <TableHead className="text-[10px]">
                              <TableRow
                                sx={{
                                  backgroundColor: "#007AF1",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Invoice Date
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Order ID
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Nama Produk
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Quantity
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Total Pembelian
                                </TableCell>
                                <TableCell
                                  align="center"
                                  onClick={() => handleSort("quantity")}
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  <p className="flex flex-row justify-center items-center gap-2">
                                    Payment/Order Method Quantity
                                    {sortBy.column.includes("quantity") &&
                                    sortBy.order === "asc" ? (
                                      <FaSortUp className="text-[10px] mt-1 font-semibold text-blue-500" />
                                    ) : sortBy.column.includes("quantity") &&
                                      sortBy.order === "desc" ? (
                                      <FaSortUp className="rotate-180 text-[10px] mb-1 font-semibold text-blue-500" />
                                    ) : (
                                      <FaSort className="text-[10px] text-blue-500" />
                                    )}
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Selling Method
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  Notes/Catatan
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  B2B Id
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontWeight: "bold" }}
                                >
                                  B2C Id
                                </TableCell>
                                {/*<TableCell
                                    align="center"
                                    onClick={() => handleSort("total")}
                                    sx={{ color: "white", fontWeight: "bold" }}
                                  >
                                    <p className="flex flex-row justify-center items-center gap-2">
                                      Kasir / Apoteker
                                      {sortBy.column.includes("total") &&
                                      sortBy.order === "asc" ? (
                                        <FaSortUp className="text-[10px] mt-1 font-semibold text-blue-500" />
                                      ) : sortBy.column.includes("total") &&
                                        sortBy.order === "desc" ? (
                                        <FaSortUp className="rotate-180 text-[10px] mb-1 font-semibold text-blue-500" />
                                      ) : (
                                        <FaSort className="text-[10px] text-blue-500" />
                                      )}
                                    </p>
                                  </TableCell>
                                */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reportingTable.data_table.map((data, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {toDate(data.invoice_date)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.order_id}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.name_medicine}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.total.toLocaleString("id-ID", {
                                      style: "currency",
                                      currency: "IDR",
                                    })}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.payment_method}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.selling_method}
                                  </TableCell>
                                  {/*<TableCell align="center">
                                    {data.staff_name}
                                  </TableCell>*/}
                                  <TableCell align="center">
                                    {data.notes.length === 0 ? "-" : data.notes}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.b2b_id.length === 0
                                      ? "-"
                                      : data.b2b_id}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.b2c_id === 0 ? "-" : data.b2c_id}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="flex justify-center mt-[10px]">
                          <Pagination
                            count={pageCount}
                            page={offset}
                            onChange={(_, value) => setOffset(value)}
                            shape="rounded"
                            size="large"
                            color="primary"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex justify-center w-full mt-5">
                <p>tidak ada produk</p>
              </div>
            )}
          </>
        ) : (
          <div className="w-full flex items-center justify-center mt-5">
            <p className="txt-[25px] font-bold">
              tidak ada transaksi yang berlangsung
            </p>
          </div>
        )}
      </>
      <Dialog
        open={date}
        onClose={() => {
          setDate(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              setDate(false);
              setPeriod(7);
              setPeriodDummy(10);
            }}
          >
            ok
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Penjualan;
