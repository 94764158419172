import React, { useState, createContext } from "react";

export const PasswordContext = createContext();

const ForgotPasswordContext = ({ children }) => {
  const [email, setEmail] = useState("");

  return (
    <PasswordContext.Provider value={{ email, setEmail }}>
      {children}
    </PasswordContext.Provider>
  );
};

export default ForgotPasswordContext;
