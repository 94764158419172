import { CircularProgress, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";

import { RxCross1 } from "react-icons/rx";
import { setPatientCareCategory } from "../../../../services/patientCare";

const ModalAddEditCategory = ({
  setOpen,
  view,
  setView,
  isEdit,
  editCategory,
  setEditCategory,
}) => {
  const [newCategory, setNewCategory] = useState({
    name: "",
  });

  const { mutate: mutateCategory, isLoading } = useMutation({
    mutationFn: setPatientCareCategory,
    onSuccess: () => {
      setView("add");
    },
  });

  useEffect(() => {
    if (editCategory && view === "category") setNewCategory(editCategory);
    if (view !== "category") setNewCategory({ name: "" });
  }, [editCategory, view]);

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between items-center pb-8">
          <div className="flex items-center gap-4">
            <FaChevronLeft
              className="hover:text-gray-300 cursor-pointer"
              onClick={() => {
                setView("add");
                setEditCategory(null);
              }}
            />
            <p className="font-bold text-lg">
              {isEdit ? "Edit" : "Add"} Category
            </p>
          </div>
          <RxCross1
            className="hover:text-red-500 cursor-pointer w-[14px] h-full"
            onClick={() => {
              setOpen(false);
              setEditCategory(null);
            }}
          />
        </div>

        <p className="text-xs text-gray-500">
          Category Name <span className="text-red-500"> *</span>
        </p>
        <TextField
          fullWidth
          variant="standard"
          value={newCategory.name}
          onChange={(e) =>
            setNewCategory({ ...newCategory, name: e.target.value })
          }
        />
      </div>
      <div className="px-8 pb-4 w-full absolute bottom-0">
        <button
          disabled={newCategory.name === ""}
          onClick={() => mutateCategory(newCategory)}
          className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
        >
          {isLoading ? <CircularProgress size={20} /> : <p>Save</p>}
        </button>
      </div>
    </>
  );
};

export default ModalAddEditCategory;
