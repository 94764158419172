import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import { LuSend } from "react-icons/lu";

const Chats = ({ customer, drugData, isConsultations }) => {
  const userId = localStorage.getItem("company_id");
  const [currentChannel, setCurrentChannel] = useState(null);
  const [channels, setChannels] = useState([]);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchMessage, setFetchMessage] = useState(false);

  const sb = SendbirdChat.init({
    appId: process.env.REACT_APP_SENDBIRD_APP_ID,
    modules: [new GroupChannelModule()],
  });

  async function connectToSendbird(currentUserId) {
    try {
      await sb.connect(currentUserId);
    } catch (error) {
      console.error("Failed to connect:", error);
    }
  }

  async function getUserChannels() {
    try {
      const channelListQuery = sb.groupChannel.createMyGroupChannelListQuery({
        includeEmpty: true,
        limit: 20,
        order: "latest_last_message",
      });

      const channels = await channelListQuery.next();
      setChannels(channels);
    } catch (error) {
      console.error("Failed to get channels:", error);
    }
  }

  async function getChannelMessages(channel) {
    try {
      const messageParams = {
        prevResultSize: 20,
        isInclusive: true,
        reverse: false,
      };
      const messages = await channel.getMessagesByTimestamp(
        new Date().getTime(),
        messageParams
      );

      const formattedChats = messages.map((msg) => ({
        sender:
          msg.sender.userId === localStorage.getItem("company_id")
            ? "me"
            : "other",
        message: msg.message,
        time: new Date(msg.createdAt).toLocaleTimeString(),
        data: msg.data !== "" ? JSON.parse(msg.data) : msg.data,
        customType: msg.customType,
      }));

      setChats(formattedChats);
    } catch (error) {
      console.log("Error getting messages:", error);
    }
  }

  const handleChannelClick = (channel) => {
    setCurrentChannel(channel);
    getChannelMessages(channel);
  };

  async function sendMessage() {
    setFetchMessage(!fetchMessage);

    if (currentChannel && message.trim()) {
      const params = {
        message,
        customType: "1",
      };

      try {
        const sentMessage = await new Promise((resolve, reject) => {
          currentChannel
            .sendUserMessage(params)
            .onSucceeded((message) => {
              resolve(message);
            })
            .onFailed((error) => {
              reject(error);
            });
        });

        const formattedMessage = {
          sender: "me",
          message: message,
          time: sentMessage.createdAt
            ? new Date(sentMessage.createdAt).toLocaleTimeString()
            : new Date().toLocaleTimeString(),
          customType: sentMessage.customType,
        };

        setChats((prevChats) => [...prevChats, formattedMessage]);
        setMessage("");
      } catch (error) {
        console.log("Error sending message:", error);
      }
    } else {
      console.log("Channel is not set or message is empty");
    }
  }

  useEffect(() => {
    const storedUserId = localStorage.getItem("company_id");
    if (storedUserId) {
      connectToSendbird(storedUserId).then(() => {
        getUserChannels();
      });
    }
  }, []);

  useEffect(() => {
    const chatContainer = document.querySelector(
      ".flex-grow.p-4.overflow-y-auto"
    );
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [chats]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentChannel(currentChannel);
      getChannelMessages(currentChannel);
    }, 2000);

    return () => clearInterval(interval);
  }, [fetchMessage, currentChannel]);

  return (
    <>
      <div className="h-[90px]">
        <div className="flex flex-row gap-2 mb-2">
          <p className="text-sm text-blue-500 cursor-pointer">Home</p>
          <p>/</p>
          <p className="text-sm text-blue-500 cursor-pointer">Consultations</p>
        </div>

        <p className="text-3xl font-semibold">Chat</p>
      </div>
      <div className={`w-full h-[calc(100vh-124px)]`}>
        <div className="w-full h-full rounded-lg shadow-md flex overflow-hidden border">
          <div className="w-1/3 bg-slate-100 rounded-l-lg border-r px-3 py-4">
            <div className="flex gap-2 mb-3">
              <input
                type="text"
                placeholder="Cari Pengguna OLIN"
                className="h-8 w-full border-2 border-gray-400 rounded-lg px-2"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="w-full">
              <div className="pt-2">
                <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                  {channels
                    .filter((channel) => {
                      const member = channel.members.find(
                        (m) => m.userId !== localStorage.getItem("company_id")
                      );
                      return (
                        member?.nickname
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) &&
                        channel.data === ""
                      );
                    })
                    .map((channel, index) => {
                      const member = channel.members.find(
                        (m) => m.userId !== localStorage.getItem("company_id")
                      );
                      const isActive = currentChannel?.url === channel.url;
                      return (
                        <div
                          key={index}
                          className={`flex items-center gap-3 px-4 py-2 border-b cursor-pointer ${
                            isActive ? "bg-blue-100" : "bg-slate-100"
                          }`}
                          onClick={() => {
                            handleChannelClick(channel);
                          }}
                        >
                          {member?.profileUrl &&
                          member?.profileUrl !== "null" ? (
                            <img
                              src={member.profileUrl}
                              alt={member.nickname || "Anonymous"}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          ) : (
                            <FaUserCircle className="w-10 h-10 text-gray-400" />
                          )}
                          <div className="flex-grow">
                            <p className="text-[14px] font-semibold">
                              {member?.nickname || "Anonymous"}
                            </p>
                            <p className="text-[11px] text-gray-500">
                              {channel.lastMessage?.createdAt
                                ? new Date(
                                    channel.lastMessage.createdAt
                                  ).toLocaleString()
                                : "No recent message"}
                            </p>
                            <p className="text-[11px] text-gray-400">
                              Finished |{" "}
                              {new Date(
                                channel.lastMessage?.createdAt || Date.now()
                              ).toLocaleDateString() +
                                " " +
                                new Date(
                                  channel.lastMessage?.createdAt || Date.now()
                                ).toLocaleTimeString()}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col justify-between">
            <div className="py-2 px-4 flex gap-2 items-center border-b">
              {currentChannel && (
                <>
                  {currentChannel.members
                    .filter((member) => member.userId !== userId)
                    .map((member, index) => (
                      <div
                        key={index}
                        className="w-full flex items-center gap-2 justify-between"
                      >
                        <div className="flex items-center justify-center gap-2">
                          {member?.profileUrl &&
                          member?.profileUrl !== "null" ? (
                            <img
                              src={member.profileUrl}
                              alt={member.nickname || "Anonymous"}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          ) : (
                            <FaUserCircle className="w-12 h-12 text-gray-400" />
                          )}
                          <div>
                            <p className="text-[14px] font-semibold">
                              {member.nickname || "Anonymous"}
                            </p>
                            <p className="text-[11px] text-gray-500">
                              {currentChannel?.customType}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <div className="flex-grow p-4 overflow-y-auto">
              {chats.map((chat, index) => (
                <div
                  key={index}
                  className={`mb-2 flex flex-col ${
                    chat.sender === "me" ? "items-end" : "items-start"
                  }`}
                >
                  <div className="flex items-center">
                    {chat.sender === "me" ? (
                      <>
                        {chat.customType === "2" ? (
                          <>
                            <p className="text-xs text-gray-400 text-right mt-2 mr-2">
                              {chat.time}
                            </p>
                            <div className="bg-blue-100 py-2 px-4 rounded-lg max-w-xs shadow-sm">
                              <p className="text-[16px] font-semibold mb-2">
                                Obat yang dipesan
                              </p>
                              <table className="table-auto w-full">
                                <tbody>
                                  <tr>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Nama produk
                                    </td>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Jumlah
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm min-w-48">
                                      R/ {chat.data.product_name}
                                    </td>
                                    <td className="text-sm">
                                      {chat.data.quantity}{" "}
                                      {chat.data.product_type}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="text-xs text-gray-400 mt-1 mr-2">
                              {chat.time}
                            </p>
                            <div className="py-2 px-4 rounded-lg shadow-sm max-w-xs bg-blue-100 text-left">
                              <p className="text-sm">{chat.message}</p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {chat.customType === "2" ? (
                          <>
                            <div className="bg-gray-100 py-2 px-4 rounded-lg max-w-xs shadow-sm">
                              <p className="text-[16px] font-semibold mb-2">
                                Obat yang dipesan
                              </p>
                              <table className="table-auto w-full">
                                <tbody>
                                  <tr>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Nama produk
                                    </td>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Jumlah
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-sm min-w-48">
                                      R/ {chat.data.product_name}
                                    </td>
                                    <td className="text-sm">
                                      {chat.data.quantity}{" "}
                                      {chat.data.product_type}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <p className="text-xs text-gray-400 text-right mt-2 ml-2">
                              {chat.time}
                            </p>
                          </>
                        ) : chat.customType === "3" ? (
                          <>
                            <div className="bg-gray-100 py-2 px-4 rounded-lg max-w-xs shadow-sm">
                              <p className="text-[16px] font-semibold mb-2">
                                Resep Digital
                              </p>
                              <table className="table-auto w-full">
                                <tbody>
                                  <tr>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Nama produk
                                    </td>
                                    <td className="text-[12px] text-gray-500 font-medium">
                                      Jumlah
                                    </td>
                                  </tr>
                                  {chat?.data?.list_product?.map(
                                    (product, index) => (
                                      <tr key={index}>
                                        <td className="text-sm min-w-48">
                                          R/ {product.drug_name}
                                        </td>
                                        <td className="text-sm">
                                          {product.quantity} {product.uom}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              <div className="my-1 w-full border-b-2 border-gray-500"></div>
                              <div className="w-full flex justify-end">
                                <p
                                  className="text-sm font-semibold text-blue-500 cursor-pointer hover:text-blue-700"
                                  // onClick={() => {
                                  //   setPrescriptionPreview(true);
                                  //   setPrescriptionData(chat.data);
                                  // }}
                                >
                                  Lihat detail
                                </p>
                              </div>
                            </div>
                            <p className="text-xs text-gray-400 mt-1 ml-2">
                              {chat.time}
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="py-2 px-4 rounded-lg shadow-sm max-w-xs bg-gray-100 text-left">
                              <p className="text-sm">{chat.message}</p>
                            </div>
                            <p className="text-xs text-gray-400 mt-1 ml-2">
                              {chat.time}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t py-3 px-4 flex items-center gap-2">
              {currentChannel === null ? null : (
                <>
                  {currentChannel !== null && !customer && (
                    <>
                      <input
                        type="text"
                        placeholder="Ketik pesanmu disini..."
                        className="flex-grow border-2 border-gray-400 rounded-lg px-4 py-2"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") sendMessage();
                        }}
                      />
                      <button
                        className="bg-blue-500 text-white p-3 border-2 border-blue-500 rounded-full hover:bg-blue-700 hover:border-blue-700"
                        onClick={sendMessage}
                      >
                        <LuSend />
                      </button>
                    </>
                  )}
                  {currentChannel !== null && customer && (
                    <>
                      <input
                        type="text"
                        placeholder="Ketik pesanmu disini..."
                        className="flex-grow border-2 border-gray-400 rounded-lg px-4 py-2"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") sendMessage();
                        }}
                      />
                      <button
                        className="bg-blue-500 text-white p-3 border-2 border-blue-500 rounded-full hover:bg-blue-700 hover:border-blue-700"
                        onClick={sendMessage}
                      >
                        <LuSend />
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chats;
