import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Badge, CircularProgress } from "@mui/material";
import { HiOutlineClipboardList } from "react-icons/hi";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";
import useDebounce from "../../../../hooks/useDebounce";

const AllCategoryList = () => {
  const navigate = useNavigate();
  const {
    countCart,
    fetchCategories,
    categoriesProduct,
    FETCH_LIMIT,
    isCountCartLoading,
  } = useContext(SelectedProductContext);

  const [searchCategories, setSearchCategories] = useState("");
  const [offset, setOffset] = useState(1);

  const debounceCategories = useDebounce(searchCategories, 500);

  useEffect(() => {
    fetchCategories({
      offset,
      limit: FETCH_LIMIT,
      category_name: debounceCategories,
    });
  }, [debounceCategories, fetchCategories]);

  return (
    <div>
      <div className="flex text-[#007AF1] font-bold my-3 p-3 gap-2">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/purchase")}
        >
          Purchase
        </p>
        <p>/</p>
        <p className="hover:underline hover:cursor-pointer">Kategori</p>
      </div>
      <div className="w-full flex items-center mb-3">
        <div className="flex flex-row items-center w-full">
          <input
            id="myInput"
            className="block w-full  pl-3 pr-3 py-2 border  rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out hover:cursor-pointer"
            placeholder="Cari Produk"
            onChange={(e) => setSearchCategories(e.target.value)}
            value={searchCategories}
          />
          <HiOutlineClipboardList
            className="text-[40px] p-2 shadow-md border rounded-lg mx-3 hover:cursor-pointer"
            onClick={() => navigate("/purchase/list-transaction")}
          />
          {countCart && countCart !== undefined ? (
            <Badge
              badgeContent={
                countCart && countCart !== undefined ? countCart.count : 0
              }
              color="error"
            >
              <AiOutlineShoppingCart
                className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
                onClick={() => navigate("/purchase/cart-detail")}
              />
            </Badge>
          ) : (
            <AiOutlineShoppingCart
              className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
              onClick={() => navigate("/purchase/cart-detail")}
            />
          )}
        </div>
      </div>
      <div className="w-full">
        <p className="text-[36px] font-bold">Kategori</p>
        <div className="border-2 border-black"></div>
      </div>
      <div className="flex grid grid-cols-6 gap-3 mt-3">
        {categoriesProduct && categoriesProduct !== undefined
          ? categoriesProduct.map((category, index) => (
              <div
                className={`bg-white p-3 rounded-lg flex justify-center items-center flex-wrap border border-black hover:cursor-pointer ${
                  category.logo !== "" ? "content-between" : "content-end"
                }`}
                key={index}
                onClick={() =>
                  navigate(
                    `/purchase/category-detail/${category.product_category_id}`
                  )
                }
              >
                <img src={category.logo} alt="" className="rounded-lg" />
                <p className="font-bold text-center">
                  {category.category_name}
                </p>
              </div>
            ))
          : null}
      </div>
      {countCart && countCart !== undefined ? (
        <div className="flex justify-center w-full mt-20">
          {countCart.count !== 0 ? (
            <div className="fixed bottom-[20px] z-20 bg-blue-500 p-3 rounded-md w-[60%] h-[10%]">
              {isCountCartLoading ? (
                <div className="flex justify-center w-full">
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              ) : (
                <div className="flex justify-between items-center font-medium text-white h-full">
                  <div className="flex justify-center items-center h-full">
                    <p>{countCart.count} Barang</p>
                    <div className="border-2 h-full border-white mx-2"></div>
                    <div>
                      <p>Total Belanja</p>
                      <p className="text-[#FDCD78]">
                        {countCart.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[#FF8D40] p-2 text-[20px] rounded-lg"
                    onClick={() => navigate("/purchase/cart-detail")}
                  >
                    Lihat Keranjang
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default AllCategoryList;
