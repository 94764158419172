import { useNavigate } from "react-router-dom";

const WAREHOUSE_PATH = "/central-warehouse";

const BillingNavigation = () => {
  const isWarehouse = window.location.pathname.includes(WAREHOUSE_PATH);
  const isManual = window.location.pathname.includes("/manual-billing/");
  const navigate = useNavigate();

  return (
    <div
      className={`w-full flex justify-between border-2 mt-3 text-sm lg:text-base grid text-center ${
        isManual ? "grid-cols-5" : "grid-cols-2"
      } divide-x`}
    >
      <button
        onClick={() =>
          isManual
            ? navigate(
                `${
                  isWarehouse ? WAREHOUSE_PATH : ""
                }/manual-billing/all-billing`
              )
            : navigate("/billing/unpaid")
        }
        className={
          (
            isWarehouse
              ? window.location.pathname ===
                WAREHOUSE_PATH + "/manual-billing/all-billing"
              : isManual
              ? window.location.pathname === "/manual-billing/all-billing"
              : window.location.pathname === "/billing/unpaid"
          )
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Semua Billing
      </button>
      <button
        onClick={() =>
          isManual
            ? navigate(
                `${isWarehouse ? WAREHOUSE_PATH : ""}/manual-billing/unpaid`
              )
            : navigate("/billing/unpaid")
        }
        className={
          (
            isWarehouse
              ? window.location.pathname ===
                WAREHOUSE_PATH + "/manual-billing/unpaid"
              : isManual
              ? window.location.pathname === "/manual-billing/unpaid"
              : window.location.pathname === "/billing/unpaid"
          )
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Belum Dibayar
      </button>
      <button
        onClick={() =>
          isManual
            ? navigate(
                `${isWarehouse ? WAREHOUSE_PATH : ""}/manual-billing/overdue`
              )
            : navigate("/billing/overdue")
        }
        className={
          (
            isWarehouse
              ? window.location.pathname ===
                WAREHOUSE_PATH + "/manual-billing/overdue"
              : isManual
              ? window.location.pathname === "/manual-billing/overdue"
              : window.location.pathname === "/billing/overdue"
          )
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Telat Bayar
      </button>
      <button
        onClick={() =>
          isManual
            ? navigate(
                `${isWarehouse ? WAREHOUSE_PATH : ""}/manual-billing/paid`
              )
            : navigate("/billing/paid")
        }
        className={
          (
            isWarehouse
              ? window.location.pathname ===
                WAREHOUSE_PATH + "/manual-billing/paid"
              : isManual
              ? window.location.pathname === "/manual-billing/paid"
              : window.location.pathname === "/billing/paid"
          )
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Lunas
      </button>
      <button
        onClick={() =>
          isManual
            ? navigate(
                `${isWarehouse ? WAREHOUSE_PATH : ""}/manual-billing/draft`
              )
            : navigate("/billing/draft")
        }
        className={
          (
            isWarehouse
              ? window.location.pathname ===
                WAREHOUSE_PATH + "/manual-billing/draft"
              : isManual
              ? window.location.pathname === "/manual-billing/draft"
              : window.location.pathname === "/billing/draft"
          )
            ? "py-2 bg-blue-500 text-white"
            : "py-2"
        }
      >
        Draft
      </button>
    </div>
  );
};

export default BillingNavigation;
