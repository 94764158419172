import { useMemo } from "react";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";

import moment from "moment";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const DetailAdjustmentModal = (props) => {
  const { adjustmentStockDetail } = props;

  const adjustmentTypeConvert = (typeAdjustment) => {
    if (typeAdjustment === 1) return "Stock Rusak";
    else if (typeAdjustment === 2) return "Stock Habis";
    else if (typeAdjustment === 3) return "Stock Expired";
  };

  return (
    <div>
      {adjustmentStockDetail && adjustmentStockDetail !== undefined ? (
        <>
          <div>
            <div className="flex justify-between w-full">
              <div className="w-full">
                <p>Tipe Penyesuaian</p>
                <p>
                  {adjustmentTypeConvert(
                    adjustmentStockDetail[0]?.type_adjustment,
                  )}
                </p>
                <div className="border border-black"></div>
              </div>
              <div className="w-full ml-3">
                <p>Nama Produk</p>
                <p>{adjustmentStockDetail[0]?.product_name}</p>
                <div className="border border-black"></div>
              </div>
            </div>
            <TableContainer component={Paper} fullWidth sx={{ marginTop: 3 }}>
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: "#007AF1",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      No.Batch
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Expired date
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Stock Awal
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Stock Sebenarnya
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      Status Stock
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adjustmentStockDetail.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{data.batch}</TableCell>
                      <TableCell align="center">
                        {toDate(data.expired_date)}
                      </TableCell>
                      <TableCell align="center">{data.stock}</TableCell>
                      <TableCell align="center">
                        {data.stock_adjustment}
                      </TableCell>
                      <TableCell align="center">
                        {data.stock === data.actual_stock
                          ? "Tidak Berubah"
                          : "Berubah"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DetailAdjustmentModal;
