import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { TiExport } from "react-icons/ti";
import {
  Snackbar,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { getMultipleOutletOption } from "../../../../services/importProductApi";
import { exportXlsxInventory } from "../../../../services/InventoryAPI";
import { productTypeOptions } from "../../../constants/constant";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const ExportProduct = ({ isWarehouse }) => {
  const company_id = parseInt(localStorage.getItem("company_id"), 10);
  const titleId = localStorage.getItem("title");
  const job = localStorage.getItem("job");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [companyId, setCompanyId] = useState([]);
  const [productType, setProductType] = useState([]);

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "existing_data.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const {
    mutate: mutateExportXlsxInventory,
    isLoading: isMutateExportXlsxInventoryLoading,
  } = useMutation({
    mutationFn: exportXlsxInventory,
    onSuccess: (data) => handleXlsxData(data),
  });

  const { data: multipleOutletOption } = useQuery({
    queryKey: ["outlet-option"],
    queryFn: () => getMultipleOutletOption(isWarehouse ? isWarehouse : false),
    onSuccess: (data) => {
      if (data !== null) {
        setCompanyId(
          data
            .filter((item) => item.company_id === company_id)
            .map((item) => ({
              company_id: item.company_id,
              company_name: item.company_name,
            }))
        );
      } else {
        setCompanyId([{ company_id: company_id, company_name: job }]);
      }
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <LoadingBackdrop isLoading={isMutateExportXlsxInventoryLoading} />
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-1">
        Export Product {isWarehouse ? "Central Warehouse" : ""}
      </h1>
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() =>
            navigate(
              `${
                isWarehouse ? "/central-warehouse" : ""
              }/inventory/produk-aktif`
            )
          }
        >
          Produk Aktif
        </p>
        <p>/</p>
        <p>Export Product</p>
      </div>
      {Number(titleId) === 1 && multipleOutletOption !== null ? (
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <InputLabel id="demo-simple-select-label">Pilih Outlet</InputLabel>
          <Select
            label="Pilih Outlet"
            value={companyId}
            onChange={(e) => {
              if (e.target.value.company_id === 0) {
                setCompanyId(multipleOutletOption?.map((data) => data));
              } else {
                setCompanyId((prevValue) => {
                  if (
                    prevValue
                      .map((data) => data.company_id)
                      .some((data) => data === e.target.value.company_id)
                  ) {
                    return prevValue.filter(
                      (data) => data.company_id === e.target.value.company_id
                    );
                  } else {
                    return [...prevValue, e.target.value];
                  }
                });
              }
              // <Checkbox
              //   checked={companyIdBuffer.indexOf(data.company_id) > -1}
              // />
              // <ListItemText primary={data.company_name} />
            }}
            input={<OutlinedInput label="Pilih Outlet" />}
            renderValue={(selected) =>
              selected.map((data) => data.company_name).join(", ")
            }
          >
            <MenuItem value={{ company_id: 0, company_name: "Semua Outlet" }}>
              Semua Outlet
            </MenuItem>
            {multipleOutletOption && multipleOutletOption !== undefined ? (
              multipleOutletOption.map((data, index) => (
                <MenuItem
                  key={index}
                  value={{
                    company_id: data.company_id,
                    company_name: data.company_name,
                  }}
                >
                  {data.company_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0} sx={{ display: "none" }} disabled></MenuItem>
            )}
          </Select>
        </FormControl>
      ) : null}
      <div className="flex flex-wrap border mt-3">
        <div className="flex items-center mr-3">
          <Checkbox
            checked={productType.length === 0}
            onChange={() => {
              //  setChecked((prevValue) => !prevValue);
              setProductType([]);
            }}
          />
          <p>Semua Tipe</p>
        </div>
        {productTypeOptions.map((data) => (
          <div className="flex items-center mr-3">
            <Checkbox
              checked={productType.includes(data.id)}
              onChange={() => {
                //setChecked(false);
                if (productType.includes(data.id)) {
                  setProductType(productType.filter((val) => val !== data.id));
                } else {
                  setProductType((prevValue) => [...prevValue, data.id]);
                }
              }}
            />
            <p>{data.name}</p>
          </div>
        ))}
      </div>

      <div className="mt-5 flex justify-center flex-col py-0 lg:py-4 lg:px-20 ">
        <h1 className="text-center font-medium text-2xl">Export Product</h1>
        <div>
          <p className="text-center text-[20px] mt-5">
            Mohon pilih outlet dan tipe produk yang ingin di export
          </p>
          <div className="flex flex-wrap justify-center gap-5 my-5">
            <div className="flex items-center transform duration-200 hover:scale-105">
              <label
                className="cursor-pointer flex flex-col items-center justify-center h-64 border-2 rounded-xl w-[500px]"
                onClick={() =>
                  mutateExportXlsxInventory({
                    company_id: companyId.map((data) => data.company_id),
                    type_product: productType,
                    warehouse: isWarehouse ? isWarehouse : false,
                  })
                }
              >
                <div className="flex flex-col items-center justify-center p-5">
                  <TiExport className="text-[90px] text-blue-500" />
                  <p className="font-medium text-l text-blue-400 mt-3">
                    Export Data Here
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSuccessMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ExportProduct;
