import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import MedicalRecordList from "../../../components/screen/patientCare/medicalRecord/MedicalRecordList";

const MedicalRecordListPage = () => {
  return (
    <PageTemplate
      header="Patient Medical Record"
      mainComponent={<MedicalRecordList />}
    />
  );
};

export default HOC(MedicalRecordListPage);
