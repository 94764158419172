import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { TbNotes } from "react-icons/tb";
import ModalNoteInbox from "./ModalNoteInbox";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getWarehouseInboxDetail } from "../../../../services/centralWarehouseApi";
import { useNavigate, useParams } from "react-router-dom";
import ModalChangeStatusWarehouse from "./ModalChangeStatusWarehouse";

const InboxDetailWarehouse = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedNote, setSelectedNote] = useState(null);
  const [receiveArr, setReceiveArr] = useState([]);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [action, setAction] = useState(0);

  const changeStock = (i, action, val) => {
    var temp = [...receiveArr];

    if (action === "min") temp[i].quantity -= 1;
    if (action === "add") temp[i].quantity += 1;
    if (action === "text") {
      if (!isNaN(Number(val))) temp[i].quantity = Number(val);
      if (isNaN(Number(val))) temp[i].quantity = 0;
    }

    if (temp[i].quantity >= dataDetail.product[i].quantity) temp[i].reason = 0;

    setReceiveArr(temp);
  };

  const {
    mutate: mutateDetail,
    data: dataDetail,
    isLoading,
  } = useMutation({
    mutationFn: getWarehouseInboxDetail,
  });

  const isValidChange = useMemo(() => {
    if (dataDetail)
      return dataDetail.product.every(
        (item, i) =>
          receiveArr[i].quantity >= item.quantity || receiveArr[i].reason !== 0
      );
    else return false;
  }, [receiveArr]);

  useEffect(() => {
    mutateDetail({
      id: Number(id.split("-")[0]),
      type: Number(id.split("-")[1]),
    });
  }, [id]);

  useEffect(() => {
    if (dataDetail && dataDetail.product)
      setReceiveArr(
        dataDetail.product.map((item) => ({
          id: item.id,
          quantity: item.quantity_received,
          reason: isNaN(Number(item.reason)) ? 0 : item.reason,
          notes: item.notes,
        }))
      );
  }, [dataDetail]);

  return (
    <>
      <div className="flex gap-2 mb-4">
        <div className="w-full">
          {dataDetail && !isLoading ? (
            <>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Transaction ID</p>
                  <p>: {dataDetail.header.transaction_number}</p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">From</p>
                  <p>: {dataDetail.header.source}</p>
                </div>
              </div>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Date</p>
                  <p>
                    :{" "}
                    {dataDetail.header.date !== ""
                      ? moment(dataDetail.header.date).format("DD MMMM YYYY")
                      : ""}
                  </p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">To</p>
                  <p>: {dataDetail.header.target}</p>
                </div>
              </div>
              <div className="flex gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">Transaction Type</p>
                  <p>: {dataDetail.header.transaction_type}</p>
                </div>
                <div className="flex gap-2 items-center w-full">
                  <p className="w-1/3">User</p>
                  <p>: {dataDetail.header.created_by}</p>
                </div>
              </div>
            </>
          ) : isLoading ? (
            <CircularProgress size={20} />
          ) : null}
        </div>
        <div className="basis-1/3 flex flex-col justify-center">
          {dataDetail && !isLoading ? (
            <>
              {dataDetail.header.status === 1 ? (
                <div className="flex gap-4 items-center">
                  <button
                    className="w-full border-2 rounded-md p-2 border-red-500 text-red-500 hover:bg-red-100 disabled:bg-gray-300 disabled:border-none disabled:text-white"
                    disabled={!isValidChange}
                    onClick={() => {
                      setAction(2);
                      setOpenChangeStatus(true);
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className="w-full border-2 rounded-md p-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 disabled:bg-gray-300 disabled:border-gray-300"
                    disabled={!isValidChange}
                    onClick={() => {
                      setAction(1);
                      setOpenChangeStatus(true);
                    }}
                  >
                    Accept
                  </button>
                </div>
              ) : dataDetail.header.status === 2 ||
                dataDetail.header.status === 4 ? (
                <div className="w-full border-2 rounded-md p-2 border-blue-500 text-blue-500 bg-blue-100 text-center">
                  Accepted
                </div>
              ) : (
                <div className="w-full border-2 rounded-md p-2 border-red-500 text-red-500 bg-red-100 text-center">
                  Rejected
                </div>
              )}
            </>
          ) : null}
          {dataDetail && !isLoading ? (
            dataDetail.header.status === 1 ? null : (
              <p className="text-left block mt-2">
                {dataDetail.header.status === 2 ||
                dataDetail.header.status === 4
                  ? "Accepted"
                  : "Rejected"}{" "}
                in:{" "}
                {dataDetail.header.date === ""
                  ? moment().format("DD MMMM YYYY")
                  : moment(dataDetail.header.date).format("DD MMMM YYYY")}
              </p>
            )
          ) : null}
        </div>
      </div>

      <p className="font-bold">Product Information</p>
      <hr className="my-2" />

      <div className="mb-4">
        <div className="flex w-full mb-4">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="py-2 text-center">No</th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Product Name
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Batch Number
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Expired Date
                  </th>
                  <th className="py-2 text-center">UOM</th>
                  <th className="py-2 text-center">Qty Sent</th>
                  <th className="py-2 text-center">Qty Received</th>
                  <th className="py-2 text-center">Qty Back</th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Final Price
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Total
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Reason
                  </th>
                  <th className="py-2 text-center">Notes</th>
                </thead>
                <tbody>
                  {dataDetail &&
                  !isLoading &&
                  dataDetail.product &&
                  receiveArr.length > 0 ? (
                    dataDetail.product.map((item, i) => (
                      <tr key={i}>
                        <td className="py-2 px-3 text-center border">
                          {i + 1}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {item.product_name}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {item.batch}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {moment(item.expired_date).format("DD-MM-YYYY")}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          {item.uom}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          {item.quantity}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          {dataDetail.header.status === 1 ? (
                            <div className="flex justify-center bg-slate-100 rounded-md border-2 p-1">
                              <button
                                disabled={receiveArr[i].quantity === 0}
                                className="text-red-500 hover:text-red-700 disabled:text-gray-300"
                                onClick={() => {
                                  changeStock(i, "min");
                                }}
                              >
                                <LuMinusCircle className="text-[16px]" />
                              </button>
                              <input
                                type="text"
                                className="bg-slate-100 p-2 w-[80px] text-center"
                                value={receiveArr[i].quantity}
                                onChange={(e) =>
                                  changeStock(i, "text", e.target.value)
                                }
                              />
                              <button
                                className="text-blue-500 hover:text-blue-700"
                                onClick={() => {
                                  changeStock(i, "add");
                                }}
                              >
                                <LuPlusCircle className="text-[16px]" />
                              </button>
                            </div>
                          ) : (
                            item.quantity_received
                          )}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          {receiveArr[i].quantity >= item.quantity
                            ? item.quantity_back
                            : item.quantity - receiveArr[i].quantity}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {item.hna.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {(dataDetail.header.status === 1
                            ? item.hna * receiveArr[i].quantity
                            : item.total
                          ).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="py-2 px-3 text-left border">
                          {dataDetail.header.status === 1 ? (
                            <Select
                              value={receiveArr[i].reason}
                              onChange={(e) => {
                                var temp = [...receiveArr];
                                temp[i].reason = e.target.value;
                                setReceiveArr(temp);
                              }}
                              disabled={receiveArr[i].quantity >= item.quantity}
                              sx={{ width: "100%" }}
                            >
                              <MenuItem value={0} disabled>
                                Choose Reason
                              </MenuItem>
                              <MenuItem value={1}>Barang Hilang</MenuItem>
                              <MenuItem value={2}>Barang Rusak</MenuItem>
                            </Select>
                          ) : (
                            item.reason
                          )}
                        </td>
                        <td className="py-2 px-3 text-center border">
                          <div className="flex justify-center items-center">
                            <TbNotes
                              className="text-[18px] cursor-pointer hover:text-gray-300"
                              onClick={() =>
                                setSelectedNote({
                                  index: i,
                                  ...item,
                                  notes: receiveArr[i].notes,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-2 px-3 text-center border" colSpan={12}>
                        {isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada produk"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ModalNoteInbox
        note={selectedNote}
        setNote={setSelectedNote}
        handleNoteSave={(e) => {
          var temp = [...receiveArr];
          temp[selectedNote.index].notes = e;
          setReceiveArr(temp);
          setSelectedNote(null);
        }}
        canChange={dataDetail ? dataDetail.header.status === 1 : false}
      />

      <ModalChangeStatusWarehouse
        open={openChangeStatus}
        setOpen={setOpenChangeStatus}
        item={{ id: Number(id.split("-")[0]) }}
        refetch={() => {
          navigate(
            `/central-warehouse/inbox/${type === 1 ? "warehouse" : "outlet"}/`
          );
        }}
        type={Number(id.split("-")[1])}
        action={action}
        product={receiveArr}
      />
    </>
  );
};

export default InboxDetailWarehouse;
