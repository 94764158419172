import React from "react";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import CreatePurchase from "../../../components/screen/inventory/productHabis/CreatePurchase";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const CreatePurchasePage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <CreatePurchase />
        </div>
      </div>
    </div>
  );
};

export default CreatePurchasePage;
