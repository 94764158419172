import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Close as CloseIcon } from "@mui/icons-material";

import { createCustomer } from "../../../services/customerApi";

const validationSchema = yup.object({
  name: yup.string().required("Nama wajib diisi"),
  phone: yup.string().required("No Telpon wajib diisi"),
  email: yup.string().email().required("Email wajib diisi"),
  address: yup.string().required("Alamat wajib diisi"),
});

const CreateCustomerModal = (props) => {
  const { isAddCustomerOpen, setIsAddCustomerOpen } = props;

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      address: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutateCreateCustomer(values);
    },
  });

  const { mutate: mutateCreateCustomer } = useMutation({
    mutationFn: createCustomer,
    onSuccess: () => {
      setIsAddCustomerOpen(false);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      open={isAddCustomerOpen}
      onClose={() => {
        setIsAddCustomerOpen(false);
        formik.resetForm();
      }}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ display: "flex" }}>
          <p className="text-center w-full">Tambah Pelanggan</p>
          <button
            type="reset"
            onClick={() => {
              setIsAddCustomerOpen(false);
              formik.resetForm();
            }}
          >
            <CloseIcon
              sx={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            />
          </button>
        </DialogTitle>
        <DialogContent>
          <div className="grid gap-y-3">
            <p className="font-bold">Nama</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.name && Boolean(formik.errors.name)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            {formik.touched.name && Boolean(formik.errors.name) ? (
              <p className="text-[#D32F2F]">{formik.errors.name}</p>
            ) : null}
            <p className="font-bold">No. Telp</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.phone && Boolean(formik.errors.phone)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
            />
            {formik.touched.phone && Boolean(formik.errors.phone) ? (
              <p className="text-[#D32F2F]">{formik.errors.phone}</p>
            ) : null}
            <p className="font-bold">Email</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.email && Boolean(formik.errors.email)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
            {formik.touched.email && Boolean(formik.errors.email) ? (
              <p className="text-[#D32F2F]">{formik.errors.email}</p>
            ) : null}
            <p className="font-bold">Alamat</p>
            <input
              className={`p-2 rounded-md w-full ${
                formik.touched.address && Boolean(formik.errors.address)
                  ? "border-2 border-[#D32F2F]"
                  : "border border-black"
              }`}
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.value)}
            />
            {formik.touched.address && Boolean(formik.errors.address) ? (
              <p className="text-[#D32F2F]">{formik.errors.address}</p>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-center w-full h-[40px]">
            <button
              className="rounded border w-full mr-1"
              type="reset"
              onClick={() => {
                setIsAddCustomerOpen(false);
                formik.resetForm();
              }}
            >
              Batal
            </button>
            <button
              className="bg-[#007AF1] rounded text-white w-full"
              type="submit"
            >
              Simpan
            </button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCustomerModal;
