const NotificationAlertStatusUserCode = (props) => {
  const { statusUserCode } = props;
  return (
    <>
      {statusUserCode === 1 ? (
        <div className="flex flex-row justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            Akun Anda sedang menunggu verifikasi, Anda tidak dapat menggunakan
            fitur pembelian hingga akun Anda terverifikasi
          </p>
        </div>
      ) : null}
      {statusUserCode === 2 ? (
        <div className="flex flex-row justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            Pengajuan akun Anda ditolak. Silahkan cek alasan ditolak di Detail
            Informasi Akun
          </p>
          <button className="px-10 py-2 bg-[#FF8D40] rounded-lg text-white font-semibold">
            Detail Informasi Akun
          </button>
        </div>
      ) : null}
    </>
  );
};

export default NotificationAlertStatusUserCode;
