import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const SendOtpModal = (props) => {
  const { isModalOpen, setIsModalOpen, mutateOtpCheckout } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="bg-gray-50 rounded-full p-2">
          <AiOutlineQuestionCircle className="rounded-full bg-blue-100 text-blue-500 p-2 text-[40px]" />
        </div>
        <button onClick={() => setIsModalOpen(false)}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        <div className="mb-3">
          <p className="font-bold">Kirim kode OTP ke Owner</p>
          <p className="font-medium">
            Untuk menyelesaikan pesanan, anda harus memasukkan kode OTP yang
            telah kami kirimkan ke owner apotek
          </p>
        </div>
        <div className="flex font-bold justify-center w-full">
          <button
            className="border border-black rounded-md p-2 mr-2 px-4"
            onClick={() => setIsModalOpen(false)}
          >
            Batal
          </button>
          <button
            className="rounded-md p-2 text-white bg-[#007AF1]"
            onClick={() => mutateOtpCheckout()}
          >
            Lanjut
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendOtpModal;
