import { createSlice } from "@reduxjs/toolkit";
import cookie from "js-cookie";
import axios from "axios";

function createCookie(name, value, minutes) {
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

const initialState = {
  accessTokenOfficeOlin: "",
  accessExpired: "",
  renewTokenOfficeOlin: "",
  renewExpired: "",
  img_profile: "",
  name: "",
  company_name: "",
  job: "",
  title: "",
  company_id: 0,
  roles: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.accessTokenOfficeOlin = payload.accessTokenOfficeOlin;
      state.accessExpired = payload.accessExpired;
      state.renewTokenOfficeOlin = payload.renewTokenOfficeOlin;
      state.renewExpired = payload.renewExpired;
      state.img_profile = payload.img_profile;
      state.name = payload.name;
      state.company_name = payload.company_name;
      state.job = payload.job;
      state.title = payload.title;
      state.company_id = payload.company_id;
      state.roles = payload.roles;
      createCookie("accessTokenOfficeOlin", payload.accessTokenOfficeOlin, 60);
      createCookie("accessExpired", payload.accessExpired);
      createCookie("renewTokenOfficeOlin", payload.renewTokenOfficeOlin);
      createCookie("renewExpired", payload.renewExpired);
      localStorage.setItem("img_profile", payload.img_profile);
      localStorage.setItem("name", payload.name);
      localStorage.setItem("company_name", payload.company_name);
      localStorage.setItem("job", payload.job);
      localStorage.setItem("title", payload.title);
      localStorage.setItem("company_id", payload.company_id);
      localStorage.setItem("roles", payload.roles);
    },

    renewTokenUser: () => {
      const refreshToken = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_ACCOUNT}token/renew`,
            {
              headers: {
                Authorization: "Bearer " + cookie.get("renewTokenOfficeOlin"),
              },
            }
          );
          // state.accessTokenOfficeOlin = response.data.detail.token;
          // state.accessExpired = response.data.detail.token_expired;
          createCookie("accessTokenOfficeOlin", response.data.detail.token, 60);
          createCookie("accessExpired", response.data.detail.token_expired);
        } catch (error) {
          //
        }
      };
      refreshToken();
    },

    logout: (state) => {
      state.accessTokenOfficeOlin = null;
      state.accessExpired = null;
      state.renewTokenOfficeOlin = null;
      state.renewExpired = null;
      cookie.remove("accessTokenOfficeOlin");
      cookie.remove("accessExpired");
      cookie.remove("renewTokenOfficeOlin");
      cookie.remove("renewExpired");
      localStorage.removeItem("img_profile");
      localStorage.removeItem("name");
      localStorage.removeItem("company_name");
      localStorage.removeItem("job");
      localStorage.removeItem("title");
      localStorage.removeItem("company_id");
      localStorage.removeItem("roles");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, switchUser, renewTokenUser, logout } =
  authSlice.actions;

export default authSlice.reducer;
