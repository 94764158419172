import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsapp,
} from "react-icons/fa";
import { MdCancel, MdOutlineFileUpload } from "react-icons/md";
import moment from "moment";

import {
  getPreviewInvoice,
  createInvoiceLabel,
} from "../../../services/ReportingApi";
import { uploadImage, deleteImage } from "../../../services/userManagementApi";
import Breadcumbs from "../../common/particles.jsx/Breadcumbs";

const InvoiceSetting = () => {
  const queryClient = useQueryClient();
  const [apiMessage, setApiMessage] = useState("");
  const [invoice, setInvoice] = useState({
    ukuran: "",
    logo: "",
    name_outlet: "",
    nama_apoteker: "",
    sia: "",
    sipa: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    footer: "",
    subtotal_alias: "",
    discount_alias: "",
    total_alias: "",
    change_money_alias: "",
    social_media_instagram: "",
    social_media_facebook: "",
    social_media_twitter: "",
    copy_invoice: 0,
  });
  const [checked, setChecked] = useState(false);

  const { data: invoicePreviewData, isLoading: isInvoicePreviewLoading } =
    useQuery({
      queryKey: ["invoice-preview"],
      queryFn: () => getPreviewInvoice(),
    });

  const { mutate: mutateCreateInvoice } = useMutation({
    mutationFn: createInvoiceLabel,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["invoice-preview"],
      });
      setApiMessage(data.message.id);
    },
    onError: (err) => {
      setApiMessage(err.message.id);
    },
  });

  const { mutate: mutateUploadImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["invoice-preview"],
      });
      setInvoice((prevValue) => ({ ...prevValue, logo: data.url }));
      setApiMessage("Berhasil mengubah logo");
    },
    onError: (err) => {
      setApiMessage(err.response.data.message.id);
    },
  });

  const { mutate: mutateDeleteImage } = useMutation({
    mutationFn: deleteImage,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["invoice-preview"],
      });
      setInvoice((prevValue) => ({ ...prevValue, logo: "" }));
      setApiMessage(data.message.id);
    },
    onError: (err) => {
      setApiMessage(err.message.id);
    },
  });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setApiMessage("");
  };

  useEffect(() => {
    if (invoicePreviewData && invoicePreviewData !== undefined)
      setInvoice(invoicePreviewData);
  }, [invoicePreviewData]);

  useEffect(() => {
    if (!checked)
      setInvoice((prevValue) => ({ ...prevValue, copy_invoice: 0 }));
  }, [checked]);

  return (
    <div className="container mx-auto w-full  mt-16 lg:mt-0">
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Invoice Setting
      </h1>

      <Breadcumbs
        nama1="Setting"
        nama2="Invoice Settings"
        link1="/settings/invoice-setting"
        link2="/settings/invoice-setting"
      />

      {isInvoicePreviewLoading ? (
        <div className="flex justify-center items-center h-[500px]">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <div className="w-full mt-5">
          {invoicePreviewData && invoicePreviewData !== undefined ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 text-center gap-4 mt-5">
              <div className="flex flex-col p-2">
                {invoice.logo.length > 0 ? (
                  <>
                    <div className="relative">
                      <button
                        type="button"
                        onClick={(e) => mutateDeleteImage(invoice.logo)}
                      >
                        <MdCancel className="text-red-500 hover:text-gray-800 text-[30px] absolute right-[-10px]" />
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full h-64 border-dashed border-2 border-gray-300 rounded-lg">
                      <img
                        src={invoice.logo}
                        alt=""
                        className="w-[30%] h-[60%]"
                      />
                    </div>
                  </>
                ) : (
                  <label
                    htmlFor="dropzone-file1"
                    className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-dashed border-2 border-gray-300 rounded-lg"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <MdOutlineFileUpload className="text-[90px] text-blue-500" />
                      <p className="font-medium text-l text-blue-400 mt-3">
                        Upload foto product
                      </p>
                      <p className="text-center">
                        <span className="">Ukuran foto maksimal 1 mb</span>
                      </p>
                    </div>
                    <input
                      id="dropzone-file1"
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => mutateUploadImage(e.target.files[0])}
                      // accept="accept=' image/jpeg, image/png, image/jpg"
                    />
                  </label>
                )}

                <div className="mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Ukuran Kertas
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Ukuran Kertas"
                      value={invoice.ukuran}
                      onChange={(e) =>
                        setInvoice((prevValue) => ({
                          ...prevValue,
                          ukuran: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={58}>58mm</MenuItem>
                      <MenuItem value={80}>80mm</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <p className="font-bold my-3 text-start">
                  Penyesuaian Teks Bagian Atas
                </p>
                <div className="grid grid-cols-1 gap-3">
                  <TextField
                    id="outlined-basic"
                    label="Nama Outlet"
                    variant="outlined"
                    value={invoice.name_outlet}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        name_outlet: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Nama Apoteker Penanggung Jawab"
                    variant="outlined"
                    value={invoice.nama_apoteker}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        nama_apoteker: e.target.value,
                      }))
                    }
                  />

                  <TextField
                    id="outlined-basic"
                    label="SIA"
                    variant="outlined"
                    value={invoice.sia}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        sia: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="SIPA"
                    variant="outlined"
                    value={invoice.sipa}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        sipa: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    value={invoice.address}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        address: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    value={invoice.city}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        city: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    value={invoice.state}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        state: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Zipcode"
                    variant="outlined"
                    value={parseInt(invoice.zipcode)}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        zipcode: parseInt(e.target.value),
                      }))
                    }
                  />

                  <p className="font-bold text-start">No Handphone</p>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="No Handphone"
                    value={invoice.phone}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        phone: e.target.value,
                      }))
                    }
                  />

                  <p className="font-bold text-start">Social Media</p>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Facebook"
                    value={invoice.social_media_facebook}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        social_media_facebook: e.target.value,
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaFacebookSquare className="text-[23px]" />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    id="input-with-icon-textfield"
                    label="Twitter"
                    value={invoice.social_media_twitter}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        social_media_twitter: e.target.value,
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaTwitterSquare className="text-[23px]" />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    id="input-with-icon-textfield"
                    label="Instagram"
                    value={invoice.social_media_instagram}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        social_media_instagram: e.target.value,
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaInstagramSquare className="text-[23px]" />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <p className="font-bold my-3 text-start">
                    Penyesuaian Lebih Lanjut
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Total"
                    variant="outlined"
                    value={invoice.total_alias}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        total_alias: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Discount"
                    variant="outlined"
                    value={invoice.discount_alias}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        discount_alias: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Subtotal"
                    variant="outlined"
                    value={invoice.subtotal_alias}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        subtotal_alias: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Kembali"
                    variant="outlined"
                    value={invoice.change_money_alias}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        change_money_alias: e.target.value,
                      }))
                    }
                  />
                  <p className="font-bold my-3 text-start">
                    Penyesuaian Teks Bagian Footer
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Greetings"
                    variant="outlined"
                    value={invoice.footer}
                    onChange={(e) =>
                      setInvoice((prevValue) => ({
                        ...prevValue,
                        footer: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex justify-start items-center mt-3">
                  <input
                    type="checkbox"
                    className="w-[20px] h-[20px] mr-2"
                    onChange={() => setChecked((prevValue) => !prevValue)}
                  />
                  <p>Buat Salinan Invoice</p>
                </div>
                {checked ? (
                  <div className="flex justify-start items-center mt-3">
                    <p>Jumlah Salinan</p>
                    <input
                      className="w-[60px] h-[40px] ml-2 border border-black rounded-md p-2 text-center"
                      value={invoice.copy_invoice}
                      onChange={(e) =>
                        setInvoice((prevValue) => ({
                          ...prevValue,
                          copy_invoice: Number(e.target.value),
                        }))
                      }
                    />
                  </div>
                ) : null}
              </div>

              <div className="bg-blue-500 h-full">
                <h3 className="text-white font-bold text-[20px] mt-5">
                  Preview
                </h3>
                <div className="flex flex-col  mt-3 lg:p-3 gap-2 bg-white m-3 p-3 text-[13px] lg:text-base rounded-lg shadow-md">
                  <div className="flex items-center justify-center mt-2">
                    <img
                      src={invoicePreviewData.logo}
                      className="w-20 rounded-lg"
                      alt=""
                    />
                  </div>
                  <p className="font-semibold text-[20px]">
                    {invoicePreviewData.name_outlet}
                  </p>
                  <p>
                    {invoicePreviewData.address}, {invoicePreviewData.city},{" "}
                    {invoicePreviewData.state}, {invoicePreviewData.zipcode}
                  </p>
                  <div className="flex flex-row justify-between mt-5">
                    <p>Apoteker</p>
                    <p>{invoicePreviewData.nama_apoteker}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Sia</p>
                    <p>{invoicePreviewData.sia}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Sipa</p>
                    <p>{invoicePreviewData.sipa}</p>
                  </div>
                  <hr />
                  <div className="flex flex-row justify-between">
                    <p>Nomor Order</p>
                    <p>TRX-{Math.floor(Math.random() * 1000000000)}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Tanggal Order</p>
                    <p>{moment().format("MMMM Do YYYY, h:mm:ss a")}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Kasir</p>
                    <p>{invoicePreviewData.nama_kasir}</p>
                  </div>
                  <hr />
                  <p className="font-bold text-start">Racikan</p>
                  <p className="font-bold text-start">LAGESIL TABLET</p>
                  <div className="flex flex-row justify-between">
                    <p>1 x 1.725 = </p>
                    <p>1.725</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Biaya Racikan</p>
                    <p>6.000</p>
                  </div>

                  <p className="font-bold text-start">Non-Racikan</p>
                  <p className="font-bold text-start">LAGESIL TABLET</p>
                  <div className="flex flex-row justify-between">
                    <p>1 x 1.725 = </p>
                    <p>1.725</p>
                  </div>
                  <p className="font-bold text-start">LAGESIL SYRUP 170 ML</p>
                  <div className="flex flex-row justify-between">
                    <p>1 x 483 = </p>
                    <p>483</p>
                  </div>
                  <hr />

                  <p className="font-bold text-start">Regular</p>
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-start">LAGESIL TABLET</p>
                    <p>@STRIP</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>1 x 17.250 = </p>
                    <p>17.250</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-start">STROMAG TABLET</p>
                    <p>@STRIP</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>1 x 69.420 = </p>
                    <p>69.420</p>
                  </div>

                  <hr />
                  <div className="flex flex-row justify-between ">
                    <p>Kartu Kredit</p>
                    <p>Rp.45.000</p>
                  </div>
                  <div className="flex flex-row justify-between ">
                    <p>Cash</p>
                    <p>Rp.100.000</p>
                  </div>
                  <hr />
                  <div className="flex flex-row justify-between ">
                    <p>{invoicePreviewData.subtotal_alias}</p>
                    <p>Rp.155.000</p>
                  </div>
                  <div className="flex flex-row justify-between ">
                    <p>{invoicePreviewData.discount_alias}</p>
                    <p>Rp.10.000</p>
                  </div>
                  <div className="flex flex-row justify-between ">
                    <p>{invoicePreviewData.total_alias}</p>
                    <p>Rp.145.000</p>
                  </div>
                  <div className="flex flex-row justify-between ">
                    <p>{invoicePreviewData.change_money_alias}</p>
                    <p>Rp.0</p>
                  </div>
                  <p className="text-center font-bold my-4">{invoice.footer}</p>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <FaFacebookSquare />{" "}
                    {invoicePreviewData.social_media_facebook}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <FaTwitterSquare />{" "}
                    {invoicePreviewData.social_media_twitter}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <FaInstagramSquare />{" "}
                    {invoicePreviewData.social_media_instagram}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <FaWhatsapp /> {invoicePreviewData.phone}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className="flex items-center justify-center gap-5">
        <button className="mt-4 p-2 px-5 bg-[#F21B3F] text-white font-bold rounded-lg">
          Batal
        </button>
        <button
          className="mt-4 p-2 px-5 bg-[#FF8D40] text-white font-bold rounded-lg"
          onClick={() => mutateCreateInvoice(invoice)}
        >
          Konfirmasi
        </button>
      </div>
      <Snackbar
        open={Boolean(apiMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setApiMessage("");
        }}
        message={apiMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default InvoiceSetting;
