import moment from "moment";
import "moment/locale/id";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import LogoOlin from "../../../assets/icons/logoOlin.png";

const RecipePreview = ({
  receipeValue,
  isCreate,
  handleClose,
  isCart,
  isPreview,
}) => {
  moment.locale("id");

  const convertToRoman = (num) => {
    const romanNumerals = [
      { value: 1000, numeral: "M" },
      { value: 900, numeral: "CM" },
      { value: 500, numeral: "D" },
      { value: 400, numeral: "CD" },
      { value: 100, numeral: "C" },
      { value: 90, numeral: "XC" },
      { value: 50, numeral: "L" },
      { value: 40, numeral: "XL" },
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 1, numeral: "I" },
    ];

    let result = "";
    for (const { value, numeral } of romanNumerals) {
      while (num >= value) {
        result += numeral;
        num -= value;
      }
    }
    return result;
  };

  const doctorName = localStorage.getItem("fullname");
  const sip = localStorage.getItem("no_sip");
  const address = localStorage.getItem("address");
  const phone = localStorage.getItem("phone_number");

  return (
    <>
      {isPreview && (
        <div className="relative">
          <MdOutlineKeyboardArrowDown
            className="text-xl text-gray-500 absolute top-0 right-2 cursor-pointer hover:text-gray-800"
            onClick={handleClose}
          />
        </div>
      )}
      {isPreview && <p className="text-center font-semibold mb-2">Preview</p>}
      <div className="border border-gray-300 rounded-xl p-6 flex flex-col min-h-[600px] h-full">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="w-full">
              {isCreate ? (
                <p className="font-bold text-[16px] mb-1">{doctorName}</p>
              ) : (
                <p className="font-bold text-[16px] mb-1">
                  {receipeValue?.doctor_name}
                </p>
              )}
              <div className="flex w-full items-center justify-between">
                <div className="flex text-[12px]">
                  <div>
                    <p>No. SIP</p>
                    <p>Alamat</p>
                    <p>No. Telp</p>
                  </div>
                  <div className="ml-3 mr-1">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                  </div>
                  {isCreate ? (
                    <div>
                      <p className="break-words">{sip}</p>
                      <p className="break-words">{address}</p>
                      <p className="break-words">{phone}</p>
                    </div>
                  ) : (
                    <div>
                      <p className="break-words">
                        {isCart
                          ? receipeValue?.no_sip
                          : receipeValue.doctor_sip}
                      </p>
                      <p className="break-words">
                        {isCart
                          ? receipeValue?.address
                          : receipeValue.doctor_address}
                      </p>
                      <p className="break-words">
                        {isCart
                          ? receipeValue?.phone_number
                          : receipeValue.doctor_phone_number}
                      </p>
                    </div>
                  )}
                </div>
                <div className="">
                  <p className="text-[12px]">Powered by</p>
                  <img src={LogoOlin} alt="Olin Logo" />
                </div>
              </div>
            </div>
          </div>
          <div className="border border-black my-2"></div>
          <div>
            <div className="text-[12px] flex justify-between">
              <div className="w-full flex justify-end">
                {isCreate ? (
                  <p>
                    {moment(new Date(), "YYYY-MM-DD").format("DD MMMM YYYY")}
                  </p>
                ) : (
                  <p>
                    {moment(receipeValue?.prescription_date).format(
                      "D MMMM YYYY"
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="my-3">
            {receipeValue?.list_product?.map((drug, index) => (
              <div className="w-full text-[12px] mt-2" key={index}>
                <div className="flex w-full">
                  <p className="mr-4">R/</p>
                  <div className="flex justify-between items-center w-full">
                    <div className="">
                      <p>
                        {drug?.drug_name === "" ? "-" : drug?.drug_name}{" "}
                        {drug?.uom ? " " + drug?.uom.toLowerCase() : ""}
                        {drug?.quantity === ""
                          ? "-"
                          : " no. " + convertToRoman(drug?.quantity)}
                      </p>
                      <p>S. {drug?.instruction_use}</p>
                    </div>
                    {drug?.iter_qty > 0 && (
                      <p className="text-center text-blue-500 border-2 border-blue-500 rounded-md px-1 min-w-14">
                        Iter {drug?.iter_qty}x
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex justify-end ml-8 mt-2 items-center w-full border-b border-black"></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex text-[12px] mt-4">
          <div>
            <p>Pro</p>
            <p>Umur</p>
          </div>
          <div className="ml-3 mr-1">
            <p>:</p>
            <p>:</p>
          </div>
          <div className="">
            <p>
              {receipeValue?.customer_name === ""
                ? "-"
                : receipeValue?.customer_name}
            </p>
            <p>
              {receipeValue?.customer_age} Tahun (
              {receipeValue?.customer_weight}
              Kg)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipePreview;
