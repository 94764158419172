import { useNavigate, useParams } from "react-router-dom";
import SubjectiveObjectiveAnalysis from "./SubjectiveObjectiveAnalysis";
import { useFormik } from "formik";
import { useQuery } from "@tanstack/react-query";
import {
  getDetailInitialAnalysis,
  getDetailPrescription,
  setDoctorAnalysis,
} from "../../../../services/patientCare";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaUser } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  getPatientCareDoctor,
  getPatientList,
} from "../../../../services/patientCare";
import { useMutation } from "@tanstack/react-query";
import useDebounce from "../../../hooks/useDebounce";
import { Search } from "@mui/icons-material";
import PatientAnalysisHistory from "../patientCareInitialAnalysis/PatientAnalysisHistory";
import AssessPlanAnalysis from "./AssessPlanAnalysis";
import DoctorConcoction from "./DoctorConcoction";
import ModalSavePatientCare from "../ModalSavePatientCare";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import ModalAddPatient from "../patientCarePatient/ModalAddPatient";
import ModalAddEditDoctor from "../patientCareDoctor/ModalAddEditDoctor";
import { Close as CloseIcon } from "@mui/icons-material";

const toDate = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const AddDoctorAnalysis = ({ isDetail }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [anchorElPatient, setAnchorElPatient] = useState(null);
  const [anchorElDoctor, setAnchorElDoctor] = useState(null);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [openAddPatient, setOpenAddPatient] = useState(false);
  const [openAddDoctor, setOpenAddDoctor] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isFormView, setIsFormView] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const debounceDoctor = useDebounce(searchDoctor, 500);
  const debouncePatient = useDebounce(searchPatient, 500);

  const handleClosePatient = () => {
    setAnchorElPatient(null);
    setSearchPatient("");
  };

  const handleCloseDoctor = () => {
    setAnchorElDoctor(null);
    setSearchDoctor("");
  };

  const fetchPatient = (searchStr) => {
    mutateListPatient({
      search: searchStr,
      offset: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      sort: 1,
      gender: 0,
      status: 0,
    });
  };

  const fetchDoctor = (searchStr) => {
    mutateListDoctor({
      search: searchStr,
      limit: 10,
      offset: 1,
      specialization_id: 0,
    });
  };

  const {
    mutate: mutateListDoctor,
    data: dataListDoctor,
    isLoading: isLoadingDoctor,
  } = useMutation({
    mutationFn: getPatientCareDoctor,
  });

  const {
    mutate: mutateListPatient,
    data: dataListPatient,
    isLoadingPatient: isLoadingPatient,
  } = useMutation({
    mutationFn: getPatientList,
  });

  const { mutate: mutateAnalysis, isLoading: isLoading } = useMutation({
    mutationFn: setDoctorAnalysis,
    onSuccess: () => {
      setOpenSave(false);
      setOpenConfirm(true);
    },
    onError: (err) => {
      setErrorMessage(err ? err.id : "");
      setIsSave(false);
    },
  });

  useEffect(() => {
    fetchDoctor(debounceDoctor);
  }, [debounceDoctor]);

  useEffect(() => {
    fetchPatient(debouncePatient);
  }, [debouncePatient]);

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-initial-analysis", id],
    queryFn: () => getDetailInitialAnalysis(id),
    enabled: id !== null && id !== undefined,
    refetchOnWindowFocus: false,
  });

  const { data: dataPrescription, isFetching: isFetchingPrescription } =
    useQuery({
      queryKey: ["detail-prescription", id],
      queryFn: () => getDetailPrescription(id),
      enabled: id !== null && id !== undefined,
      refetchOnWindowFocus: false,
    });

  const formik = useFormik({
    initialValues: {
      patient_id:
        dataDetail && dataDetail.patient_id ? dataDetail.patient_id : 0,
      doctor_id: dataDetail && dataDetail.doctor_id ? dataDetail.doctor_id : 0,
      check_date:
        dataDetail && dataDetail.check_date
          ? toDateApi(dataDetail.check_date)
          : toDate,
      subjective: {
        birth_history:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.birth_history
            : "",
        existing_illness:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.existing_illness
            : "",
        development_history:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.development_history
            : "",
        current_medication:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.current_medication
            : "",
        main_complaint:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.main_complaint
            : "",
        social_history:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.social_history
            : "",
        family_history:
          dataDetail && dataDetail.subjective
            ? dataDetail.subjective.family_history
            : "",
      },
      objective: {
        systole:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.systole
            : "",
        diastole:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.diastole
            : "",
        respiratory_rate:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.respiratory_rate
            : "",
        body_temp:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.body_temp
            : "",
        pulse:
          dataDetail && dataDetail.objective ? dataDetail.objective.pulse : "",
        oxygen:
          dataDetail && dataDetail.objective ? dataDetail.objective.oxygen : "",
        pain_level:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.pain_level
            : "",
        pain_location:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.pain_location
            : "",
        head_circumference:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.head_circumference
            : "",
        height:
          dataDetail && dataDetail.objective ? dataDetail.objective.height : "",
        weight:
          dataDetail && dataDetail.objective ? dataDetail.objective.weight : "",
        physical_exam:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.physical_exam
            : "",
        support_exam:
          dataDetail && dataDetail.objective
            ? dataDetail.objective.support_exam
            : "",
      },
      img: dataDetail ? dataDetail.img : [],
      exam_img: dataDetail ? dataDetail.exam_img : [],
      assessment: {
        diagnosis:
          dataDetail && dataDetail.assessment
            ? dataDetail.assessment.diagnosis
            : "",
        diff_diagnosis:
          dataDetail && dataDetail.assessment
            ? dataDetail.assessment.diff_diagnosis
            : "",
      },
      plan: {
        treatment_plan:
          dataDetail && dataDetail.plan ? dataDetail.plan.treatment_plan : "",
        follow_up:
          dataDetail && dataDetail.plan ? dataDetail.plan.follow_up : "",
        patient_education:
          dataDetail && dataDetail.plan
            ? dataDetail.plan.patient_education
            : "",
        reference:
          dataDetail && dataDetail.plan ? dataDetail.plan.reference : "",
      },
      prescription_date: dataDetail
        ? toDateApi(dataDetail.prescription_date)
        : toDate,
      regular: dataPrescription ? dataPrescription.regular : [],
      concoction: dataPrescription ? dataPrescription.concoction : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setOpenSave(true);
    },
  });

  useEffect(() => {
    if (isSave) {
      id
        ? mutateAnalysis({
            id: Number(id),
            status: dataDetail.status ? dataDetail.status : 0,
            ...formik.values,
          })
        : mutateAnalysis(formik.values);
    }
  }, [isSave]);

  useEffect(() => {
    if (
      dataListPatient &&
      dataListDoctor &&
      !isLoadingPatient &&
      !isLoadingDoctor &&
      id !== undefined
    ) {
      if (
        dataListPatient.data.length > 0 &&
        dataListDoctor.doctor.length === 1
      ) {
        setSelectedPatient(dataListPatient.data[0]);
        setSelectedDoctor(dataListDoctor.doctor[0]);
      }
    }
  }, [dataListPatient, dataListDoctor, id]);

  useEffect(() => {
    if (dataDetail && !isFetching) {
      fetchDoctor(dataDetail.doctor_name);
      fetchPatient(dataDetail.patient_name);
    }
  }, [dataDetail, isFetching, id]);

  return (
    <>
      {!id || (id && dataDetail && !isFetching) ? (
        <form onSubmit={formik.handleSubmit}>
          {isFormView ? (
            <>
              {" "}
              <div className="grid grid-cols-3 w-full items-center gap-2 mb-4">
                <div className="w-full flex items-center gap-2">
                  <div
                    className="border-2 rounded-md p-[8px] flex justify-between items-center w-full hover:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      if (!id) {
                        setAnchorElPatient(e.currentTarget);
                        fetchPatient("");
                      }
                    }}
                  >
                    {selectedPatient ? (
                      <p>{selectedPatient.patient_name}</p>
                    ) : (
                      <p>
                        Patient <span className="text-red-500"> *</span>
                      </p>
                    )}
                    <FaChevronDown />
                  </div>
                  {id ? null : (
                    <div className="rounded-md text-white bg-blue-500 hover:bg-gray-300 cursor-pointer w-[40px] h-[40px] p-1">
                      <IoMdAddCircleOutline
                        className="w-full h-full"
                        onClick={() => {
                          handleClosePatient();
                          setOpenAddPatient(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full flex items-center gap-2">
                  <div
                    className="border-2 rounded-md p-[8px] flex justify-between items-center w-full hover:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      if (!id) {
                        setAnchorElDoctor(e.currentTarget);
                        fetchDoctor("");
                      }
                    }}
                  >
                    {selectedDoctor ? (
                      <p>{selectedDoctor.name}</p>
                    ) : (
                      <p>
                        Doctor <span className="text-red-500"> *</span>
                      </p>
                    )}
                    <FaChevronDown />
                  </div>
                  {id ? null : (
                    <div className="rounded-md text-white bg-blue-500 hover:bg-gray-300 cursor-pointer w-[40px] h-[40px] p-1">
                      <IoMdAddCircleOutline
                        className="w-full h-full"
                        onClick={() => setOpenAddDoctor(true)}
                      />
                    </div>
                  )}
                </div>
                <DesktopDatePicker
                  disabled={id !== undefined && id !== null}
                  label="Check Date"
                  sx={{ width: "100%" }}
                  value={formik.values.check_date}
                  onChange={(newValue) =>
                    formik.setFieldValue("check_date", toDateApi(newValue))
                  }
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                <div className="w-full col-span-2">
                  <SubjectiveObjectiveAnalysis
                    formik={formik}
                    isDetail={isDetail}
                    selectedDoctor={selectedDoctor}
                    selectedPatient={selectedPatient}
                    status={dataDetail ? dataDetail.status : 0}
                  />
                  <AssessPlanAnalysis
                    formik={formik}
                    isDetail={isDetail}
                    setIsFormView={setIsFormView}
                    selectedDoctor={selectedDoctor}
                    selectedPatient={selectedPatient}
                  />
                  {isDetail ? null : (
                    <div className="w-full flex justify-end gap-2">
                      <button
                        type="button"
                        className="w-fit px-12 border-2 rounded-md py-2 hover:bg-gray-100"
                        onClick={() =>
                          navigate(`/patient-care/doctor-analysis/`)
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="w-fit px-12 rounded-md py-2 bg-blue-500 disabled:bg-gray-300 text-white hover:bg-blue-700"
                        // disabled={!formik.isValid || isLoading}
                        disabled={
                          formik.values.assessment.diagnosis === "" ||
                          formik.values.assessment.diff_diagnosis === "" ||
                          formik.values.plan.patient_education === "" ||
                          formik.values.plan.follow_up === "" ||
                          formik.values.plan.patient_education === "" ||
                          formik.values.plan.reference === "" ||
                          isLoading
                        }
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full col-span-2 lg:col-span-1">
                  <PatientAnalysisHistory
                    selectedPatient={selectedPatient}
                    isDoctor={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <DoctorConcoction
                formik={formik}
                setIsFormView={setIsFormView}
                isFormView={isFormView}
                selectedDoctor={selectedDoctor}
                selectedPatient={selectedPatient}
              />
            </>
          )}
        </form>
      ) : id && isFetching ? (
        <div className="flex justify-center my-4 w-full">
          <CircularProgress size={20} />
        </div>
      ) : (
        <div className="flex justify-center my-4 w-full">
          Tidak ada data doctor analysis
        </div>
      )}

      <Popover
        open={Boolean(anchorElPatient)}
        anchorEl={anchorElPatient}
        onClose={handleClosePatient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
          <div className="sticky top-0 bg-white">
            <div className="px-8 py-3">
              <div className="grid grid-cols-3 items-center mb-2">
                <FaChevronLeft
                  className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                  onClick={handleClosePatient}
                />
                <p className="text-center font-bold text-lg">List Patient</p>
              </div>
              <TextField
                fullWidth
                sx={{ marginBottom: 1 }}
                placeholder="Find Patient"
                value={searchPatient}
                onChange={(e) => setSearchPatient(e.target.value)}
                inputProps={{ style: { padding: 8 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <hr />
          </div>
          {dataListPatient &&
          !isLoadingPatient &&
          dataListPatient.data &&
          dataListPatient.count > 0 ? (
            dataListPatient.data.map((item) => (
              <div
                className={`px-8 py-2 border-b-2 last:border:none flex gap-4 cursor-pointer hover:bg-gray-100 ${
                  formik.values.patient_id === item.id ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  formik.setFieldValue("patient_id", item.id);
                  setSelectedPatient(item);
                  handleClosePatient();
                }}
              >
                <div className="w-full">
                  <div className="mb-3">
                    <p className="font-bold mb-1">{item.patient_name}</p>
                    {item.member ? (
                      <p className="w-fit border-[#FF8D40] border-2 rounded-md text-[#FF8D40] text-center text-sm px-4 py-1">
                        Member
                      </p>
                    ) : null}
                  </div>{" "}
                  <div className="flex gap-2">
                    <p className="w-1/3">No Rekam Medis</p>
                    <p>:</p>
                    <p>{item.medical_number}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Tanggal Lahir</p>
                    <p>:</p>
                    <p>{item.date_of_birth}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Gender</p>
                    <p>:</p>
                    <p>{item.gender === 1 ? "Laki-laki" : "Perempuan"}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No Telepon</p>
                    <p>:</p>
                    <p>{item.phone_number === "" ? "-" : item.phone_number}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Email</p>
                    <p>:</p>
                    <p>{item.email === "" ? "-" : item.email}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">NIK</p>
                    <p>:</p>
                    <p>{item.nik}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Alamat</p>
                    <p>:</p>
                    <p>{item.address}</p>
                  </div>
                </div>
              </div>
            ))
          ) : isLoadingPatient ? (
            <div className="flex justify-center py-2">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="flex justify-center py-2 text-gray-300">
              <p>No Patient Found</p>
            </div>
          )}
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElDoctor)}
        anchorEl={anchorElDoctor}
        onClose={handleCloseDoctor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "100%", minWidth: "50vw" }}
      >
        <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
          <div className="sticky top-0 bg-white">
            <div className="px-8 py-3">
              <div className="grid grid-cols-3 items-center mb-2">
                <FaChevronLeft
                  className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                  onClick={handleCloseDoctor}
                />
                <p className="text-center font-bold text-lg">List Dokter</p>
              </div>
              <TextField
                fullWidth
                sx={{ marginBottom: 1 }}
                placeholder="Find Doctor"
                value={searchDoctor}
                onChange={(e) => setSearchDoctor(e.target.value)}
                inputProps={{ style: { padding: 8 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <hr />
          </div>
          {dataListDoctor &&
          !isLoadingDoctor &&
          dataListDoctor.doctor &&
          dataListDoctor.count > 0 ? (
            dataListDoctor.doctor.map((item) => (
              <div
                className={`px-8 py-2 border-b-2 last:border:none flex gap-4 cursor-pointer hover:bg-gray-100 ${
                  formik.values.doctor_id === item.id ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  formik.setFieldValue("doctor_id", item.id);
                  setSelectedDoctor(item);
                  handleCloseDoctor();
                }}
              >
                <div
                  className={`rounded-md w-[48px] h-[48px] bg-gray-200 ${
                    item.image === "" ? "p-2" : ""
                  }`}
                >
                  {item.image === "" ? (
                    <FaUser className="w-full h-full text-white" />
                  ) : (
                    <img
                      src={item.image}
                      className="w-full h-full rounded-md object-cover"
                    />
                  )}
                </div>
                <div className="w-full">
                  <p className="font-bold mb-2">{item.name}</p>
                  <div className="flex gap-2">
                    <p className="w-1/3">Email</p>
                    <p>:</p>
                    <p>{item.email === "" ? "-" : item.email}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Phone Number</p>
                    <p>:</p>
                    <p>{item.phone === "" ? "-" : item.phone}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No SIP</p>
                    <p>:</p>
                    <p>{item.sip === "" ? "-" : item.sip}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No KTP</p>
                    <p>:</p>
                    <p>{item.ktp === "" ? "-" : item.ktp}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Specialization</p>
                    <p>:</p>
                    <p>
                      {item.specialization === "" ? "-" : item.specialization}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Address</p>
                    <p>:</p>
                    <p>{item.address === "" ? "-" : item.address}</p>
                  </div>
                </div>
              </div>
            ))
          ) : isLoadingDoctor ? (
            <div className="flex justify-center py-2">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="flex justify-center py-2 text-gray-300">
              <p>No Doctors Found</p>
            </div>
          )}
        </div>
      </Popover>

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />

      <ModalSavePatientCare
        open={openSave}
        setOpen={setOpenSave}
        setConfirm={setIsSave}
        title="Simpan Data Doctor Analysis"
        message="Simpan data doctor analysis yang baru dibuat?"
      />

      <ModalConfirmationAction
        open={openConfirm}
        setOpen={setOpenConfirm}
        returnRoute="/patient-care/doctor-analysis"
        message="Doctor analysis Disimpan"
      />

      <ModalAddPatient
        open={openAddPatient}
        setOpen={setOpenAddPatient}
        refetch={() => fetchPatient("")}
      />

      <ModalAddEditDoctor
        open={openAddDoctor}
        setOpen={setOpenAddDoctor}
        isEdit={true}
        refetch={() => fetchDoctor("")}
      />
    </>
  );
};

export default AddDoctorAnalysis;
