import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getOutletList = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/list/outlet`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getOutletDetail = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/list/outlet`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const switchOutlet = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/switch/outlet`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};
