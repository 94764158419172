import NotFound from "../../assets/images/not_found.png"

const EmptyList = ({message}) => {
  return (
    <>
      <div className="flex flex-col items-center">
        <div><img src={NotFound} className="w-[150px]"/></div>
        <p className="font-bold text-xl align-center my-8">
          {message}
        </p>
      </div>
    </>
  );
};

export default EmptyList;
