import React, { useState, createContext } from "react";

export const ListContext = createContext();

const ProductActiveProvider = ({ children }) => {
  const [listId, setListId] = useState([]);
  const [type, setType] = useState("");

  const [productId, setProductId] = useState([]);

  return (
    <ListContext.Provider
      value={{ listId, setListId, type, setType, productId, setProductId }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ProductActiveProvider;
