import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { AiOutlineDownload } from "react-icons/ai";
import { ImPrinter } from "react-icons/im";

import { getOrderDetail } from "../../../services/OrderApi";
import { statusOrder, statusOrderColor } from "../../constants/constant";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const toDate = (date) => moment(date).format("DD MMMM YYYY, HH:mm");

const OrderDetail = () => {
  const { invoiceNumber } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prescriptionImg, setPrescriptionImg] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = prescriptionImg || [];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { data: orderDetailData, isLoading: isOrderDetailLoading } = useQuery({
    queryKey: ["order-detail"],
    queryFn: () => getOrderDetail(invoiceNumber),
    enabled: invoiceNumber !== "",
  });

  return (
    <div>
      {orderDetailData && orderDetailData !== undefined ? (
        <>
          {isOrderDetailLoading ? (
            <div className="h-screen flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="w-full">
                <div className="flex justify-between mt-3">
                  <p>No. Invoice</p>
                  <p className="text-[#007AF1] hover:cursor-pointer hover:underline">
                    {orderDetailData.invoice_number}
                  </p>
                </div>
                <div className="flex justify-between my-3">
                  <p>Tanggal Pembelian</p>
                  <p>{toDate(orderDetailData.created_at)}</p>
                </div>
                <div className="flex justify-between">
                  <p>Nama Pelanggan</p>
                  <p>{orderDetailData.fullname_censored}</p>
                </div>
                <div className="flex justify-between mt-3">
                  <p>Status Pesanan</p>
                  <p
                    style={{
                      backgroundColor: statusOrderColor[orderDetailData.status],
                      padding: "0.5rem",
                      paddingLeft: "0.75rem",
                      paddingRight: "0.75rem",
                      borderRadius: "9999px",
                      color: "white",
                    }}
                  >
                    {statusOrder[orderDetailData.status]}
                  </p>
                </div>
                <div className="border border-black my-2"></div>
                <>
                  {orderDetailData.data && orderDetailData.data !== undefined
                    ? orderDetailData.data.map((data, index) => (
                        <div
                          className="border border-black rounded-md p-3 my-3 font-bold"
                          key={index}
                        >
                          <div className="flex justify-between w-full">
                            <div className="flex items-center gap-x-3">
                              <img
                                src={data.media}
                                alt=""
                                className="border border-black rounded-md w-[150px] h-auto bg-cover"
                              />
                              <div>
                                <p>{data.product_name}</p>
                                <p>jumlah: {data.quantity}</p>
                                <p>
                                  {data.price && data.price !== undefined
                                    ? data.price.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })
                                    : 0}
                                </p>

                                {data.product_label_id === 3 ||
                                data.product_label_id === 4 ||
                                data.product_label_id === 5 ? (
                                  <button
                                    className="border-2 border-blue-500 font-bold text-blue-500 rounded-md p-2"
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      setPrescriptionImg(
                                        data.prescription_image
                                      );
                                    }}
                                  >
                                    Lihat Resep Dokter
                                  </button>
                                ) : null}
                              </div>
                            </div>
                            <div className="flex justify-center items-center">
                              <div className="h-full border border-black mr-3"></div>
                              <div className="p-4 text-center">
                                <p>Total</p>
                                <p className="font-bold">
                                  {(data.quantity * data.price).toLocaleString(
                                    "id-ID",
                                    {
                                      style: "currency",
                                      currency: "IDR",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </>
                <p className="font-bold">Info Pengiriman</p>
                <div className="flex justify-start w-full">
                  {orderDetailData && orderDetailData !== undefined ? (
                    <div className="flex justify-evenly">
                      <div>
                        <p>Kurir&nbsp;&nbsp;</p>
                        <p>Alamat&nbsp;&nbsp;</p>
                      </div>
                      <div>
                        <p>:</p>
                        <p>:</p>
                      </div>
                      <div>
                        <p>
                          &nbsp;&nbsp;
                          {orderDetailData.shipping_method_name}
                        </p>
                        <p>
                          &nbsp;&nbsp;
                          {orderDetailData.address}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                <p className="font-bold mt-3">Rincian Pembayaran</p>
                <div>
                  <div className="flex justify-between">
                    <p>Subtotal Untuk produk</p>

                    {orderDetailData && orderDetailData !== undefined ? (
                      <p>
                        {orderDetailData?.data
                          ?.reduce((acc, num) => {
                            return acc + num.price * num.quantity;
                          }, 0)
                          .toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex justify-between">
                    <p>Ongkos Kirim</p>
                    <p>Gratis</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Total Pembayaran</p>
                    {orderDetailData && orderDetailData !== undefined ? (
                      <p>
                        {orderDetailData?.data
                          ?.reduce((acc, num) => {
                            return acc + num.price * num.quantity;
                          }, 0)
                          .toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Resep Dokter</DialogTitle>
        <DialogContent sx={{ height: "100%" }}>
          <div className="relative flex w-full items-center justify-center">
            {images.length > 0 ? (
              <>
                <img
                  src={images[currentIndex]}
                  alt={`Prescription ${currentIndex + 1}`}
                  className="mx-2"
                  style={{ maxWidth: "100%", maxHeight: "500px" }}
                />
                {images.length > 1 && (
                  <>
                    <button
                      onClick={handlePrev}
                      className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                    >
                      <FaCaretLeft />
                    </button>
                    <button
                      onClick={handleNext}
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                    >
                      <FaCaretRight />
                    </button>
                  </>
                )}
              </>
            ) : (
              <p>No images available</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-end w-full">
            <div className="flex gap-2">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-center w-12 h-12 rounded-lg cursor-pointer
          ${
            currentIndex === index
              ? "bg-blue-900 text-white"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
                  onClick={() => setCurrentIndex(index)}
                >
                  <p className="font-bold">{index + 1}</p>
                </div>
              ))}
            </div>
            <div>
              <button
                className="text-white p-3 rounded-lg bg-blue-500 hover:bg-blue-600"
                onClick={async () => {
                  const response = await fetch(images[currentIndex], {
                    mode: "cors",
                  });
                  const blob = await response.blob();
                  const link = document.createElement("a");
                  const fileName = `Resep-Digital-${currentIndex + 1}.png`;
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(link.href);
                }}
              >
                <ImPrinter className="text-[18px]" />
              </button>
              <button
                className="text-white p-3 rounded-lg bg-blue-500 hover:bg-blue-600 ml-2"
                onClick={async () => {
                  const response = await fetch(images[currentIndex], {
                    mode: "cors",
                  });
                  const blob = await response.blob();
                  const link = document.createElement("a");
                  const fileName = `Resep-Digital-${currentIndex + 1}.png`;
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(link.href);
                }}
              >
                <AiOutlineDownload className="text-[18px]" />
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderDetail;
