import React from "react";
import { Visibility as VisibilityIcon } from "@mui/icons-material";

const Input = (props) => {
  const {
    onChange,
    value,
    placeholder,
    disabled,
    label,
    name,
    hasPassword,
    passwordVisibility,
    setPasswordVisibility,
  } = props;

  return (
    <>
      <div className="w-full">
        <label htmlFor="email" className="block mb-2 text-sm text-blue-400">
          {label}
        </label>
        <div className="flex justify-between items-center w-full">
          <input
            type={hasPassword && passwordVisibility ? "password" : "text"}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            disabled={disabled}
          />
          {hasPassword ? (
            <button
              onClick={() => {
                setPasswordVisibility(!passwordVisibility);
              }}
              type="button"
            >
              <VisibilityIcon sx={{ marginLeft: 1 }} />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Input;
