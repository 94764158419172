import React, { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { Visibility as VisibilityIcon } from "@mui/icons-material";

import Logo from "../../../assets/icons/olinlogo.png";
import Carousel from "../../common/particles.jsx/Carousel";
import { PasswordContext } from "../../context/ForgotPasswordProvider";
import { makeNewPassword } from "../../../services/authApi";
import { useNavigate } from "react-router-dom";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const { email } = useContext(PasswordContext);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const { mutate } = useMutation({
    mutationFn: makeNewPassword,
    onSuccess: () => {
      navigate("/forgot-password/login");
    },
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  return (
    <div className="bg-white">
      <div className="flex flex-col-reverse lg:flex-row justify-center h-screen">
        <div className="order-first md:order-last bg-cover lg:block lg:w-2/3">
          <div className="flex items-center h-full px-20 bg-[#EAF3FE] bg-opacity-40">
            <div className="w-full my-10">
              <Carousel />
            </div>
          </div>
        </div>

        <div className="flex mt-20 w-full max-w-lg px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src={Logo}
                alt=""
                className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
              />
              <>
                <h2 className="text-4xl font-bold text-center text-black mt-10 lg:mt-28">
                  Masukkan kata sandi baru
                </h2>
              </>
            </div>
            <div className="mt-8">
              <div className="w-full">
                <div className="flex justify-between">
                  <label className="block mb-2 text-sm text-blue-400">
                    Password Baru
                  </label>
                </div>
                <div className="flex justify-between items-center w-full">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    name="new-password"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    onChange={(e) => {
                      setErrorMessage("");
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                  <button
                    onClick={() => {
                      setPasswordVisibility(!passwordVisibility);
                    }}
                    type="button"
                  >
                    <VisibilityIcon sx={{ marginLeft: 1 }} />
                  </button>
                </div>
                <div className="flex justify-between">
                  <label className="block mb-2 text-sm text-blue-400 mt-[20px]">
                    Ketik ulang Password Baru
                  </label>
                </div>
                <div className="flex justify-between items-center w-full">
                  <input
                    type={confirmPasswordVisibility ? "text" : "password"}
                    name="confirm-password"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    onChange={(e) => {
                      setErrorMessage("");
                      setConfirmPassword(e.target.value);
                    }}
                    value={confirmPassword}
                  />
                  <button
                    onClick={() => {
                      setConfirmPasswordVisibility(!confirmPasswordVisibility);
                    }}
                    type="button"
                  >
                    <VisibilityIcon sx={{ marginLeft: 1 }} />
                  </button>
                </div>
              </div>
              <p className="text-red-500 mt-[10px]">{errorMessage}</p>
              <div className="mt-6">
                <button
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors transform bg-blue-500 rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50 disabled:bg-gray-500"
                  type="button"
                  disabled={password === "" || confirmPassword === ""}
                  onClick={() => {
                    if (password !== confirmPassword)
                      return setErrorMessage("Password tidak cocok");
                    else if (password.length < 8 && confirmPassword.length < 8)
                      return setErrorMessage("Password kurang dari 8 karakter");
                    mutate({ email, new_password: confirmPassword });
                  }}
                >
                  Selanjutnya
                </button>
              </div>
              {/* <div className="relative mt-6 h-px bg-gray-300">
                <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                  <span className="bg-white px-4 text-xs text-gray-500 uppercase">Belum Registrasi?</span>
                </div>
              </div>
              <div className="mt-6">
                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-orange-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                  Daftar
                </button>
              </div> */}
              <div className="flex flex-col xl:flex-row items-center justify-center gap-2 my-6">
                <p className="font-medium text-center">Pelajari tentang OLIN</p>
                <p className="border-1 text-center p-2 rounded-xl border-orange-500">
                  Unduh Aplikasi
                </p>
              </div>

              {/* <div className="mt-6 text-sm text-center text-gray-400">
              Don&#x27;t have an account yet? <p className="text-blue-500 underline focus:outline-none hover:underline focus:underline hover:underline">Sign up</p>.
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
