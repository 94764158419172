import { Popover } from "@mui/material";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const YEARS = Array.from(
  { length: new Date().getFullYear() + 1 - 1990 },
  (_, i) => 1990 + 1 + i
);
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PopupRangeCalendar = ({
  anchorElCal,
  setAnchorElCal,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  resetFunc,
}) => {
  return (
    <Popover
      open={Boolean(anchorElCal)}
      anchorEl={anchorElCal}
      onClose={() => setAnchorElCal(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="p-2">
        <DatePicker
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start ? moment(start).format("YYYY-MM-DD") : "");
            setEndDate(end ? moment(end).format("YYYY-MM-DD") : "");
          }}
          startDate={startDate == "" ? null : new Date(startDate)}
          endDate={endDate === "" ? null : new Date(endDate)}
          selectsRange={true}
          inline
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex justify-center gap-2 px-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <FaChevronLeft />
              </button>
              <select
                className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                value={new Date(date).getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {YEARS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                value={MONTHS[new Date(date).getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(MONTHS.indexOf(value))
                }
              >
                {MONTHS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        />
        <button
          className="bg-blue-500 text-white w-full rounded-md p-1 hover:bg-blue-700"
          onClick={() => {
            if (resetFunc) resetFunc();
            else {
              setStartDate("");
              setEndDate("");
            }
          }}
        >
          Reset
        </button>
      </div>
    </Popover>
  );
};

export default PopupRangeCalendar;
