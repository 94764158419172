import { useMutation } from "@tanstack/react-query";
import { getInitialAnalysisList } from "../../../../services/patientCare";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import { MdOutlineHideImage } from "react-icons/md";
import { DecryptImage } from "../../../../utils/DecryptImage";
import cornerstone, { loadImage } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import dicomParser from "dicom-parser";
// import "cornerstone-tools/dist/cornerstoneTools.css";

// Set up the CornerstoneWADOImageLoader (using WADO-URI)
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

// Initialize CornerstoneWADOImageLoader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const PatientAnalysisHistory = ({ selectedPatient, isDoctor }) => {
  const cornerstoneRefs = useRef([]);
  const cornerstoneRefsExam = useRef([]);

  const [offsetHistory, setOffsetHistory] = useState(1);
  const [arrDicom, setArrDicom] = useState([]);
  const [arrDicomExam, setArrDicomExam] = useState([]);

  const decryptText = async (encryptText) => {
    try {
      const decrypted = await DecryptImage(
        encryptText,
        process.env.REACT_APP_SECRET_KEY_IMG
      );
      return decrypted;
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  const HistoryTextfield = ({ title, content, suffix }) => {
    return (
      <div className="mb-1">
        <p className="text-xs mb-2 text-gray-500">{title}</p>
        <p className={`border-b-2 inline-block ${suffix ? "w-3/4" : "w-full"}`}>
          {content}
        </p>
        {suffix ? <p className="inline-block ml-4">{suffix}</p> : null}
      </div>
    );
  };

  const {
    mutate: mutateListAnalysis,
    data: dataList,
    isLoading: isLoadingList,
  } = useMutation({
    mutationFn: getInitialAnalysisList,
  });

  useEffect(() => {
    mutateListAnalysis({
      search: "",
      offset: offsetHistory,
      limit: 1,
      start_date: "",
      end_date: "",
      status: 0,
      patient_id: selectedPatient ? selectedPatient.id : 0,
      doctor_id: 0,
      doctor_analysis: isDoctor,
    });
  }, [selectedPatient, offsetHistory]);

  useEffect(() => {
    // Decrypting images
    const loadImages = async (imgArr, setDicom, refElem) => {
      imgArr.map(async (encryptedUrl, index) => {
        const decryptedUrl = await decryptText(encryptedUrl);

        if (decryptedUrl) {
          const element = refElem.current[index];
          cornerstone.enable(element);
          const imageId = `wadouri:${decryptedUrl}`;

          cornerstone
            .loadImage(imageId)
            .then((image) => {
              setDicom((prevArrDicom) => [...prevArrDicom, image]);
              cornerstone.displayImage(element, image);
            })
            .catch((err) => {
              console.error("Error loading DICOM image:", err);
              setDicom((prevArrDicom) => [...prevArrDicom, null]);
            });
        }
      });
    };
    if (dataList && dataList.data[0]) {
      loadImages(dataList.data[0].img, setArrDicom, cornerstoneRefs);
      loadImages(
        dataList.data[0].exam_img,
        setArrDicomExam,
        cornerstoneRefsExam
      );
    }
  }, [dataList, cornerstoneRefs, cornerstoneRefsExam]);

  useEffect(() => {
    setOffsetHistory(1);
  }, [selectedPatient]);

  return (
    <>
      <div className=" border-2 border-black rounded-md p-4 ">
        <p className="font-bold mb-4">Initial Analysis History</p>
        {selectedPatient && dataList && !isLoadingList && dataList.data[0] ? (
          <div className="flex justify-end w-full mb-2">
            <div className="flex gap-4 justify-between items-center w-3/4">
              <button
                type="button"
                className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px]"
                disabled={offsetHistory === 1}
                onClick={() => setOffsetHistory(offsetHistory - 1)}
              >
                <FaChevronLeft />
              </button>
              <p>{moment(dataList.data[0].check_date).format("DD MMM YYYY")}</p>
              <button
                type="button"
                className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px]"
                disabled={offsetHistory === dataList.count}
                onClick={() => setOffsetHistory(offsetHistory + 1)}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        ) : null}
        <hr className="my-2" />
        {selectedPatient && dataList && !isLoadingList && dataList.data[0] ? (
          <>
            <div className="mb-4">
              <p className="font-bold mb-2">Patient Information</p>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">No Rekam Medis</p>
                <p>:</p>
                <p>{selectedPatient.medical_number}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Tanggal Lahir</p>
                <p>:</p>
                <p>{selectedPatient.date_of_birth}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Gender</p>
                <p>:</p>
                <p>
                  {selectedPatient.gender === 1 ? "Laki-laki" : "Perempuan"}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">No Telepon</p>
                <p>:</p>
                <p>
                  {selectedPatient.phone_number === ""
                    ? "-"
                    : selectedPatient.phone_number}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Email</p>
                <p>:</p>
                <p>
                  {selectedPatient.email === "" ? "-" : selectedPatient.email}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">NIK</p>
                <p>:</p>
                <p>{selectedPatient.nik}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Alamat</p>
                <p>:</p>
                <p>{selectedPatient.address}</p>
              </div>
            </div>
            <div className="mb-8">
              <p className="font-bold mb-2">Doctor Information</p>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Nama Dokter</p>
                <p>:</p>
                <p>{dataList.data[0].doctor_name}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">No. SIP</p>
                <p>:</p>
                <p>{dataList.data[0].sip}</p>
              </div>
              <div className="flex gap-2 items-center">
                <p className="w-1/3">Specialization</p>
                <p>:</p>
                <p>{dataList.data[0].specialization}</p>
              </div>
            </div>
            <div className="mb-4">
              <p className="font-bold mb-2">Subjective (S)</p>
              <HistoryTextfield
                title="Keluhan Utama"
                content={dataList.data[0].subjective.main_complaint}
              />
              <HistoryTextfield
                title="Riwayat penyakit sebelumnya"
                content={dataList.data[0].subjective.existing_illness}
              />
              <HistoryTextfield
                title="Riwayat sosial"
                content={dataList.data[0].subjective.social_history}
              />
              <HistoryTextfield
                title="Riwayat tumbuh kembang"
                content={dataList.data[0].subjective.development_history}
              />
              <HistoryTextfield
                title="Riwayat keluarga"
                content={dataList.data[0].subjective.family_history}
              />
              <HistoryTextfield
                title="Riwayat kelahiran"
                content={dataList.data[0].subjective.birth_history}
              />
              <HistoryTextfield
                title="Konsumsi obat saat ini"
                content={dataList.data[0].subjective.current_medication}
              />
            </div>
            <div className="mb-4">
              <p className="font-bold mb-2">Objective (O)</p>
              <div className="mb-4">
                <p className="font-bold">Tanda-tanda Vital</p>
                <HistoryTextfield
                  title="Tekanan darah (diastolik)"
                  content={dataList.data[0].objective.diastole}
                  suffix="mmHg"
                />
                <HistoryTextfield
                  title="Suhu Tubuh"
                  content={dataList.data[0].objective.body_temp}
                  suffix="&#176;C"
                />
                <HistoryTextfield
                  title="Denyut Nadi"
                  content={dataList.data[0].objective.pulse}
                  suffix="x/menit"
                />
                <HistoryTextfield
                  title="Tekanan darah (sistolik)"
                  content={dataList.data[0].objective.systole}
                  suffix="mmHg"
                />
                <HistoryTextfield
                  title="Nafas"
                  content={dataList.data[0].objective.respiratory_rate}
                  suffix="x/menit"
                />
                <HistoryTextfield
                  title="Kadar Oksigen"
                  content={dataList.data[0].objective.oxygen}
                  suffix="Sp02 (%)"
                />
              </div>
              <div className="mb-4">
                <p className="font-bold">Nyeri</p>
                <HistoryTextfield
                  title="Tingkat Nyeri"
                  content={dataList.data[0].objective.pain_level}
                  suffix="1 s/d 10"
                />
                <HistoryTextfield
                  title="Lokasi Nyeri"
                  content={dataList.data[0].objective.pain_location}
                />
              </div>
              <div className="mb-4">
                <p className="font-bold">Lainnya</p>
                <HistoryTextfield
                  title="Lingkar Kepala"
                  content={dataList.data[0].objective.head_circumference}
                  suffix="cm"
                />
                <HistoryTextfield
                  title="Berat Badan"
                  content={dataList.data[0].objective.weight}
                  suffix="kg"
                />
                <HistoryTextfield
                  title="Tinggi Badan"
                  content={dataList.data[0].objective.height}
                  suffix="cm"
                />
                <HistoryTextfield
                  title="Pemeriksaan Fisik"
                  content={dataList.data[0].objective.physical_exam}
                  suffix="cm"
                />
                <HistoryTextfield
                  title="Pemeriksaan Penunjang"
                  content={dataList.data[0].objective.support_exam}
                  suffix="cm"
                />
              </div>
            </div>
            <div className="mb-4">
              <p className="font-bold mb-2">Hasil Laboratorium</p>
              <hr className="mb-2" />
              {dataList.data[0] && dataList.data[0].img.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2  gap-2 w-full">
                  {dataList.data[0].img.map((item, i) => (
                    <div className="w-full h-[200px]">
                      <div
                        key={i}
                        ref={(element) =>
                          (cornerstoneRefs.current[i] = element)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "black",
                          objectFit: "contain",
                        }}
                      >
                        {arrDicom[i] ? null : (
                          <div className="flex justify-center items-center w-full h-[200px] p-3">
                            <MdOutlineHideImage className="w-[48px] h-[48px]" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-300">Tidak ada hasil laboratorium</p>
              )}
            </div>
            <div className="mb-4">
              <p className="font-bold mb-2">Hasil Pemeriksaan</p>
              <hr className="mb-2" />
              {dataList.data[0] && dataList.data[0].exam_img.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
                  {dataList.data[0].exam_img.map((item, i) => (
                    <div className="w-full h-[200px]">
                      <div
                        key={i}
                        ref={(element) =>
                          (cornerstoneRefsExam.current[i] = element)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "black",
                          objectFit: "contain",
                        }}
                      >
                        {arrDicomExam[i] ? null : (
                          <div className="flex justify-center items-center w-full h-[200px] p-3">
                            <MdOutlineHideImage className="w-[48px] h-[48px]" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-300">Tidak ada hasil pemeriksaan</p>
              )}
            </div>
          </>
        ) : selectedPatient && isLoadingList ? (
          <CircularProgress size={20} />
        ) : (
          <p className="text-gray-300">Tidak ada history analysis</p>
        )}
      </div>
    </>
  );
};

export default PatientAnalysisHistory;
