import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";
import { FormControlLabel, Checkbox } from "@mui/material";

import {
  getDetailInformation,
  getDetailInformationOwner,
} from "../../../services/authApi";
import { getRole } from "../../../utils/FetchData";

const AccountProfileDetail = () => {
  const title = parseInt(localStorage.getItem("title"), 10);
  const navigate = useNavigate();
  const [ownerDocsIndex, setOwnerDocsIndex] = useState(null);
  const [isOpenOwnerDocs, setIsOpenOwnerDocs] = useState(false);
  const [staffDocsIndex, setStaffDocsIndex] = useState(null);
  const [isOpenStaffDocs, setIsOpenStaffDocs] = useState(false);

  const { data: detailProfileOwner } = useQuery({
    queryKey: ["detail-profile"],
    queryFn: () => getDetailInformation(),
  });

  const { data: detailProfileStaff } = useQuery({
    queryKey: ["detail-profile-owner"],
    queryFn: () => getDetailInformationOwner(),
  });

  const { data: roleData } = useQuery({
    queryKey: ["role"],
    queryFn: () => getRole(),
  });

  const role = roleData && roleData !== undefined ? roleData : [];

  const isPreviewDocumentOwner =
    detailProfileOwner && detailProfileOwner !== undefined
      ? [
          {
            id: 1,
            file: detailProfileOwner.file_company?.nib,
            type: detailProfileOwner.file_company?.type_nib,
          },
          {
            id: 2,
            file: detailProfileOwner.file_company?.npwp,
            type: detailProfileOwner.file_company?.type_npwp,
          },
          {
            id: 3,
            file: detailProfileOwner.file_company?.izin_sarana,
            type: detailProfileOwner.file_company?.type_izin_sarana,
          },
          {
            id: 4,
            file: detailProfileOwner.file_company?.stempel_sarana,
            type: detailProfileOwner.file_company?.type_stempel_sarana,
          },
          {
            id: 5,
            file: detailProfileOwner.file_owner?.ktp,
            type: detailProfileOwner.file_owner?.type_ktp,
          },
          {
            id: 6,
            file: detailProfileOwner.file_owner?.npwp,
            type: detailProfileOwner.file_owner?.type_npwp,
          },
        ]
      : [];

  const isPreviewDocumentStaff =
    detailProfileOwner && detailProfileOwner !== undefined
      ? [
          {
            id: 1,
            file: detailProfileStaff.document_sipa?.spesimen_signature,
            type: detailProfileStaff.document_sipa?.type_spesimen_signature,
          },
          {
            id: 2,
            file: detailProfileStaff.document_sipa?.ktp,
            type: detailProfileStaff.document_sipa?.type_ktp,
          },
          {
            id: 3,
            file: detailProfileStaff.document_sipa?.nib,
            type: detailProfileStaff.document_sipa?.type_nib,
          },
        ]
      : [];

  return (
    <div>
      {title !== 1 ? (
        <div className="w-full">
          {detailProfileStaff && detailProfileStaff !== undefined ? (
            <>
              <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
                <div className="flex flex-col">
                  <p className="font-semibold">Nama Apoteker</p>
                  <p>{detailProfileStaff.name}</p>
                </div>
              </div>
              {detailProfileStaff.no_sipa !== null &&
              detailProfileStaff.masa_berlaku_sipa !== null ? (
                <>
                  <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
                    <div className="flex flex-col">
                      <p className="font-semibold">Nomor Sertifikat SIPA</p>
                      <p>{detailProfileStaff.no_sipa}</p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
                    <div className="flex flex-col">
                      <p className="font-semibold">Masa Berlaku SIPA</p>
                      <p>{detailProfileStaff.masa_berlaku_sipa}</p>
                    </div>
                  </div>

                  <div className="flex flex-col rounded-lg shadow-md p-3 w-full justify-start">
                    <p className="font-semibold">Dokumen</p>
                    <div className="border border-black"></div>
                    <div className="flex justify-between mt-3 font-bold">
                      <p>SIPA</p>
                      <button className="border-2 border-orange-500 bg-white rounded rounded-md p-1 text-orange-500 text-[10px]">
                        Lihat Dokumen
                      </button>
                    </div>
                    <div className="flex justify-between my-3 font-bold">
                      <p>Spesimen Signature</p>
                      <button className="border-2 border-orange-500 bg-white rounded rounded-md p-1 text-orange-500 text-[10px]">
                        Lihat Dokumen
                      </button>
                    </div>
                    <div className="flex justify-between font-bold">
                      <p>KTP</p>
                      <button className="border-2 border-orange-500 bg-white rounded rounded-md p-1 text-orange-500 text-[10px]">
                        Lihat Dokumen
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="flex flex-col rounded-lg shadow-md p-3 w-full justify-start">
                <p className="font-semibold">Izin Pengguna</p>
                <div className="border border-black"></div>
                <div className="flex flex-wrap justify-between mt-2">
                  {role && role !== undefined
                    ? role.map((role, index) => (
                        <FormControlLabel
                          key={index}
                          control={<Checkbox value={role.id} />}
                          disabled
                          label={role.name}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
                          checked={
                            detailProfileStaff &&
                            detailProfileStaff !== undefined
                              ? detailProfileStaff.roles.includes(role.id)
                              : false
                          }
                        />
                      ))
                    : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <>
          <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg justify-center">
            Informasi Akun
          </div>
          {detailProfileOwner && detailProfileOwner !== undefined ? (
            <>
              <div className="flex items-center justify-center h-full border border-2 rounded rounded-b-lg">
                {detailProfileOwner.information_account &&
                detailProfileOwner.information_account !== undefined ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center w-full font-semibold p-3">
                      <p>Cus. No. Faktur</p>
                      <p className="my-3">Nama Usaha</p>
                      <p>Alamat Usaha</p>
                    </div>
                    <div className="flex flex-col justify-center items-center w-full font-semibold">
                      <p>:</p>
                      <p className="my-3">:</p>
                      <p>:</p>
                    </div>
                    <div className="flex flex-col justify-center items-center w-full font-semibold">
                      <p>
                        {detailProfileOwner.information_account.no_faktur !==
                        "" ? (
                          detailProfileOwner.information_account.no_faktur
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p className="my-3">
                        {detailProfileOwner.information_account.company_name !==
                        "" ? (
                          detailProfileOwner.information_account.company_name
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_account
                          .business_address !== "" ? (
                          detailProfileOwner.information_account
                            .business_address
                        ) : (
                          <br></br>
                        )}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg justify-center">
                Informasi Perusahaan
              </div>
              <div className="flex items-center justify-center h-full border border-2 rounded rounded-b-lg">
                {detailProfileOwner.information_company &&
                detailProfileOwner.information_company !== undefined ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center w-full font-semibold p-3 space-y-3">
                      <p>Bidang Usaha</p>
                      <p>NPWP Usaha</p>
                      <p>NIB</p>
                      <p>Surat Izin Usaha</p>
                      <p>Valid Surat Izin</p>
                      <p>No. HP Usaha</p>
                      <p>Owner</p>
                      <p>ID Card No.</p>
                      <p>No NPWP</p>
                      <p>Nama Pemilik Bank</p>
                      <p>No Rekening Bank</p>
                      <p>Nama Bank</p>
                    </div>
                    <div className="flex flex-col justify-center items-center w-full font-semibold space-y-3">
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                    </div>
                    <div className="flex flex-col justify-center items-center w-full font-semibold space-y-3">
                      <p>
                        {detailProfileOwner.information_company
                          .business_field !== "" ? (
                          detailProfileOwner.information_company.business_field
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.npwp !== "" ? (
                          detailProfileOwner.information_company.npwp
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.nib !== "" ? (
                          detailProfileOwner.information_company.nib
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.surat_izin !==
                        "" ? (
                          detailProfileOwner.information_company.surat_izin
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company
                          .masa_berlaku_surat_izin !== "" ? (
                          detailProfileOwner.information_company
                            .masa_berlaku_surat_izin
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.phone !== "" ? (
                          detailProfileOwner.information_company.phone
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.owner_name !==
                        "" ? (
                          detailProfileOwner.information_company.owner_name
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.owner_id !==
                        "" ? (
                          detailProfileOwner.information_company.owner_id
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.owner_npwp !==
                        "" ? (
                          detailProfileOwner.information_company.owner_npwp
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company
                          .bank_account_name !== "" ? (
                          detailProfileOwner.information_company
                            .bank_account_name
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company
                          .bank_account_number !== "" ? (
                          detailProfileOwner.information_company
                            .bank_account_number
                        ) : (
                          <br></br>
                        )}
                      </p>
                      <p>
                        {detailProfileOwner.information_company.bank !== "" ? (
                          detailProfileOwner.information_company.bank
                        ) : (
                          <br></br>
                        )}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg justify-center">
                Kelengkapan Dokumen
              </div>
              {detailProfileOwner.file_owner &&
              detailProfileOwner.file_owner !== undefined ? (
                <div className="flex items-center justify-between h-full border border-2 rounded rounded-b-lg">
                  <div className="flex flex-col justify-center items-center w-full font-semibold p-3 space-y-3">
                    <p>Nomor Induk Usaha</p>
                    <p>NPWP Usaha</p>
                    <p>Surat Izin Usaha</p>
                    <p>Stampel Usaha</p>
                    <p>KTP Owner</p>
                    <p>NPWP Pemilik Usaha</p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full font-semibold p-3 text-[10px] text-orange-500 space-y-3">
                    {isPreviewDocumentOwner.map((_, index) => (
                      <>
                        <button
                          key={index}
                          className="border-2 border-orange-500 bg-white rounded rounded-md p-1"
                          onClick={() => {
                            setIsOpenOwnerDocs(!isOpenOwnerDocs);
                            setOwnerDocsIndex(index);
                          }}
                        >
                          Lihat Dokumen
                        </button>
                      </>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
      <Dialog open={isOpenOwnerDocs} onClose={() => setIsOpenOwnerDocs(false)}>
        <DialogContent>
          <img src={isPreviewDocumentOwner[ownerDocsIndex]?.file} />
        </DialogContent>
      </Dialog>
      <Dialog open={isOpenStaffDocs} onClose={() => setIsOpenStaffDocs(false)}>
        <DialogContent>
          <img src={isPreviewDocumentStaff[staffDocsIndex]?.file} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AccountProfileDetail;
