import { CircularProgress, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";

import { RxCross1 } from "react-icons/rx";
import { setPatientCareSpecialization } from "../../../../services/patientCare";

const ModalAddEditSpecialization = ({
  setOpen,
  view,
  setView,
  isEdit,
  editSpec,
  setEditSpec,
}) => {
  const [newSpec, setNewSpec] = useState({
    name: "",
  });

  const { mutate: mutateSpec, isLoading } = useMutation({
    mutationFn: setPatientCareSpecialization,
    onSuccess: () => {
      setView("doctor");
      setEditSpec(null);
    },
  });

  useEffect(() => {
    if (editSpec && view === "specialization") setNewSpec(editSpec);
    if (view !== "specialization") setNewSpec({ name: "" });
  }, [editSpec, view]);

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between items-center pb-8">
          <div className="flex items-center gap-4">
            <FaChevronLeft
              className="hover:text-gray-300 cursor-pointer"
              onClick={() => {
                setView("doctor");
                setEditSpec(null);
              }}
            />
            <p className="font-bold text-lg">
              {isEdit ? "Edit" : "Add"} Specialization
            </p>
          </div>
          <RxCross1
            className="hover:text-red-500 cursor-pointer w-[14px] h-full"
            onClick={() => {
              setOpen(false);
              setEditSpec(null);
            }}
          />
        </div>

        <p className="text-xs text-gray-500">
          Specialization Name <span className="text-red-500"> *</span>
        </p>
        <TextField
          fullWidth
          variant="standard"
          value={newSpec.name}
          onChange={(e) => setNewSpec({ ...newSpec, name: e.target.value })}
        />
      </div>
      <div className="px-8 pb-4 w-full absolute bottom-0">
        <button
          disabled={newSpec.name === ""}
          onClick={() => mutateSpec(newSpec)}
          className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
        >
          {isLoading ? <CircularProgress size={20} /> : <p>Save</p>}
        </button>
      </div>
    </>
  );
};

export default ModalAddEditSpecialization;
