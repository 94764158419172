import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import * as yup from "yup";

import { updateData } from "../../../services/userManagementApi";

const validationSchema = yup.object({
  oldValue: yup.string().when("$enableEmailValidation", {
    is: true,
    then: yup
      .string()
      .email("format email salah")
      .required("email lama wajib diisi"),
    otherwise: yup.string().required("No Telp lama wajib diisi"),
  }),
  newValue: yup.string().when("$enableEmailValidation", {
    is: true,
    then: yup
      .string()
      .email("format email salah")
      .required("email baru wajib diisi"),
    otherwise: yup.string().required("No Telp baru wajib diisi"),
  }),
  confirmedValue: yup.string().when("$enableEmailValidation", {
    is: true,
    then: yup
      .string()
      .email("format email salah")
      .required("email lama wajib diisi")
      .oneOf([yup.ref("newValue"), null], "Email harus sesuai"),
    otherwise: yup
      .string()
      .required("No Telp wajib diisi")
      .oneOf([yup.ref("newValue"), null], "No Telp harus sesuai"),
  }),
});

const UpdateDataModal = (props) => {
  const { id, modalComponent, setIsModalOpen, userEmail, userPhone } = props;
  const [oldValue, setOldValue] = useState("");
  const { mutate: mutateUpdateData } = useMutation({
    mutationFn: updateData,
    onSuccess: () => setIsModalOpen(false),
  });

  const formik = useFormik({
    initialValues: {
      oldValue: oldValue,
      newValue: "",
      confirmedValue: "",
    },
    validationSchema,
    onSubmit: (value) => {
      if (modalComponent.enableEmailValidation)
        mutateUpdateData({
          type: "email",
          staff_id: parseInt(id, 10),
          email: value.confirmedValue,
        });
      else
        mutateUpdateData({
          type: "phone",
          staff_id: parseInt(id, 10),
          phone: value.confirmedValue,
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (modalComponent.enableEmailValidation)
      setOldValue(userEmail && userEmail ? userEmail : "");
    else setOldValue(userPhone && userPhone !== undefined ? userPhone : "");
  }, [oldValue, userPhone, userEmail]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <p className="text-[18px] font-semibold">
          Ubah {modalComponent.header}
        </p>
        <p>
          Perbarui {modalComponent.header} Anda dengan mengisi data dibawah ini
        </p>
      </div>
      <div className="flex flex-col">
        <TextField
          label={`${modalComponent.header} sebelumnya`}
          value={formik.values.oldValue}
          onChange={(e) => formik.setFieldValue("oldValue", e.target.value)}
          error={formik.touched.oldValue && Boolean(formik.errors.oldValue)}
          helperText={formik.touched.oldValue && formik.errors.oldValue}
        />
        <TextField
          label={`${modalComponent.header} baru`}
          sx={{ marginY: 3 }}
          value={formik.values.newValue}
          onChange={(e) => formik.setFieldValue("newValue", e.target.value)}
          error={formik.touched.newValue && Boolean(formik.errors.newValue)}
          helperText={formik.touched.newValue && formik.errors.newValue}
        />
        <TextField
          label={`Konfirmasi ${modalComponent.header} baru`}
          value={formik.values.confirmedValue}
          onChange={(e) =>
            formik.setFieldValue("confirmedValue", e.target.value)
          }
          error={
            formik.touched.confirmedValue &&
            Boolean(formik.errors.confirmedValue)
          }
          helperText={
            formik.touched.confirmedValue && formik.errors.confirmedValue
          }
        />
      </div>
      <div className="flex font-semibold w-full mt-3 justify-center ">
        <button
          className="text-[#344054] border border-black p-2 rounded-md"
          onClick={() => setIsModalOpen(false)}
        >
          Batalkan
        </button>
        <button
          className="text-white bg-[#007AF1] p-2 rounded-md ml-2"
          type="submit"
        >
          Ubah
        </button>
      </div>
    </form>
  );
};

export default UpdateDataModal;
