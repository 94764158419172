import { useQuery } from "@tanstack/react-query";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiMoney } from "react-icons/bi";
import moment from "moment";

import { getVoucherDetail } from "../../../services/paymentApi";

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const VoucherDetail = (props) => {
  const { isDetailVoucherOpen } = props;
  const { data: detailVoucher } = useQuery({
    queryKey: ["detail_voucher"],
    queryFn: () => getVoucherDetail(isDetailVoucherOpen.voucherId),
    disabled: !!isDetailVoucherOpen.voucherId,
  });

  return (
    <>
      {detailVoucher && detailVoucher !== undefined ? (
        <div className="flex flex-col justify-center">
          <div className="flex justify-center">
            <img src={detailVoucher.media} className="w-full" />
          </div>
          <p className="text-[32px]">{detailVoucher.name_voucher}</p>
          <div className="flex my-2">
            <div className="flex items-center">
              <AiOutlineClockCircle />
              <p>Berlaku hingga</p>
            </div>
            <p className="ml-10">{toDate(detailVoucher.berlaku_hingga)}</p>
          </div>
          <div className="flex">
            <div className="flex items-center">
              <BiMoney />
              <p>Maksimal Diskon</p>
            </div>
            <p className="ml-10">
              
              {detailVoucher.max_total_discount.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
          <p className="text-[32px] mt-10">Syarat dan ketentuan</p>
          <p>{detailVoucher.voucher_information}</p>
        </div>
      ) : null}
    </>
  );
};

export default VoucherDetail;
