import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Pagination,
  InputAdornment,
  CircularProgress,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import { AiOutlineClockCircle } from "react-icons/ai";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  fetchTransactionList,
  postReorderTransaction,
  postTransactionDone,
} from "../../../../../services/purchaseApi";
import useDebounce from "../../../../hooks/useDebounce";
import StatusTransaction from "./StatusTransaction";
import HelpInformationModal from "../../../../common/particles.jsx/HelpInformationModal";
import FinishOrderPurchaseModal from "../../../../common/particles.jsx/FinishOrderPurchaseModal";
import ReorderPurchaseModal from "../../../../common/particles.jsx/ReorderPurchaseModal";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const ListTransactionPurchase = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(1);
  const [offset, setOffset] = useState(1);
  const [period, setPeriod] = useState(1);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isHelpInformationModalOpen, setIsHelpInformationModalOpen] =
    useState(false);
  const [finishedOrder, setFinishedOrder] = useState({
    open: false,
    invoice: "",
  });
  const [reorderTransaction, setReorderTransaction] = useState({
    open: false,
    invoice: "",
  });
  const debounce = useDebounce(search, 500);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [refreshMutation, setRefreshMutation] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    content: "",
    status: false,
  });

  const [reorderValue, setReorderValue] = useState({
    statusName: "",
    invoice: "",
  });

  const {
    mutate: mutateTransactionList,
    data: transactionListData,
    isLoading: isTransactionListIsLoading,
  } = useMutation({
    mutationFn: fetchTransactionList,
  });

  const {
    mutate: mutateTransactionDone,
    isLoading: isMutateTransactionDoneLoading,
  } = useMutation({
    mutationFn: postTransactionDone,
    onSuccess: () => {
      setFinishedOrder({ ...finishedOrder, open: false, invoice: "" });
      setRefreshMutation((prevBool) => !prevBool);
    },
    onError: (err) => {
      setErrorMessage({
        title: "Tidak dapat menyelesaikan pesanan",
        content: err.message.id,
        status: true,
      });
    },
  });

  const {
    mutate: mutateReorderTransaction,
    isLoading: isMutateReorderTransactionLoading,
  } = useMutation({
    mutationFn: postReorderTransaction,
    onSuccess: () => {
      setReorderTransaction({
        ...reorderTransaction,
        open: false,
        invoice: "",
      });
      navigate("/purchase/cart-detail");
    },
    onError: (err) => {
      setErrorMessage({
        title: "Tidak dapat menyelesaikan pesanan",
        content: err.message.id,
        status: true,
      });
    },
  });

  const pageCount = useMemo(() => {
    if (transactionListData === undefined) {
      return 0;
    }
    return Math.ceil(transactionListData.count_pagination / FETCH_LIMIT);
  }, [transactionListData]);

  const onChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    },
    [startDate, endDate]
  );

  useEffect(() => {
    mutateTransactionList({
      limit: FETCH_LIMIT,
      offset,
      search: debounce,
      status,
      start_date: toDate(startDate) === "Invalid date" ? "" : toDate(startDate),
      end_date: toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
    });
  }, [offset, debounce, status, refreshMutation]);

  return (
    <div>
      {isTransactionListIsLoading ? (
        <div className="h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {transactionListData && transactionListData !== undefined ? (
            <>
              <p className="text-[48px] font-medium">List Pembelian</p>
              <div className="flex gap-3 text-[#007AF1] font-bold my-3 p-3">
                <p
                  className="hover:underline hover:cursor-pointer"
                  onClick={() => navigate("/purchase")}
                >
                  Purchase
                </p>
                <p>/</p>
                <p className="hover:underline hover:cursor-pointer">
                  List Pembelian
                </p>
              </div>
              <div className="flex w-full items-center gap-x-3">
                <TextField
                  label="Cari Transaksi"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl sx={{ width: "40%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Pilih Tanggal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Pilih Tanggal"
                    value={period}
                    onChange={(e) => {
                      if (e.target.value === 1) {
                        mutateTransactionList({
                          limit: FETCH_LIMIT,
                          offset,
                          search: debounce,
                          status,
                          start_date: "",
                          end_date: "",
                        });
                        setStartDate("");
                        setEndDate("");
                      } else if (e.target.value === 2) setIsDateModalOpen(true);
                      setPeriod(Number(e.target.value));
                    }}
                  >
                    <MenuItem value={1}>Semua Tanggal</MenuItem>
                    <MenuItem value={2}>Pilih Tanggal</MenuItem>
                    <MenuItem value={3} disabled sx={{ display: "none" }}>
                      {toDate(startDate) + " - " + toDate(endDate)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value={1}>Semua Status</MenuItem>
                    <MenuItem value={2}>Sedang Berlangsung</MenuItem>
                    <MenuItem value={3}>Selesai</MenuItem>
                    <MenuItem value={4}>Dibatalkan</MenuItem>
                    <MenuItem value={5}>Menunggu Konfirmasi</MenuItem>
                    <MenuItem value={6}>Diproses</MenuItem>
                    <MenuItem value={7}>Dikirim</MenuItem>
                    <MenuItem value={8}>Tiba ditujuan</MenuItem>
                    <MenuItem value={9}>Dikomplain</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                className="rounded-md w-full border border-black p-3 flex justify-between mt-2 hover:cursor-pointer items-center"
                onClick={() => navigate("/purchase/waiting-payment")}
              >
                <Typography>Menunggu Pembayaran</Typography>
                <div className="flex gap-x-1 items-center">
                  <p className="text-white bg-red-500 rounded-full px-2 py-1 text-[14px]">
                    {transactionListData.count}
                  </p>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              {transactionListData.transaction !== null ? (
                transactionListData.transaction.map((data, index) => (
                  <div
                    className="border border-black rounded-md p-3 my-3 font-bold"
                    key={index}
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-x-2 items-center">
                        <p>{data.tanggal_order}</p>
                        <StatusTransaction status={data.status_name} />
                        <p>{data.nomor_invoice}</p>
                      </div>
                      {data.status_name === "Tiba Di Tujuan" ? (
                        <div className="flex gap-x-2 items-center">
                          <p>Selesai Otomatis</p>
                          <div className="rounded-md bg-[#FF8D40] p-2 text-white flex items-center">
                            <AiOutlineClockCircle className="text-[30px]" />
                            <p>{data.selesai_otomatis}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex justify-between w-full">
                      <div className="flex items-center gap-x-3">
                        <img
                          src={data.product_image}
                          alt=""
                          className="border border-black rounded-md w-[150px] h-auto bg-cover"
                        />
                        <div>
                          <p>{data.product_name}</p>
                          <p>jumlah: {data.quantity}</p>
                          <p>
                            {data.price.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <div className="h-full border border-black mr-3"></div>
                        <div className="p-4 text-center">
                          <p>Total</p>
                          <p className="font-bold">
                            {data.total_transaction.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex gap-x-2 justify-end">
                      <button
                        className="border border-black p-2 rounded-md"
                        onClick={() => {
                          navigate(
                            `/purchase/detail-transaction/${data.nomor_invoice}`
                          );
                        }}
                      >
                        Lihat Detail
                      </button>
                      {data.status_name === "Tiba Di Tujuan" ? (
                        <button
                          className="text-white bg-[#007AF1] p-2 rounded-md mx-2"
                          onClick={() => {
                            setFinishedOrder({
                              ...finishedOrder,
                              open: true,
                              invoice: data.nomor_invoice,
                            });
                          }}
                        >
                          Selesai
                        </button>
                      ) : null}
                      <button
                        className="border border-black p-2 rounded-md px-3"
                        onClick={(e) => {
                          setReorderValue({
                            statusName: data.status_name,
                            invoice: data.nomor_invoice,
                          });
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        ...
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center mt-5">
                  <p>Tidak ada Produk!</p>
                </div>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setIsHelpInformationModalOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Bantuan
                </MenuItem>
                {reorderValue.statusName === "Selesai" ? (
                  <MenuItem
                    onClick={() => {
                      setReorderTransaction({
                        ...reorderTransaction,
                        open: true,
                        invoice: reorderValue.invoice,
                      });
                      setAnchorEl(null);
                    }}
                  >
                    Beli Lagi
                  </MenuItem>
                ) : null}
                {reorderValue.statusName === "Tiba Di Tujuan" ? (
                  <MenuItem
                    onClick={() => {
                      navigate(`/purchase/complain/${reorderValue.invoice}`);
                    }}
                  >
                    Ajukan Komplain
                  </MenuItem>
                ) : null}
              </Menu>
              {transactionListData.transaction !== null ? (
                <div className="flex justify-center">
                  <Pagination
                    count={pageCount}
                    page={offset}
                    onChange={(_, value) => setOffset(value)}
                    shape="rounded"
                    size="large"
                    color="primary"
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
      <HelpInformationModal
        isModalOpen={isHelpInformationModalOpen}
        setIsModalOpen={setIsHelpInformationModalOpen}
      />
      <FinishOrderPurchaseModal
        finishedOrder={finishedOrder}
        setFinishedOrder={setFinishedOrder}
        mutateTransactionDone={mutateTransactionDone}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isMutateTransactionDoneLoading={isMutateTransactionDoneLoading}
      />
      <ReorderPurchaseModal
        reorderTransaction={reorderTransaction}
        setReorderTransaction={setReorderTransaction}
        mutateReorderTransaction={mutateReorderTransaction}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isMutateReorderTransactionLoading={isMutateReorderTransactionLoading}
      />
      <Dialog
        open={isDateModalOpen}
        onClose={() => {
          setIsDateModalOpen(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold"
            onClick={() => {
              if (startDate !== "" && endDate !== "") {
                setPeriod(3);
                setIsDateModalOpen(false);
                mutateTransactionList({
                  limit: FETCH_LIMIT,
                  offset,
                  search: debounce,
                  status,
                  start_date:
                    toDate(startDate) === "Invalid date"
                      ? ""
                      : toDate(startDate),
                  end_date:
                    toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
                });
              } else {
                setPeriod(1);
                setIsDateModalOpen(false);
              }
            }}
          >
            Pilih
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ListTransactionPurchase;
