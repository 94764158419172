import {
  CameraAlt as CameraAltIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { BsImages } from "react-icons/bs";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  deleteImage,
  uploadImage,
} from "../../../../services/userManagementApi";
import useDebounce from "../../../hooks/useDebounce";
import {
  getCategoryMaster,
  getGroups,
  getPrincipal,
} from "../../../../services/InventoryAPI";
import { useNavigate, useParams } from "react-router-dom";
import {
  getConsignmentDetailProduct,
  setUpdateProductConsignment,
} from "../../../../services/consignmentApi";

const EditConsignmentProduct = () => {
  const { params } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [nieNumber, setNieNumber] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [openCategory, setOpenCategory] = useState(false);
  const [optionCategory, setOptionCategory] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [openPrincipal, setOpenPrincipal] = useState(false);
  const [optionPrincipal, setOptionPrincipal] = useState([]);
  const [principalValue, setPrincipalValue] = useState([]);
  const [searchPrincipal, setSearchPrincipal] = useState("");

  const debounceCategory = useDebounce(searchCategory, 500);
  const debouncePrincipal = useDebounce(searchPrincipal, 500);

  const invoiceNum = atob(params.split(":")[0]);
  const id = atob(params.split(":")[1]);

  const { data } = useQuery({
    queryKey: ["consignment-product"],
    queryFn: () => getConsignmentDetailProduct(id, invoiceNum),
    staleTime: Infinity,
  });

  const tempDetail = useFormik({
    initialValues: {
      product_id: Number(id),
      nie_number: data && data !== undefined ? data.nie_number : "",
      product_name: data && data !== undefined ? data.product_name : "",
      product_image: data && data !== undefined ? data.media : [],
      product_description: {
        indication: data && data !== null ? data.description.indication : "",
        composition: data && data !== null ? data.description.composition : "",
        dosage: data && data !== null ? data.description.dosage : "-",
        usage: data && data !== null ? data.description.usage : "-",
        contraindication:
          data && data !== null ? data.description.contraindication : "",
        side_effect: data && data !== null ? data.description.side_effect : "",
        storage_description:
          data && data !== null ? data.description.storage_description : "",
        require_prescription:
          data && data !== undefined
            ? data.description.require_prescription
            : false,
      },
      packaging: {
        type_box: data && data !== undefined ? data.packing?.type_box : "",

        total_box: data && data !== undefined ? data.packing?.total_box : 1,

        type_strip: data && data !== undefined ? data.packing?.type_strip : "",

        total_strip: data && data !== undefined ? data.packing?.total_strip : 1,

        type_unit: data && data !== undefined ? data.packing?.type_unit : "",

        total_unit: data && data !== undefined ? data.packing?.total_unit : 1,
      },
      category_id: data && data !== undefined ? data.product_category_id : [],
      principal_id: 0,
      label_id: 0,
      status_reminder_stock: false,
      reminder_stock: 0,
      price: {
        platform_price: data && data !== undefined ? data.platform_price : [],
        pharmacy_net_price: data && data !== undefined ? data.hna : 0,
        sell_price: data && data !== undefined ? data.sell_price : 0,
        sell_price_sekunder:
          data && data !== undefined ? data.price_sekunder : 0,
        sell_price_tersier: data && data !== undefined ? data.price_tersier : 0,
        price_grosir: data && data !== undefined ? data.price_grosir : [],
      },

      barcode: data && data !== undefined ? data.barcode : "",
      supplier_name: data && data !== undefined ? data.supplier_name : "",
    },
    enableReinitialize: true,
    validate: () => {},
    validateOnMount: true,
    onSubmit: (values) => {
      mutateUpdateProduct(values);
    },
  });

  const { mutate: mutateDeleteImage } = useMutation({
    mutationFn: deleteImage,
  });

  const { mutate: mutateUploadImage, data: imageData } = useMutation({
    mutationFn: uploadImage,
  });

  const { data: categories } = useQuery({
    queryKey: ["category", debounceCategory, data],
    queryFn: () => getCategoryMaster(10, 1, debounceCategory),
    enabled: !!data,
  });

  const { data: groups } = useQuery({
    queryKey: ["groups", data],
    queryFn: () => getGroups(),
    enabled: !!data,
  });

  const { data: principals, isLoading: isPrincipalLoading } = useQuery({
    queryKey: ["principal", debouncePrincipal],
    queryFn: () => getPrincipal(10, 1, debouncePrincipal),
    enabled: !!data,
  });

  const { data: existingPrincipal } = useQuery({
    queryKey: ["principal", data],
    queryFn: () => getPrincipal(10, 1, data.product_principal_name),
    enabled: !!data,
  });

  const { mutate: mutateUpdateProduct } = useMutation({
    mutationFn: setUpdateProductConsignment,
    onSuccess: () => navigate(-1),
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const handleDeleteImage = useCallback(
    (index, value) => {
      tempDetail.setFieldValue("product_image", [
        ...tempDetail.values.product_image.splice(index, 1),
      ]);
      mutateDeleteImage(value);
    },
    [tempDetail.values.product_image]
  );

  const handleUploadImage = useCallback(
    (e) => {
      mutateUploadImage(e.target.files[0]);
    },
    [tempDetail.values.product_image]
  );

  useEffect(() => {
    if (imageData === undefined) return;
    var temp = [...tempDetail.values.product_image];
    temp.push(imageData.url);
    tempDetail.setFieldValue("product_image", temp);
  }, [imageData]);

  useEffect(() => {
    if (data && groups) {
      var index = groups.findIndex((group) => group.name === data.label_name);
      tempDetail.setFieldValue("label_id", Number(groups[index].id));
    }
  }, [data, groups]);

  useEffect(() => {
    if (categories) {
      setOptionCategory(categories && categories !== null ? categories : []);

      var temp = [];
      if (
        tempDetail.values.category_id &&
        tempDetail.values.category_id !== null
      )
        tempDetail.values.category_id.map((item) => {
          var index = categories.findIndex((cat) => cat.id === item);
          if (index >= 0) temp.push(categories[index]);
        });
      setCategoryValue(temp);
    }
  }, [tempDetail.values.category_id]);

  useEffect(() => {
    if (data && categories) {
      var temp = [];
      data.product_category_id.map((item) => {
        var index = categories.findIndex((cat) => cat.id === item);
        temp.push(categories[index]);
      });
      setCategoryValue(temp);
    }
  }, [data, categories]);

  useEffect(() => {
    if (existingPrincipal) {
      tempDetail.setFieldValue(
        "principal_id",
        existingPrincipal ? existingPrincipal[0].id : 0
      );
      setPrincipalValue(existingPrincipal[0]);
    }
  }, [existingPrincipal]);

  useEffect(() => {
    if (openCategory)
      setOptionCategory(categories && categories !== null ? categories : []);
    if (openPrincipal)
      setOptionPrincipal(principals !== undefined ? principals : []);
  }, [categories, openCategory, principals, openPrincipal]);

  useEffect(() => {
    if (data && data !== null) {
      setNieNumber(data.nie_number);
    }
  }, [data]);

  useEffect(() => {
    if (data && data !== null) {
      tempDetail.setFieldValue(
        "price.pharmacy_net_price",
        tempDetail.values.price.pharmacy_net_price
      );
      tempDetail.setFieldValue(
        "price.sell_price_sekunder",
        Number(
          parseFloat(
            tempDetail.values.price.pharmacy_net_price /
              tempDetail.values.packaging.total_strip
          ).toFixed(2)
        )
      );
      tempDetail.setFieldValue(
        "price.price_tersier",
        Number(
          parseFloat(
            tempDetail.values.price.pharmacy_net_price /
              (tempDetail.values.packaging.total_strip *
                tempDetail.values.packaging.total_unit)
          ).toFixed(2)
        )
      );
    }
  }, [tempDetail.values.price.pharmacy_net_price]);

  return (
    <>
      <div className="border-[#d3d3d3] border-2 rounded-md p-4 my-8">
        <p className="font-medium text-[20px] lg:text-[25px]">Detail Produk</p>
      </div>
      <form onSubmit={tempDetail.handleSubmit}>
        {data && data !== null ? (
          <div className="w-full my-4">
            <div className="text-gray-500 p-4 border border-black rounded-md shadow-md flex justify-between">
              <p>{nieNumber}</p>
            </div>

            <div className="input-foto">
              <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                <span>
                  <BsImages />
                </span>
                Foto Produk
              </div>
              <div className="flex items-center justify-center h-64 border-2 border-dashed">
                {tempDetail.values.product_image.map((value, index) => (
                  <div
                    className="flex items-start justify-center w-[25%] mx-[10px]"
                    key={index}
                  >
                    <img
                      alt=""
                      className="rounded-md h-[50%] w-[50%]"
                      src={value}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (tempDetail.values.product_image.length === 1)
                          return;
                        handleDeleteImage(index, value);
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                ))}
                <label
                  htmlFor="dropzone-file1"
                  className="cursor-pointer flex flex-col items-center justify-center w-[15%] p-6 border-2 rounded-md"
                >
                  <input
                    id="dropzone-file1"
                    hidden
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={(e) => {
                      if (
                        tempDetail.values.product_image.length >= 4 ||
                        !data ||
                        (data && data === null)
                      )
                        return;
                      handleUploadImage(e);
                    }}
                  />
                  <div className="flex justify-center flex-col text-center">
                    <CameraAltIcon fontSize="large" sx={{ color: "black" }} />
                    <p>{tempDetail.values.product_image.length}/4</p>
                  </div>
                </label>
              </div>
            </div>

            <TextField
              id="outlined-basic"
              label="Nama Obat"
              variant="outlined"
              fullWidth
              className="mt-3"
              disabled={nieNumber === ""}
              value={tempDetail.values.product_name}
              onChange={(e) =>
                tempDetail.setFieldValue("product_name", e.target.value)
              }
            />

            <p className="mt-8 mb-4">Tambah Kategori Obat</p>
            {categoryValue &&
            categoryValue.length > 0 &&
            optionCategory &&
            optionCategory.length > 0 ? (
              <Autocomplete
                fullWidth
                multiple
                disabled={nieNumber === ""}
                sx={{ marginTop: 3 }}
                open={openCategory}
                onOpen={() => setOpenCategory(true)}
                onClose={() => setOpenCategory(false)}
                disablePortal
                options={optionCategory}
                value={categoryValue}
                onChange={(_, inputValue) => {
                  setCategoryValue(inputValue);
                  setOptionCategory([]);
                  tempDetail.setFieldValue(
                    "category_id",
                    inputValue !== null ? inputValue.map((val) => val.id) : []
                  );
                }}
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearchCategory(onInputValue);
                    setOptionCategory([]);
                  }
                }}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tambah Kategori"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <p>sdfsdf</p>
            )}
            <TextField
              fullWidth
              name="indication"
              disabled={nieNumber === ""}
              label="Indikasi / Kegunaan"
              variant="outlined"
              className="mt-3"
              value={tempDetail.values.product_description.indication}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.indication",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-basic"
              name="composition"
              disabled={nieNumber === ""}
              label="Kandungan / Komposisi"
              variant="outlined"
              className="mt-3"
              value={tempDetail.values.product_description.composition}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.composition",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-multiline-static"
              name="dosage"
              label="Dosis"
              className="mt-3"
              disabled={nieNumber === ""}
              value={tempDetail.values.product_description.dosage}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.dosage",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-multiline-static"
              name="usage"
              label="Cara Pemakaian"
              multiline
              disabled={nieNumber === ""}
              rows={2}
              className="mt-3"
              value={tempDetail.values.product_description.usage}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.usage",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-basic"
              name="containdication"
              label="Kontradiksi / Jangan digunakan oleh"
              disabled={nieNumber === ""}
              variant="outlined"
              className="mt-3"
              value={tempDetail.values.product_description.contraindication}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.contraindication",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Efek Samping"
              name="side_effect"
              multiline
              disabled={nieNumber === ""}
              rows={3}
              variant="outlined"
              className="mt-3"
              value={tempDetail.values.product_description.side_effect}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.side_effect",
                  e.target.value
                )
              }
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Cara Penyimpanan"
              name="storage_description"
              variant="outlined"
              className="mt-3"
              disabled={nieNumber === ""}
              value={tempDetail.values.product_description.storage_description}
              onChange={(e) =>
                tempDetail.setFieldValue(
                  "product_description.storage_description",
                  e.target.value
                )
              }
            />

            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Golongan Obat
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="label_id"
                id="demo-simple-select"
                label="Golongan Obat"
                value={tempDetail.values.label_id}
                disabled={nieNumber === ""}
                onChange={(e) =>
                  tempDetail.setFieldValue("label_id", e.target.value)
                }
              >
                <MenuItem value="" disabled sx={{ display: "none" }}></MenuItem>
                {groups !== undefined ? (
                  groups &&
                  groups.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem
                    value=""
                    disabled
                    sx={{ display: "none" }}
                  ></MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              sx={{ marginTop: 3 }}
              id="outlined-basic"
              label="Perlu resep"
              variant="filled"
              disabled
              fullWidth
              value={tempDetail.values.require_prescription ? `Ya` : `Tidak`}
              onChange={(e) =>
                tempDetail.setFieldValue("require_prescription", e.target.value)
              }
            />
            <Autocomplete
              fullWidth
              sx={{ marginTop: 3 }}
              loading={isPrincipalLoading}
              disabled={nieNumber === ""}
              onOpen={() => setOpenPrincipal(true)}
              onClose={() => setOpenPrincipal(false)}
              disablePortal
              options={optionPrincipal}
              value={principalValue}
              onChange={(_, inputValue) => {
                setPrincipalValue(inputValue);
                tempDetail.setFieldValue("principal_id", inputValue?.id);
              }}
              onInputChange={(_, onInputValue, reason) => {
                if (reason === "input") {
                  setSearchPrincipal(onInputValue);
                }
              }}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              id="combo-box-demo"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Principal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isPrincipalLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <TextField
              id="barcode"
              fullWidth
              variant="outlined"
              disabled={nieNumber === ""}
              label="Nomor Barcode"
              value={tempDetail.values.barcode}
              onChange={(e) =>
                tempDetail.setFieldValue("barcode", e.target.value)
              }
              sx={{ marginTop: 3 }}
            />
          </div>
        ) : (
          <div className="w-full flex justify-center items-center my-4">
            <p>Please wait while we fetch your data</p>
            <CircularProgress size={20} className="w-full text-center ml-4" />
          </div>
        )}

        <p className="text-red-500 my-2">{errorMessage}</p>

        <div className="grid grid-cols-2 mt-10 gap-12">
          <button
            className="hover:bg-red-800 bg-red-700 text-white font-semibold p-3 rounded-lg"
            onClick={() => navigate(-1)}
          >
            Batal
          </button>
          <button
            className="hover:bg-orange-600 bg-orange-400 text-white font-semibold p-3 rounded-lg"
            type="submit"
          >
            Konfirmasi
          </button>
        </div>
      </form>
    </>
  );
};

export default EditConsignmentProduct;
