import { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Snackbar, IconButton, Menu, MenuItem } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineContentCopy } from "react-icons/md";

import { fetchTransactionList } from "../../../../../services/purchaseApi";
import HelpInformationModal from "../../../../common/particles.jsx/HelpInformationModal";

const FETCH_LIMIT = 10;

const WaitingPaymentPurchase = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [copyMessage, setCopyMessage] = useState("");
  const [isHelpInformationModalOpen, setIsHelpInformationModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    mutate: mutateTransactionList,
    data: transactionListData,
    isLoading: isTransactionListIsLoading,
  } = useMutation({
    mutationFn: fetchTransactionList,
  });

  useEffect(() => {
    mutateTransactionList({
      limit: FETCH_LIMIT,
      offset,
      search: "",
      status: 10,
    });
  }, [offset]);

  return (
    <div>
      <div className="flex gap-3 text-[#007AF1] font-bold my-3 p-3">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/purchase")}
        >
          Purchase
        </p>
        <p>/</p>
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => navigate("/purchase/list-transaction")}
        >
          List Pembelian
        </p>
        <p>/</p>
        <p className="hover:underline hover:cursor-pointer">
          Menunggu Pembayaran
        </p>
      </div>
      {isTransactionListIsLoading ? (
        <div className="h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {transactionListData && transactionListData !== undefined ? (
            <>
              {transactionListData.transaction &&
              transactionListData.transaction !== null ? (
                transactionListData.transaction.map((data, index) => (
                  <div
                    className="border border-black rounded-md p-3 my-3 font-medium"
                    key={index}
                  >
                    <div className="flex gap-x-2 items-center justify-between">
                      <div className="flex gap-x-2 items-center">
                        <p>{data.tanggal_order}</p>
                        <div className="rounded-md bg-[#9AA4B2] p-2 text-white">
                          {data.status_name}
                        </div>
                        <p>{data.nomor_invoice}</p>
                      </div>
                      <div className="flex gap-x-2 items-center">
                        <p>Bayar Sebelum</p>
                        <div className="rounded-md bg-[#FF8D40] p-2 text-white flex items-center">
                          <AiOutlineClockCircle className="text-[30px]" />
                          <p>{data.bayar_sebelum}</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex justify-between w-full">
                      <div className="flex items-center gap-x-3">
                        <img
                          src={data.product_image}
                          alt=""
                          className="border border-black rounded-md w-[150px] h-auto bg-cover"
                        />
                        <div>
                          <p>{data.product_name}</p>
                          <p>jumlah: {data.quantity}</p>
                          <p className="font-bold">
                            {data.price.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <div className="h-full border border-black mr-3"></div>
                        <div className="p-4 text-center">
                          <p>Total</p>
                          <p className="font-bold">
                            {data.total_transaction.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex gap-x-2 justify-between items-center">
                      <div className="flex gap-x-2 items-center font-medium">
                        <div>
                          <img
                            src={data.logo_pembayaran}
                            className="w-[50px] h-auto bg-cover"
                          />
                        </div>
                        <div>
                          <p className="text-[#7D8A9C] text-[12px]">
                            Metode Pembayaran
                          </p>
                          <p>{data.metode_pembayaran}</p>
                        </div>
                        {data.metode_pembayaran === "SHOPEPAY QRIS" ? null : (
                          <div>
                            <p className="text-[#7D8A9C] text-[12px]">
                              Nomor Virtual Account
                            </p>
                            <div className="flex gap-x-2 items-center">
                              <p>{data.va_number}</p>
                              {/* <MdOutlineContentCopy
                            className="text-[20px] hover:cursor-pointer"
                            onClick={() => {
                              const textToCopy = textRef.current.innerText;
                              navigator.clipboard
                                .writeText(textToCopy)
                                .then(() => {
                                  setCopyMessage("Text copied to clipboard");
                                });
                            }}
                          /> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="gap-x-2 ">
                        <button className="border border-black p-2 rounded-md">
                          Lihat Detail
                        </button>
                        <button
                          className="text-white bg-[#007AF1] p-2 rounded-md mx-2"
                          onClick={() =>
                            navigate(`/purchase/payment/${data.nomor_invoice}`)
                          }
                        >
                          Bayar Sekarang
                        </button>
                        <button
                          className="border border-black p-2 rounded-md px-3"
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                          ...
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h-screen flex items-center justify-center">
                  <p>Tidak Ada Produk</p>
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setIsHelpInformationModalOpen(true);
            setAnchorEl(null);
          }}
        >
          Bantuan
        </MenuItem>
      </Menu>
      <Snackbar
        open={Boolean(copyMessage)}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setCopyMessage("");
        }}
        message={copyMessage}
        action={
          <Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setCopyMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <HelpInformationModal
        isModalOpen={isHelpInformationModalOpen}
        setIsModalOpen={setIsHelpInformationModalOpen}
      />
    </div>
  );
};

export default WaitingPaymentPurchase;
