import { useMutation } from "@tanstack/react-query";
import { FaRegTrashAlt } from "react-icons/fa";
import { useState } from "react";
import { removeCustomer } from "../../../services/invoiceApi";
import { RxCross1 } from "react-icons/rx";

const ModalDeleteCustomer = (props) => {
  const { open, setOpen, selectedCustomer, refetch, setOffset } = props;

  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateDeleteCustomer } = useMutation({
    mutationFn: removeCustomer,
    onSuccess: () => {
      setOffset(1);
      refetch();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <>
      {open ? (
        <>
          <div
            className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setOpen(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4">
                {/*body*/}
                <div className="flex justify-between items-center px-8 py-2 pb-2">
                  <div className="w-[52px] h-[52px] bg-red-100 rounded-full items-center justify-center flex">
                    <div className="w-[36px] h-[36px] bg-red-300 rounded-full items-center justify-center flex">
                      <FaRegTrashAlt className="text-red-700 w-full h-full p-2" />
                    </div>
                  </div>
                  <div>
                    <RxCross1
                      className="cursor-pointer hover:text-gray-300"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                </div>
                <div className="flex relative flex-auto object-contain px-8 pt-0">
                  <p className="font-bold text-2xl">Delete Customer</p>
                </div>
                {/*footer*/}
                <p className="text-center font-medium text-[16px] my-3 px-8">
                  Apakah anda yakin ingin menghapus{" "}
                  {selectedCustomer.partner_name} dari list customer?
                </p>
                <p className="my-3 text-red-500">{errorMessage}</p>

                <div className="flex items-center justify-center p-3 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="hover:bg-gray-100 border-2 font-bold uppercase text-sm w-full py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-red-400 text-white hover:bg-red-700 active:bg-red-600 font-bold uppercase text-sm w-full py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      mutateDeleteCustomer(selectedCustomer.partner_id)
                    }
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalDeleteCustomer;
