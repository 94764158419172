import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "../../../redux/features/auth/authSlice";
import success from "../../../assets/images/success-img.jpg";
import { VerificationMethodContext } from "../../context/VerificationMethodProvider";

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isVerificationMethodEmail,
    isVerificationMethodPassword,
    isVerificationMethodPhone,
    setIsVerificaitonMethodEmail,
    setIsVerificaitonMethodPassword,
    setIsVerificaitonMethodPhone,
  } = useContext(VerificationMethodContext);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    setIsVerificaitonMethodEmail(false);
    setIsVerificaitonMethodPassword(false);
    setIsVerificaitonMethodPhone(false);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img src={success} />
      {isVerificationMethodEmail && (
        <p className="text-[36px] font-bold">Email berhasil diperbarui</p>
      )}
      {isVerificationMethodPassword && (
        <p className="text-[36px] font-bold">Kata Sandi berhasil diperbarui</p>
      )}
      {isVerificationMethodPhone && (
        <p className="text-[36px] font-bold">No HP berhasil diperbarui</p>
      )}

      <p className="text-[20px] my-5">
        Silahkan Login ulang untuk mengakses OLIN
      </p>
      <button
        className="p-3 bg-[#007AF1] text-white font-bold rounded rounded-md w-[30%]"
        onClick={handleLogout}
      >
        Login
      </button>
    </div>
  );
};

export default SuccessPage;
