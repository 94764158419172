import { useState, useContext, useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Badge, CircularProgress, Pagination } from "@mui/material";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";

import {
  addToCartPurchase,
  searchProductBy,
} from "../../../../../services/purchaseApi";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedProductContext } from "../../../../context/SelectedProductProvider";
import useDebounce from "../../../../hooks/useDebounce";

const FETCH_LIMIT = 10;

const SelectedPrincipalById = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { countCart, isCountCartLoading, isLoading, setIsLoading } = useContext(
    SelectedProductContext
  );

  const [dataBuffer, setDataBuffer] = useState([]);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);

  const debounce = useDebounce(search, 500);

  const { mutate: mutateAddToCart } = useMutation({
    mutationFn: addToCartPurchase,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["count-cart"] });
      setIsLoading(false);
      setDataBuffer((prevValue) =>
        prevValue.map((data, index) => {
          return data.id === prevValue[index].id
            ? {
                ...data,
                isLoading: false,
              }
            : data;
        })
      );
    },
  });

  const {
    mutate: mutateSearchProductBy,
    data: principalIdProduct,
    isLoading: isPrincipalProductLoading,
  } = useMutation({
    mutationFn: searchProductBy,
    onSuccess: (data) => {
      if (data.product) {
        setDataBuffer(
          data.product.map((data) => ({
            id: data.id,
            nama_produk: data.nama_produk,
            quantity: data.quantity,
            image: data.image,
            price: data.price,
            isVisible: data.quantity !== 0,
            isLoading: false,
          }))
        );
      } else {
        setDataBuffer([]);
      }
    },
  });

  const pageCount = useMemo(() => {
    if (principalIdProduct && principalIdProduct !== undefined) {
      return Math.ceil(principalIdProduct.count / FETCH_LIMIT);
    } else return 0;
  }, [principalIdProduct]);

  useEffect(() => {
    mutateSearchProductBy({
      search: debounce,
      offset,
      limit: FETCH_LIMIT,
      type: 1,
      id: Number(id),
    });
  }, [debounce, id, offset, mutateSearchProductBy]);

  return (
    <div>
      <div className="flex gap-3 text-[#007AF1] font-bold my-3 p-3">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => {
            navigate("/purchase");
          }}
        >
          Purchase
        </p>
        <p>/</p>
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() => {
            navigate("/purchase/principals");
          }}
        >
          Prinsipal
        </p>
        <p>/</p>
        <p className="hover:underline hover:cursor-pointer">Nama Prinsipal</p>
      </div>
      <div className="w-full flex items-center mb-3">
        <div className="flex flex-row items-center w-full">
          <input
            id="myInput"
            className="block w-full  pl-3 pr-3 py-2 border  rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out hover:cursor-pointer"
            placeholder="Cari Produk"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <HiOutlineClipboardList
            className="text-[40px] p-2 shadow-md border rounded-lg mx-3 hover:cursor-pointer"
            onClick={() => navigate("/purchase/list-transaction")}
          />
          {countCart && countCart !== undefined ? (
            <Badge
              badgeContent={
                countCart && countCart !== undefined ? countCart.count : 0
              }
              color="error"
            >
              <AiOutlineShoppingCart
                className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
                onClick={() => navigate("/purchase/cart-detail")}
              />
            </Badge>
          ) : (
            <AiOutlineShoppingCart
              className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
              onClick={() => navigate("/purchase/cart-detail")}
            />
          )}
        </div>
      </div>

      <div className="w-full">
        <p className="text-[36px] font-bold">Prinsipal</p>
        <div className="border-2 border-black"></div>
        {isPrincipalProductLoading ? (
          <div className="flex justify-center h-screen items-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex grid grid-cols-4 gap-3 mt-3">
            {dataBuffer && dataBuffer !== undefined
              ? dataBuffer.map((list, index) => (
                  <div key={index}>
                    <div className="p-3 bg-white rounded-lg shadow-md w-full h-full flex flex-wrap content-between">
                      <div>
                        <img
                          src={list.image !== null ? list.image : ""}
                          alt=""
                          className="rounded-lg object-cover hover:cursor-pointer"
                          onClick={() =>
                            navigate(`/purchase/detail/${list.id}`)
                          }
                        />
                        <div className="mt-3 text-[12px] lg:text-[15px]">
                          <h1 className="text-gray-700 font-bold ">
                            {list.nama_produk}
                          </h1>
                          <p className="text-gray-500 mt-2">{list.uom}</p>
                          <h1 className="text-gray-700 font-bold">
                            Rp. {list.price}
                          </h1>
                          {/* <div className="flex gap-2 items-center mt-1">
                          <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                            15%
                          </p>
                          <p className="line-through">Rp. {productPrice}</p>
                        </div> */}
                        </div>
                      </div>
                      <>
                        {list.isVisible ? (
                          <div className="w-full mt-3">
                            <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                              {list.isLoading ? (
                                <div className="flex justify-center w-full">
                                  <p>Loading</p>
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="bg-[#FF7A5D] px-3 rounded-lg"
                                    disabled={isLoading}
                                    onClick={() => {
                                      if (list.quantity === 1) {
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((data) => {
                                            return data.id === list.id
                                              ? {
                                                  ...data,
                                                  isVisible: false,
                                                  isLoading: true,
                                                  quantity: 0,
                                                }
                                              : data;
                                          })
                                        );
                                        mutateAddToCart({
                                          price: list.price,
                                          product_id: list.id,
                                          quantity: 0,
                                          flag: false,
                                          type: 1,
                                          param: 1,
                                        });
                                        setIsLoading(true);
                                        return;
                                      } else
                                        setDataBuffer((prevValue) =>
                                          prevValue.map((data) => {
                                            return data.id === list.id
                                              ? {
                                                  ...data,
                                                  isVisible: false,
                                                  isLoading: true,
                                                  quantity: data.quantity - 1,
                                                }
                                              : data;
                                          })
                                        );
                                      setIsLoading(true);
                                      mutateAddToCart({
                                        price: list.price,
                                        product_id: list.id,
                                        quantity: list.quantity - 1,
                                        flag: false,
                                        type: 1,
                                        param: 1,
                                      });
                                    }}
                                  >
                                    -
                                  </button>
                                  {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBufferMostSearched[listIndex]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBufferMostSearched(
                                      dataBufferMostSearched.map((list) => {
                                        return list.id === productId
                                          ? {
                                              ...list,
                                              quantity: 0,
                                            }
                                          : list;
                                      })
                                    );
                                  setDataBufferMostSearched(
                                    dataBufferMostSearched.map((list) => {
                                      return list.id === productId
                                        ? {
                                            ...list,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0

                                              ),
                                              10
                                            ),
                                          }
                                        : list;
                                    })
                                  );
                                }}
                              /> */}
                                  <p>{list.quantity}</p>
                                  <button
                                    className="bg-[#5DFFBB] px-3 rounded-lg"
                                    disabled={isLoading}
                                    onClick={() => {
                                      setDataBuffer((prevValue) =>
                                        prevValue.map((data) => {
                                          return data.id === list.id
                                            ? {
                                                ...data,
                                                isLoading: true,
                                                quantity: data.quantity + 1,
                                              }
                                            : data;
                                        })
                                      );
                                      setIsLoading(true);
                                      mutateAddToCart({
                                        price: list.price,
                                        product_id: list.id,
                                        quantity: list.quantity + 1,
                                        flag: false,
                                        type: 1,
                                        param: 1,
                                      });
                                    }}
                                  >
                                    +
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="mt-3 text-[12px] lg:text-base w-full">
                            <button
                              className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                              disabled={isLoading}
                              onClick={() => {
                                setDataBuffer((prevValue) =>
                                  prevValue.map((data) => {
                                    return data.id === list.id
                                      ? {
                                          ...data,
                                          isVisible: true,
                                          isLoading: true,
                                          quantity: 1,
                                        }
                                      : data;
                                  })
                                );
                                mutateAddToCart({
                                  price: list.price,
                                  product_id: list.id,
                                  quantity: 1,
                                  flag: false,
                                  type: 1,
                                  param: 1,
                                });
                                setIsLoading(true);
                              }}
                            >
                              <AiOutlineShoppingCart />
                              Add to cart
                            </button>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
        {dataBuffer &&
        dataBuffer !== undefined &&
        !isPrincipalProductLoading ? (
          <div className="flex justify-center w-full items-center my-3">
            <Pagination
              count={pageCount}
              page={offset}
              onChange={(_, value) => setOffset(value)}
              shape="rounded"
              size="large"
              color="primary"
            />
          </div>
        ) : null}
      </div>
      {countCart && countCart !== undefined ? (
        <div className="flex justify-center w-full mt-20">
          {countCart.count !== 0 ? (
            <div className="fixed bottom-[20px] z-20 bg-blue-500 p-3 rounded-md w-[60%] h-[10%]">
              {isCountCartLoading ? (
                <div className="flex justify-center w-full">
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              ) : (
                <div className="flex justify-between items-center font-medium text-white h-full">
                  <div className="flex justify-center items-center h-full">
                    <p>{countCart.count} Barang</p>
                    <div className="border-2 h-full border-white mx-2"></div>
                    <div>
                      <p>Total Belanja</p>
                      <p className="text-[#FDCD78]">
                        {countCart.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[#FF8D40] p-2 text-[20px] rounded-lg"
                    onClick={() => navigate("/purchase/cart-detail")}
                  >
                    Lihat Keranjang
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default SelectedPrincipalById;
