import React from 'react';
import Error from '.././assets/images/404.svg';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className=" bg-[#63616B]">
      <div
        className="
            flex flex-col items-center justify-center h-screen text-gray-700"
      >
        <img src={Error} alt="404" className="w-1/2" />
        <div
          className="absolute 
                top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
        >
          <h1 className="text-center text-[60px] lg:text-[120px] font-bold">404</h1>
          <h2 className="text-center text-[12px] lg:text-[20px]">Halaman yang anda tuju tidak ditemukan</h2>
        </div>
        <div className="flex justify-center items-center mt-10">
          <Link to="/home">
            <button className="bg-[#007AF1] p-2 rounded-lg text-white px-3 hover:bg-blue-700">Kembali ke beranda</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
