import { useState, useEffect, createContext } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getRankPrinciple,
  getMostSearchedProduct,
  fetchCategoriesProduct,
  getProductBasedOnSearch,
  fetchSpecialOffer,
  fetchSpecialOfferBackground,
  fetchAllProducts,
  getCountCart,
  addToCartPurchase,
  searchProduct,
} from "../../services/purchaseApi";

export const SelectedProductContext = createContext();

const FETCH_LIMIT = 10;

const SelectedProductProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchFocus, setSearchFocus] = useState(false);
  const [dataBufferMostSearched, setDataBufferMostSearched] = useState([]);
  const [dataBufferProductList, setDataBufferProductList] = useState([]);
  const [dataBufferProductSearched, setDataBufferProductSearched] = useState(
    [],
  );
  const [dataBufferSpecialOffer, setDataBufferSpecialOffer] = useState([]);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [dataBufferSearchPurchase, setDataBufferSearchPurchase] = useState([]);
  const [dataBufferCategories, setDataBufferCategories] = useState([]);
  const [dataBufferSuggestion, setDataBufferSuggestion] = useState({});
  const [offset, setOffset] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [isProduct, setIsProduct] = useState({
    mostSearchSelected: false,
    specialOfferSelected: false,
    userSearchSelected: false,
    searchSelected: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);

  const { data: countCart, isLoading: isCountCartLoading } = useQuery({
    queryKey: ["count-cart"],
    queryFn: () => getCountCart(1),
  });

  const {
    mutate: mutateAddToCart,
    data: addToCartData,
    isLoading: isAddToCartLoading,
  } = useMutation({
    mutationFn: addToCartPurchase,
    onError: (err) => setErrorMessage(err.message.id),
    onSuccess: (data) => {
      setIsLoading(false);
      if (data.suggestion?.hasOwnProperty("related")) setIsSuggestionOpen(true);
      queryClient.invalidateQueries({ queryKey: ["count-cart"] });
      setDataBufferMostSearched((prevValue) =>
        prevValue.map((item, index) => {
          return item.id === prevValue[index]?.id
            ? {
                ...item,
                isLoading: false,
              }
            : item;
        }),
      );
      setDataBufferProductSearched((prevValue) =>
        prevValue.map((item, index) => {
          return item.id === prevValue[index]?.id
            ? {
                ...item,
                isLoading: false,
              }
            : item;
        }),
      );
      setDataBufferProductList((prevValue) =>
        prevValue.map((item, index) => {
          return item.id === prevValue[index]?.id
            ? {
                ...item,
                isLoading: false,
              }
            : item;
        }),
      );
      setDataBufferSearchPurchase((prevValue) => ({
        ...prevValue,
        product: prevValue.product?.map((data, index) => {
          return data.id === prevValue.product[index]?.id
            ? {
                ...data,
                isLoading: false,
              }
            : data;
        }),
      }));
      setDataBufferSpecialOffer((prevValue) =>
        prevValue.map((data, index) => {
          return data.id === prevValue[index]?.id
            ? {
                ...data,
                isLoading: false,
              }
            : data;
        }),
      );
      setDataBufferSuggestion((prevValue) => ({
        ...prevValue,
        parent: {
          isLoading: false,
        },
        related: prevValue?.related?.map((data, index) => {
          return data.id === prevValue?.related[index]?.id
            ? {
                ...data,
                isLoading: false,
              }
            : data;
        }),
      }));
    },
  });

  const {
    data: searchedProductData,
    mutate: mutateSearchProduct,
    isLoading: isSearchedProductLoading,
  } = useMutation({
    mutationFn: searchProduct,
    onSuccess: () => {
      setSearchFocus(false);
    },
  });

  const { data: mostSearchedProduct, isLoading: isMostSearchedProductLoading } =
    useQuery({
      queryKey: ["most-search", isProduct, offset],
      queryFn: () =>
        getMostSearchedProduct(
          isProduct.mostSearchSelected ? FETCH_LIMIT : 4,
          offset,
        ),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    });

  const { data: rankPrinciple, isLoading: isRankPrincipleLoading } = useQuery({
    queryKey: ["rank-principle", isProduct],
    queryFn: () =>
      getRankPrinciple(offset, isProduct.principalSelected ? FETCH_LIMIT : 8),
  });

  const {
    data: productBasedOnSearch,
    isLoading: isProductBasedOnSearchLoading,
  } = useQuery({
    queryKey: ["product-searched", isProduct],
    queryFn: () =>
      getProductBasedOnSearch(
        offset,
        isProduct.userSearchSelected ? FETCH_LIMIT : 3,
      ),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const {
    mutate: fetchCategories,
    data: categoriesProduct,
    isLoading: isCategoriesLoading,
  } = useMutation({
    mutationFn: fetchCategoriesProduct,
  });

  const {
    mutate: mutateSpecialOffer,
    data: specialOffer,
    isLoading: isSpecialOfferLoading,
  } = useMutation({
    mutationFn: fetchSpecialOffer,
  });

  const {
    mutate: mutateSpecialOfferBackground,
    data: specialOfferBackground,
    isLoading: isBackgroundLoading,
  } = useMutation({
    mutationFn: fetchSpecialOfferBackground,
  });

  const {
    mutate: mutateAllProductList,
    data: productList,
    isLoading: isAllProductLoading,
  } = useMutation({
    mutationFn: fetchAllProducts,
  });

  const isPurchaseLoading =
    isAllProductLoading ||
    isBackgroundLoading ||
    isSpecialOfferLoading ||
    isCategoriesLoading ||
    isProductBasedOnSearchLoading ||
    isMostSearchedProductLoading ||
    isCountCartLoading;

  useEffect(() => {
    setOffset(1);
  }, [isProduct]);

  useEffect(() => {
    mutateSpecialOffer({ offset: offset, limit: FETCH_LIMIT });
    mutateSpecialOfferBackground({ offset: offset, limit: FETCH_LIMIT });
    fetchCategories({
      offset,
      limit: FETCH_LIMIT,
    });
  }, [offset]);

  useEffect(() => {
    mutateAllProductList({ offset: offset, limit: fetchLimit });
  }, [offset, fetchLimit]);

  return (
    <SelectedProductContext.Provider
      value={{
        offset,
        setOffset,
        errorMessage,
        setErrorMessage,
        isSuggestionOpen,
        setIsSuggestionOpen,
        FETCH_LIMIT,
        isProduct,
        setIsProduct,
        setFetchLimit,
        rankPrinciple,
        categoriesProduct,
        productBasedOnSearch,
        mostSearchedProduct,
        specialOffer,
        specialOfferBackground,
        productList,
        isAllProductLoading,
        countCart,
        mutateAddToCart,
        addToCartData,
        dataBufferMostSearched,
        setDataBufferMostSearched,
        dataBufferProductList,
        setDataBufferProductList,
        dataBufferProductSearched,
        setDataBufferProductSearched,
        dataBufferSpecialOffer,
        setDataBufferSpecialOffer,
        isLoading,
        setIsLoading,
        dataBuffer,
        setDataBuffer,
        dataBufferCategories,
        setDataBufferCategories,
        search,
        setSearch,
        dataBufferSearchPurchase,
        setDataBufferSearchPurchase,
        dataBufferSuggestion,
        setDataBufferSuggestion,
        searchedProductData,
        mutateSearchProduct,
        searchFocus,
        setSearchFocus,
        isSearchedProductLoading,
        isAddToCartLoading,
        isCountCartLoading,
        isPurchaseLoading,
        fetchCategories,
      }}
    >
      {children}
    </SelectedProductContext.Provider>
  );
};

export default SelectedProductProvider;
