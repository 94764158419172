import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FaChevronLeft } from "react-icons/fa";
import { getPatientList } from "../../../services/patientCare";
import { useEffect } from "react";

const PopoverPatientList = ({
  anchorElPatient,
  setAnchorElPatient,
  searchPatient,
  setSearchPatient,
  patientArr,
  setPatientArr,
  debouncePatient,
}) => {
  const {
    mutate: mutateListPatient,
    data: dataListPatient,
    isLoadingPatient: isLoadingPatient,
  } = useMutation({
    mutationFn: getPatientList,
  });

  const handleClosePatient = () => {
    setAnchorElPatient(null);
    setSearchPatient("");
  };

  useEffect(() => {
    mutateListPatient({
      search: debouncePatient,
      offset: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      sort: 1,
      gender: 0,
      status: 0,
    });
  }, [debouncePatient]);

  return (
    <Popover
      open={Boolean(anchorElPatient)}
      anchorEl={anchorElPatient}
      onClose={handleClosePatient}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ width: "100%", minWidth: "50vw" }}
    >
      <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
        <div className="sticky top-0 bg-white">
          <div className="px-8 py-3">
            <div className="grid grid-cols-3 items-center mb-2 gap-4">
              <FaChevronLeft
                className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                onClick={handleClosePatient}
              />
              <p className="text-center font-bold text-lg">List Pasien</p>
            </div>
            <TextField
              fullWidth
              sx={{ marginBottom: 1 }}
              placeholder="Find Patient"
              value={searchPatient}
              onChange={(e) => setSearchPatient(e.target.value)}
              inputProps={{ style: { padding: 8 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <hr />
        </div>
        {dataListPatient &&
        !isLoadingPatient &&
        dataListPatient.data &&
        dataListPatient.count > 0 ? (
          dataListPatient.data.map((item) => (
            <div
              className={`px-8 py-2 border-b-2 last:border:none flex gap-4 cursor-pointer hover:bg-gray-100 ${
                patientArr.map((item) => item.id).includes(item.id)
                  ? "bg-gray-200"
                  : ""
              }`}
              onClick={() => {
                const index = patientArr.findIndex((pat) => pat.id === item.id);
                setPatientArr(index < 0 ? [item] : []);
              }}
            >
              <div className="w-full">
                <div className="mb-3">
                  <p className="font-bold mb-1">{item.patient_name}</p>
                  {item.member ? (
                    <p className="w-fit border-[#FF8D40] border-2 rounded-md text-[#FF8D40] text-center text-sm px-4 py-1">
                      Member
                    </p>
                  ) : null}
                </div>{" "}
                <div className="flex gap-2">
                  <p className="w-1/3">No Rekam Medis</p>
                  <p>:</p>
                  <p>{item.medical_number}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Tanggal Lahir</p>
                  <p>:</p>
                  <p>{item.date_of_birth}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Gender</p>
                  <p>:</p>
                  <p>{item.gender === 1 ? "Laki-laki" : "Perempuan"}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">No Telepon</p>
                  <p>:</p>
                  <p>{item.phone_number === "" ? "-" : item.phone_number}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Email</p>
                  <p>:</p>
                  <p>{item.email === "" ? "-" : item.email}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">NIK</p>
                  <p>:</p>
                  <p>{item.nik}</p>
                </div>
                <div className="flex gap-2">
                  <p className="w-1/3">Alamat</p>
                  <p>:</p>
                  <p>{item.address}</p>
                </div>
              </div>
            </div>
          ))
        ) : isLoadingPatient ? (
          <div className="flex justify-center py-2">
            <CircularProgress size={20} />
          </div>
        ) : (
          <div className="flex justify-center py-2 text-gray-300">
            <p>No Patient Found</p>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default PopoverPatientList;
