import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { BsUpload } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";
import {
  Snackbar,
  CircularProgress,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  getXlTemplate,
  getXlsxData,
  createXl,
  updateCsv,
  getMultipleOutletOption,
  fetchExistingCsvDataMultiple,
} from "../../../../services/importProductApi";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const ImportProduct = ({ isWarehouse }) => {
  const company_id = parseInt(localStorage.getItem("company_id"), 10);
  const titleId = localStorage.getItem("title");
  const job = localStorage.getItem("job");
  const navigate = useNavigate();
  const [xlsxFileTemplate, setXlsxFileTemplate] = useState(null);
  const [xlsxFileExisting, setXlsxFileExisting] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isXlsxTemplateLoading, setIsXlsxTemplateLoading] = useState(false);
  const [isExistingXlsxLoading, setIsExistingXlsxLoading] = useState(false);
  const [companyId, setCompanyId] = useState([]);

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "existing_data.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { refetch: triggerXlsxTemplate } = useQuery({
    queryKey: ["xlsx-template"],
    queryFn: () => getXlTemplate(),
    keepPreviousData: true,
    enabled: false,
    onSuccess: (data) => {
      setIsXlsxTemplateLoading(false);
      handleXlsxData(data);
    },
  });

  const { mutate: fetchExcelData } = useMutation({
    mutationFn: fetchExistingCsvDataMultiple,
    onSuccess: (data) => {
      setIsExistingXlsxLoading(false);
      handleXlsxData(data);
    },
    onError: () => {
      setIsExistingXlsxLoading(false);
    },
  });

  const { mutate: mutateUpdateCsv, isLoading: isMutateUpdateCsvLoading } =
    useMutation({
      mutationFn: updateCsv,
      onSuccess: (data) => {
        setSuccessMessage(data.message.id);
        setXlsxFileExisting(null);
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
      },
    });

  const { mutate: mutateCreateXlsx, isLoading: isMutateCreateXlsxLoading } =
    useMutation({
      mutationFn: createXl,
      onSuccess: (data) => {
        setSuccessMessage(data.message.id);
        setXlsxFileTemplate(null);
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
      },
    });

  const { data: multipleOutletOption } = useQuery({
    queryKey: ["outlet-option"],
    queryFn: () => getMultipleOutletOption(isWarehouse),
    onSuccess: (data) => {
      if (data !== null) {
        setCompanyId(
          data
            .filter((item) => item.company_id === company_id)
            .map((item) => ({
              company_id: item.company_id,
              company_name: item.company_name,
            }))
        );
      } else {
        setCompanyId([{ company_name: job, company_id: company_id }]);
      }
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <LoadingBackdrop
        isLoading={isExistingXlsxLoading || isXlsxTemplateLoading}
      />
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-1">
        Import Product {isWarehouse ? "Central Warehouse" : ""}
      </h1>
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() =>
            navigate(
              `${
                isWarehouse ? "/central-warehouse" : ""
              }/inventory/produk-aktif`
            )
          }
        >
          Produk Aktif
        </p>
        <p>/</p>
        <p>Import Product</p>
      </div>
      {Number(titleId) === 1 && multipleOutletOption !== null ? (
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <InputLabel id="demo-simple-select-label">Pilih Outlet</InputLabel>
          <Select
            label="Pilih Outlet"
            value={companyId}
            onChange={(e) => {
              if (e.target.value.company_id === 0) {
                setCompanyId(multipleOutletOption?.map((data) => data));
              } else {
                setCompanyId((prevValue) => {
                  if (
                    prevValue
                      .map((data) => data.company_id)
                      .some((data) => data === e.target.value.company_id)
                  ) {
                    return prevValue.filter(
                      (data) => data.company_id === e.target.value.company_id
                    );
                  } else {
                    return [...prevValue, e.target.value];
                  }
                });
              }
              // <Checkbox
              //   checked={companyIdBuffer.indexOf(data.company_id) > -1}
              // />
              // <ListItemText primary={data.company_name} />
            }}
            input={<OutlinedInput label="Pilih Outlet" />}
            renderValue={(selected) =>
              selected.map((data) => data.company_name).join(", ")
            }
          >
            <MenuItem value={{ company_id: 0, company_name: "Semua Outlet" }}>
              Semua Outlet
            </MenuItem>
            {multipleOutletOption && multipleOutletOption !== undefined ? (
              multipleOutletOption.map((data, index) => (
                <MenuItem
                  key={index}
                  value={{
                    company_id: data.company_id,
                    company_name: data.company_name,
                  }}
                >
                  {data.company_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0} sx={{ display: "none" }} disabled></MenuItem>
            )}
          </Select>
        </FormControl>
      ) : null}

      <div className="mt-5 flex justify-center flex-col py-0 lg:py-4 lg:px-20 ">
        <h1 className="text-center font-medium text-2xl">
          Input/Update Product
        </h1>
        <>
          <div>
            <ul className="list-decimal px-7 mt-4 text-lg">
              <li>
                Untuk menginput data baru, harap menggunakan template CSV yang
                telah di sediakan.
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    triggerXlsxTemplate();
                    setIsXlsxTemplateLoading(true);
                  }}
                >
                  {" "}
                  Download template Xlsx
                </span>
              </li>
              <li className="mt-3">
                Fitur update existing data produk hanya mendukung perubahan
                harga / indikasi / kegunaan / kegunaan / komposisi / Dosis /
                Cara Pemakaian / Kontraindikasi dan jangan digunakan Oleh, efek
                samping, Cara penyampaian , Nomor Principal, Nomor Izin Edar
                (NIE), HNA, Harga Jual Platform produk aktif. Mohon tidak
                mengganti ID Produk.
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    fetchExcelData({
                      data: companyId.map((data) => data.company_id),
                      warehouse: isWarehouse ? isWarehouse : false,
                    });
                    setIsExistingXlsxLoading(true);
                  }}
                >
                  {" "}
                  Download Data Produk CSV
                </span>
              </li>
            </ul>
            <div className="flex flex-wrap justify-center gap-5 my-5">
              {xlsxFileTemplate !== null ? (
                <>
                  <div className="cursor-pointer flex flex-col items-center justify-center h-64 w-[47%] border-2 rounded-xl">
                    {isMutateCreateXlsxLoading ? (
                      <CircularProgress />
                    ) : (
                      <div className="flex flex-col items-center justify-center p-5">
                        <div className="text-right">
                          <button
                            onClick={() => setXlsxFileTemplate(null)}
                            className="bg-[#F21B3F] p-1 rounded-full"
                          >
                            <CloseIcon sx={{ color: "white" }} />
                          </button>
                          <BiNotepad className="text-[90px] text-blue-500" />
                        </div>
                        <p className="font-medium text-l text-blue-400 mt-3">
                          {xlsxFileTemplate.name}
                        </p>
                        <div className="flex justify-center mt-[10px]">
                          <button
                            className="bg-[#007AF1] p-3 rounded-xl text-white"
                            type="button"
                            onClick={() => {
                              mutateCreateXlsx(xlsxFileTemplate);
                            }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center transform duration-200 hover:scale-105">
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-col items-center justify-center h-64 border-2 rounded-xl"
                    >
                      <div className="flex flex-col items-center justify-center p-5">
                        <BsUpload className="text-[90px] text-blue-500" />
                        <p className="font-medium text-l text-blue-400 mt-3">
                          Input New Data Here
                        </p>
                        <p className="text-center">
                          Saya sudah memiliki berkas <br /> CSV dengan data
                          produk yang siap diunggah
                        </p>
                      </div>
                      <input
                        id="dropzone-file1"
                        type="file"
                        hidden
                        accept=".xlsx, .xls, .xlsm"
                        onChange={(e) => setXlsxFileTemplate(e.target.files[0])}
                      />
                    </label>
                  </div>
                </>
              )}
              {xlsxFileExisting !== null ? (
                <>
                  <div className="cursor-pointer flex flex-col items-center justify-center h-64 w-[47%] border-2 rounded-xl">
                    {isMutateUpdateCsvLoading ? (
                      <CircularProgress />
                    ) : (
                      <div className="flex flex-col items-center justify-center p-5">
                        <div className="text-right">
                          <button
                            onClick={() => setXlsxFileExisting(null)}
                            className="bg-[#F21B3F] p-1 rounded-full"
                          >
                            <CloseIcon sx={{ color: "white" }} />
                          </button>
                          <BiNotepad className="text-[90px] text-blue-500" />
                        </div>
                        <p className="font-medium text-l text-blue-400 mt-3">
                          {xlsxFileExisting !== null
                            ? xlsxFileExisting.name
                            : ""}
                        </p>
                        <div className="flex justify-center mt-[10px]">
                          <button
                            className="bg-[#007AF1] p-3 rounded-xl text-white"
                            onClick={() => {
                              mutateUpdateCsv(xlsxFileExisting);
                            }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="flex items-center transform duration-200 hover:scale-105">
                  <label
                    htmlFor="dropzone-file2"
                    className="cursor-pointer flex flex-col items-center justify-center h-64 border-2 rounded-xl"
                  >
                    <div className="flex flex-col items-center justify-center p-5">
                      <BsUpload className="text-[90px] text-blue-500" />
                      <p className="font-medium text-l text-blue-400 mt-3">
                        Update Existing Data
                      </p>
                      <p className="text-center">
                        Saya sudah memiliki berkas <br /> CSV dengan data produk
                        yang siap diunggah
                      </p>
                    </div>
                    <input
                      id="dropzone-file2"
                      type="file"
                      hidden
                      accept=".xlsx"
                      onChange={(e) => setXlsxFileExisting(e.target.files[0])}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
        </>
      </div>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSuccessMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ImportProduct;
