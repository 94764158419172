import { useState, useContext, useMemo, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Collapse, ListItemButton } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { AiFillExclamationCircle, AiFillCheckCircle } from "react-icons/ai";
import moment from "moment";

import { checkStatusTransactionSubs } from "../../../services/paymentApi";
import { PaymentContext } from "../../context/PaymentStatusProvider";

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const SubscriptionPurchase = () => {
  const navigate = useNavigate();
  const { setPaymentPayload, transaction, setTransaction } =
    useContext(PaymentContext);
  const [isPaymentInstructionOpen, setIsPaymentInstructionOpen] = useState([
    false,
    false,
    false,
  ]);

  const { mutate: checkStatusPayment } = useMutation({
    mutationFn: checkStatusTransactionSubs,
    onSuccess: (data) => {
      setPaymentPayload(data);
      navigate("/payment-status");
    },
  });

  const totalPrice = useMemo(() => {
    if (transaction && transaction !== undefined) {
      let finalPrice = 0;
      let priceMinusDiscount =
        transaction.detail_plan?.price_after_discount -
        transaction.detail_price?.voucher_discount;
      //      let tax = priceMinusDiscount * 0.11;
      if (transaction.detail_plan?.type === 1) {
        finalPrice =
          //priceMinusDiscount + tax + transaction.detail_price?.biaya_penanganan;
          priceMinusDiscount + transaction.detail_price?.biaya_penanganan;
      } else if (transaction.detail_plan?.type === 2) {
        finalPrice =
          //priceMinusDiscount + tax + transaction.detail_price?.biaya_penanganan;
          priceMinusDiscount + transaction.detail_price?.biaya_penanganan;
      }

      return finalPrice;
    }

    return 0;
  }, [transaction]);

  useEffect(() => {
    const payment = sessionStorage.getItem("payment");
    if (payment !== null || undefined) setTransaction(JSON.parse(payment));
  }, [sessionStorage]);

  return (
    <div className="w-full">
      {transaction && transaction !== undefined ? (
        <>
          <div className="bg-[#D7E9FC] font-bold flex items-center p-2">
            <AiFillExclamationCircle className="mr-3 text-[25px] text-[#EFCE4A]" />
            <p>Maksimal proses pembayaran selama 3 jam</p>
          </div>
          <div className="border border-black rounded-md p-2 font-bold mt-3">
            <p className="text-[20px] font-bold">Olin Subscription</p>
            {transaction.detail_plan !== undefined || null ? (
              <>
                <p className="text-[#007AF1] text-[12px] font-bold">
                  {transaction.detail_plan.type_name}
                </p>
                <p className="font-bold">
                  {transaction.detail_plan.price_after_discount.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}{" "}
                  /{transaction.detail_plan.periode_name}
                </p>
                <div className="border border-black my-3"></div>
                {transaction.detail_plan.description.map((desc, index) => (
                  <div className="flex items-center" key={index}>
                    <AiFillCheckCircle className="mr-2 text-[#407BFF] text-[25px]" />
                    <p>{desc}</p>
                  </div>
                ))}
              </>
            ) : null}
            <div className="bg-[#F0F2F4] font-bold flex items-center p-2 mt-3">
              <p>
                Masa langganan anda akan diperpanjang hingga{" "}
                {toDate(transaction.extended_subscription)}
              </p>
            </div>
            <p className="my-4 font-bold">Detail Harga</p>
            <div className="font-bold flex justify-between">
              <div>
                <p>OLIN Susbscription</p>
                <p className="my-3">Voucher Discount</p>
                <p>Biaya Penanganan</p>
              </div>
              <div>
                {transaction.detail_plan !== undefined ||
                (null && transaction.detail_price !== undefined) ||
                null ? (
                  <>
                    <p>
                      {transaction.detail_plan
                        ? transaction.detail_plan.price_after_discount.toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )
                        : ""}
                    </p>
                    <p className="my-3">
                      {transaction.detail_price.voucher_discount.toLocaleString(
                        "id-ID",
                        {
                          style: "currency",
                          currency: "IDR",
                        }
                      )}
                    </p>
                    {transaction.detail_plan.type !== 1 ? (
                      <p>{transaction.detail_price.biaya_penanganan}</p>
                    ) : (
                      <p>
                        {transaction.detail_price.biaya_penanganan.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </p>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div className="border border-black my-3"></div>
            <div className="flex font-bold justify-between">
              <p className="text-[14px]">Total Harga</p>
              <p>
                {totalPrice.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>{" "}
            </div>
          </div>
          <div className="mt-3">
            <p className="text-[20px] font-bold">Transfer ke Virtual Account</p>
            <div className="bg-[#D7E9FC] rounded-md my-3">
              <div className="flex w-[10%] items-center p-2">
                <img src={transaction.logo_bank} className="mr-3" />
                <p className="font-bold">
                  {transaction.number_virtual_account}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <p>
                Pastikan nomor virtual account/pelanggan yang kamu masukkan
                benar. OLIN&nbsp;
              </p>
              <p className="font-bold">Tidak Bertanggung Jawab&nbsp;</p>
              <p>jika Terjadi kesalahan pengisian data</p>
            </div>
            <div className="flex justify-between mt-3 font-bold">
              <p>tagihan kamu</p>
              <p>
                {" "}
                {totalPrice.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <div className="border border-black my-3"></div>
            <div className="flex justify-between font-bold">
              <p>Jatuh Tempo</p>
              <p>
                Mohon lakukan pembayaran sebelum&nbsp;
                {toDate(transaction.payment_due_date)}
              </p>
            </div>
            <div>
              <p className="text-[20px] font-bold my-3">Instruksi Pembayaran</p>
              {transaction.payment_instruction !== undefined || null
                ? transaction.payment_instruction.map((trx, index) => (
                    <div
                      className="w-full border border-black mb-3 rounded-md"
                      key={index}
                    >
                      <ListItemButton
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                        }}
                        onClick={() =>
                          setIsPaymentInstructionOpen((prevBool) => {
                            const newBoolArray = [...prevBool];
                            newBoolArray[index] = !newBoolArray[index];
                            return newBoolArray;
                          })
                        }
                      >
                        <p>{trx.payment_source}</p>
                        <KeyboardArrowDownIcon />
                      </ListItemButton>
                      <Collapse
                        in={isPaymentInstructionOpen[index]}
                        unmountOnExit
                        sx={{ fontWeight: "bold", padding: 2 }}
                      >
                        {trx.payment_instruction.map((instruction, idx) => (
                          <p className="mb-3" key={idx}>
                            {instruction}
                          </p>
                        ))}
                      </Collapse>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <button
            className="bg-[#007AF1] text-white font-bold p-2 w-full rounded-md"
            onClick={() => checkStatusPayment(transaction.bill_no)}
          >
            Cek Status Pembayaran
          </button>
        </>
      ) : null}
    </div>
  );
};

export default SubscriptionPurchase;
