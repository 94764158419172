import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";

import BillingBreadcrumb from "./BillingBreadcrumb";
import { fetchTransactionDetail } from "../../../services/purchaseApi";
import { Button, CircularProgress } from "@mui/material";

const InvoiceDetail = () => {
  const { invoiceNum } = useParams();

  const { mutate: mutateInvoice, data } = useMutation({
    mutationFn: fetchTransactionDetail,
  });

  useEffect(() => {
    mutateInvoice({ invoice: atob(invoiceNum) });
  }, []);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <BillingBreadcrumb />

      {data && data !== null ? (
        <>
          <div className="my-3 px-4 py-2 bg-green-500 text-white rounded-md w-fit">
            {data.status}
          </div>
          <div className="w-full">
            <div className="flex justify-between py-2">
              <p>No. Invoice</p>
              <p>{data.nomor_invoice}</p>
            </div>
            <div className="flex justify-between py-2">
              <p>Tanggal Pembelian</p>
              <p>
                {moment(data.tanggal_pembelian).format("DD MMMM YYYY, HH:mm ")}
                WIB
              </p>
            </div>
            <div className="flex justify-between py-2">
              <p>Apoteker</p>
              <p>{data.apoteker_name}</p>
            </div>
          </div>
          <hr className="my-8" />
          <p className="font-bold text-xl mb-4">Detail Produk</p>
          <>
            {data.product.regular !== null ? (
              data.product.regular.map((item) => {
                return (
                  <div className="w-full border-2 border-[#d8d8d8] rounded-md p-4 flex">
                    <div className="w-[20%] mr-4">
                      <img src={item.image} className="object-contain" />
                    </div>
                    <div className="w-full flex flex-col justify-center font-bold">
                      <p>{item.product_name}</p>
                      <p>
                        {item.quantity}x
                        {item.price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                    <div className="basis-1/3 border-l text-center p-4">
                      <p className="text-grey-300">Total Belanja</p>
                      <p className="font-bold">
                        {item.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Tidak ada produk untuk transaksi ini</p>
            )}
          </>
          <hr className="my-8" />
          <p className="font-bold text-xl mb-4">Info Pengiriman</p>
          <div className="w-full">
            <div className="flex py-2">
              <p className="basis-1/3">Kurir</p>
              <p className="mx-2 w-fit">:</p>
              <p>{data.shipping.shipping_name}</p>
            </div>
            <div className="flex py-2">
              <p className="basis-1/3">No. Resi</p>
              <p className="mx-2 w-fit">:</p>
              <p>{data.shipping.shipping_number}</p>
            </div>
            <div className="flex py-2">
              <p className="basis-1/3">Alamat</p>
              <p className="mx-2 w-fit">:</p>
              <p>{data.shipping.shipping_address}</p>
            </div>

            <hr className="my-8" />
            <p className="font-bold text-xl mb-4">Rincian Pembayaran</p>
            <div className="w-full">
              <div className="flex justify-between py-2">
                <p>Metode Pembayaran</p>
                <p>{data.payment.payment_method}</p>
              </div>
              <div className="flex justify-between py-2">
                <p>Subtotal untuk produk</p>
                <p>
                  {data.payment.subtotal.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex justify-between py-2">
                <p>Ongkos Kirim</p>
                <p>
                  {" "}
                  {data.payment.shipping_price.toLocaleString.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}
                </p>
              </div>
              <div className="flex justify-between py-2">
                <p>Diskon Ongkos Kirim</p>
                <p>
                  {" "}
                  {data.payment.discount_shipping.toLocaleString.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                    }
                  )}
                </p>
              </div>
              <div className="flex justify-between py-2">
                <p>PPN 11%</p>
                <p>
                  {" "}
                  {data.payment.ppn.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex justify-between py-2">
                <p className="font-bold">Total Pembayaran</p>
                <p className="font-bold text-amber-500">
                  {" "}
                  {data.payment.total.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default InvoiceDetail;
