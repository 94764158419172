import { useState, useEffect } from "react";
import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaRegTrashAlt,
} from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineFilterAlt, MdOutlineLocalPrintshop } from "react-icons/md";
import { RxCross1, RxFilePlus } from "react-icons/rx";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import PopoverDoctorList from "../PopoverDoctorList";
import {
  exportAnalysis,
  getInitialAnalysisList,
} from "../../../../services/patientCare";
import { useMutation } from "@tanstack/react-query";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import { BiTask } from "react-icons/bi";
import ModalDeleteAnalysis from "../patientCareInitialAnalysis/ModalDeleteAnalysis";

const INITIAL_REQ = {
  offset: 1,
  limit: 10,
  start_date: "",
  end_date: "",
  doctor_analysis: true,
};

const DoctorAnalysisList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [reqBody, setReqBody] = useState({ ...INITIAL_REQ, status: 1 });
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDoctor, setAnchorElDoctor] = useState(null);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [doctorArr, setDoctorArr] = useState([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [analysisArr, setAnalysisArr] = useState([]);

  const debounce = useDebounce(search, 500);
  const debounceDoctor = useDebounce(searchDoctor, 500);

  const resetReq = () => {
    setReqBody({ ...INITIAL_REQ, status: reqBody.status });
    setDoctorArr([]);
  };

  const refetchList = () => {
    setSearch("");
    resetReq();
    mutateList({
      search: "",
      ...INITIAL_REQ,
      // doctor_id: doctorArr.map((item) => item.id),
      status: reqBody.status,
      doctor_id: 0,
    });
  };

  const handleExport = () => {
    mutateXlsx({
      id: analysisArr,
      analysis_type: 2,
    });
  };

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "initial-analysis-list.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getInitialAnalysisList,
  });

  const { mutate: mutateXlsx, isLoading: isLoadingXlsx } = useMutation({
    mutationFn: exportAnalysis,
    onSuccess: (data) => handleXlsxData(data),
  });

  useEffect(() => {
    mutateList({
      search: debounce,
      ...reqBody,
      // doctor_id: doctorArr.map((item) => item.id),
      doctor_id: doctorArr.length === 0 ? 0 : doctorArr[0].id,
    });
  }, [debounce, doctorArr, reqBody]);

  return (
    <>
      <LoadingBackdrop isLoading={isLoadingXlsx} />
      <BreadcrumbPatientCare />

      <div className="flex items-end mb-4">
        <div className="flex font-bold text-center w-1/3">
          <p
            onClick={() =>
              setReqBody((prevValue) => ({
                ...prevValue,
                status: 1,
              }))
            }
            className={`w-full p-2 border-b-2 hover:text-gray-300 cursor-pointer ${
              reqBody.status === 1 ? "border-blue-500 text-blue-500" : ""
            }`}
          >
            Queue
          </p>
          <p
            onClick={() =>
              setReqBody((prevValue) => ({
                ...prevValue,
                status: 2,
              }))
            }
            className={`w-full p-2 border-b-2 hover:text-gray-300 cursor-pointer ${
              reqBody.status === 2 ? "border-blue-500 text-blue-500" : ""
            }`}
          >
            Completed
          </p>
        </div>
        <hr className="w-full text-gray-400 border-t-5 h-5" />
      </div>

      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <button
          className="rounded-md p-2 border-2 border-blue-500 text-blue-500 text-sm flex gap-2 justify-center items-center hover:bg-gray-300 disabled:bg-gray-300 disabled:text-white"
          disabled={isLoadingXlsx || analysisArr.length === 0}
          onClick={handleExport}
        >
          <RxFilePlus className="w-4 h-4" />
          Export
        </button>
        <div
          className="rounded-md p-2 flex justify-center items-center cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() =>
            navigate(`/patient-care/doctor-analysis/create-analysis`)
          }
        >
          <IoMdAddCircleOutline className="w-5 h-5" />
        </div>
      </div>
      <p className="text-gray-500 text-xs mb-4">
        You can search by check number, patient name, medical record number,
        doctor name and complaints.
      </p>

      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-2">
          <Checkbox
            disabled={
              !dataList ||
              isLoading ||
              (dataList && !isLoading && dataList.count === 0)
            }
            checked={
              dataList && dataList.count > 0
                ? analysisArr.length === dataList.data.length
                : false
            }
            onChange={(_, checked) =>
              setAnalysisArr(
                checked
                  ? dataList && dataList.data
                    ? dataList.data.map((item) => item.id)
                    : []
                  : []
              )
            }
          />
          <p>Select All</p>
        </div>
        {dataList && !isLoading ? <p>{dataList.count} data found</p> : null}
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center"></th>
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No Antrian
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Input
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nama Pasien
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nama Dokter
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Checkup
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nomor Rekam Medis
                </th>
                <th className="p-2 text-center whitespace-nowrap">Keluhan</th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>

                <th className="p-2 text-center w-[100px] sticky right-0 bg-blue-500">
                  Action
                </th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.count > 0 ? (
                  dataList.data.map((item, i) => (
                    <tr>
                      <td className="p-2 text-center border border-black">
                        <Checkbox
                          checked={analysisArr.includes(item.id)}
                          onChange={() => {
                            const index = analysisArr.findIndex(
                              (analysis) => analysis === item.id
                            );
                            setAnalysisArr(
                              index < 0
                                ? [...analysisArr, item.id]
                                : [
                                    ...analysisArr.slice(0, index),
                                    ...analysisArr.slice(index + 1),
                                  ]
                            );
                          }}
                        />
                      </td>
                      <td className="p-2 text-center border border-black">
                        {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.queue_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {moment(item.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.patient_name}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.doctor_name}
                      </td>
                      <td
                        className="p-2 text-center border border-black text-blue-500 hover:text-gray-500 cursor-pointer"
                        onClick={() => {
                          navigate(
                            `/patient-care/doctor-analysis/detail-analysis/${item.id}`
                          );
                        }}
                      >
                        {item.check_up_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.medical_number === "" ? "-" : item.medical_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.complaints}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.status === 1 ? "Open" : "Completed"}
                      </td>
                      <td className="p-2 text-center border-t border-b border-black w-[100px] sticky right-0 bg-[#F0F2F4]">
                        <div className="flex items-center justify-center gap-2">
                          <MdOutlineLocalPrintshop className="text-[16px] text-gray-500 cursor-pointer hover:text-gray-300" />
                          <FaRegTrashAlt
                            className={`text-[16px] text-red-500 cursor-pointer hover:text-gray-300`}
                            onClick={() => {
                              setOpenDelete(true);
                              setSelectedAnalysis(item);
                            }}
                          />
                          {item.status === 2 ? (
                            <FaEdit
                              className={`text-[16px] text-blue-500 cursor-pointer hover:text-gray-300`}
                              onClick={() => {
                                navigate(
                                  `/patient-care/doctor-analysis/edit-analysis/${item.id}`
                                );
                              }}
                            />
                          ) : (
                            <BiTask
                              className={`text-[16px] text-blue-500 cursor-pointer hover:text-gray-300`}
                              onClick={() => {
                                navigate(
                                  `/patient-care/doctor-analysis/edit-analysis/${item.id}`
                                );
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : isLoading ? (
                  <tr>
                    <td
                      colSpan={11}
                      className="text-center border border-black p-2"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={11}
                      className="text-center border border-black p-2"
                    >
                      Tidak ada analysis
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setAnalysisArr([]);
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setAnalysisArr([]);
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              onClick={resetReq}
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between"
            onClick={(e) => setAnchorElDoctor(e.currentTarget)}
          >
            <p>Doctor</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {doctorArr.length > 0 ? (
              doctorArr.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      // const index = doctorArr.findIndex((doc) => doc == item);
                      // setDoctorArr([
                      //   ...doctorArr.slice(0, index),
                      //   ...doctorArr.slice(index + 1),
                      // ]);
                      setDoctorArr([]);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No doctor selected</p>
            )}
          </div>
        </div>
      </Popover>

      <PopoverDoctorList
        anchorElDoctor={anchorElDoctor}
        setAnchorElDoctor={setAnchorElDoctor}
        searchDoctor={searchDoctor}
        setSearchDoctor={setSearchDoctor}
        doctorArr={doctorArr}
        setDoctorArr={setDoctorArr}
        debounceDoctor={debounceDoctor}
      />

      <ModalDeleteAnalysis
        open={openDelete}
        setOpen={setOpenDelete}
        analysis={selectedAnalysis}
        refetch={() => {
          refetchList();
        }}
        isDoctor={true}
      />
    </>
  );
};

export default DoctorAnalysisList;
