import { SIDEBAR_WIDTH } from "../../constants/constant";
import Header from "../../ui/Header";
import SidebarComponents from "../../ui/SidebarComponents";

const PageTemplate = ({ header, mainComponent }) => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-full lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          {header ? (
            <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
              {header}
            </h1>
          ) : null}
          {mainComponent}
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
