import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineFilterAlt } from "react-icons/md";
import {
  FaChevronDown,
  FaChevronLeft,
  FaEdit,
  FaRegTrashAlt,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
} from "react-icons/fa";
import { RxFilePlus } from "react-icons/rx";
import DatePicker from "react-datepicker";

import useDebounce from "../../../hooks/useDebounce";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import ModalAddPatient from "./ModalAddPatient";
import ModalDeletePatient from "./ModalDeletePatient";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import ModalDetailPatient from "./ModalDetailPatient";
import { useMutation } from "@tanstack/react-query";
import {
  exportPatientXlsx,
  getPatientList,
} from "../../../../services/patientCare";

const statusArr = [
  { id: 0, name: "Semua" },
  { id: 1, name: "Member" },
  { id: 2, name: "Non-Member" },
];

const INITIAL_REQ = {
  start_date: "",
  end_date: "",
  sort: 1,
  gender: 0,
  status: 0,
};

const PatientCarePatientList = () => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientArr, setPatientArr] = useState([]);

  const debounce = useDebounce(search, 500);

  const refetchList = () => {
    setReqBody(INITIAL_REQ);
    setOffset(1);
    setLimit(10);
    setSearch("");
    mutateList({
      search: "",
      offset: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      sort: 1,
      gender: 0,
      status: 0,
    });
  };

  const handleExport = () => {
    mutateXlsx({
      id: patientArr,
    });
  };

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "patient-list.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const {
    mutate: mutateList,
    data: dataPatient,
    isLoadingPatient: isLoadingPatient,
  } = useMutation({
    mutationFn: getPatientList,
  });

  const { mutate: mutateXlsx, isLoading: isLoadingXlsx } = useMutation({
    mutationFn: exportPatientXlsx,
    onSuccess: (data) => handleXlsxData(data),
  });

  useEffect(() => {
    mutateList({
      search: search,
      offset: offset,
      limit: limit,
      ...reqBody,
    });
  }, [reqBody, offset, limit, debounce]);

  useEffect(() => {
    if (!openAdd) setSelectedPatient(null);
  }, [openAdd]);

  useEffect(() => {
    if (!openDelete) setSelectedPatient(null);
  }, [openDelete]);

  return (
    <>
      <LoadingBackdrop isLoading={isLoadingXlsx} />
      <BreadcrumbPatientCare />
      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "Pilih Tanggal"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                " - " +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <button
          onClick={handleExport}
          disabled={isLoadingXlsx || patientArr.length === 0}
          className="rounded-md p-2 border-2 border-blue-500 text-blue-500 text-sm flex gap-2 justify-center items-center hover:bg-gray-300 disabled:border-none disabled:bg-gray-300 disabled:text-white"
        >
          <RxFilePlus className="w-4 h-4" />
          Export
        </button>
        <div
          className="rounded-md p-2 flex justify-center items-center cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() => {
            setOpenAdd(true);
          }}
        >
          <IoMdAddCircleOutline className="w-5 h-5" />
        </div>
      </div>
      <p className="text-gray-500 text-xs mb-4">
        You can search by service name or doctor name
      </p>

      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-2">
          <Checkbox
            disabled={
              !dataPatient ||
              isLoadingPatient ||
              (dataPatient && !isLoadingPatient && dataPatient.count === 0)
            }
            checked={
              dataPatient && dataPatient.count > 0
                ? patientArr.length === dataPatient.data.length
                : false
            }
            onChange={(_, checked) =>
              setPatientArr(
                checked
                  ? dataPatient && dataPatient.data
                    ? dataPatient.data.map((item) => item.id)
                    : []
                  : []
              )
            }
          />
          <p>Select All</p>
        </div>
        {dataPatient && !isLoadingPatient ? (
          <p>{dataPatient.count} data found</p>
        ) : isLoadingPatient ? (
          <CircularProgress size={20} />
        ) : (
          <p>No data found</p>
        )}
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse table-auto">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center whitespace-nowrap"></th>
                <th className="p-2 text-center whitespace-nowrap">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  <div className="flex gap-4 justify-between items-center">
                    <p>Nama Pasien</p>
                    <div
                      className="cursor-pointer hover:text-gray-300"
                      onClick={() =>
                        setReqBody((prevValue) => ({
                          ...prevValue,
                          sort: reqBody.sort === 1 ? 2 : 1,
                        }))
                      }
                    >
                      {reqBody.sort === 1 ? (
                        <FaSortAlphaDown />
                      ) : (
                        <FaSortAlphaDownAlt />
                      )}
                    </div>
                  </div>
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  No Telepon
                </th>
                <th className="p-2 text-center whitespace-nowrap">Email</th>
                <th className="p-2 text-center whitespace-nowrap w-full">
                  Alamat
                </th>
                <th className="p-2 text-center whitespace-nowrap">NIK</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Lahir
                </th>
                <th className="p-2 text-center whitespace-nowrap">Umur</th>
                <th className="p-2 text-center whitespace-nowrap">Gender</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nomor Rekam Medis
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Created At
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Member Status
                </th>
                <th className="p-2 text-center w-[100px] sticky right-0 bg-blue-500">
                  Action
                </th>
              </thead>
              <tbody>
                {dataPatient && !isLoadingPatient && dataPatient.count > 0 ? (
                  dataPatient.data.map((item, i) => (
                    <tr>
                      <td className="p-2 text-center border border-black">
                        <Checkbox
                          checked={patientArr.includes(item.id)}
                          onChange={() => {
                            const index = patientArr.findIndex(
                              (patient) => patient === item.id
                            );
                            setPatientArr(
                              index < 0
                                ? [...patientArr, item.id]
                                : [
                                    ...patientArr.slice(0, index),
                                    ...patientArr.slice(index + 1),
                                  ]
                            );
                          }}
                        />
                      </td>
                      <td className="p-2 text-center border border-black">
                        {(offset - 1) * limit + (i + 1)}
                      </td>
                      <td
                        className="p-2 text-center border border-black text-blue-500 cursor-pointer hover:text-gray-300"
                        onClick={() => {
                          setSelectedPatient(item);
                          setOpenDetail(true);
                        }}
                      >
                        {item.patient_name}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.phone_number === "" ? "-" : item.phone_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.email === "" ? "-" : item.email}
                      </td>
                      <td className="p-2 text-center border border-black min-w-[300px] whitespace-normal">
                        {item.address}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.nik}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.date_of_birth
                          ? moment(item.date_of_birth).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.age}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.gender === 1 ? "Laki-laki" : "Perempuan"}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.medical_number}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.created_at
                          ? moment(item.created_at).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td className="p-2 text-center border border-black">
                        {item.member ? "Member" : "Non-member"}
                      </td>
                      <td className="p-2 text-center border-t border-b border-black w-[100px] sticky right-0 bg-[#F0F2F4]">
                        <div className="flex items-center justify-center gap-2">
                          <FaRegTrashAlt
                            className="text-[16px] text-red-500 cursor-pointer hover:text-gray-300"
                            onClick={() => {
                              setOpenDelete(true);
                              setSelectedPatient(item);
                            }}
                          />
                          <FaEdit
                            className="text-[16px] text-blue-500 cursor-pointer hover:text-gray-300"
                            onClick={() => {
                              setOpenAdd(true);
                              setSelectedPatient(item);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : isLoadingPatient ? (
                  <tr>
                    <td
                      colSpan={14}
                      className="p-2 border border-black text-center"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={14}
                      className="p-2 border border-black text-center"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataPatient && !isLoadingPatient && dataPatient.count > 0 ? (
        <PagePagination
          offset={offset}
          setOffset={(e) => {
            setOffset(e);
          }}
          limit={limit}
          setLimit={(e) => {
            setLimit(e);
          }}
          total={dataPatient.count}
        />
      ) : null}

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Status</p>
          </div>
        </div>
        <hr />

        {statusArr.map((item) => (
          <div
            className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 last:border-b-0 ${
              reqBody.status === item.id ? "bg-gray-200" : ""
            }`}
            key={item.id}
            onClick={() =>
              setReqBody((prevValue) => ({ ...prevValue, status: item.id }))
            }
          >
            {item.name}
          </div>
        ))}
      </Popover>

      <Popover
        open={Boolean(anchorElCal)}
        anchorEl={anchorElCal}
        onClose={() => setAnchorElCal(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="p-2">
          <DatePicker
            onChange={(dates) => {
              const [start, end] = dates;
              setReqBody((prevValue) => ({
                ...prevValue,
                start_date: start ? moment(start).format("YYYY-MM-DD") : "",
                end_date: end ? moment(end).format("YYYY-MM-DD") : "",
              }));
              if (end) setAnchorElCal(null);
            }}
            startDate={
              reqBody.start_date === "" ? null : new Date(reqBody.start_date)
            }
            endDate={
              reqBody.end_date === "" ? null : new Date(reqBody.end_date)
            }
            selectsRange={true}
            inline
          />
          <button
            className="bg-blue-500 text-white w-full rounded-md p-1 hover:bg-blue-700"
            onClick={() =>
              setReqBody((prevValue) => ({
                ...prevValue,
                start_date: "",
                end_date: "",
              }))
            }
          >
            Reset
          </button>
        </div>
      </Popover>
      <ModalAddPatient
        open={openAdd}
        setOpen={setOpenAdd}
        patient={selectedPatient}
        setPatientCarePatient={setSelectedPatient}
        refetch={() => {
          refetchList();
        }}
      />
      <ModalDetailPatient
        open={openDetail}
        setOpen={setOpenDetail}
        patient={selectedPatient}
        setPatient={setSelectedPatient}
      />
      <ModalDeletePatient
        open={openDelete}
        setOpen={setOpenDelete}
        patient={selectedPatient}
        refetch={() => {
          refetchList();
        }}
      />
    </>
  );
};

export default PatientCarePatientList;
