import { CircularProgress } from "@mui/material";
import { AiOutlineCheck } from "react-icons/ai";

const PaymentMethodCheckout = (props) => {
  const {
    paymentChannel,
    isPaymentChannelLoading,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    paymentConfirmed,
    setPaymentConfirmed,
  } = props;

  return (
    <>
      {isPaymentChannelLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {paymentChannel && paymentChannel !== undefined ? (
            <>
            { /* paymentChannel.ewallet !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via E-Wallet</p>
                  {paymentChannel.ewallet.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          paymentCode: pay.pg_code.toString(),
                          paymentId: [pay.id],
                          paymentImg: pay.logo,
                          paymentBank: pay.pg_bank,
                          paymentBankFee: pay.bank_fee,
                          paymentTypeFee: pay.type_bank_fee,
                          isConfirmed: true,
                        });
                        setPaymentConfirmed({
                          ...paymentConfirmed,
                          fee_services: pay.bank_fee,
                          type_subs: pay.type_bank_fee,
                          payment: {
                            pg_name: pay.pg_name,
                            pg_bank: pay.pg_bank,
                            pg_code: pay.pg_code,
                            pg_type: pay.pg_type,
                          },
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedPaymentMethod.paymentId.includes(pay.id) ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  )) }
                </>
              ) */}
              {/* paymentChannel.credit !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via Kartu Kredit</p>
                  {paymentChannel.credit.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          paymentCode: pay.pg_code.toString(),
                          paymentId: [pay.id],
                          paymentImg: pay.logo,
                          paymentBank: pay.pg_bank,
                          paymentBankFee: pay.bank_fee,
                          paymentTypeFee: pay.type_bank_fee,
                          isConfirmed: true,
                        });
                        setPaymentConfirmed({
                          ...paymentConfirmed,
                          fee_services: pay.bank_fee,
                          type_subs: pay.type_bank_fee,
                          payment: {
                            pg_name: pay.pg_name,
                            pg_bank: pay.pg_bank,
                            pg_code: pay.pg_code,
                            pg_type: pay.pg_type,
                          },
                        });
 
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedPaymentMethod.paymentId.includes(pay.id) ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              ) */}
              {paymentChannel.emoney !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via E-Money</p>
                  {paymentChannel.emoney.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          paymentCode: pay.pg_code.toString(),
                          paymentId: [pay.id],
                          paymentImg: pay.logo,
                          paymentBank: pay.pg_bank,
                          paymentBankFee: pay.bank_fee,
                          paymentTypeFee: pay.type_bank_fee,
                          isConfirmed: true,
                        });
                        setPaymentConfirmed({
                          ...paymentConfirmed,
                          fee_services: pay.bank_fee,
                          type_subs: pay.type_bank_fee,
                          payment: {
                            pg_name: pay.pg_name,
                            pg_bank: pay.pg_bank,
                            pg_code: pay.pg_code,
                            pg_type: pay.pg_type,
                          },
                        });
 
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedPaymentMethod.paymentId.includes(pay.id) ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentChannel.retail !== null && (
                <>
                  <p className="font-bold my-3">Pembayaran via Retail</p>
                  {paymentChannel.retail.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          paymentCode: pay.pg_code.toString(),
                          paymentId: [pay.id],
                          paymentImg: pay.logo,
                          paymentBank: pay.pg_bank,
                          paymentBankFee: pay.bank_fee,
                          paymentTypeFee: pay.type_bank_fee,
                          isConfirmed: true,
                        });
                        setPaymentConfirmed({
                          ...paymentConfirmed,
                          fee_services: pay.bank_fee,
                          type_subs: pay.type_bank_fee,
                          payment: {
                            pg_name: pay.pg_name,
                            pg_bank: pay.pg_bank,
                            pg_code: pay.pg_code,
                            pg_type: pay.pg_type,
                          },
                        });
 
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedPaymentMethod.paymentId.includes(pay.id) ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {paymentChannel.virtual_accounts !== null && (
                <>
                  <p className="font-bold my-3">
                    Pembayaran via Virtual Account
                  </p>
                  {paymentChannel.virtual_accounts.map((pay, index) => (
                    <div
                      className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 font-bold"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          paymentCode: pay.pg_code.toString(),
                          paymentId: [pay.id],
                          paymentImg: pay.logo,
                          paymentBank: pay.pg_bank,
                          paymentBankFee: pay.bank_fee,
                          paymentTypeFee: pay.type_bank_fee,
                          isConfirmed: true,
                        });
                        setPaymentConfirmed({
                          ...paymentConfirmed,
                          fee_services: pay.bank_fee,
                          type_subs: pay.type_bank_fee,
                          payment: {
                            pg_name: pay.pg_name,
                            pg_bank: pay.pg_bank,
                            pg_code: pay.pg_code,
                            pg_type: pay.pg_type,
                          },
                        });
                      }}
                    >
                      <div className="flex w-[10%] mr-3">
                        <img src={pay.logo} />
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p>{pay.pg_bank}</p>
                        {selectedPaymentMethod.paymentId.includes(pay.id) ? (
                          <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <p>kosong</p>
          )}
        </>
      )}
    </>
  );
};

export default PaymentMethodCheckout;
