import { useState, useEffect, useMemo, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import moment from "moment";
import { MdAccessTimeFilled } from "react-icons/md";

import {
  getDetailPayment,
  getHistoryTransaction,
} from "../../../services/authApi";
import { PaymentContext } from "../../context/PaymentStatusProvider";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD-MMM-YYYY hh:mm");

const SubscriptionHistory = () => {
  const navigate = useNavigate();
  const {
    billNumber,
    setBillNumber,
    invoiceType,
    setInvoiceType,
    setTransaction,
  } = useContext(PaymentContext);

  const [offset, setOffset] = useState(1);
  const { data: historyTransaction } = useQuery({
    queryKey: ["history", offset],
    queryFn: () => getHistoryTransaction(offset, FETCH_LIMIT),
    keepPreviousData: true,
  });

  const { data: paymentDetail } = useQuery({
    queryKey: ["payment-detail", billNumber, invoiceType],
    queryFn: () => getDetailPayment(billNumber, invoiceType),
    enabled: !!billNumber && invoiceType !== 0,
    onSuccess: () => {
      if (invoiceType === 1) navigate("/subscription/purchase");
    },
  });

  const pageCount = useMemo(() => {
    if (!historyTransaction) return;
    return Math.ceil(historyTransaction.total_data / FETCH_LIMIT);
  }, [historyTransaction]);

  useEffect(() => {
    if (historyTransaction === undefined || null) return;
    if (historyTransaction.detail[0]?.status_id === 1) sessionStorage.clear();
  }, [sessionStorage]);

  useEffect(() => {
    setTransaction(
      paymentDetail && paymentDetail !== undefined ? paymentDetail : undefined
    );
  }, [paymentDetail, billNumber, invoiceType]);

  return (
    <div>
      <p className="font-bold text-[20px]">Riwayat Langganan</p>
      <div className="border border-black"></div>
      {historyTransaction && historyTransaction !== undefined ? (
        <>
          {historyTransaction.detail && historyTransaction.detail !== undefined
            ? historyTransaction.detail.map((transaction, index) => (
                <div
                  className={`w-full mt-3 p-5 rounded-lg flex justify-between items-center ${
                    transaction.status_id === 1
                      ? "border-2 border-[#F21B3F]"
                      : "border border-black"
                  } `}
                  key={index}
                >
                  <div className="flex">
                    <div className="flex flex-col">
                      <p>Tanggal Transaksi</p>
                      <p>ID Tagihan</p>
                      <p>Total Tagihan</p>
                      {transaction.bayar_sebelum ? (
                        <p>Bayar Sebelum</p>
                      ) : (
                        <p>Tanggal Pembayaran</p>
                      )}
                    </div>

                    <div className="flex flex-col ml-3">
                      <p>
                        :&nbsp;&nbsp;&nbsp;
                        {toDate(transaction.tanggal_transaksi)}
                      </p>
                      <p className="text-[#007AF1]">
                        :&nbsp;&nbsp;&nbsp;{transaction.nomor_tagihan}
                      </p>
                      <div className="flex">
                        <p>
                          :&nbsp;&nbsp;&nbsp;
                          {transaction.total_tagihan.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                        {transaction.status_id === 1 ? (
                          <p className="text-[#F21B3F] ml-2">Belum Dibayar</p>
                        ) : (
                          <p className="text-[#007AF1] ml-2">Sudah dibayar</p>
                        )}
                      </div>
                      {transaction.bayar_sebelum ? (
                        <div className="bg-[#FF8D40] text-white p-1 rounded-md font-bold flex items-center">
                          <MdAccessTimeFilled className="mr-2 text-[15px]" />
                          <p>{toDate(transaction.bayar_sebelum)}</p>
                        </div>
                      ) : (
                        <>
                          {transaction.tanggal_pembayaran ? (
                            <p>
                              :&nbsp;&nbsp;&nbsp;
                              {transaction.tanggal_pembayaran}
                            </p>
                          ) : (
                            <p>:&nbsp;&nbsp;&nbsp;kosong</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {transaction.status_id === 1 ? (
                    <p
                      className="font-bold text-[#007AF1] hover:cursor-pointer"
                      onClick={() => {
                        setBillNumber(transaction.nomor_tagihan);
                        setInvoiceType(1);
                      }}
                    >
                      Bayar Sekarang
                    </p>
                  ) : (
                    <p
                      className="font-bold text-[#007AF1] hover:cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/subscription/invoice/${
                            transaction.nomor_tagihan
                          }/${2}`
                        );
                      }}
                    >
                      Lihat Invoice
                    </p>
                  )}
                </div>
              ))
            : null}
        </>
      ) : null}
      <div className="flex justify-center items-center mt-3">
        <Pagination
          count={pageCount}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default SubscriptionHistory;
