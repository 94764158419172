import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { InputAdornment, TextField } from "@mui/material";
import * as yup from "yup";

import InvoiceBreadcrumb from "./InvoiceBreadcrumb";
import { setNewCustomer } from "../../../services/invoiceApi";

const validationSchema = yup.object({
  partner_name: yup.string().required("Nama customer harus diisi"),
  address: yup.string().required("Alamat customer harus diisi"),
  npwp: yup.string(),
  phone: yup.string().required("No. telepon customer harus diisi"),
  pic: yup.string().required("PIC customer harus diisi"),
  discount: yup.number(),
  sia: yup.string(),
  sipa: yup.string(),
  warehouse: yup.boolean(),
});

const CreateCustomer = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateNewCustomer } = useMutation({
    mutationFn: setNewCustomer,
    onSuccess: () => {
      navigate(`${isWarehouse ? "/central-warehouse" : ""}/invoice/customer`);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const formik = useFormik({
    initialValues: {
      partner_name: "",
      address: "",
      npwp: "",
      phone: "",
      pic: "",
      discount: 0,
      sia: "",
      sipa: "",
      warehouse: isWarehouse ? isWarehouse : false,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewCustomer(values);
    },
  });

  return (
    <>
      <InvoiceBreadcrumb />
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-3 items-center my-4">
          <div className="w-full">
            <p className="font-bold mb-2">
              Nama Customer <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={
                  formik.touched.partner_name && formik.errors.partner_name
                }
                value={formik.values.partner_name}
                onChange={(e) => {
                  if (e.target.value.length <= 50)
                    formik.setFieldValue("partner_name", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <p className="font-bold mb-2">
              PIC <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={formik.touched.pic && formik.errors.pic}
                value={formik.values.pic}
                onChange={(e) => {
                  if (e.target.value.length <= 50)
                    formik.setFieldValue("pic", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3 items-center mb-4">
          <div className="w-full">
            <p className="font-bold mb-2">NPWP</p>
            <div className="w-full">
              <TextField
                fullWidth
                value={formik.values.npwp}
                onChange={(e) => {
                  if (
                    e.target.value.length <= 20 &&
                    !isNaN(Number(e.target.value))
                  ) {
                    formik.setFieldValue("npwp", e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <p className="font-bold mb-2">Diskon</p>
            <div className="w-full">
              <TextField
                fullWidth
                value={formik.values.discount}
                onChange={(e) =>
                  formik.setFieldValue(
                    "discount",
                    !isNaN(Number(e.target.value)) &&
                      Number(e.target.value) <= 100
                      ? Number(e.target.value)
                      : 0
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3 items-center mb-4">
          <div className="w-full">
            <p className="font-bold mb-2">
              No. Telepon <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={formik.touched.phone && formik.errors.phone}
                value={formik.values.phone}
                onChange={(e) => {
                  if (
                    e.target.value.length <= 30 &&
                    !isNaN(Number(e.target.value))
                  )
                    formik.setFieldValue("phone", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <p className="font-bold mb-2">
              Alamat <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={formik.touched.address && formik.errors.address}
                value={formik.values.address}
                onChange={(e) => {
                  if (e.target.value.length <= 100)
                    formik.setFieldValue("address", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/*
<div className="flex gap-3 items-center mb-4">
          <div className="w-full">
            <p className="font-bold mb-2">
              Nama Apoteker <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={formik.touched.sia && formik.errors.sia}
                value={formik.values.sia}
                onChange={(e) => {
                  if (e.target.value.length <= 200)
                    formik.setFieldValue("sia", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <p className="font-bold mb-2">
              Nomor SIPA <span className="text-red-500"> *</span>
            </p>
            <div className="w-full">
              <TextField
                fullWidth
                helperText={formik.touched.sipa && formik.errors.sipa}
                value={formik.values.sipa}
                onChange={(e) => {
                  if (
                    e.target.value.length <= 200 &&
                    !isNaN(Number(e.target.value))
                  )
                    formik.setFieldValue("sipa", e.target.value);
                }}
              />
            </div>
          </div>
        </div>

          */}
        <p className="text-red-500 text-center my-4">{errorMessage}</p>
        <div className="w-full flex justify-end">
          <div className="w-1/2 flex gap-2 pl-3">
            <button
              className="w-full border-2 hover:bg-slate-100 rounded-md p-2"
              onClick={() =>
                navigate(
                  `${isWarehouse ? "/central-warehouse" : ""}/invoice/customer`
                )
              }
            >
              Cancel
            </button>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white rounded-md p-2 disabled:bg-gray-300"
              type="submit"
              disabled={!formik.isValid}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateCustomer;
