import React, { useRef, useEffect, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import ReactToPrint from "react-to-print";
import moment from "moment";

import { fetchReturnExpiredProduct } from "../../../../services/InventoryAPI";
import { ExpiredListContext } from "../../../context/ProductExpiredProvider";

const PrintRetur = () => {
  const componentRef = useRef();
  const { printRetur } = useContext(ExpiredListContext);

  const { mutate: mutateFetchRetur, data: returData } = useMutation({
    mutationFn: fetchReturnExpiredProduct,
  });

  useEffect(() => {
    mutateFetchRetur(printRetur);
  }, [printRetur]);

  return (
    <div className="container mx-auto mt-16 mb-5 lg:mt-0">
      {returData && returData !== undefined ? (
        <>
          <div className="flex justify-between mt-3">
            <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
              Surat Retur
            </h1>
            <ReactToPrint
              trigger={() => (
                <button className="p-2 lg:p-[12px] bg-blue-500 mb-3 px-4 lg:px-5 font-medium text-sm lg:text-base text-white rounded-xl hover:bg-blue-300">
                  Download
                </button>
              )}
              content={() => componentRef.current}
              documentTitle={`OLIN_Return_${moment().format("DD-MMM-YYYY HH:mm:ss")}`}
            />
          </div>

          <div className="p-5 border text-xs">
            <div className="p-5" ref={componentRef}>
              <h2 className="font-bold text-[10px] lg:text-[20px]">
                {returData.nama_apotek}
              </h2>
              <p>{returData.alamat_apotek}</p>
              <p>{returData.no_telp_apotek}</p>

              {/* body surat */}
              <div className="mt-2 text-xs">
                <p className="text-center font-medium text-[15px] lg:text-[20px]">
                  {returData.nama_surat}
                </p>
                <p className="text-center mt-1">{returData.no_surat_return}</p>
                <hr />
              </div>

              {/* Isi surat */}
              <div className="flex flex-col mt-3 gap-1 text-xs">
                <p className="my-2">Yang bertanda tangan dibawah ini</p>
                <div className="flex flex-row mt-2">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Nama
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{returData.nama}</p>
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Jabatan
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{returData.jabatan}</p>
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    No SIPA
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{returData.no_sipa}</p>
                  <br />
                </div>
              </div>
              {returData.nama_surat?.includes("NARKOTIKA") ||
              returData.nama_surat?.includes("PSIKOTROPIKA") ? (
                <div className="flex flex-col mt-3 gap-1 text-xs">
                  <p className="my-2">
                    {returData.nama_surat?.includes("NARKOTIKA")
                      ? "Mengajukan pesanan Narkotika kepada :"
                      : null}
                    {returData.nama_surat?.includes("PSIKOTROPIKA")
                      ? "Mengajukan pesanan Psikotropika kepada :"
                      : null}
                  </p>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      Nama Distributor
                    </span>
                    <p className="mx-4">:&nbsp;&nbsp;{returData.nama}</p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      Alamat
                    </span>
                    <p className="mx-4">:</p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      No Telp
                    </span>
                    <p className="mx-4">:</p>
                    <br />
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col gap-1">
                <p className="mt-3">
                  Mengajukan Pengembalian Produk expired kepada:{" "}
                </p>
                <div className="flex flex-row mt-2">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Distribusi&nbsp;&nbsp;:
                  </span>
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Alamat&nbsp;&nbsp;:
                  </span>
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    No. Telp&nbsp;&nbsp;:
                  </span>
                  <br />
                </div>
              </div>

              <p className="mt-3">Dengan data terlampir sebagai berikut: </p>

              <div className="px-0 lg:px-5 text-xs">
                {/* table surat */}
                {returData.nama_surat?.includes("NARKOTIKA") ||
                returData.nama_surat?.includes("PSIKOTROPIKA") ? (
                  <div className="overflow-x-auto p-4">
                    <table className="table w-full border">
                      <thead>
                        <tr className="bg-blue-500 text-white text-center">
                          <th className="border-collapse border border-black">
                            No
                          </th>
                          <th className="border-collapse border border-black">
                            Nama obat
                          </th>
                          <th className="border-collapse border border-black">
                            Satuan
                          </th>
                          <th className="border-collapse border border-black">
                            Qty
                          </th>
                          <th className="border-collapse border border-black">
                            No. Order
                          </th>
                        </tr>
                      </thead>

                      {returData.product?.map((item, index) => {
                        return (
                          <tbody key={index} className="text-center">
                            <tr>
                              <th className="border-collapse border border-black">
                                {index + 1}
                              </th>
                              <td className="border-collapse border border-black">
                                {item.nama_obat}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.satuan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.quantity}
                              </td>
                              <td className="border-collapse border border-black"></td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className="overflow-x-auto p-4">
                    <table className="table w-full">
                      <thead>
                        <tr className="bg-blue-500 text-white text-center">
                          <th className="border-collapse border border-black">
                            No
                          </th>
                          <th className="border-collapse border border-black">
                            Nama Obat
                          </th>
                          <th className="border-collapse border border-black">
                            Bentuk Sediaan
                          </th>
                          <th className="border-collapse border border-black">
                            Satuan
                          </th>
                          <th className="border-collapse border border-black">
                            Qty
                          </th>
                          <th className="border-collapse border border-black">
                            No. Order
                          </th>
                        </tr>
                      </thead>
                      {returData.product?.map((item, index) => {
                        return (
                          <tbody key={index} className="text-center">
                            <tr>
                              <th className="border-collapse border border-black">
                                {index + 1}
                              </th>
                              <td className="border-collapse border border-black">
                                {item.nama_obat}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.bentuk_sediaan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.satuan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.quantity}
                              </td>
                              <td className="border-collapse border border-black"></td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                )}
                {/* penutup */}
                {/* tanda tangan */}
                <div className="flex flex-col items-end">
                  <div className="flex mt-5 flex-col justify-end text-center px-5 items-center">
                    <div className="p-4">
                      <p>{returData.tanggal_pengajuan}</p>
                      <p>Diserahkan Oleh: </p>
                    </div>
                    <div className="mt-5">
                      <p>{returData.nama}</p>
                      <p>{returData.no_sipa}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>tidak ditemukan!</p>
      )}
    </div>
  );
};

export default PrintRetur;
