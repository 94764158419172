import { Box, Modal } from "@mui/material";
import { BsTrash } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import ModalConfirmationAction from "../../common/particles.jsx/ModalConfirmationAction";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { deleteCartWarehouse } from "../../../services/centralWarehouseApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeleteProductWarehouse = ({
  open,
  setOpen,
  refetch,
  product,
  type,
}) => {
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClose = () => {
    setOpen(null);
  };

  const { mutate: mutateDeleteCart, isLoading } = useMutation({
    mutationFn: deleteCartWarehouse,
    onSuccess: () => {
      setOpenSuccess(true);
    },
  });

  useEffect(() => {
    if (!openSuccess) {
      refetch();
      setOpen(null);
    }
  }, [openSuccess]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "50vh",
            padding: 0,
          }}
          className="text-left text-base w-3/4 lg:w-1/3"
        >
          <div className="flex justify-between items-center px-8 py-4">
            <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
              <div className="w-full h-full bg-red-300 p-2 rounded-full">
                <BsTrash className="w-full h-full text-red-600" />
              </div>
            </div>
            <RxCross1
              className="cursor-pointer hover:text-red-500"
              onClick={handleClose}
            />
          </div>

          <div className="px-8 mb-4">
            <p className="font-bold text-lg ">Delete product</p>
            <p>Do you want to delete the product</p>
          </div>

          <div className="px-8 mb-4 flex gap-2 items-center">
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-red-500 bg-red-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-none"
              onClick={() =>
                mutateDeleteCart({
                  type: type,
                  id: product,
                })
              }
              disabled={isLoading}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>

      <ModalConfirmationAction
        open={openSuccess}
        setOpen={setOpenSuccess}
        message={"Product successfully deleted"}
      />
    </>
  );
};

export default ModalDeleteProductWarehouse;
