import { useState, useEffect, useContext, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BsFillFileEarmarkTextFill, BsCreditCard2Back } from "react-icons/bs";
import { FaTruckMoving } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import Delivery from "../../../../../assets/icons/delivery.png";
import {
  fetchCheckoutCart,
  getPaymentMethod,
  getShippingMethod,
  getVoucher,
  OtpCheckout,
  verifyCheckoutOtp,
  getCountCart,
} from "../../../../../services/purchaseApi";
import useDebounce from "../../../../hooks/useDebounce";
import OtpConfirmationPurchase from "../OtpConfirmationPurchase";
import SendOtpModal from "../../../../common/particles.jsx/SendOtpModal";
import { VoucherProductContext } from "../../../../context/VoucherProductProvider";
import PurchasePaymentMethod from "./PurchasePaymentMethod";
import ShippingMethod from "./ShippingMethod";
import VoucherPurchaseDetail from "./VoucherPurchaseDetail";
import VoucherPurchaseCheckout from "./VoucherPurchaseCheckout";

const FETCH_LIMIT = 30;

const SEND_VERIFICATION_RETRY_DELAY_SECONDS = 60;

const CheckoutPurchase = () => {
  const navigate = useNavigate();
  const { selectedVoucher, setSelectedVoucher } = useContext(
    VoucherProductContext
  );

  const [selectedMethod, setSelectedMethod] = useState({
    payment_id: null,
    code: "",
    payment_method: "",
    shop: null,
    shipping_id: null,
    total_discount: 0,
    voucher_id: [],
    shipping_price: 0,
    total: 0,
    ppn: 0,
    fee: 0,
    data: [],
    is_inventory: false,
    note: "",
    use_point: false,
  });

  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState(false);
  const [isShippingMethodOpen, setIsShippingMethodOpen] = useState(false);
  const [isOpenAddVoucher, setIsOpenAddVoucher] = useState(false);
  const [isDetailVoucherOpen, setIsDetailVoucherOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voucherId, setVoucherId] = useState(null);
  const [search, setSearchVoucher] = useState("");
  const [offset, setOffset] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [isOtpConfirmed, setIsOtpConfirmed] = useState(
    sessionStorage.getItem("isOtpConfirmed")
  );
  const [isPressed, setIsPressed] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(
    SEND_VERIFICATION_RETRY_DELAY_SECONDS
  );

  const [payment, setPayment] = useState({
    logo: "",
    name: "",
    fee: 0,
  });

  const [shipping, setShipping] = useState({
    name: "",
    price: 0,
    estimation: "",
  });

  const debounce = useDebounce(search, 500);

  const { data: countCart } = useQuery({
    queryFn: () => getCountCart(2),
    queryKey: ["count-cart"],
  });

  const {
    mutate: mutateCheckoutCart,
    data: checkoutCartData,
    isLoading: isCheckoutCartDataLoading,
  } = useMutation({
    mutationFn: fetchCheckoutCart,
    onSuccess: (data) => {
      setSelectedMethod({
        payment_id: data.payment?.id,
        code: "",
        payment_method: data.payment?.pg_name,
        shop: data.result?.shop,
        shipping_id: data.shipping?.id,
        total_discount: data.result?.discount,
        ppn: tax,
        fee: data.payment?.bank_fee,
        data: selectedMethod.data
          .concat(data.cart?.cart_regular, data.cart?.cart_so)
          .filter((data) => data !== null)
          .map((item) => ({
            id: item.product_id,
            quantity: item.quantity,
          })),
        // voucher_id: checkoutCartData.result?.voucher?.voucher_id,
        // voucher_id: unuseableVoucher,
        shipping_fee: data.shipping?.price_shipping,
        total: data.result?.total,
        note: "",
        is_inventory: false,
        use_point: false,
      });
      setPayment({
        logo: data.payment?.logo,
        name: data.payment?.pg_bank,
        fee: data.payment?.bank_fee,
      });
      setShipping({
        name: data.shipping?.name_shipping,
        price: data.shipping?.price_shipping,
        estimation: data.shipping?.estimation_shipping,
      });
    },
  });

  const { data: voucherPurchaseData } = useQuery({
    queryFn: () => getVoucher(debounce, FETCH_LIMIT, offset),
    queryKey: ["voucher-purchase", debounce],
  });

  const { data: paymentMethodData, isLoading: isPaymentMethodLoading } =
    useQuery({
      queryKey: ["checkout-payment"],
      queryFn: () => getPaymentMethod(),
      enabled: isPaymentMethodOpen === true,
    });

  const { data: shippingMethodData, isLoading: isShippingMethodLoading } =
    useQuery({
      queryKey: ["checkout-shipping"],
      queryFn: () => getShippingMethod(),
      enabled: isShippingMethodOpen === true,
    });

  const { mutate: mutateOtpCheckout } = useMutation({
    mutationFn: OtpCheckout,
    onSuccess: (data) => {
      setIsOtpConfirmed(true);
      sessionStorage.setItem("isOtpConfirmed", true);
      setIsPressed(true);
      setSuccessMessage(data.message.id);
      setIsModalOpen(false);
    },
  });

  const { mutate: mutateOtpCheckoutVerify, isLoading: isOtpVerifyLoading } =
    useMutation({
      mutationFn: verifyCheckoutOtp,
      onSuccess: (data) => {
        navigate(`/purchase/payment/${data.invoice}`);
        sessionStorage.clear();
      },
    });

  const tax = useMemo(() => {
    if (checkoutCartData && checkoutCartData !== undefined) {
      let priceMinusDiscount =
        checkoutCartData.result?.total +
        shipping.price -
        selectedMethod.total_discount;
      let tax = priceMinusDiscount * 0.11;
      return tax;
    }

    return 0;
  }, [checkoutCartData, shipping, selectedMethod]);

  const totalPrice = useMemo(() => {
    let finalPrice = 0;
    if (checkoutCartData && checkoutCartData !== undefined) {
      let priceMinusDiscount =
        checkoutCartData.result?.total +
        shipping.price -
        selectedMethod.total_discount;
      let tax = priceMinusDiscount * 0.11;
      finalPrice = priceMinusDiscount + tax + payment.fee;
      if (selectedMethod.use_point) {
        return finalPrice - checkoutCartData.loyalty_point;
      }
      return finalPrice;
    }
    return 0;
  }, [checkoutCartData, selectedMethod, shipping, payment]);

  const totalDiscount = useMemo(() => {
    return selectedVoucher.voucherPrice.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }, [selectedVoucher.voucherPrice]);

  const dataPrincipalId =
    checkoutCartData && checkoutCartData !== undefined
      ? checkoutCartData.cart_regular?.map((data) => data.principal_id)
      : [];

  const dataProductId =
    checkoutCartData && checkoutCartData !== undefined
      ? checkoutCartData.cart_regular?.map((data) => data.product_id)
      : [];

  const filteredVoucher = useMemo(() => {
    if (voucherPurchaseData && voucherPurchaseData !== undefined)
      return voucherPurchaseData
        .filter(
          (data) =>
            (data.voucher_value_detail?.voucher_min > countCart?.total ||
              data.voucher_product?.some((id) =>
                dataPrincipalId?.includes(id)
              ) === false ||
              data.voucher_product?.some((id) =>
                dataProductId?.includes(id)
              ) === false ||
              !data.voucher_payment?.includes(selectedMethod.payment_id)) &&
            checkoutCartData?.result?.voucher?.voucher_id?.includes(
              data.voucher_id
            )
        )
        .map((list) => list.voucher_id);
    else return [];
  }, [voucherPurchaseData, countCart, selectedMethod, checkoutCartData]);

  const unuseableVoucher = useMemo(() => {
    if (voucherPurchaseData && voucherPurchaseData !== undefined)
      return voucherPurchaseData.filter(
        (data) =>
          data.voucher_value_detail?.voucher_min > countCart?.total ||
          data.voucher_product?.some((id) => dataPrincipalId?.includes(id)) ===
            false ||
          data.voucher_product?.some((id) => dataProductId?.includes(id)) ===
            false ||
          !data.voucher_payment?.includes(selectedMethod.payment_id)
      );
    else return [];
  }, [voucherPurchaseData, countCart, selectedMethod, checkoutCartData]);

  useEffect(() => {
    const selectedVoucher = sessionStorage.getItem("selectedVoucher");
    mutateCheckoutCart(
      selectedVoucher.split(",").map((item) => {
        return parseInt(item, 10);
      })
    );
  }, [sessionStorage, selectedVoucher]);

  useEffect(() => {
    if (!isPressed) return;

    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setIsPressed(false);
          setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
          return prevCount;
        }
        return prevCount - 1;
      });

      return () => clearInterval(interval);
    }, 1000);
  }, [isPressed]);

  useEffect(() => {
    if (sessionStorage.getItem("isOtpConfirmed") === "true")
      setIsOtpConfirmed(true);
  }, [sessionStorage]);

  return (
    <div className="container mt-5 lg:mt-0">
      {isCheckoutCartDataLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          {isOtpConfirmed ? (
            <OtpConfirmationPurchase
              checkoutCartData={checkoutCartData}
              payment={payment}
              shipping={shipping}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              totalPrice={totalPrice}
              tax={tax}
              mutateOtpCheckout={mutateOtpCheckout}
              mutateOtpCheckoutVerify={mutateOtpCheckoutVerify}
              countdownSeconds={countdownSeconds}
              setCountdownSeconds={setCountdownSeconds}
              setIsOtpConfirmed={setIsOtpConfirmed}
              setIsPressed={setIsPressed}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              filteredVoucher={filteredVoucher}
              isOtpVerifyLoading={isOtpVerifyLoading}
            />
          ) : (
            <>
              <SendOtpModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                mutateOtpCheckout={mutateOtpCheckout}
              />
              <>
                <div className="flex gap-3 text-[#007AF1] font-medium mb-3">
                  <p
                    className="hover:cursor-pointer"
                    onClick={() => navigate("/purchase")}
                  >
                    Purchase
                  </p>
                  <p>/</p>
                  <p
                    className="hover:cursor-pointer"
                    onClick={() => navigate("/purchase/cart-detail")}
                  >
                    Cart
                  </p>
                  <p>/</p>
                  <p>Checkout</p>
                </div>
                <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
                  Check Out
                </h1>
                {checkoutCartData && checkoutCartData !== undefined ? (
                  <>
                    <div className="overflow-auto flex flex-row items-center p-3 shadow-md rounded-xl mt-5 border">
                      <img src={Delivery} alt="" />
                      {checkoutCartData.address &&
                      checkoutCartData.address !== undefined ? (
                        <div className="flex flex-col">
                          <h1 className="font-bold">Informasi Pengiriman</h1>
                          <div className="gap-2 flex font-medium">
                            <p>{checkoutCartData.address.company_name}</p>
                            <p>{checkoutCartData.address.phone}</p>
                            <p>{checkoutCartData.address.district}, </p>
                            <p>{checkoutCartData.address.business_address}</p>
                            <p>{checkoutCartData.address.sub_district}, </p>
                            <p>{checkoutCartData.address.province}, </p>
                            <p>{checkoutCartData.address.kode_pos}</p>
                          </div>
                          <div className="flex flex-row shadow-md p-3 rounded-xl items-center gap-3 border mt-3">
                            <BsFillFileEarmarkTextFill />
                            <p>Catatan Pengiriman</p>
                            <input
                              className="border"
                              onChange={(e) =>
                                setSelectedMethod({
                                  ...selectedMethod,
                                  note: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <h1 className="font-bold text-[20px]">Detail Produk</h1>
                    </div>
                    {checkoutCartData.cart &&
                    checkoutCartData.cart !== undefined
                      ? checkoutCartData.cart.cart_regular?.map((item) => (
                          <div
                            className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between"
                            key={item.product_id}
                          >
                            <div className="flex justify-between w-full">
                              <div className="flex items-center gap-x-3">
                                <img
                                  src={item.image}
                                  alt=""
                                  className="border border-black rounded-md w-[150px] h-auto bg-cover"
                                />
                                <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden font-bold ml-3">
                                  <p>{item.product_name}</p>
                                  <p>jumlah: {item.quantity}</p>
                                  <p>{item.uom}</p>
                                  <p>
                                    {item.price !== null
                                      ? item.price.toLocaleString("id-ID", {
                                          style: "currency",
                                          currency: "IDR",
                                        })
                                      : null}
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-center items-center">
                                <div className="h-full border border-black mr-3"></div>
                                <div className="p-4 text-center">
                                  <p>Total</p>
                                  <p className="font-bold">
                                    {item.price !== null
                                      ? item.price.toLocaleString("id-ID", {
                                          style: "currency",
                                          currency: "IDR",
                                        })
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                    {checkoutCartData.cart &&
                    checkoutCartData.cart !== undefined
                      ? checkoutCartData.cart.cart_so?.map((item) => (
                          <div
                            className="mt-3  items-center shadow-md rounded-xl p-3 border-2 justify-between"
                            key={item.product_id}
                          >
                            <p className="font-bold text-[20px]">
                              Penawaran Spesial
                            </p>
                            <div className="flex justify-between w-full">
                              <div className="flex items-center gap-x-3">
                                <img
                                  src={item.image}
                                  alt=""
                                  className="border border-black rounded-md w-[150px] h-auto bg-cover"
                                />
                                <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden font-bold ml-3">
                                  <p>{item.product_name}</p>
                                  <p>jumlah: {item.quantity}</p>
                                  <p>{item.uom}</p>
                                  <p>
                                    {item.price_discount !== null
                                      ? item.price_discount.toLocaleString(
                                          "id-ID",
                                          {
                                            style: "currency",
                                            currency: "IDR",
                                          }
                                        )
                                      : null}
                                  </p>
                                  <div className="flex">
                                    <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold mr-2">
                                      {item.discount}%
                                    </p>
                                    <p className="line-through">
                                      {item.price !== null
                                        ? item.price.toLocaleString("id-ID", {
                                            style: "currency",
                                            currency: "IDR",
                                          })
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-center items-center">
                                <div className="h-full border border-black mr-3"></div>
                                <div className="p-4 text-center">
                                  <p>Total</p>
                                  <p className="font-bold">
                                    {item.price !== null
                                      ? item.price_discount.toLocaleString(
                                          "id-ID",
                                          {
                                            style: "currency",
                                            currency: "IDR",
                                          }
                                        )
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}

                    <div className="flex justify-between items-center font-medium h-full p-3 mt-3 rounded-md shadow-md border border-black">
                      <div className="flex items-center h-full">
                        <BsCreditCard2Back className="text-[25px] mr-3" />
                        {filteredVoucher.length !== 0 ? (
                          <p>{filteredVoucher.length} Voucher Terpakai</p>
                        ) : (
                          <p>Pakai Voucher / Kode Promo</p>
                        )}
                      </div>
                      <button onClick={() => setIsOpenAddVoucher(true)}>
                        <ArrowForwardIosIcon sx={{ color: "#007AF1" }} />
                      </button>
                    </div>
                    {checkoutCartData.address?.business_field_id === 5 ? (
                      <div className="font-medium h-full p-3 mt-3 rounded-md shadow-md border border-black">
                        <div className="flex items-center h-full">
                          <div className="flex">
                            <BsCreditCard2Back className="text-[25px] mr-3" />
                            <p className="text-[16px] font-bold">
                              Metode Pembayaran
                            </p>
                          </div>
                        </div>
                        <div className="border border-black w-full my-3"></div>
                        <p className="mb-2">
                          {checkoutCartData.payment.pg_bank}
                        </p>
                        <div className="flex justify-center bg-[#007AF1] rounded-md text-white p-2">
                          <p>
                            {checkoutCartData.address?.limit_order?.toLocaleString(
                              "id-ID",
                              {
                                style: "currency",
                                currency: "IDR",
                              }
                            )}
                            &nbsp;
                          </p>
                          <p>x1 Bulan</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {payment.logo !== "" ? (
                          <div className="font-medium h-full p-3 mt-3 rounded-md shadow-md border border-black">
                            <div className="flex items-center justify-between h-full">
                              <div className="flex">
                                <BsCreditCard2Back className="text-[25px] mr-3" />
                                <p className="text-[16px] font-bold">
                                  Metode Pembayaran
                                </p>
                              </div>
                              <p
                                className="text-[#007AF1] hover:cursor-pointer"
                                onClick={() => setIsPaymentMethodOpen(true)}
                              >
                                Pilih Metode Lain
                              </p>
                            </div>
                            <div className="border border-black w-full my-3"></div>
                            <div className="flex gap-2">
                              <div className="flex w-[10%] mr-3">
                                <img src={payment.logo} />
                              </div>
                              <div className="flex justify-between items-center w-full font-bold">
                                <p>{payment.name}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center font-medium h-full p-3 mt-3 rounded-md shadow-md border border-black">
                            <div className="flex items-center h-full">
                              <BsCreditCard2Back className="text-[25px] mr-3" />
                              <p className="text-[16px] font-bold">
                                Pilih Metode Pembayaran
                              </p>
                            </div>
                            <p
                              className="text-[#007AF1] hover:cursor-pointer"
                              onClick={() => setIsPaymentMethodOpen(true)}
                            >
                              Pilih
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    <div className="font-medium h-full p-3 mt-3 rounded-md shadow-md border border-black">
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <FaTruckMoving className="text-[25px] mr-3 text-[#007AF1]" />
                          <p className="text-[16px] font-bold">
                            Metode Pengiriman
                          </p>
                        </div>
                        <p
                          className="text-[#007AF1] hover:cursor-pointer"
                          onClick={() => setIsShippingMethodOpen(true)}
                        >
                          Ubah
                        </p>
                      </div>
                      <div className="border border-black w-full my-3"></div>
                      <div className="flex gap-2">
                        <div className="flex justify-between items-center w-full font-bold">
                          <div className="w-[10%] mr-3">
                            <p>{shipping.name}</p>
                            <p>{shipping.estimation}</p>
                          </div>
                          {shipping.price === 0 ? (
                            <p className="text-[#00B21D]">Gratis</p>
                          ) : (
                            <p>
                              Rp.{" "}
                              {shipping.price.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-20 mt-3 border border-black p-3 rounded-md">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          id="tidak-aktif"
                          checked={selectedMethod.use_point}
                          onClick={() =>
                            setSelectedMethod((prevValue) => ({
                              ...prevValue,
                              use_point: !prevValue.use_point,
                            }))
                          }
                        />
                        <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none dark:peer-focus:ring-gray-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-gray-500 dark:border-gray-600 peer-checked:bg-blue-500"></div>
                      </label>
                      <p>Olin Point: {checkoutCartData.loyalty_point} </p>
                    </div>
                  </>
                ) : null}
                <div className="flex items-center">
                  <CgNotes className="text-[30px] text-[#FF8D40] mr-2" />
                  <p className="font-bold">Rincian Pembayaran</p>
                </div>
                <div className="font-bold flex justify-between mt-3">
                  <div>
                    <p>Total Belanja</p>
                    <p className="mt-3">Biaya Pengiriman</p>
                    {selectedMethod.total_discount !== 0 ? (
                      <p className="mt-3">Total Diskon</p>
                    ) : null}
                    <p className="my-3">PPN 11%</p>
                    {selectedMethod.use_point ? (
                      <p className="mb-3">Olin Point</p>
                    ) : null}
                    <p className="mb-3">Biaya Admin</p>
                    <p>Total Pembayaran</p>
                  </div>
                  <div className="gap-y-3">
                    {selectedMethod.discount !== 0 ? (
                      <div className="flex">
                        {/*<p className="text-gray-400 line-through">
                                                      Rp.
                                                      {(
                                                        checkoutCartData?.result?.total -
                                                        selectedMethod.discount
                                                      ).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                                                    </p> */}
                        <p>
                          &nbsp;Rp.{" "}
                          {checkoutCartData?.result?.total?.toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )}
                        </p>
                      </div>
                    ) : (
                      <p>
                        Rp.{" "}
                        {checkoutCartData?.result?.total?.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </p>
                    )}
                    <p className="mt-3">
                      {shipping.price === 0
                        ? "Gratis"
                        : shipping.price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                    </p>
                    {selectedMethod.total_discount !== 0 ? (
                      <p className="my-3">
                        - Rp.{" "}
                        {selectedMethod.total_discount.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    ) : null}
                    <p className="my-3">
                      Rp.{" "}
                      {Math.round(tax).toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    {selectedMethod.use_point ? (
                      <p className="mb-3">
                        Rp.{" "}
                        {checkoutCartData?.loyalty_point?.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </p>
                    ) : null}
                    <p className="mb-3">
                      Rp.{" "}
                      {payment.fee?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    <p className="text-[#FCB63A]">
                      Rp.{" "}
                      {Math.round(totalPrice).toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                </div>
                <div className="border border-black my-3"></div>
              </>
              <div className="flex justify-center mt-20">
                <div className="fixed bottom-0 z-20 bg-white p-3 rounded-md w-full h-[10%] drop-shadow-lg">
                  <div className="flex justify-center w-full">
                    <button
                      className="flex justify-center items-center gap-2 text-center bg-[#007AF1] w-[77%] p-1 rounded-lg text-white hover:shadow-md disabled:bg-gray-500"
                      disabled={
                        !selectedVoucher.voucherPayment.includes(
                          selectedMethod.payment_id
                        ) && selectedVoucher.voucherPayment.length !== 0
                      }
                      onClick={() => setIsModalOpen(true)}
                    >
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
              <Dialog
                open={isOpenAddVoucher}
                onClose={() => {
                  setIsOpenAddVoucher(false);
                }}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                  {isDetailVoucherOpen ? (
                    <button onClick={() => setIsDetailVoucherOpen(false)}>
                      <AiOutlineArrowLeft />
                    </button>
                  ) : null}
                  <div className="flex w-full items-center justify-center">
                    <p className="font-bold">
                      {isDetailVoucherOpen
                        ? "Detail Voucher"
                        : "Voucher / Kode Promo"}
                    </p>
                  </div>
                </DialogTitle>
                <DialogContent>
                  {isDetailVoucherOpen ? (
                    <VoucherPurchaseDetail voucherId={voucherId} />
                  ) : (
                    <VoucherPurchaseCheckout
                      voucherPurchaseData={voucherPurchaseData}
                      setIsDetailVoucherOpen={setIsDetailVoucherOpen}
                      setVoucherId={setVoucherId}
                      cartDetailData={checkoutCartData}
                      // checklistData={checklistData}
                      countCart={countCart}
                      selectedMethod={selectedMethod}
                      unuseableVoucher={unuseableVoucher}
                    />
                  )}
                </DialogContent>
                {isDetailVoucherOpen ? null : (
                  <DialogActions>
                    {totalDiscount !== 0 ? (
                      <div className="flex justify-between w-full">
                        <div className="text-[12px] font-bold w-full">
                          <p>Kamu bisa hemat</p>
                          <p className="text-[#007AF1]">
                            Rp.{" "}
                            {totalDiscount.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                        <button
                          className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                          onClick={() => {
                            if (
                              selectedVoucher &&
                              selectedVoucher !== undefined
                            ) {
                              setIsOpenAddVoucher(false);
                            } else return;
                          }}
                        >
                          Gunakan Voucher
                        </button>
                      </div>
                    ) : (
                      <button
                        className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                        onClick={() => {
                          setIsOpenAddVoucher(false);
                          setSelectedVoucher({
                            voucherId: [],
                            voucherName: "",
                            voucherPrice: [],
                          });
                        }}
                      >
                        Bayar Tanpa Voucher
                      </button>
                    )}
                  </DialogActions>
                )}
              </Dialog>

              <Dialog
                open={isPaymentMethodOpen}
                onClose={() => {
                  setIsPaymentMethodOpen(false);
                }}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>
                  <div className="flex items-center w-full justify-center">
                    <button
                      onClick={() => {
                        setIsPaymentMethodOpen(false);
                      }}
                    >
                      <AiOutlineArrowLeft className="font-bold text-start text-[25px] hover:cursor-pointer" />
                    </button>
                    <div className="flex justify-center w-full">
                      <p>Metode Pembayaran</p>
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <PurchasePaymentMethod
                    paymentMethodData={paymentMethodData}
                    isPaymentMethodLoading={isPaymentMethodLoading}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    payment={payment}
                    setPayment={setPayment}
                  />
                </DialogContent>
                <DialogActions>
                  <div className="w-full">
                    <button
                      className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                      onClick={() => {
                        setIsPaymentMethodOpen(false);
                      }}
                    >
                      Konfirmasi
                    </button>
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isShippingMethodOpen}
                onClose={() => {
                  setIsShippingMethodOpen(false);
                }}
                maxWidth="xs"
                fullWidth
              >
                <DialogTitle>
                  <div className="flex items-center w-full justify-center">
                    <button
                      onClick={() => {
                        setIsShippingMethodOpen(false);
                      }}
                    >
                      <AiOutlineArrowLeft className="font-bold text-start text-[25px] hover:cursor-pointer" />
                    </button>
                    <div className="flex justify-center w-full">
                      <p>Metode Pengiriman</p>
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <ShippingMethod
                    isShippingMethodLoading={isShippingMethodLoading}
                    shippingMethodData={shippingMethodData}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    shipping={shipping}
                    setShipping={setShipping}
                  />
                </DialogContent>
                <DialogActions>
                  <div className="w-full">
                    <button
                      className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                      onClick={() => {
                        setIsShippingMethodOpen(false);
                      }}
                    >
                      Konfirmasi
                    </button>
                  </div>
                </DialogActions>
              </Dialog>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CheckoutPurchase;
