import React from "react";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";
import Header from "../../../components/ui/Header";
import HOC from "../../../components/HOC";
import AddRequestWarehouse from "../../../components/screen/centralWarehouse/request/AddRequestWarehouse";

const AddRequestTransferPage = () => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            New Request Transfer
          </h1>
          <AddRequestWarehouse type={5} />
        </div>
      </div>
    </div>
  );
};

export default HOC(AddRequestTransferPage);
