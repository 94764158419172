import { Box, Modal } from "@mui/material";
import { FaChevronLeft, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import PrescriptionPreview from "./PrescriptionPreview";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalPreview = ({
  open,
  setOpen,
  setIsFormView,
  formik,
  selectedDoctor,
  selectedPatient,
  isDetail,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "50%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "95vh",
            padding: 0,
          }}
          className="text-left text-base"
        >
          <div className="px-8 py-2 w-full">
            <div className=" grid grid-cols-3 items-center gap-4">
              <FaChevronLeft
                className="hover:text-red-500 cursor-pointer"
                onClick={handleClose}
              />
              <p className="font-bold text-center">Prescription Preview</p>
              {isDetail ? null : (
                <div className="w-full text-right">
                  <FaRegEdit
                    className="text-blue-500 cursor-pointer hover:text-blue-700 float-right"
                    onClick={() => setIsFormView(false)}
                  />
                </div>
              )}
            </div>
          </div>

          <hr />
          <div className="px-8 py-2 w-full flex justify-center">
            <div className="w-full border-2 p-3 rounded-md">
              {formik.values.regular.length === 0 &&
              formik.values.concoction.length === 0 ? (
                <p>Belum ada resep</p>
              ) : (
                <PrescriptionPreview
                  formik={formik}
                  selectedDoctor={selectedDoctor}
                  selectedPatient={selectedPatient}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalPreview;
