import React from "react";
import HOC from "../../../components/HOC";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import ProductActive from "../../../components/screen/inventory/productActive/ProductActive";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const ProductActivePage = ({ isWarehouse }) => {
  return (
    <div className="flex">
      <SidebarComponents />
      <div
        className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
      >
        <Header />
        <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
          Inventory {isWarehouse ? "Central Warehouse" : ""}
        </h1>
        <ProductActive isWarehouse={isWarehouse} />
      </div>
    </div>
  );
};

export default HOC(ProductActivePage);
