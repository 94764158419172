import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";

const SpecialOffer = () => {
  const navigate = useNavigate();
  const {
    specialOffer,
    specialOfferBackground,
    dataBufferSpecialOffer,
    setDataBufferSpecialOffer,
    isLoading,
    setIsLoading,
    mutateAddToCart,
  } = useContext(SelectedProductContext);

  useEffect(() => {
    if (specialOffer) {
      setDataBufferSpecialOffer(
        specialOffer.map((data) => ({
          id: data.id,
          price: data.price,
          nama_produk: data.nama_produk,
          image: data.image,
          quantity: data.quantity,
          price_discount: data.price_discount,
          discount: data.discount,
          product_so_id: data.product_so_id,
          isVisible: data.quantity !== 0,
          isLoading: false,
        }))
      );
    }
  }, [specialOffer]);

  return (
    <div>
      {specialOffer && (specialOffer !== undefined || null) ? (
        <div className="w-full">
          <div
            className="flex items-center mt-10 p-3 rounded-lg"
            style={
              specialOfferBackground && specialOfferBackground !== undefined
                ? {
                    backgroundImage: `linear-gradient(to right, ${specialOfferBackground.color[0]}, ${specialOfferBackground.color[1]}, ${specialOfferBackground.color[2]})`,
                  }
                : {
                    backgroundImage:
                      "linear-gradient(to right, #005EB8, #0089D4, #00A6CE)",
                  }
            }
          >
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              modules={[Navigation]}
              className="flex justify-center w-full grid grid-cols-1"
            >
              <SwiperSlide className="flex items-center">
                <img
                  src={specialOfferBackground.image}
                  alt=""
                  className="w-[250px] mt-3"
                />
              </SwiperSlide>
              {dataBufferSpecialOffer.map((offer, index) => (
                <SwiperSlide key={index}>
                  <div className="p-3 rounded-lg shadow-md w-full h-full bg-white flex flex-wrap content-between">
                    <div>
                      <img
                        src={offer.image !== null ? offer.image : ""}
                        alt=""
                        className="rounded-lg object-cover hover:cursor-pointer"
                        onClick={() => navigate(`/purchase/detail/${offer.id}`)}
                      />
                      <div className="mt-3 text-[12px] lg:text-[15px]">
                        <h1 className="text-gray-700 font-bold ">
                          {offer.nama_produk}
                        </h1>
                        <p className="text-gray-500 mt-2">{offer.uom}</p>
                        <h1 className="text-gray-700 font-bold">
                          {Math.round(offer.price_discount).toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )}
                        </h1>
                        <div className="flex gap-2 items-center mt-1">
                          <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                            {offer.discount}%
                          </p>
                          <p className="line-through">
                            {offer.price?.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <>
                      {offer.isVisible ? (
                        <div className="w-full mt-3">
                          <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                            {offer.isLoading ? (
                              <div className="flex justify-center w-full">
                                <p>Loading</p>
                              </div>
                            ) : (
                              <>
                                <button
                                  className="bg-[#FF7A5D] px-3 rounded-lg"
                                  disabled={isLoading}
                                  onClick={() => {
                                    if (offer.quantity === 1) {
                                      setDataBufferSpecialOffer((prevValue) =>
                                        prevValue.map((data) => {
                                          return data.id === offer.id
                                            ? {
                                                ...data,
                                                isVisible: false,
                                                isLoading: true,
                                                quantity: 0,
                                              }
                                            : data;
                                        })
                                      );
                                      mutateAddToCart({
                                        price: offer.price,
                                        product_id: offer.id,
                                        quantity: 0,
                                        flag: false,
                                        type: 2,
                                        param: 1,
                                        product_so_id: offer.product_so_id,
                                      });
                                      return;
                                    }
                                    setDataBufferSpecialOffer((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === offer.id
                                          ? {
                                              ...data,
                                              isLoading: true,
                                              quantity: data.quantity - 1,
                                            }
                                          : data;
                                      })
                                    );
                                    setIsLoading(true);
                                    mutateAddToCart({
                                      price: offer.price,
                                      product_id: offer.id,
                                      quantity: offer.quantity - 1,
                                      flag: false,
                                      type: 2,
                                      param: 1,
                                      product_so_id: offer.product_so_id,
                                    });
                                  }}
                                >
                                  -
                                </button>
                                {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBufferMostSearched[index]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBufferMostSearched(
                                      dataBufferMostSearched.map((offer) => {
                                        return offer.id === productId
                                          ? {
                                              ...offer,
                                              quantity: 0,
                                            }
                                          : offer;
                                      })
                                    );
                                  setDataBufferMostSearched(
                                    dataBufferMostSearched.map((offer) => {
                                      return offer.id === productId
                                        ? {
                                            ...offer,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0
                                              ),
                                              10
                                            ),
                                          }
                                        : offer;
                                    })
                                  );
                                }}
                              /> */}
                                <p>{offer.quantity}</p>
                                <button
                                  className="bg-[#5DFFBB] px-3 rounded-lg"
                                  disabled={isLoading}
                                  onClick={() => {
                                    setDataBufferSpecialOffer((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === offer.id
                                          ? {
                                              ...data,
                                              isLoading: true,
                                              quantity: offer.quantity + 1,
                                            }
                                          : data;
                                      })
                                    );
                                    setIsLoading(true);
                                    mutateAddToCart({
                                      price: offer.price,
                                      product_id: offer.id,
                                      quantity: offer.quantity + 1,
                                      flag: false,
                                      type: 2,
                                      param: 1,
                                      product_so_id: offer.product_so_id,
                                    });
                                  }}
                                >
                                  +
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="mt-3 text-[12px] lg:text-base w-full">
                          <button
                            className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                            disabled={isLoading}
                            onClick={() => {
                              setDataBufferSpecialOffer((prevValue) =>
                                prevValue.map((data) => {
                                  return data.id === offer.id
                                    ? {
                                        ...data,
                                        isVisible: true,
                                        isLoading: true,
                                        quantity: 1,
                                      }
                                    : data;
                                })
                              );
                              setIsLoading(true);
                              mutateAddToCart({
                                price: offer.price,
                                product_id: offer.id,
                                quantity: 1,
                                flag: false,
                                type: 2,
                                param: 1,
                                product_so_id: offer.product_so_id,
                              });
                            }}
                          >
                            <AiOutlineShoppingCart />
                            Add to cart
                          </button>
                        </div>
                      )}
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SpecialOffer;
