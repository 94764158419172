import React from "react";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import {
  initialValues,
  validationSchema,
} from "../../components/context/CostumerProviderFormik";
import SidebarComponents from "../../components/ui/SidebarComponents";
import DetailCustomerComponents from "../../components/screen/customer/DetailCustomer";
import { Formik } from "formik";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const DetailCustomer = () => {
  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <div className="">
        <div className="flex">
          <SidebarComponents />
          <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
            <Header />
            <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
              Detail Pelanggan
            </h1>
            <DetailCustomerComponents />
          </div>
        </div>
      </div>
    </Formik>
  );
};

export default HOC(DetailCustomer);
