import moment from "moment";

const PrintPatientHistory = ({ printRef, historyList, selectedPatient }) => {
  const HistoryTextfield = ({ title, content, suffix }) => {
    return (
      <div className="mb-1 text-xs">
        <p className="mb-2 text-gray-500">{title}</p>
        <p className={`border-b-2 inline-block ${suffix ? "w-3/4" : "w-full"}`}>
          {content}
        </p>
        {suffix ? <p className="inline-block ml-4">{suffix}</p> : null}
      </div>
    );
  };

  return (
    <div ref={printRef}>
      {historyList
        ? historyList.map((item) => (
            <div className="page-break">
              <div className="flex justify-between items-center">
                <p className="font-bold mb-2">Riwayat Analisis</p>
                <p className="mb-4 text-xs">
                  Tanggal: {moment(item.check_date).format("DD MMMM YYYY")}
                </p>
              </div>
              <hr className="mb-4" />

              <div className="mb-4 text-xs">
                <p className="font-bold text-base mb-2 bg-blue-100 p-1">
                  Informasi Pasien
                </p>
                <div className="flex gap-2 items-center">
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">No Rekam Medis</p>
                    <p>:</p>
                    <p>{selectedPatient?.medical_number}</p>
                  </div>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">Email</p>
                    <p>:</p>
                    <p>
                      {selectedPatient?.email === ""
                        ? "-"
                        : selectedPatient?.email}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">Tanggal Lahir</p>
                    <p>:</p>
                    <p>{selectedPatient?.date_of_birth}</p>
                  </div>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">NIK</p>
                    <p>:</p>
                    <p>{selectedPatient?.nik}</p>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">Gender</p>
                    <p>:</p>
                    <p>
                      {selectedPatient?.gender === 1
                        ? "Laki-laki"
                        : "Perempuan"}
                    </p>
                  </div>
                  <div className="flex gap-2 items-center w-full">
                    <p className="w-1/3">No Telepon</p>
                    <p>:</p>
                    <p>
                      {selectedPatient?.phone_number === ""
                        ? "-"
                        : selectedPatient?.phone_number}
                    </p>
                  </div>
                </div>

                <div className="flex gap-2 items-center">
                  <p className="w-1/6">Alamat</p>
                  <p>:</p>
                  <p>{selectedPatient?.address}</p>
                </div>
              </div>
              <div className="mb-8 text-xs">
                <p className="font-bold mb-2 text-base bg-blue-100 p-1">
                  Informasi Dokter
                </p>
                <div className="flex gap-2 items-center">
                  <p className="w-1/6">Nama Dokter</p>
                  <p>:</p>
                  <p>{item.doctor_name}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <p className="w-1/6">No. SIP</p>
                  <p>:</p>
                  <p>{item.sip}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <p className="w-1/6">Specialization</p>
                  <p>:</p>
                  <p>{item.specialization}</p>
                </div>
              </div>

              <div className="mb-4 keep-together">
                <p className="font-bold mb-2 bg-blue-100 p-1">Subjective (S)</p>
                <div className="flex items-start gap-2">
                  <div className="w-full">
                    <HistoryTextfield
                      title="Keluhan Utama"
                      content={item.subjective.main_complaint}
                    />
                    <HistoryTextfield
                      title="Riwayat penyakit sebelumnya"
                      content={item.subjective.existing_illness}
                    />
                    <HistoryTextfield
                      title="Riwayat sosial"
                      content={item.subjective.social_history}
                    />
                  </div>
                  <div className="w-full">
                    <HistoryTextfield
                      title="Riwayat tumbuh kembang"
                      content={item.subjective.development_history}
                    />
                    <HistoryTextfield
                      title="Riwayat keluarga"
                      content={item.subjective.family_history}
                    />
                    <HistoryTextfield
                      title="Riwayat kelahiran"
                      content={item.subjective.birth_history}
                    />
                  </div>
                </div>
                <HistoryTextfield
                  title="Konsumsi obat saat ini"
                  content={item.subjective.current_medication}
                />
              </div>

              <div className="mb-4 keep-together">
                <p className="font-bold mb-2 bg-blue-100 p-1">Objective (O)</p>
                <div className="mb-4">
                  <p className="font-bold text-xs">Tanda-tanda Vital</p>
                  <div className="flex items-start gap-2 w-full">
                    <div className="w-full">
                      <HistoryTextfield
                        title="Tekanan darah (diastolik)"
                        content={item.objective.diastole}
                        suffix="mmHg"
                      />
                      <HistoryTextfield
                        title="Suhu Tubuh"
                        content={item.objective.body_temp}
                        suffix="&#176;C"
                      />
                      <HistoryTextfield
                        title="Denyut Nadi"
                        content={item.objective.pulse}
                        suffix="x/menit"
                      />
                    </div>
                    <div className="w-full">
                      <HistoryTextfield
                        title="Tekanan darah (sistolik)"
                        content={item.objective.systole}
                        suffix="mmHg"
                      />
                      <HistoryTextfield
                        title="Nafas"
                        content={item.objective.respiratory_rate}
                        suffix="x/menit"
                      />
                      <HistoryTextfield
                        title="Kadar Oksigen"
                        content={item.objective.oxygen}
                        suffix="Sp02 (%)"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="font-bold text-xs">Nyeri</p>
                  <div className="flex gap-2 w-full">
                    <div className="w-full">
                      <HistoryTextfield
                        title="Tingkat Nyeri"
                        content={item.objective.pain_level}
                        suffix="1 s/d 10"
                      />
                    </div>
                    <div className="w-full">
                      <HistoryTextfield
                        title="Lokasi Nyeri"
                        content={item.objective.pain_location}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="font-bold text-xs">Lainnya</p>
                  <div className="w-full flex gap-2 items-start">
                    <div className="w-full">
                      <HistoryTextfield
                        title="Lingkar Kepala"
                        content={item.objective.head_circumference}
                        suffix="cm"
                      />
                      <HistoryTextfield
                        title="Berat Badan"
                        content={item.objective.weight}
                        suffix="kg"
                      />
                    </div>
                    <div className="w-full">
                      <HistoryTextfield
                        title="Tinggi Badan"
                        content={item.objective.height}
                        suffix="cm"
                      />
                      <HistoryTextfield
                        title="Pemeriksaan Fisik"
                        content={item.objective.physical_exam}
                        suffix="cm"
                      />
                    </div>
                  </div>
                  <HistoryTextfield
                    title="Pemeriksaan Penunjang"
                    content={item.objective.support_exam}
                    suffix="cm"
                  />
                </div>
              </div>

              <div className="mb-4 keep-together">
                <p className="font-bold mb-2 bg-blue-100 p-1">Assessment (A)</p>
                <div className="mb-4">
                  <HistoryTextfield
                    title="Diagnosis"
                    content={item.assessment.diagnosis}
                  />
                  <HistoryTextfield
                    title="Diagnosis Banding"
                    content={item.assessment.diff_diagnosis}
                  />
                </div>
              </div>

              <div className="mb-4 keep-together">
                <p className="font-bold mb-2 w-full bg-blue-100 p-1">
                  Plan (P)
                </p>
                <div className="mb-4">
                  <HistoryTextfield
                    title="Rencana Pengobatan"
                    content={item.plan.diff_diagnosis}
                  />
                  <HistoryTextfield
                    title="Edukasi Pasien"
                    content={item.plan.patient_education}
                  />
                  <HistoryTextfield
                    title="Tindak Lanjut"
                    content={item.plan.follow_up}
                  />
                  <HistoryTextfield
                    title="Rujukan"
                    content={item.plan.reference}
                  />
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default PrintPatientHistory;
