import { useState, useRef, Fragment, useContext } from "react";
import { ListItemButton, Collapse, Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { MdOutlineContentCopy } from "react-icons/md";

import { PaymentContext } from "../PurchasePayment";

const VaPaymentMethod = () => {
  const textRef = useRef(null);

  const { invoice, mutatePaymentCheck, paymentDetailData, formattedTime } =
    useContext(PaymentContext);

  const [isPaymentInstructionOpen, setIsPaymentInstructionOpen] = useState([
    false,
    false,
    false,
  ]);
  const [copyMessage, setCopyMessage] = useState("");

  return (
    <div className="mt-3">
      {paymentDetailData && paymentDetailData !== undefined ? (
        <>
          <p className="text-[48px] font-medium text-center">
            Status Pembayaran
          </p>
          <p className="text-[20px] font-bold">Transfer ke Virtual Account</p>
          <div className="bg-[#D7E9FC] rounded-md my-3">
            <div className="flex items-center justify-between p-2">
              <div className="flex w-[10%] items-center">
                <img src={paymentDetailData.logo} className="mr-3" />
                <p className="font-bold" ref={textRef}>
                  {paymentDetailData.va_numbers}
                </p>
              </div>
              <MdOutlineContentCopy
                className="text-[35px] hover:cursor-pointer"
                onClick={() => {
                  const textToCopy = textRef.current.innerText;
                  navigator.clipboard.writeText(textToCopy).then(() => {
                    setCopyMessage("Text copied to clipboard");
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap">
            <p>
              Pastikan nomor virtual account/pelanggan yang kamu masukkan benar.
              OLIN&nbsp;
            </p>
            <p className="font-bold">Tidak Bertanggung Jawab&nbsp;</p>
            <p>jika Terjadi kesalahan pengisian data</p>
          </div>
          <div className="flex justify-between mt-3 font-bold">
            <p>tagihan kamu</p>
            <p>
              {paymentDetailData.total && paymentDetailData.total !== undefined
                ? paymentDetailData.total.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })
                : 0}
            </p>
          </div>
          <div className="border border-black my-3"></div>
          <div className="flex justify-between font-bold">
            <p>Jatuh Tempo&nbsp;</p>
            <p className="text-[#F21B3F]">{formattedTime}</p>
          </div>
          <div>
            <p className="text-[20px] font-bold my-3">Instruksi Pembayaran</p>
            {paymentDetailData.data && paymentDetailData.data !== undefined
              ? paymentDetailData.data.map((trx, index) => (
                  <div
                    className="w-full border border-black mb-3 rounded-md"
                    key={index}
                  >
                    <ListItemButton
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        setIsPaymentInstructionOpen((prevBool) => {
                          const newBoolArray = [...prevBool];
                          newBoolArray[index] = !newBoolArray[index];
                          return newBoolArray;
                        })
                      }
                    >
                      <p>{trx.source}</p>
                      <KeyboardArrowDownIcon />
                    </ListItemButton>
                    <Collapse
                      in={isPaymentInstructionOpen[index]}
                      unmountOnExit
                      sx={{ fontWeight: "bold", padding: 2 }}
                    >
                      {trx.instruction.map((instruction, idx) => (
                        <p className="mb-3" key={idx}>
                          {instruction}
                        </p>
                      ))}
                    </Collapse>
                  </div>
                ))
              : null}
            <div className="flex justify-center">
              <div className="fixed bottom-0 z-20 bg-white p-3 rounded-md w-full h-[10%] drop-shadow-lg">
                <div className="flex justify-center w-full">
                  <button
                    className="flex justify-center items-center gap-2 text-center bg-[#007AF1] w-[77%] p-1 rounded-lg text-white hover:shadow-md"
                    onClick={() => mutatePaymentCheck({ invoice: invoice })}
                  >
                    Cek Status Pembayaran
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <Snackbar
        open={Boolean(copyMessage)}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setCopyMessage("");
        }}
        message={copyMessage}
        action={
          <Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setCopyMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default VaPaymentMethod;
