import { CircularProgress } from "@mui/material";

const DOICard = ({ interval, value, data, isLoading }) => {
  return (
    <div className="w-full border border-black rounded-md font-bold p-2 items-center justify-center flex flex-col">
      <p className="mb-2 p-0 whitespace-nowrap">DOI {interval} Days</p>
      <div className="rounded bg-blue-200 text-blue-500 p-2 w-full justify-center items-center w-full flex">
        {data && !isLoading ? (
          (value ? value : 0).toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
          })
        ) : isLoading ? (
          <CircularProgress size={20} />
        ) : null}
      </div>
    </div>
  );
};

export default DOICard;
