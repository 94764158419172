import { useState } from "react";
import { TextField, InputAdornment, Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { BiLockAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import VerificationOTP from "../../../../assets/images/verifikasi OTP 1.jpg";
import { changeEmail } from "../../../../services/authApi";

const validationSchema = yup.object({
  oldEmail: yup.string().email().required("Email wajib diisi"),
  email: yup
    .string()
    .required("Email wajib diisi")
    .email()
    .oneOf([yup.ref("oldEmail"), null], "Email harus sesuai"),
});

const RequestEmailChange = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateChangeEmail } = useMutation({
    mutationFn: changeEmail,
    onSuccess: () => navigate("/success"),
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const formik = useFormik({
    initialValues: {
      oldEmail: "",
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutateChangeEmail(values.email);
    },
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      <img src={VerificationOTP} className="w-[50%]" alt="" />
      <div className="flex flex-col justify-center">
        <p className="font-bold text-[35px]">Masukkan Email Baru</p>
        <p className="text-[#007AF1] font-bold">Email Baru</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <BiLockAlt className="text-[30px] mr-5" />
                </InputAdornment>
              ),
            }}
            value={formik.values.oldEmail}
            onChange={(e) => formik.setFieldValue("oldEmail", e.target.value)}
            helperText={formik.touched.oldEmail && formik.errors.oldEmail}
            error={formik.touched.oldEmail && Boolean(formik.errors.oldEmail)}
          />

          <div className="my-3">
            <p className="text-[#007AF1] font-bold">Ketik Ulang Email Baru</p>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <BiLockAlt className="text-[30px] mr-5" />
                  </InputAdornment>
                ),
              }}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </div>
          <button
            className="bg-[#007AF1] p-3 text-white font-bold rounded rounded-md mt-3 w-full"
            type="submit"
          >
            Selanjutnya
          </button>
        </form>
      </div>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  setErrorMessage("");
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default RequestEmailChange;
