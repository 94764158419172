import { useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Pagination } from "@mui/material";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";
import { getMostSearchedProduct } from "../../../../../services/purchaseApi";

const FETCH_LIMIT = 10;

const AllMostSearchList = () => {
  const navigate = useNavigate();
  const {
    mostSearchedProduct,
    isProduct,
    setIsProduct,
    isLoading,
    setIsLoading,
    mutateAddToCart,
    dataBufferMostSearched,
    setDataBufferMostSearched,
    offset,
    setOffset,
  } = useContext(SelectedProductContext);

  const pageCount = useMemo(() => {
    if (mostSearchedProduct === undefined || mostSearchedProduct === null)
      return 0;
    else return Math.ceil(mostSearchedProduct.count / FETCH_LIMIT);
  }, [mostSearchedProduct]);

  useEffect(() => {
    setDataBufferMostSearched(
      mostSearchedProduct && mostSearchedProduct !== undefined
        ? mostSearchedProduct.product?.map((data) => ({
            id: data.id,
            image: data.image,
            nama_produk: data.nama_produk,
            price: data.price,
            quantity: data.quantity,
            isVisible: data.quantity !== 0,
            isLoading: false,
          }))
        : []
    );
  }, [mostSearchedProduct, isProduct]);

  return (
    <div>
      <div className="flex text-[#007AF1] font-bold my-3 p-3 gap-2">
        <p
          className="hover:underline hover:cursor-pointer"
          onClick={() =>
            setIsProduct({ ...isProduct, mostSearchSelected: false })
          }
        >
          Purchase
        </p>
        <p>/</p>
        <p className="hover:underline hover:cursor-pointer">
          Produk Paling dicari
        </p>
      </div>
      <div className="w-full">
        <p className="text-[36px] font-bold">Produk Paling dicari</p>
        <div className="border-2 border-black"></div>
      </div>
      <div className="flex grid grid-cols-4 gap-3 mt-3">
        {dataBufferMostSearched && dataBufferMostSearched !== undefined
          ? dataBufferMostSearched.map((list, index) => (
              <div
                className="bg-white p-3 rounded-lg flex justify-center items-center flex-col border border-black"
                key={index}
              >
                <div className="p-3 bg-white rounded-lg shadow-md w-full h-full flex flex-wrap content-between">
                  <div>
                    <img
                      src={list.image !== null ? list.image : ""}
                      alt=""
                      className="rounded-lg object-cover hover:cursor-pointer"
                      onClick={() => navigate(`/purchase/detail/${list.id}`)}
                    />
                    <div className="mt-3 text-[12px] lg:text-[15px]">
                      <h1 className="text-gray-700 font-bold ">
                        {list.nama_produk}
                      </h1>
                      <p className="text-gray-500 mt-2">{list.uom}</p>
                      <h1 className="text-gray-700 font-bold">
                        {list.price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </h1>
                      {/* <div className="flex gap-2 items-center mt-1">
                      <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                        15%
                      </p>
                      <p className="line-through">Rp. {productPrice}</p>
                    </div> */}
                    </div>
                  </div>
                  <>
                    {list.isVisible ? (
                      <div className="w-full mt-3">
                        <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                          {list.isLoading ? (
                            <div className="flex justify-center w-full">
                              <p>Loading</p>
                            </div>
                          ) : (
                            <>
                              <button
                                className="bg-[#FF7A5D] px-3 rounded-lg"
                                disabled={isLoading}
                                onClick={() => {
                                  if (list.quantity === 1) {
                                    setDataBufferMostSearched((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === list.id
                                          ? {
                                              ...data,
                                              isVisible: false,
                                              isLoading: true,
                                              quantity: data.quantity - 1,
                                            }
                                          : data;
                                      })
                                    );
                                  } else {
                                    setDataBufferMostSearched((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === list.id
                                          ? {
                                              ...data,
                                              isVisible: false,
                                              isLoading: true,
                                              quantity: list.quantity - 1,
                                            }
                                          : data;
                                      })
                                    );
                                  }
                                  setIsLoading(true);
                                  mutateAddToCart({
                                    price: list.price,
                                    product_id: list.id,
                                    quantity: list.quantity - 1,
                                    flag: false,
                                    type: 1,
                                    param: 1,
                                  });
                                }}
                              >
                                -
                              </button>
                              {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBuffer[index]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBuffer(
                                      dataBuffer.map((list) => {
                                        return list.id === productId
                                          ? {
                                              ...list,
                                              quantity: 0,
                                            }
                                          : list;
                                      })
                                    );
                                  setDataBuffer(
                                    dataBuffer.map((list) => {
                                      return list.id === productId
                                        ? {
                                            ...list,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0
                                              ),
                                              10
                                            ),
                                          }
                                        : list;
                                    })
                                  );
                                }}
                              /> */}
                              <p>{list.quantity}</p>
                              <button
                                className="bg-[#5DFFBB] px-3 rounded-lg"
                                disabled={isLoading}
                                onClick={() => {
                                  setDataBufferMostSearched((prevValue) =>
                                    prevValue.map((data) => {
                                      return data.id === list.id
                                        ? {
                                            ...data,
                                            isLoading: true,
                                            quantity: data.quantity + 1,
                                          }
                                        : data;
                                    })
                                  );
                                  setIsLoading(true);
                                  mutateAddToCart({
                                    price: list.price,
                                    product_id: list.id,
                                    quantity: list.quantity + 1,
                                    flag: false,
                                    type: 1,
                                    param: 1,
                                  });
                                }}
                              >
                                +
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3 text-[12px] lg:text-base w-full">
                        <button
                          className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                          disabled={isLoading}
                          onClick={() => {
                            setDataBufferMostSearched((prevValue) =>
                              prevValue.map((data) => {
                                return data.id === list.id
                                  ? {
                                      ...data,
                                      isVisible: true,
                                      isLoading: true,
                                      quantity: 1,
                                    }
                                  : data;
                              })
                            );
                            setIsLoading(true);
                            mutateAddToCart({
                              price: list.price,
                              product_id: list.id,
                              quantity: 1,
                              flag: false,
                              type: 1,
                              param: 1,
                            });
                          }}
                        >
                          <AiOutlineShoppingCart />
                          Add to cart
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </div>
            ))
          : null}
      </div>
      <div className="flex w-full justify-center my-3">
        <Pagination
          count={pageCount}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default AllMostSearchList;
