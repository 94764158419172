import { TextField, InputAdornment } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { BiLockAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import VerificationOTP from "../../../../assets/images/verifikasi OTP 1.jpg";
import { changePhone } from "../../../../services/authApi";

const validationSchema = yup.object({
  oldPhone: yup.string().required("No HP wajib diisi"),
  phone: yup
    .string()
    .required("No HP wajib diisi")
    .oneOf([yup.ref("oldPhone"), null], "No HP harus sesuai"),
});

const RequestPhoneChange = () => {
  const navigate = useNavigate;

  const { mutate: mutateChangePhone } = useMutation({
    mutationFn: changePhone,
    onSuccess: () => navigate("/success"),
  });

  const formik = useFormik({
    initialValues: {
      oldPhone: "",
      phone: "",
    },
    validationSchema,
    onSubmit: (value) => {
      mutateChangePhone(value.phone);
    },
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      <img src={VerificationOTP} className="w-[50%]" />
      <div className="flex flex-col justify-center">
        <p className="font-bold text-[35px]">Masukkan phone Baru</p>
        <p className="text-[#007AF1] font-bold">No HP Baru</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <BiLockAlt className="text-[30px] mr-5" />
                </InputAdornment>
              ),
            }}
            value={formik.values.oldPhone}
            onChange={(e) => formik.setFieldValue("oldPhone", e.target.value)}
            helperText={formik.touched.oldPhone && formik.errors.oldPhone}
            error={formik.touched.oldPhone && Boolean(formik.errors.oldPhone)}
          />

          <div className="my-3">
            <p className="text-[#007AF1] font-bold">Ketik Ulang No HP Baru</p>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <BiLockAlt className="text-[30px] mr-5" />
                  </InputAdornment>
                ),
              }}
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
              helperText={formik.touched.phone && formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </div>
        </form>
        <button
          className="bg-[#007AF1] p-3 text-white font-bold rounded rounded-md mt-3"
          type="submit"
        >
          Selanjutnya
        </button>
      </div>
    </div>
  );
};

export default RequestPhoneChange;
