import React, { useEffect } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { BsImages } from "react-icons/bs";
import { useMutation } from "@tanstack/react-query";
import { postSubmitSIAOrSIPA } from "../../../services/authApi";
import { uploadImage } from "../../../services/userManagementApi";

const validationSchema = yup.object({
  nomor: yup.string().required("nomor SIPA wajib diisi"),
  expired: yup.string().required("tanggal kadaluarsa wajib di isi"),
  file: yup.mixed().required("file wajib diisi"),
});

const toDate = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const ModalSIPA = (props) => {
  const { open, setOpen } = props;

  const { mutate: mutateSubmitSIAOrSIPA } = useMutation({
    mutationFn: postSubmitSIAOrSIPA,
    onSuccess: () => setOpen(false)
  });

  const { mutate: mutateUploadImage, data: imageData } = useMutation({
    mutationFn: uploadImage,
  });

  const formik = useFormik({
    initialValues: {
      nomor: "",
      expired: toDate,
      file: "",
    },
    validationSchema,
    onSubmit: (value) => {
      mutateSubmitSIAOrSIPA({
        nomor: value.nomor,
        expired: value.expired,
        file: value.file,
        type: "sipa",
      });
    },
  });

  const handleUploadImage = (e) => {
    mutateUploadImage(e.target.files[0]);
  };

  useEffect(() => {
    formik.setFieldValue("file", imageData);
  }, [formik.values.file, imageData]);

  return (
    <>
      {open ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-[5%]">
                <p className="text-center text-[32px] text-[#68A1F9]">
                  Perbarui SIPA
                </p>
                {/*body*/}
                <div className="flex justify-center relative flex-auto object-contain border-t border-solid border-slate-200"></div>
                <div className="flex flex-column text-center font-semibold my-[10px]">
                  <p>SIPA anda sudah kadaluarsa</p>
                  <p>Mohon perbarui SIPA Anda ke yang baru</p>
                </div>

                <div className="flex justify-center flex-column">
                  <TextField
                    fullWidth
                    label="No SIPA"
                    sx={{ marginBottom: 2 }}
                    onChange={(e) =>
                      formik.setFieldValue("nomor", e.target.value)
                    }
                  />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      fullWidth
                      onChange={(newExpiredDate) =>
                        formik.setFieldValue(
                          "expired",
                          toDateApi(newExpiredDate)
                        )
                      }
                      value={formik.values.expired}
                      renderInput={(params) => {
                        return <TextField {...params} />;
                      }}
                      disablePast
                    />
                  </LocalizationProvider>
                </div>
                <div className="input-foto">
                  <div className="bg-blue-500 p-2 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Foto Produk
                  </div>

                  <div className="flex items-center justify-center h-25% border-2 border-solid">
                    {formik.values.file !== "" ? (
                      <label
                        htmlFor="drop"
                        className="cursor-pointer flex flex-col items-center justify-center w-[30%]"
                      >
                        <div className="relative">
                          <img
                            src={formik.values.file}
                            alt="foto produk"
                            className="h-[20%] rounded-lg shadow-md"
                          />
                        </div>
                        <input
                          id="drop"
                          type="file"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </label>
                    ) : (
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-col items-center justify-center w-[100%] p-6"
                      >
                        <input
                          id="dropzone-file1"
                          hidden
                          type="file"
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                        <div className="flex justify-center items-center flex-col text-center">
                          <FileUploadIcon sx={{ textAlign: "center" }} />
                          <p>Unggah SIA terbaru</p>
                        </div>
                      </label>
                    )}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center p-6 gap-3  rounded-b">
                  <button
                    className="bg-white text-black border-2 border-[#838383] font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-[#007AF1] text-white font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </form>
      ) : null}
    </>
  );
};

export default ModalSIPA;
