import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";

import {
  getPlatform,
  fetchUpdateProduct,
  updateStocksPrice,
} from "../../../../services/InventoryAPI";
import {
  getConsignmentDetailProduct,
  setUpdateProductConsignment,
} from "../../../../services/consignmentApi";
import { useFormik } from "formik";

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const EditPriceConsignmentProduct = () => {
  const { params } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const invoiceNum = atob(params.split(":")[0]);
  const id = atob(params.split(":")[1]);

  const { data } = useQuery({
    queryKey: ["consignment-product"],
    queryFn: () => getConsignmentDetailProduct(id, invoiceNum),
  });

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    keepPreviousData: true,
    queryFn: () => getPlatform(),
  });

  const submitValue = (values) => {
    if (values.price.price_grosir !== null)
      values.price.price_grosir = values.price.price_grosir.filter(
        (item) => item.minimum > 0 && item.maximum > 0 && item.price_grosirs > 0
      );
    mutateUpdateProduct(values);
  };

  const tempDetail = useFormik({
    initialValues: {
      product_id: Number(id),
      nie_number: data && data !== undefined ? data.nie_number : "",
      product_name: data && data !== undefined ? data.product_name : "",
      product_image: data && data !== undefined ? data.media : [],
      product_description: {
        indication: data && data !== null ? data.description.indication : "",
        composition: data && data !== null ? data.description.composition : "",
        dosage: data && data !== null ? data.description.dosage : "-",
        usage: data && data !== null ? data.description.usage : "-",
        contraindication:
          data && data !== null ? data.description.contraindication : "",
        side_effect: data && data !== null ? data.description.side_effect : "",
        storage_description:
          data && data !== null ? data.description.storage_description : "",
        require_prescription:
          data && data !== undefined
            ? data.description.require_prescription
            : false,
      },
      packaging: {
        type_box: data && data !== undefined ? data.packing?.type_box : "",

        total_box: data && data !== undefined ? data.packing?.total_box : 1,

        type_strip: data && data !== undefined ? data.packing?.type_strip : "",

        total_strip: data && data !== undefined ? data.packing?.total_strip : 1,

        type_unit: data && data !== undefined ? data.packing?.type_unit : "",

        total_unit: data && data !== undefined ? data.packing?.total_unit : 1,
      },
      category_id: data && data !== undefined ? data.product_category_id : [],
      principal_id: 0,
      label_id: 0,
      status_reminder_stock: false,
      reminder_stock: 0,
      price: {
        platform_price: data && data !== undefined ? data.platform_price : [],
        pharmacy_net_price: data && data !== undefined ? data.hna : 0,
        sell_price: data && data !== undefined ? data.price : 0,
        sell_price_sekunder:
          data && data !== undefined ? data.price_sekunder : 0,
        sell_price_tersier: data && data !== undefined ? data.price_tersier : 0,
        price_grosir: data && data !== undefined ? data.price_grosir : [],
      },

      barcode_number: data && data !== undefined ? data.barcode : "",
      supplier_name: data && data !== undefined ? data.supplier_name : "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      submitValue(values);
    },
  });

  const intersection = new Set(
    tempDetail.values.price.platform_price &&
    tempDetail.values.price.platform_price !== null
      ? tempDetail.values.price.platform_price.map(
          ({ platform_id }) => platform_id
        )
      : []
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined)
      tempDetail.values.price.platform_price === null
        ? tempDetail.setFieldValue("price.platform_price", [
            {
              platform_id: result[0]?.id,
              price: "",
              is_active: true,
              name: "",
            },
          ])
        : tempDetail.setFieldValue("price.platform_price", [
            ...tempDetail.values.price.platform_price,
            {
              platform_id: result[0]?.id,
              price: "",
              is_active: true,
              name: "",
            },
          ]);
    else tempDetail.setFieldValue("price.platform_price", []);
  }, [tempDetail.values.price.platform_price, result]);

  const { mutate: mutateUpdateProduct } = useMutation({
    mutationFn: setUpdateProductConsignment,
    onSuccess: () => navigate(`/inventory/consignment/product/${params}`),
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  return (
    <>
      {data && data !== null ? (
        <>
          <div className="flex flex-col flex-wrap gap-2 mt-4">
            <p className="font-bold text-[20px]">Nama Produk</p>
            <p>{data.product_name}</p>
          </div>
          <hr className="border-2 border-black my-4" />
          <form onSubmit={tempDetail.handleSubmit}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="HNA"
              disabled
              autoComplete="off"
              variant="outlined"
              value={tempDetail.values.price.pharmacy_net_price}
              className="mt-3"
              onChange={(e) => {
                let numericValue = e.target.value;
                if (numericValue === "") {
                  numericValue = "0";
                }
                tempDetail.setFieldValue(
                  "price.pharmacy_net_price",
                  parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp.</InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              fullWidth
              label="Harga Jual Primer"
              autoComplete="off"
              variant="outlined"
              value={tempDetail.values.price.sell_price}
              className="mt-3"
              onChange={(e) => {
                let numericValue = e.target.value;
                if (numericValue === "") {
                  numericValue = "0";
                }
                tempDetail.setFieldValue(
                  "price.sell_price",
                  parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp.</InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              fullWidth
              label="Harga Jual Sekunder"
              autoComplete="off"
              variant="outlined"
              value={tempDetail.values.price.sell_price_sekunder}
              className="mt-3"
              onChange={(e) => {
                let numericValue = e.target.value;
                if (numericValue === "") {
                  numericValue = "0";
                }
                tempDetail.setFieldValue(
                  "price.sell_price_sekunder",
                  parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp.</InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-basic"
              fullWidth
              label="Harga Jual Tersier"
              autoComplete="off"
              variant="outlined"
              value={tempDetail.values.price.sell_price_tersier}
              className="mt-3"
              onChange={(e) => {
                let numericValue = e.target.value;
                if (numericValue === "") {
                  numericValue = "0";
                }
                tempDetail.setFieldValue(
                  "price.sell_price_tersier",
                  parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp.</InputAdornment>
                ),
              }}
            />

            <div className="grid mt-4 items-center w-full lg:w-[calc(100vw-310px)])">
              {tempDetail.values.price.price_grosir?.map((item, i) => {
                return (
                  <div className="my-2 w-full">
                    <div className="w-full flex gap-2 items-center">
                      {i > 0 ? (
                        <div className="w-full basis-1/3"></div>
                      ) : (
                        <p className="font-bold mt-2 w-full basis-1/3">
                          Harga Grosir
                        </p>
                      )}
                      <div className="w-full flex gap-2 items-center">
                        <TextField
                          fullWidth
                          value={item.minimum === null ? 0 : item.minimum}
                          onChange={(e) =>
                            tempDetail.setFieldValue(
                              "price.price_grosir[" + i + "].minimum",
                              Number(e.target.value)
                            )
                          }
                        />
                        <p>S/D</p>
                        <TextField
                          fullWidth
                          value={item.maximum === null ? 0 : item.maximum}
                          onChange={(e) =>
                            tempDetail.setFieldValue(
                              "price.price_grosir[" + i + "].maximum",
                              Number(e.target.value)
                            )
                          }
                        />
                      </div>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                        value={
                          item.price_grosirs === null ? 0 : item.price_grosirs
                        }
                        onChange={(e) =>
                          tempDetail.setFieldValue(
                            "price.price_grosir[" + i + "].price_grosirs",
                            Number(e.target.value)
                          )
                        }
                      />
                      <button
                        type="button"
                        className="ml-[10px]"
                        onClick={() => {
                          tempDetail.setFieldValue("price.price_grosir", [
                            ...tempDetail.values.price.price_grosir.slice(0, i),
                            ...tempDetail.values.price.price_grosir.slice(
                              i + 1
                            ),
                          ]);
                        }}
                      >
                        <DeleteIcon sx={{ color: "black" }} />
                      </button>
                    </div>
                  </div>
                );
              })}
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-right w-full"
                onClick={() => {
                  tempDetail.values.price.price_grosir === null
                    ? tempDetail.setFieldValue("price.price_grosir", [
                        { minimum: null, maximum: null, price_grosirs: null },
                      ])
                    : tempDetail.setFieldValue("price.price_grosir", [
                        ...tempDetail.values.price.price_grosir,
                        { minimum: null, maximum: null, price_grosirs: null },
                      ]);
                }}
              >
                +Tambah harga grosir baru
              </p>

              <div>
                <p
                  className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                  onClick={handleAddPlatform}
                >
                  +Tambah Platform lain
                </p>
                <div>
                  {platforms &&
                  platforms !== undefined &&
                  tempDetail.values.price.platform_price?.length > 0 ? (
                    <>
                      {tempDetail.values.price.platform_price?.map(
                        (plat, index) => {
                          return (
                            <div
                              className="flex justify-center items-center mt-[20px] mb-4"
                              key={plat.platform_id}
                            >
                              <FormControl fullWidth sx={{ marginRight: 1 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Kategori
                                </InputLabel>
                                <Select
                                  id="add-category"
                                  value={plat.platform_id}
                                  onChange={(e) => {
                                    tempDetail.setFieldValue(
                                      "price.platform_price",
                                      tempDetail.values.price.platform_price.map(
                                        (p1) => {
                                          return p1.platform_id ===
                                            plat.platform_id
                                            ? {
                                                ...plat,
                                                platform_id: e.target.value,
                                              }
                                            : p1;
                                        }
                                      )
                                    );
                                  }}
                                >
                                  {platforms.map((p) => {
                                    return (
                                      <MenuItem
                                        key={p.id}
                                        value={p.id}
                                        disabled={
                                          tempDetail.values.price.platform_price.find(
                                            (f) => f.id === p.id
                                          )
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          display:
                                            tempDetail.values.price.platform_price.find(
                                              (f) => f.id === p.id
                                            )
                                              ? "none"
                                              : null,
                                        }}
                                      >
                                        {p.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Jumlah Uang
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type="text"
                                  value={plat.price}
                                  onChange={(e) =>
                                    tempDetail.setFieldValue(
                                      "price.platform_price",
                                      tempDetail.values.price.platform_price.map(
                                        (p1) => {
                                          return p1.platform_id ===
                                            plat.platform_id
                                            ? {
                                                ...plat,
                                                price: Number(e.target.value),
                                              }
                                            : p1;
                                        }
                                      )
                                    )
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={() => {
                                          if (
                                            tempDetail.values.price
                                              .platform_price.length === 1
                                          )
                                            tempDetail.setFieldValue(
                                              "price.platform_price",
                                              []
                                            );
                                          else
                                            tempDetail.setFieldValue(
                                              "price.platform_price",
                                              [
                                                ...tempDetail.values.price.platform_price.slice(
                                                  0,
                                                  index
                                                ),
                                                ...tempDetail.values.price.platform_price.slice(
                                                  index + 1
                                                ),
                                              ]
                                            );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                />
                              </FormControl>
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <p className="text-red-500 my-2">{errorMessage}</p>

            <div className="grid grid-cols-2 mt-10 gap-12">
              <button
                className="hover:bg-red-800 bg-red-700 text-white font-semibold p-3 rounded-lg"
                onClick={() => navigate(-1)}
              >
                Batal
              </button>
              <button
                className="hover:bg-orange-600 bg-orange-400 text-white font-semibold p-3 rounded-lg"
                type="submit"
              >
                Konfirmasi
              </button>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
};

export default EditPriceConsignmentProduct;
