import {
  Checkbox,
  Button,
  Divider,
  Snackbar,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { cloneDeep } from "lodash";

import BillingNavigation from "./BillingNavigation";
import {
  getBillingList,
  setPaymentBilling,
} from "../../../services/billingAPI";
import logoOlin from "../../../assets/icons/logoOlin.png";
import BillingBreadcrumb from "./BillingBreadcrumb";
import MultiplePaymentButton from "./MultiplePaymentButton";
import ModalPaymentOption from "../../common/particles.jsx/ModalPaymentOption";

const FETCH_LIMIT = 10;

const UnpaidBilling = () => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [billArr, setBillArr] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [offset, setOffset] = useState(1);

  const { data, isFetching } = useQuery({
    queryFn: () => getBillingList(1, FETCH_LIMIT, offset),
    queryKey: ["billing-unpaid"],
  });

  const createBill = () => {
    if (paymentOption !== null) {
      const body = {
        billings: billArr,
        handling_fee: paymentOption.bank_fee,
        total_price:
          billArr.reduce((acc, curVal) => acc + curVal.price, 0) +
          paymentOption.bank_fee,
        payment: {
          pg_name: paymentOption.pg_name,
          pg_bank: paymentOption.pg_bank,
          pg_code: paymentOption.pg_code,
          pg_type: paymentOption.pg_type,
        },
      };
      mutateBill(body);
    }
  };

  const handleSelectAll = (checked) => {
    var tempArr = [];

    if (checked) {
      data.detail.bills.map((bill) => {
        var temp = { bill_id: bill.bills_id[0], price: bill.amount };
        tempArr.push(temp);
      });
    }
    setBillArr(tempArr);
    setSelectAll(checked);
  };

  const {
    mutate: mutateBill,
    isLoading,
    data: dataBill,
  } = useMutation({
    mutationFn: setPaymentBilling,
    onSuccess: () => {
      setOpenModalPayment(false);
    },
  });

  const handleSelectList = useCallback(
    (billId, price) => {
      const index = billArr.findIndex((item) => item.bill_id === billId);
      let updatedList = cloneDeep(billArr);

      if (index !== -1) {
        updatedList.splice(index, 1);
      } else {
        updatedList.push({ bill_id: billId, price: price });
      }
      setBillArr(updatedList);
      updatedList.length !== data?.detail.bills.length
        ? setSelectAll(false)
        : setSelectAll(true);
    },
    [billArr]
  );

  useEffect(() => {
    if (dataBill && dataBill.va !== null)
      navigate(
        `/billing/waiting-payment/payment-summary/${btoa(dataBill.payment.va)}`
      );
  }, [dataBill]);

  useEffect(() => {
    if (paymentOption !== null) createBill();
  }, [paymentOption]);

  useEffect(() => {
    data &&
    data.detail &&
    data.detail.bills &&
    data.detail.bills !== null &&
    billArr &&
    billArr.length !== data.detail.bills.length
      ? setSelectAll(false)
      : setSelectAll(true);
  }, [billArr]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <BillingBreadcrumb />
      <BillingNavigation />
      <div
        className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full hover:bg-gray-100 cursor-pointer"
        onClick={() => navigate("/billing/waiting-payment")}
      >
        <p className="font-bold">Menunggu Pembayaran</p>
        <div className="flex items-center">
          <p className="bg-red-500 rounded-full w-8 h-8 text-white p-2 flex justify-center items-center">
            {data && data.detail && data.detail !== null ? (
              data.detail.info.total_waiting_payment
            ) : !isFetching && data.detail === null ? (
              "-"
            ) : (
              <CircularProgress size={12} color="inherit" />
            )}
          </p>
          <div className="w-8 h-8">
            <ChevronRight sx={{ height: "100%", width: "100% " }} />
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>
      <div className="mb-24">
        {data &&
        data.detail &&
        data.detail !== null &&
        data.detail.bills !== null ? (
          <>
            <div className="flex items-center">
              <Checkbox
                sx={{ paddingX: 0, paddingY: 2 }}
                onChange={(e, checked) => handleSelectAll(checked)}
                checked={selectAll}
              />
              <p className="font-bold ml-4">Pilih Semua</p>
            </div>
            <p>
              Total {data.detail.info.total}
              <span className="text-red-500"> Belum Dibayar Bill</span>, Total
              Amount{" "}
              <span className="text-blue-500">
                {data.detail.info.amount_all.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </span>
            </p>
            {data.detail.bills.map((item) => {
              return (
                <div
                  className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full"
                  key={item.transactions_id[0]}
                >
                  <div className="flex items-center w-full">
                    <div className="w-fit px-0 md:px-4">
                      <input
                        id={item.transactions_id[0]}
                        type="checkbox"
                        name={item.transactions_id[0]}
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2 "
                        checked={billArr.find(
                          (bill) => bill.bill_id === item.bills_id[0]
                        )}
                        onChange={() =>
                          handleSelectList(item.bills_id[0], item.amount)
                        }
                      />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center py-2">
                        <p className="w-full">
                          Jatuh tempo{" "}
                          {moment(item.due_date).format("DD MMMM YYYY")}
                        </p>
                      </div>
                      <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                      <div className="flex my-2">
                        <img
                          src={logoOlin}
                          alt="foto_product"
                          className="w-30 rounded-lg mx-2 p-2"
                        />
                        <div className="flex justify-between w-full">
                          <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden w-full">
                            <p className="text-blue-500">
                              <Link
                                to={`/billing/unpaid/detail/${item.bills_id[0]}`}
                                className="hover:none"
                              >
                                {item.transactions_id[0]}
                              </Link>
                            </p>
                            <p className="font-bold">
                              {item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                          </div>
                          {item.tenor.total > 1 ? (
                            <div className="flex items-center">
                              <p className="inline-block mr-2">
                                {item.tenor.current}/{item.tenor.total}
                              </p>
                              <p className="w-full hidden md:inline-block whitespace-nowrap">
                                berhasil dibayar
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <Divider sx={{ marginY: 2, borderWidth: 1 }} />
                      <div className="flex w-full items-center justify-end">
                        <div className="basis-1/2 md:basis-1/4 float-right">
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              setOpenModalPayment(true);
                              setBillArr([
                                {
                                  bill_id: item.bills_id[0],
                                  price: item.amount,
                                },
                              ]);
                            }}
                          >
                            Bayar Sekarang
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : isFetching ? (
          <CircularProgress />
        ) : data && data.detail === null && data.message ? (
          <p className="text-red-500 py-4 text-center">{data.message.id}</p>
        ) : null}
      </div>

      <div className="flex justify-end">
        {data &&
        data.detail &&
        data.detail.bills?.length > 0 &&
        data.detail.info?.total > 0 ? (
          <Pagination
            count={Math.ceil(data.detail.info.total / FETCH_LIMIT)}
            page={offset}
            onChange={(_, value) => setOffset(value)}
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>

      <div
        className={`ml-0 lg:ml-[310px] fixed bottom-0 left-0 w-full lg:w-[calc(100vw-310px)] ${
          billArr.length === 0 ? "hidden" : ""
        }`}
      >
        <MultiplePaymentButton
          arr={billArr.length}
          total={
            paymentOption
              ? billArr.reduce((acc, curVal) => acc + curVal.price, 0) +
                paymentOption.bank_fee
              : billArr.reduce((acc, curVal) => acc + curVal.price, 0)
          }
          setPaymentOption={setPaymentOption}
        />
      </div>

      <Snackbar
        open={isCopied}
        onClose={() => setIsCopied(false)}
        message={"Virtual Account Number Copied"}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />

      <ModalPaymentOption
        open={openModalPayment}
        setOpen={setOpenModalPayment}
        setPaymentOption={setPaymentOption}
        isLoading={isLoading}
      />
    </div>
  );
};

export default UnpaidBilling;
