import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import {
  MdCheckCircleOutline,
  MdOutlineCancel,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { getWarehouseInboxList } from "../../../../services/centralWarehouseApi";
import useDebounce from "../../../hooks/useDebounce";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import ModalChangeStatusWarehouse from "./ModalChangeStatusWarehouse";
import { RxCross1 } from "react-icons/rx";

const TRANSACTION_TYPE = [
  { id: null, name: "All" },
  { id: 1, name: "Allocation" },
  { id: 2, name: "Transfer" },
  { id: 3, name: "Send to Warehouse" },
  { id: 4, name: "Request Allocation" },
  { id: 5, name: "Request Transfer" },
];

const STATUS_TYPE = [
  { id: null, name: "All" },
  { id: 1, name: "In Progress" },
  { id: 2, name: "Accepted" },
  { id: 3, name: "Rejected" },
];

const INITIAL_REQ = {
  start_date: "2012-01-01",
  end_date: moment().endOf("year").format("YYYY-MM-DD"),
  limit: 10,
  offset: 1,
  transaction_type: [],
  source: [],
  status: [],
};

const InboxWarehouseList = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [search, setSearch] = useState("");
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElSubfilter, setAnchorElSubfilter] = useState(null);
  const [anchorElSource, setAnchorElSource] = useState(null);
  const [typeSubfilter, setTypeSubfilter] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [action, setAction] = useState(0);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [sourceArr, setSourceArr] = useState([]);

  const debounce = useDebounce(search, 500);

  const isItemIncluded = (id) => {
    return typeSubfilter === "transaction"
      ? reqBody.transaction_type.includes(id)
      : typeSubfilter === "status"
      ? reqBody.status.includes(id)
      : // : reqBody.source.map((item) => item.id).includes(id);
        false;
  };

  const getTypeFromString = (str) => {
    return str === "Allocation"
      ? 1
      : str === "Transfer"
      ? 2
      : str === "Return To Warehouse"
      ? 3
      : str === "Request Allocation"
      ? 4
      : str === "Request Transfer"
      ? 5
      : null;
  };

  const handleChangeStatus = (newStatus) => {
    const index = reqBody.status.findIndex((item) => item === newStatus);
    const tempStatus =
      newStatus === null
        ? []
        : index < 0
        ? [...reqBody.status, newStatus]
        : [
            ...reqBody.status.slice(0, index),
            ...reqBody.status.slice(index + 1),
          ];
    setReqBody((prevValue) => ({
      ...prevValue,
      status: tempStatus,
      offset: 1,
    }));
  };

  const handleChangeTransaction = (newVal) => {
    const index = reqBody.transaction_type.findIndex((item) => item === newVal);
    const tempTransaction =
      newVal === null
        ? []
        : index < 0
        ? [...reqBody.transaction_type, newVal]
        : [
            ...reqBody.transaction_type.slice(0, index),
            ...reqBody.transaction_type.slice(index + 1),
          ];
    setReqBody((prevValue) => ({
      ...prevValue,
      offset: 1,
      transaction_type: tempTransaction,
    }));
  };

  const {
    mutate: mutateList,
    isLoading,
    data: dataList,
  } = useMutation({
    mutationFn: getWarehouseInboxList,
  });

  useEffect(() => {
    mutateList({ ...reqBody, type: type, name: debounce });
  }, [reqBody, debounce, location.pathname]);

  useEffect(() => {
    setReqBody(INITIAL_REQ);
  }, [location.pathname]);

  useEffect(() => {
    setReqBody((prevValue) => ({ ...prevValue, offset: 1 }));
  }, [debounce]);

  return (
    <>
      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
          <p>Filter</p>
          <FaChevronDown />
        </div>
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="py-2 text-center">No</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction ID
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">Date</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction Type
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">From</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">To</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">User</th>
                <th className="py-2 text-center">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.request ? (
                  dataList.request.map((item, i) => (
                    <tr key={i}>
                      <td className="px-3 py-2 border text-center">{i + 1}</td>
                      <td
                        className="px-3 py-2 border text-left text-blue-500 hover:underline hover:text-blue-700 cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/central-warehouse/inbox/${
                              type === 1 ? "warehouse" : "outlet"
                            }/detail/${item.id}-${getTypeFromString(
                              item.request
                            )}`
                          )
                        }
                      >
                        {item.transaction_number}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {moment(item.date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {
                          TRANSACTION_TYPE.find(
                            (trx) => trx.id === item.transaction_type
                          )?.name
                        }
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {item.source}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {item.target}
                      </td>
                      <td className="px-3 py-2 border text-left">
                        {item.created_by}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        {item.status === 1 ? (
                          <div className="flex gap-2 items-center justify-center">
                            <MdOutlineCancel
                              className="text-[18px] text-red-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setSelectedRequest(item);
                                setOpenChangeStatus(true);
                                setAction(2);
                              }}
                            />
                            <MdCheckCircleOutline
                              className="text-[18px] text-blue-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setSelectedRequest(item);
                                setOpenChangeStatus(true);
                                setAction(1);
                              }}
                            />
                          </div>
                        ) : (
                          <p
                            className={`${
                              item.status === 2 || item.status === 4
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {item.status === 2 || item.status === 4
                              ? "Accepted"
                              : "Rejected"}
                          </p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-3 py-2 border text-center" colSpan={8}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Data tidak ditemukan"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              onClick={() =>
                setReqBody((prevValue) => ({
                  ...prevValue,
                  offset: 1,
                  status: [],
                  transaction_type: [],
                  source: [],
                }))
              }
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => {
            setAnchorElSubfilter(e.currentTarget);
            setTypeSubfilter("transaction");
          }}
        >
          <p>Transaction Type</p>
          <FaChevronRight />
        </div>
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => {
            setAnchorElSubfilter(e.currentTarget);
            setTypeSubfilter("status");
          }}
        >
          <p>Status</p>
          <FaChevronRight />
        </div>
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
            onClick={(e) => {
              setAnchorElSource(e.currentTarget);
            }}
          >
            <p>Source</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {reqBody.source.length > 0 ? (
              reqBody.source.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      const index = reqBody.source.findIndex(
                        (source) => source == item
                      );
                      setReqBody((prevValue) => ({
                        ...prevValue,
                        source: [
                          ...reqBody.source.slice(0, index),
                          ...reqBody.source.slice(index + 1),
                        ],
                      }));
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No source selected</p>
            )}
          </div>
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElSubfilter)}
        anchorEl={anchorElSubfilter}
        onClose={() => setAnchorElSubfilter(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElSubfilter(null)}
            />
            <p className="font-bold text-center">
              {typeSubfilter === "transaction"
                ? "Transaction Type"
                : typeSubfilter === "status"
                ? "Status"
                : ""}
            </p>
          </div>
        </div>
        <hr />
        {(typeSubfilter === "transaction" && type === 1
          ? TRANSACTION_TYPE.filter(
              (item) => item.id === null || item.id === 3 || item.id === 4
            )
          : typeSubfilter === "transaction" && type === 2
          ? TRANSACTION_TYPE.filter(
              (item) => (item.id === null) | (item.id < 3) || item.id > 4
            )
          : typeSubfilter === "status"
          ? STATUS_TYPE
          : []
        ).map((item, i) => (
          <div
            className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
              i <
              (typeSubfilter === "transaction"
                ? TRANSACTION_TYPE
                : typeSubfilter === "status"
                ? STATUS_TYPE
                : []
              ).length -
                1
                ? "border-b-2"
                : ""
            } ${isItemIncluded(item.id) ? "bg-gray-200" : ""}`}
            onClick={() =>
              typeSubfilter === "transaction"
                ? handleChangeTransaction(item.id)
                : typeSubfilter === "status"
                ? handleChangeStatus(item.id)
                : null
            }
          >
            {item.name}
          </div>
        ))}
      </Popover>

      <Popover
        open={Boolean(anchorElSource)}
        anchorEl={anchorElSource}
        onClose={() => setAnchorElSource(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElSource(null)}
            />
            <p className="font-bold text-center">Source</p>
          </div>
        </div>
        <hr />
        {dataList && !isLoading && dataList.source ? (
          dataList.source.map((item, i) => (
            <div
              className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
                i < dataList.source.length - 1 ? "border-b-2" : ""
              } ${reqBody.source.includes(item) ? "bg-gray-200" : ""}`}
              onClick={() => {
                const index = reqBody.source.findIndex(
                  (source) => source === item
                );
                setReqBody((prevValue) => ({
                  ...prevValue,
                  source:
                    index < 0
                      ? [...reqBody.source, item]
                      : [
                          ...reqBody.source.slice(0, index),
                          ...reqBody.source.slice(index + 1),
                        ],
                }));
              }}
            >
              {item.name}
            </div>
          ))
        ) : (
          <div className="px-8 py-2">
            {isLoading ? <CircularProgress size={20} /> : "No source"}
          </div>
        )}
      </Popover>

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
        resetFunc={() =>
          setReqBody((prevValue) => ({
            ...prevValue,
            offset: 1,
            start_date: INITIAL_REQ.start_date,
            end_date: INITIAL_REQ.end_date,
          }))
        }
      />

      <ModalChangeStatusWarehouse
        open={openChangeStatus}
        setOpen={setOpenChangeStatus}
        item={selectedRequest}
        refetch={() => {
          mutateList({ ...reqBody, type: type, name: debounce });
        }}
        type={
          selectedRequest ? getTypeFromString(selectedRequest.request) : null
        }
        action={action}
      />
    </>
  );
};

export default InboxWarehouseList;
