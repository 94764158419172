import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { importPO } from "../../../services/billingAPI";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { BsUpload } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";
import { Close as CloseIcon } from "@mui/icons-material";
import { getPoList } from "../../../services/purchaseOrderApi";

const FETCH_LIMIT = 10000;

const ImportBillingModal = ({ setOpenModal, openModal, isWarehouse }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPO, setSelectedPO] = useState(null);
  const [openPO, setOpenPO] = useState(false);
  const [searchPO, setSearchPO] = useState("");
  const [xlsxFileExisting, setXlsxFileExisting] = useState(null);

  const {
    mutate: mutatePoList,
    data,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  const { mutate: mutateImport, isFetching: isLoadingImport } = useMutation({
    mutationFn: importPO,
    onSuccess: () => {
      setOpenModal(false);
      setXlsxFileExisting(null);
      setSelectedPO(null);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (openModal) {
      const body = {
        search: "",
        limit: FETCH_LIMIT,
        offset: 1,
        type: 4,
        sort: 0,
        start_date: "",
        end_date: "",
        warehouse: isWarehouse ? isWarehouse : false,
      };
      mutatePoList(body);
    }
  }, [openModal]);

  useEffect(() => {
    setErrorMessage("");
  }, [xlsxFileExisting]);

  return (
    <>
      {openModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-8 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="p-4 w-full">
                  <p className="text-center font-medium text-[30px] mb-3">
                    Import Billing
                  </p>
                  <p>Pilih Nomor PO yang ingin diperbarui</p>
                  <Autocomplete
                    fullWidth
                    loading={isLoading}
                    open={openPO}
                    onOpen={() => setOpenPO(true)}
                    onClose={() => setOpenPO(false)}
                    disablePortal
                    options={data && data.data !== null ? data.data : []}
                    value={selectedPO}
                    onChange={(_, inputValue) => {
                      setSelectedPO(inputValue);
                    }}
                    onInputChange={(_, onInputValue, reason) => {
                      if (reason === "input") {
                        setSearchPO(onInputValue);
                      }
                    }}
                    getOptionLabel={(option) => option.po_number || ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.po_number}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                {xlsxFileExisting !== null ? (
                  <div className="w-full flex justify-center">
                    <div className="cursor-pointer flex flex-col items-center justify-center h-64 w-[47%] border-2 rounded-xl">
                      <div className="flex flex-col items-center justify-center p-5">
                        <div className="text-right">
                          <button
                            onClick={() => setXlsxFileExisting(null)}
                            className="bg-[#F21B3F] p-1 rounded-full"
                          >
                            <CloseIcon sx={{ color: "white" }} />
                          </button>
                          <BiNotepad className="text-[90px] text-blue-500" />
                        </div>
                        <p className="font-medium text-l text-blue-400 mt-3">
                          {xlsxFileExisting !== null
                            ? xlsxFileExisting.name
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center transform duration-200 hover:scale-105">
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-col items-center justify-center h-64 border-2 rounded-xl"
                    >
                      <div className="flex flex-col items-center justify-center p-5">
                        <BsUpload className="text-[90px] text-blue-500" />
                        <p className="font-medium text-l text-blue-400 mt-3">
                          Update Existing PO
                        </p>
                        <p className="text-center">
                          Saya sudah memiliki berkas <br /> Excel dengan data PO
                          yang siap diunggah
                        </p>
                      </div>
                      <input
                        id="dropzone-file1"
                        type="file"
                        hidden
                        accept=".xlsx"
                        onChange={(e) => setXlsxFileExisting(e.target.files[0])}
                      />
                    </label>
                  </div>
                )}

                <p className="text-center text-red-500 mb-4 mx-4">
                  {errorMessage}
                </p>

                <div className="flex items-center justify-center p-2 gap-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white hover:bg-red-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() => {
                      setErrorMessage("");
                      setSelectedPO(null);
                      setXlsxFileExisting(null);
                      setOpenModal(false);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    className="disabled:bg-gray-300 bg-orange-400 text-white hover:bg-orange-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() =>
                      mutateImport({
                        file: xlsxFileExisting,
                        id: selectedPO.id,
                        warehouse: isWarehouse ? isWarehouse : false,
                      })
                    }
                    disabled={
                      selectedPO === null ||
                      xlsxFileExisting === null ||
                      isLoadingImport
                    }
                  >
                    {isLoadingImport ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Konfirmasi"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ImportBillingModal;
