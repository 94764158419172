const NotificationAlertStatusCode = (props) => {
  const { statusCode, setOpenSIA, setOpenSIPA } = props;
  return (
    <>
      {statusCode === 1 ? (
        <div className="flex flex-row justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            Akun anda masih menunggu verifikasi, Anda tidak bisa menggunakan
            fitur purchase sampai akun anda di verifikasi
          </p>
        </div>
      ) : null}

      {statusCode === 2 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            Pengajuan akun Anda ditolak. Silahkan cek alasan ditolak di Detail
            Informasi Akun
          </p>
          <button className="px-10 py-2 bg-[#FF8D40] rounded-lg text-white font-semibold">
            Detail Informasi Akun
          </button>
        </div>
      ) : null}

      {statusCode === 4 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            akun ini tidak bisa melakukan purchase
          </p>
        </div>
      ) : null}

      {statusCode === 6 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            SIA Anda sudah kedaluwarsa, harap perbarui SIA Anda
          </p>
          <button
            className="px-10 py-2 bg-[#FF8D40] rounded-lg text-white font-semibold"
            onClick={() => setOpenSIA(true)}
          >
            Perbarui SIA
          </button>
        </div>
      ) : null}

      {statusCode === 7 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            SIPA Anda sudah kedaluwarsa, harap perbarui SIPA Anda
          </p>
          <button
            className="px-10 py-2 bg-[#FF8D40] rounded-lg text-white font-semibold"
            onClick={() => setOpenSIPA(true)}
          >
            Perbarui SIPA
          </button>
        </div>
      ) : null}

      {statusCode === 8 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[14px] font-bold flex items-center">
            Akun OLIN Anda sedang tangguhkan oleh MPI yang berati Anda tidak
            dapat mengakses fitur pembelian
          </p>
        </div>
      ) : null}

      {statusCode === 9 ? (
        <div className="flex flex-row flex-wrap justify-between p-2 bg-[#FBB7C2] rounded-lg mt-[10px]">
          <p className="text-[16px] font-bold flex items-center">
            Akun OLIN Anda sedang tangguhkan oleh MPI yang berati Anda tidak
            dapat mengakses fitur pembelian, harap melakukan pembayaran billing
            agar Anda dapat terus menggunakan aplikasi OLIN
          </p>
          <button className="px-10 py-2 bg-[#FF8D40] rounded-lg text-white font-semibold">
            Lunasi Billing
          </button>
        </div>
      ) : null}
    </>
  );
};

export default NotificationAlertStatusCode;
