import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Redux
import store from "./redux/store";
import { Provider } from "react-redux";

// React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ProSidebarProvider>
              <App />
            </ProSidebarProvider>
          </Router>
        </QueryClientProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);

// DISABLED ON: 8/11/24
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     const waitingWorker = registration.waiting;

//     if (waitingWorker) {
//       waitingWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//     window.location.reload();
//   },
// });

// Check every 1h for service worker and unregister
setInterval(() => {
  navigator.serviceWorker.getRegistration().then((reg) => {
    console.log("service worker status: ", reg);
    if (reg) reg.unregister();
  });
}, 3600000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
