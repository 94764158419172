import { useState, useContext } from "react";
import { ListItemButton, Collapse } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

import logoOlin from "../../../../../assets/icons/logoOlin.png";
import { PaymentContext } from "../PurchasePayment";

const QrisPaymentMethod = () => {
  const { paymentDetailData, mutatePaymentCheck, invoice, formattedTime } =
    useContext(PaymentContext);

  const [isPaymentInstructionOpen, setIsPaymentInstructionOpen] = useState([
    false,
    false,
  ]);

  return (
    <div>
      {paymentDetailData && paymentDetailData !== undefined ? (
        <>
          <p className="text-[48px] font-medium text-center">
            Status Pembayaran
          </p>
          <div className="flex items-center justify-center w-full">
            <img src={logoOlin} className="w-[100px] h-auto bg-cover my-3" />
          </div>
          <div className="flex justify-center w-full">
            <img
              src={paymentDetailData.qris}
              className="w-[400px] h-auto bg-cover"
            />
            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <p>Pembayaran Via:</p>
                <img
                  src={paymentDetailData.logo}
                  className="rounded-md w-[100px] h-auto bg-cover my-3"
                />
              </div>
              <div className="flex justify-between items-center w-full">
                <p>Total Pembayaran</p>
                <p>
                  {paymentDetailData.total
                    ? paymentDetailData.total.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                    : 0}
                </p>
              </div>
              <div className="flex justify-between items-center w-full mt-3">
                <p>Jatuh Tempo</p>
                <p className="text-[#F21B3F]">{formattedTime}</p>
              </div>
              <div>
                <p className="text-[20px] font-bold my-3">
                  Instruksi Pembayaran
                </p>
                {paymentDetailData.data && paymentDetailData.data !== undefined
                  ? paymentDetailData.data.map((trx, index) => (
                      <div
                        className="w-full border border-black mb-3 rounded-md"
                        key={index}
                      >
                        <ListItemButton
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            setIsPaymentInstructionOpen((prevBool) => {
                              const newBoolArray = [...prevBool];
                              newBoolArray[index] = !newBoolArray[index];
                              return newBoolArray;
                            })
                          }
                        >
                          <p>{trx.source}</p>
                          <KeyboardArrowDownIcon />
                        </ListItemButton>
                        <Collapse
                          in={isPaymentInstructionOpen[index]}
                          unmountOnExit
                          sx={{ fontWeight: "bold", padding: 2 }}
                        >
                          {trx.instruction.map((instruction, idx) => (
                            <p className="mb-3" key={idx}>
                              {instruction}
                            </p>
                          ))}
                        </Collapse>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center">
              <div className="fixed bottom-0 z-20 bg-white p-3 rounded-md w-full h-[10%] drop-shadow-lg">
                <div className="flex justify-center w-full">
                  <button
                    className="flex justify-center items-center gap-2 text-center bg-[#007AF1] w-[77%] p-1 rounded-lg text-white hover:shadow-md"
                    onClick={() => mutatePaymentCheck({ invoice: invoice })}
                  >
                    Cek Status Pembayaran
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default QrisPaymentMethod;
