import {
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  getPatientCareCategory,
  getPatientCareDoctor,
} from "../../../../services/patientCare";
import {
  FaChevronDown,
  FaRegEdit,
  FaRegTrashAlt,
  FaUser,
} from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import useDebounce from "../../../hooks/useDebounce";
import { Search } from "@mui/icons-material";
import { RxCross1 } from "react-icons/rx";

const DetailService = ({
  formik,
  setOpen,
  setView,
  isLoadingAdd,
  setIsEdit,
  setEditCategory,
  setOpenDeleteCat,
  isEditService,
  service,
}) => {
  const [anchorElCategory, setAnchorElCategory] = useState(false);
  const [anchorElDoctor, setAnchorElDoctor] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [doctorSearch, setDoctorSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const openCategory = Boolean(anchorElCategory);
  const openDoctor = Boolean(anchorElDoctor);
  const debounceCategory = useDebounce(categorySearch, 500);
  const debounceDoctor = useDebounce(doctorSearch, 500);

  const {
    mutate: mutateCategoryList,
    data: dataCategory,
    isLoading: isLoadingCategory,
  } = useMutation({
    mutationFn: getPatientCareCategory,
  });

  const {
    mutate: mutateDoctorList,
    data: dataDoctor,
    isLoading: isLoadingDoctor,
  } = useMutation({
    mutationFn: getPatientCareDoctor,
  });

  useEffect(() => {
    if (anchorElCategory)
      mutateCategoryList({
        search: debounceCategory,
        limit: 10,
        offset: 1,
      });
  }, [debounceCategory, anchorElCategory]);

  useEffect(() => {
    if (anchorElDoctor)
      mutateDoctorList({
        search: debounceDoctor,
        limit: 10,
        offset: 1,
      });
  }, [debounceDoctor, anchorElDoctor]);

  return (
    <>
      <div className="px-8 pt-8">
        <div className="flex justify-between items-center">
          <p className="font-bold text-lg">
            {isEditService ? "Edit" : "Detail"} Service
          </p>
          <RxCross1
            className="hover:text-red-500 cursor-pointer w-[14px] h-full"
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
      <hr className="my-4" />
      <form onSubmit={formik.handleSubmit}>
        <div className="px-8">
          <p className="text-xs text-gray-500">
            Service Name <span className="text-red-500"> *</span>
          </p>
          <TextField
            fullWidth
            disabled={!isEditService}
            variant="standard"
            sx={{ marginBottom: 2 }}
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
          />
          <div className="flex gap-4 items-center mb-4">
            <div className="w-full h-full">
              <div
                className="flex justify-betwen items-center cursor-pointer"
                onClick={(e) => {
                  if (isEditService) setAnchorElCategory(e.currentTarget);
                }}
              >
                <p className="text-xs w-full py-3">
                  {selectedCategory ? selectedCategory.name : service.category}
                </p>
                <FaChevronDown className="text-gray-500" />
                <Popper
                  disablePortal
                  style={{ width: anchorElCategory?.clientWidth }}
                  open={openCategory}
                  anchorEl={anchorElCategory}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ width: "100%", zIndex: 1500 }}
                >
                  <ClickAwayListener
                    onClickAway={() => setAnchorElCategory(null)}
                  >
                    <div className="w-full bg-white rounded-b-md shadow-md max-h-[30vh] overflow-y-auto">
                      <TextField
                        fullWidth
                        inputProps={{ style: { padding: 12 } }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ padding: 2 }}
                        placeholder="Find Category"
                        value={categorySearch}
                        onChange={(e) => setCategorySearch(e.target.value)}
                      />
                      <hr className="" />
                      {dataCategory &&
                      !isLoadingCategory &&
                      dataCategory.count > 0 ? (
                        dataCategory.category.map((item) => {
                          return (
                            <div
                              className={`w-full py-2 px-8 border-b-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 ${
                                selectedCategory &&
                                selectedCategory.id === item.id
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                            >
                              <p
                                className="w-full"
                                onClick={() => {
                                  formik.setFieldValue("category_id", item.id);
                                  setSelectedCategory(item);
                                  setAnchorElCategory(null);
                                }}
                              >
                                {item.name}
                              </p>{" "}
                              <div className="flex items-center gap-2">
                                <FaRegTrashAlt
                                  className="text-red-500 cursor-pointer hover:text-gray-300"
                                  onClick={() => {
                                    formik.setFieldValue("category_id", 0);
                                    setSelectedCategory(null);
                                    setAnchorElCategory(null);
                                    setEditCategory(item);
                                    setOpenDeleteCat(true);
                                  }}
                                />
                                <FaRegEdit
                                  className="text-blue-500 cursor-pointer hover:text-gray-300"
                                  onClick={() => {
                                    setView("category");
                                    setIsEdit(true);
                                    setEditCategory(item);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : isLoadingCategory ? (
                        <div className="flex justify-center py-2">
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <div className="flex justify-center py-2 text-gray-300">
                          Category not found
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div>
              <hr className="" />
            </div>
            <button
              disabled={!isEditService}
              className="rounded-md p-2 flex justify-center items-center w-[48px] h-[48px] hover:bg-gray-300 bg-blue-500 text-white disabled:bg-gray-300"
              onClick={() => {
                setView("category");
                setIsEdit(false);
              }}
            >
              <IoMdAddCircleOutline className="w-full h-full" />
            </button>
          </div>
          <div className="flex gap-4 items-center mb-4">
            <div className="w-full h-full">
              <div
                className="flex justify-betwen items-center cursor-pointer"
                onClick={(e) => {
                  if (isEditService) setAnchorElDoctor(e.currentTarget);
                }}
              >
                <p className="text-xs w-full py-3">
                  {selectedDoctor ? selectedDoctor.name : service.doctor}
                </p>
                <FaChevronDown className="text-gray-500" />
                <Popper
                  disablePortal
                  style={{ width: anchorElDoctor?.clientWidth }}
                  open={openDoctor}
                  anchorEl={anchorElDoctor}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ width: "100%", zIndex: 1500 }}
                >
                  <ClickAwayListener
                    onClickAway={() => setAnchorElDoctor(null)}
                  >
                    <div className="w-full bg-white rounded-b-md shadow-md max-h-[30vh] overflow-y-auto">
                      <TextField
                        fullWidth
                        inputProps={{ style: { padding: 12 } }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ padding: 2 }}
                        placeholder="Find Doctor"
                        value={doctorSearch}
                        onChange={(e) => setDoctorSearch(e.target.value)}
                      />
                      <hr className="" />
                      {dataDoctor &&
                      !isLoadingDoctor &&
                      dataDoctor.count > 0 ? (
                        dataDoctor.doctor.map((item) => {
                          return (
                            <div
                              className={`w-full py-2 px-8 border-b-2 flex gap-4 items-start text-sm cursor-pointer hover:bg-gray-100 ${
                                selectedDoctor && selectedDoctor.id === item.id
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                              onClick={() => {
                                formik.setFieldValue("doctor_id", item.id);
                                setSelectedDoctor(item);
                                setAnchorElDoctor(null);
                              }}
                            >
                              <div
                                className={`w-10 h-10 rounded-sm bg-gray-300 ${
                                  item.image === "" ? "p-2" : ""
                                }`}
                              >
                                {item.image === "" ? (
                                  <FaUser className="w-full h-full text-white" />
                                ) : (
                                  <img
                                    src={item.image}
                                    className="w-full h-full rounded-sm object-cover"
                                  />
                                )}
                              </div>
                              <div className="w-full">
                                <p className="font-bold mb-3">{item.name}</p>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">Email</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.email}</p>
                                </div>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">Phone</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.phone}</p>
                                </div>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">No. SIP</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.sip === "" ? "-" : item.sip}</p>
                                </div>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">No. KTP</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.ktp === "" ? "-" : item.ktp}</p>
                                </div>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">Specialization</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.specialization}</p>
                                </div>
                                <div className="flex w-full gap-2">
                                  <p className="w-1/3">Address</p>
                                  <p className="w-fit">:</p>
                                  <p>{item.address}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : isLoadingDoctor ? (
                        <div className="flex justify-center py-2">
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <div className="flex justify-center py-2 text-gray-300">
                          Doctor not found
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                </Popper>
              </div>
              <hr className="" />
            </div>
            <button
              disabled={!isEditService}
              className="rounded-md p-2 flex justify-center items-center w-[48px] h-[48px] hover:bg-gray-300 bg-blue-500 text-white disabled:bg-gray-300"
              onClick={() => {
                setView("doctor");
                setIsEdit(false);
              }}
            >
              <IoMdAddCircleOutline className="w-full h-full" />
            </button>
          </div>
          <p className="text-xs text-gray-500">
            Service Fee <span className="text-red-500"> *</span>
          </p>
          <TextField
            fullWidth
            disabled={!isEditService}
            variant="standard"
            sx={{ marginBottom: 2 }}
            value={formik.values.fee}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                formik.setFieldValue("fee", Number(e.target.value));
            }}
          />
        </div>

        {isEditService ? (
          <div className="px-8 pb-4 w-full absolute bottom-0">
            <button
              type="submit"
              disabled={isLoadingAdd || !formik.isValid}
              className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
            >
              {isLoadingAdd ? <CircularProgress size={20} /> : <p>Save</p>}
            </button>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default DetailService;
