import { Box, CircularProgress, Modal } from "@mui/material";
import { FaChevronLeft } from "react-icons/fa";
import React from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getDetailPatient } from "../../../../services/patientCare";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDetailPatient = ({ open, setOpen, patient, setPatient }) => {
  const handleClose = () => {
    setOpen(false);
    setPatient(null);
  };

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-patient", patient],
    queryFn: () => getDetailPatient(patient.id),
    enabled: patient !== null && patient !== undefined,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "80%",
            overflowY: "auto",
            overflowX: "wrap",
            // minHeight: "80vh",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-base w-[95%] lg:w-full"
        >
          <div className="px-8 pt-4">
            <div className="grid grid-cols-3 items-center">
              <FaChevronLeft
                className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                onClick={handleClose}
              />
              <p className="text-center font-bold text-lg">Detail Pasien</p>
            </div>
          </div>
          <hr className="my-4" />
          <div className="px-8 mb-4">
            {dataDetail && !isFetching ? (
              <>
                {" "}
                <div className="flex items-center mb-8 ">
                  <div className="w-full">
                    <p className="text-lg w-full">{dataDetail.patient_name}</p>
                    {dataDetail.member ? (
                      <p className="w-fit border-[#FF8D40] border-2 rounded-md text-[#FF8D40] text-center text-sm px-4 py-1">
                        Member
                      </p>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <p className="text-gray-400">Date created</p>
                    <p>
                      {moment(dataDetail.created_at).format("DD MMMM YYYY")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-2">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">
                      Nomor Rekam Medis
                    </p>
                    <p className="w-full">: {dataDetail.medical_number}</p>
                  </div>
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">Provinsi</p>
                    <p className="w-full">: {dataDetail.province}</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-2">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">
                      Tanggal Lahir
                    </p>
                    <p className="w-full">
                      :{" "}
                      {moment(dataDetail.date_of_birth).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">Kota</p>
                    <p className="w-full">: {dataDetail.city}</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-2">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">Gender</p>
                    <p className="w-full">
                      : {dataDetail.gender === 1 ? "Laki-laki" : "Perempuan"}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">District</p>
                    <p className="w-full">: {dataDetail.district}</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-2">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">No Telepon</p>
                    <p className="w-full">
                      :{" "}
                      {dataDetail.phone_number === ""
                        ? "-"
                        : dataDetail.phone_number}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">
                      Sub District
                    </p>
                    <p className="w-full">: {dataDetail.sub_district}</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-2">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">Email</p>
                    <p className="w-full">
                      : {dataDetail.email === "" ? "-" : dataDetail.email}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">Alamat</p>
                    <p className="w-full">: {dataDetail.detail_address}</p>
                  </div>
                </div>
                <div className="flex items-center w-full">
                  <div className="w-full flex items-center">
                    <p className="w-full basis-1/2 text-gray-400">NIK</p>
                    <p className="w-full">: {dataDetail.nik}</p>
                  </div>
                  <div className="flex items-center w-full">
                    <p className="w-full basis-1/2 text-gray-400">Kode Pos</p>
                    <p className="w-full">: {dataDetail.postal_code}</p>
                  </div>
                </div>
              </>
            ) : isFetching ? (
              <div className="flex justify-center">
                <CircularProgress size={20} />
              </div>
            ) : (
              <div className="flex justify-center text-gray-300">
                Data tidak ditemukan
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalDetailPatient;
