import React from 'react';
import SidebarComponents from '../../../components/ui/SidebarComponents';
import Header from '../../../components/ui/Header';
import DetailProductExpired from '../../../components/screen/inventory/productExpired/DetailProductExpired';
import { SIDEBAR_WIDTH } from '../../../components/constants/constant';

const DetailsProductExpiredPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <DetailProductExpired />
        </div>
      </div>
    </div>
  );
};

export default DetailsProductExpiredPage;