import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import cookie from "js-cookie";
import { renewTokenUser } from "../../redux/features/auth/authSlice";

const HOC = (WrappedComponent, type = "required-auth") => {
  const Wrapper = (props) => {
    const authStore = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      if (
        !authStore.renewTokenUser &&
        type === "required-auth" &&
        !cookie.get("renewTokenOfficeOlin")
      ) {
        navigate("/login");
      }
    }, [authStore.renewTokenUser, navigate]);

    // User memiliki token
    useEffect(() => {
      if (cookie.get("renewTokenOfficeOlin") && type === "login-required") {
        navigate("/home");
      }
    }, [authStore.accessTokenOfficeOlin, navigate]);

    useEffect(() => {
      if (window.location.pathname === "/" && cookie.get("accessTokenOfficeOlin")) {
        navigate("/home");
      } else if (
        window.location.pathname === "/login" &&
        cookie.get("accessTokenOfficeOlin")
      ) {
        navigate("/home");
      } else if (
        window.location.pathname === "/login/password" &&
        cookie.get("accessTokenOfficeOlin")
      ) {
        navigate("/home");
      }
    }, [location, navigate]);

    useEffect(() => {
      if (cookie.get("renewTokenOfficeOlin") && type === "Register-required") {
        navigate("/home");
      }
    }, [authStore.accessTokenOfficeOlin, navigate]);

    // Renew Access Token
    useEffect(() => {
      if (!cookie.get("accessTokenOfficeOlin")) {
        dispatch(renewTokenUser());
      }
    }, [authStore.accessTokenOfficeOlin, dispatch]);

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};

export default HOC;
