import { useState } from "react";
import OlinLogo from "../../../assets/icons/olinlogo.png";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const CopyRecipeModal = (props) => {
  const { recipeDoctorCopyData } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  const convertToRoman = (num) => {
    const romanNumerals = [
      { value: 1000, numeral: "M" },
      { value: 900, numeral: "CM" },
      { value: 500, numeral: "D" },
      { value: 400, numeral: "CD" },
      { value: 100, numeral: "C" },
      { value: 90, numeral: "XC" },
      { value: 50, numeral: "L" },
      { value: 40, numeral: "XL" },
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 1, numeral: "I" },
    ];

    let result = "";
    for (const { value, numeral } of romanNumerals) {
      while (num >= value) {
        result += numeral;
        num -= value;
      }
    }
    return result;
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : recipeDoctorCopyData.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < recipeDoctorCopyData.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div>
      {recipeDoctorCopyData && recipeDoctorCopyData.length > 0 && (
        <div className="relative">
          <div className="border-1 border-black rounded-lg p-4 w-[400px]">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-bold text-[18px] mb-2">
                  {recipeDoctorCopyData[currentIndex]?.company_name}
                </p>
                <div className="flex text-[12px]">
                  <div>
                    <p>No. SIPA</p>
                    <p>SIA</p>
                    <p>Alamat</p>
                  </div>
                  <div className="ml-3 mr-1">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div>
                    <p className="break-words">
                      {recipeDoctorCopyData[currentIndex]?.sipa_id}
                    </p>
                    <p className="break-words">
                      {recipeDoctorCopyData[currentIndex]?.permission_letter_id}
                    </p>
                    <p className="break-words">
                      {recipeDoctorCopyData[currentIndex]?.npwp_address}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-[13px]">Powered by</p>
                <img alt="Olin Logo" src={OlinLogo} className="w-[80px]" />
              </div>
            </div>
            <div className="border-1 border-black my-2"></div>
            <div>
              <p className="text-center text-[16px] font-bold">Salinan Resep</p>
              <div className="text-[12px] flex justify-between">
                <div className="flex">
                  <div>
                    <p>No</p>
                    <p>Dari</p>
                    <p>Tanggal Resep</p>
                  </div>
                  <div className="ml-3 mr-1">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div>
                    <p>{recipeDoctorCopyData[currentIndex]?.recipe_code}</p>
                    <p>{recipeDoctorCopyData[currentIndex]?.doctor_name}</p>
                    <p>{recipeDoctorCopyData[currentIndex]?.recipe_date}</p>
                  </div>
                </div>
                <div>
                  <p>{recipeDoctorCopyData[currentIndex]?.recipe_date}</p>
                </div>
              </div>
            </div>
            <div className="my-3">
              {recipeDoctorCopyData[currentIndex]?.list_products.map(
                (data, index) => (
                  <div className="flex gap-4 items-center" key={index}>
                    <div className="w-full text-[12px] mt-2">
                      <div className="flex w-full">
                        <p className="mr-4">R/</p>
                        <div className="flex justify-between items-center w-full">
                          <div>
                            <p className="text-start">
                              {data.drug_name || "-"}
                              {data.uom ? " " + data.uom.toLowerCase() : ""}
                              {data.quantity
                                ? " no. " + convertToRoman(data.quantity)
                                : ""}{" "}
                            </p>
                            <p>S. {data.instruction_use}</p>
                          </div>
                          {data.iter_qty > 0 && (
                            <p className="text-blue-500 border-2 border-blue-500 rounded-sm px-1 text-end">
                              Iter {data.iter_qty}x
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex w-full">
                        <div className="flex justify-end ml-8 mt-2 items-center w-full border-b border-black">
                          <p>
                            - {data.det_type?.toLowerCase()}{" "}
                            {data.det_iter_qty === 0 ? "" : data.det_iter_qty}
                            {data.det_type === "Det Iter" ? "x" : ""} -
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="flex text-[12px]">
              <div>
                <p>Pro</p>
                <p>Umur</p>
              </div>
              <div className="ml-3 mr-1">
                <p>:</p>
                <p>:</p>
              </div>
              <div>
                <p>{recipeDoctorCopyData[currentIndex]?.pro}</p>
                <p>{recipeDoctorCopyData[currentIndex]?.age} Tahun</p>
              </div>
            </div>
            <div className="flex w-full justify-end">
              <div className="flex flex-col text-[12px] gap-12">
                <p>P.C.C</p>
                <p>{recipeDoctorCopyData[currentIndex]?.apoteker_name}</p>
              </div>
            </div>
          </div>

          {recipeDoctorCopyData.length > 1 && (
            <>
              <button
                onClick={handlePrev}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                aria-label="Previous"
              >
                <FaCaretLeft />
              </button>
              <button
                onClick={handleNext}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 bg-opacity-50 rounded-full hover:bg-gray-300 hover:scale-125 hover:duration-200"
                aria-label="Next"
              >
                <FaCaretRight />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CopyRecipeModal;
