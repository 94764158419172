import PatientCareServicesList from "../../components/screen/patientCare/patientCareServices/PatientCareServicesList";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const PatientCareServicesPage = () => {
  return (
    <PageTemplate
      header="Services"
      mainComponent={<PatientCareServicesList />}
    />
  );
};

export default HOC(PatientCareServicesPage);
