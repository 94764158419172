import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Snackbar,
  CircularProgress,
  IconButton,
  Backdrop,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  // Checkbox,
  // ListItemText,
  OutlinedInput,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { fetchReportingProfitnLoss } from "../../../../services/ReportingApi";
import { getMultipleOutletOption } from "../../../../services/importProductApi";
import EmptyList from "../../../ui/EmptyList";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const ReportingProfitnLoss = () => {
  const company_id = parseInt(localStorage.getItem("company_id"), 10);
  const job = localStorage.getItem("job");
  const titleId = localStorage.getItem("title");
  const [reqBody, setReqBody] = useState({
    date: null,
    company_id: [],
  });
  const [errorMessage, setErrorMessage] = useState("");

  const {
    mutate: mutateProfitnLoss,
    data: profitnLossData,
    isLoading: isProfitnLossLoading,
  } = useMutation({
    mutationFn: fetchReportingProfitnLoss,
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { data: multipleOutletOption } = useQuery({
    queryKey: ["outlet-option"],
    queryFn: () => getMultipleOutletOption(),
    onSuccess: (data) => {
      // if (data !== null) {
      //   setReqBody((prevValue) => ({
      //     ...prevValue,
      //     company_id: data
      //       .filter((item) => item.company_id === company_id)
      //       .map((item) => ({
      //         company_id: item.company_id,
      //         company_name: item.company_name,
      //       })),
      //   }));
      // } else {
      //   setReqBody((prevValue) => ({
      //     ...prevValue,
      //     company_id: [{ company_id: company_id, company_name: job }],
      //   }));
      // }

      setReqBody((prevValue) => ({
        ...prevValue,
        company_id: data.map((item) => ({
          company_id: item.company_id,
          company_name: item.company_name,
        })),
      }));
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <LoadingBackdrop isLoading={isProfitnLossLoading} />
      <div className="flex mt-3">
        {Number(titleId) === 1 && multipleOutletOption !== null ? (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Pilih Outlet</InputLabel>
            <Select
              label="Pilih Outlet"
              value={reqBody.company_id}
              onChange={(e) => {
                if (e.target.value.company_id === 0) {
                  setReqBody((prevValue) => ({
                    ...prevValue,
                    company_id: multipleOutletOption?.map((data) => data),
                  }));
                } else {
                  setReqBody((prevValue) => {
                    if (
                      prevValue.company_id
                        .map((data) => data.company_id)
                        .some((data) => data === e.target.value.company_id)
                    ) {
                      return {
                        ...prevValue,
                        company_id: prevValue.company_id.filter(
                          (data) =>
                            data.company_id === e.target.value.company_id
                        ),
                      };
                    } else {
                      return {
                        ...prevValue,
                        company_id: [...prevValue.company_id, e.target.value],
                      };
                    }
                  });
                }
              }}
              input={<OutlinedInput label="Pilih Outlet" />}
              renderValue={(selected) =>
                selected.map((data) => data.company_name).join(", ")
              }
            >
              <MenuItem value={{ company_id: 0, company_name: "Semua Outlet" }}>
                Semua Outlet
              </MenuItem>
              {multipleOutletOption && multipleOutletOption !== undefined ? (
                multipleOutletOption.map((data, index) => (
                  <MenuItem
                    key={index}
                    value={{
                      company_id: data.company_id,
                      company_name: data.company_name,
                    }}
                  >
                    {data.company_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value={0}
                  sx={{ display: "none" }}
                  disabled
                ></MenuItem>
              )}
            </Select>
          </FormControl>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Pilih Tanggal"
            onChange={(opCostDate) =>
              setReqBody((prevValue) => ({
                ...prevValue,
                date: moment(opCostDate).format("YYYY-MM-DD"),
              }))
            }
            value={reqBody.date}
            renderInput={(params) => {
              return <TextField {...params} fullWidth sx={{ marginX: 3 }} />;
            }}
            views={["month", "year"]}
          />
        </LocalizationProvider>
        <button
          className="bg-blue-500 rounded-md text-white font-bold px-5 py-3 mr-3 disabled:bg-gray-300"
          onClick={() =>
            mutateProfitnLoss({
              date: reqBody.date,
              company_id: reqBody.company_id.map((data) => data.company_id),
            })
          }
          disabled={reqBody.date === null || reqBody.company_id.length === 0}
        >
          Export
        </button>
      </div>
      {profitnLossData && profitnLossData !== undefined ? (
        <div className="flex justify-center w-full">
          <div className="rounded-md p-3 border border-black mt-3 w-[70%]">
            <p className="font-bold text-[30px] text-center mb-3">
              Profit and Loss Reporting
            </p>
            <table className="w-full">
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Penjualan</th>
                <td className="px-2 w-[30%]">:</td>
                <td>Rp. {profitnLossData.penjualan.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Diskon</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.diskon.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Biaya Racikan</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.biaya_racikan.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Biaya Pengiriman</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.biaya_pengiriman.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Biaya Admin</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.biaya_admin.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Void</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.void.toLocaleString()}</td>
              </tr>

              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Kas Masuk</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.kas_masuk.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Kas Keluar</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.kas_keluar.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2 text-[#007AF1]">Penjualan Bersih</th>
                <td className="px-2">:</td>
                <th className="text-[#007AF1]">
                  Rp. {profitnLossData.Penjualan_bersih.toLocaleString()}
                </th>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Harga Pokok Jual</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.hna.toLocaleString()}</td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2 text-[#007AF1]">Laba Rugi/Kotor</th>
                <td className="px-2">:</td>
                <td className="py-2">
                  {/*<div className="flex items-center">*/}
                  <div>
                    {profitnLossData.laba_rugi_kotor > 0 ? (
                      <p className="text-green-500 font-bold">Untung</p>
                    ) : (
                      <p className="text-red-500 font-bold">Rugi</p>
                    )}
                    <p className="mr-3 text-[#007AF1] font-bold">
                      Rp. {profitnLossData.laba_rugi_kotor.toLocaleString()}
                    </p>
                  </div>
                </td>
              </tr>
              <tr className="border-b-2 border-[#B7BEC8]">
                <th className="py-2">Pengeluaran Operational</th>
                <td className="px-2">:</td>
                <td>Rp. {profitnLossData.operational_cost.toLocaleString()}</td>
              </tr>
              <tr>
                <th className="py-2 text-[#007AF1]">Laba Rugi/Bersih</th>
                <td className="px-2">:</td>
                <th className="text-[#007AF1] ">
                  <div className="py-2">
                    {profitnLossData.laba_rugi_bersih > 0 ? (
                      <p className="text-green-500 font-bold">Untung</p>
                    ) : (
                      <p className="text-red-500 font-bold">Rugi</p>
                    )}
                    <p className="mr-3 text-[#007AF1] font-bold">
                      Rp. {profitnLossData.laba_rugi_bersih.toLocaleString()}
                    </p>
                  </div>
                </th>
              </tr>
            </table>
            {/*
            <div className="flex justify-between">
              <div className="text-center w-full">
                <p>Penjualan</p>
                <div className="border-[1.5px] border-black mb-3 "></div>
                <p>Diskon</p>
                <p>Void</p>
                <p>Biaya Racikan</p>
                <p>Biaya Pengiriman</p>
                <p>Biaya Admin</p>
                <p>Kas Masuk</p>
                <p>Kas Keluar</p>
                <p>Penjualan Bersih</p>
                <p>Harga Pokok Penjualan</p>
                <div className="border-[1.5px] border-black mb-3 "></div>
                <p>Laba Rugi/Kotor</p>
                <p>Pengeluaran Operational</p>
                <p>Laba Rugi/Bersih</p>
              </div>
              <div className="w-full text-center">
                <p>:</p>
                <div className="border-[1.5px] border-black mb-3"></div>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <div className="border-[1.5px] border-black mb-3 "></div>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
              </div>
              <div className="text-center">
                <p>Rp. {profitnLossData.penjualan.toLocaleString()}</p>
                <div className="border-[1.5px] border-black mb-3"></div>
                <p>Rp. {profitnLossData.diskon.toLocaleString()}</p>
                <p>Rp. {profitnLossData.void.toLocaleString()}</p>
                <p>Rp. {profitnLossData.biaya_racikan.toLocaleString()}</p>
                <p>Rp. {profitnLossData.biaya_pengiriman.toLocaleString()}</p>
                <p>Rp. {profitnLossData.biaya_admin.toLocaleString()}</p>
                <p>Rp. {profitnLossData.kas_masuk.toLocaleString()}</p>
                <p>Rp. {profitnLossData.kas_keluar.toLocaleString()}</p>
                <p>Rp. {profitnLossData.Penjualan_bersih.toLocaleString()}</p>
                <p>Rp. {profitnLossData.hna.toLocaleString()}</p>
                <div className="flex items-center mb-3">
                  <p className="mr-3">
                    Rp. {profitnLossData.laba_rugi_kotor.toLocaleString()}
                  </p>
                  {profitnLossData.laba_rugi_kotor > 0 ? (
                    <p className="text-green-500 font-bold">Untung</p>
                  ) : (
                    <p className="text-red-500 font-bold">Rugi</p>
                  )}
                </div>
                <p>Rp. {profitnLossData.operational_cost.toLocaleString()}</p>
                <p>Rp. {profitnLossData.laba_rugi_bersih.toLocaleString()}</p>
              </div>
            </div>
              */}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <EmptyList message={"Silahkan Pilih Outlet dan Tanggal"} />
        </div>
      )}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default ReportingProfitnLoss;
