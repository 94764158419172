import { Box, CircularProgress, Modal } from "@mui/material";
import { MdCheckCircleOutline, MdOutlineCancel } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { acceptRejectInbox } from "../../../../services/centralWarehouseApi";
import { useMutation } from "@tanstack/react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalChangeStatusWarehouse = ({
  open,
  setOpen,
  item,
  refetch,
  type,
  action,
  product,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: mutateChangeStatus, isLoading: isLoadingChangeStatus } =
    useMutation({
      mutationFn: acceptRejectInbox,
      onSuccess: () => {
        refetch();
        setOpen(false);
      },
    });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "50vh",
          padding: 0,
        }}
        className="text-left text-base w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          {action === 1 ? (
            <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
              <div className="w-full h-full bg-blue-300 p-2 rounded-full">
                <MdCheckCircleOutline className="w-full h-full text-blue-600" />
              </div>
            </div>
          ) : (
            <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
              <div className="w-full h-full bg-red-300 p-2 rounded-full">
                <MdOutlineCancel className="w-full h-full text-red-600" />
              </div>
            </div>
          )}
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg ">
            {action === 1 ? "Accept" : "Reject"} allocation
          </p>
          <p>
            Are you sure you want to {action === 1 ? "accept" : "reject"} the
            allocated product to your inventory?
          </p>
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`w-full p-2 rounded-md border-2  text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 ${
              action === 1
                ? "border-blue-500 bg-blue-500"
                : "border-red-500 bg-red-500 "
            }`}
            disabled={isLoadingChangeStatus}
            onClick={() => {
              mutateChangeStatus({
                id: item.id,
                action: action, // 1 accept, 2 reject
                type: type,
                product: product ? product : [],
              });
            }}
          >
            {isLoadingChangeStatus ? (
              <CircularProgress size={20} />
            ) : action === 1 ? (
              "Accept"
            ) : (
              "Reject"
            )}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalChangeStatusWarehouse;
