import React from 'react';
import HOC from '../../components/HOC';
import Header from '../../components/ui/Header';
import SidebarComponents from '../../components/ui/SidebarComponents';
import InvoiceSettingComponents from '../../components/screen/settings/InvoiceSetting';
import { SIDEBAR_WIDTH } from '../../components/constants/constant';

const InvoiceSetting = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <InvoiceSettingComponents />
        </div>
      </div>
    </div>
  );
};

export default HOC(InvoiceSetting);
