import { useMutation } from "@tanstack/react-query";
import { switchOutlet } from "../../../services/outletApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/features/auth/authSlice";

const SwitchOutletModal = ({
  setOpenModal,
  openModal,
  setIsChanged,
  companyId,
  outletName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const body = {
      company_id: Number(companyId),
    };
    mutateSwitchOutlet(body);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const { mutate: mutateSwitchOutlet, isLoading } = useMutation({
    mutationFn: switchOutlet,
    onSuccess: (data) => {
      setIsChanged(true);
      if (data === undefined) return;
      dispatch(
        setUser({
          accessTokenOfficeOlin: data.token.access,
          accessExpired: data.token.access_expired,
          renewTokenOfficeOlin: data.token.renew,
          renewExpired: data.token.renew_expired,
          img_profile: data.foto_profile,
          name: data.nama_lengkap,
          job: data.title_name,
          status_code: data.status_code,
          title: data.title,
          company_id: data.company_id,
          roles: data.roles,
        })
      );
      handleClose();
      window.location.reload();
    },
  });

  return (
    <>
      {openModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-8 max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="p-4 w-full text-center">
                  Apakah anda yakin ingin mengganti outlet ke {outletName}?
                </div>

                <div className="flex items-center justify-center p-2 gap-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="disabled:bg-gray-200 bg-red-400 text-white hover:bg-red-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Batal
                  </button>
                  <button
                    className="disabled:bg-gray-200 bg-orange-400 text-white hover:bg-orange-600 font-bold uppercase text-sm w-full py-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default SwitchOutletModal;
