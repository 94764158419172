import moment from "moment";

const CopyPrescriptionPreview = ({ formik, selectedPatient }) => {
  function romanize(num) {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }

  const filterRegular = () => {
    return formik.values.non_concoction_copy.filter(
      (item) => item.product_name !== "" && item.uom !== "" && item.quantity > 0
    );
  };

  const filterConcoction = () => {
    return formik.values.concoction_copy.filter((conc) =>
      conc.product.every(
        (prod) =>
          prod.product_name !== "" && prod.uom !== "" && prod.quantity > 0
      )
    );
  };

  return (
    <>
      <div className="font-serif">
        <p className="text-center font-bold text-[18px] my-8">SALINAN RESEP</p>
        <div className="flex justify-between">
          <div className="flex w-full">
            <p className="w-1/3">No</p>
            <p>: {formik.values.queue_number}</p>
          </div>
          <div className="text-right w-1/3 text-nowrap">
            {moment(formik.values.prescription_date).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className="flex w-3/4">
          <p className="w-1/3">Dari</p>
          <p>: {formik.values.doctor_name}</p>
        </div>
        <div className="flex w-3/4">
          <p className="w-1/3">Tanggal Resep</p>
          <p>: {moment(formik.values.created_at).format("DD/MM/YYYY")}</p>
        </div>

        <div className="w-full my-8">
          {filterRegular().map((item, i) => (
            <div className="mb-4">
              <div className="flex gap-4 w-full">
                <p>R/</p>
                <div className="w-full">
                  <p>
                    -{item.product_name} {item.uom} {romanize(item.quantity)}
                  </p>
                  <p>S. {item.signa}</p>
                </div>
                <div className="w-fit whitespace-nowrap ">
                  <p className="border-blue-500 border-2 p-2 text-blue-500">
                    Iter {item.iter}x
                  </p>
                </div>
              </div>
              {item.type_det ? (
                <div className="w-full text-right mt-4">
                  {item.det_qty === 0
                    ? item.type_det
                    : "-" +
                      item.type_det.replaceAll("-", "") +
                      " " +
                      item.det_qty +
                      "x-"}
                </div>
              ) : null}
              <hr className="my-2 border-black border-t-5" />
            </div>
          ))}
        </div>
        <div className="w-full mb-4">
          {filterConcoction().map((item, i) => (
            <div className="mb-4">
              <p className="font-bold mb-2">Racikan {i + 1}</p>
              <div className="flex gap-4 w-full">
                <p>R/</p>
                <div className="w-full">
                  {item.product.map((prod, prodIndex) => (
                    <p>
                      -{prod.product_name} {prod.uom} {romanize(prod.quantity)}
                    </p>
                  ))}
                  <p>m.f {item.misce_fiat}</p>
                  <p>S. {item.signa}</p>
                </div>
                <div className="w-fit whitespace-nowrap ">
                  <p className="border-blue-500 border-2 p-2 text-blue-500">
                    Iter {item.iter}x
                  </p>
                </div>
              </div>
              {item.type_det ? (
                <div className="w-full text-right mt-4">
                  {item.det_qty === 0
                    ? item.type_det
                    : "-" +
                      item.type_det.replaceAll("-", "") +
                      " " +
                      item.det_qty +
                      "x-"}
                </div>
              ) : null}
              <hr className="my-2 border-black border-t-5" />
            </div>
          ))}
        </div>

        <div className="block">
          <p className="inline-block w-[100px]">Pro</p>
          <p className="inline-block">: {selectedPatient.patient_name}</p>
        </div>
        <div className="block">
          <p className="inline-block w-[100px]">Umur</p>
          <p className="inline-block">
            {/* : {getAge(selectedPatient.date_of_birth)} Tahun */}:{" "}
            {selectedPatient.patient_age
              ? selectedPatient.patient_age + " Tahun"
              : ""}
            {formik.values.objective.weight &&
            formik.values.objective.weight !== ""
              ? "(" + formik.values.objective.weight + " kg)"
              : null}
          </p>
        </div>

        <div className="w-full justify-end flex mt-4">
          <div className="w-1/3">
            <p className="mb-[3cm]">P.C.C.</p>
            <p>{formik.values.pcc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyPrescriptionPreview;
