import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import { BsTrash } from "react-icons/bs";
import { useMutation } from "@tanstack/react-query";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import { IGNORE_TYPE } from "../../constants/constant";
import { exchangeConsignment } from "../../../services/consignmentApi";

export default function ModalSwitchConsignment({
  showModal,
  setShowModal,
  productName,
  batchArr,
  prodId,
  setReload,
}) {
  const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

  const [showTable, setShowTable] = useState(true);
  const [switchArr, setSwitchArr] = useState([]);
  const [oldStockArr, setOldStockArr] = useState([]);
  const [newStockArr, setNewStockArr] = useState([
    { number_batch: "", quantity: 0, expired_date: null },
  ]);
  const [allSelect, setAllSelect] = useState(false);
  const [count, setCount] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return (
        primaryCount(stock, item) + " " + item.packing.type_strip.toUpperCase()
      );
    } else {
      return (
        primaryCount(stock, item) + " " + item.packing.type_box.toUpperCase()
      );
    }
  };

  const primaryCount = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return Math.floor(stock / item.packing.total_strip);
    } else {
      return Math.floor(
        stock / (item.packing.total_strip * item.packing.total_unit)
      );
    }
  };

  const handleAddItem = (checked, i) => {
    var temp = [...switchArr];
    if (checked) {
      temp.push(batchArr[i]);
      setSwitchArr(temp);
    } else {
      setSwitchArr(switchArr.filter((item) => item !== batchArr[i]));
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSwitchArr(batchArr);
      setAllSelect(true);
    } else {
      setSwitchArr([]);
      setAllSelect(false);
    }
  };

  const handleSetCount = (newVal, i) => {
    var temp = [...count];
    temp[i] = Number(newVal);
    setCount(temp);
  };

  const handleDeleteNewItem = (i) => {
    var temp = [...newStockArr];
    temp = [...temp.slice(0, i), ...temp.slice(i + 1)];
    setNewStockArr(temp);
  };

  const handleAddNewItem = () => {
    var temp = [...newStockArr];
    temp.push({ number_batch: "", quantity: 0, expired_date: null });
    setNewStockArr(temp);
  };

  const handleAddNewStock = (val, i, type) => {
    var temp = [...newStockArr];
    if (type === "batch") temp[i].number_batch = val;
    if (type === "qty")
      temp[i].quantity = IGNORE_TYPE.includes(switchArr[0].packing.type_unit)
        ? Number(val) * switchArr[0].packing.total_strip
        : Number(val) *
          switchArr[0].packing.total_strip *
          switchArr[0].packing.total_unit;
    if (type === "exp") temp[i].expired_date = val;
    setNewStockArr(temp);
  };

  const handleExchange = () => {
    const body = {
      product_id: Number(prodId),
      batch_list: oldStockArr,
      new_stock: newStockArr,
    };
    mutateExchange(body);
  };

  const { mutate: mutateExchange } = useMutation({
    mutationFn: exchangeConsignment,
    onSuccess: () => {
      setShowModal(false);
      setReload(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    var temp = [];
    switchArr.map((item, i) => {
      temp = [
        ...temp,
        {
          number_batch: item.number_batch,
          quantity: IGNORE_TYPE.includes(switchArr[0].packing.type_unit)
            ? count[i] * switchArr[0].packing.total_strip
            : count[i] *
              switchArr[0].packing.total_strip *
              switchArr[0].packing.total_unit,
          description: "Tukar Barang",
        },
      ];
    });
    setOldStockArr(temp);
  }, [count]);

  useEffect(() => {
    batchArr && batchArr !== null && switchArr.length === batchArr.length
      ? setAllSelect(true)
      : setAllSelect(false);
    setCount([...Array(switchArr.length).fill(0)]);
  }, [switchArr]);

  useEffect(() => {
    if (showModal) {
      setShowTable(true);
      setSwitchArr([]);
    }
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[80%] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="h-full max-h-[80vh] overflow-y-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="absolute top-12 w-[calc(100%-48px)] font-bold text-lg text-center -z-2">
                    Tukar Stok Konsinyasi
                  </p>
                  <div
                    className="w-full flex justify-end cursor-pointer hover:text-gray-200"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <Close className="" />
                  </div>
                  {showTable ? (
                    <div className="mt-12 block overflow-x-auto rounded-md">
                      <table className="w-full">
                        <thead className="bg-[#007AF1] text-white text-center">
                          <tr>
                            <th className="p-2 border-2 border-[#5B5B5B] rounded-tl-md">
                              <Checkbox
                                checked={allSelect}
                                onChange={(_, checked) => {
                                  handleSelectAll(checked);
                                }}
                              />
                            </th>
                            <th className="p-2 border-2 border-[#5B5B5B]">
                              Nama Produk
                            </th>
                            <th className="p-2 border-2 border-[#5B5B5B]">
                              No. Batch
                            </th>
                            <th className="p-2 border-2 border-[#5B5B5B]">
                              Expired Date
                            </th>
                            <th className="p-2 border-2 border-[#5B5B5B] rounded-tr-md">
                              Stok
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {batchArr && batchArr !== null ? (
                            batchArr.map((item, i) => {
                              return (
                                <tr>
                                  <td
                                    className={`p-2 border-2 border-[#5B5B5B] text-center ${
                                      i === batchArr.length - 1
                                        ? "rounded-bl-md"
                                        : ""
                                    }`}
                                  >
                                    <Checkbox
                                      checked={switchArr.includes(item)}
                                      onChange={(_, checked) =>
                                        handleAddItem(checked, i)
                                      }
                                    />
                                  </td>
                                  <td className="p-2 border-2 border-[#5B5B5B] text-center">
                                    {productName}
                                  </td>
                                  <td className="p-2 border-2 border-[#5B5B5B] text-center">
                                    {item.number_batch}
                                  </td>
                                  <td className="p-2 border-2 border-[#5B5B5B] text-center">
                                    {item.expired_date}
                                  </td>
                                  <td
                                    className={`p-2 border-2 border-[#5B5B5B] text-center ${
                                      i === batchArr.length - 1
                                        ? "rounded-br-md"
                                        : ""
                                    }`}
                                  >
                                    {primaryStock(item.stock, item)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : batchArr && batchArr === null ? (
                            <tr>
                              <td
                                colSpan={4}
                                className="text-red-500 w-full text-center"
                              >
                                Terjadi kesalahan dalam mengambil data batch
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={4} className="w-full text-center">
                                <CircularProgress size={20} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="mt-12">
                      <p className="font-bold mb-4">Stock Lama</p>

                      {switchArr.map((item, i) => {
                        return (
                          <div className="mb-2">
                            <div className="flex w-full">
                              <div className="w-1/4 text-gray-400">
                                Nama Produk
                              </div>
                              <div className="w-1/4 text-gray-400">
                                No. Batch
                              </div>
                              <div className="w-1/4 text-gray-400">Tanggal</div>
                              <div className="w-1/4 text-gray-400">Qty</div>
                            </div>
                            <div className="block rounded-lg w-full">
                              <table className="rounded-lg w-full">
                                <tbody>
                                  <tr className="rounded-lg">
                                    <td className="w-1/4 border border-[#5B5B5B] p-2 rounded-l-lg">
                                      {productName}
                                    </td>
                                    <td className="w-1/4 border border-[#5B5B5B] p-2">
                                      {item.number_batch}
                                    </td>
                                    <td className="w-1/4 border border-[#5B5B5B] p-2">
                                      {item.expired_date}
                                    </td>
                                    <td className="w-1/4 border border-[#5B5B5B] p-2 rounded-r-lg">
                                      {primaryStock(item.stock, item)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="flex flex-row gap-2 justify-end mt-2 items-center">
                              <p className="font-bold mr-2">Qty yang ditukar</p>
                              <button
                                className="bg-[#FF7A5D] w-[30px] h-[30px] mr-[10px] rounded-lg disabled:bg-gray-500"
                                type="button"
                                onClick={() => {
                                  if (count[i] === 0) return;
                                  handleSetCount(count[i] - 1, i);
                                }}
                                disabled={count[i] === 0}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                pattern="[1-9][0-9]*?"
                                className="border rounded-lg w-[69px] h-[30px] p-2"
                                value={count[i]}
                                onChange={(e) => {
                                  handleSetCount(
                                    e.target.value >
                                      primaryCount(item.stock, item)
                                      ? primaryCount(item.stock, item)
                                      : e.target.value,
                                    i
                                  );
                                }}
                              />
                              <button
                                className="bg-[#5DFFBB] w-[30px] h-[30px] ml-[10px] rounded-lg disabled:bg-gray-500"
                                type="button"
                                onClick={() => {
                                  handleSetCount(count[i] + 1, i);
                                }}
                                disabled={
                                  count[i] === primaryCount(item.stock, item)
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      <hr className="my-2" />
                      <div className="my-4">
                        <p className="font-bold">Tukar Stock</p>
                        {count.reduce((acc, num) => acc + num, 0) !==
                        newStockArr.reduce(
                          (acc, num) =>
                            acc + primaryCount(num.quantity, switchArr[0]),
                          0
                        ) ? (
                          <p className="text-red-500">
                            Total stock harus sama dengan{" "}
                            {count.reduce((acc, num) => acc + num, 0)}
                          </p>
                        ) : null}
                      </div>
                      {newStockArr.map((item, i) => {
                        return (
                          <div className="w-full flex items-center gap-2 my-2">
                            <TextField
                              label="No. Batch"
                              value={newStockArr[i].number_batch}
                              onChange={(e) =>
                                handleAddNewStock(e.target.value, i, "batch")
                              }
                            />
                            <TextField
                              label="Qty"
                              value={primaryCount(
                                newStockArr[i].quantity,
                                switchArr[0]
                              )}
                              onChange={(e) =>
                                handleAddNewStock(e.target.value, i, "qty")
                              }
                            />

                            <DesktopDatePicker
                              label="Expired"
                              sx={{ width: "100%" }}
                              value={newStockArr[i].expired_date}
                              onChange={(newValue) =>
                                handleAddNewStock(toDateApi(newValue), i, "exp")
                              }
                              renderInput={(params) => {
                                return <TextField {...params} />;
                              }}
                            />
                            <BsTrash
                              className={`w-[24px] h-full cursor-pointer ${
                                i === 0
                                  ? "text-gray-300"
                                  : "text-red-500 hover:text-red-700"
                              }`}
                              onClick={() =>
                                i === 0 ? null : handleDeleteNewItem(i)
                              }
                            />
                          </div>
                        );
                      })}
                      <p
                        className="font-bold text-blue-500 w-full text-right cursor-pointer hover:text-blue-700"
                        onClick={() =>
                          count.reduce((acc, num) => acc + num, 0) >
                          newStockArr.reduce(
                            (acc, num) =>
                              acc + primaryCount(num.quantity, switchArr[0]),
                            0
                          )
                            ? handleAddNewItem()
                            : null
                        }
                      >
                        + Tambah Batch Lain
                      </p>
                    </div>
                  )}
                </div>
                {/*footer*/}
                {!showTable && errorMessage !== "" ? (
                  <p className="text-red-500">{errorMessage}</p>
                ) : null}
                <div className="flex items-center justify-center p-6 gap-1 md:gap-3 rounded-b w-full">
                  <div className="w-full">
                    <button
                      className="w-full border border-[#5B5B5B] hover:bg-slate-100 font-bold uppercase text-sm py-2 px-4 md:px-12 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Batal
                    </button>
                  </div>
                  <div className="w-full">
                    <button
                      className="w-full bg-blue-500 text-white hover:bg-blue-700 font-bold uppercase text-sm py-2 px-4 md:px-12 rounded-lg shadow hover:shadow-lg outline-none focus:outline-noneease-linear transition-all duration-150 disabled:bg-gray-400"
                      type="button"
                      onClick={() =>
                        showTable ? setShowTable(false) : handleExchange()
                      }
                      disabled={
                        showTable
                          ? switchArr.length === 0
                          : false ||
                            (count.length > 0 &&
                              newStockArr.length > 0 &&
                              newStockArr.every(
                                (item) => item.number_batch !== ""
                              ) &&
                              !newStockArr.includes(
                                (item) => item.quantity === 0
                              ))
                          ? count.reduce((acc, num) => acc + num, 0) !==
                            newStockArr.reduce(
                              (acc, num) =>
                                acc + primaryCount(num.quantity, switchArr[0]),
                              0
                            )
                          : true
                      }
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
