import React, { useState, createContext } from "react";

export const HabisListContext = createContext();

const ProductHabisProvider = ({ children }) => {
  const [printSP, setPrintSP] = useState({});

  return (
    <HabisListContext.Provider value={{ printSP, setPrintSP }}>
      {children}
    </HabisListContext.Provider>
  );
};

export default ProductHabisProvider;
