import React from "react";
import { Formik } from "formik";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import CustomerComponents from "../../components/screen/customer/Customer";

import {
  initialValues,
  validationSchema,
} from "../../components/context/CostumerProviderFormik";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const Customer = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
    >
      <div className="">
        <div className="flex">
          <SidebarComponents />
          <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
            <Header />
            <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
              Pelanggan
            </h1>
            <CustomerComponents />
          </div>
        </div>
      </div>
    </Formik>
  );
};

export default HOC(Customer);
