import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { getReturPdf } from "../../../../services/billingAPI";

const toDate = (date) => moment(date).format("DD-MMM-YYYY");

const ReturDetailProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: returPdfData } = useQuery({
    queryKey: ["retur-pdf", id],
    queryFn: () => getReturPdf(id.replaceAll("-", "/")),
    enabled: id !== 0,
  });
  return (
    <div>
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Detail Retur
      </h1>
      <div className="text-blue-500 flex">
        <p
          className="cursor-pointer hover:underline"
          onClick={() => navigate("/home")}
        >
          Homepage
        </p>
        <p className="mx-2">/</p>
        <p
          className="cursor-pointer hover:underline"
          onClick={() => navigate("/billing/product-retur")}
        >
          Retur
        </p>
        <p className="mx-2">/</p>
        <p>Detail Retur</p>
      </div>
      <div className="mx-5">
        {returPdfData && returPdfData !== undefined ? (
          <div>
            <div className="grid grid-cols-2 gap-2 my-3">
              <div className="border-b-2">
                <p className="font-bold mb-2">Note Number</p>
                <p className="text-[20px] font-normal">
                  {returPdfData.note_number}
                </p>
              </div>
              <div className="border-b-2">
                <p className="font-bold mb-2">No.Telp Distributor</p>
                <p className="text-[20px] font-normal">
                  {returPdfData.distributor_phone}
                </p>
              </div>
              <div className="border-b-2">
                <p className="font-bold mb-2">Nama Distributor</p>
                <p className="text-[20px] font-normal">
                  {returPdfData.distributor_name}
                </p>
              </div>
              <div className="border-b-2">
                <p className="font-bold mb-2">Alamat Distributor</p>
                <p className="text-[20px] font-normal">
                  {returPdfData.distributor_address}
                </p>
              </div>
            </div>
            <div className="flex justify-between font-bold my-2">
              <p>Product List</p>
              <div className="flex grid-cols-2 gap-2">
                <p>Jumlah Bayar</p>
                <p>
                  {returPdfData.total_price.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
            <div>
              {returPdfData.product_list &&
              returPdfData.product_list !== undefined ? (
                <table className="w-full border">
                  <thead>
                    <tr className="text-center bg-blue-500 text-white">
                      <th className="py-2 ">Id Produk</th>
                      <th className="py-2 ">Nama Produk</th>
                      <th className="py-2">Nomor Batch</th>
                      <th className="py-2">Expired Date</th>
                      <th className="py-2">Retur Quantity</th>
                      <th className="py-2">HNA</th>
                      <th className="py-2">Nomor Invoice</th>
                    </tr>
                  </thead>
                  {returPdfData.product_list.map((data, index) => (
                    <tbody key={index}>
                      <tr className="text-center">
                        <td className="py-2 border">{data.product_id}</td>
                        <td className="py-2 border text-blue-500 underline">
                          {data.product_name}
                        </td>
                        <td className="py-2 border">{data.batch_number}</td>
                        <td className="py-2 border">
                          {toDate(data.expired_date)}
                        </td>
                        <td className="py-2 border">
                          {data.quantity} {data.type_packaging}
                        </td>
                        <td className="py-2 border">
                          {data.pharmacy_net_price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="py-2 border">{data.invoice_number}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ReturDetailProduct;
