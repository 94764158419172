import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsExclamationTriangle } from "react-icons/bs";
import LoadingBackdrop from "./LoadingBackdrop";

const ReorderPurchaseModal = (props) => {
  const {
    reorderTransaction,
    setReorderTransaction,
    mutateReorderTransaction,
    errorMessage,
    setErrorMessage,
    isMutateReorderTransactionLoading,
  } = props;

  const button = `rounded-md p-2 text-white ${
    errorMessage.status ? "bg-red-500" : "bg-[#007AF1]"
  }`;

  return (
    <Dialog
      open={reorderTransaction.open}
      onClose={() =>
        setReorderTransaction({ ...reorderTransaction, open: false })
      }
      fullWidth
      maxWidth="xs"
    >
      {isMutateReorderTransactionLoading ? (
        <LoadingBackdrop isLoading={isMutateReorderTransactionLoading} />
      ) : (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="bg-gray-50 rounded-full p-2">
              {errorMessage.status ? (
                <BsExclamationTriangle className="rounded-full bg-red-100 text-red-500 p-2 text-[40px]" />
              ) : (
                <AiOutlineQuestionCircle className="rounded-full bg-blue-100 text-blue-500 p-2 text-[40px]" />
              )}
            </div>
            <button
              onClick={() => {
                setReorderTransaction({ ...reorderTransaction, open: false });
                setErrorMessage({
                  ...errorMessage,
                  title: "",
                  content: "",
                  status: false,
                });
              }}
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            {errorMessage.status ? (
              <div className="mb-3">
                <p className="font-bold">{errorMessage.title}</p>
                <p className="font-medium">{errorMessage.content}</p>
              </div>
            ) : (
              <div className="mb-3">
                <p className="font-bold">Beli Lagi?</p>
                <p className="font-medium">
                  Pesanan ini akan masuk kembali ke dalam keranjang
                </p>
              </div>
            )}
            <div className="flex font-bold justify-center w-full">
              <button
                className="border border-black rounded-md p-2 mr-2 px-4"
                onClick={() => {
                  setReorderTransaction({ ...reorderTransaction, open: false });
                  setErrorMessage({
                    ...errorMessage,
                    title: "",
                    content: "",
                    status: false,
                  });
                }}
              >
                Batal
              </button>
              <button
                className={button}
                onClick={() =>
                  mutateReorderTransaction({
                    invoice: reorderTransaction.invoice,
                  })
                }
              >
                {errorMessage.status ? "Coba Lagi" : "Ok"}
              </button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ReorderPurchaseModal;
