import { Box, Button, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";

import Breadcumbs from "../../../common/particles.jsx/Breadcumbs";
import {
  getCongsignmentDetail,
  getConsignmentDetailBatch,
} from "../../../../services/consignmentApi";
import ModalSwitchConsignment from "../../../common/particles.jsx/ModalSwitchConsignment";
import ModalPaymentConsignment from "../../../common/particles.jsx/ModalPaymentConsignment";
import ModalReturnConsignment from "../../../common/particles.jsx/ModalReturnConsignment";
import { IGNORE_TYPE } from "../../../constants/constant";

const DetailConsignment = () => {
  const { params } = useParams();
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isMoreHistory, setIsMoreHistory] = useState(false);
  const [isMoreHistoryPayment, setIsMoreHistoryPayment] = useState(false);
  const [reload, setReload] = useState(false);

  const invoiceNum = atob(params.split(":")[0]);
  const id = atob(params.split(":")[1]);

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return (
        Math.floor(stock / item.packing.total_strip) +
        " " +
        item.packing.type_strip.toUpperCase()
      );
    } else {
      return (
        Math.floor(
          stock / (item.packing.total_strip * item.packing.total_unit)
        ) +
        " " +
        item.packing.type_box.toUpperCase()
      );
    }
  };

  const { data, isFetching } = useQuery({
    queryKey: ["detail-consignment", reload],
    queryFn: () => getCongsignmentDetail(invoiceNum, id),
    retry: 3,
  });

  const { data: batchData } = useQuery({
    queryKey: ["detail-batch-consignment", reload],
    queryFn: () => getConsignmentDetailBatch(id),
  });

  useEffect(() => {
    setReload(false);
  }, [reload]);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full">
        <div className="w-full basis-1/2 md:basis-full">
          <Breadcumbs
            nama1="Konsinyasi"
            link1="/inventory/consignment"
            nama2="Detail Konsinyasi"
            link2=""
            nama3={invoiceNum}
            link3=""
            textBlue={true}
          />
        </div>
        <div className="w-full flex justify-items items-center gap-3">
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowSwitchModal(true)}
          >
            Tukar Produk
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowReturnModal(true)}
            sx={{
              backgroundColor: "#f59e0b",
              "&:hover": { backgroundColor: "#b45309" },
            }}
          >
            Return Produk
          </Button>
        </div>
      </div>

      <Box
        sx={{
          boxShadow: 4,
          padding: 2,
          borderRadius: 2,
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <p className="font-bold">Detail Konsinyasi</p>
        <hr className="my-2" />
        {data && data.detail !== null ? (
          <>
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Nama Obat</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">{data.detail.product_name}</p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Jumlah Awal</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {primaryStock(data.detail.first_stock, data.detail)}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Tanggal Transaksi</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {moment(data.detail.transaction_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Return</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {primaryStock(data.detail.total_return, data.detail)}
                </p>
              </div>
            </div>{" "}
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Nomor Faktur</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">{data.detail.invoice}</p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Terjual</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {primaryStock(data.detail.total_terjual, data.detail)}
                </p>
              </div>
            </div>{" "}
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Nama Supplier</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">{data.detail.supplier_name}</p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Current Stock</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {primaryStock(data.detail.current_stock, data.detail)}
                </p>
              </div>
            </div>{" "}
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Harga Satuan</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {data.detail.pharmacy_net_price.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Jumlah Bayar</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {(
                    data.detail.total_price - data.detail.kekurangan
                  ).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-full items-center">
              <div className="w-full">
                <p className="inline-block w-[40%]">Total</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {" "}
                  {Number(data.detail.total_price).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="w-full">
                <p className="inline-block w-[40%]">Kekurangan</p>
                <p className="inline-block">:</p>
                <p className="inline-block ml-2">
                  {data.detail.kekurangan.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
          </>
        ) : (data && data.detail === null) || !isFetching ? (
          <p className="text-red-500">Tidak ada detail konsinyasi</p>
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>

      <Button
        variant="contained"
        sx={{ float: "right", marginY: 2 }}
        onClick={() => setShowPaymentModal(true)}
        disabled={
          data && data.detail !== null ? data.detail.kekurangan === 0 : true
        }
      >
        Bayar Konsinyasi
      </Button>

      {data &&
      data.detail !== null &&
      data.detail.history_consignment !== null ? (
        <div className="w-full block overflow-x-auto my-4 text-sm">
          <table className="w-full border-2 border-[#5B5B5B]">
            <thead className="bg-[#007AF1] text-white text-center">
              <tr>
                <th className="p-2 border-2 border-[#5B5B5B]">Tanggal</th>
                <th className="p-2 border-2 border-[#5B5B5B]">
                  Tipe Transaksi
                </th>
                <th className="p-2 border-2 border-[#5B5B5B]">Deskripsi</th>
                <th className="p-2 border-2 border-[#5B5B5B]">Jumlah</th>
                <th className="p-2 border-2 border-[#5B5B5B]">Current Stock</th>
              </tr>
            </thead>
            <tbody>
              {data.detail.history_consignment.map((item, i) => {
                if ((i < 5 && !isMoreHistory) || isMoreHistory) {
                  return (
                    <tr>
                      <td className="p-2 text-center border-2 border-[#5B5B5B]">
                        {moment(item.transaction_date).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center border-2 border-[#5B5B5B]">
                        {item.transaction_type}
                      </td>
                      <td className="p-2 text-center border-2 border-[#5B5B5B]">
                        {item.description}
                      </td>
                      <td className="p-2 text-center border-2 border-[#5B5B5B]">
                        {primaryStock(item.quantity, data.detail)}
                      </td>
                      <td className="p-2 text-center border-2 border-[#5B5B5B]">
                        {primaryStock(item.current_stock, data.detail)}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          {data.detail.history_consignment.length > 5 ? (
            <p
              className="font-bold text-blue-500 cursor-pointer hover:text-blue-700 w-full text-right"
              onClick={() => setIsMoreHistory(!isMoreHistory)}
            >
              {isMoreHistory ? "Show Less" : "Show More"}
            </p>
          ) : null}
        </div>
      ) : (data &&
          (data.detail === null || data.detail.history_consignment === null)) ||
        !isFetching ? (
        <p className="text-red-500">Belum ada transaksi</p>
      ) : (
        <CircularProgress size={20} />
      )}

      <div className="my-4 w-full">
        <p className="font-bold mb-2">History Pembayaran</p>
        {data &&
        data.detail !== null &&
        data.detail.history_consignment_payment !== null ? (
          <div className="w-full block overflow-x-auto my-2 text-sm">
            <table className="w-full border-2 border-[#5B5B5B]">
              <thead className="bg-[#007AF1] text-white text-center">
                <tr>
                  <th className="p-2 border-2 border-[#5B5B5B]">Tanggal</th>
                  <th className="p-2 border-2 border-[#5B5B5B]">
                    Nominal Pembayaran
                  </th>
                  <th className="p-2 border-2 border-[#5B5B5B]">Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                {data.detail.history_consignment_payment.map((item, i) => {
                  if (
                    (i < 5 && !isMoreHistoryPayment) ||
                    isMoreHistoryPayment
                  ) {
                    return (
                      <tr>
                        <td className="p-2 text-center border-2 border-[#5B5B5B]">
                          {moment(item.transaction_date).format("DD/MM/YYYY")}
                        </td>
                        <td className="p-2 text-center border-2 border-[#5B5B5B]">
                          {item.paid_nominee.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="p-2 text-center border-2 border-[#5B5B5B]">
                          {item.description}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <p
              className="font-bold text-blue-500 cursor-pointer hover:text-blue-700 w-full text-right"
              onClick={() => setIsMoreHistoryPayment(!isMoreHistoryPayment)}
            >
              {data.detail.history_consignment_payment.length > 5
                ? isMoreHistoryPayment
                  ? "Show Less"
                  : "Show More"
                : null}
            </p>
          </div>
        ) : (data &&
            (data.detail === null ||
              data.detail.history_consignment_payment === null)) ||
          !isFetching ? (
          <p className="text-red-500">Tidak ada history pembayaran</p>
        ) : (
          <CircularProgress size={20} />
        )}
      </div>

      {data && data.detail !== null && batchData && id ? (
        <>
          <ModalSwitchConsignment
            showModal={showSwitchModal}
            setShowModal={setShowSwitchModal}
            batchArr={batchData}
            productName={data.detail.product_name}
            prodId={id}
            setReload={setReload}
          />
          <ModalReturnConsignment
            showModal={showReturnModal}
            setShowModal={setShowReturnModal}
            batchArr={batchData}
            productName={data.detail.product_name}
            prodId={id}
            setReload={setReload}
          />
          <ModalPaymentConsignment
            showModal={showPaymentModal}
            setShowModal={setShowPaymentModal}
            productName={data.detail.product_name}
            toPay={data.detail.kekurangan}
            returnNum={data.detail.total_return}
            sold={data.detail.total_terjual}
            initialStock={
              Math.floor(
                data.detail.first_stock /
                  (data.detail.packing.total_strip *
                    data.detail.packing.total_unit)
              ) +
              " " +
              data.detail.packing.type_box.toUpperCase()
            }
            currentStock={
              Math.floor(
                data.detail.current_stock /
                  (data.detail.packing.total_strip *
                    data.detail.packing.total_unit)
              ) +
              " " +
              data.detail.packing.type_box.toUpperCase()
            }
            prodId={id}
            setReload={setReload}
          />
        </>
      ) : null}
    </div>
  );
};

export default DetailConsignment;
