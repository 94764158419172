import React, { useState, createContext } from "react";

export const VerificationMethodContext = createContext();

const VerificationMethodProvider = ({ children }) => {
  const [isVerificationMethodEmail, setIsVerificaitonMethodEmail] =
    useState(false);
  const [isVerificationMethodPassword, setIsVerificaitonMethodPassword] =
    useState(false);
  const [isVerificationMethodPhone, setIsVerificationMethodPhone] =
    useState(false);

  return (
    <VerificationMethodContext.Provider
      value={{
        isVerificationMethodEmail,
        setIsVerificaitonMethodEmail,
        isVerificationMethodPassword,
        setIsVerificaitonMethodPassword,
        isVerificationMethodPhone,
        setIsVerificationMethodPhone,
      }}
    >
      {children}
    </VerificationMethodContext.Provider>
  );
};

export default VerificationMethodProvider;
