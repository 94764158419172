import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ReportingProductSell from "./ReportingProductSell";
import ReportingProductPurchase from "./ReportingProductPurchase";

const period = [
  {
    value: 0,
    name: "Semua Period",
  },
  {
    value: 1,
    name: "Hari ini",
  },
  {
    value: 2,
    name: "Kemarin",
  },
  {
    value: 3,
    name: "Minggu Berjalan",
  },
  {
    value: 4,
    name: "Minggu Lalu",
  },
  {
    value: 5,
    name: "Bulan Berjalan",
  },
  {
    value: 6,
    name: "Bulan Lalu",
  },
  {
    value: 7,
    name: "Tahun Berjalan",
  },
  {
    value: 8,
    name: "Tahun Lalu",
  },
  {
    value: -2,
    name: "Pilih Tanggal",
  },
];

const FETCH_LIMIT = 10;

const ReportingProduct = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  return (
    <div>
      <h1 className="font-semibold text-[20px] lg:text-[30px]">Laporan</h1>
      <div className="flex text-blue-500 gap-2 my-3">
        <p
          className="hover:cursor-pointer"
          onClick={() => navigate("/inventory/produk-aktif")}
        >
          Laporan
        </p>
        <p>/</p>
        <p className="hover:cursor-pointer">Laporan Per Produk</p>
      </div>

      <div className="w-full justify-between border-2 mt-14 lg:mt-3 text-sm lg:text-base  text-center grid grid-cols-2 divide-x">
        <button
          className={page === 1 ? "py-2 bg-blue-500 text-white" : "py-2"}
          onClick={() => setPage(1)}
        >
          Penjualan Produk
        </button>
        <button
          className={page === 2 ? "py-2 bg-blue-500 text-white" : "py-2"}
          onClick={() => setPage(2)}
        >
          Pembelian Produk
        </button>
      </div>
      <div>
        {page === 1 ? (
          <ReportingProductSell period={period} FETCH_LIMIT={FETCH_LIMIT} />
        ) : (
          <ReportingProductPurchase period={period} FETCH_LIMIT={FETCH_LIMIT} />
        )}
      </div>
    </div>
  );
};

export default ReportingProduct;
