import React, { useContext, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useQueryClient } from "@tanstack/react-query";

import { SelectedProductContext } from "../../../context/SelectedProductProvider";

const HeaderPurchase = () => {
  const queryClient = useQueryClient();

  const {
    isProduct,
    setIsProduct,
    setSearch,
    mutateSearchProduct,
    search,
    setSearchFocus,
  } = useContext(SelectedProductContext);

  useEffect(() => {
    const input = document.getElementById("myInput");
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (search !== "") {
          mutateSearchProduct({
            search,
            limit: 5,
            offset: 1,
          });
        } else {
          setSearchFocus(true);
        }
      }
    };
    input.addEventListener("keydown", handleKeyDown);
    return () => {
      input.removeEventListener("keydown", handleKeyDown);
    };
  }, [search]);

  return (
    <div className="w-full flex items-center">
      {isProduct.searchSelected ? (
        <button
          onClick={() =>
            setIsProduct({
              ...isProduct,
              searchSelected: false,
            })
          }
        >
          <BiArrowBack className="mr-2 text-[20px]" />
        </button>
      ) : null}
      <div className="w-full shadow-md rounded-lg">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div method="get" action="#" className="relative z-10">
          <button
            type="submit"
            id="searchsubmit"
            className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <svg
              className="h-5 w-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <input
            id="myInput"
            className="block w-full  pl-10 pr-3 py-2 border  rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out hover:cursor-pointer"
            placeholder="Cari Produk"
            onClick={() => {
              setIsProduct({
                ...isProduct,
                searchSelected: true,
              });
              setSearchFocus(true);
              queryClient.invalidateQueries({ queryKey: ["history-search"] });
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderPurchase;
