import { useParams } from "react-router-dom";
import HOC from "../../../components/HOC";
import PageTemplate from "../../../components/common/particles.jsx/PageTemplate";
import AddDoctorAnalysis from "../../../components/screen/patientCare/patientCareDoctorAnalysis/AddDoctorAnalysis";

const DetailMedicalRecordPage = () => {
  const { id } = useParams();
  return (
    <PageTemplate
      header={`Detail Patient Medical Record`}
      mainComponent={<AddDoctorAnalysis isDetail={true} />}
    />
  );
};

export default HOC(DetailMedicalRecordPage);
