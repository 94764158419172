import { CircularProgress, Checkbox, Collapse, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { format } from "date-fns";

const CollapsibleExpired = ({
  products,
  isLoading,
  anchorElProduct,
  setAnchorElProduct,
  expandedExp,
  setExpandedExp,
  loadMore,
  FETCH_LIMIT,
  isFirst,
  handleSelectList,
  productId,
  setProductId,
  type,
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const [hasNext, setHasNext] = useState(true);

  useEffect(() => {
    if (
      (products && !products.product_data) ||
      (products &&
        products.product_data &&
        products.product_data.length < FETCH_LIMIT)
    )
      setHasNext(false);
    if (products && products.product_data) {
      setAllProducts(
        isFirst
          ? products.product_data
          : [...allProducts, ...products.product_data]
      );
    }
  }, [products]);

  useEffect(() => {
    if (isFirst) setHasNext(true);
  }, [isFirst]);

  return (
    <>
      <div
        className="flex justify-between items-center mb-2 hover:text-gray-300 cursor-pointer"
        onClick={() => setExpandedExp(!expandedExp)}
      >
        <div>
          <p className="font-bold inline-block">
            {type === 0
              ? "Expired"
              : type === 1
              ? "Expiring in 1 Month"
              : type === 2
              ? "Expiring in 3 Months"
              : "Expiring in 6 Months"}
          </p>
          <div className="inline-block ml-4">
            {products && !isLoading ? (
              <p>
                {"("}
                {products?.jumlah_product} products{")"}
              </p>
            ) : (
              <CircularProgress size={20} />
            )}
          </div>
        </div>
        {expandedExp ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      <hr />
      <Collapse in={expandedExp} timeout="auto">
        {allProducts.length > 0 ? (
          <>
            {allProducts.map((item, i) => (
              <div
                key={i}
                className="border-b-2 flex justify-between items-center gap-4 py-2"
              >
                <div className="flex items-center gap-4">
                  <Checkbox
                    onChange={() => handleSelectList(item.product_id)}
                    checked={productId.includes(item.product_id)}
                  />
                  <div className="flex items-center gap-4">
                    <img
                      src={item.media[0]}
                      className="rounded-md w-[100px] h-[100px] border-2"
                    />
                    <div className="text-xs">
                      <p className="">{item.product_name}</p>
                      <p className="font-bold">
                        Rp. {item.pharmacy_net_price} /
                        {item.packing !== null && item.packing !== undefined
                          ? item.packing.type_box
                          : null}
                      </p>
                      <p className="mb-2">{item.po_number}</p>
                      <p>Supplier: {item.distributor_name}</p>
                      <p className="text-red-500">
                        Expired Date:{" "}
                        {format(new Date(item.expired_date), "dd/MM/yyyy")}
                      </p>
                      <p>{item.packaging_detail}</p>
                    </div>
                  </div>
                </div>
                <BsThreeDotsVertical
                  className="cursor-pointer hover:text-gray-300"
                  aria-controls={
                    Boolean(anchorElProduct) ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorElProduct) ? "true" : undefined}
                  onClick={(e) => {
                    setAnchorElProduct(e.currentTarget);
                    setProductId([item.product_id]);
                  }}
                />
              </div>
            ))}
            {hasNext ? (
              <Button
                fullWidth
                variant="outlined"
                sx={{ marginY: 2 }}
                onClick={loadMore}
              >
                {isLoading ? <CircularProgress size={20} /> : "Load More"}
              </Button>
            ) : null}
          </>
        ) : isLoading ? (
          <CircularProgress size={20} sx={{ marginY: 2 }} />
        ) : (
          <p>Tidak ada produk</p>
        )}
      </Collapse>
    </>
  );
};

export default CollapsibleExpired;
