import { useContext, useEffect } from "react";
import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { PaymentContext } from "../../context/PaymentStatusProvider";

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { paymentPayload } = useContext(PaymentContext);

  useEffect(() => {
    if (!paymentPayload.sukses) return;
    const handler = setTimeout(() => navigate("/profile"), 5000);
    return () => clearTimeout(handler);
  }, [paymentPayload]);

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-col justify-center items-center">
        {!paymentPayload.sukses ? (
          <AiFillExclamationCircle className="w-[30%] h-[30%] text-[#EFCE4A]" />
        ) : (
          <AiFillCheckCircle className="w-[30%] h-[30%] text-[#007AF1]" />
        )}
        <div className="font-bold text-center my-3 text-[20px]">
          {!paymentPayload.sukses ? (
            <p>
              Mohon lakukan pembayaran sebesar{" "}
              {paymentPayload.total.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
              sebelum tanggal {toDate(paymentPayload.bayar_sebelum)}
            </p>
          ) : (
            <>
              <p>Terima Kasih!</p>
              <p>Pembayaran Berhasil</p>
            </>
          )}
        </div>
        {!paymentPayload.sukses ? (
          <button
            className="bg-[#007AF1] text-white font-bold p-2 rounded-md mb-3"
            onClick={() => navigate("/subscription/purchase")}
          >
            Selesaikan Pembayaran
          </button>
        ) : (
          <>
            <button
              className="bg-[#007AF1] text-white font-bold p-2 rounded-md mb-3"
              onClick={() => navigate("/profile")}
            >
              Kembali ke halaman User Account
            </button>
            <p>
              Halaman akan kembali ke menu purchase secara otomatis jika tidak
              ada interaksi
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
