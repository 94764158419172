import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  CircularProgress,
  Pagination,
  MenuItem,
  Menu,
  Button,
  TextField,
  Popover,
  Select,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { AiOutlinePlusCircle } from "react-icons/ai";

import ModalChangeCustomer from "../../common/particles.jsx/ModalChangeCustomer";
import ModalDeleteCustomer from "../../common/particles.jsx/ModalDeleteCustomer";
import Trash from "../../../assets/icons/trash.svg";
import { getCustomer } from "../../../services/customerApi";
import useDebounce from "../../hooks/useDebounce";
import CreateCustomerModal from "../../common/particles.jsx/CreateCustomerModal";
import BreadcrumbCustomer from "./BreadcrumbCustomer";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import PagePagination from "../../common/particles.jsx/PagePagination";

const Customer = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [anchorElProduct, setAnchorElProduct] = useState(null);
  const [isAddCustomerOpen, setIsAddCustomerOpen] = useState(false);
  const [updateValue, setUpdateValue] = useState({
    id: 0,
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const debounce = useDebounce(search, 500);

  const { data: customers, isLoading: isCustomerLoading } = useQuery({
    queryKey: ["customers", debounce, offset, limit],
    queryFn: () => getCustomer(Math.ceil(Number(offset)), limit, debounce, ""),
    keepPreviousData: true,
  });

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (search === "") {
      setOffset(Number("1"));
    }
  }, [search, setOffset]);

  return (
    <>
      <BreadcrumbCustomer />
      <div className="flex gap-2 w-full items-center mb-4">
        <div className="w-full shadow-md rounded-lg">
          <label htmlFor="search" className="sr-only">
            Search{" "}
          </label>
          <div methode="get" action="#" className="relative z-10">
            <button
              type="submit"
              id="searchsubmit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>
        </div>
        <div
          className="hover:cursor-pointer py-2 px-3 gap-2 text-white font-medium rounded-md bg-[#007AF1] flex justify-between items-center"
          onClick={() => setIsAddCustomerOpen(true)}
        >
          <AiOutlinePlusCircle className="text-[20px]" />
          <p className="whitespace-nowrap">Tambah Pelanggan</p>
        </div>
      </div>
      <ModalDeleteCustomer
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        image={Trash}
        onClick={() => setShowDeleteModal(false)}
        name="Apakah anda yakin ingin menghapus pelanggan ini?"
        updateValue={updateValue}
      />
      <ModalChangeCustomer
        setShowModal={setShowModal}
        showModal={showModal}
        updateValue={updateValue}
        onClick={() => setShowModal(true)}
        header="Edit Customer"
      />
      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full">
            <table className="w-full border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2">Doctor Name</th>
                <th className="p-2 text-left">Patient Name</th>
                <th className="p-2 text-left">No Rekam Medis</th>
                <th className="p-2 text-left">Phone Number</th>
                <th className="p-2 text-left">Date of Birth</th>
                <th className="p-2 text-left">NIK</th>
                <th className="p-2 text-left">Email</th>
                <th className="p-2 text-left">Address</th>
                <th className="p-2 text-left">Action</th>
                <th></th>
              </thead>
              <tbody>
                {customers &&
                !isCustomerLoading &&
                customers.detail !== null &&
                customers.detail.customer ? (
                  customers.detail.customer.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="border-collapse border border-gray-500"
                      >
                        <td className="p-2 text-left border">
                          {item.doctor ? item.doctor : "-"}
                        </td>
                        <td
                          className="p-2 text-left border cursor-pointer text-blue-500 hover:text-blue-700"
                          onClick={() =>
                            navigate(`/pelanggan/detail-pelanggan/${item.id}`)
                          }
                        >
                          {item.name}
                        </td>
                        <td className="p-2 text-left border">-</td>
                        <td className="p-2 text-left border">{item.phone}</td>
                        <td className="p-2 text-left border">
                          {item.dob ? item.dob : "-"}
                        </td>
                        <td className="p-2 text-left border">
                          {item.nik === "" ? "-" : item.nik}
                        </td>
                        <td className="p-2 text-left border">{item.email}</td>
                        <td className="p-2 text-left border">{item.address}</td>
                        <td className="p-2 text-center flex justify-center">
                          <button
                            className="w-[24px] h-[24px] border-2 rounded-lg p-3 flex items-center justify-center hover:bg-gray-100"
                            onClick={(e) => {
                              setAnchorElProduct(e.currentTarget);
                              setUpdateValue({
                                id: item.id,
                                name: item.name,
                                email: item.email,
                                address: item.address,
                                phone: item.phone,
                              });
                            }}
                          >
                            <MoreHorizIcon sx={{ color: "black" }} />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : isCustomerLoading ? (
                  <tr>
                    <td className="p-2 text-center border-2" colSpan={9}>
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="p-2 text-center border-2" colSpan={9}>
                      Tidak ada pelanggan
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {customers &&
      customers.detail &&
      !isCustomerLoading &&
      customers.detail.total_customer > 0 ? (
        <PagePagination
          offset={offset}
          setOffset={(e) => {
            setOffset(e);
          }}
          limit={limit}
          setLimit={(e) => {
            setLimit(e);
          }}
          total={customers.detail.total_customer}
        />
      ) : null}

      <Popover
        anchorEl={anchorElProduct}
        open={Boolean(anchorElProduct)}
        onClose={() => setAnchorElProduct(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          className="p-2 border-b-2 cursor-pointer hover:bg-gray-100"
          onClick={() => {
            setShowModal(true);
            setAnchorElProduct(null);
          }}
        >
          Edit
        </div>
        <div
          className="p-2 cursor-pointer hover:bg-gray-100"
          onClick={() => {
            setShowDeleteModal(true);
            setAnchorElProduct(null);
          }}
        >
          Hapus
        </div>
      </Popover>
      <CreateCustomerModal
        isAddCustomerOpen={isAddCustomerOpen}
        setIsAddCustomerOpen={setIsAddCustomerOpen}
      />
    </>
  );
};

export default Customer;
