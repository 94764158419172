import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FaRegTrashAlt } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { deletePatientCareSpecialization } from "../../../../services/patientCare";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeleteSpecialization = ({ open, setOpen, spec }) => {
  const { mutate: mutateDelete, isLoading } = useMutation({
    mutationFn: deletePatientCareSpecialization,
    onSuccess: () => {
      setOpen(false);
    },
  });

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          ...style,
          width: "50%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "85vh",
          padding: 0,
        }}
        className="text-left"
      >
        <div className="p-8">
          <div className="flex justify-between items-center">
            <div className="w-14 h-14 bg-red-100 p-2 flex items-center justify-center rounded-full">
              <div className="w-full h-full bg-red-300 p-2 flex items-center justify-center rounded-full">
                <FaRegTrashAlt className="w-full h-full text-red-500" />
              </div>
            </div>
            <RxCross1
              className="hover:text-red-500 cursor-pointer w-[14px] h-full"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="mt-8 mb-4">
            <p className="text-lg font-bold mb-2">Delete Specialization</p>
            <p>The deleted specialization can't be recovered</p>
          </div>

          <div className="w-full grid grid-cols-1 gap-2">
            <button
              type="button"
              onClick={() => mutateDelete(spec.id)}
              disabled={isLoading}
              className="disabled:bg-gray-300 disabled:cursor-default p-2 bg-red-500 text-white rounded-md hover:bg-red-700 cursor-pointer w-full flex items-center gap-2 justify-center"
            >
              {isLoading ? <CircularProgress size={20} /> : <p>Delete</p>}
            </button>
            <button
              className="p-2 border-2 rounded-md hover:bg-gray-100 cursor-pointer w-full"
              onClick={() => setOpen(false)}
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalDeleteSpecialization;
