import { Popover } from "@mui/material";
import { FaChevronLeft } from "react-icons/fa";

function PopoverStatusFilter({
  anchorElStatus,
  setAnchorElStatus,
  status,
  setStatus,
}) {
  return (
    <Popover
      open={Boolean(anchorElStatus)}
      anchorEl={anchorElStatus}
      onClose={() => setAnchorElStatus(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="px-8 py-2 w-full">
        <div className=" grid grid-cols-3 items-center gap-4">
          <FaChevronLeft
            className="hover:text-red-500"
            onClick={() => setAnchorElStatus(null)}
          />
          <p className="font-bold text-center">Status</p>
        </div>
      </div>
      <hr />
      <div
        className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
          status === 0 ? "bg-gray-200" : ""
        }`}
        onClick={() => setStatus(0)}
      >
        All
      </div>
      <div
        className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
          status === 1 ? "bg-gray-200" : ""
        }`}
        onClick={() => setStatus(1)}
      >
        Open
      </div>
      <div
        className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
          status === 2 ? "bg-gray-200" : ""
        }`}
        onClick={() => setStatus(2)}
      >
        Completed
      </div>
    </Popover>
  );
}

export default PopoverStatusFilter;
