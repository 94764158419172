import { Buffer } from "buffer";

function stringTo128BitArrayBuffer(str) {
  // Create a Uint8Array with 16 bytes (128 bits)
  const buffer = new ArrayBuffer(16);
  const uint8Array = new Uint8Array(buffer);

  // Convert the string to UTF-8 encoded bytes
  const encoder = new TextEncoder();
  const encodedString = encoder.encode(str);

  // Fill the Uint8Array with the encoded string bytes (up to 16 bytes)
  for (let i = 0; i < Math.min(uint8Array.length, encodedString.length); i++) {
    uint8Array[i] = encodedString[i];
  }

  // Return the ArrayBuffer
  return buffer;
}

export async function DecryptImage(encryptedText, key) {
  // Convert the Base64-encoded string to a Uint8Array
  //   const encryptedData = base64ToUint8Array(encryptedText);
  const encryptedData = Buffer.from(encryptedText, "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  // Extract the nonce (first 12 bytes), ciphertext, and authentication tag (last 16 bytes)
  const nonce = encryptedData.slice(0, 12); // First 12 bytes are the nonce
  const ciphertext = encryptedData.slice(12); // Middle part is the ciphertext
  const authTag = encryptedData.slice(-16); // Last 16 bytes are the authentication tag

  // Combine the ciphertext and authTag (since Web Crypto API handles them together)
  //   const dataToDecrypt = new Uint8Array([...ciphertext, ...authTag]);
  // const dataToDecrypt = new Uint8Array(ciphertext);
  const dataToDecrypt = new Uint8Array([...ciphertext]);

  // Convert the key to a CryptoKey object
  const encodedKey = new TextEncoder().encode(key); // UTF-8 encoding

  const cryptoKey = await crypto.subtle.importKey(
    "raw", // Raw format of the key
    encodedKey, // The raw key data
    { name: "AES-GCM" }, // The algorithm you are using
    false, // Whether the key is extractable (not needed here)
    ["decrypt"] // The operation you want to perform with the key
  );

  // Decrypt the ciphertext
  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: nonce, // The nonce
      //tagLength: 128, // The tag length (16 bytes * 8 bits/byte)
    },
    cryptoKey, // The key to use for decryption
    dataToDecrypt // The data to decrypt
  );

  // Convert the decrypted ArrayBuffer to a string
  const decryptedText = new TextDecoder().decode(decryptedBuffer);

  return decryptedText;
}
