import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getCustomerList = async (limit, offset, name, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }invoice/list-customer?limit=${limit}&offset=${offset}&name=${name}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setNewCustomer = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/customer`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const removeCustomer = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "DELETE",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/customer-delete?partner_id=${id}`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getCustomerDetail = async (id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }invoice/customer-detail?partner_id=${id}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setCustomerDetail = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/customer-update`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getPromoList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/list-promo`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const updatePromoStatus = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/update-promo`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setNewPromo = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/promo`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getProductList = async (limit, offset, search, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }invoice/get-product?limit=${limit}&offset=${offset}&keyword=${search}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getInvoiceList = async (
  limit,
  offset,
  keyword,
  status,
  warehouse
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }invoice/list-invoice?offset=${offset}&limit=${limit}&status=${status}&keyword=${keyword}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getInvoiceDetail = async (num, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }invoice/detail-invoice?invoice_number=${num}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewInvoice = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/create-invoice`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const updateInvoiceStatus = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/update-invoice`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setPPNStatus = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/setting-ppn`,
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getPPNStatus = async (warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}invoice/get-ppn?warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};
