import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";

const BillingBreadcrumb = () => {
  const navigate = useNavigate();
  const { status } = useParams();
  const { va } = useParams();

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate("/billing/unpaid")}
        >
          Billing
        </Link>
        {window.location.pathname.includes("/billing/waiting-payment") ? (
          <Link
            underline="hover"
            color="inherit"
            href="/billing/waiting-payment"
            onClick={() => navigate("/billing/waiting-payment")}
          >
            Menunggu Pembayaran
          </Link>
        ) : null}
        {window.location.pathname.includes("/billing/unpaid") ? (
          <Link
            underline="hover"
            color="inherit"
            href="/billing/unpaid"
            onClick={() => navigate("/billing/unpaid")}
          >
            Unpaid
          </Link>
        ) : null}
        {window.location.pathname.includes("/billing/paid") ? (
          <Link
            underline="hover"
            color="inherit"
            href="/billing/paid"
            onClick={() => navigate("/billing/paid")}
          >
            Paid
          </Link>
        ) : null}
        {window.location.pathname.includes("/billing/overdue") ? (
          <Link
            underline="hover"
            color="inherit"
            href="/billing/overdue"
            onClick={() => navigate("/billing/overdue")}
          >
            Overdue
          </Link>
        ) : null}

        {window.location.pathname.includes("/billing/" && status && va) ? (
          <Link
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate(`/billing/${status}/payment-summary/${va}`);
            }}
          >
            Pembayaran Billing
          </Link>
        ) : null}
        {window.location.pathname.includes("/billing/" && "/detail/") ||
        window.location.pathname.includes("invoice-detail") ? (
          <Link underline="hover" color="inherit">
            Billing Detail
          </Link>
        ) : null}
        {window.location.pathname.includes("invoice-detail") ? (
          <Link underline="hover" color="inherit">
            Detail Pesanan
          </Link>
        ) : null}
      </Breadcrumbs>
    </div>
  );
};

export default BillingBreadcrumb;
