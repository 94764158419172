import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbCustomer = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2 }}>
      <Link underline="hover" color="#1761FD" sx={{ color: "#1761FD" }}>
        Homepage
      </Link>
      {pathname.includes("/pelanggan") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/pelanggan"
          sx={{ color: "#1761FD" }}
        >
          Customer
        </Link>
      ) : null}
      {pathname.includes("/pelanggan/detail-pelanggan/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Pelanggan
        </Link>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbCustomer;
