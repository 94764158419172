import { Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const InvoiceBreadcrumb = ({ isProduct }) => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" onClick={() => navigate("/home")}>
          Homepage
        </Link>

        {window.location.pathname.includes("/invoice/invoice") ? (
          <Link
            underline="hover"
            href="/invoice/invoice"
            onClick={() => navigate("/invoice/invoice")}
          >
            Invoice
          </Link>
        ) : null}
        {window.location.pathname.includes(
          "/invoice/invoice/detail-invoice/"
        ) ? (
          <Link underline="hover">Detail Faktur</Link>
        ) : null}
        {window.location.pathname.includes(
          "/invoice/invoice/create-invoice"
        ) ? (
          <Link underline="hover">Tambah Faktur</Link>
        ) : null}
        {window.location.pathname.includes("/invoice/invoice/create-invoice") &&
        isProduct ? (
          <Link underline="hover">Tambah Produk</Link>
        ) : null}

        {window.location.pathname.includes("/invoice/customer") ? (
          <Link
            underline="hover"
            href="/invoice/customer"
            onClick={() => navigate("/invoice/customer")}
          >
            Customer
          </Link>
        ) : null}
          {window.location.pathname.includes(
          "/customer/create-customer"
        ) ? (
          <Link underline="hover">Tambah Customer</Link>
        ) : null}
          {window.location.pathname.includes(
          "/customer/detail-customer/"
        ) ? (
          <Link underline="hover">Detail Customer</Link>
        ) : null}
      </Breadcrumbs>
    </>
  );
};

export default InvoiceBreadcrumb;
