import { Stepper, Step, StepLabel } from "@mui/material";
import moment from "moment";

// import { detailStatusMapped } from "../../constants/constant";

const toDate = (date) => moment(date).format("DD MMMM YYYY, HH:mm");

const OrderStatusModal = (props) => {
  const { detailStatus } = props;

  const detailStatusMapped = {
    2: [
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    3: [
      {
        title: "Pesanan Diproses",
        status: "Pemesanan sedang diproses oleh penjual.",
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    4: [
      {
        title: "Pesanan Telah Dikirim",
        status: "Pesanan dalam proses pengiriman oleh kurir.",
      },
      {
        title: "Pesanan Diproses",
        status: "Pemesanan sedang diproses oleh penjual.",
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    5: [
      {
        title: "Transaksi Selesai",
        status: "Dana akan diteruskan ke penjual.",
      },
      {
        title: "Pesanan Telah Tiba Di tujuan",
        status:
          "Dana akan diteruskan ketika barang dikonfirmasi pembeli atau otomatis dalam 48 jam. Received by ibu yanti resepsionis",
      },
      {
        title: "Pesanan Telah Dikirim",
        status: "Pesanan dalam proses pengiriman oleh kurir.",
      },

      {
        title: "Pesanan Diproses",
        status: "Pemesanan sedang diproses oleh penjual.",
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    6: [
      {
        title: "Transaksi Ditolak",
        status: detailStatus.cancelReason,
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    7: [
      {
        title: "Transaksi Ditolak sistem",
        status: "Penjual telah melebihi batas waktu pengiriman pesanan",
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
    8: [
      {
        title: "Pesanan Diproses",
        status: "Pemesanan sedang diproses oleh penjual.",
      },
      {
        title: "Verifikasi Konfirmasi Pembayaran",
        status:
          "Pembayaran telah diterima Platform dan pesanan sudah diteruskan ke penjual.",
      },
    ],
  };

  return (
    <div>
      <div className="flex">
        <p className="text-blue-500 hover:underline cursor-pointer">
          {detailStatus.invoiceNumber}
        </p>
        <p className="mx-2">/</p>
        <p>{detailStatus.fullname}</p>
        <p className="mx-2">/</p>
        <p>{toDate(detailStatus.createdAt) + " WIB"}</p>
      </div>
      <div className="border border-black"></div>
      <Stepper orientation="vertical">
        {detailStatusMapped[detailStatus.status].map((data) => (
          <Step>
            <StepLabel>
              <div className="flex justify-between items-center w-full">
                <div>
                  <p>{data.title}</p>
                  <p>{data.status}</p>
                </div>
                <p>{toDate(detailStatus.createdAt) + " WIB"}</p>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default OrderStatusModal;
