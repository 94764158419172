import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { deletePatientCareDoctor } from "../../../../services/patientCare";
import { RxCross1 } from "react-icons/rx";
import { FaRegTrashAlt } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeleteDoctor = ({ open, setOpen, doctor, refetch }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    mutateDelete(doctor.id);
  };

  const { mutate: mutateDelete, isLoading } = useMutation({
    mutationFn: deletePatientCareDoctor,
    onSuccess: () => {
      refetch();
      setOpen(false);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (!open) {
      setErrorMessage("");
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          width: "30%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "85vh",
          padding: 0,
        }}
        className="text-left text-base"
      >
        <div className="p-8">
          <div className="flex justify-between items-center">
            <div className="w-14 h-14 bg-red-100 p-2 flex items-center justify-center rounded-full">
              <div className="w-full h-full bg-red-300 p-2 flex items-center justify-center rounded-full">
                <FaRegTrashAlt className="w-full h-full text-red-500" />
              </div>
            </div>
            <RxCross1
              className="hover:text-red-500 cursor-pointer w-[14px] h-full"
              onClick={handleClose}
            />
          </div>
          <div className="mt-8">
            <p className="text-lg font-bold mb-2">Delete Doctor</p>
            <p>Are you sure you want to delete this doctor?</p>
          </div>
          <p className="text-red-500 my-4 text-right">{errorMessage}</p>
          <>
            <div className="w-full flex items-center justify-center gap-2">
              <button
                className="p-2 border-2 rounded-md hover:bg-gray-100 cursor-pointer w-full"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDiscard}
                disabled={isLoading}
                className="disabled:bg-gray-300 disabled:cursor-default p-2 bg-red-500 text-white rounded-md hover:bg-red-700 cursor-pointer w-full flex items-center gap-2 justify-center"
              >
                {isLoading ? <CircularProgress size={20} /> : <p>Delete</p>}
              </button>
            </div>
          </>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalDeleteDoctor;
