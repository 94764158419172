import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CircularProgress, TextField } from "@mui/material";
import moment from "moment";

import { getPoDetail } from "../../../services/purchaseOrderApi";

const DetailPurchaseOrder = ({ isWarehouse }) => {
  const { param } = useParams();

  const idNum = atob(param.split("-")[0]);
  const isPartial = param.split("-")[1];

  const { data } = useQuery({
    queryFn: () =>
      getPoDetail(
        idNum,
        isPartial === 1 ? true : false,
        false,
        isWarehouse ? isWarehouse : false
      ),
    queryKey: ["po-detail"],
  });

  const productInfo = (id) => {
    return data.product.find((item) => item.id === id);
  };

  const typeStockChecker = (item) => {
    if (item.type_stock === 0) {
      return item.packaging?.type_box;
    } else if (item.type_stock === 1) {
      return item.packaging?.type_strip;
    } else {
      return item.packaging?.type_unit;
    }
  };

  return (
    <>
      <div className="shadow-md rounded-md p-4">
        <div className="flex justify-between">
          <p className="text-blue-500 font-bold pt-2">Create Purchase Order</p>
          <p
            className={`border-2 rounded-md py-2 px-12 ${
              data && data !== null
                ? data.is_invoice
                  ? "bg-green-100"
                  : "bg-red-200"
                : "bg-gray-100"
            }`}
          >
            {data && data !== null ? (
              data.is_invoice ? (
                "Invoiced"
              ) : (
                "Not Invoiced"
              )
            ) : (
              <CircularProgress size={20} />
            )}
          </p>
        </div>
        <hr className="my-2" />
        {data && data !== null ? (
          <>
            <div className="lg:flex gap-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">Input PO Date</div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    disabled
                    value={moment(data.po_date).format("DD MMMM YYYY")}
                  />
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">PO Number</div>
                <div className="w-full">
                  <TextField
                    disabled
                    fullWidth
                    sx={{ backgroundColor: "#d8d8d8" }}
                    value={data.po_number}
                  />
                </div>
              </div>
            </div>
            <div className="lg:flex gap-2 mt-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">
                  Nama Distributor / Principal
                </div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={data.distribution.name}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">
                  No. Telp. Distributor / Principal
                </div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={data.distribution.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="w-full font-bold mt-4 mb-2">
              Alamat Distributor / Principal
            </div>
            <div className="w-full">
              <TextField
                fullWidth
                multiline
                rows={4}
                value={data.distribution.address}
                disabled
              />
            </div>

            <hr className="my-4" />
            <p className="font-bold">Produk List</p>

            <div className="shadow-md rounded-md p-4 mt-4">
              <table className="w-full">
                <tr className="font-bold">
                  <th className="border p-2">Nama Produk</th>
                  <th className="border p-2">Kuantitas</th>
                  <th className="border p-2">Kemasan</th>
                </tr>
                {data.product !== null && data.product.length > 0
                  ? data.product.map((item, i) => (
                      <tr key={i}>
                        <td className="border p-2">
                          {productInfo(item.id).name}
                        </td>
                        <td className="border p-2">{item.po_stock}</td>
                        <td className="border p-2">{typeStockChecker(item)}</td>
                      </tr>
                    ))
                  : null}
              </table>
            </div>
          </>
        ) : (
          <CircularProgress size={20} />
        )}
      </div>
    </>
  );
};

export default DetailPurchaseOrder;
