import React, { useEffect, useState } from "react";
import { CancelRounded, Close, Save } from "@mui/icons-material";
import { CircularProgress, TextField } from "@mui/material";
import moment from "moment";
import { BsPencil } from "react-icons/bs";
import { useMutation } from "@tanstack/react-query";
import { setUpdateBatch } from "../../../services/consignmentApi";
import { IGNORE_TYPE } from "../../constants/constant";

export default function DetailStockConsignment({
  showModal,
  setShowModal,
  stockArr,
  setReload,
  isFetching,
}) {
  const [isEdit, setIsEdit] = useState([...Array(stockArr.length).fill(false)]);
  const [oldBatchNum, setOldBatchNum] = useState("");
  const [newBatchNum, setNewBatchNum] = useState("");
  const [prodId, setProdId] = useState(0);

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return (
        primaryCount(stock, item) + " " + item.packing.type_strip.toUpperCase()
      );
    } else {
      return (
        primaryCount(stock, item) + " " + item.packing.type_box.toUpperCase()
      );
    }
  };

  const primaryCount = (stock, item) => {
    if (
      IGNORE_TYPE.includes(item.packing.type_unit) &&
      item.packing.total_strip > 0
    ) {
      return Math.floor(stock / item.packing.total_strip);
    }
    if (item.packing.total_strip > 0 && item.packing.total_unit > 0) {
      return Math.floor(
        stock / (item.packing.total_strip * item.packing.total_unit),
      );
    } else {
      return stock;
    }
  };

  const handleChange = (status, index, batchNum, id) => {
    var temp = [...isEdit];
    temp[index] = status;
    setIsEdit(temp);
    if (batchNum) setOldBatchNum(batchNum);
    if (id) setProdId(Number(id));
    if (!status) {
      setOldBatchNum("");
      setNewBatchNum("");
    }
  };

  const handleUpdateBatch = () => {
    const body = {
      product_id: prodId,
      batch_number: oldBatchNum,
      new_batch: newBatchNum ? newBatchNum : oldBatchNum,
    };
    mutateNewBatch(body);
  };

  const { mutate: mutateNewBatch } = useMutation({
    mutationFn: setUpdateBatch,
    onSuccess: () => {
      setReload(true);
      setIsEdit([...Array(isEdit.length).fill(false)]);
    },
  });

  useEffect(() => {
    if (showModal) {
      setIsEdit([...Array(stockArr.length).fill(false)]);
    }
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[80%] my-6 mx-auto max-w-3xl">
              {/*content*/}
              {!isFetching ? (
                <div className="h-full max-h-[80vh] overflow-y-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="absolute top-12 w-[calc(100%-48px)] font-bold text-lg text-center -z-2">
                      Detail Stock
                    </p>
                    <div
                      className="w-full flex justify-end cursor-pointer hover:text-gray-200"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      <Close className="" />
                    </div>
                    <div className="mt-12 mb-4 block overflow-x-auto rounded-md">
                      <table className="w-full">
                        <thead className="text-center border-b-2">
                          <tr>
                            <th className="p-2">Nomor Batch</th>
                            <th className="p-2">Tanggal Input</th>
                            <th className="p-2">Kadarluarsa</th>
                            <th className="p-2">Stock</th>
                            <th className="p-2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stockArr && stockArr !== null ? (
                            stockArr.map((item, i) => {
                              return (
                                <tr className="align-middle">
                                  <td className="p-2 text-center">
                                    {isEdit[i] ? (
                                      <TextField
                                        defaultValue={item.number_batch}
                                        onChange={(e) => {
                                          e.target.value === ""
                                            ? setNewBatchNum(item.number_batch)
                                            : setNewBatchNum(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      item.number_batch
                                    )}
                                  </td>
                                  <td className="p-2 text-center">
                                    {moment(item.tanggal_input).format(
                                      "DD MMMM YYYY",
                                    )}
                                  </td>
                                  <td className="p-2 text-center">
                                    {moment(item.expired_date).format(
                                      "DD MMMM YYYY",
                                    )}
                                  </td>
                                  <td className="p-2 text-center">
                                    {primaryStock(item.stock, item)}
                                  </td>
                                  <td className="p-2 flex justify-center items-center table-cell">
                                    <div className="w-full flex justify-center">
                                      {isEdit[i] ? (
                                        <div className="flex items-center gap-2">
                                          <CancelRounded
                                            className="text-gray-500 hover:text-gray-700 hover:scale-125 cursor-pointer"
                                            onClick={() =>
                                              handleChange(false, i)
                                            }
                                          />
                                          <Save
                                            className="text-blue-500 hover:text-blue-700 hover:scale-125 cursor-pointer"
                                            onClick={handleUpdateBatch}
                                          />
                                        </div>
                                      ) : (
                                        <BsPencil
                                          className="text-blue-500 hover:text-blue-700 hover:scale-125 cursor-pointer"
                                          onClick={() => {
                                            handleChange(
                                              true,
                                              i,
                                              item.number_batch,
                                              item.product_id,
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : stockArr && stockArr === null ? (
                            <tr>
                              <td
                                colSpan={5}
                                className="text-red-500 py-4 text-center"
                              >
                                Tidak ada data stock
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td
                                colSpan={5}
                                className="text-red-500 py-4 text-center"
                              >
                                <CircularProgress size={20} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <CircularProgress size={20} />
              )}
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
