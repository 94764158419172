import { useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Pagination,
  TextField,
  CircularProgress,
} from "@mui/material";

const FETCH_LIMIT = 10;

const CreateReturProductModal = (props) => {
  const {
    productReturList,
    isProductReturListLoading,
    IGNORE_TYPE,
    buffer,
    setBuffer,
    productReturListBuffer,
    setProductBufferListBuffer,
    offset,
    setOffset,
    searchProduct,
    setSearchProduct,
    debounce,
  } = props;

  const pageCount = useMemo(() => {
    if (productReturList !== undefined) {
      return Math.ceil(productReturList.count / FETCH_LIMIT);
    }
    return 0;
  }, [productReturList]);

  useEffect(() => {
    if (debounce.length === 0) {
      setOffset(1);
    }
  }, [debounce.length, setOffset]);

  return (
    <div className="w-full">
      <TextField
        fullWidth
        sx={{ marginTop: 3 }}
        label="Search produk"
        value={searchProduct}
        onChange={(e) => setSearchProduct(e.target.value)}
      />
      {productReturListBuffer && productReturListBuffer !== undefined ? (
        <div>
          {isProductReturListLoading ? (
            <div className="flex justify-center mt-3">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="mt-3">
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#007AF1" }}>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nama Produk
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nomor Batch
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Tanggal Kadaluarsa
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nomor Invoice
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Kuantitas
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productReturListBuffer.map((data, index) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="col" align="center">
                        <Checkbox
                          checked={
                            data.isChecked ||
                            buffer
                              .map((item) => item.iteration)
                              .includes(data.iteration)
                          }
                          onChange={() => {
                            setProductBufferListBuffer((prevValue) =>
                              prevValue.map((item) => {
                                return data.iteration === item.iteration
                                  ? {
                                      ...item,
                                      isChecked: !item.isChecked,
                                    }
                                  : item;
                              }),
                            );
                            if (!data.isChecked) {
                              setBuffer((prevValue) => [
                                ...prevValue,
                                {
                                  iteration: data.iteration,
                                  invoice_number: data.invoice_number,
                                  product_id: data.product_detail_id,
                                  product_new_id: data.product_new_id,
                                  product_name: data.product_name,
                                  batch_number: data.batch_number,
                                  expired_date: data.expired_date,
                                  product_new_stock: data.product_new_stock,
                                  retur_quantity: 0,
                                  quantity: 0,
                                  pharmacy_net_price: data.pharmacy_net_price,
                                  sell_price: data.sell_price,
                                  packaging: data.packaging,
                                  type_packaging: "",
                                },
                              ]);
                            } else {
                              setBuffer((prevValue) =>
                                prevValue.filter(
                                  (item) => item.iteration !== data.iteration,
                                ),
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{data.product_name}</TableCell>
                      <TableCell align="center">{data.batch_number}</TableCell>
                      <TableCell align="center">{data.expired_date}</TableCell>
                      <TableCell align="center">
                        {data.invoice_number}
                      </TableCell>
                      <TableCell align="center">
                        {data.product_new_stock}{" "}
                        {IGNORE_TYPE.includes(data.packaging?.type_unit)
                          ? data.packaging?.type_strip
                          : data.packaging?.type_unit}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isProductReturListLoading ? null : (
            <div className="flex justify-center mt-[10px]">
              <Pagination
                count={pageCount}
                page={offset}
                onChange={(_, value) => setOffset(value)}
                shape="rounded"
                size="large"
                color="primary"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-10">
          <p>Produk tidak ditemukan!</p>
        </div>
      )}
    </div>
  );
};

export default CreateReturProductModal;
