import React from "react";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import Header from "../../../components/ui/Header";
import LogShift from "../../../components/screen/Reporting/LogShift/LogShift";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const LogShiftPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <LogShift />
        </div>
      </div>
    </div>
  );
};

export default LogShiftPage;
