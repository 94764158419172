import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Snackbar, IconButton, CircularProgress } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import logoOlin from "../../../../assets/icons/logoOlin.png";
import HelpInformationModal from "../../../common/particles.jsx/HelpInformationModal";

const SEND_VERIFICATION_RETRY_DELAY_SECONDS = 60;

const OtpConfirmationPurchase = (props) => {
  const {
    checkoutCartData,
    payment,
    shipping,
    selectedMethod,
    setSelectedMethod,
    totalPrice,
    tax,
    mutateOtpCheckout,
    mutateOtpCheckoutVerify,
    countdownSeconds,
    setIsOtpConfirmed,
    setIsPressed,
    setCountdownSeconds,
    successMessage,
    setSuccessMessage,
    filteredVoucher,
    isOtpVerifyLoading,
  } = props;

  const [isHelpInformationModalOpen, setIsHelpInformationModalOpen] =
    useState(false);

  return (
    <>
      <div className="border border-black rounded-md p-3 font-semibold">
        {checkoutCartData && checkoutCartData !== undefined ? (
          <>
            <div className="flex items-center">
              <button
                onClick={() => {
                  setIsOtpConfirmed(false);
                  setIsPressed(false);
                  setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
                  sessionStorage.clear();
                }}
                className="mr-3"
              >
                <AiOutlineArrowLeft className="text-[30px]" />
              </button>
              <img src={logoOlin} />
            </div>
            <div className="my-3">
              <p>Kode OTP</p>
              <p>
                Silahkan masukkan kode OTP yang OLIN kirimkan ke alamat email
                Owner untuk menverifikasi transaksi ini
              </p>
            </div>
            <div className="flex">
              <input
                type="text"
                className="rounded-md mr-3 border border-black p-2 w-full"
                value={selectedMethod.code}
                onChange={(e) =>
                  setSelectedMethod({ ...selectedMethod, code: e.target.value })
                }
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxLength="4"
              />
              {isOtpVerifyLoading ? (
                <button className="bg-[#007AF1] px-5 py-3 rounded-md text-white font-semibold">
                  <CircularProgress sx={{ color: "white" }} size={23} />
                </button>
              ) : (
                <button
                  className="bg-[#007AF1] p-3 rounded-md text-white font-semibold disabled:text-gray-500 disabled:bg-gray-300"
                  disabled={selectedMethod.code.length < 4}
                  onClick={() =>
                    mutateOtpCheckoutVerify({
                      ...selectedMethod,
                      ppn: tax,
                      shipping_fee: shipping.price,
                      voucher_id: filteredVoucher,
                    })
                  }
                >
                  Konfirmasi
                </button>
              )}
            </div>
            <div className="flex">
              <p>Tidak menerima Kode OTP?&nbsp;</p>
              {countdownSeconds === SEND_VERIFICATION_RETRY_DELAY_SECONDS ? (
                <p
                  className="text-[#FF8D40] hover:underline hover:cursor-pointer"
                  onClick={() => mutateOtpCheckout()}
                >
                  Kirim Ulang
                </p>
              ) : (
                <p>
                  {new Date(countdownSeconds * 1000)
                    .toISOString()
                    .substring(14, 19)}
                </p>
              )}
            </div>
            <div className="border-2 border-dashed border-gray-200"></div>
            <div className="mt-3">
              <p>Rincian Pesanan</p>
              <div className="border border-dashed border-black my-3"></div>
              {checkoutCartData.address &&
              checkoutCartData.address !== undefined ? (
                <div className="flex justify-between">
                  <div>
                    <p>Informasi Pengiriman</p>
                    <div className="flex flex-wrap w-full">
                      <p>
                        {checkoutCartData.address.company_name} |{" "}
                        {checkoutCartData.address.phone}{" "}
                        {checkoutCartData.address.district},{" "}
                        {checkoutCartData.address.business_address}
                        {checkoutCartData.address.sub_district},
                        {checkoutCartData.address.province},
                        {checkoutCartData.address.kode_pos}
                      </p>
                    </div>
                  </div>
                  <div className="gap-2 flex flex-col font-medium"></div>
                  <div className="w-full text-center">
                    <p>Metode Pembayaran</p>
                    <p>{payment.name}</p>
                  </div>
                  <div className="w-full text-center">
                    <p>Metode Pengiriman</p>
                    <p>{shipping.name}</p>
                  </div>
                </div>
              ) : null}
              <div></div>
            </div>
            <div className="mt-3">
              <p>Rincian Pembayaran</p>
              <div className="border border-dashed border-black my-3"></div>
              <div className="font-bold flex justify-between mt-3">
                <div>
                  <p>Total Belanja</p>
                  <p className="mt-3">Biaya Pengiriman</p>
                  {selectedMethod.total_discount !== 0 ? (
                    <p className="mt-3">Total Diskon</p>
                  ) : null}
                  <p className="my-3">PPN 11%</p>
                  {selectedMethod.use_point ? (
                    <p className="mb-3">Olin Point</p>
                  ) : null}
                  <p className="mb-3">Biaya Admin</p>
                  <p>Total Pembayaran</p>
                </div>
                <div className="gap-y-3">
                  {selectedMethod.total_discount !== 0 ? (
                    <div className="flex">
                      <p className="text-gray-400 line-through">
                        {(
                          checkoutCartData?.result?.total -
                          selectedMethod.total_discount
                        ).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                      <p>
                        &nbsp;
                        {checkoutCartData?.result?.total?.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </p>
                    </div>
                  ) : (
                    <p>
                      {checkoutCartData?.result?.total?.toLocaleString(
                        "id-ID",
                        {
                          style: "currency",
                          currency: "IDR",
                        }
                      )}
                    </p>
                  )}
                  <p className="mt-3">
                    {shipping.price === 0
                      ? "Gratis"
                      : shipping.price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                  </p>
                  {selectedMethod.total_discount !== 0 ? (
                    <p className="my-3">
                      -{" "}
                      {selectedMethod.total_discount.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  ) : null}
                  <p className="my-3">
                    {" "}
                    {Math.round(tax).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                  {selectedMethod.use_point ? (
                    <p className="mb-3"> {checkoutCartData.loyalty_point}</p>
                  ) : null}
                  <p className="mb-3">
                    {" "}
                    {payment.fee?.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                  <p>
                    {" "}
                    {Math.round(totalPrice).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessMessage("");
          }}
          message={successMessage}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(_, reason) => {
                  if (reason === "clickaway") return;
                  setSuccessMessage("");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
      </div>
      <button
        className="w-full border-2 border-gray-400 font-medium text-gray-400 p-2 mt-2 text-center rounded-md"
        onClick={() => setIsHelpInformationModalOpen(true)}
      >
        Halaman Bantuan
      </button>
      <HelpInformationModal
        isModalOpen={isHelpInformationModalOpen}
        setIsModalOpen={setIsHelpInformationModalOpen}
      />
    </>
  );
};

export default OtpConfirmationPurchase;
