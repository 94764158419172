import { useState, useEffect, createContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchPaymentDetail,
  paymentCheck,
} from "../../../../services/purchaseApi";

import VaPaymentMethod from "./PaymentMethod/VaPaymentMethod";
import QrisPaymentMethod from "./PaymentMethod/QrisPaymentMethod";

export const PaymentContext = createContext();

const PurchasePayment = () => {
  const { invoice = "" } = useParams();
  const navigate = useNavigate();

  const [time, setTime] = useState(0);

  const { mutate: mutatePaymentDetail, data: paymentDetailData } = useMutation({
    mutationFn: fetchPaymentDetail,
    onSuccess: (data) => setTime(data.expired),
  });

  const { mutate: mutatePaymentCheck } = useMutation({
    mutationFn: paymentCheck,
    onSuccess: (data) => {
      if (!data) navigate("/purchase/waiting-payment");
      else navigate("/purchase/list-transaction");
    },
  });

  useEffect(() => {
    mutatePaymentDetail({ invoice: invoice });
  }, [invoice]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime((prevValue) => prevValue - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);

  const hours = Math.floor(Math.floor(time / 3600) / 1000);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const formattedTime = `${hours} jam ${minutes} menit ${seconds} detik`;

  return (
    <PaymentContext.Provider
      value={{
        invoice,
        mutatePaymentCheck,
        paymentDetailData,
        formattedTime,
      }}
    >
      {paymentDetailData && paymentDetailData !== undefined ? (
        <div className="mt-3">
          {paymentDetailData.hasOwnProperty("va_numbers") && (
            <VaPaymentMethod />
          )}
          {paymentDetailData.hasOwnProperty("qris") && <QrisPaymentMethod />}
        </div>
      ) : null}
    </PaymentContext.Provider>
  );
};

export default PurchasePayment;
