import React, { useState, createContext } from "react";
import moment from "moment";

const timeNow = moment().format("HH:mm");
export const ChatboxContext = createContext({
  isLogin: false,
  setIsLogin: () => {},
  isChatBoxOpen: false,
  setIsChatBoxOpen: () => {},
  chatboxResponse: [],
  setChatboxResponse: () => {},
  productName: "",
  setProductName: () => {},
  buyerName: "",
  setBuyerName: () => {},
});

const ChatboxProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [chatboxResponse, setChatboxResponse] = useState([]);
  const [productName, setProductName] = useState("");
  const [buyerName, setBuyerName] = useState("");

  return (
    <ChatboxContext.Provider
      value={{
        isLogin,
        setIsLogin,
        isChatBoxOpen,
        setIsChatBoxOpen,
        chatboxResponse,
        setChatboxResponse,
        productName,
        setProductName,
        buyerName,
        setBuyerName,
      }}
    >
      {children}
    </ChatboxContext.Provider>
  );
};

export default ChatboxProvider;
