import {
  FormControl,
  FormLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { LuImagePlus } from "react-icons/lu";
import { MdOutlineHideImage, MdCancel } from "react-icons/md";
import { DecryptImage } from "../../../../utils/DecryptImage";
import cornerstone, { loadImage } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import dicomParser from "dicom-parser";
// import "cornerstone-tools/dist/cornerstoneTools.css";

// Set up the CornerstoneWADOImageLoader (using WADO-URI)
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import { useParams } from "react-router-dom";
import {
  deleteImageDicom,
  uploadImageDicom,
} from "../../../../services/patientCare";
import { useMutation } from "@tanstack/react-query";

// Initialize CornerstoneWADOImageLoader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const toDate = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const SubjectiveObjectiveAnalysis = ({
  formik,
  isDetail,
  selectedPatient,
  selectedDoctor,
  status,
}) => {
  const { id } = useParams();
  const cornerstoneRefs = useRef([]);
  const cornerstoneRefsExam = useRef([]);

  const [arrDicom, setArrDicom] = useState([]);
  const [arrDicomExam, setArrDicomExam] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);

  const decryptText = async (encryptText) => {
    try {
      const decrypted = await DecryptImage(
        encryptText,
        process.env.REACT_APP_SECRET_KEY_IMG
      );
      return decrypted;
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  const { mutate: mutateUploadImg, isLoading: isLoadingImg } = useMutation({
    mutationFn: uploadImageDicom,
    onSuccess: (values) => {
      formik.setFieldValue("img", [...formik.values.img, ...values.url]);
    },
  });

  const { mutate: mutateDeleteImg, isLoading: isLoadingDeleteImg } =
    useMutation({
      mutationFn: deleteImageDicom,
      onSuccess: () => {
        formik.setFieldValue("img", [
          ...formik.values.img.slice(0, selectedImg),
          ...formik.values.img.slice(selectedImg + 1),
        ]);
        setSelectedImg(null);
      },
      onError: () => {
        setSelectedImg(null);
      },
    });

  const { mutate: mutateUploadImgExam, isLoading: isLoadingImgExam } =
    useMutation({
      mutationFn: uploadImageDicom,
      onSuccess: (values) => {
        formik.setFieldValue("exam_img", [
          ...formik.values.exam_img,
          ...values.url,
        ]);
      },
    });

  const { mutate: mutateDeleteImgExam, isLoading: isLoadingDeleteImgExam } =
    useMutation({
      mutationFn: deleteImageDicom,
      onSuccess: () => {
        formik.setFieldValue("img", [
          ...formik.values.exam_img.slice(0, selectedImg),
          ...formik.values.exam_img.slice(selectedImg + 1),
        ]);
        setSelectedImg(null);
      },
      onError: () => {
        setSelectedImg(null);
      },
    });

  useEffect(() => {
    // Decrypting images
    const loadImages = async (imgArr, setDicom, refElem) => {
      imgArr.map(async (encryptedUrl, index) => {
        const decryptedUrl = await decryptText(encryptedUrl);

        if (decryptedUrl) {
          const element = refElem.current[index];
          cornerstone.enable(element);
          const imageId = `wadouri:${decryptedUrl}`;

          cornerstone
            .loadImage(imageId)
            .then((image) => {
              setDicom((prevArrDicom) => [...prevArrDicom, image]);
              cornerstone.displayImage(element, image);
            })
            .catch((err) => {
              console.error("Error loading DICOM image:", err);
              setDicom((prevArrDicom) => [...prevArrDicom, null]);
            });
        }
      });
    };

    loadImages(formik.values.img, setArrDicom, cornerstoneRefs);
    loadImages(formik.values.exam_img, setArrDicomExam, cornerstoneRefsExam);
  }, [
    formik.values.img,
    formik.values.exam_img,
    cornerstoneRefs,
    cornerstoneRefsExam,
  ]);

  return (
    <>
      <div className="flex gap-2 mb-4">
        <div className="w-full">
          <p className="font-bold mb-2">Patient Information</p>
          {selectedPatient ? (
            <>
              {" "}
              <div className="flex gap-2">
                <p className="w-1/3">No Rekam Medis</p>
                <p>:</p>
                <p>{selectedPatient.medical_number}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">Tanggal Lahir</p>
                <p>:</p>
                <p>{selectedPatient.date_of_birth}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">Gender</p>
                <p>:</p>
                <p>
                  {selectedPatient.gender === 1 ? "Laki-laki" : "Perempuan"}
                </p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">No Telepon</p>
                <p>:</p>
                <p>
                  {selectedPatient.phone_number === ""
                    ? "-"
                    : selectedPatient.phone_number}
                </p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">Email</p>
                <p>:</p>
                <p>
                  {selectedPatient.email === "" ? "-" : selectedPatient.email}
                </p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">NIK</p>
                <p>:</p>
                <p>{selectedPatient.nik}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">Alamat</p>
                <p>:</p>
                <p>{selectedPatient.address}</p>
              </div>
            </>
          ) : (
            <p className="text-gray-300">Select a patient</p>
          )}
        </div>
        <div className="w-full">
          <p className="font-bold mb-2">Doctor Information</p>
          {selectedDoctor ? (
            <>
              {" "}
              <div className="flex gap-2">
                <p className="w-1/3">Nama Dokter</p>
                <p>:</p>
                <p>{selectedDoctor.name}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">No SIP</p>
                <p>:</p>
                <p>{selectedDoctor.sip === "" ? "-" : selectedDoctor.sip}</p>
              </div>
              <div className="flex gap-2">
                <p className="w-1/3">Specialization</p>
                <p>:</p>
                <p>
                  {selectedDoctor.specialization === ""
                    ? "-"
                    : selectedDoctor.specialization}
                </p>
              </div>
            </>
          ) : (
            <p className="text-gray-300">Select a doctor</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <p className="font-bold text-lg mb-4">Subjective (S)</p>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Riwayat Kelahiran</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.birth_history}
              onChange={(e) =>
                formik.setFieldValue("subjective.birth_history", e.target.value)
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Keluhan Utama</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.main_complaint}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.main_complaint",
                  e.target.value
                )
              }
            />
          </FormControl>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Riwayat Penyakit Sebelumnya</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.existing_illness}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.existing_illness",
                  e.target.value
                )
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Riwayat Sosial</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.social_history}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.social_history",
                  e.target.value
                )
              }
            />
          </FormControl>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Riwayat Tumbuh Kembang</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.development_history}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.development_history",
                  e.target.value
                )
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Riwayat Keluarga</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.family_history}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.family_history",
                  e.target.value
                )
              }
            />
          </FormControl>
        </div>

        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "50%" }}>
            <FormLabel>Konsumsi Obat Saat Ini</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.subjective.current_medication}
              onChange={(e) =>
                formik.setFieldValue(
                  "subjective.current_medication",
                  e.target.value
                )
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="mb-4">
        <p className="font-bold text-lg mb-4">Objective (O)</p>
        <p className="font-bold mb-2">Tanda-tanda Vital</p>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Tekanan Darah (Diastolik)</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.diastole}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (Number(numericValue > 0) || numericValue === "") {
                    formik.setFieldValue(
                      "objective.diastole",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>mmHG</p>
            </div>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Tekanan Darah (Sistolik)</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.systole}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (Number(numericValue > 0) || numericValue === "") {
                    formik.setFieldValue(
                      "objective.systole",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>mmHG</p>
            </div>
          </FormControl>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Suhu Tubuh</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.body_temp}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (Number(numericValue > 0) || numericValue === "") {
                    formik.setFieldValue(
                      "objective.body_temp",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>&#176;C</p>
            </div>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Denyut Nadi</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.pulse}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (Number(numericValue > 0) || numericValue === "") {
                    formik.setFieldValue(
                      "objective.pulse",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>x/menit</p>
            </div>
          </FormControl>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Nafas</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.respiratory_rate}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (Number(numericValue > 0) || numericValue === "") {
                    formik.setFieldValue(
                      "objective.respiratory_rate",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>x/menit</p>
            </div>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Kadar Oksigen</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                placeholder="-"
                disabled={isDetail}
                sx={{ width: "75%" }}
                value={formik.values.objective.oxygen}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(",", ".");
                  if (
                    (Number(numericValue > 0) && Number(numericValue <= 100)) ||
                    numericValue === ""
                  ) {
                    formik.setFieldValue(
                      "objective.oxygen",
                      numericValue.includes(".") && numericValue !== ""
                        ? numericValue.replace(".", ",")
                        : numericValue
                    );
                  }
                }}
              />
              <p>Sp02 (%)</p>
            </div>
          </FormControl>
        </div>
      </div>
      <p className="font-bold mb-2">Nyeri</p>
      <div className="flex gap-2 mb-4">
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Tingkat Nyeri</FormLabel>
          <div className="flex gap-2 items-center">
            <TextField
              placeholder="-"
              disabled={isDetail}
              sx={{ width: "75%" }}
              value={formik.values.objective.pain_level}
              onChange={(e) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  Number(e.target.value) <= 10
                )
                  formik.setFieldValue("objective.pain_level", e.target.value);
              }}
            />
            <p>1 s/d 10</p>
          </div>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Lokasi Nyeri</FormLabel>
          <TextField
            placeholder="-"
            disabled={isDetail}
            sx={{ width: "75%" }}
            value={formik.values.objective.pain_location}
            onChange={(e) =>
              formik.setFieldValue("objective.pain_location", e.target.value)
            }
          />
        </FormControl>
      </div>
      <p className="font-bold mb-2">Lainnya</p>
      <div className="flex gap-2 mb-4">
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Lingkar Kepala</FormLabel>
          <div className="flex gap-2 items-center">
            <TextField
              placeholder="-"
              disabled={isDetail}
              sx={{ width: "75%" }}
              value={formik.values.objective.head_circumference}
              onChange={(e) => {
                let numericValue = e.target.value.replace(",", ".");
                if (Number(numericValue > 0) || numericValue === "") {
                  formik.setFieldValue(
                    "objective.head_circumference",
                    numericValue.includes(".") && numericValue !== ""
                      ? numericValue.replace(".", ",")
                      : numericValue
                  );
                }
              }}
            />
            <p>cm</p>
          </div>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Berat Badan</FormLabel>
          <div className="flex gap-2 items-center">
            <TextField
              placeholder="-"
              disabled={isDetail}
              sx={{ width: "75%" }}
              value={formik.values.objective.weight}
              onChange={(e) => {
                let numericValue = e.target.value.replace(",", ".");
                if (Number(numericValue > 0) || numericValue === "") {
                  formik.setFieldValue(
                    "objective.weight",
                    numericValue.includes(".") && numericValue !== ""
                      ? numericValue.replace(".", ",")
                      : numericValue
                  );
                }
              }}
            />
            <p>kg</p>
          </div>
        </FormControl>
      </div>
      <div className="flex gap-2 mb-4">
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Tinggi Badan</FormLabel>
          <div className="flex gap-2 items-center">
            <TextField
              placeholder="-"
              disabled={isDetail}
              sx={{ width: "75%" }}
              value={formik.values.objective.height}
              onChange={(e) => {
                let numericValue = e.target.value.replace(",", ".");
                if (Number(numericValue > 0) || numericValue === "") {
                  formik.setFieldValue(
                    "objective.height",
                    numericValue.includes(".") && numericValue !== ""
                      ? numericValue.replace(".", ",")
                      : numericValue
                  );
                }
              }}
            />
            <p>cm</p>
          </div>
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Pemeriksaan Fisik</FormLabel>
          <TextField
            placeholder="-"
            disabled={isDetail}
            sx={{ width: "75%" }}
            value={formik.values.objective.physical_exam}
            onChange={(e) =>
              formik.setFieldValue("objective.physical_exam", e.target.value)
            }
          />
        </FormControl>
      </div>
      <div className="mb-4">
        <FormControl sx={{ width: "100%" }}>
          <FormLabel>Pemeriksaan Penunjang</FormLabel>
          <TextField
            placeholder="-"
            disabled={isDetail}
            sx={{ width: "87.5%" }}
            value={formik.values.objective.support_exam}
            onChange={(e) =>
              formik.setFieldValue("objective.support_exam", e.target.value)
            }
          />
        </FormControl>
      </div>
      <div className="w-full mb-4">
        <p className="font-bold text-lg">Hasil Laboratorium</p>
        <hr />
        <div className="w-full grid grid-cols-2 lg:grid-cols-5 gap-3 mt-2">
          {formik.values.img.length > 0 ? (
            formik.values.img.map((item, i) => (
              <div className="relative">
                <div className="w-full h-[200px]">
                  <div
                    key={i}
                    ref={(element) => (cornerstoneRefs.current[i] = element)}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      objectFit: "contain",
                    }}
                  >
                    {arrDicom[i] ? null : (
                      <div className="flex justify-center items-center w-full h-[200px] p-3">
                        <MdOutlineHideImage className="w-[48px] h-[48px]" />
                      </div>
                    )}
                  </div>
                </div>
                {isDetail ? null : (
                  <MdCancel
                    className="cursor-pointer text-red-500 bg-white rounded-full absolute -top-2 -right-3 w-[24px] h-[24px]"
                    onClick={() => {
                      mutateDeleteImg(formik.values.img[i]);
                      setSelectedImg(i);
                    }}
                  />
                )}
              </div>
            ))
          ) : isDetail ? (
            <p className="col-span-5">Tidak ada hasil laboratorium</p>
          ) : null}

          {isDetail ? null : (
            <>
              <input
                type="file"
                id="doctor-img-upload"
                hidden
                accept="*/dicom,.dcm, image/dcm, */dcm, .dicom"
                onChange={(e) => {
                  mutateUploadImg(e.target.files[0]);
                }}
              />
              <label for="doctor-img-upload">
                <div className="w-full h-[200px] flex justify-center items-center text-center rounded-md border-2 cursor-pointer hover:bg-gray-100">
                  {isLoadingImg ? (
                    <CircularProgress size={20} />
                  ) : (
                    <LuImagePlus className="w-[48px] h-[48px]" />
                  )}
                </div>
              </label>
            </>
          )}
        </div>
      </div>

      <div className="w-full mb-4">
        <p className="font-bold text-lg">Hasil Pemeriksaan</p>
        <hr />
        <div className="w-full grid grid-cols-2 lg:grid-cols-5 gap-3 mt-2">
          {formik.values.exam_img.length > 0 ? (
            formik.values.exam_img.map((item, i) => (
              <div className="relative">
                <div className="w-full h-[200px]">
                  <div
                    key={i}
                    ref={(element) =>
                      (cornerstoneRefsExam.current[i] = element)
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      objectFit: "contain",
                    }}
                  >
                    {arrDicomExam[i] ? null : (
                      <div className="flex justify-center items-center w-full h-[200px] p-3">
                        <MdOutlineHideImage className="w-[48px] h-[48px]" />
                      </div>
                    )}
                  </div>
                </div>
                {isDetail ? null : (
                  <MdCancel
                    className="cursor-pointer text-red-500 bg-white rounded-full absolute -top-2 -right-3 w-[24px] h-[24px]"
                    onClick={() => {
                      mutateDeleteImgExam(formik.values.img[i]);
                      setSelectedImg(i);
                    }}
                  />
                )}
              </div>
            ))
          ) : isDetail ? (
            <p className="col-span-5">Tidak ada hasil pemeriksaan</p>
          ) : null}

          {isDetail ? null : (
            <>
              <input
                type="file"
                id="doctor-img-upload-exam"
                hidden
                accept="*/dicom,.dcm, image/dcm, */dcm, .dicom"
                onChange={(e) => {
                  mutateUploadImgExam(e.target.files[0]);
                }}
              />
              <label for="doctor-img-upload-exam">
                <div className="w-full h-[200px] flex justify-center items-center text-center rounded-md border-2 cursor-pointer hover:bg-gray-100">
                  {isLoadingImgExam ? (
                    <CircularProgress size={20} />
                  ) : (
                    <LuImagePlus className="w-[48px] h-[48px]" />
                  )}
                </div>
              </label>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SubjectiveObjectiveAnalysis;
