import React from "react";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import Header from "../../../components/ui/Header";
import InventorySummary from "../../../components/screen/inventory/InventorySummary/InventorySummary";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const InventorySummaryPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            {isWarehouse ? "Central Warehouse" : ""} Inventory Summary
          </h1>
          <InventorySummary isWarehouse={isWarehouse} />
        </div>
      </div>
    </div>
  );
};

export default InventorySummaryPage;
