import React from 'react';
import Header from '../../components/ui/Header';
import SidebarComponents from '../../components/ui/SidebarComponents';
import AllCategoryList from '../../components/screen/purchase/purchaseComponents/CategorySection/AllCategoryList';
import { SIDEBAR_WIDTH } from '../../components/constants/constant';

const CategoryPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <AllCategoryList />
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
