import { useState, useContext, useMemo, useEffect } from "react";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import moment from "moment";

import { ChatboxContext } from "../../context/ChatboxProvider";

// const userList = [
//   {
//     id: 1,
//     status: true,
//     name: "Apotek Sehat Jaya",
//   },
//   {
//     id: 2,
//     status: true,
//     name: "Apotek Mandiri",
//   },
//   {
//     id: 3,
//     status: true,
//     name: "Apotek Iren Mutia",
//   },
// ];

const timeNow = moment().format("HH:mm");

const Chatbox = (props) => {
  const { companyName } = props;
  const apotekerName = localStorage.getItem("name");
  const fullname = localStorage.getItem("fullname");
  const {
    isChatBoxOpen,
    setIsChatBoxOpen,
    chatboxResponse,
    setChatboxResponse,
    productName,
    buyerName,
    setBuyerName,
  } = useContext(ChatboxContext);
  const [userInput, setUserInput] = useState("");

  const userList = useMemo(() => {
    return [
      {
        id: 1,
        status: true,
        name: companyName,
      },
      {
        id: 2,
        status: true,
        name: "Apotek Mandiri",
      },
      {
        id: 3,
        status: true,
        name: "Apotek Iren Mutia",
      },
    ];
  }, [companyName]);

  useEffect(() => {
    setChatboxResponse([
      {
        id: 1,
        // message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
        message: `selamat siang dok, saya mau konsultasi untuk pengunaan ${productName}, saya lupa penggunaannya untuk pengidap bronkitis harus berapa kali sehari ya?`,
        type: 1,
        time: timeNow,
      },
      {
        id: 2,
        //message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
        message: `Hi selamat siang ${buyerName}, untuk pemakaian ${productName} sebagai berikut: 

            Dewasa: 1 - 2 Tablet, dihisap 4 - 5 kali per hari. 
            Anak-anak: 1 Tablet, dihisap 4 - 5 kali per hari.
          `,
        type: 2,
        time: timeNow,
      },
    ]);
  }, [companyName, setChatboxResponse, productName, buyerName]);

  return (
    <div>
      <div
        className="fixed bottom-0 right-0 mb-4 mr-4"
        // hidden={isChatBoxOpen}
        hidden
      >
        <button
          id="open-chat"
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 flex items-center"
          onClick={() => {
            setIsChatBoxOpen((prevValue) => !prevValue);
            setChatboxResponse([
              {
                id: 1,
                message: `selamat siang kak, selamat datang di apotek ${apotekerName}. ada yang bisa kami bantu?`,
                type: 2,
                time: timeNow,
              },
            ]);
          }}
        >
          Chat with {apotekerName}
        </button>
      </div>
      <div
        id="chat-container"
        className="fixed bottom-5 right-4 w-[53%] h-[77%]"
        hidden={!isChatBoxOpen}
      >
        <div className="bg-white shadow-md rounded-lg w-full h-full">
          <div className="p-4 border-b bg-blue-500 text-white rounded-t-lg flex justify-between items-center">
            <p className="text-lg font-semibold">{buyerName}</p>
            <button
              className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              //    onClick={() => setIsChatBoxOpen((prevValue) => !prevValue)}
              onClick={() => {
                setIsChatBoxOpen((prevValue) => !prevValue);
                setChatboxResponse([
                  {
                    id: 1,
                    // message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
                    message: `selamat siang dok, saya mau konsultasi untuk pengunaan ${productName}, saya lupa penggunaannya untuk pengidap bronkitis harus berapa kali sehari ya?`,
                    type: 1,
                    time: timeNow,
                  },
                  {
                    id: 2,
                    //message: `selamat siang kak, selamat datang di apotek ${companyName}. ada yang bisa kami bantu?`,
                    message: `Hi selamat siang ${buyerName}, untuk pemakaian ${productName} sebagai berikut: 

            Dewasa: 1 - 2 Tablet, dihisap 4 - 5 kali per hari. 
            Anak-anak: 1 Tablet, dihisap 4 - 5 kali per hari.
          `,
                    type: 2,
                    time: timeNow,
                  },
                ]);
              }}
            >
              <KeyboardArrowDownIcon fontSize="large" />
            </button>
          </div>
          <div className="flex h-[85%]">
            <div className="w-full border border-l-gray-200 pb-3">
              <div id="chatbox" className="p-4 overflow-y-auto h-[90%]">
                {chatboxResponse.map((data, index) => (
                  <div className="" key={index}>
                    <div
                      className={`mb-2 ${data.type === 1 ? "text-right" : ""} `}
                      key={data.id}
                    >
                      <p
                        className={`${data.type === 1 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} rounded-lg py-2 px-4 inline-block`}
                      >
                        {data.message}
                      </p>
                    </div>
                    <p
                      className={`mb-2 ${data.type === 1 ? "text-right" : ""} `}
                    >
                      {data.time}
                    </p>
                  </div>
                ))}
              </div>
              <div className="p-4 border-t flex">
                <input
                  id="user-input"
                  type="text"
                  placeholder="Type a message"
                  className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={userInput}
                  onChange={(e) => {
                    setUserInput(e.target.value);
                  }}
                />
                <button
                  id="send-button"
                  className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-300"
                  onClick={() => {
                    setChatboxResponse((prevValue) => [
                      ...prevValue,
                      {
                        message: userInput,
                        type: 1,
                        time: moment().format("HH:mm"),
                      },
                    ]);
                    setChatboxResponse((prevValue) => [
                      ...prevValue,
                      {
                        message: `Iya betul kak ${fullname}, untuk melakukan pembelian obat ${productName}, kakak perlu mengupload resep dokter yang akan otomatis muncul sebelum check out obatnya`,
                        type: 2,
                        time: moment().format("HH:mm"),
                      },
                    ]);
                    setUserInput("");
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
