import React, { useContext, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { SelectedProductContext } from "../../../context/SelectedProductProvider";

const ProductList = () => {
  const navigate = useNavigate();
  const {
    setFetchLimit,
    productList,
    isAllProductLoading,
    mutateAddToCart,
    setIsLoading,
    isLoading,
    dataBufferProductList,
    setDataBufferProductList,
  } = useContext(SelectedProductContext);

  useEffect(() => {
    setDataBufferProductList(
      productList && productList !== undefined
        ? productList.product?.map((data) => ({
            id: data.id,
            image: data.image,
            nama_produk: data.nama_produk,
            quantity: data.quantity,
            price: data.price,
            isVisible: data.quantity !== 0,
            isLoading: false,
          }))
        : []
    );
  }, [productList]);

  return (
    <div className="w-full mt-5">
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-[20px]">Product List</h1>
      </div>
      {isAllProductLoading ? (
        <div className="flex justify-center">
          <CircularProgress sx={{ marginY: 3 }} />
        </div>
      ) : (
        <div className="flex justify-center grid grid-cols-4 gap-3 w-full">
          {dataBufferProductList && dataBufferProductList !== undefined
            ? dataBufferProductList.map((list, index) => (
                <div
                  className="p-3 bg-white rounded-lg shadow-md w-full h-full flex flex-wrap content-between"
                  key={index}
                >
                  <div>
                    <img
                      src={list.image !== null ? list.image : ""}
                      alt=""
                      className="rounded-lg object-cover hover:cursor-pointer"
                      onClick={() => navigate(`/purchase/detail/${list.id}`)}
                    />
                    <div className="mt-3 text-[12px] lg:text-[15px]">
                      <h1 className="text-gray-700 font-bold ">
                        {list.nama_produk}
                      </h1>
                      <p className="text-gray-500 mt-2">{list.uom}</p>
                      <h1 className="text-gray-700 font-bold">
                        {list.price.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </h1>
                      {/* <div className="flex gap-2 items-center mt-1">
                        <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                          15%
                        </p>
                        <p className="line-through">Rp. {productPrice}</p>
                      </div> */}
                    </div>
                  </div>
                  <>
                    {list.isVisible ? (
                      <div className="w-full mt-3">
                        <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                          {list.isLoading ? (
                            <div className="flex justify-center w-full">
                              <p>Loading</p>
                            </div>
                          ) : (
                            <>
                              <button
                                className="bg-[#FF7A5D] px-3 rounded-lg"
                                disabled={isLoading}
                                onClick={() => {
                                  if (list.quantity === 1) {
                                    setDataBufferProductList((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === list.id
                                          ? {
                                              ...data,
                                              isVisible: false,
                                              isLoading: true,
                                              quantity: data.quantity - 1,
                                            }
                                          : data;
                                      })
                                    );
                                  } else {
                                    setDataBufferProductList((prevValue) =>
                                      prevValue.map((data) => {
                                        return data.id === list.id
                                          ? {
                                              ...data,
                                              quantity: data.quantity - 1,
                                              isLoading: true,
                                            }
                                          : data;
                                      })
                                    );
                                  }
                                  setIsLoading(true);
                                  mutateAddToCart({
                                    price: list.price,
                                    product_id: list.id,
                                    quantity: list.quantity - 1,
                                    flag: false,
                                    type: 1,
                                    param: 1,
                                  });
                                }}
                              >
                                -
                              </button>
                              {/* <input
                                  className="w-[10%] bg-[#EAEAEA]"
                                  value={dataBuffer[index]?.quantity}
                                  onChange={(e) => {
                                    const numericValue = e.target.value;
                                    if (numericValue === "")
                                      return setDataBuffer(
                                        dataBuffer.map((list) => {
                                          return list.id === productId
                                            ? {
                                                ...list,
                                                quantity: 0,
                                              }
                                            : list;
                                        })
                                      );
                                    setDataBuffer(
                                      dataBuffer.map((list) => {
                                        return list.id === productId
                                          ? {
                                              ...list,
                                              quantity: parseInt(
                                                numericValue.replace(
                                                  /[^0-9]/g,
                                                  0
                                                ),
                                                10
                                              ),
                                            }
                                          : list;
                                      })
                                    );
                                  }}
                                /> */}
                              <p>{list.quantity}</p>
                              <button
                                className="bg-[#5DFFBB] px-3 rounded-lg"
                                disabled={isLoading}
                                onClick={() => {
                                  setDataBufferProductList((prevValue) =>
                                    prevValue.map((data) => {
                                      return data.id === list.id
                                        ? {
                                            ...data,
                                            isLoading: true,
                                            quantity: data.quantity + 1,
                                          }
                                        : data;
                                    })
                                  );
                                  setIsLoading(true);
                                  mutateAddToCart({
                                    price: list.price,
                                    product_id: list.id,
                                    quantity: list.quantity + 1,
                                    flag: false,
                                    type: 1,
                                    param: 1,
                                  });
                                }}
                              >
                                +
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3 text-[12px] lg:text-base w-full">
                        <button
                          className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                          disabled={isLoading}
                          onClick={() => {
                            setDataBufferProductList(
                              dataBufferProductList.map((data) => {
                                return data.id === list.id
                                  ? {
                                      ...data,
                                      isVisible: true,
                                      quantity: 1,
                                    }
                                  : data;
                              })
                            );
                            setIsLoading(true);
                            mutateAddToCart({
                              price: list.price,
                              product_id: list.id,
                              quantity: 1,
                              flag: false,
                              type: 1,
                              param: 1,
                            });
                          }}
                        >
                          <AiOutlineShoppingCart />
                          Add to cart
                        </button>
                      </div>
                    )}
                  </>
                </div>
              ))
            : null}
        </div>
      )}
      <div className="flex justify-center my-5">
        <button
          className="p-3 border-2 border-[#007AF1] font-bold text-[#007AF1] rounded-lg w-full"
          onClick={() => setFetchLimit((prevFetchValue) => prevFetchValue + 10)}
        >
          Muat selengkapnya
        </button>
      </div>
    </div>
  );
};

export default ProductList;
