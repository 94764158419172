import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Divider } from "@mui/material";
import { ReceiptLong } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  getBillingDetail,
  getBillingInvoiceDetail,
  setPaymentBilling,
} from "../../../services/billingAPI";
import BillingBreadcrumb from "./BillingBreadcrumb";
import ModalPaymentOption from "../../common/particles.jsx/ModalPaymentOption";

const DetailBilling = () => {
  const { id } = useParams();
  const { status } = useParams();
  const navigate = useNavigate();

  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [paymentOption, setPaymentOption] = useState(0);
  const [billArr, setBillArr] = useState([]);

  const createBill = () => {
    if (paymentOption !== 0) {
      const body = {
        billings: billArr,
        handling_fee: paymentOption.bank_fee,
        total_price:
          billArr.reduce((acc, curVal) => acc + curVal.price, 0) +
          paymentOption.bank_fee,
        payment: {
          pg_name: paymentOption.pg_name,
          pg_bank: paymentOption.pg_bank,
          pg_code: paymentOption.pg_code,
          pg_type: paymentOption.pg_type,
        },
      };
      mutateBill(body);
    }
  };

  const { data } = useQuery({
    queryFn: () => getBillingDetail(id.split("-")[0]),
    queryKey: ["detail-billing"],
  });

  const {
    mutate: mutateBill,
    isLoading,
    data: dataBill,
  } = useMutation({
    mutationFn: setPaymentBilling,
    onSuccess: () => {
      setOpenModalPayment(false);
    },
  });

  const { mutate: mutateDetailInvoice, data: dataInvoice } = useMutation({
    mutationFn: getBillingInvoiceDetail,
  });

  useEffect(() => {
    if (data) mutateDetailInvoice(data.detail.bill.bill_id);
  }, [data]);

  useEffect(() => {
    if (dataBill && dataBill.va !== null)
      navigate(
        `/billing/waiting-payment/payment-summary/${btoa(dataBill.payment.va)}`
      );
  }, [dataBill]);

  useEffect(() => {
    if (paymentOption !== 0) createBill();
  }, [paymentOption]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <BillingBreadcrumb />

      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>

      {data && data.detail && data.detail !== null ? (
        <>
          <div className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full text-sm lg:text-base ">
            <table>
              <tbody>
                <tr>
                  <td className="py-2">No PO</td>
                  <td className="w-fit px-4">:</td>
                  <td>{data.detail.bill.transaction_id}</td>
                </tr>
                <tr>
                  <td className="py-2">Tanggal Transaksi</td>
                  <td className="w-fit px-4">:</td>
                  <td>
                    {moment(data.detail.transaction_date).format(
                      "DD MMMM YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Tanggal Tagihan</td>
                  <td className="w-fit px-4">:</td>
                  <td>
                    {moment(data.detail.bill.due_date).format("DD MMMM YYYY")}
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Tanggal Pembayaran</td>
                  <td className="w-fit px-4">:</td>
                  <td>
                    {data.detail.bill.status < 4
                      ? "-"
                      : dataInvoice
                      ? moment(dataInvoice.payment_at).format("DD MMMM YYYY")
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Tempo</td>
                  <td className="w-fit px-4">:</td>
                  <td>
                    {data.detail.bill.tenor.total === 1
                      ? 1
                      : 12 / data.detail.bill.tenor.total}{" "}
                    Bulan{" "}
                    {data.detail.bill.tenor.total === 1
                      ? ""
                      : data.detail.bill.tenor.current +
                        "/" +
                        data.detail.bill.tenor.total}
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Metode Pembayaran</td>
                  <td className="w-fit px-4">:</td>
                  <td>
                    {data.detail.bill.status % 2 !== 0
                      ? "-"
                      : dataInvoice
                      ? dataInvoice.payment_name
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Total Transaksi</td>
                  <td className="w-fit px-4 text-blue-500">:</td>
                  <td>
                    {data.detail.bill.amount.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </td>
                </tr>
                {data.detail.bill.status === 3 ? (
                  <>
                    <tr>
                      <td className="py-2">Aging</td>
                      <td className="w-fit px-4">:</td>
                      <td>placeholder</td>
                    </tr>
                    <tr>
                      <td className="py-2">Denda Telat Pembayaran</td>
                      <td className="w-fit px-4">:</td>
                      <td>
                        {data.detail.billings[0].penalty_fee.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2">Total Tagihan</td>
                      <td className="w-fit px-4">:</td>
                      <td>
                        {data.detail.billings[0].amount.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </td>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <td className="py-2">Status</td>
                  <td className="w-fit px-4">:</td>
                  {data.detail.bill.status === 1 ? (
                    <td className="text-red-500">Belum Dibayar</td>
                  ) : data.detail.bill.status === 2 ? (
                    <td className="text-red-500">Menunggu Pembayaran</td>
                  ) : data.detail.bill.status === 3 ? (
                    <td className="text-red-500">Harus Dibayar</td>
                  ) : data.detail.bill.status === 4 ? (
                    <td className="text-green-500 flex items-center">
                      Lunas
                      <ReceiptLong
                        className="text-blue-500 ml-8 cursor-pointer hover:text-blue-700"
                        onClick={() => {
                          navigate(
                            `/billing/paid/invoice/${data.detail.bill.bill_id}`
                          );
                        }}
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-3  shadow-md rounded-xl p-3 border-2  w-full">
            <div className="flex items-center justify-between w-full">
              <div className="w-full">
                <div className="flex my-2">
                  <div className="w-12 md:w-20 flex justify-center items-center">
                    <img
                      src={data.detail.product.image}
                      alt="foto_product"
                      className="w-full border rounded-lg"
                    />
                  </div>
                  <div className="flex justify-between items-center w-full h-20 ml-2">
                    <div className="flex flex-col text-xs lg:text-base gap-1 text-clip overflow-hidden w-full">
                      <p className="font-bold">
                        {data.detail.product.product_name}
                      </p>
                      <p className="">
                        <span className="text-sm">
                          {data.detail.product.quantity}x
                        </span>
                        <span className="font-bold ml-4">
                          {data.detail.product.price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center flex-col text-clip overflow-hidden w-full text-right text-center basis-2/3 md:basis-1/3 border-l-2 h-full ">
                      <p className="text-gray-400 h-full flex items-end text-xs lg:text-base">
                        Total Belanja
                      </p>
                      <p className="font-bold h-full text-xs lg:text-base">
                        {data.detail.product.transaction_amount.toLocaleString(
                          "id-ID",
                          {
                            style: "currency",
                            currency: "IDR",
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider sx={{ marginY: 2 }} />
            <div className="flex justify-end items-center w-full">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#000",
                  color: "#000",
                  "&:hover": { borderColor: "#d3d3d3", color: "#d3d3d3" },
                }}
                onClick={() =>
                  navigate(
                    `/billing/${status}/invoice-detail/${btoa(
                      data.detail.product.invoice_number
                    )}`
                  )
                }
              >
                Details
              </Button>
            </div>
          </div>

          <p className="font-lg font-bold my-4">Rincian Cicilan</p>
          <>
            {data.detail.billings.map((item, index) => {
              return (
                <>
                  <div className="w-full flex justify-  " key={index}>
                    <div className="w-full">
                      <div className="w-full flex">
                        <p className="mr-4">
                          {item.tenor.current + "/" + item.tenor.total}
                        </p>
                        <p>
                          {item.amount.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      </div>
                      <div>
                        <p>
                          Tanggal jatuh tempo{" "}
                          {moment(item.due_date).format("DD MMMM YYYY")}
                        </p>
                      </div>
                      {item.penalty_fee > 0 ? (
                        <div>
                          <p className="text-red-500">
                            Denda keterlambatan{" "}
                            {item.penalty_fee.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex items-center">
                      <p
                        className={`w-full text-right ${
                          item.status === "4"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {item.status === "1"
                          ? "Belum dibayar"
                          : item.status === "2"
                          ? ""
                          : item.status === "3"
                          ? ""
                          : "Lunas"}
                      </p>
                    </div>
                  </div>
                  <Divider sx={{ marginY: 2 }} />
                </>
              );
            })}
          </>

          {window.location.pathname.includes("/paid") ||
          window.location.pathname.includes("/waiting-payment") ? (
            <></>
          ) : (
            <div className="w-full" onClick={() => setOpenModalPayment(true)}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setOpenModalPayment(true);
                  setBillArr([
                    {
                      bill_id: data.detail.bill.bill_id,
                      price: data.detail.bill.amount,
                    },
                  ]);
                }}
              >
                Bayar Sekarang
              </Button>
            </div>
          )}
        </>
      ) : null}

      <ModalPaymentOption
        open={openModalPayment}
        setOpen={setOpenModalPayment}
        setPaymentOption={setPaymentOption}
        isLoading={isLoading}
      />
    </div>
  );
};

export default DetailBilling;
