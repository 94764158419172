import { FormControl, FormLabel, TextField } from "@mui/material";
import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import ModalPreview from "./ModalPreview";

const AssessPlanAnalysis = ({
  formik,
  isDetail,
  setIsFormView,
  selectedDoctor,
  selectedPatient,
}) => {
  const { id } = useParams();

  const [openPreview, setOpenPreview] = useState(false);

  return (
    <>
      <div className="mb-4">
        <p className="font-bold text-lg mb-4">Assessment (A)</p>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Diagnosis</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.assessment.diagnosis}
              onChange={(e) =>
                formik.setFieldValue("assessment.diagnosis", e.target.value)
              }
            />
            <p className="text-xs">
              ICD 2010 (Wajib jika integrasi Satusehat){" "}
              <span
                className="text-blue-500 cursor-pointer hover:text-gray-300"
                onClick={() =>
                  window.open("https://icd.who.int/browse10/2010/en", "_blank")
                }
              >
                Referensi
              </span>
            </p>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Diagnosis Banding</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.assessment.diff_diagnosis}
              onChange={(e) =>
                formik.setFieldValue(
                  "assessment.diff_diagnosis",
                  e.target.value
                )
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <p className="font-bold text-lg mb-4">Plan (P)</p>
          <button
            type="button"
            disabled={
              !selectedDoctor ||
              !selectedPatient ||
              (isDetail &&
                formik.values.regular.length === 0 &&
                formik.values.concoction.length === 0)
            }
            className="rounded-md bg-blue-500 text-white hover:bg-gray-300 flex gap-4 items-center justify-center px-4 py-2 disabled:bg-gray-300"
            onClick={() => {
              id ? setOpenPreview(true) : setIsFormView(false);
              if (!isDetail && formik.values.regular.length === 0)
                formik.setFieldValue("regular", [
                  {
                    product_id: 0,
                    product_name: "",
                    uom: "",
                    quantity: 0,
                    signa: "",
                    iter: 0,
                  },
                ]);
              if (!isDetail && formik.values.concoction.length === 0)
                formik.setFieldValue("concoction", [
                  {
                    product: [
                      { product_id: 0, product_name: "", uom: "", quantity: 0 },
                    ],
                    misce_fiat: "",
                    signa: "",
                    iter: 0,
                  },
                ]);
            }}
          >
            {!(
              formik.values.regular.filter(
                (item) => item.product_id !== 0 || item.product_name !== ""
              ).length === 0 &&
              formik.values.concoction.filter((conc) =>
                conc.product.every(
                  (prod) => prod.product_id !== 0 || prod.product_name !== ""
                )
              ).length === 0
            ) || isDetail ? (
              <p>Lihat Resep</p>
            ) : (
              <>
                <p>Buat Resep</p>
                <AiOutlinePlusCircle />
              </>
            )}
          </button>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Rencana Pengobatan</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.plan.treatment_plan}
              onChange={(e) =>
                formik.setFieldValue("plan.treatment_plan", e.target.value)
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Edukasi Pasien</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.plan.patient_education}
              onChange={(e) =>
                formik.setFieldValue("plan.patient_education", e.target.value)
              }
            />
          </FormControl>
        </div>
        <div className="flex gap-2 mb-4">
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Tindak Lanjut</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.plan.follow_up}
              onChange={(e) =>
                formik.setFieldValue("plan.follow_up", e.target.value)
              }
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Rujukan</FormLabel>
            <TextField
              placeholder="-"
              disabled={isDetail}
              fullWidth
              value={formik.values.plan.reference}
              onChange={(e) =>
                formik.setFieldValue("plan.reference", e.target.value)
              }
            />
          </FormControl>
        </div>
      </div>

      <ModalPreview
        open={openPreview}
        setOpen={setOpenPreview}
        setIsFormView={setIsFormView}
        formik={formik}
        selectedDoctor={selectedDoctor}
        selectedPatient={selectedPatient}
        isDetail={isDetail}
      />
    </>
  );
};

export default AssessPlanAnalysis;
