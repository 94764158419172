import React from "react";
import Header from "../../../components/ui/Header";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import Retur from "../../../components/screen/inventory/productExpired/Retur";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const ReturPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
        >
          <Header />
          <Retur isWarehouse={isWarehouse} />
        </div>
      </div>
    </div>
  );
};

export default ReturPage;
