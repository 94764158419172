import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Snackbar,
  IconButton,
  Badge,
  Dialog,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsArrowUp } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";

import SpecialOffer from "./purchaseComponents/SpecialOfferSection/SpecialOffer";
import Principals from "./purchaseComponents/PrincipalSection/Principals";
import MostSearch from "./purchaseComponents/MostSearchSection/MostSearch";
import BasedOnSearch from "./purchaseComponents/BasedOnSearchSection/BasedOnSearch";
import Categories from "./purchaseComponents/CategorySection/Categories";
import ProductList from "./purchaseComponents/ProductList";
import HeaderPurchase from "./purchaseComponents/HeaderPurchase";
import SearchProductPurchase from "./SearchProductPurchase";

import AllPrincipalList from "./purchaseComponents/PrincipalSection/AllPrincipalList";
import AllCategoryList from "./purchaseComponents/CategorySection/AllCategoryList";
import AllMostSearchList from "./purchaseComponents/MostSearchSection/AllMostSearchList";
import AllBasedOnSearchList from "./purchaseComponents/BasedOnSearchSection/AllBasedOnSearchList";

import SuggestionModal from "./Suggestion/SuggestionModal";

import { SelectedProductContext } from "../../context/SelectedProductProvider";

const Purchase = () => {
  const navigate = useNavigate();
  const {
    isProduct,
    countCart,
    isCountCartLoading,
    isAddToCartLoading,
    errorMessage,
    setErrorMessage,
    isPurchaseLoading,
    addToCartData,
    isSuggestionOpen,
    setIsSuggestionOpen,
  } = useContext(SelectedProductContext);

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const hasTrueValue = useCallback(
    (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === true) {
          return true;
        }
      }
      return false;
    },
    [isProduct]
  );

  const scroll = useCallback(() => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setIsButtonVisible(true);
    } else if (scrolled <= 300) {
      setIsButtonVisible(false);
    }
  }, [isButtonVisible]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [window]);

  useEffect(() => {
    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, [window, scroll]);

  return (
    <div className="w-full mt-14 lg:mt-0">
      {isPurchaseLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex flex-row items-center w-full">
            <HeaderPurchase />
            <HiOutlineClipboardList
              className="text-[40px] p-2 shadow-md border rounded-lg mx-3 hover:cursor-pointer"
              onClick={() => navigate("/purchase/list-transaction")}
            />
            {countCart && countCart !== undefined ? (
              <Badge
                badgeContent={
                  countCart && countCart !== undefined ? countCart.count : 0
                }
                color="error"
              >
                <AiOutlineShoppingCart
                  className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
                  onClick={() => navigate("/purchase/cart-detail")}
                />
              </Badge>
            ) : (
              <AiOutlineShoppingCart
                className="text-[40px] p-2 shadow-md border rounded-lg text-bold hover:cursor-pointer"
                onClick={() => navigate("/purchase/cart-detail")}
              />
            )}
          </div>
          {hasTrueValue(isProduct) ? (
            <>
              {/* isProduct.principalSelected && <AllPrincipalList /> */}
              {/* isProduct.categorySelected && <AllCategoryList /> */}
              {isProduct.mostSearchSelected && <AllMostSearchList />}
              {isProduct.userSearchSelected && <AllBasedOnSearchList />}
              {isProduct.searchSelected && <SearchProductPurchase />}
            </>
          ) : (
            <>
              <SpecialOffer />
              <Principals />
              <MostSearch />
              <BasedOnSearch />
              <Categories />
              <ProductList />
            </>
          )}
          {countCart && countCart !== undefined ? (
            <div className="flex justify-center w-full mt-20">
              {countCart.count !== 0 ? (
                <div className="fixed bottom-[20px] z-20 bg-blue-500 p-3 rounded-md w-[60%] h-[10%]">
                  {isCountCartLoading || isAddToCartLoading ? (
                    <div className="flex justify-center w-full">
                      <CircularProgress sx={{ color: "white" }} />
                    </div>
                  ) : (
                    <div className="flex justify-between items-center font-medium text-white h-full">
                      <div className="flex justify-center items-center h-full">
                        <p>{countCart.count} Barang</p>
                        <div className="border-2 h-full border-white mx-2"></div>
                        <div>
                          <p>Total Belanja</p>
                          <p className="text-[#FDCD78]">
                            {countCart.total.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                      </div>
                      <button
                        className="bg-[#FF8D40] p-2 text-[20px] rounded-lg"
                        onClick={() => navigate("/purchase/cart-detail")}
                      >
                        Lihat Keranjang
                      </button>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          <button
            className={`fixed bottom-[20px] right-[25px] z-20 bg-white shadow-lg p-3 rounded-full text-[#007AF1] text-[30px] font-bold ${
              isButtonVisible ? "inline" : "hidden"
            }`}
            onClick={scrollToTop}
          >
            <BsArrowUp />
          </button>
          <Snackbar
            open={Boolean(errorMessage)}
            autoHideDuration={5000}
            onClose={() => {
              setErrorMessage("");
            }}
            message={errorMessage}
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={(_, reason) => {
                    if (reason === "clickaway") {
                      setErrorMessage("");
                    }
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />
        </>
      )}
      <SuggestionModal />
      {/* {addToCartData && addToCartData !== undefined ? (
        <Dialog
          open={isSuggestionOpen}
          onClose={() => setIsSuggestionOpen(false)}
          fullWidth
          maxWidth="lg"
        >
          {addToCartData.suggestion &&
          addToCartData.suggestion !== undefined ? (
            <SuggestionModal addToCartData={addToCartData} />
          ) : null}
        </Dialog>
      ) : null} */}
    </div>
  );
};

export default Purchase;
