import { MdOutlineFilterAlt } from "react-icons/md";
import BreadcrumbCentralWarehouse from "../BreadcrumbCentralWarehouse";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { BsPrinter, BsTrash } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import PopupRangeCalendar from "../../../common/particles.jsx/PopupRangeCalendar";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getRequestWarehouseList,
  getDetailWarehouse,
  deleteRequestWarehouse,
} from "../../../../services/centralWarehouseApi";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import { RxCross1 } from "react-icons/rx";
import { getOutletList } from "../../../../services/outletApi";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import PrintNota from "../../../common/particles.jsx/PrintNota";
import { getPreviewInvoice } from "../../../../services/ReportingApi";
import { useReactToPrint } from "react-to-print";
import ModalDeleteWarehouse from "../ModalDeleteWarehouse";

const INITIAL_REQ = {
  type: 3, // 1: allocation, 2: trf, 3: retur to warehouse, 4: req allocation, 5: req transfer
  start_date: "2012-01-01",
  end_date: moment().endOf("year").format("YYYY-MM-DD"),
  name: "",
  offset: 1,
  limit: 10,
  status: [],
  company_id: [],
};

const ReturWarehouseList = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElOutlet, setAnchorElOutlet] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selectedRetur, setSelectedRetur] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const NotaInfo = () => {
    return (
      <div className="mb-4">
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/3">Nama Outlet</p>
            <p>:</p>
            <p>{dataDetail ? dataDetail.header.target : ""}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/3">User</p>
            <p>:</p>
            <p>{dataDetail ? dataDetail.header.created_by : ""}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/3">No Telepon</p>
            <p>:</p>
            <p>{dataLabel ? dataLabel.phone : ""}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/3">Tanggal</p>
            <p>:</p>
            <p>
              {dataDetail
                ? moment(dataDetail.header.date).format("DD MMMM YYYYY")
                : ""}
            </p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/3">Alamat</p>
            <p>:</p>
            <p>
              {dataLabel
                ? dataLabel.address +
                  ", " +
                  dataLabel.city +
                  ", " +
                  dataLabel.state +
                  " " +
                  dataLabel.zipcode
                : ""}
            </p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/3">ID Transaksi</p>
            <p>:</p>
            <p>{dataDetail ? dataDetail.header.transaction_number : ""}</p>
          </div>
        </div>
      </div>
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setSelectedRetur(null);
      setIsPrinting(false);
    },
  });

  const handleChangeStatus = (newStatus) => {
    const index = reqBody.status.findIndex((item) => item === newStatus);
    const tempStatus =
      newStatus === null
        ? []
        : index < 0
        ? [...reqBody.status, newStatus]
        : [
            ...reqBody.status.slice(0, index),
            ...reqBody.status.slice(index + 1),
          ];
    setReqBody((prevValue) => ({
      ...prevValue,
      status: tempStatus,
      offset: 1,
    }));
  };

  const handleSelectOutlet = (outlet) => {
    const index = reqBody.company_id.findIndex(
      (item) => item.company_id === outlet?.company_id
    );

    setReqBody((prevValue) => ({
      ...prevValue,
      offset: 1,
      company_id: outlet
        ? index < 0
          ? [...prevValue.company_id, outlet]
          : [
              prevValue.company_id.slice(0, index),
              prevValue.company_id.slice(index + 1),
            ]
        : [],
    }));
  };

  const {
    data: dataList,
    mutate: mutateList,
    isLoading,
  } = useMutation({
    mutationFn: getRequestWarehouseList,
  });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation({
    mutationFn: deleteRequestWarehouse,
    onSuccess: () => {
      setSelectedRetur(null);
      setConfirm(false);
      setReqBody(INITIAL_REQ);
      setOpenSuccessDelete(true);
    },
    onError: () => {
      setSelectedRetur(null);
      setConfirm(false);
    },
  });

  const {
    mutate: mutateDetail,
    isLoading: isLoadingDetail,
    data: dataDetail,
  } = useMutation({
    mutationFn: getDetailWarehouse,
    onSuccess: () => {
      setTimeout(() => {
        handlePrint();
      }, 500);
    },
    onError: () => {
      setSelectedRetur(null);
      setIsPrinting(false);
    },
  });

  const { data: dataOutlet, isFetching: isFetchingOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  useEffect(() => {
    mutateList({
      ...reqBody,
      company_id: reqBody.company_id.map((item) => item.company_id),
    });
  }, [reqBody]);

  useEffect(() => {
    if (confirm) {
      mutateDelete({
        id: selectedRetur ? selectedRetur.id : null,
        type: 3,
      });
    }
  }, [confirm]);

  return (
    <>
      <LoadingBackdrop isLoading={isLoadingDelete || isPrinting} />
      {/* change to isLoading delete, isloading print */}

      <BreadcrumbCentralWarehouse />
      <div className="flex items-center gap-2 mb-4">
        <TextField
          fullWidth
          placeholder="Search"
          value={reqBody.name}
          onChange={(e) =>
            setReqBody((prevValue) => ({
              ...prevValue,
              name: e.target.value,
              offset: 1,
            }))
          }
          inputProps={{ style: { padding: 8 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <div
          className="w-fit p-2 rounded-md border-2 flex gap-2 items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-5 h-5" />
          <p>Filter</p>
          <FaChevronDown />
        </div>
        <button
          className="rounded-md p-2 border-2 border-gray-500 text-gray-500 text-sm flex gap-2 justify-center items-center hover:bg-gray-100 disabled:bg-gray-300 disabled:text-white"
          // disabled={isLoadingXlsx || analysisArr.length === 0}
          disabled={selectedRetur === null}
          onClick={() => {
            setIsPrinting(true);
            mutateDetail({ id: selectedRetur, type: 3 });
          }}
        >
          Print
          <BsPrinter className="w-4 h-4" />
        </button>
        <div
          className="rounded-md p-2 flex justify-center items-center cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() =>
            navigate(`/central-warehouse/retur-warehouse/create-retur`)
          }
        >
          <IoMdAddCircleOutline className="w-5 h-5" />
        </div>
      </div>
      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="py-2 text-center"></th>
                <th className="py-2 text-center">No</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Transaction ID
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Retur Date
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Outlet Destination
                </th>
                <th className="px-3 py-2 text-left whitespace-nowrap">User</th>
                <th className="px-3 py-2 text-left whitespace-nowrap">
                  Status
                </th>
                <th className="px-3 py-2 text-center whitespace-nowrap">
                  Action
                </th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.request ? (
                  dataList.request.map((item, i) => (
                    <tr key={i}>
                      <td className="py-1 border text-center">
                        <Checkbox
                          onChange={(_, checked) =>
                            setSelectedRetur(checked ? item.id : null)
                          }
                          checked={selectedRetur === item.id}
                        />
                      </td>
                      <td className="py-1 border text-center">{i + 1}</td>
                      <td
                        className="px-3 py-1 border text-blue-500 cursor-pointer hover:text-blue-700 hover:underline"
                        onClick={() =>
                          navigate(
                            `/central-warehouse/retur-warehouse/detail/${item.id}`
                          )
                        }
                      >
                        {item.transaction_number}
                      </td>
                      <td className="px-3 py-1 border">
                        {moment(item.date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-3 py-1 border">{item.target}</td>
                      <td className="px-3 py-1 border">{item.created_by}</td>
                      <td
                        className={`px-3 py-1 border ${
                          item.status === 2 || item.status === 4
                            ? "text-green-500"
                            : item.status === 3
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        {item.status === 1
                          ? "In Progress"
                          : item.status === 2 || item.status === 4
                          ? "Accepted"
                          : "Rejected"}
                      </td>
                      <td className="px-3 py-1 border bg-gray-100 text-center">
                        <div className="flex justify-center">
                          <BsTrash
                            className={`${
                              item.status === 1
                                ? "text-red-500 cursor-pointer hover:text-red-700"
                                : "text-gray-300 cursor-default"
                            }`}
                            onClick={() => {
                              if (item.status === 1) {
                                setSelectedRetur(item);
                                setOpenDelete(true);
                              }
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center px-3 py-1 border">
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "No data found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.count}
        />
      ) : null}
      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
        resetFunc={() =>
          setReqBody((prevValue) => ({
            ...prevValue,
            offset: 1,
            start_date: INITIAL_REQ.start_date,
            end_date: INITIAL_REQ.end_date,
          }))
        }
      />
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full min-w-[20vw]">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElFilter(null)}
            />
            <p className="font-bold text-center">Filter</p>
            <p
              className="text-red-500 cursor-pointer hover:text-gray-500 text-right"
              onClick={() =>
                setReqBody((prevValue) => ({
                  ...prevValue,
                  offset: 1,
                  status: [],
                  company_id: [],
                }))
              }
            >
              Reset
            </p>
          </div>
        </div>
        <hr />
        <div
          className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
          onClick={(e) => setAnchorElStatus(e.currentTarget)}
        >
          <p>Status</p>
          <FaChevronRight />
        </div>
        <div>
          <div
            className="px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 flex justify-between items-center"
            onClick={(e) => setAnchorElOutlet(e.currentTarget)}
          >
            <p>Outlet</p>
            <FaChevronRight />
          </div>
          <div className="p-2 grid grid-cols-2 gap-2">
            {reqBody.company_id.length > 0 ? (
              reqBody.company_id.map((item) => (
                <div className="flex items-center justify-between gap-2 bg-gray-200 p-1 rounded-md w-full">
                  <p>{item.company_name}</p>
                  <RxCross1
                    className="text-red-500 cursor-pointer hover:text-red-700"
                    onClick={() => {
                      const index = reqBody.company_id.findIndex(
                        (outlet) => outlet.company_id === item.company_id
                      );

                      setReqBody((prevValue) => ({
                        ...prevValue,
                        offset: 1,
                        company_id: [
                          ...prevValue.company_id.slice(0, index),
                          ...prevValue.company_id.slice(index + 1),
                        ],
                      }));
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-300 w-full ml-8">No outlet selected</p>
            )}
          </div>
        </div>
      </Popover>
      <Popover
        open={Boolean(anchorElStatus)}
        anchorEl={anchorElStatus}
        onClose={() => setAnchorElStatus(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElStatus(null)}
            />
            <p className="font-bold text-center">Status</p>
          </div>
        </div>
        <hr />
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            reqBody.status.includes(0) ? "bg-gray-200" : ""
          }`}
          onClick={() => handleChangeStatus(null)}
        >
          All
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            reqBody.status.includes(1) ? "bg-gray-200" : ""
          }`}
          onClick={() => handleChangeStatus(1)}
        >
          In progress
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer border-b-2 ${
            reqBody.status.includes(2) ? "bg-gray-200" : ""
          }`}
          onClick={() => handleChangeStatus(2)}
        >
          Accepted
        </div>
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
            reqBody.status.includes(3) ? "bg-gray-200" : ""
          }`}
          onClick={() => handleChangeStatus(3)}
        >
          Rejected
        </div>
      </Popover>
      <Popover
        open={Boolean(anchorElOutlet)}
        anchorEl={anchorElOutlet}
        onClose={() => setAnchorElOutlet(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="px-8 py-2 w-full">
          <div className=" grid grid-cols-3 items-center gap-4">
            <FaChevronLeft
              className="hover:text-red-500"
              onClick={() => setAnchorElOutlet(null)}
            />
            <p className="font-bold text-center">Outlet</p>
          </div>
        </div>
        <hr />
        <div
          className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
            dataOutlet &&
            dataOutlet.filter((item) => item.status === 1 || item.status === 5)
              .length > 0
              ? "border-b-2"
              : ""
          } ${reqBody.company_id.length === 0 ? "bg-gray-100" : ""}`}
          onClick={() => handleSelectOutlet(null)}
        >
          All
        </div>
        {dataOutlet && !isLoading ? (
          dataOutlet
            .filter((item) => item.status === 1 || item.status === 5)
            .map((outlet, i) => (
              <div
                key={i}
                className={`px-8 py-2 hover:bg-gray-300 cursor-pointer ${
                  i !==
                  dataOutlet.filter(
                    (item) => item.status === 1 || item.status === 5
                  ).length -
                    1
                    ? "border-b-2"
                    : ""
                } 
              ${reqBody.company_id.includes(outlet) ? "bg-gray-100" : ""}`}
                onClick={() => handleSelectOutlet(outlet)}
              >
                {outlet.company_name}
              </div>
            ))
        ) : (
          <div className="w-full flex justify-center px-8 py-2">
            {isFetchingOutlet ? (
              <CircularProgress size={20} />
            ) : (
              <p>Outlet tidak ditemukan</p>
            )}
          </div>
        )}
      </Popover>
      <ModalDeleteWarehouse
        open={openDelete}
        setOpen={setOpenDelete}
        setConfirm={setConfirm}
        type={3}
      />
      <ModalConfirmationAction
        open={openSuccessDelete}
        setOpen={setOpenSuccessDelete}
        message={"Retur Warehouse Information Deleted"}
        onButtonPress={() =>
          mutateList({
            ...reqBody,
            company_id: reqBody.company_id.map((item) => item.company_id),
          })
        }
      />
      <div className="hidden">
        <PrintNota
          type="warehouse"
          dataLabel={dataLabel}
          data={dataDetail}
          dataList={dataDetail ? dataDetail.product : []}
          printRef={printRef}
          title="Nota Retur Warehouse"
          tableContent={[
            {
              header: "No",
              key: "INDEX",
            },
            {
              header: "Nama Barang",
              key: "product_name",
            },
            {
              header: "No. Batch",
              key: "batch",
            },
            {
              header: "Exp Date",
              key: "expired_date",
            },
            {
              header: "Satuan",
              key: "uom",
            },
            {
              header: "Qty",
              key: "quantity",
            },
            {
              header: "Harga Satuan",
              key: "hna",
              type: "currency",
            },
            {
              header: "Total Harga",
              key: "total",
              type: "currency",
            },
          ]}
          totalPrice={0}
          NotaInfo={NotaInfo}
          email={
            dataDetail && dataDetail.header.email ? dataDetail.header.email : ""
          }
          sia={dataDetail && dataDetail.header.sia ? dataDetail.header.sia : ""}
        />
      </div>
    </>
  );
};

export default ReturWarehouseList;
