import { useState, useEffect } from "react";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosAddCircleOutline, IoIosArrowRoundBack } from "react-icons/io";

import { getProductList } from "../../../services/invoiceApi";
import InfiniteScroll from "react-infinite-scroll-component";

const FETCH_LIMIT = 10;

const AddProductInvoice = ({
  setIsAddProduct,
  setProductArr,
  productArr,
  isWarehouse,
}) => {
  const [search, setSearch] = useState("");
  const [addProductArr, setAddProductArr] = useState(productArr);
  const [offset, setOffset] = useState(1);
  const [fetchNext, setFetchNext] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const handleCheckItem = (checked, item) => {
    var temp = [...addProductArr];
    if (checked) {
      temp.push({
        product_id: item.product_id,
        product_name: item.product_name,
        batch_number: item.batch_no,
        expired_date: item.batch_exp,
        price: item.price,
        on_us: item.on_us,
        off_us: item.off_us,
        promo: item.promo,
        product_units: item.product_units,
        type_uom: 0,
        quantity: 0,
      });
    } else {
      const index = temp.findIndex(
        (prod) => prod.product_id === item.product_id
      );
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    }
    setAddProductArr(temp);
  };

  const changeStock = (id, action, stock, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.product_id === id);
    if (action === "min") temp[index].quantity -= 1;
    if (action === "add") temp[index].quantity += 1;
    if (action === "text")
      isNaN(Number(val))
        ? (temp[index].quantity = 0)
        : (temp[index].quantity = Number(val) > stock ? stock : Number(val));
    setAddProductArr(temp);
  };

  const changePackaging = (id, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.product_id === id);
    temp[index].type_uom = val;
    temp[index].quantity = 0;
    setAddProductArr(temp);
  };

  const handleAddProduct = () => {
    setProductArr(
      addProductArr.filter(
        (item) => item.quantity > 0 && item.type_uom !== null
      )
    );
    setIsAddProduct(false);
  };

  const { isFetching } = useQuery({
    queryKey: ["promo-product", search],
    queryFn: () =>
      getProductList(FETCH_LIMIT, 1, search, isWarehouse ? isWarehouse : false),
    onSuccess: (data) => {
      setItems(data.data);
      if (data.total <= FETCH_LIMIT) {
        setHasMore(false);
      }
      if (data.total > FETCH_LIMIT) setFetchNext(true);
    },
  });

  const { data: nextData, isFetching: isFetchingNext } = useQuery({
    queryKey: ["next-promo-product", offset],
    queryFn: () => getProductList(FETCH_LIMIT, offset, search),
    enabled: fetchNext,
    retry: 3,
    onError: () => {
      setFetchNext(false);
      setHasMore(false);
    },
    onSuccess: (nextData) => {
      var temp = [...items];
      temp.push(...nextData.data);
      setItems(temp);
      setFetchNext(false);
    },
  });

  useEffect(() => {
    if (nextData && nextData.data === null) {
      setHasMore(false);
    }
    if (nextData && nextData.data !== null) {
      nextData.data.length > 0 ? setHasMore(true) : setHasMore(false);
    }
  }, [nextData]);

  useEffect(() => {
    if (fetchNext) setOffset(offset + 1);
  }, [fetchNext]);

  useEffect(() => {
    setHasMore(true);
    setOffset(1);
  }, [search]);

  return (
    <>
      <div className="flex gap-2 items-center">
        <IoIosArrowRoundBack
          className="hover:text-slate-300 cursor-pointer w-[24px] h-full"
          onClick={() => setIsAddProduct(false)}
        />
        <p className="font-bold text-xl my-4">Tambah Product</p>
      </div>
      <div className="flex w-full items-center gap-2">
        <TextField
          value={search}
          label="Search Product (by name, NIE)"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
        <button
          onClick={handleAddProduct}
          className=" w-full basis-1/3 bg-[#99FFC2] flex justify-center p-2 px-4 items-center border-2 border-black rounded-md cursor-pointer hover:bg-[#88dba9]"
        >
          <IoIosAddCircleOutline className="mr-2 text-black" />
          Add Products
        </button>
      </div>
      <div className="block overflow-x-auto my-4">
        <div className="flex w-full">
          <p className="w-[50%] text-center border p-2 font-bold">Select</p>
          <p className="w-full border p-2 font-bold">Product Name</p>
          <p className="w-full border p-2 font-bold">Batch No</p>
          <p className="w-full border p-2 font-bold">Expired Date</p>
          <p className="w-full border p-2 font-bold">Packaging</p>
          <p className="w-[40%] border p-2 font-bold">Stock</p>
          <p className="w-full border p-2 font-bold">Order Qty</p>
        </div>
        <InfiniteScroll
          className="w-full"
          dataLength={items && items.length ? items.length : 0}
          next={() => setFetchNext(true)}
          hasMore={hasMore}
          scrollThreshold={0.9}
          loader={<CircularProgress size={20} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Tidak ada produk lagi</b>
            </p>
          }
        >
          <>
            {items && items.length > 0 ? (
              items.map((item, i) => {
                return (
                  <>
                    <div
                      className={`w-full flex ${
                        item.stock === 0 ? "bg-gray-100" : ""
                      }`}
                      key={i}
                    >
                      <p className="w-[50%] text-center border p-2 font-bold">
                        <Checkbox
                          onChange={(_, checked) => {
                            handleCheckItem(checked, item);
                          }}
                          disabled={item.stock === 0}
                          checked={
                            addProductArr.find(
                              (prod) => prod.product_id === item.product_id
                            ) !== undefined
                          }
                        />
                      </p>
                      <p className="w-full border p-2 truncate">
                        {item.product_name}
                      </p>
                      <p className="w-full border p-2">{item.batch_no}</p>
                      <p className="w-full border p-2">{item.batch_exp}</p>
                      {item.product_units !== null ? (
                        <div className="border w-full">
                          <FormControl sx={{ margin: 2, width: "90%" }}>
                            <InputLabel>Packaging</InputLabel>
                            <Select
                              value={
                                !addProductArr.find(
                                  (prod) => prod.product_id === item.product_id
                                )
                                  ? 0
                                  : addProductArr[
                                      addProductArr.findIndex(
                                        (prod) =>
                                          prod.product_id === item.product_id
                                      )
                                    ].type_uom
                              }
                              disabled={
                                addProductArr.find(
                                  (prod) => prod.product_id === item.product_id
                                ) === undefined
                              }
                              label="Promo Type"
                              onChange={(e) => {
                                changePackaging(
                                  item.product_id,
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem
                                value={null}
                                sx={{ display: "none" }}
                              ></MenuItem>
                              <MenuItem value={0}>
                                {item.product_units.primary_type}
                              </MenuItem>
                              <MenuItem value={1}>
                                {item.product_units.secondary_type}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : null}
                      {/*<p className="w-full border p-2">
                        {item.product_unit.toUpperCase()}
                      </p>*/}
                      <p className="w-[40%] border p-2">
                        {addProductArr[
                          addProductArr.findIndex(
                            (prod) => prod.product_id === item.product_id
                          )
                        ]?.type_uom === 1
                          ? item.stock * item.product_units?.total_secondary
                          : item.stock * item.product_units?.total_primary}
                      </p>
                      <p className="w-full border p-2">
                        <div className="flex justify-center">
                          <button
                            disabled={
                              !addProductArr.find(
                                (prod) => prod.product_id === item.product_id
                              ) ||
                              addProductArr.find(
                                (prod) => prod.product_id === item.product_id
                              ).quantity === 0
                            }
                            className="rounded-l-md py-2 px-3 bg-[#FF7A5D] disabled:bg-gray-200"
                            onClick={() => {
                              changeStock(item.product_id, "min", item.stock);
                            }}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className="bg-slate-100 p-2 w-[15%]"
                            value={
                              !addProductArr.find(
                                (prod) => prod.product_id === item.product_id
                              )
                                ? 0
                                : addProductArr[
                                    addProductArr.findIndex(
                                      (prod) =>
                                        prod.product_id === item.product_id
                                    )
                                  ].quantity
                            }
                            onChange={(e) =>
                              changeStock(
                                item.product_id,
                                "text",
                                item.stock,
                                e.target.value
                              )
                            }
                          />
                          <button
                            disabled={
                              addProductArr[
                                addProductArr.findIndex(
                                  (prod) => prod.product_id === item.product_id
                                )
                              ]?.type_uom === 1
                                ? !addProductArr.find(
                                    (prod) =>
                                      prod.product_id === item.product_id
                                  ) ||
                                  addProductArr.find(
                                    (prod) =>
                                      prod.product_id === item.product_id
                                  ).quantity ===
                                    item.stock *
                                      item.product_units?.total_secondary
                                : !addProductArr.find(
                                    (prod) =>
                                      prod.product_id === item.product_id
                                  ) ||
                                  addProductArr.find(
                                    (prod) =>
                                      prod.product_id === item.product_id
                                  ).quantity ===
                                    item.stock *
                                      item.product_units?.total_primary
                            }
                            className="rounded-r-md bg-[#5DFFBB] py-2 px-3 disabled:bg-gray-200"
                            onClick={() => {
                              changeStock(item.product_id, "add", item.stock);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </p>
                    </div>
                  </>
                );
              })
            ) : !isFetching && !isFetchingNext ? (
              <p className="p-2 border text-center w-full">
                Tidak ada produk dengan nama/NIE yang dicari
              </p>
            ) : null}
          </>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AddProductInvoice;
