import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbInventory = ({ isWarehouse }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2 }}>
        <Link
          href="/home"
          underline="hover"
          color="#1761FD"
          sx={{ color: "#1761FD" }}
        >
          Homepage
        </Link>
        {/* PARENT LINK */}
        {pathname.includes("/inventory/") ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/produk-aktif`}
            sx={{ color: "#1761FD" }}
          >
            Inventory
          </Link>
        ) : null}
        {/* INVENTORY LIST */}
        {pathname.includes("/inventory/produk-aktif") ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/produk-aktif`}
            sx={{ color: "#1761FD" }}
          >
            Produk Aktif
          </Link>
        ) : null}
        {pathname.includes("/inventory/produk-hold") ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/produk-hold`}
            sx={{ color: "#1761FD" }}
          >
            Produk Hold
          </Link>
        ) : null}
        {pathname.includes("/inventory/produk-expired") ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/produk-expired`}
            sx={{ color: "#1761FD" }}
          >
            Produk Expired
          </Link>
        ) : null}
        {pathname.includes("/inventory/produk-inactive") ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/produk-inactive`}
            sx={{ color: "#1761FD" }}
          >
            Produk Tidak Aktif
          </Link>
        ) : null}
        {/* STOCK ADJUSTMENT */}
        {pathname.includes(
          `${
            isWarehouse ? "/central-warehouse" : ""
          }/inventory/stock-adjustment`
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/stock-adjustment`}
            sx={{ color: "#1761FD" }}
          >
            Penyesuaian Stok
          </Link>
        ) : null}
        {pathname.includes(
          `${
            isWarehouse ? "/central-warehouse" : ""
          }/inventory/stock-adjustment/create-adjustment`
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/stock-adjustment/create-adjustment`}
            sx={{ color: "#1761FD" }}
          >
            Create Penyesuaian Stok
          </Link>
        ) : null}
        {/* STOCK OPNAME */}
        {pathname.includes(
          `${isWarehouse ? "/central-warehouse" : ""}/inventory/stock-opname`
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/stock-opname`}
            sx={{ color: "#1761FD" }}
          >
            Stock Opname
          </Link>
        ) : null}
        {pathname.includes(
          `${
            isWarehouse ? "/central-warehouse" : ""
          }/inventory/stock-opname/create/`
        ) ? (
          <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
            Tambah Stock Opname
          </Link>
        ) : null}
        {pathname.includes(
          `${
            isWarehouse ? "/central-warehouse" : ""
          }/inventory/stock-opname/stock-opname-recap/`
        ) ? (
          <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
            Rekap Stock Opname
          </Link>
        ) : null}
        {/* KARTU STOK */}
        {pathname.includes(
          `${isWarehouse ? "/central-warehouse" : ""}/inventory/card-stock`
        ) ? (
          <Link
            underline="none"
            color="#1761FD"
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/card-stock`}
            sx={{ color: "#1761FD" }}
          >
            Kartu Stok
          </Link>
        ) : null}
        {pathname.includes(
          `${
            isWarehouse ? "/central-warehouse" : ""
          }/inventory/card-stock/card-stock-detail-new/`
        ) ? (
          <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
            Detail Kartu Stok
          </Link>
        ) : null}
        {/* INVENTORY SUMMARY */}
        {pathname.includes(
          `${isWarehouse ? "/central-warehouse" : ""}/inventory/summary`
        ) ? (
          <Link
            href={`${
              isWarehouse ? "/central-warehouse" : ""
            }/inventory/summary`}
            underline="none"
            color="#1761FD"
            sx={{ color: "#1761FD" }}
          >
            Inventory Summary
          </Link>
        ) : null}
        {pathname.includes(
          `${isWarehouse ? "/central-warehouse" : ""}/inventory/summary/`
        ) ? (
          <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
            Detail Inventory Summary
          </Link>
        ) : null}
      </Breadcrumbs>
    </>
  );
};

export default BreadcrumbInventory;
