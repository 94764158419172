import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getGoodDoctorCredential = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}gd-credential`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const goodDoctorLogin = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}gd-login`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const goodDoctorSync = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}gd-sync`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
