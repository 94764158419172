import { KeyboardBackspace, Check } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { getPaymentChannelBilling } from "../../../services/paymentApi";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";

const ModalPaymentOption = (props) => {
  const { open, setOpen, setPaymentOption, isLoading } = props;

  const [tempSelection, setTempSelection] = useState({});

  const handleConfirm = () => {
    setPaymentOption(tempSelection);
  };

  const handleCancel = () => {
    setTempSelection(0);
    setPaymentOption(null);
    setOpen(false);
  };

  const { data: dataPayment } = useQuery({
    queryFn: () => getPaymentChannelBilling(1),
    queryKey: ["billing-payment"],
  });

  const SubPaymentOption = ({ sectionTitle, arr }) => {
    return (
      <>
        <p className="font-bold text-lg mb-4">{sectionTitle}</p>

        {arr.map((item) => {
          return (
            <div
              className={`w-full hover:bg-slate-100 flex py-3 justify-between px-4 rounded-md cursor-pointer ${
                item.id === tempSelection.id ? "bg-slate-100" : ""
              }`}
              onClick={() => setTempSelection(item)}
            >
              <div className="w-full flex items-center">
                <img src={item.logo} className="w-[48px] object-contain mr-3" />
                <p>{item.pg_bank}</p>
              </div>
              <div className="w-fit">
                {item.id === tempSelection.id ? (
                  <Check className="text-blue-500" />
                ) : null}
              </div>
              <hr />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {open ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-[80%] my-6 mx-auto max-w-3xl">
              <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[80vh]">
                <div className="flex items-center p-4">
                  <KeyboardBackspace
                    className="hover:text-gray-300 cursor-pointer"
                    onClick={handleCancel}
                  />
                  <p className="text-center font-medium text-[25px] w-full">
                    Metode Pembayaran
                  </p>
                </div>
                <hr />
                <div className="justify-center relative flex-auto object-contain p-4 overflow-y-scroll">
                  {dataPayment ? (
                    <>
                      {dataPayment.virtual_accounts ? (
                        <SubPaymentOption
                          sectionTitle={"Pembayaran Virtual Account"}
                          arr={dataPayment.virtual_accounts}
                        />
                      ) : null}
                      {dataPayment.emoney ? (
                        <SubPaymentOption
                          sectionTitle={"Pembayaran E-Money"}
                          arr={dataPayment.emoney}
                        />
                      ) : null}
                      {dataPayment.credit ? (
                        <SubPaymentOption
                          sectionTitle={"Pembayaran Kartu Kredit"}
                          arr={dataPayment.credit}
                        />
                      ) : null}
                      {dataPayment.retail ? (
                        <SubPaymentOption
                          sectionTitle={"Pembayaran Via Retail"}
                          arr={dataPayment.retail}
                        />
                      ) : null}
                      {dataPayment.ewallet ? (
                        <SubPaymentOption
                          sectionTitle={"Pembayaran E-Wallet"}
                          arr={dataPayment.ewallet}
                        />
                      ) : null}
                    </>
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </div>
                <div className="w-full p-2">
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleConfirm}
                    disabled={isLoading}
                  >
                    Konfirmasi
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalPaymentOption;
