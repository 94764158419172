import React from "react";
import Logo from "../../assets/icons/logoOlin.png";
import { useProSidebar } from "react-pro-sidebar";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const { toggleSidebar, broken } = useProSidebar();

  return (
    <>
      <nav className="bg-white p-2  block lg:hidden fixed z-20 w-full top-0 left-0 border-b-2 border-gray-200 ">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <img src={Logo} className="h-6 mr-3 sm:h-9" alt="Olin Logo" />
          {broken ? (
            <button onClick={() => toggleSidebar()}>
              <FaBars />
            </button>
          ) : null}
        </div>
      </nav>
    </>
  );
};

export default Header;
