import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useQuery, useMutation } from "@tanstack/react-query";

import Breadcumbs from "../../common/particles.jsx/Breadcumbs";

import { getBank, getPayment, addPayment } from "../../../services/paymentApi";

const PaymentMethod = () => {
  const [value, setValue] = useState([]);
  const [data, setData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let payment = [];

  const { data: bank, isLoading: isBankLoading } = useQuery({
    queryKey: ["bank"],
    queryFn: () => getBank(),
    keepPreviousData: true,
  });

  const { data: payments, isLoading: isPaymentLoading } = useQuery({
    queryKey: ["payment"],
    queryFn: () => getPayment(),
    keepPreviousData: true,
  });

  const { mutate } = useMutation({
    mutationFn: addPayment,
    onSuccess: (data) => {
      setSuccessFlag(true);
      setSuccessMessage(data.message.id);
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const allOptions =
    payments !== undefined
      ? payment.concat(
          payments.emoney === null ? [] : payments.emoney,
          payments.credit === null ? [] : payments.credit,
          payments.debit === null ? [] : payments.debit
        )
      : [];

  useEffect(() => {
    setValue(
      allOptions && allOptions !== undefined
        ? allOptions.map((val) => {
            return {
              payment_id: val.bank_id,
              name: val.name_payment,
              type: val.type_payment_id,
            };
          })
        : []
    );
    setData(
      allOptions && allOptions !== undefined
        ? allOptions.map((val) => {
            return {
              payment_id: val.bank_id,
              type: val.type_payment_id,
            };
          })
        : []
    );
  }, [payments]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Payment Method
      </h1>
      <Breadcumbs
        nama1="Setting"
        nama2="Payment Method"
        link1="/settings/payment-method"
        link2="/settings/payment-method"
      />
      {isBankLoading || isPaymentLoading ? (
        <div className="flex justify-center items-center h-[300px]">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-blue-500 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <div className="w-full mt-5">
          <h1 className="text-center font-bold text-[20px]">Select Payment</h1>
          <Autocomplete
            className="mt-10"
            multiple
            filterSelectedOptions
            id="tags-outlined"
            value={value}
            options={
              bank && bank !== undefined
                ? bank.map((b) => {
                    return {
                      payment_id: b.id,
                      name: b.name,
                      type: b.type_id,
                    };
                  })
                : []
            }
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(_, value) => {
              setValue(
                value.map((v) => {
                  return {
                    payment_id: v.payment_id,
                    type: v.type,
                    name: v.name,
                  };
                })
              );
              setData(
                value.map((v) => {
                  return { payment_id: v.payment_id, type: v.type };
                })
              );
              setSuccessFlag(false)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Payment Method"
                placeholder="Pilih Bank"
              />
            )}
          />
        </div>
      )}

      <div className="flex items-end justify-end">
        <button
          disabled={value.length === 0 || successFlag}
          className="mt-4 p-2 px-5 bg-[#FF8D40] text-white font-bold rounded-xl disabled:bg-gray-200"
          onClick={() => mutate(data)}
        >
          Save
        </button>
      </div>
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setSuccessMessage("");
        }}
        message={successMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") return;
                setSuccessMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") return;
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default PaymentMethod;
