import { useState, useEffect, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { fetchProductList } from "../../../services/InventoryAPI";
import useDebounce from "../../hooks/useDebounce";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 3;

const AddProductStockoutContent = (props) => {
  const { selectedProductId, setSelectedProductId, principalId } = props;
  const [offset, setOffset] = useState(1);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");

  const debounce = useDebounce(search, 500);

  const {
    mutate: mutateProductList,
    data: products,
    isLoading,
  } = useMutation({
    mutationFn: fetchProductList,
  });

  const handleSelectList = useCallback(
    (selectedId) => {
      const selectedValue = selectedProductId.findIndex(
        (id) => id === selectedId
      );
      let updatedList = cloneDeep(selectedProductId);
      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setSelectedProductId(updatedList);
    },
    [selectedProductId]
  );

  useEffect(() => {
    mutateProductList({
      status: STATUS_PRODUCT,
      limit: FETCH_LIMIT,
      type,
      offset,
      name: debounce,
      principal_id: principalId,
    });
  }, [offset, debounce, type]);

  useEffect(() => {
    const id = sessionStorage
      .getItem("id")
      .split(",")
      .map((id) => parseInt(id, 10));
    setSelectedProductId(id);
  }, []);

  return (
    <>
      <div methode="get" action="#" className="relative z-10">
        <button
          id="searchsubmit"
          className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <svg
            className="h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-2 border  rounded-md leading-5 bg-white  placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out"
          placeholder="Cari produk"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center mt-3">
          <CircularProgress />
        </div>
      ) : (
        <>
          {products && products.product_data !== null ? (
            <>
              {products.product_data.map((item, index) => (
                <div
                  className={`mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between
                   ${
                     sessionStorage
                       .getItem("id")
                       .split(",")
                       .find(
                         (id) => parseInt(id, 10) === Number(item.product_id)
                       )
                       ? "bg-gray-200"
                       : null
                   }
                                      `}
                  key={index}
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name={item.product_name}
                      checked={selectedProductId.includes(item.product_id)}
                      disabled={sessionStorage
                        .getItem("id")
                        .split(",")
                        .find(
                          (id) => parseInt(id, 10) === Number(item.product_id)
                        )}
                      onChange={() => handleSelectList(item.product_id)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600  focus:ring-2 "
                    />
                    <img
                      src={item.media}
                      alt="foto_product"
                      className="w-20 mx-2"
                    />
                    <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden">
                      <p>{item.product_name}</p>
                      <p className="font-bold">Rp. {item.sell_price}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex justify-center items-center mt-5">
              <div className="flex flex-col items-center">
                <p className="text-gray-500">Tidak ada produk</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddProductStockoutContent;
