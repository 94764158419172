import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { SlArrowRight } from "react-icons/sl";
import { BsChatRightDots } from "react-icons/bs";
import { MdOutlineAccessTime } from "react-icons/md";
import { TfiReceipt } from "react-icons/tfi";

import {
  cancelOrder,
  getPrescriptionDoctor,
  updateStatusOrder,
} from "../../../services/OrderApi";
import { statusOrder, statusOrderColor } from "../../constants/constant";
import { ChatboxContext } from "../../context/ChatboxProvider";
import RecipePreview from "../../common/particles.jsx/RecipePreview";
import useDebounce from "../../hooks/useDebounce";

const toDate = (date) => moment(date).format("DD MMMM YYYY, HH:mm");

const twoDays = (date) =>
  moment(date).add(2, "days").format("DD MMMM YYYY, HH:mm");

const oneDay = (date) =>
  moment(date).add(1, "days").format("DD MMMM YYYY, HH:mm");

const button = {
  2: "Terima Pesanan",
  3: "Siap Dikirim",
};

const FETCH_LIMIT = 10;

const OrderCard = (props) => {
  const navigate = useNavigate();
  const {
    orderListData,
    setIsModalOpen,
    setRecipeCopy,
    isOrderListLoading,
    setDetailStatus,
    setIsDrugModalOpen,
    mutateOrderDetail,
    isLoading,
    setUpdateReceiptBody,
    isRecipeCopy,
    setIsRecipeCopy,
    setIsRevenueModalOpen,
    setPrescriptionImg,
    setRecipeBody,
    detailStatus,
    mutateOrderList,
  } = props;

  const [cancelModal, setCancelModal] = useState({
    isModalOpen: false,
    isOtherReason: false,
    cancel_reason: "",
    buffer: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [resBody, setResBody] = useState({
    limit: FETCH_LIMIT,
    offset: 1,
    start_date: "",
    end_date: "",
    search: "",
    status: [1],
    sort: 2,
  });

  const debounce = useDebounce(resBody.search, 500);

  const { setProductName, setBuyerName, setIsChatBoxOpen } =
    useContext(ChatboxContext);

  const { mutate: mutateCancelOrder } = useMutation({
    mutationFn: cancelOrder,
    onSuccess() {
      setCancelModal({
        isModal: false,
        cancel_reason: "",
      });
      setIsDrugModalOpen(false);
      mutateOrderList(resBody);
    },
  });

  const { mutate: mutateUpdateStatusOrder } = useMutation({
    mutationFn: updateStatusOrder,
    onSuccess() {
      mutateOrderList(resBody);
    },
  });

  const [prescriptionDoctorId, setPrescriptionDoctorId] = useState({
    isModalOpen: false,
    recipe_id: 0,
  });

  const {
    data: doctorPrescriptionData,
    isLoading: isLoadingPrescriptionDoctor,
  } = useQuery({
    queryFn: () => getPrescriptionDoctor(prescriptionDoctorId.recipe_id),
    queryKey: ["doctor-recipe", prescriptionDoctorId.recipe_id],
    enabled: prescriptionDoctorId.recipe_id !== 0,
  });

  useEffect(() => {
    mutateOrderList(resBody);
  }, [resBody, mutateOrderList, debounce]);

  return (
    <div>
      <div>
        {isOrderListLoading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {orderListData && orderListData !== undefined
              ? orderListData.data?.map((data, index) => (
                  <div
                    className="border border-black rounded-md p-3 my-3 font-bold"
                    key={index}
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-x-2 items-center">
                        <div className="flex items-center">
                          <p
                            style={{
                              backgroundColor: statusOrderColor[data.status],
                              padding: "0.5rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              borderRadius: "9999px",
                              color: "white",
                            }}
                          >
                            {statusOrder[data.status]}
                          </p>
                        </div>
                        <p>/</p>
                        <p
                          className="text-blue-500 hover:underline cursor-pointer"
                          onClick={() =>
                            navigate(`/order/${data.invoice_number}`)
                          }
                        >
                          {data.invoice_number}
                        </p>
                        <p>/</p>
                        <p>{data.fullname_censored}</p>
                        <p>/</p>
                        <p>{toDate(data.created_at) + " WIB"}</p>
                      </div>
                      {data.status === 2 ||
                      data.status === 3 ||
                      data.status === 4 ? (
                        <div>
                          <p className="text-center">Batas Response</p>
                          <div className="rounded-full bg-[#FFB30F] py-1 px-3 text-white flex items-center ">
                            <p>{twoDays(data.created_at)}</p>
                            <MdOutlineAccessTime className="text-[25px] ml-2" />
                          </div>
                        </div>
                      ) : null}
                      {data.status === 8 ? (
                        <div>
                          <p>Batas Response</p>
                          <div className="rounded-full bg-[#FFB30F] py-1 px-3 text-white flex items-center ">
                            <p>{oneDay(data.created_at)}</p>
                            <MdOutlineAccessTime className="text-[25px] ml-2" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full border border-black my-2"></div>

                    <div className="flex justify-between w-full">
                      <div className="w-[50%]">
                        <div className="flex items-center gap-x-3 border border-black p-3 rounded-md  w-full">
                          <img
                            src={data.media}
                            alt=""
                            className="rounded-md w-[150px] h-auto bg-cover"
                          />
                          <div className="w-full">
                            <div className="w-full flex justify-between">
                              <p>{data.product_name}</p>
                              {data.product_label_id === 3 ||
                              data.product_label_id === 4 ||
                              data.product_label_id === 5 ? (
                                <p className="font-bold text-red-500">
                                  Perlu Resep Obat
                                </p>
                              ) : null}
                            </div>

                            <p className="text-sm">Jumlah: {data.quantity}</p>
                            <p>
                              {data.price.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                            <div className="flex">
                              {(data.product_label_id === 3 ||
                                data.product_label_id === 4 ||
                                data.product_label_id === 5) &&
                              data.recipe_doctor_id !== 0 ? (
                                <button
                                  className="border-2 border-blue-500 font-bold text-blue-500 rounded-full px-3 py-1 mr-2 text-sm hover:bg-blue-100 mt-2"
                                  onClick={() => {
                                    if (data.recipe_doctor_id === 0) {
                                      setPrescriptionImg(
                                        data.prescription_image
                                      );
                                      if (data.copy_recipe_doctor) {
                                        setIsRecipeCopy(false);
                                      } else {
                                        setIsRecipeCopy(true);
                                      }
                                      setRecipeCopy({
                                        isModalOpen: true,
                                        invoice_number: data.invoice_number,
                                      });
                                      if (data.copy_recipe_doctor) {
                                        setRecipeBody({
                                          invoice_number: data.invoice_number,
                                        });
                                      } else {
                                        setRecipeBody({
                                          recipe_id: data.recipe_id,
                                        });
                                      }
                                    } else {
                                      setPrescriptionDoctorId({
                                        isModalOpen: true,
                                        recipe_id: data.recipe_doctor_id,
                                      });
                                    }
                                  }}
                                >
                                  Lihat Resep Konsultasi Dokter
                                </button>
                              ) : (data.product_label_id === 3 ||
                                  data.product_label_id === 4 ||
                                  data.product_label_id === 5) &&
                                data?.prescription_image?.length > 0 &&
                                data.recipe_doctor_id === 0 ? (
                                <button
                                  className="border-2 border-blue-500 font-bold text-blue-500 rounded-full px-3 py-1 mr-2 text-sm hover:bg-blue-100 mt-2"
                                  onClick={() => {
                                    if (data.recipe_doctor_id === 0) {
                                      setPrescriptionImg(
                                        data.prescription_image
                                      );
                                      if (data.copy_recipe_doctor) {
                                        setIsRecipeCopy(false);
                                      } else {
                                        setIsRecipeCopy(true);
                                      }
                                      setRecipeCopy({
                                        isModalOpen: true,
                                        invoice_number: data.invoice_number,
                                      });
                                      if (data.copy_recipe_doctor) {
                                        setRecipeBody({
                                          invoice_number: data.invoice_number,
                                        });
                                      } else {
                                        setRecipeBody({
                                          recipe_id: data.recipe_id,
                                        });
                                      }
                                    } else {
                                      setPrescriptionDoctorId({
                                        isModalOpen: true,
                                        recipe_id: data.recipe_doctor_id,
                                      });
                                    }
                                  }}
                                >
                                  Lihat Resep Dokter
                                </button>
                              ) : (data.product_label_id === 3 ||
                                  data.product_label_id === 4 ||
                                  data.product_label_id === 5) &&
                                data.recipe_id !== null ? (
                                <button
                                  className="border-2 border-blue-500 font-bold text-blue-500 rounded-full px-3 py-1 mr-2 text-sm hover:bg-blue-100 mt-2"
                                  onClick={() => {
                                    if (data.recipe_doctor_id === 0) {
                                      setPrescriptionImg(
                                        data.prescription_image
                                      );
                                      if (data.copy_recipe_doctor) {
                                        setIsRecipeCopy(false);
                                      } else {
                                        setIsRecipeCopy(true);
                                      }
                                      setRecipeCopy({
                                        isModalOpen: true,
                                        invoice_number: data.invoice_number,
                                      });
                                      if (data.copy_recipe_doctor) {
                                        setRecipeBody({
                                          invoice_number: data.invoice_number,
                                        });
                                      } else {
                                        setRecipeBody({
                                          recipe_id: data.recipe_id,
                                        });
                                      }
                                    } else {
                                      setPrescriptionDoctorId({
                                        isModalOpen: true,
                                        recipe_id: data.recipe_doctor_id,
                                      });
                                    }
                                  }}
                                >
                                  Lihat Salinan Resep Dokter
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {data.total_product === 1 ? null : (
                          <p
                            className="text-blue-500 font-bold hover:underline cursor-pointer text-end my-3"
                            onClick={() =>
                              navigate(`/order/${data.invoice_number}`)
                            }
                          >
                            +{data.total_product - 1} more product
                          </p>
                        )}
                      </div>
                      <div className="flex justify-center w-[25%]">
                        <div className="h-full border border-black mx-3"></div>
                        <div className="p-2">
                          <p>Alamat</p>
                          <p>{data.address}</p>
                        </div>
                      </div>
                      <div className="flex justify-center w-[25%]">
                        <div className="h-full border border-black mr-3"></div>
                        <div className="p-2">
                          <p>Kurir</p>
                          <p>{data.shipping_method_name}</p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full border border-black my-2"></div>
                    {data.status === 6 || data.status === 7 ? (
                      <p>Alasan Penolakan : {data.cancel_reason}</p>
                    ) : (
                      <div className="flex gap-x-2 justify-between">
                        <p>Total Penjualan</p>
                        <button
                          className="flex items-center border border-black"
                          onClick={() => {
                            setIsRevenueModalOpen(true);
                            mutateOrderDetail(data.invoice_number);
                          }}
                        >
                          <p>
                            {(data.price * data.quantity).toLocaleString(
                              "id-ID",
                              {
                                style: "currency",
                                currency: "IDR",
                              }
                            )}
                          </p>
                          <SlArrowRight />
                        </button>
                      </div>
                    )}
                    <div className="w-full border border-black my-2"></div>
                    <div className="flex gap-x-2 justify-between w-full">
                      <div className="flex items-center">
                        <span
                          className="flex items-center cursor-pointer"
                          onClick={() =>
                            setDetailStatus((prevValue) => ({
                              ...prevValue,
                              isModalOpen: true,
                              invoiceNumber: data.invoice_number,
                              status: data.status,
                              cancelReason: data.cancel_reason,
                              fullname: data.fullname,
                              createdAt: data.created_at,
                            }))
                          }
                        >
                          <TfiReceipt className="mr-2" /> Detail Status
                        </span>
                        {/* <div className="h-full border border-black mx-3"></div> */}
                        {/* <span
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            setIsChatBoxOpen(true);
                            setBuyerName(data.fullname);
                            setProductName(data.product_name);
                          }}
                        >
                          <BsChatRightDots className="mr-2" /> Chat Pembeli
                        </span> */}
                      </div>
                      {data.status === 5 ||
                      data.status === 6 ||
                      data.status === 7 ||
                      data.status === 8 ? null : (
                        <div className="flex gap-2">
                          {data.status === 2 && (
                            <button
                              className="px-3 py-2 rounded-md border-2 border-red-500 text-red-500 font-bold text-[13px] hover:bg-slate-200"
                              onClick={() => {
                                setCancelModal((prevValue) => ({
                                  ...prevValue,
                                  isModalOpen: true,
                                }));
                                setInvoiceNumber(data.invoice_number);
                              }}
                            >
                              Tolak Pesanan
                            </button>
                          )}
                          <button
                            className="bg-blue-500 text-white py-2 px-3 rounded-lg text-sm hover:bg-blue-700"
                            onClick={() => {
                              if (data.copy_recipe_doctor) {
                                setRecipeBody({
                                  invoice_number: data.invoice_number,
                                });
                              } else {
                                setRecipeBody({
                                  recipe_id: data.recipe_id,
                                });
                              }
                              mutateOrderDetail(data.invoice_number);
                              if (data.status === 2 && data.hard_drug_flag) {
                                setIsDrugModalOpen(true);
                                mutateOrderDetail(data.invoice_number);
                                setDetailStatus((prevValue) => ({
                                  ...prevValue,
                                  recipe_doctor_id: data.recipe_doctor_id,
                                  invoiceNumber: data.invoice_number,
                                  shippingId: data.shipping_method_id,
                                }));
                              }
                              if (data.status === 3 && !isLoading) {
                                setUpdateReceiptBody((prevValue) => ({
                                  ...prevValue,
                                  invoice_number: data.invoice_number,
                                  isModalOpen: true,
                                }));
                              }
                              if (data.status === 8) {
                                mutateUpdateStatusOrder({
                                  invoice_number: data.invoice_number,
                                  status: 5,
                                  recipe_copy_data: [],
                                });
                              }
                              if (data.status === 2 && !data.hard_drug_flag) {
                                mutateUpdateStatusOrder({
                                  invoice_number: data.invoice_number,
                                  status: data.shipping_method_id === 4 ? 8 : 3,
                                  recipe_copy_data: [],
                                });
                              }
                            }}
                          >
                            {button[data.status]}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      </div>

      <Dialog
        open={cancelModal.isModalOpen}
        fullWidth
        maxWidth="md"
        onClose={() =>
          setCancelModal({
            isModalOpen: false,
            cancel_reason: "",
            buffer: "",
            isOtherReason: false,
          })
        }
      >
        <DialogTitle>Tolak Pesanan</DialogTitle>
        <DialogContent>
          <p>Apa Anda yakin ingin menolak pesanan ini</p>
          <div className="flex">
            <FormControl fullWidth sx={{ marginY: 3 }}>
              <InputLabel>Alasan Penolakan</InputLabel>
              <Select
                label="Alasan Penolakan"
                value={cancelModal.buffer}
                onChange={(e) => {
                  if (e.target.value === "Others") {
                    setCancelModal((prevValue) => ({
                      ...prevValue,
                      buffer: e.target.value,
                      isOtherReason: true,
                    }));
                  } else {
                    setCancelModal((prevValue) => ({
                      ...prevValue,
                      buffer: e.target.value,
                      cancel_reason: e.target.value,
                      isOtherReason: false,
                    }));
                  }
                }}
              >
                <MenuItem value={"Resep tidak sesuai dengan pesanan"}>
                  Resep tidak sesuai dengan pesanan
                </MenuItem>
                <MenuItem value={"Resep tidak jelas"}>
                  Resep tidak jelas
                </MenuItem>
                <MenuItem value={"Obat yang dipesan sudah tidak tersedia"}>
                  Obat yang dipesan sudah tidak tersedia
                </MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
                <MenuItem
                  value={""}
                  disabled
                  sx={{ display: "none" }}
                ></MenuItem>
              </Select>
            </FormControl>
          </div>
          {cancelModal.isOtherReason ? (
            <TextField
              placeholder="Masukkan alasan penolakan"
              fullWidth
              value={cancelModal.cancel_reason}
              onChange={(e) => {
                setCancelModal((prevValue) => ({
                  ...prevValue,
                  cancel_reason: e.target.value,
                }));
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <button
            className="px-3 py-2 rounded-md border border-black font-bold w-full mr-2"
            onClick={() => {
              setCancelModal((prevValue) => ({
                ...prevValue,
                isModalOpen: false,
              }));
            }}
          >
            Cancel
          </button>
          <button
            className="px-3 py-2 rounded-md text-white bg-red-500 font-bold w-full"
            onClick={() => {
              mutateCancelOrder({
                invoice_number: invoiceNumber,
                cancel_reason: cancelModal.cancel_reason,
              });
            }}
          >
            Tolak
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={prescriptionDoctorId.isModalOpen}
        onClose={() => {
          setPrescriptionDoctorId({
            isModalOpen: false,
            recipe_id: 0,
          });
        }}
        fullWidth
        maxWidth="sm"
      >
        {isLoadingPrescriptionDoctor ? (
          <div className="flex">
            <CircularProgress />
          </div>
        ) : (
          <DialogContent>
            <RecipePreview
              receipeValue={doctorPrescriptionData}
              isCreate={false}
              isCart={true}
              isPreview={true}
            />
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default OrderCard;
