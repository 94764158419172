import React, { useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useMutation } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";

import { fetchPrincipal } from "../../../services/InventoryAPI";

const Principal = (props) => {
  const { offset, status, listId, setListId } = props;
  const {
    mutate: mutatePrincipals,
    data: principals,
    isLoading,
  } = useMutation({
    mutationFn: fetchPrincipal,
  });

  const handleSelectList = useCallback(
    (selectedId) => {
      const selectedValue = listId.findIndex((id) => id === selectedId);

      let updatedList = cloneDeep(listId);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setListId(updatedList);
    },
    [listId]
  );

  useEffect(() => {
    mutatePrincipals({
      status,
      limit: 10000,
      name: "",
      offset,
    });
  }, [offset]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-principal="simple table">
            <TableBody>
              {principals && principals !== undefined ? (
                <>
                  {principals.map((principal) => (
                    <TableRow
                      key={principal.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ paddingY: 0 }}>
                        <Checkbox
                          onClick={() => {
                            handleSelectList(principal.id);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ paddingY: 0 }}
                        component="th"
                        scope="principal"
                      >
                        {principal.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Principal;
