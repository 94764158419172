import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getCustomerOrderDetail } from "../../../services/customerApi";
import moment from "moment";
import { FaWhatsapp } from "react-icons/fa";

const DetailOrder = () => {
  const { id = "" } = useParams();

  const { data } = useQuery({
    queryKey: ["orderDetail"],
    queryFn: () => getCustomerOrderDetail(id),
  });

  return (
    <>
      {data && data !== null ? (
        <div className="bg-gray-200 flex justify-center items-center min-h-screen p-8">
          <div className="bg-white rounded-md p-4 w-1/2">
            <div className="w-full text-center">
              <div className="w-full flex justify-center mb-8">
                <img
                  className="text-center w-[56px]"
                  src={data.detail_invoice.logo}
                />
              </div>
              <p className="text-[20px] w-full">
                {data.detail_invoice.name_outlet}
              </p>
              <p className="text-[20px] w-full mb-8">
                {data.detail_invoice.address}, {data.detail_invoice.city},{" "}
                {data.detail_invoice.state} {data.detail_invoice.zipcode}
              </p>
            </div>

            <div className="my-1">
              <p className="inline-block w-[30%]">Apoteker</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">
                {data.detail_invoice.nama_apoteker}
              </p>
            </div>
            <div className="my-1">
              <p className="inline-block w-[30%]">SIA</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">{data.detail_invoice.sia}</p>
            </div>
            <div className="mt-1 pb-4 border-b-2 border-dashed">
              <p className="inline-block w-[30%]">SIPA</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">{data.detail_invoice.sipa}</p>
            </div>
            <div className="mt-4 mb-1">
              <p className="inline-block w-[30%]">No. Order</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">{data.subtotal.transaction_id}</p>
            </div>
            <div className="my-1">
              <p className="inline-block w-[30%]">Tanggal Order</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">
                {moment(data.detail_payment.created_at).format(
                  "DD MMMM YYYY, hh:mm"
                )}
              </p>
            </div>
            <div className="my-1">
              <p className="inline-block w-[30%]">Kasir</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">{data.detail_invoice.nama_kasir}</p>
            </div>
            <div className="mt-1 pb-4 mb-3 border-b-2 border-dashed">
              <p className="inline-block w-[30%]">Catatan</p>
              <p className="mx-2 inline-block">:</p>
              <p className="inline-block">{data.subtotal.notes}</p>
            </div>

            <p className="font-bold font-[16px]">
              {data.list_medicine[0].platform_id === 0 ? "Regular" : "Platform"}
            </p>

            {data.list_medicine.filter(
              (item) =>
                item.type_concoction_id &&
                (item.type_concoction_id === 1 || item.type_concoction_id === 2)
            ).length > 0 ? (
              <>
                {data.list_medicine.filter(
                  (item) => item.type_concoction_id === 2
                ).length > 0 ? (
                  <>
                    {" "}
                    <p className="font-bold font-[16px]">Tebus Resep</p>
                    <div className="border-b-2 border-dashed py-4">
                      <p className="">Racikan</p>
                      <>
                        {data.list_medicine
                          .filter((item) => item.type_concoction_id === 2)
                          .map((item) => (
                            <>
                              <p className="w-full text-[12px]">
                                {item.name_medicine}
                              </p>

                              <div className="my-1 flex justify-between text-[12px]">
                                <p className="w-full">
                                  {item.quantity} x{" "}
                                  {item.price?.toLocaleString("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  })}
                                </p>
                                <p className="text-right w-full">
                                  {(item.price * item.quantity).toLocaleString(
                                    "id-ID",
                                    {
                                      style: "currency",
                                      currency: "IDR",
                                    }
                                  )}
                                </p>
                              </div>
                            </>
                          ))}
                        <div className="flex justify-between">
                          <p className="text-[12px] mb-2">Biaya Racikan:</p>
                          <p className="text-[12px] mb-2">
                            {" "}
                            {data.list_medicine.filter(
                              (item) => item.type_concoction_id === 2
                            ).length > 0 &&
                            data.list_medicine.filter(
                              (item) => item.type_concoction_id === 2
                            )[0].concoction_fee
                              ? data.list_medicine
                                  .filter(
                                    (item) => item.type_concoction_id === 2
                                  )[0]
                                  .concoction_fee.toLocaleString("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  })
                              : 0}
                          </p>
                        </div>
                      </>
                    </div>
                  </>
                ) : null}

                {data.list_medicine.filter(
                  (item) => item.type_concoction_id === 1
                ).length > 0 ? (
                  <div className="border-b-2 border-dashed py-4">
                    <p className="">Non Racikan</p>
                    <>
                      {data.list_medicine
                        .filter((item) => item.type_concoction_id === 1)
                        .map((item) => (
                          <>
                            <p className="w-full text-[12px]">
                              {item.name_medicine}
                            </p>

                            <div className="my-1 flex justify-between text-[12px]">
                              <p className="w-full">
                                {item.quantity} x{" "}
                                {item.price?.toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })}
                              </p>
                              <p className="text-right w-full">
                                {(item.price * item.quantity).toLocaleString(
                                  "id-ID",
                                  {
                                    style: "currency",
                                    currency: "IDR",
                                  }
                                )}
                              </p>
                            </div>
                          </>
                        ))}
                    </>
                  </div>
                ) : null}
              </>
            ) : null}

            {data.list_medicine.filter((item) => !item.type_concoction_id)
              .length > 0 ? (
              <div className="border-b-2 border-dashed py-4">
                {/* <p className="font-bold font-[16px] mb-2">Regular we</p> */}
                {data.list_medicine
                  .filter((item) => !item.type_concoction_id)
                  .map((item) => (
                    <>
                      <p className="w-full text-[12px]">{item.name_medicine}</p>

                      <div className="my-1 flex justify-between text-[12px]">
                        <p className="w-full">
                          {item.quantity} x{" "}
                          {item.price?.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                        <p className="text-right w-full">
                          {(item.price * item.quantity).toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )}
                        </p>
                      </div>
                    </>
                  ))}{" "}
              </div>
            ) : null}
            <div className="mt-3 pt-4 mb-1 flex justify-between">
              <p className="w-full">Subtotal</p>
              <p className="text-right w-full">
                {data.subtotal.subtotal.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <div className="my-1 flex justify-between">
              <p className="w-full">Biaya Pengiriman</p>
              <p className="text-right w-full">
                {data.subtotal.delivery_fee
                  ? data.subtotal.delivery_fee.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })
                  : 0}
              </p>
            </div>
            <div className="mt-1 pb-4 border-b-2 border-dashed flex justify-between">
              <p className="w-full">{data.detail_payment[0].payment_method}</p>
              <p className="text-right w-full">
                {data.detail_payment[0].amount.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <div className="mt-4 mb-1 flex justify-between">
              <p className="w-full">Total</p>
              <p className="text-right w-full">
                {data.subtotal.total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            {data.subtotal.change ? (
              <div className="my-1 flex justify-between">
                <p className="w-full">Kembalian</p>
                <p className="text-right w-full">
                  {data.subtotal.change.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            ) : null}

            <div className="text-center mt-4">
              <p>{data.detail_invoice.footer}</p>
              <p className="flex items-center justify-center">
                <FaWhatsapp className="mr-2" />
                {data.detail_invoice.phone}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default DetailOrder;
