import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { TiEyeOutline } from "react-icons/ti";
import { Close as CloseIcon } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import {
  createOperationalCost,
  getSavedOperationalNames,
} from "../../../../services/operationalCostApi";
import useDebounce from "../../../hooks/useDebounce";
import OperationalCostSavedName from "./OperationalCostSavedName";

const FETCH_LIMIT = 10;

const CreateOperationalCost = () => {
  const navigate = useNavigate();
  const [reqBody, setReqBody] = useState({
    operational_name: "",
    operational_date: null,
    operational_cost: 0,
    save_name: false,
  });
  const [operationalNameListPage, setOperationalNamePage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchOpName, setSearchOpName] = useState("");

  const debounce = useDebounce(searchOpName, 500);

  const { mutate: mutateCreateOperationalCost } = useMutation({
    mutationFn: createOperationalCost,
    onSuccess: () => navigate("/operational/list"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { data: savedOperationalNamesData } = useQuery({
    queryKey: ["op-name", debounce],
    queryFn: () => getSavedOperationalNames(FETCH_LIMIT, 1, debounce),
  });

  return (
    <div>
      {operationalNameListPage ? (
        <div>
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Operational Cost Name
          </h1>
          <div className="flex text-blue-500 gap-2 mb-3">
            <p
              className="cursor-pointer hover:underline"
              onClick={() => setOperationalNamePage(false)}
            >
              Create Operational Cost
            </p>
            <p>/</p>
            <p>Operational Cost Name List</p>
          </div>
        </div>
      ) : (
        <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
          Create Operational Cost
        </h1>
      )}
      {operationalNameListPage ? (
        <OperationalCostSavedName
          savedOperationalNamesData={savedOperationalNamesData}
          searchOpName={searchOpName}
          setSearchOpName={setSearchOpName}
          FETCH_LIMIT={FETCH_LIMIT}
        />
      ) : (
        <div>
          <div>
            <p className="font-bold">
              Operational Name <span className="text-[#F21B3F]">*</span>
            </p>
            <div
              className="w-full border border-black rounded-md cursor-pointer p-3 mt-2"
              onClick={() => setIsModalOpen(true)}
            >
              {reqBody.operational_name !== "" ? (
                reqBody.operational_name
              ) : (
                <p className="text-gray-400">choose operational name here</p>
              )}
            </div>
          </div>

          <div className="flex justify-between w-full">
            <div className="flex items-center text-blue-500">
              <p
                className="cursor-pointer hover:underline"
                onClick={() => setOperationalNamePage(true)}
              >
                Lihat Nama Operational yang disimpan
              </p>
              <TiEyeOutline
                className="ml-1.5 text-[20px] cursor-pointer hover:underline"
                onClick={() => setOperationalNamePage(true)}
              />
            </div>
            <div className="flex items-center my-2">
              <Checkbox
                checked={reqBody.save_name}
                onChange={() => {
                  setReqBody((prevValue) => ({
                    ...prevValue,
                    save_name: !prevValue.save_name,
                  }));
                }}
              />
              <p className="ml-2">Simpan Nama Operational</p>
            </div>
          </div>
          <div className="font-bold">
            <p>
              Period <span className="text-[#F21B3F]">*</span>
            </p>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                value={reqBody.operational_date}
                onChange={(selectedDate) => {
                  setReqBody((prevValue) => ({
                    ...prevValue,
                    operational_date: selectedDate,
                  }));
                }}
                renderInput={(params) => {
                  return (
                    <TextField {...params} fullWidth sx={{ marginTop: 2 }} />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="font-bold mt-3">
            <p>
              Cost <span className="text-[#F21B3F]">*</span>
            </p>
            <TextField
              sx={{ marginTop: 2 }}
              value={reqBody.operational_cost}
              onChange={(e) => {
                let numericValue = e.target.value;
                if (numericValue === "") {
                  numericValue = "0";
                }
                setReqBody((prevValue) => ({
                  ...prevValue,
                  operational_cost: parseInt(
                    numericValue.replace(/[^0-9]/g, ""),
                    10,
                  ),
                }));
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rp </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex justify-center mt-3">
            <button className="rounded-md border border-black w-full py-2">
              Cancel
            </button>
            <button
              disabled={
                reqBody.operational_cost < 1000 ||
                reqBody.operational_date === null ||
                reqBody.operational_name.length < 5
              }
              className="rounded-md border border-black w-full py-2 text-white font-bold bg-blue-500 ml-3 disabled:bg-gray-300"
              onClick={() =>
                mutateCreateOperationalCost({
                  ...reqBody,
                  operational_date: moment(reqBody.operational_date).add(
                    1,
                    "day",
                  ),
                })
              }
            >
              Save
            </button>
          </div>
          <Snackbar
            open={Boolean(errorMessage)}
            autoHideDuration={5000}
            onClose={() => {
              setErrorMessage("");
            }}
            message={errorMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setErrorMessage("")}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />
          <Dialog
            fullWidth
            maxWidth="md"
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <TextField
                value={searchOpName}
                onChange={(e) => setSearchOpName(e.target.value)}
                sx={{ width: "100%" }}
              />
              {savedOperationalNamesData &&
              savedOperationalNamesData !== undefined &&
              savedOperationalNamesData?.data !== null &&
              savedOperationalNamesData?.length !== 0 ? (
                savedOperationalNamesData?.data.map((data, index) => (
                  <div
                    key={index}
                    className="w-full border border-black p-3 flex mt-3 cursor-pointer"
                    onClick={() => {
                      setReqBody((prevValue) => ({
                        ...prevValue,
                        operational_name: data.operational_name,
                      }));
                      setIsModalOpen(false);
                    }}
                  >
                    <div>
                      <p className="font-bold">Nama Operational</p>
                      <p>{data.operational_name}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="w-full border border-black p-3 flex mt-3 cursor-pointer"
                  onClick={() => {
                    setReqBody((prevValue) => ({
                      ...prevValue,
                      operational_name: searchOpName,
                    }));
                    setIsModalOpen(false);
                  }}
                >
                  <div>
                    <p className="font-bold">Nama Operational</p>
                    <p>{searchOpName}</p>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      )}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  setErrorMessage("");
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default CreateOperationalCost;
