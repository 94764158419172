import { Button } from "@mui/material";
import { useState } from "react";

import ModalPaymentOption from "../../common/particles.jsx/ModalPaymentOption";

const MultiplePaymentButton = ({ arr, total, setPaymentOption }) => {
  const [openModalPayment, setOpenModalPayment] = useState(false);

  return (
    <>
      <table className="w-full bg-white">
        <tbody>
          <tr>
            <td className="border-2 w-[30%]">
              <p className="text-blue-500 font-bold text-center">{arr} Bill</p>
            </td>
            <td className="border-2 w-[40%] text-left px-4 py-1">
              <p>Jumlah Pembayaran</p>
              <p>
                {total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </td>
            <td className="border-2 w-[30%]">
              <p className="text-blue-500 font-bold text-center px-8 py-1">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenModalPayment(true)}
                >
                  Bayar Sekarang
                </Button>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalPaymentOption
        open={openModalPayment}
        setOpen={setOpenModalPayment}
        setPaymentOption={setPaymentOption}
      />
    </>
  );
};

export default MultiplePaymentButton;
