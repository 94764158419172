import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const fetchOrderList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}orders`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updateStatusOrder = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}update-order`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const updateReceiptNumber = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}/order/update-receipt`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getOrderDetail = async (invoice_number) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}order?invoice_number=${invoice_number}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const cancelOrder = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}cancel-order`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDoctorCopyRecipe = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}order/doctor-recipe-copy`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDrugData = async (search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}product-datas?search=${search}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getPrescriptionDoctor = async (recipe_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_PRODUCT}detail-recipe-doctor?recipe_id=${recipe_id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
