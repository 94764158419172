import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Pagination,
} from "@mui/material";
import moment from "moment";

import { getDetailSummaryInventory } from "../../../../services/InventoryAPI";
import BreadcrumbInventory from "../BreadcrumbInventory";

const toDate = (date) => moment(date).format("DD-MM-YYYY");

const FETCH_LIMIT = 10;

const InventorySummaryDetail = ({ isWarehouse }) => {
  const { productId = "", companyId = "" } = useParams();
  const navigate = useNavigate();

  const [offset, setOffset] = useState(1);

  const { data: summaryDetailData } = useQuery({
    queryKey: ["summary-detail", productId, companyId, offset],
    queryFn: () =>
      getDetailSummaryInventory(
        productId,
        companyId,
        FETCH_LIMIT,
        offset,
        isWarehouse ? isWarehouse : false
      ),
  });

  const pageCount = useMemo(() => {
    if (summaryDetailData && summaryDetailData !== undefined)
      return Math.ceil(summaryDetailData.count / FETCH_LIMIT);
    else return 0;
  }, [summaryDetailData]);

  return (
    <div>
      <BreadcrumbInventory isWarehouse={isWarehouse} />
      {summaryDetailData && summaryDetailData !== undefined ? (
        <div>
          <div className="flex justify-between w-full my-3 gap-3">
            <div className="w-full">
              <p>Product ID</p>
              <p>
                {summaryDetailData.data && summaryDetailData.data !== undefined
                  ? summaryDetailData.data[0].product_id
                  : null}
              </p>
              <div className="w-full border border-black"></div>
            </div>
            <div className="w-full">
              <p>Nama Produk</p>
              <p>
                {summaryDetailData.data && summaryDetailData.data !== undefined
                  ? summaryDetailData.data[0].product_name
                  : null}
              </p>
              <div className="w-full border border-black"></div>
            </div>
          </div>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Beginnning Stock
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock In
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Stock Out
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Final Stock
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Batch Number
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    HNA per satuan Primer
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Discount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    PPN
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Final Price per satuan Primer
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    Sales Price
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "bold" }}
                    align="center"
                  >
                    User Log
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryDetailData.data.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" sx={{ textWrap: "nowrap" }}>
                      {toDate(data.created_at)}
                    </TableCell>
                    <TableCell align="center">{data.stock_current}</TableCell>
                    <TableCell align="center">{data.stock_in}</TableCell>
                    <TableCell align="center">{data.stock_out}</TableCell>
                    {/*
<TableCell align="center">
                      {data.stock_in === 0
                        ? data.stock_current - data.stock_out
                        : data.stock_current + data.stock_in}
                    </TableCell>

                        */}{" "}
                    {/*data.package_detail !== null ? (
                      <TableCell align="center">
                        {IGNORE_TYPE.includes(data.package_detail.type_unit) ? (
                          <p>{data.package_detail.type_strip}</p>
                        ) : (
                          <p>{data.package_detail.type_unit}</p>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="center">Rp. 0</TableCell>
                    )*/}
                    <TableCell align="center">
                      {data.stock_current + data.stock_in - data.stock_out}
                    </TableCell>
                    <TableCell align="center">{data.number_batch}</TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_discount !== 0
                        ? data.pharmacy_net_price_discount + "%"
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_ppn ? "11%" : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {data.pharmacy_net_price_real.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {data.sell_price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </TableCell>
                    <TableCell align="center">{data.user_log}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}

      <div className="flex justify-center mt-3">
        <Pagination
          count={pageCount}
          page={offset}
          onChange={(_, value) => setOffset(value)}
          shape="rounded"
          size="large"
          color="primary"
        />
      </div>
    </div>
  );
};

export default InventorySummaryDetail;
