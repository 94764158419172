import { useState, useEffect, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { MdDateRange } from "react-icons/md";
import { FiBell } from "react-icons/fi";

import DatePicker from "react-datepicker";
import moment from "moment";

import { dates } from "../../constants/constant";
import { fetchDetailPayment } from "../../../services/dashboardApi";

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const DetailPaymentDashboard = () => {
  const name = localStorage.getItem("name");

  const [chosenDate, setChosenDate] = useState(1);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateName, setDateName] = useState("");

  const handleDate = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  const { mutate: mutateDetailPayment, data: detailPayment } = useMutation({
    mutationFn: fetchDetailPayment,
  });

  useEffect(() => {
    mutateDetailPayment({
      param: chosenDate,
      start_date: toDate(startDate) === "Invalid date" ? "" : toDate(startDate),
      end_date: toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
    });
  }, [chosenDate]);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex flex-col text-[14px] lg:text-base">
          <p className="font-semibold">
            Hi, <span className="font-semibold"> {name}</span>
          </p>
          <p>Welcome to OLIN dashboard</p>
        </div>
        <FiBell className="text-[18px] lg:text-[30px] hover:bg-sky-200 hover:rounded-full p-1" />
      </div>

      <div className="flex justify-end my-3">
        <div
          className="hover:cursor-pointer border border-black rounded-md flex items-center p-2 w-[20%] flex-wrap mx-3"
          onClick={(e) => setAnchorElDate(e.currentTarget)}
        >
          <MdDateRange className="mr-2 text-[30px]" />
          <p>{dateName === "" ? "Ubah Tanggal" : dateName}</p>
        </div>
      </div>
      {detailPayment && detailPayment !== undefined ? (
        <>
          <div className="flex w-full font-bold">
            <div className="border border-black rounded-md p-3 mr-3 w-full">
              <p className="mb-5">Pendapatan Kotor</p>
              <p>
                {detailPayment.gross_income.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <div className="border border-black rounded-md p-3 ml-3 w-full">
              <p className="mb-5">Total Transaksi</p>
              <p>{detailPayment.total_transaction}</p>
            </div>
          </div>
          <p className="mt-3">Metode Pembayaran</p>
          {detailPayment.payment_method &&
          detailPayment.payment_method !== undefined ? (
            detailPayment.payment_method.map((method, index) => (
              <div className="grid grid-cols-2 gap-3">
                <div
                  className="border border-black rounded-md p-3 mr-3 w-full flex"
                  key={index}
                >
                  <div className="w-[10%] flex justify-center items-center">
                    <img src={method.logo} className="mr-3" alt="logo" />
                  </div>
                  <div>
                    <p>{method.payment_mehod}</p>
                    <p className="font-bold">
                      {method.total_amount.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                    <p>{method.total_transaction} Transaksi</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex mt-7 justify-center w-full">
              <p>Metode pembayaran kosong</p>
            </div>
          )}
        </>
      ) : (
        <div className="flex justify-center h-screen w-full">
          <p>kosong</p>
        </div>
      )}
      <Menu
        anchorEl={anchorElDate}
        open={Boolean(anchorElDate)}
        onClose={() => setAnchorElDate(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {dates.map((date, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setDateName(date.name);
              setChosenDate(date.value);
              setAnchorElDate(null);
            }}
          >
            {date.name}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setAnchorElDate(null);
            setIsModalOpen(true);
            setChosenDate(0);
          }}
        >
          Pilih Tanggal
        </MenuItem>
      </Menu>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDate}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              mutateDetailPayment({
                param: chosenDate,
                start_date:
                  toDate(startDate) === "Invalid date" ? "" : toDate(startDate),
                end_date:
                  toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
              });
              setDateName(toDate(startDate) + " - " + toDate(endDate));
              setIsModalOpen(false);
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DetailPaymentDashboard;
