import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import { Search } from "@mui/icons-material";
import { MdOutlineFilterAlt } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useMutation } from "@tanstack/react-query";
import {
  getPatientCareDoctor,
  getPatientCareSpecialization,
} from "../../../../services/patientCare";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegEdit,
  FaRegTrashAlt,
  FaUser,
} from "react-icons/fa";
import { RxFilePlus } from "react-icons/rx";
import ModalDeleteDoctor from "./ModalDeleteDoctor";
import ModalAddEditDoctor from "./ModalAddEditDoctor";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const PatientCareDoctorList = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [spec, setSpec] = useState(null);
  const [searchSpec, setSearchSpec] = useState("");
  const [exportArr, setExportArr] = useState([]);

  const debounceSearch = useDebounce(search, 500);
  const debounceSpec = useDebounce(searchSpec, 500);
  const openFilter = Boolean(anchorElFilter);

  const handleCheck = (doctor) => {
    const i = exportArr.findIndex((item) => item === doctor.id);
    setExportArr(
      i < 0
        ? [...exportArr, doctor.id]
        : [...exportArr.slice(0, i), ...exportArr.slice(i + 1)]
    );
  };

  const refetchList = () => {
    setSelectedDoctor(null);
    setSearch("");
    setLimit(10);
    setOffset(1);
    setSpec(null);

    mutateList({
      search: "",
      limit: 10,
      offset: 1,
      specialization_id: 0,
    });
  };

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getPatientCareDoctor,
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  const {
    mutate: mutateSpecialization,
    isLoading: isLoadingSpecialization,
    data: dataSpecialization,
  } = useMutation({
    mutationFn: getPatientCareSpecialization,
  });

  useEffect(() => {
    mutateSpecialization({
      search: debounceSpec,
      limit: 10,
      offset: 1,
    });
  }, [debounceSpec]);

  useEffect(() => {
    setSelectedDoctor(null);
    mutateList({
      search: debounceSearch,
      limit: limit,
      offset: offset,
      specialization_id: spec ? spec : 0,
    });
  }, [debounceSearch, limit, offset, spec]);

  useEffect(() => {
    if (!openAdd) setSelectedDoctor(null);
  }, [openAdd]);

  return (
    <>
      <BreadcrumbPatientCare />
      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 12 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center w-12 h-12 cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-full h-full" />
        </div>
        <div className="rounded-md p-2 border-2 border-blue-500 text-blue-500 px-4 h-12 flex gap-2 justify-center items-center cursor-pointer hover:bg-gray-300">
          <RxFilePlus className="w-8 h-8" />
          Export
        </div>
        <div
          className="rounded-md p-2 flex justify-center items-center w-12 h-12 cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() => {
            setOpenAdd(true);
            setSelectedDoctor(null);
            setIsEdit(true);
          }}
        >
          <IoMdAddCircleOutline className="w-full h-full" />
        </div>
      </div>
      <p className="text-gray-500 text-xs mb-4">
        You can search by phone number or name
      </p>

      {dataList && !isLoading ? (
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={
                dataList.doctor && dataList.doctor.length > 0
                  ? exportArr.length === dataList.doctor.length
                  : false
              }
              onChange={(_, checked) =>
                setExportArr(
                  checked
                    ? dataList && dataList.doctor
                      ? dataList.doctor.map((item) => item.id)
                      : []
                    : []
                )
              }
            />
            <p>Select All</p>
          </div>
          <p>{dataList.count} Doctors found</p>
        </div>
      ) : null}

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full">
            <table className="w-full">
              <thead className="bg-blue-500 text-white">
                <th className="p-2" colSpan={2}>
                  Doctor Name
                </th>
                <th className="p-2 text-center">Email</th>
                <th className="p-2 text-center">Phone Number</th>
                <th className="py-2 px-24 text-center">Address</th>
                <th className="p-2 text-center">No. SIP</th>
                <th className="p-2 text-center">No. KTP</th>
                <th className="p-2 text-center">Specialization</th>
                <th className="p-2 text-center">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.count > 0 ? (
                  dataList.doctor.map((item, i) => {
                    return (
                      <tr className="border-collapse border border-gray-500">
                        <td className="p-2 text-center border border-r-none border-gray-500">
                          <Checkbox
                            onChange={() => handleCheck(item)}
                            checked={exportArr.includes(item.id)}
                          />
                        </td>
                        <td className="p-2 border border-l-border-gray-500">
                          <div className="flex gap-2 items-center ">
                            <div
                              className={`w-10 h-10 rounded-sm bg-gray-300 ${
                                item.image === "" ? "p-2" : ""
                              }`}
                            >
                              {item.image === "" ? (
                                <FaUser className="w-full h-full text-white" />
                              ) : (
                                <img
                                  src={item.image}
                                  className="w-full h-full rounded-sm object-cover"
                                />
                              )}
                            </div>
                            <p
                              className="text-blue-500 flex-1 whitespace-nowrap text-nowrap cursor-pointer"
                              onClick={() => {
                                setIsEdit(false);
                                setSelectedDoctor(item);
                                setOpenAdd(true);
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.email === "" ? "-" : item.email}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.phone === "" ? "-" : item.phone}
                        </td>
                        <td className="p-2 text-center border border-gray-500 ">
                          {item.address === "" ? "-" : item.address}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.sip === "" ? "-" : item.sip}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.ktp === "" ? "-" : item.ktp}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.specialization === ""
                            ? "-"
                            : item.specialization}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          <div className="flex items-center gap-2 justify-center">
                            <FaRegTrashAlt
                              className="text-red-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setSelectedDoctor(item);
                                setOpenDelete(true);
                              }}
                            />
                            <FaRegEdit
                              className="text-blue-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setOpenAdd(true);
                                setSelectedDoctor(item);
                                setIsEdit(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td
                      colSpan={9}
                      className="text-center p-2 border border-gray-500"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={9}
                      className="text-center text-gray-300 p-2 border border-gray-500"
                    >
                      Data doctor not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={offset}
          setOffset={(e) => {
            setOffset(e);
          }}
          limit={limit}
          setLimit={(e) => {
            setLimit(e);
          }}
          total={dataList.count}
        />
      ) : null}

      <Popover
        open={openFilter}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-full min-w-[30vw]">
          <p className="px-4 py-2 font-bold w-full text-center">
            Specialization
          </p>
          <TextField
            fullWidth
            placeholder="Find Specialization"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ paddingX: 2, marginBottom: 1 }}
            value={searchSpec}
            onChange={(e) => setSearchSpec(e.target.value)}
          />
          <hr />
          {dataSpecialization &&
          !isLoadingSpecialization &&
          dataSpecialization.count > 0 ? (
            dataSpecialization.specialization.map((item) => {
              return (
                <div
                  className={`py-2 px-4 border-b-2 cursor-pointer hover:bg-gray-100 ${
                    spec === item.id ? "bg-gray-100" : ""
                  }`}
                  onClick={() => setSpec(spec === item.id ? 0 : item.id)}
                >
                  {item.name}
                </div>
              );
            })
          ) : isLoadingSpecialization ? (
            <div className="p-2 flex justify-center items-center">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="p-2 flex justify-center items-center">
              No specialization found
            </div>
          )}
        </div>
      </Popover>

      <ModalDeleteDoctor
        open={openDelete}
        setOpen={setOpenDelete}
        doctor={selectedDoctor}
        refetch={() => refetchList()}
      />

      <ModalAddEditDoctor
        open={openAdd}
        setOpen={setOpenAdd}
        isEdit={isEdit}
        doctor={selectedDoctor}
        refetch={() => refetchList()}
      />
    </>
  );
};

export default PatientCareDoctorList;
