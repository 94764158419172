import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import BreadcrumbPatientCare from "../BreadcrumbPatientCare";
import { Search } from "@mui/icons-material";
import { MdOutlineFilterAlt } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useMutation } from "@tanstack/react-query";
import {
  getPatientCareCategory,
  getPatientCareServicesList,
} from "../../../../services/patientCare";
import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegEdit,
  FaRegTrashAlt,
  FaUser,
} from "react-icons/fa";
import ModalDeleteServices from "./ModalDeleteServices";
import ModalDetailServices from "./ModalDetailServices";
import ModalAddServices from "./ModalAddServices";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const PatientCareServicesList = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [range, setRange] = useState(1000000);
  const [category, setCategory] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");

  const debounceSearch = useDebounce(search, 500);
  const debounceRange = useDebounce(range, 500);
  const debounceCategory = useDebounce(searchCategory, 500);
  const openFilter = Boolean(anchorElFilter);
  const openCategory = Boolean(anchorElCategory);

  const refetchList = () => {
    setSelectedService(null);
    setSearch("");
    setLimit(10);
    setOffset(1);
    setRange(1000000);
    setCategory(0);

    mutateList({
      search: "",
      category_id: 0,
      range: 1000000,
      limit: 10,
      offset: 1,
    });
  };

  const {
    mutate: mutateCategoryList,
    data: dataCategory,
    isLoading: isLoadingCategory,
  } = useMutation({
    mutationFn: getPatientCareCategory,
  });

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getPatientCareServicesList,
    onError: (err) =>
      setErrorMessage(err.message.id ? err.message.id : err.message),
  });

  useEffect(() => {
    mutateCategoryList({
      search: debounceCategory,
      limit: 10,
      offset: 1,
    });
  }, [debounceCategory]);

  useEffect(() => {
    setSelectedService(null);
    mutateList({
      search: debounceSearch,
      category_id: category,
      range: debounceRange,
      limit: limit,
      offset: offset,
    });
  }, [debounceSearch, limit, offset, debounceRange, category]);
  return (
    <>
      <BreadcrumbPatientCare />
      <div className="flex items-center gap-2 mb-2">
        <TextField
          fullWidth
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ style: { padding: 12 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="rounded-md p-2 border-2 flex justify-center items-center w-12 h-12 cursor-pointer hover:bg-gray-300"
          onClick={(e) => setAnchorElFilter(e.currentTarget)}
        >
          <MdOutlineFilterAlt className="w-full h-full" />
        </div>
        <div
          className="rounded-md p-2 flex justify-center items-center w-12 h-12 cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
          onClick={() => setOpenAdd(true)}
        >
          <IoMdAddCircleOutline className="w-full h-full" />
        </div>
      </div>
      <p className="text-gray-500 text-xs mb-4">
        You can search by service or name
      </p>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full">
            <table className="w-full">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2">Doctor Name</th>
                <th className="p-2 text-center">Services Name</th>
                <th className="p-2 text-center">Category</th>
                <th className="p-2 text-center">Service Fee</th>
                <th className="p-2 text-center">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.count > 0 ? (
                  dataList.service.map((item, i) => {
                    return (
                      <tr className="border-collapse border border-gray-500">
                        <td className="p-2 text-center border border-gray-500">
                          {i + 1}
                        </td>
                        <td className="p-2 border border-gray-500">
                          <div className="flex gap-2 items-center ">
                            <div
                              className={`w-10 h-10 rounded-sm bg-gray-300 ${
                                item.image === "" ? "p-2" : ""
                              }`}
                            >
                              {item.image === "" ? (
                                <FaUser className="w-full h-full text-white" />
                              ) : (
                                <img
                                  src={item.image}
                                  className="w-full h-full rounded-sm object-cover"
                                />
                              )}
                            </div>
                            <p> {item.doctor} </p>
                          </div>
                        </td>
                        <td
                          className="p-2 text-center text-blue-500 border border-gray-500 cursor-pointer hover:text-gray-300"
                          onClick={() => {
                            setSelectedService(item);
                            setOpenDetail(true);
                            setIsEdit(false);
                          }}
                        >
                          {item.name}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          {item.category}
                        </td>
                        <td className="p-2 text-center text-[#FF8D40] border border-gray-500">
                          {item.fee.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="p-2 text-center border border-gray-500">
                          <div className="flex items-center gap-2 justify-center">
                            <FaRegTrashAlt
                              className="text-red-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setSelectedService(item);
                                setOpenDelete(true);
                              }}
                            />
                            <FaRegEdit
                              className="text-blue-500 cursor-pointer hover:text-gray-300"
                              onClick={() => {
                                setSelectedService(item);
                                setOpenDetail(true);
                                setIsEdit(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center p-2 border border-gray-500"
                    >
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center text-gray-300 p-2 border border-gray-500"
                    >
                      Data services not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.count > 0 ? (
        <PagePagination
          offset={offset}
          setOffset={(e) => {
            setOffset(e);
          }}
          limit={limit}
          setLimit={(e) => {
            setLimit(e);
          }}
          total={dataList.count}
        />
      ) : null}

      <Popover
        open={openCategory}
        anchorEl={anchorElCategory}
        onClose={() => setAnchorElCategory(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-full min-w-[30vw]">
          <p className="px-4 py-2 font-bold w-full text-center">Category</p>
          <TextField
            fullWidth
            placeholder="Find Category"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ paddingX: 2, marginBottom: 1 }}
            value={searchCategory}
            onChange={(e) => setSearchCategory(e.target.value)}
          />
          <hr />
          {dataCategory && !isLoadingCategory && dataCategory.count > 0 ? (
            dataCategory.category.map((item) => {
              return (
                <div
                  className={`p-2 border-b-2 cursor-pointer hover:bg-gray-100 ${
                    category === item.id ? "bg-gray-100" : ""
                  }`}
                  onClick={() =>
                    setCategory(category === item.id ? 0 : item.id)
                  }
                >
                  {item.name}
                </div>
              );
            })
          ) : isLoading ? (
            <div className="p-2 flex justify-center items-center">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="p-2 flex justify-center items-center">
              No Category Found
            </div>
          )}
        </div>
      </Popover>

      <Popover
        open={openFilter}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-full min-w-[30vw] ">
          <p className="w-full font-bold px-4 pt-4">Filter</p>
          <hr className="mt-4" />
          <div
            className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-300"
            onClick={(e) => setAnchorElCategory(e.currentTarget)}
          >
            <p className="font-bold">Category</p>
            <FaChevronRight />
          </div>
          <hr />
          <p className="font-bold p-4 ">Price Range</p>
          <div className="px-4">
            <Slider
              min={0}
              max={1000000}
              value={range}
              onChange={(e) => setRange(e.target.value)}
              valueLabelDisplay="auto"
              fullWidth
            />
            <div className="flex justify-between items-center text-xs mb-4">
              <p>Rp0</p>
              <p>Rp1.000.000</p>
            </div>
          </div>
        </div>
      </Popover>

      <ModalAddServices
        open={openAdd}
        setOpen={setOpenAdd}
        refetch={() => refetchList()}
      />

      <ModalDeleteServices
        open={openDelete}
        setOpen={setOpenDelete}
        service={selectedService}
        refetch={() => refetchList()}
      />

      <ModalDetailServices
        open={openDetail}
        setOpen={setOpenDetail}
        service={selectedService}
        refetch={() => refetchList()}
        isEditService={isEdit}
      />
    </>
  );
};

export default PatientCareServicesList;
