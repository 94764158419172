import { IconButton, Snackbar } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const ErrorPopup = ({ errorMessage, setErrorMessage }) => {
  return (
    <Snackbar
      open={Boolean(errorMessage)}
      autoHideDuration={5000}
      onClose={() => setErrorMessage("")}
      message={errorMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={(_, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setErrorMessage("");
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default ErrorPopup;
