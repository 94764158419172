import React from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const Breadcumbs = (props) => {
  if (props.nama3 === undefined) {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`${props.link1}`}>{props.nama1}</Link>
          <Link to={`${props.link2}`}>{props.nama2}</Link>
        </Breadcrumbs>
      </div>
    );
  } else {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`${props.link1}`}>
            <p
              className={`${
                props.blueText
                  ? "text-blue-500 hover:underline hover:text-blue-700"
                  : ""
              }`}
            >
              {props.nama1}
            </p>
          </Link>
          <Link to={`${props.link2}`}>
            <p
              className={`${
                props.blueText
                  ? "text-blue-500 hover:underline hover:text-blue-700"
                  : ""
              }`}
            >
              {props.nama2}
            </p>
          </Link>
          <Link to={`${props.link3}`} aria-current="page">
            <p className={`${props.blueText ? "text-blue-500" : ""}`}>
              {props.nama3}
            </p>
          </Link>
        </Breadcrumbs>
      </div>
    );
  }
};

export default Breadcumbs;
