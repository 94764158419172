import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";

const BasedOnSearch = () => {
  const navigate = useNavigate();
  const {
    isProduct,
    setIsProduct,
    dataBufferProductSearched,
    setDataBufferProductSearched,
    productBasedOnSearch,
    setIsLoading,
    isLoading,
    mutateAddToCart,
  } = useContext(SelectedProductContext);

  useEffect(() => {
    if (productBasedOnSearch) {
      setDataBufferProductSearched(
        productBasedOnSearch.product?.map((data) => ({
          id: data.id,
          image: data.image,
          nama_produk: data.nama_produk,
          price: data.price,
          quantity: data.quantity,
          isVisible: data.quantity !== 0,
          isLoading: false,
        }))
      );
    }
  }, [productBasedOnSearch]);

  return (
    <div className="w-full mt-5">
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-[20px]">Berdasarkan Pencarianmu</h1>
        <p
          className="text-blue-500 hover:underline cursor-pointer"
          onClick={() =>
            setIsProduct({ ...isProduct, userSearchSelected: true })
          }
        >
          Lihat semua
        </p>
      </div>
      <div className="flex justify-center grid grid-cols-4 gap-3 w-full">
        {dataBufferProductSearched && dataBufferProductSearched !== undefined
          ? dataBufferProductSearched.map((list, index) => (
              <div
                className="p-3 bg-white rounded-lg shadow-md w-full h-full flex flex-wrap content-between"
                key={index}
              >
                <div>
                  <img
                    src={list.image !== null ? list.image : ""}
                    alt=""
                    onClick={() => navigate(`/purchase/detail/${list.id}`)}
                    className="rounded-lg object-cover hover:cursor-pointer"
                  />
                  <div className="mt-3 text-[12px] lg:text-[15px]">
                    <h1 className="text-gray-700 font-bold ">
                      {list.nama_produk}
                    </h1>
                    <p className="text-gray-500 mt-2">{list.uom}</p>
                    <h1 className="text-gray-700 font-bold">
                      {list.price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </h1>
                    {/* <div className="flex gap-2 items-center mt-1">
                      <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                        15%
                      </p>
                      <p className="line-through">Rp. {productPrice}</p>
                    </div> */}
                  </div>
                </div>
                <>
                  {list.isVisible ? (
                    <div className="w-full mt-3">
                      <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                        {list.isLoading ? (
                          <div className="flex justify-center w-full">
                            <p>Loading</p>
                          </div>
                        ) : (
                          <>
                            <button
                              className="bg-[#FF7A5D] px-3 rounded-lg"
                              disabled={isLoading}
                              onClick={() => {
                                if (list.quantity === 1) {
                                  setDataBufferProductSearched((prevValue) =>
                                    prevValue.map((data) => {
                                      return data.id === list.id
                                        ? {
                                            ...list,
                                            isVisible: false,
                                            isLoading: true,
                                            quantity: data.quantity - 1,
                                          }
                                        : data;
                                    })
                                  );
                                }
                                setDataBufferProductSearched((prevValue) =>
                                  prevValue.map((data) => {
                                    return data.id === list.id
                                      ? {
                                          ...data,
                                          isLoading: true,
                                          quantity: data.quantity - 1,
                                        }
                                      : data;
                                  })
                                );
                                setIsLoading(true);
                                mutateAddToCart({
                                  price: list.price,
                                  product_id: list.id,
                                  quantity: list.quantity - 1,
                                  flag: false,
                                  type: 1,
                                  param: 1,
                                });
                              }}
                            >
                              -
                            </button>
                            {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBufferMostSearched[index]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBufferMostSearched(
                                      dataBufferMostSearched.map((list) => {
                                        return list.id === productId
                                          ? {
                                              ...list,
                                              quantity: 0,
                                            }
                                          : list;
                                      })
                                    );
                                  setDataBufferMostSearched(
                                    dataBufferMostSearched.map((list) => {
                                      return list.id === productId
                                        ? {
                                            ...list,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0
                                              ),
                                              10
                                            ),
                                          }
                                        : list;
                                    })
                                  );
                                }}
                              /> */}
                            <p>{list.quantity}</p>
                            <button
                              className="bg-[#5DFFBB] px-3 rounded-lg"
                              disabled={isLoading}
                              onClick={() => {
                                setDataBufferProductSearched((prevValue) =>
                                  prevValue.map((data) => {
                                    return data.id === list.id
                                      ? {
                                          ...data,
                                          isLoading: true,
                                          quantity: data.quantity + 1,
                                        }
                                      : data;
                                  })
                                );
                                setIsLoading(true);
                                mutateAddToCart({
                                  price: list.price,
                                  product_id: list.id,
                                  quantity: list.quantity + 1,
                                  flag: false,
                                  type: 1,
                                  param: 1,
                                });
                              }}
                            >
                              +
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 text-[12px] lg:text-base w-full">
                      <button
                        className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                        disabled={isLoading}
                        onClick={() => {
                          setDataBufferProductSearched((prevValue) =>
                            prevValue.map((data) => {
                              return data.id === list.id
                                ? {
                                    ...data,
                                    isVisible: true,
                                    isLoading: true,
                                    quantity: 1,
                                  }
                                : data;
                            })
                          );
                          setIsLoading(true);
                          mutateAddToCart({
                            price: list.price,
                            product_id: list.id,
                            quantity: 1,
                            flag: false,
                            type: 1,
                            param: 1,
                          });
                        }}
                      >
                        <AiOutlineShoppingCart />
                        Add to cart
                      </button>
                    </div>
                  )}
                </>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default BasedOnSearch;
