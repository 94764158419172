import React, { useState, useEffect, useContext, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BsCreditCard2Back } from "react-icons/bs";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoMdTrash } from "react-icons/io";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Snackbar,
  IconButton,
} from "@mui/material";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import {
  addToCartPurchase,
  getCartDetails,
  getVoucher,
  checkallCartProduct,
  getCountCart,
  deleteCart,
} from "../../../../../services/purchaseApi";
import useDebounce from "../../../../hooks/useDebounce";
import VoucherPurchaseCart from "./VoucherPurchaseCart";
import VoucherPurchaseDetail from "./VoucherPurchaseDetail";
import { VoucherProductContext } from "../../../../context/VoucherProductProvider";
const FETCH_LIMIT = 30;

const CartDetails = () => {
  const { selectedVoucher, setSelectedVoucher } = useContext(
    VoucherProductContext
  );
  //const [selectedVoucher, setSelectedVoucher] = useState([]);
  const queryClient = useQueryClient();
  const [dataBufferReguler, setDataBufferReguler] = useState([]);
  const [dataBufferSo, setDataBufferSo] = useState([]);
  const [searchVoucher, setSearchVoucher] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [offset, setOffset] = useState(1);
  const [isOpenAddVoucher, setIsOpenAddVoucher] = useState(false);
  const [isDetailVoucherOpen, setIsDetailVoucherOpen] = useState(false);
  const [voucherId, setVoucherId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const debounce = useDebounce(searchVoucher, 500);
  const navigate = useNavigate();

  const { data: cartDetailData, isLoading: isCartLoading } = useQuery({
    queryKey: ["cart-detail"],
    queryFn: () => getCartDetails(1),
    onSuccess: (data) => {
      setDataBufferSo(
        data.cart_so !== null
          ? data.cart_so.map((data) => ({
              product_id: data.product_id,
              product_so_id: data.product_so_id,
              image: data.image,
              product_name: data.product_name,
              uom: data.uom,
              discount: data.discount,
              check: data.check,
              price_discount: data.price_discount,
              price: data.price,
              quantity: data.quantity,
              isLoading: false,
            }))
          : []
      );
      setDataBufferReguler(
        data.cart_regular !== null
          ? data.cart_regular.map((data) => ({
              product_id: data.product_id,
              image: data.image,
              product_name: data.product_name,
              uom: data.uom,
              check: data.check,
              price: data.price,
              quantity: data.quantity,
              isLoading: false,
            }))
          : []
      );
    },
  });

  const isCheckoutButtonDisabled = useMemo(() => {
    return (
      dataBufferReguler.every((item) => !item.check) &&
      dataBufferSo.every((item) => !item.check)
    );
  }, [dataBufferReguler, dataBufferSo]);

  const { data: voucherPurchaseData } = useQuery({
    queryFn: () => getVoucher(debounce, FETCH_LIMIT, offset),
    queryKey: ["voucher-purchase", debounce],
  });

  const { data: countCart } = useQuery({
    queryFn: () => getCountCart(2),
    queryKey: ["count-cart"],
    onSuccess: (data) => {
      setIsChecked(data.checkall);
    },
  });

  const { mutate: deleteCartItem } = useMutation({
    mutationFn: deleteCart,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cart-detail"],
      });
      queryClient.invalidateQueries({
        queryKey: ["count-cart"],
      });

      setIsDeleteProductModalOpen(false);
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateAddToCart, isLoading: isAddToCartLoading } =
    useMutation({
      mutationFn: addToCartPurchase,
      onSuccess: () => {
        setIsLoading(false);
        setIsDeleteProductModalOpen(false);
        queryClient.invalidateQueries({
          queryKey: ["count-cart"],
        });
        queryClient.invalidateQueries({
          queryKey: ["cart-detail"],
        });
      },
    });

  const {
    mutate: mutateChecklist,
    data: checklistData,
    isLoading: isCheckListLoading,
  } = useMutation({
    mutationFn: checkallCartProduct,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["count-cart"] });
      queryClient.invalidateQueries({ queryKey: ["cart-detail"] });
      setIsChecked(data.checkall);
    },
  });

  const totalDiscount = useMemo(() => {
    return selectedVoucher.voucherPrice.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }, [selectedVoucher.voucherPrice]);

  // const handleSelectList = useCallback(
  //   (selectedId) => {
  //     const selectedValue = productIndex.findIndex((id) => id === selectedId);
  //     let updatedList = cloneDeep(productIndex);

  //     if (selectedValue !== -1) {
  //       updatedList.splice(selectedValue, 1);
  //     } else {
  //       updatedList.push(selectedId);
  //     }
  //     setProductIndex(updatedList);
  //   },
  //   [productIndex]
  // );

  useEffect(() => {
    sessionStorage.setItem("selectedVoucher", selectedVoucher.voucherId);
  }, [selectedVoucher]);

  return (
    <div>
      {isCartLoading ? (
        <div className="h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="flex text-[#007AF1] font-bold my-3 p-3 gap-2">
            <p
              className="hover:underline hover:cursor-pointer"
              onClick={() => navigate("/purchase")}
            >
              Purchase
            </p>
            <p>/</p>
            <p className="hover:underline hover:cursor-pointer">Cart</p>
          </div>
          <div className="w-full">
            <div className="flex justify-between items-center">
              <p className="text-[36px] font-bold">Keranjang</p>
              {isCheckoutButtonDisabled ? null : (
                <button
                  onClick={() => {
                    if (isChecked) {
                      setDeleteMessage(
                        "Apakah anda yakin ingin menghapus semua produk?"
                      );
                    } else if (
                      dataBufferReguler.filter((data) => data.check).length >=
                        1 ||
                      dataBufferSo.filter((data) => data.check).length >= 1
                    ) {
                      setDeleteMessage(
                        `Apakah anda yakin ingin menghapus ${
                          dataBufferReguler.filter((data) => data.check)
                            .length +
                          dataBufferSo.filter((data) => data.check).length
                        } produk?`
                      );
                    }
                    setIsDeleteProductModalOpen(true);
                  }}
                >
                  <IoMdTrash className="text-[30px]" />
                </button>
              )}
            </div>
            <div className="border-2 border-black"></div>
            <div className="flex items-center justify-between mt-3">
              <div>
                <input
                  id="allSelect"
                  type="checkbox"
                  name="allSelect"
                  disabled={isCheckListLoading || isAddToCartLoading}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2 "
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) mutateChecklist(false);
                    else mutateChecklist(true);
                  }}
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  Pilih Semua
                </label>
              </div>
              <div></div>
            </div>
          </div>
          {dataBufferReguler && dataBufferReguler !== undefined
            ? dataBufferReguler
                ?.filter((data) => data.quantity > 0)
                .map((data, index) => (
                  <div
                    className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between"
                    key={index}
                  >
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2 "
                        checked={data.check}
                        disabled={
                          isCheckListLoading || isAddToCartLoading || isLoading
                        }
                        onChange={() => {
                          setDataBufferReguler((prevValue) =>
                            prevValue?.map((item) => {
                              return data.product_id === item.product_id
                                ? {
                                    ...data,
                                    check: !item.check,
                                  }
                                : item;
                            })
                          );
                          if (data.check) {
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              quantity: data.quantity,
                              flag: false,
                              type: 1,
                              param: 1,
                              check: false,
                            });
                          } else {
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              quantity: data.quantity,
                              flag: false,
                              type: 1,
                              param: 1,
                              check: true,
                            });
                          }
                        }}
                      />
                      <img
                        src={data.image[0]}
                        alt="foto_product"
                        className="w-20 border rounded-lg mx-2 hover:cursor-pointer"
                        onClick={() =>
                          navigate(`/purchase/detail/${data.product_id}`)
                        }
                      />
                      <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden">
                        <p>{data.product_name}</p>
                        <p>{data.uom}</p>
                        <p className="font-bold">
                          {data.price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          setDataBufferReguler((prevValue) =>
                            prevValue?.map((item) => {
                              return data.product_id === item.product_id
                                ? {
                                    ...data,
                                    quantity: 0,
                                    isLoading: true,
                                  }
                                : item;
                            })
                          );
                          setIsLoading(true);
                          mutateAddToCart({
                            price: data.price,
                            product_id: data.product_id,
                            quantity: 0,
                            check: data.check,
                            flag: false,
                            type: 1,
                            param: 1,
                          });
                        }}
                      >
                        <IoMdTrash className="text-[30px]" />
                      </button>
                      <div className="flex">
                        <button
                          className="bg-[#FF7A5D] px-3 rounded-lg"
                          disabled={isLoading}
                          onClick={() => {
                            if (data.quantity === 1) {
                              setDataBufferReguler((prevValue) =>
                                prevValue?.map((item) => {
                                  return data.product_id === item.product_id
                                    ? {
                                        ...data,
                                        quantity: 0,
                                        isLoading: true,
                                      }
                                    : item;
                                })
                              );
                            } else {
                              setDataBufferReguler((prevValue) =>
                                prevValue?.map((item) => {
                                  return data.product_id === item.product_id
                                    ? {
                                        ...data,
                                        quantity: item.quantity - 1,
                                        isLoading: true,
                                      }
                                    : item;
                                })
                              );
                            }
                            setIsLoading(true);
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              quantity: data.quantity - 1,
                              check: data.check,
                              flag: false,
                              type: 1,
                              param: 1,
                            });
                          }}
                        >
                          -
                        </button>
                        <p className="w-[30px] text-center">{data.quantity}</p>
                        <button
                          className="bg-[#5DFFBB] px-3 rounded-lg"
                          disabled={isLoading}
                          onClick={() => {
                            setDataBufferReguler((prevValue) =>
                              prevValue?.map((item) => {
                                return data.product_id === item.product_id
                                  ? {
                                      ...data,
                                      quantity: item.quantity + 1,
                                      isLoading: true,
                                    }
                                  : item;
                              })
                            );
                            setIsLoading(true);
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              quantity: data.quantity + 1,
                              check: data.check,
                              flag: false,
                              type: 1,
                              param: 1,
                            });
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            : null}
          {dataBufferSo && dataBufferSo !== undefined
            ? dataBufferSo
                ?.filter((data) => data.quantity > 0)
                .map((data, index) => (
                  <div
                    className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between"
                    key={index}
                  >
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2 "
                        checked={data.check}
                        disabled={
                          isCheckListLoading || isAddToCartLoading || isLoading
                        }
                        onChange={() => {
                          setDataBufferSo((prevValue) =>
                            prevValue?.map((item) => {
                              return data.product_id === item.product_id
                                ? {
                                    ...data,
                                    check: !item.check,
                                  }
                                : item;
                            })
                          );
                          if (data.check)
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              product_so_id: data.product_so_id,
                              quantity: data.quantity,
                              flag: false,
                              type: 2,
                              param: 1,
                              check: false,
                            });
                          else
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              product_so_id: data.product_so_id,
                              quantity: data.quantity,
                              flag: false,
                              type: 2,
                              param: 1,
                              check: true,
                            });
                        }}
                      />
                      <img
                        src={data.image[0]}
                        alt="foto_product"
                        className="w-20 border rounded-lg mx-2 hover:cursor-pointer"
                        onClick={() =>
                          navigate(`/purchase/detail/${data.product_id}`)
                        }
                      />
                      <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden">
                        <p>{data.product_name}</p>
                        <p>{data.uom}</p>
                        <div className="flex gap-2 items-center mt-1">
                          <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                            {data.discount}%
                          </p>
                          <p className="line-through">
                            {data.price?.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          </p>
                        </div>
                        <p className="font-bold">
                          {data.price_discount?.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          setDataBufferSo((prevValue) =>
                            prevValue?.map((item) => {
                              return data.product_id === item.product_id
                                ? {
                                    ...data,
                                    quantity: 0,
                                    isLoading: true,
                                  }
                                : item;
                            })
                          );
                          setIsLoading(true);
                          mutateAddToCart({
                            price: data.price,
                            product_id: data.product_id,
                            product_so_id: data.product_so_id,
                            quantity: 0,
                            check: data.check,
                            flag: false,
                            type: 2,
                            param: 1,
                          });
                        }}
                      >
                        <IoMdTrash className="text-[30px]" />
                      </button>
                      <div className="flex">
                        <button
                          className="bg-[#FF7A5D] px-3 rounded-lg"
                          onClick={() => {
                            if (data.quantity === 1) {
                              setDataBufferSo((prevValue) =>
                                prevValue?.map((item) => {
                                  return data.product_id === item.product_id
                                    ? {
                                        ...data,
                                        quantity: 0,
                                        isLoading: true,
                                      }
                                    : item;
                                })
                              );
                            } else {
                              setDataBufferSo((prevValue) =>
                                prevValue?.map((item) => {
                                  return data.product_id === item.product_id
                                    ? {
                                        ...data,
                                        quantity: item.quantity - 1,
                                        isLoading: true,
                                      }
                                    : item;
                                })
                              );
                            }
                            setIsLoading(true);
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              product_so_id: data.product_so_id,
                              quantity: data.quantity - 1,
                              check: data.check,
                              flag: false,
                              type: 2,
                              param: 1,
                            });
                          }}
                        >
                          -
                        </button>
                        <p className="w-[30px] text-center">{data.quantity}</p>
                        <button
                          className="bg-[#5DFFBB] px-3 rounded-lg"
                          onClick={() => {
                            setDataBufferSo((prevValue) =>
                              prevValue?.map((item) => {
                                return data.product_id === item.product_id
                                  ? {
                                      ...data,
                                      quantity: item.quantity + 1,
                                      isLoading: true,
                                    }
                                  : item;
                              })
                            );
                            setIsLoading(true);
                            mutateAddToCart({
                              price: data.price,
                              product_id: data.product_id,
                              product_so_id: data.product_so_id,
                              quantity: data.quantity + 1,
                              check: data.check,
                              flag: false,
                              type: 2,
                              param: 1,
                            });
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            : null}
        </div>
      )}
      <div className="flex justify-center w-full mt-40">
        <div className="fixed bottom-[65px] z-20 bg-white p-3 rounded-md w-[78%] h-[10%] shadow-md">
          <div className="flex justify-between items-center font-medium h-full">
            <div className="flex items-center h-full">
              <BsCreditCard2Back className="text-[25px] mr-3" />
              {selectedVoucher.voucherId.length !== 0 ? (
                <p>{selectedVoucher.voucherId.length} Voucher Terpakai</p>
              ) : (
                <p>Pakai Voucher / Kode Promo</p>
              )}
            </div>

            <button onClick={() => setIsOpenAddVoucher(true)}>
              <ArrowForwardIosIcon sx={{ color: "#007AF1" }} />
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <div className="fixed bottom-0 z-20 bg-blue-500 p-3 rounded-md w-[78%] h-[10%]">
          {countCart && countCart !== undefined ? (
            <>
              {isCheckListLoading || isAddToCartLoading ? (
                <div className="flex justify-center items-center w-full">
                  <CircularProgress sx={{ color: "white" }} />
                </div>
              ) : (
                <div className="flex justify-between items-center font-medium text-white h-full">
                  <div className="flex justify-center items-center h-full">
                    <p className="mr-2">{countCart.count}</p>
                    <p>Barang</p>
                    <div className="border-2 h-full border-white mx-2"></div>
                    <div>
                      <p>Total Belanja</p>
                      <p className="text-[#FDCD78]">
                        {countCart.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[#FF8D40] p-2 text-[20px] rounded-lg disabled:bg-gray-500"
                    disabled={isCheckoutButtonDisabled}
                    onClick={() => navigate("/purchase/checkout")}
                  >
                    Checkout
                  </button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={isDeleteProductModalOpen}
        onClose={() => setIsDeleteProductModalOpen(false)}
      >
        <DialogTitle>Hapus Produk</DialogTitle>
        <DialogContent sx={{ height: "100%" }}>
          {isLoading ? (
            <div className="flex justify-center items-center h-[100px]">
              <CircularProgress />
            </div>
          ) : (
            <div className="flex justify-center items-center h-[100px]">
              <p>{deleteMessage}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="border border-black p-2 rounded-md font-bold w-full"
            onClick={() => {
              setIsDeleteProductModalOpen(false);
            }}
          >
            Tidak
          </button>
          <button
            className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
            onClick={() => {
              if (isChecked) {
                deleteCartItem();
              } else {
                dataBufferReguler
                  .filter((data) => data.check)
                  .map((data) =>
                    mutateAddToCart({
                      price: data.price,
                      product_id: data.product_id,
                      quantity: 0,
                      check: data.check,
                      flag: false,
                      type: 1,
                      param: 1,
                    })
                  );
                dataBufferSo
                  .filter((data) => data.check)
                  .map((data) =>
                    mutateAddToCart({
                      price: data.price,
                      product_id: data.product_id,
                      quantity: 0,
                      check: data.check,
                      flag: false,
                      type: 1,
                      param: 1,
                    })
                  );

                setIsLoading(true);
              }
              //     {
              //   cart_id: cartRegId,
              //   param: 1,
              //   tipe: 1,
              // }
            }}
          >
            Ya
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenAddVoucher}
        onClose={() => {
          setIsOpenAddVoucher(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          {isDetailVoucherOpen ? (
            <button onClick={() => setIsDetailVoucherOpen(false)}>
              <AiOutlineArrowLeft />
            </button>
          ) : null}
          <div className="flex w-full items-center justify-center">
            <p className="font-bold">
              {isDetailVoucherOpen ? "Detail Voucher" : "Voucher / Kode Promo"}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          {isDetailVoucherOpen ? (
            <VoucherPurchaseDetail voucherId={voucherId} />
          ) : (
            <VoucherPurchaseCart
              voucherPurchaseData={voucherPurchaseData}
              setIsDetailVoucherOpen={setIsDetailVoucherOpen}
              setVoucherId={setVoucherId}
              cartDetailData={cartDetailData}
              // checklistData={checklistData}
              countCart={countCart}
              isChecked={isChecked}
            />
          )}
        </DialogContent>
        {isDetailVoucherOpen ? null : (
          <DialogActions>
            {totalDiscount !== 0 ? (
              <div className="flex justify-between w-full">
                <div className="text-[12px] font-bold w-full">
                  <p>Kamu bisa hemat</p>
                  <p className="text-[#007AF1]">
                    {totalDiscount.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                </div>
                <button
                  className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                  onClick={() => {
                    if (selectedVoucher && selectedVoucher !== undefined) {
                      setIsOpenAddVoucher(false);
                    } else return;
                  }}
                >
                  Gunakan Voucher
                </button>
              </div>
            ) : (
              <button
                className="bg-blue-500 p-2 rounded-md text-white font-bold w-full"
                onClick={() => {
                  setIsOpenAddVoucher(false);
                  setSelectedVoucher({
                    voucherId: [],
                    voucherName: "",
                    voucherPrice: [],
                  });
                }}
              >
                Bayar Tanpa Voucher
              </button>
            )}
          </DialogActions>
        )}
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  setErrorMessage("");
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default CartDetails;
