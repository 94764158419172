import {
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Modal,
  TextField,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaRegUser,
  FaChevronDown,
  FaChevronLeft,
} from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { uploadImage } from "../../../../services/userManagementApi";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import {
  getPatientCareSpecialization,
  setPatientCareDoctor,
  setPatientCareSpecialization,
} from "../../../../services/patientCare";
import useDebounce from "../../../hooks/useDebounce";
import ModalDeleteSpecialization from "../patientCareServices/ModalDeleteSpecialization";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalAddEditDoctor = ({ open, setOpen, isEdit, doctor, refetch }) => {
  const [view, setView] = useState("doctor");
  const [anchorElSpecialization, setAnchorElSpecialization] = useState(null);
  const [specializationSearch, setSpecializationSearch] = useState("");
  const [selectedSpecialization, setselectedSpecialization] = useState(null);
  const [editSpec, setEditSpec] = useState(null);
  const [isEditSpec, setIsEditSpec] = useState(false);
  const [newSpec, setNewSpec] = useState({
    name: "",
  });
  const [openDeleteSpec, setOpenDeleteSpec] = useState(false);

  const { mutate: mutateSpec, isLoading } = useMutation({
    mutationFn: setPatientCareSpecialization,
    onSuccess: () => {
      setView("doctor");
    },
  });

  useEffect(() => {
    if (editSpec && view === "specialization") setNewSpec(editSpec);
    if (view !== "specialization") setNewSpec({ name: "" });
  }, [editSpec, view]);

  const openSpecialization = Boolean(anchorElSpecialization);
  const debounce = useDebounce(specializationSearch, 500);

  const handleClose = () => {
    setselectedSpecialization(null);
    setOpen(false);
  };

  const { mutate: mutateUploadImage, isLoading: isLoadingImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => formik.setFieldValue("image", data.url),
  });

  const {
    mutate: mutateSpecialization,
    isLoading: isLoadingSpecialization,
    data: dataSpecialization,
  } = useMutation({
    mutationFn: getPatientCareSpecialization,
  });

  const { mutate: mutateDoctor, isLoading: isLoadingDoctor } = useMutation({
    mutationFn: setPatientCareDoctor,
    onSuccess: () => {
      setselectedSpecialization(null);
      refetch();
      setOpen(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: doctor ? doctor.name : "",
      email: doctor ? doctor.email : "",
      phone: doctor ? doctor.phone : "",
      address: doctor ? doctor.address : "",
      sip: doctor ? doctor.sip : "",
      ktp: doctor ? doctor.ktp : "",
      image: doctor ? doctor.image : "",
      specialization_id: doctor ? doctor.specialization_id : 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateDoctor(doctor ? { ...values, id: Number(doctor.id) } : values);
    },
  });

  useEffect(() => {
    if (anchorElSpecialization)
      mutateSpecialization({
        search: debounce,
        limit: 10,
        offset: 1,
      });
  }, [debounce, anchorElSpecialization]);

  useEffect(() => {
    if (doctor) {
      formik.setFieldValue("id", Number(doctor.id));
      setselectedSpecialization({
        id: doctor.specialization_id,
        name: doctor.specialization,
      });
    } else {
      setselectedSpecialization(null);
    }
  }, [doctor]);

  useEffect(() => {
    if (open) {
      formik.resetForm();
      setView("doctor");
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "80%",
            overflowY: "auto",
            overflowX: "wrap",
            minHeight: "80vh",
            maxHeight: "90vh",
            padding: 0,
          }}
          className="text-left text-base"
        >
          {view === "doctor" ? (
            <>
              <div className="px-8 pt-4">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <p className="font-bold text-lg">Add Doctor</p>
                  </div>
                  <RxCross1
                    className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                    onClick={handleClose}
                  />
                </div>
              </div>
              <hr className="my-4" />
              <form onSubmit={formik.handleSubmit}>
                <div className="px-8 flex gap-4 items-start overflow-y-auto">
                  <div className="relative">
                    <div
                      className={`w-[200px] h-[200px] rounded-md bg-gray-200 ${
                        formik.values.image === "" ? "p-12" : ""
                      }`}
                    >
                      {formik.values.image === "" ? (
                        isLoadingImage ? (
                          <div className="w-full h-full flex justify-center items-center">
                            <CircularProgress size={30} />
                          </div>
                        ) : (
                          <FaRegUser className="w-full h-full text-white" />
                        )
                      ) : (
                        <img
                          src={formik.values.image}
                          className="w-full h-full rounded-md object-cover"
                        />
                      )}
                    </div>

                    {isEdit ? (
                      <>
                        <input
                          type="file"
                          id="doctor-img-upload"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => {
                            formik.setFieldValue("image", "");
                            mutateUploadImage(e.target.files[0]);
                          }}
                        />
                        <label for="doctor-img-upload">
                          <div className="w-12 h-12 p-2.5 flex justify-center text-white items-center rounded-full bg-blue-500 absolute bottom-0 left-0 right-0 mx-auto cursor-pointer hover:bg-gray-300">
                            <FaRegEdit className="w-full h-full" />
                          </div>
                        </label>
                      </>
                    ) : null}
                  </div>
                  <div className="w-full flex-1">
                    <p className="text-xs">
                      Nama Dokter <span className="text-red-500">*</span>
                    </p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      value={formik.values.name}
                      onChange={(e) =>
                        formik.setFieldValue("name", e.target.value)
                      }
                    />
                    <p className="text-xs">Email</p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                    />
                    <p className="text-xs">Address</p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      multiline
                      rows={4}
                      value={formik.values.address}
                      onChange={(e) =>
                        formik.setFieldValue("address", e.target.value)
                      }
                    />
                    <p className="text-xs">Phone Number</p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      value={formik.values.phone}
                      onChange={(e) =>
                        formik.setFieldValue("phone", e.target.value)
                      }
                    />
                    <p className="text-xs">No. SIP</p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      value={formik.values.sip}
                      onChange={(e) =>
                        formik.setFieldValue("sip", e.target.value)
                      }
                    />
                    <p className="text-xs">NIK</p>
                    <TextField
                      disabled={!isEdit}
                      fullWidth
                      variant="standard"
                      sx={{ marginBottom: 2 }}
                      value={formik.values.ktp}
                      onChange={(e) =>
                        formik.setFieldValue("ktp", e.target.value)
                      }
                    />
                    <div className="flex gap-4 items-center mb-4">
                      <div className="w-full h-full">
                        <div
                          className="flex justify-betwen items-center cursor-pointer"
                          onClick={(e) =>
                            setAnchorElSpecialization(e.currentTarget)
                          }
                        >
                          <p className="text-xs w-full py-3">
                            {selectedSpecialization
                              ? selectedSpecialization.name
                              : "Specialization"}
                          </p>
                          <FaChevronDown className="text-gray-500" />
                          <Popper
                            disablePortal
                            style={{
                              width: anchorElSpecialization?.clientWidth,
                            }}
                            open={openSpecialization}
                            anchorEl={anchorElSpecialization}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            sx={{ width: "100%", zIndex: 1500 }}
                          >
                            <ClickAwayListener
                              onClickAway={() =>
                                setAnchorElSpecialization(null)
                              }
                            >
                              <div className="w-full bg-white rounded-b-md shadow-md max-h-[30vh] overflow-y-auto">
                                <TextField
                                  fullWidth
                                  inputProps={{ style: { padding: 12 } }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Search />
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ padding: 2 }}
                                  placeholder="Find Specialization"
                                  value={specializationSearch}
                                  onChange={(e) =>
                                    setSpecializationSearch(e.target.value)
                                  }
                                />
                                <hr className="" />
                                {dataSpecialization &&
                                !isLoadingSpecialization &&
                                dataSpecialization.count > 0 ? (
                                  dataSpecialization.specialization.map(
                                    (item) => {
                                      return (
                                        <div
                                          className={`w-full py-2 px-8 border-b-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 ${
                                            selectedSpecialization &&
                                            selectedSpecialization.id ===
                                              item.id
                                              ? "bg-gray-100"
                                              : ""
                                          }`}
                                        >
                                          <p
                                            className="w-full"
                                            onClick={() => {
                                              formik.setFieldValue(
                                                "specialization_id",
                                                item.id
                                              );
                                              setselectedSpecialization(item);
                                              setAnchorElSpecialization(null);
                                            }}
                                          >
                                            {item.name}
                                          </p>{" "}
                                          <div className="flex items-center gap-2">
                                            <FaRegTrashAlt
                                              className="text-red-500 cursor-pointer hover:text-gray-300"
                                              onClick={() => {
                                                formik.setFieldValue(
                                                  "specialization_id",
                                                  0
                                                );
                                                setselectedSpecialization(null);
                                                setAnchorElSpecialization(null);
                                                setEditSpec(item);
                                                setOpenDeleteSpec(true);
                                              }}
                                            />
                                            <FaRegEdit
                                              className="text-blue-500 cursor-pointer hover:text-gray-300"
                                              onClick={() => {
                                                setView("specialization");
                                                setIsEditSpec(true);
                                                setEditSpec(item);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : isLoadingSpecialization ? (
                                  <div className="flex justify-center py-2">
                                    <CircularProgress size={20} />
                                  </div>
                                ) : (
                                  <div className="flex justify-center py-2 text-gray-300">
                                    Specialization not found
                                  </div>
                                )}
                              </div>
                            </ClickAwayListener>
                          </Popper>
                        </div>
                        <hr className="" />
                      </div>
                      <div
                        className="rounded-md p-2 flex justify-center items-center w-[48px] h-[48px] cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
                        onClick={() => {
                          setView("specialization");
                          setIsEditSpec(false);
                        }}
                      >
                        <IoMdAddCircleOutline className="w-full h-full" />
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      <Checkbox disabled />
                      <p className="text-xs text-gray-300">
                        Send email to create doctor account
                      </p>
                    </div>
                  </div>
                </div>
                {isEdit ? (
                  <div className="px-8 py-4 w-full ">
                    <button
                      type="submit"
                      disabled={
                        !formik.isValid ||
                        isLoadingDoctor ||
                        formik.values.name === ""
                      }
                      className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
                    >
                      {isLoadingDoctor ? (
                        <CircularProgress size={20} />
                      ) : (
                        <p>Save</p>
                      )}
                    </button>
                  </div>
                ) : null}
              </form>
            </>
          ) : view === "specialization" ? (
            <>
              <div className="p-8">
                <div className="flex justify-between items-center pb-8">
                  <div className="flex items-center gap-4">
                    <FaChevronLeft
                      className="hover:text-gray-300 cursor-pointer"
                      onClick={() => {
                        setView("doctor");
                        setEditSpec(null);
                      }}
                    />
                    <p className="font-bold text-lg">
                      {isEditSpec ? "Edit" : "Add"} Specialization
                    </p>
                  </div>
                  <RxCross1
                    className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                    onClick={() => {
                      setOpen(false);
                      setEditSpec(null);
                    }}
                  />
                </div>

                <p className="text-xs text-gray-500">
                  Specialization Name <span className="text-red-500"> *</span>
                </p>
                <TextField
                  fullWidth
                  variant="standard"
                  value={newSpec.name}
                  onChange={(e) =>
                    setNewSpec({ ...newSpec, name: e.target.value })
                  }
                />
              </div>
              <div className="px-8 pb-4 w-full absolute bottom-0">
                <button
                  disabled={newSpec.name === ""}
                  onClick={() => mutateSpec(newSpec)}
                  className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
                >
                  {isLoading ? <CircularProgress size={20} /> : <p>Save</p>}
                </button>
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
      <ModalDeleteSpecialization
        open={openDeleteSpec}
        setOpen={setOpenDeleteSpec}
        spec={editSpec}
      />
    </>
  );
};

export default ModalAddEditDoctor;
