import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { SelectedProductContext } from "../../../context/SelectedProductProvider";

const SuggestionModal = () => {
  const navigate = useNavigate();
  const {
    addToCartData,
    countCart,
    isCountCartLoading,
    isAddToCartLoading,
    isSuggestionOpen,
    setIsSuggestionOpen,
    mutateAddToCart,
    dataBufferSuggestion,
    setDataBufferSuggestion,
    setIsLoading,
    isLoading,
  } = useContext(SelectedProductContext);

  useEffect(() => {
    if (addToCartData && addToCartData !== undefined) {
      setDataBufferSuggestion(
        addToCartData.suggestion && addToCartData.suggestion !== undefined
          ? {
              parent: {
                discount: addToCartData.suggestion.parent?.discount,
                id: addToCartData.suggestion.parent?.id,
                price: addToCartData.suggestion.parent?.price,
                image: addToCartData.suggestion.parent?.image,
                uom: addToCartData.suggestion.parent?.uom,
                nama_produk: addToCartData.suggestion.parent?.nama_produk,
                quantity: addToCartData.suggestion.parent?.quantity,
                isVisible: addToCartData.suggestion.parent?.quantity !== 0,
                isLoading: false,
              },
              related: addToCartData.suggestion.related?.map((data) => ({
                discount: data.discount,
                id: data.id,
                price: data.price,
                price_discount: data.price_discount,
                image: data.image,
                uom: data.uom,
                nama_produk: data.nama_produk,
                quantity: data.quantity,
                isVisible: data.quantity !== 0,
                isLoading: false,
              })),
            }
          : null
      );
    }
  }, [addToCartData]);

  return (
    <>
      {addToCartData && addToCartData !== undefined ? (
        <Dialog
          open={isSuggestionOpen}
          onClose={() => setIsSuggestionOpen(false)}
          fullWidth
          maxWidth="lg"
        >
          {addToCartData.suggestion &&
          addToCartData.suggestion !== undefined ? (
            <DialogContent>
              {addToCartData.suggestion.parent &&
              addToCartData.suggestion.parent !== undefined &&
              dataBufferSuggestion !== null ? (
                <div className="flex justify-center w-full">
                  <img
                    className="h-[150px] w-[150px]"
                    src={dataBufferSuggestion.parent.image}
                  />
                  <div className="text-[12px] lg:text-[15px] w-[15%]">
                    <h1 className="text-gray-700 font-bold ">
                      {dataBufferSuggestion.parent.nama_produk}
                    </h1>
                    <p className="text-gray-500 my-2">
                      {dataBufferSuggestion.parent.uom}
                    </p>
                    {dataBufferSuggestion.parent.discount === 0 ? (
                      <h1 className="text-gray-700 font-bold">
                        {Math.round(
                          dataBufferSuggestion.parent.price
                        ).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </h1>
                    ) : (
                      <div className="flex gap-2 items-center mt-1">
                        <h1 className="text-gray-700 font-bold">
                          {Math.round(
                            dataBufferSuggestion.parent.price
                          ).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </h1>
                        <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                          {Math.round(dataBufferSuggestion.parent.discount)}%
                        </p>
                        <p className="line-through">
                          Rp.{" "}
                          {dataBufferSuggestion.parent.price?.toLocaleString(
                            "id-ID",
                            {
                              style: "currency",
                              currency: "IDR",
                            }
                          )}
                        </p>
                      </div>
                    )}
                    <div>
                      {dataBufferSuggestion.parent.isVisible ? (
                        <div className="w-full mt-3">
                          <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                            {dataBufferSuggestion.parent.isLoading ? (
                              <div className="flex justify-center w-full">
                                <p>Loading</p>
                              </div>
                            ) : (
                              <>
                                <button
                                  className="bg-[#FF7A5D] px-3 rounded-lg"
                                  disabled={isLoading}
                                  onClick={() => {
                                    if (
                                      dataBufferSuggestion.parent.quantity === 1
                                    )
                                      setDataBufferSuggestion((prevValue) => ({
                                        ...prevValue,
                                        parent: {
                                          quantity:
                                            prevValue.parent.quantity - 1,
                                          isLoading: true,
                                          isVisible: false,
                                        },
                                      }));
                                    else
                                      setDataBufferSuggestion((prevValue) => ({
                                        ...prevValue,
                                        parent: {
                                          quantity:
                                            prevValue.parent.quantity - 1,
                                          isLoading: true,
                                        },
                                      }));
                                    setIsLoading(true);
                                    mutateAddToCart({
                                      product_id:
                                        dataBufferSuggestion.parent.id,
                                      quantity:
                                        dataBufferSuggestion.parent.quantity -
                                        1,
                                      price: dataBufferSuggestion.parent.price,
                                      flag: true,
                                      type: 1,
                                      param: 1,
                                    });
                                  }}
                                >
                                  -
                                </button>
                                {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBufferMostSearched[index]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBufferMostSearched(
                                      dataBufferMostSearched.map((offer) => {
                                        return offer.id === productId
                                          ? {
                                              ...offer,
                                              quantity: 0,
                                            }
                                          : offer;
                                      })
                                    );
                                  setDataBufferMostSearched(
                                    dataBufferMostSearched.map((offer) => {
                                      return offer.id === productId
                                        ? {
                                            ...offer,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0
                                              ),
                                              10
                                            ),
                                          }
                                        : offer;
                                    })
                                  );
                                }}
                              /> */}
                                <p>{dataBufferSuggestion.parent.quantity}</p>
                                <button
                                  className="bg-[#5DFFBB] px-3 rounded-lg"
                                  disabled={isLoading}
                                  onClick={() => {
                                    setDataBufferSuggestion((prevValue) => ({
                                      ...prevValue,
                                      parent: {
                                        quantity: prevValue.parent.quantity + 1,
                                        isLoading: true,
                                      },
                                    }));
                                    setIsLoading(true);
                                    mutateAddToCart({
                                      product_id:
                                        dataBufferSuggestion.parent.id,
                                      quantity:
                                        dataBufferSuggestion.parent.quantity +
                                        1,
                                      price: dataBufferSuggestion.parent.price,
                                      flag: true,
                                      type: 1,
                                      param: 1,
                                    });
                                  }}
                                >
                                  +
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="mt-3 text-[12px] lg:text-base">
                          <button
                            className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                            disabled={isLoading}
                            onClick={() => {
                              setDataBufferSuggestion((prevValue) => ({
                                ...prevValue,
                                parent: {
                                  quantity: 1,
                                  isVisible: true,
                                },
                              }));
                              setIsLoading(true);
                              mutateAddToCart({
                                product_id: dataBufferSuggestion.parent.id,
                                quantity: 1,
                                price: dataBufferSuggestion.parent.price,
                                flag: true,
                                type: 1,
                                param: 1,
                              });
                            }}
                          >
                            <AiOutlineShoppingCart />
                            Add to cart
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <p className="font-bold">Kamu mungkin tertarik</p>
              <Swiper
                slidesPerView={4}
                spaceBetween={10}
                navigation
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                modules={[Navigation]}
                className="flex justify-center w-full grid grid-cols-1"
              >
                {addToCartData.suggestion.related &&
                addToCartData.suggestion.related !== undefined &&
                dataBufferSuggestion !== null
                  ? dataBufferSuggestion.related?.map((data, index) => (
                      <SwiperSlide key={index}>
                        <div className="flex justify-center">
                          <img
                            className="w-[200px] h-[200px]"
                            src={data.image}
                          />
                        </div>
                        <div className="text-[12px] lg:text-[15px]">
                          <h1 className="text-gray-700 font-bold ">
                            {data.nama_produk}
                          </h1>
                          <p className="text-gray-500 mt-2">{data.uom}</p>
                          {data.discount === null ? (
                            <h1 className="text-gray-700 font-bold">
                              Rp.
                              {Math.round(data.price).toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </h1>
                          ) : (
                            <>
                              <h1 className="text-gray-700 font-bold">
                                Rp.
                                {Math.round(data.price_discount).toLocaleString(
                                  "id-ID",
                                  {
                                    style: "currency",
                                    currency: "IDR",
                                  }
                                )}
                              </h1>
                              <div className="flex gap-2 items-center mt-1">
                                <p className="bg-yellow-400 px-2 rounded-tl-xl rounded-br-xl font-bold">
                                  {Math.round(data.discount)}%
                                </p>
                                <p className="line-through">
                                  Rp.{" "}
                                  {data.price?.toLocaleString("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  })}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          {data.isVisible ? (
                            <div className="w-full mt-3">
                              <div className="bg-[#EAEAEA] flex justify-between font-medium rounded-lg">
                                {data.isLoading ? (
                                  <div className="flex justify-center w-full">
                                    <p>Loading</p>
                                  </div>
                                ) : (
                                  <>
                                    <button
                                      className="bg-[#FF7A5D] px-3 rounded-lg"
                                      disabled={isLoading}
                                      onClick={() => {
                                        if (data.quantity === 1)
                                          setDataBufferSuggestion(
                                            (prevValue) => ({
                                              ...prevValue,
                                              related: prevValue.related.map(
                                                (list) => {
                                                  return list.id === data.id
                                                    ? {
                                                        quantity:
                                                          prevValue.parent
                                                            .quantity - 1,
                                                        isLoading: true,
                                                        isVisible: false,
                                                      }
                                                    : data;
                                                }
                                              ),
                                            })
                                          );
                                        else
                                          setDataBufferSuggestion(
                                            (prevValue) => ({
                                              ...prevValue,
                                              related: prevValue.related.map(
                                                (list) => {
                                                  return list.id === data.id
                                                    ? {
                                                        quantity:
                                                          prevValue.parent
                                                            .quantity - 1,
                                                        isLoading: true,
                                                      }
                                                    : data;
                                                }
                                              ),
                                            })
                                          );
                                        setIsLoading(true);
                                        mutateAddToCart({
                                          product_id: data.id,
                                          quantity: data.quantity - 1,
                                          price: data.price,
                                          flag: true,
                                          type: 1,
                                          param: 1,
                                        });
                                      }}
                                    >
                                      -
                                    </button>
                                    {/* <input
                                className="w-[10%] bg-[#EAEAEA]"
                                value={dataBufferMostSearched[index]?.quantity}
                                onChange={(e) => {
                                  const numericValue = e.target.value;
                                  if (numericValue === "")
                                    return setDataBufferMostSearched(
                                      dataBufferMostSearched.map((offer) => {
                                        return offer.id === productId
                                          ? {
                                              ...offer,
                                              quantity: 0,
                                            }
                                          : offer;
                                      })
                                    );
                                  setDataBufferMostSearched(
                                    dataBufferMostSearched.map((offer) => {
                                      return offer.id === productId
                                        ? {
                                            ...offer,
                                            quantity: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                0
                                              ),
                                              10
                                            ),
                                          }
                                        : offer;
                                    })
                                  );
                                }}
                              /> */}
                                    <p>
                                      {dataBufferSuggestion.parent.quantity}
                                    </p>
                                    <button
                                      className="bg-[#5DFFBB] px-3 rounded-lg"
                                      disabled={isLoading}
                                      onClick={() => {
                                        setDataBufferSuggestion(
                                          (prevValue) => ({
                                            ...prevValue,
                                            related: prevValue.related.map(
                                              (list) => {
                                                return list.id === data.id
                                                  ? {
                                                      quantity:
                                                        prevValue.parent
                                                          .quantity + 1,
                                                      isLoading: true,
                                                    }
                                                  : data;
                                              }
                                            ),
                                          })
                                        );
                                        setIsLoading(true);
                                        mutateAddToCart({
                                          product_id: data.id,
                                          quantity: data.quantity + 1,
                                          price: data.price,
                                          flag: true,
                                          type: 1,
                                          param: 1,
                                        });
                                      }}
                                    >
                                      +
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="mt-3 text-[12px] lg:text-base">
                              <button
                                className="flex justify-center items-center gap-2 text-center bg-[#4F5D75] w-full p-1 rounded-lg text-white hover:bg-[#3B485E] hover:shadow-md hover:scale-105 transition duration-300 ease-in-out"
                                disabled={isLoading}
                                onClick={() => {
                                  setDataBufferSuggestion((prevValue) => ({
                                    ...prevValue,
                                    related: prevValue.related.map((list) => {
                                      return list.id === data.id
                                        ? {
                                            quantity:
                                              prevValue.parent.quantity + 1,
                                            isVisible: true,
                                          }
                                        : data;
                                    }),
                                  }));
                                  setIsLoading(true);
                                  mutateAddToCart({
                                    product_id: data.id,
                                    quantity: 1,
                                    price: data.price,
                                    flag: true,
                                    type: 1,
                                    param: 1,
                                  });
                                }}
                              >
                                <AiOutlineShoppingCart />
                                Add to cart
                              </button>
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </DialogContent>
          ) : (
            <p>tidak ada produk</p>
          )}
          <DialogActions sx={{ backgroundColor: "#007AF1" }}>
            {countCart && countCart !== undefined ? (
              <div className="flex justify-center w-full">
                {countCart.count !== 0 ? (
                  <div className="w-full h-[10%]">
                    {isCountCartLoading || isAddToCartLoading ? (
                      <div className="flex justify-center w-full">
                        <CircularProgress sx={{ color: "white" }} />
                      </div>
                    ) : (
                      <div className="flex justify-between items-center font-medium text-white h-full">
                        <div className="flex justify-center items-center h-full">
                          <p>{countCart.count} Barang</p>
                          <div className="border-2 h-full border-white mx-2"></div>
                          <div>
                            <p>Total Belanja</p>
                            <p className="text-[#FDCD78]">
                              Rp.{" "}
                              {countCart.total.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                          </div>
                        </div>
                        <button
                          className="bg-[#FF8D40] p-2 text-[20px] rounded-lg"
                          onClick={() => navigate("/purchase/cart-detail")}
                        >
                          Lihat Keranjang
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default SuggestionModal;
