import React from "react";
import ImportProduct from "../../../components/screen/inventory/productActive/ImportProduct";
import SidebarComponents from "../../../components/ui/SidebarComponents";
import Header from "../../../components/ui/Header";
import { SIDEBAR_WIDTH } from "../../../components/constants/constant";

const ImportActiveProduct = ({ isWarehouse }) => {
  return (
    <div className="flex">
      <SidebarComponents />
      <div
        className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}
      >
        <Header />
        <ImportProduct isWarehouse={isWarehouse} />
      </div>
    </div>
  );
};

export default ImportActiveProduct;
