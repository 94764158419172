import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import ReactToPrint from "react-to-print";
import moment from "moment";

import { fetchTransactionDetail } from "../../../../../services/purchaseApi";
import logoOlin from "../../../../../assets/icons/logoOlin.png";

const toDate = (date) => moment(date).format("DD-MM-YYYY");

const InvoiceTransactionPurchase = () => {
  const { invoice = "" } = useParams();
  const componentRef = useRef();

  const {
    mutate: mutateTransactionDetail,
    data: transactionDetailData,
    isLoading: isTransactionDetailLoading,
  } = useMutation({
    mutationFn: fetchTransactionDetail,
  });

  useEffect(() => {
    mutateTransactionDetail({ invoice: invoice });
  }, [invoice]);

  return (
    <div>
      {transactionDetailData && transactionDetailData !== undefined ? (
        <>
          {isTransactionDetailLoading ? (
            <div className="h-screen flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="flex justify-end mt-3">
                <ReactToPrint
                  trigger={() => (
                    <button className="p-2 lg:p-[12px] bg-blue-500 mb-3 px-4 lg:px-5 font-medium text-sm lg:text-base text-white rounded-xl hover:bg-blue-300">
                      Download
                    </button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={`Purchase_Invoice_${moment().format(
                    "DD-MM-YYYY HH_mm_ss"
                  )}`}
                />
              </div>
              <div ref={componentRef} className="p-3">
                <div className="p-5 border text-sm font-medium">
                  <div>
                    <div className="flex w-full justify-between items-center">
                      <img
                        className="h-[41px] w-[75px] bg-cover"
                        src={logoOlin}
                      />
                      <p className="font-bold text-[32px]">Invoice</p>
                    </div>
                    <div className="flex mt-3 gap-1 text-xs">
                      <div>
                        <p className="mb-2">Nomor Invoice</p>
                        <p>Tanggal Pembelian</p>
                      </div>
                      <div className="mx-2">
                        <p className="mb-2">:</p>
                        <p>:</p>
                      </div>
                      <div>
                        <p className="mb-2">
                          {transactionDetailData.nomor_invoice}
                        </p>
                        <p>{toDate(transactionDetailData.tanggal_pembelian)}</p>
                      </div>
                    </div>
                    <div className="px-0 lg:px-5 text-xs">
                      <div className="overflow-x-auto p-4">
                        <table className="table w-full">
                          <thead>
                            <tr className="text-center">
                              <th className="border-collapse border border-black">
                                Produk
                              </th>
                              <th className="border-collapse border border-black">
                                QTY
                              </th>
                              <th className="border-collapse border border-black">
                                Harga Satuan
                              </th>
                              <th className="border-collapse border border-black">
                                Diskon
                              </th>
                              <th className="border-collapse border border-black">
                                Total Harga
                              </th>
                            </tr>
                          </thead>
                          {transactionDetailData.product?.regular?.map(
                            (item, index) => {
                              return (
                                <tbody key={index} className="text-center">
                                  <tr>
                                    <td className="border-collapse border border-black">
                                      {item.product_name}
                                    </td>
                                    <td className="border-collapse border border-black">
                                      {item.quantity}
                                    </td>
                                    <td className="border-collapse border border-black">
                                      {item.price.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })}
                                    </td>
                                    <td className="border-collapse border border-black">
                                      {Math.round(
                                        ((item.total - item.price) /
                                          item.total) *
                                          100
                                      )}
                                      %
                                    </td>
                                    <td className="border-collapse border border-black">
                                      {item.total.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      })}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }
                          )}
                        </table>
                      </div>
                      <div className="flex gap-1 justify-end w-full">
                        <div>
                          <p>Subtotal Untuk Produk</p>
                          <p className="mt-2">Ongkos Kirim</p>
                          <p className="mt-2">Diskon Ongkos Kirim</p>
                          <p className="mt-2">Total Pembayaran</p>
                        </div>
                        {transactionDetailData.payment !== null ? (
                          <div className="ml-7">
                            <p>
                              {transactionDetailData.payment.subtotal?.toLocaleString(
                                "id-ID",
                                {
                                  style: "currency",
                                  currency: "IDR",
                                }
                              )}
                            </p>
                            {transactionDetailData.payment.shipping_price ===
                            0 ? (
                              <p className="mt-2">Gratis</p>
                            ) : (
                              <p className="mt-2">
                                {transactionDetailData.payment.shipping_price?.toLocaleString(
                                  "id-ID",
                                  {
                                    style: "currency",
                                    currency: "IDR",
                                  }
                                )}
                              </p>
                            )}
                            <p className="mt-2">
                              {transactionDetailData.payment.discount_shipping?.toLocaleString(
                                "id-ID",
                                {
                                  style: "currency",
                                  currency: "IDR",
                                }
                              )}
                            </p>
                            <p className="mt-2 text-[#FCB63A]">
                              {transactionDetailData.payment.total.toLocaleString(
                                "id-ID",
                                {
                                  style: "currency",
                                  currency: "IDR",
                                }
                              )}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="border border-black w-full my-3"></div>
                      {transactionDetailData.shipping !== null &&
                      transactionDetailData.payment_method !== null ? (
                        <div className="flex">
                          <div className="w-full">
                            <p>Kurir :</p>
                            <p className="mt-2">
                              {transactionDetailData.shipping.shipping_name}
                            </p>
                          </div>
                          <div className="w-full">
                            <p>Metode Pembayaran :</p>
                            <p className="mt-2">
                              {transactionDetailData.payment.payment_method}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default InvoiceTransactionPurchase;
