import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputAdornment,
  TextField,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import {
  getCongsignmentDetail,
  getCongsignmentList,
  getConsignmentDetailBatch,
} from "../../../../services/consignmentApi";
import ModalSwitchConsignment from "../../../common/particles.jsx/ModalSwitchConsignment";
import ModalReturnConsignment from "../../../common/particles.jsx/ModalReturnConsignment";
import { IGNORE_TYPE } from "../../../constants/constant";

const FETCH_LIMIT = 10;

const Consignment = () => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState("");
  const [productName, setProductName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [offset, setOffset] = useState(1);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [invoiceNum, setInvoiceNum] = useState("");
  const [id, setId] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [reload, setReload] = useState(false);

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return (
        Math.floor(stock / item.packing.total_strip) +
        " " +
        item.packing.type_strip.toUpperCase()
      );
    } else {
      return (
        Math.floor(
          stock / (item.packing.total_strip * item.packing.total_unit)
        ) +
        " " +
        item.packing.type_box.toUpperCase()
      );
    }
  };

  const { data: batchData } = useQuery({
    queryKey: ["detail-batch-consignment", id],
    queryFn: () => getConsignmentDetailBatch(id),
  });

  const { data } = useQuery({
    queryKey: [
      "consignment",
      reload,
      invoice,
      offset,
      supplierName,
      productName,
    ],

    queryFn: () =>
      getCongsignmentList(
        invoice,
        FETCH_LIMIT,
        offset,
        supplierName,
        productName
      ),
  });

  useEffect(() => {
    setReload(false);
  }, [reload]);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center text-white font-bold">
        <p className="text-[#007AF1]">Konsinyasi</p>
        <button
          className="bg-[#007AF1] py-2 px-4 rounded-md"
          onClick={() => navigate("/inventory/add-consignment")}
        >
          Tambah Faktur
        </button>
      </div>
      <div className="flex gap-3 mt-3">
        <TextField
          label={invoice === "" ? "" : "No. Faktur"}
          placeholder={invoice !== "" ? "" : "No. Faktur"}
          onChange={(e) => {
            setInvoice(e.target.value);
          }}
          value={invoice}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />{" "}
        <TextField
          label={productName === "" ? "" : "Nama Produk"}
          placeholder={productName !== "" ? "" : "Nama Produk"}
          onChange={(e) => {
            setProductName(e.target.value);
          }}
          value={productName}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />{" "}
        <TextField
          label={supplierName === "" ? "" : "Nama Supplier"}
          placeholder={supplierName !== "" ? "" : "Nama Supplier"}
          onChange={(e) => {
            setSupplierName(e.target.value);
          }}
          value={supplierName}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="mt-8 mb-2 block overflow-x-auto w-full">
        <table className="w-full border-2 border-[#5B5B5B]">
          <thead className="bg-[#007AF1] text-white text-center">
            <tr>
              <th className="p-2 border-2 border-[#5B5B5B]">
                Tanggal Transaksi
              </th>
              <th className="p-2 border-2 border-[#5B5B5B]">No Faktur</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Nama Supplier</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Nama Obat</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Jumlah Awal</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Return</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Terjual</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Current Stock</th>
              <th className="p-2 border-2 border-[#5B5B5B]">Operation</th>
            </tr>
          </thead>
          <tbody className="">
            {data &&
            data.detail.consignment_list !== null &&
            data.detail.count > 0 ? (
              data.detail.consignment_list.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {moment(item.date_transaction).format("DD-MM-YYYY")}
                    </td>
                    <td
                      className="p-2 text-blue-500 border-2 border-[#5B5B5B] whitespace-nowrap cursor-pointer hover:text-blue-700"
                      onClick={() =>
                        navigate(
                          `/inventory/consignment/${btoa(item.invoice)}:${btoa(
                            item.id
                          )}`
                        )
                      }
                    >
                      {item.invoice}
                    </td>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {item.supplier_name}
                    </td>
                    <td
                      className="p-2 text-center text-blue-500 border-2 border-[#5B5B5B] cursor-pointer hover:text-blue-700"
                      onClick={() =>
                        navigate(
                          `/inventory/consignment/product/${btoa(
                            item.invoice
                          )}:${btoa(item.id)}`
                        )
                      }
                    >
                      {item.product_name}
                    </td>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {primaryStock(
                        item.first_stock === null ? 0 : item.first_stock,
                        item
                      )}
                    </td>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {primaryStock(
                        item.total_return !== null ? item.total_return : 0,
                        item
                      )}
                    </td>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {primaryStock(
                        item.total_terjual !== null ? item.total_terjual : 0,
                        item
                      )}
                    </td>
                    <td className="p-2 text-center border-2 border-[#5B5B5B]">
                      {primaryStock(
                        item.current_stock !== null ? item.current_stock : 0,
                        item
                      )}
                    </td>
                    <td className="border-2 border-[#5B5B5B]">
                      <div className="w-full flex items-center gap-2">
                        <div
                          className="text-amber-500 w-1/2 text-center hover:text-amber-700 cursor-pointer"
                          onClick={() => {
                            setInvoiceNum(item.invoice);
                            setId(item.id);
                            setShowReturnModal(true);
                            setSelectedProduct(item.product_name);
                          }}
                        >
                          Return
                        </div>
                        <div
                          className="text-blue-500 w-1/2 text-center hover:text-blue-700 cursor-pointer"
                          onClick={() => {
                            setInvoiceNum(item.invoice);
                            setId(item.id);
                            setShowSwitchModal(true);
                            setSelectedProduct(item.product_name);
                          }}
                        >
                          Tukar Barang
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : data &&
              (data.detail.consignment_list === null ||
                data.detail.count === 0) ? (
              <tr>
                <td colSpan={9} className="text-center py-3">
                  Tidak ada produk konsinyasi
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={9} className="text-center py-3">
                  <CircularProgress size={24} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data &&
      data.detail.consignment_list !== null &&
      data.detail.count > 0 ? (
        <Pagination
          sx={{ float: "right", marginY: 2 }}
          count={Math.ceil(data.detail.count / FETCH_LIMIT)}
          onChange={(_, val) => setOffset(val)}
          page={offset}
        ></Pagination>
      ) : null}

      {batchData && batchData !== null ? (
        <>
          <ModalSwitchConsignment
            showModal={showSwitchModal}
            setShowModal={setShowSwitchModal}
            batchArr={batchData}
            productName={selectedProduct}
            prodId={id}
            setReload={setReload}
          />
          <ModalReturnConsignment
            showModal={showReturnModal}
            setShowModal={setShowReturnModal}
            batchArr={batchData}
            productName={selectedProduct}
            prodId={id}
            setReload={setReload}
          />
        </>
      ) : null}
    </div>
  );
};

export default Consignment;
