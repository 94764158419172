import { useQuery } from "@tanstack/react-query";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiOutlineTwitter,
} from "react-icons/ai";

import { getInvoice } from "../../../services/ReportingApi";
import { useParams } from "react-router-dom";

const InvoiceTransaction = () => {
  const { id = "" } = useParams();

  const { data: invoice } = useQuery({
    queryKey: ["invoice"],
    queryFn: () => getInvoice(id),
  });

  return (
    <div className="h-full flex items-center justify-center">
      {invoice && invoice !== undefined ? (
        <div className="flex flex-col justify-center items-center w-[50%]">
          {invoice.detail_invoice !== null && (
            <>
              <img
                src={invoice.detail_invoice.logo}
                className="w-[20%] h-[20%]"
              />
              <p className="font-bold text-[30px]">
                {invoice.detail_invoice.name_outlet}
              </p>
              <p className="font-semibold mb-5">
                {invoice.detail_invoice.address}
              </p>
              <div className="flex">
                <div>
                  <p>Apoteker</p>
                  <p>SIA</p>
                  <p>SIPA</p>
                </div>
                <div className="mx-3">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div>
                  <p>{invoice.detail_invoice.nama_apoteker}</p>
                  <p>{invoice.detail_invoice.sia}</p>
                  <p>{invoice.detail_invoice.sipa}</p>
                </div>
              </div>
              <div className="border-dashed border-2 w-full my-3"></div>
              {invoice.subtotal !== null &&
                invoice.detail_payment.map((detail, index) => (
                  <div className="flex" key={index}>
                    <div>
                      <p>No. Order</p>
                      <p>Tgl. Order</p>
                      <p>Kasir</p>
                    </div>
                    <div className="mx-3">
                      <p>:</p>
                      <p>:</p>
                      <p>:</p>
                    </div>
                    <div>
                      <>
                        <p>{detail.transaction_id}</p>
                        <p>{detail.created_at}</p>
                        <p>{invoice.detail_invoice.nama_kasir}</p>
                      </>
                    </div>
                  </div>
                ))}

              {invoice.list_medicine !== null &&
                invoice.list_medicine.map((list, index) => (
                  <div className="w-full" key={index}>
                    <div className="border-dashed border-2 w-full my-3"></div>
                    {"type_concoction_id" in invoice.list_medicine[index] ? (
                      <>
                        {list.type_concoction_id === 1 && (
                          <p className="font-bold">Racikan</p>
                        )}
                        {list.type_concoction_id === 2 && (
                          <p className="font-bold">Non-Racikan</p>
                        )}
                      </>
                    ) : (
                      <p className="font-bold">Regular</p>
                    )}
                    <div className="flex justify-between w-full">
                      <p>{list.name_medicine}</p>
                      <p>{list.unit}</p>
                    </div>
                    <div className="flex justify-between w-full">
                      <p>
                        Rp. {list.price} x {list.quantity}
                      </p>
                      <p>
                        {(list.price * list.quantity).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </p>
                    </div>
                  </div>
                ))}
              <div className="border-dashed border-2 w-full my-3"></div>
              {invoice.detail_payment &&
                invoice.detail_payment.map((payment, index) => (
                  <div
                    className="flex justify-between w-full font-semibold"
                    key={index}
                  >
                    <p>{payment.payment_method}</p>
                    <p>
                      {payment.amount.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                ))}
              <div className="border-dashed border-2 w-full my-3"></div>
              {invoice.subtotal !== null && (
                <>
                  <div className="flex justify-between w-full font-semibold">
                    <p>Subtotal</p>
                    <p>
                      {invoice.subtotal.subtotal.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                  <div className="flex justify-between w-full font-semibold">
                    <p>Total</p>
                    <p>
                      {" "}
                      {invoice.subtotal.total.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
          <p className="mt-3">{invoice.detail_invoice.footer}</p>
          <div className="flex justify-center">
            <div className="mr-3">
              <AiFillInstagram className="text-[30px]" />
              <AiFillFacebook className="text-[30px]" />
              <AiOutlineTwitter className="text-[30px]" />
            </div>
            <div>
              <p>{invoice.detail_invoice.social_media_instagram}</p>
              <p>{invoice.detail_invoice.social_media_facebook}</p>
              <p>{invoice.detail_invoice.social_media_twitter}</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceTransaction;
