import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsExclamationTriangle } from "react-icons/bs";
import LoadingBackdrop from "./LoadingBackdrop";

const FinishOrderPurchaseModal = (props) => {
  const {
    finishedOrder,
    setFinishedOrder,
    mutateTransactionDone,
    errorMessage,
    setErrorMessage,
    isMutateTransactionDoneLoading,
  } = props;

  const button = `rounded-md p-2 text-white ${
    errorMessage.status ? "bg-red-500" : "bg-[#007AF1]"
  }`;

  return (
    <Dialog
      open={finishedOrder.open}
      onClose={() => setFinishedOrder({ ...finishedOrder, open: false })}
      fullWidth
      maxWidth="xs"
    >
      {isMutateTransactionDoneLoading ? (
        <LoadingBackdrop isLoading={isMutateTransactionDoneLoading} />
      ) : (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="bg-gray-50 rounded-full p-2">
              {errorMessage.status ? (
                <BsExclamationTriangle className="rounded-full bg-red-100 text-red-500 p-2 text-[40px]" />
              ) : (
                <AiOutlineQuestionCircle className="rounded-full bg-blue-100 text-blue-500 p-2 text-[40px]" />
              )}
            </div>
            <button
              onClick={() => {
                setFinishedOrder({ ...finishedOrder, open: false });
                setErrorMessage({
                  ...errorMessage,
                  title: "",
                  content: "",
                  status: false,
                });
              }}
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            {errorMessage.status ? (
              <div className="mb-3">
                <p className="font-bold">{errorMessage.title}</p>
                <p className="font-medium">{errorMessage.content}</p>
              </div>
            ) : (
              <div className="mb-3">
                <p className="font-bold">Konfirmasi Pesanan</p>
                <p className="font-medium">
                  Pastikan Barang yang Anda terima sesuai, Anda tidak bisa
                  melakukan komplain setelah konfirmasi
                </p>
              </div>
            )}
            <div className="flex font-bold justify-center w-full">
              <button
                className="border border-black rounded-md p-2 mr-2 px-4"
                onClick={() => {
                  setFinishedOrder({ ...finishedOrder, open: false });
                  setErrorMessage({
                    ...errorMessage,
                    title: "",
                    content: "",
                    status: false,
                  });
                }}
              >
                Batal
              </button>
              <button
                className={button}
                onClick={() =>
                  mutateTransactionDone({
                    invoice: finishedOrder.invoice,
                  })
                }
              >
                {errorMessage.status ? "Coba Lagi" : "Konfirmasi"}
              </button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FinishOrderPurchaseModal;
