import React from "react";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import InvoiceDetail from "../../components/screen/billing/InvoiceDetail";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const DetailBillingInvoicePage = () => {
  return (
    <div className="">
      <div className="flex">
        <div
          className={`w-0 lg:w-[${SIDEBAR_WIDTH}px] h-screen sticky top-0 z-30`}
        >
          <SidebarComponents />
        </div>
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Detail Pesanan
          </h1>
          <InvoiceDetail />
        </div>
      </div>
    </div>
  );
};

export default HOC(DetailBillingInvoicePage);
