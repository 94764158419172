import React, { useRef, useEffect, useContext } from "react";
import ReactToPrint from "react-to-print";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";

import { fetchPrintSP } from "../../../../services/InventoryAPI";
import { HabisListContext } from "../../../context/ProductHabisProvider";

const PrintCreatePurchase = () => {
  const componentRef = useRef();
  const { printSP } = useContext(HabisListContext);

  const { mutate: mutatePrintCetakSP, data: cetakSPData } = useMutation({
    mutationFn: fetchPrintSP,
  });

  useEffect(() => {
    mutatePrintCetakSP(printSP);
  }, [printSP]);

  return (
    <div className="container mx-auto mt-16 mb-5 lg:mt-0">
      {cetakSPData && cetakSPData !== undefined ? (
        <>
          <div className="flex justify-between mt-3">
            <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
              Surat Pesanan
            </h1>
            <ReactToPrint
              trigger={() => (
                <button className="p-2 lg:p-[12px] bg-blue-500 mb-3 px-4 lg:px-5 font-medium text-sm lg:text-base text-white rounded-xl hover:bg-blue-300">
                  Download
                </button>
              )}
              content={() => componentRef.current}
              documentTitle={`OLIN_Surat_Pesanan_${moment().format(
                "DD-MMM-YYYY HH:mm:ss"
              )}`}
            />
          </div>

          <div className="p-5 border text-xs">
            <div className="p-5" ref={componentRef}>
              <h2 className="font-bold text-[10px] lg:text-[20px]">
                {cetakSPData.nama_apotek}
              </h2>
              <p>{cetakSPData.alamat_apotek}</p>
              <p>{cetakSPData.no_telp_apotek}</p>

              {/* body surat */}
              <div className="mt-2 text-xs">
                <p className="text-center font-medium text-[15px] lg:text-[20px]">
                  {cetakSPData.nama_surat}
                </p>
                <p className="text-center mt-1">
                  {cetakSPData.no_surat_return}
                </p>
                <hr />
              </div>

              {/* Isi surat */}
              <div className="flex flex-col mt-3 gap-1 text-xs">
                <p className="my-2">Yang bertanda tangan dibawah ini</p>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Nama
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{cetakSPData.nama}</p> <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    Jabatan
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{cetakSPData.jabatan}</p>
                  <br />
                </div>
                <div className="flex flex-row">
                  <span className="w-[100px] pr-[0] mr-[0] font-bold">
                    No SIPA
                  </span>
                  <p className="mx-4">:&nbsp;&nbsp;{cetakSPData.no_sipa}</p>
                  <br />
                </div>
              </div>
              {cetakSPData.nama_surat?.includes("PSIKOTROPIKA") ||
              cetakSPData.nama_surat?.includes("NARKOTIKA") ? (
                <div className="flex flex-col mt-3 gap-1 text-xs">
                  <p className="my-2">
                    {cetakSPData.nama_surat?.includes("NARKOTIKA")
                      ? "Mengajukan pesanan Narkotika kepada :"
                      : null}
                    {cetakSPData.nama_surat.includes("PSIKOTROPIKA")
                      ? "Mengajukan pesanan Psikotropika kepada :"
                      : null}
                  </p>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      Nama Distributor
                    </span>
                    <p className="mx-4">:</p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      Alamat
                    </span>
                    <p className="mx-4">:</p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[110px] pr-[0] mr-[0] font-bold">
                      No Telp
                    </span>
                    <p className="mx-4">:</p>
                    <br />
                  </div>
                </div>
              ) : null}
              {cetakSPData.nama_surat?.includes("NARKOTIKA") && (
                <p className="mt-3">
                  Mengajukan Pesanan Obat dengan daftar obat yang dipesan
                  sebagai berikut:
                </p>
              )}
              {cetakSPData.nama_surat?.includes("PSIKOTROPIKA") && (
                <p className="mt-3">
                  Mengajukan Pesanan Obat dengan daftar obat yang dipesan
                  sebagai berikut:
                </p>
              )}
              <div className="px-0 lg:px-5 text-xs">
                {cetakSPData.nama_surat?.includes("PSIKOTROPIKA") ||
                cetakSPData.nama_surat?.includes("NARKOTIKA") ? (
                  <div className="overflow-x-auto p-4">
                    <table className="table w-full">
                      <thead>
                        <tr className="bg-blue-500 text-white text-center">
                          <th className="border-collapse border border-black">
                            No
                          </th>
                          <th className="border-collapse border border-black">
                            Nama Obat
                          </th>
                          <th className="border-collapse border border-black">
                            Bentuk Sediaan
                          </th>
                          <th className="border-collapse border border-black">
                            Satuan
                          </th>
                          <th className="border-collapse border border-black">
                            Qty
                          </th>
                          <th className="border-collapse border border-black">
                            Prinsipal
                          </th>
                        </tr>
                      </thead>
                      {cetakSPData.product?.map((item, index) => {
                        return (
                          <tbody key={index} className="text-center">
                            <tr>
                              <th className="border-collapse border border-black">
                                {index + 1}
                              </th>
                              <td className="border-collapse border border-black">
                                {item.nama_obat}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.bentuk_sediaan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.satuan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.quantity}
                              </td>
                              <td className="border-collapse border border-black">
                                {cetakSPData.nama_industri}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className="overflow-x-auto p-4">
                    <table className="table w-full border">
                      <thead>
                        <tr className="bg-blue-500 text-white text-center">
                          <th className="border-collapse border border-black">
                            No
                          </th>
                          <th className="border-collapse border border-black">
                            Nama Obat
                          </th>
                          <th className="border-collapse border border-black">
                            Satuan
                          </th>
                          <th className="border-collapse border border-black">
                            Qty
                          </th>
                          <th className="border-collapse border border-black">
                            Prinsipal
                          </th>
                        </tr>
                      </thead>
                      {cetakSPData.product?.map((item, index) => {
                        return (
                          <tbody key={index} className="text-center">
                            <tr>
                              <th className="border-collapse border border-black">
                                {index + 1}
                              </th>
                              <td className="border-collapse border border-black">
                                {item.nama_obat}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.satuan}
                              </td>
                              <td className="border-collapse border border-black">
                                {item.quantity}
                              </td>
                              <td className="border-collapse border border-black">
                                {cetakSPData.nama_industri}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                )}
                {/* penutup */}
                <div className="flex flex-col gap-1">
                  <p className="">
                    Pesanan obat tersebut akan digunakan untuk memenuhi
                    kebutuhan:
                  </p>
                  <div className="flex flex-row mt-2">
                    <span className="w-[100px] pr-[0] mr-[0] font-bold">
                      Nama Sarana
                    </span>
                    <p className="mx-4">
                      :&nbsp;&nbsp;{cetakSPData.nama_apotek}
                    </p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[100px] pr-[0] mr-[0] font-bold">
                      Alamat Sarana
                    </span>
                    <p className="mx-4">
                      :&nbsp;&nbsp;{cetakSPData.alamat_apotek}
                    </p>
                    <br />
                  </div>
                  <div className="flex flex-row">
                    <span className="w-[100px] pr-[0] mr-[0] font-bold">
                      SIA Sarana
                    </span>
                    <p className="mx-4">
                      :&nbsp;&nbsp;{cetakSPData.no_sia_apotek}
                    </p>
                    <br />
                  </div>
                </div>

                {/* tanda tangan */}
                <div className="flex flex-col items-end">
                  <div className="flex mt-5 flex-col justify-end text-center px-5 items-center">
                    <div className="p-4">
                      <p>{cetakSPData.tanggal_pengajuan}</p>
                      <p>Diserahkan Oleh: </p>
                    </div>
                    <div className="mt-5">
                      <p>{cetakSPData.nama}</p>
                      <p>{cetakSPData.no_sipa}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>tidak ditemukan!</p>
      )}
    </div>
  );
};

export default PrintCreatePurchase;
