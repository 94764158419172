import React from "react";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import DetailDiscountComponents from "../../components/screen/discount/DetailDiscount";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";
const DetailDiscount = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <DetailDiscountComponents />
        </div>
      </div>
    </div>
  );
};

export default HOC(DetailDiscount);
