import React, { useState, createContext } from "react";

export const ExpiredListContext = createContext();

const ProductExpiredProvider = ({ children }) => {
  const [printRetur, setPrintRetur] = useState({});

  return (
    <ExpiredListContext.Provider
      value={{ printRetur, setPrintRetur }}
    >
      {children}
    </ExpiredListContext.Provider>
  );
};

export default ProductExpiredProvider;
