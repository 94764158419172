import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
} from "@mui/material";
import { FiBell, FiShoppingBag } from "react-icons/fi";
import { MdDateRange } from "react-icons/md";
import { BiFilterAlt } from "react-icons/bi";
import { FaPiggyBank } from "react-icons/fa";
import { FaCashRegister, FaRegListAlt } from "react-icons/fa";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  fetchHistoryTransaction,
  getPaymentDashboard,
  getTransactionDetail,
} from "../../../services/dashboardApi";
import {
  dates,
  transactionDetailStatus,
  transactionDetailStatusColor,
} from "../../constants/constant";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("YYYY-MM-DD");

const HistoryTransactionDashboard = () => {
  const navigate = useNavigate();
  const name = localStorage.getItem("name");

  const [chosenDate, setChosenDate] = useState(1);
  const [bankName, setBankName] = useState("Metode Pembayaran");
  const [offset, setOffset] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorElBank, setAnchorElBank] = useState(null);
  const [arr, setArr] = useState([]);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [dateName, setDateName] = useState("");
  const [transactionId, setTransactionId] = useState(0);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);

  const { mutate: mutateFetchTransaction, data: historyTransaction } =
    useMutation({
      mutationFn: fetchHistoryTransaction,
    });

  const { data: transactionDetail } = useQuery({
    queryKey: ["transaction-detail", transactionId],
    queryFn: () => getTransactionDetail(transactionId),
    enabled: transactionId !== 0,
  });

  const { data: paymentBank } = useQuery({
    queryKey: ["payment-filter"],
    queryFn: () => getPaymentDashboard(),
  });

  const handleDate = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  useEffect(() => {
    if (bankName === "Metode Pembayaran" || bankName === "") {
      mutateFetchTransaction({
        param: chosenDate,
        offset,
        limit: FETCH_LIMIT,
        start_date:
          toDate(startDate) === "Invalid date" ? "" : toDate(startDate),
        end_date: toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
      });
    } else {
      mutateFetchTransaction({
        param: chosenDate,
        offset,
        limit: FETCH_LIMIT,
        bank_name: bankName,
        start_date:
          toDate(startDate) === "Invalid date" ? "" : toDate(startDate),
        end_date: toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
      });
    }
  }, [chosenDate, offset, bankName]);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <div className="flex flex-wrap justify-between items-center">
        <div className="flex flex-col text-[14px] lg:text-base">
          <p className="font-semibold">
            Hi, <span className="font-semibold"> {name}</span>
          </p>
          <p>Welcome to OLIN dashboard</p>
        </div>
        <FiBell className="text-[18px] lg:text-[30px] hover:bg-sky-200 hover:rounded-full p-1" />
      </div>
      <div className="flex justify-end my-3">
        <div
          className="hover:cursor-pointer border border-black rounded-md flex items-center p-2 w-[25%] flex-wrap"
          onClick={(e) => setAnchorElBank(e.currentTarget)}
        >
          <BiFilterAlt className="mr-2 text-[30px]" />
          <p>{bankName === "" ? "Semua Pembayaran" : bankName}</p>
        </div>
        <div
          className="hover:cursor-pointer border border-black rounded-md flex items-center p-2 w-[20%] flex-wrap mx-3"
          onClick={(e) => setAnchorElDate(e.currentTarget)}
        >
          <MdDateRange className="mr-2 text-[30px]" />
          <p>{dateName === "" ? "Ubah Tanggal" : dateName}</p>
        </div>
        <button
          className="text-white bg-[#007AF1] p-2 rounded-md font-bold"
          onClick={() => navigate("/detail-transaction")}
        >
          Lihat Rincian
        </button>
      </div>
      {historyTransaction && historyTransaction !== undefined ? (
        historyTransaction.map((transaction, index) => (
          <div
            className="border border-black rounded-md p-3 mb-3 flex items-center w-full"
            key={index}
          >
            <div>
              {transaction.transaction_from === "Purchase" && (
                <FiShoppingBag className="text-[70px] mr-3" />
              )}
              {transaction.transaction_from === "Point Of Sales" && (
                <FaCashRegister className="text-[70px] mr-3" />
              )}
              {transaction.transaction_from === "Order Management" && (
                <FaRegListAlt className="text-[70px] mr-3" />
              )}
              {transaction.transaction_from === "Disbursement" && (
                <FaPiggyBank className="text-[70px] mr-3" />
              )}
            </div>
            <div className="flex justify-between items-center w-full">
              <div>
                {transaction.transaction_from === "Disbursement" ? (
                  <p
                    className="text-[#007AF1] hover:underline hover:cursor-pointer"
                    onClick={() => {
                      setTransactionId(transaction.id);
                      setIsTransactionModalOpen(true);
                    }}
                  >
                    {transaction.transaction_from}
                  </p>
                ) : (
                  <p>{transaction.transaction_from}</p>
                )}
                {transaction.transaction_from === "Disbursement" ? (
                  <p>{transaction.transaction_id}</p>
                ) : (
                  <p
                    className="text-[#007AF1] hover:underline hover:cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/invoice-transaction/${transaction.transaction_id}`
                      )
                    }
                  >
                    {transaction.transaction_id}
                  </p>
                )}
                <p>{transaction.transaction_date}</p>
                <p>{transaction.payment_method}</p>
              </div>
              <p className="font-bold">
                {transaction.transaction_total.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="flex mt-10 justify-center w-full">
          <p>Sejarah transaksi kosong</p>
        </div>
      )}
      <Menu
        anchorEl={anchorElBank}
        open={Boolean(anchorElBank)}
        onClose={() => setAnchorElBank(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setBankName("");
            setAnchorElBank(null);
          }}
        >
          Semua Pembayaran
        </MenuItem>
        {paymentBank && paymentBank !== undefined ? (
          paymentBank.map((payment, index) => (
            <MenuItem
              onClick={() => {
                setBankName(payment.Name);
                setAnchorElBank(null);
              }}
              key={index}
            >
              {payment.Name}
            </MenuItem>
          ))
        ) : (
          <MenuItem></MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={anchorElDate}
        open={Boolean(anchorElDate)}
        onClose={() => setAnchorElDate(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {dates.map((date, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setChosenDate(date.value);
              setStartDate("");
              setEndDate("");
              setDateName(date.name);
              setAnchorElDate(null);
            }}
          >
            {date.name}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setIsModalOpen(true);
            setAnchorElDate(null);
            setChosenDate(10);
          }}
        >
          Pilih Tanggal
        </MenuItem>
      </Menu>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <DatePicker
            className="border-2 border rounded-md p-2"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDate}
            inline
          />
          <button
            className="bg-red-500 text-white rounded-md p-2"
            onClick={() => {
              if (bankName === "Metode Pembayaran" || bankName === "") {
                mutateFetchTransaction({
                  param: chosenDate,
                  offset,
                  limit: FETCH_LIMIT,
                  start_date:
                    toDate(startDate) === "Invalid date"
                      ? ""
                      : toDate(startDate),
                  end_date:
                    toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
                });
              } else {
                mutateFetchTransaction({
                  param: chosenDate,
                  offset,
                  limit: FETCH_LIMIT,
                  bank_name: bankName,
                  start_date:
                    toDate(startDate) === "Invalid date"
                      ? ""
                      : toDate(startDate),
                  end_date:
                    toDate(endDate) === "Invalid date" ? "" : toDate(endDate),
                });
              }
              setIsModalOpen(false);
              setDateName(toDate(startDate) + " - " + toDate(endDate));
            }}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isTransactionModalOpen}
        onClose={() => setIsTransactionModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Detail Disbursement</DialogTitle>
        <DialogContent>
          {transactionDetail && transactionDetail !== undefined ? (
            <table className="w-full border-2 border-black">
              <tr>
                <th className="p-3 text-left border-r-2 w-[20%] bg-blue-500 text-white">
                  Transaction ID
                </th>
                <td className="p-3 text-left border-l-2">
                  {transactionDetail.transaction_id}
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left border-r-2 w-[20%] bg-blue-500 text-white">
                  Fee
                </th>
                <td className="p-3 text-left border-l-2">
                  {transactionDetail.fee.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left border-r-2 w-[20%] bg-blue-500 text-white">
                  Amount
                </th>
                <td className="p-3 text-left border-l-2">
                  {transactionDetail.amount.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </td>
              </tr>

              <tr>
                <th className="p-3 text-left border-r-2 w-[20%] bg-blue-500 text-white">
                  Status
                </th>
                <td
                  className="p-3 text-left border-l-2 font-bold"
                  style={{
                    color:
                      transactionDetailStatusColor[transactionDetail.status],
                  }}
                >
                  {transactionDetailStatus[transactionDetail.status]}
                </td>
              </tr>
            </table>
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HistoryTransactionDashboard;
