import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { paymentConsignment } from "../../../services/consignmentApi";

export default function ModalPaymentConsignment({
  showModal,
  setShowModal,
  toPay,
  productName,
  returnNum,
  initialStock,
  sold,
  prodId,
  setReload,
  currentStock,
}) {
  const [payment, setPayment] = useState(0);

  const handlePayment = () => {
    const body = {
      product_id: Number(prodId),
      payment: payment,
      description: "Payment konsinyasi",
    };
    mutatePaymentConsignment(body);
  };

  const { mutate: mutatePaymentConsignment } = useMutation({
    mutationFn: paymentConsignment,
    onSuccess: () => {
      setShowModal(false);
      setReload(true);
    },
  });

  useEffect(() => {
    if (showModal) {
      setPayment(0);
    }
  }, [showModal]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[80%] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="h-[80vh] overflow-y-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="absolute top-12 w-[calc(100%-48px)] font-bold text-lg text-center -z-2">
                    Bayar Konsinyasi
                  </p>
                  <div
                    className="w-full flex justify-end cursor-pointer hover:text-gray-200"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <Close className="" />
                  </div>
                  <div className="mt-12 mb-4 block overflow-x-auto rounded-md">
                    <table className="w-full">
                      <thead className="bg-[#007AF1] text-white text-center">
                        <tr>
                          <th className="p-2 border-2 border-[#5B5B5B]">
                            Nama Produk
                          </th>
                          <th className="p-2 border-2 border-[#5B5B5B]">
                            Return
                          </th>
                          <th className="p-2 border-2 border-[#5B5B5B]">
                            Terjual
                          </th>
                          <th className="p-2 border-2 border-[#5B5B5B] rounded-tr-md">
                            Jumlah Awal
                          </th>
                          <th className="p-2 border-2 border-[#5B5B5B] rounded-tr-md">
                            Stock Saat Ini
                          </th>
                          <th className="p-2 border-2 border-[#5B5B5B] rounded-tr-md">
                            Kekurangan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {productName}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {returnNum}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {sold}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {initialStock}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {currentStock}
                          </td>
                          <td className="p-2 border-2 border-[#5B5B5B] text-center">
                            {toPay
                              .toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })
                              .replace(",", ".")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr className="my-4" />
                  <div className="w-full">
                    <TextField
                      value={payment}
                      onChange={(e) =>
                        Number(e.target.value) < 0
                          ? setPayment(0)
                          : Number(e.target.value) > toPay - payment
                          ? setPayment(toPay)
                          : setPayment(Number(e.target.value))
                      }
                      type="number"
                      label="Input Pembayaran"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp</InputAdornment>
                        ),
                      }}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      value={(toPay - payment)
                        .toLocaleString()
                        .replace(",", ".")}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp</InputAdornment>
                        ),
                      }}
                      disabled
                      label="Kekurangan"
                      fullWidth
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center p-6 gap-1 md:gap-3 rounded-b w-full">
                  <div className="w-full">
                    <button
                      className="w-full border border-[#5B5B5B] hover:bg-slate-100 font-bold uppercase text-sm py-2 px-4 md:px-12 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Batal
                    </button>
                  </div>
                  <div className="w-full">
                    <button
                      className="w-full bg-blue-500 text-white hover:bg-blue-700 font-bold uppercase text-sm py-2 px-4 md:px-12 rounded-lg shadow hover:shadow-lg outline-none focus:outline-noneease-linear transition-all duration-150 disabled:bg-gray-400"
                      type="button"
                      onClick={handlePayment}
                      disabled={payment === 0}
                    >
                      Submit Pembayaran
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
