import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { CiMail, CiLocationOn } from "react-icons/ci";

import olinMascot from "../../../assets/images/logo192.png";

const HelpInformationModal = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ textAlign: "center", display: "flex" }}>
        <button onClick={() => setIsModalOpen(false)}>
          <AiOutlineArrowLeft className="flex text-center items-center text-[30px]" />
        </button>
        <p className="text-center w-full">Bantuan</p>
      </DialogTitle>
      <DialogContent>
        <div className="flex bg-[#EAF3FE] font-medium p-3">
          <img src={olinMascot} />
          <div className="flex justify-center items-center p-3">
            <div className="rounded-md bg-white p-3">
              <p>
                Hubungi Kami untuk informasi / bantuan pada kontak berikut:{" "}
              </p>
              <div className="flex items-center mt-2">
                <BsTelephone className="text-white text-[40px] bg-[#06D6A0] rounded-full px-2 py-1 mr-3" />
                <p>+62 27085961</p>
              </div>
              <div className="flex items-center mt-2">
                <CiMail className="text-white text-[40px] bg-[#007AF1] rounded-full px-2 py-1 mr-3" />
                <p>Info@pharmaniaga.com</p>
              </div>
              <div className="flex mt-2">
                <CiLocationOn className="text-white text-[40px] bg-[#FDCD78] rounded-full px-2 py-1 mr-3" />
                <div>
                  <p>Millenium Pharmacon International Tbk</p>
                  <p>Jl. Sultan Isakndar Muda no. 18</p>
                  <p>Crown Bungur Arteri Lantai 2-4</p>
                  <p>
                    Kel. Kebayoran Lama Selatan, Kec, Kebayoran Lama, Jakarta
                    Selatan 12220
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HelpInformationModal;
