import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  getConsignmentDetailBatch,
  getConsignmentDetailProduct,
} from "../../../../services/consignmentApi";
import PriceContainer from "../../../common/particles.jsx/PriceContainer";
import DetailStockConsignment from "../../../common/particles.jsx/DetailStockConsignment";
import { IGNORE_TYPE } from "../../../constants/constant";

const DetailConsignmentProduct = () => {
  const { params } = useParams();
  const [displayImage, setDisplayImage] = useState("");
  const [imgArr, setImgArr] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const invoiceNum = atob(params.split(":")[0]);
  const id = atob(params.split(":")[1]);

  const primaryStock = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return (
        primaryCount(stock, item) + " " + item.packing.type_strip.toUpperCase()
      );
    } else {
      return (
        primaryCount(stock, item) + " " + item.packing.type_box.toUpperCase()
      );
    }
  };

  const primaryCount = (stock, item) => {
    if (IGNORE_TYPE.includes(item.packing.type_unit)) {
      return Math.floor(stock / item.packing.total_strip);
    } else {
      return Math.floor(
        stock / (item.packing.total_strip * item.packing.total_unit)
      );
    }
  };

  const handleChangeImg = (chosenImg) => {
    var temp = [...data.media];
    var chosenIndex = data.media.indexOf(chosenImg);

    temp.splice(chosenIndex, 1);
    setImgArr(temp);
    setDisplayImage(chosenImg);
  };

  const { data } = useQuery({
    queryFn: () => getConsignmentDetailProduct(id, invoiceNum),
  });

  const { data: batchData, isFetching } = useQuery({
    queryKey: ["detail-batch-consignment", reload],
    queryFn: () => getConsignmentDetailBatch(id),
  });

  useEffect(() => {
    if (data && data.media !== null && data.media.length > 0) {
      setDisplayImage(data.media[0]);
      setImgArr(data.media.slice(1));
    }
  }, [data]);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-row justify-end lg:justify-end mt-10 p-3 gap-3">
          <button className="hover:bg-blue-700 bg-blue-500 hover:bg-grey-200 py-2 px-4 rounded-lg inline-flex items-center gap-3 font-medium text-white">
            <Link
              to={`/inventory/consignment/update-price/${params}`}
              className="hover:text-white"
            >
              Ubah Harga
            </Link>
          </button>
          <button className="hover:bg-orange-700 bg-orange-500 hover:bg-grey-200 py-2 px-[28px] rounded-lg inline-flex items-center gap-3 font-medium text-white">
            <Link
              to={`/inventory/consignment/edit-product-details/${params}`}
              className="hover:text-white"
            >
              Edit Information
            </Link>
          </button>
        </div>
        <h1 className="bg-blue-500 text-white text-center py-2 font-medium my-4">
          Produk Aktif
        </h1>
      </div>
      <Box sx={{ padding: 2, borderRadius: 3, boxShadow: 4 }}>
        {data && data !== null ? (
          <div className="flex w-full ">
            {displayImage !== "" ? (
              <div className="w-full basis-1/4 mr-4">
                <img
                  src={displayImage}
                  className="w-full border-2 border-gray-300 rounded-md h-[15vw] object-contain p-2"
                />
                <div
                  className={`flex gap-[3%] w-full mt-2 ${
                    imgArr.length === 3 ? "justify-center" : "justify-start"
                  }`}
                >
                  {imgArr.map((item) => (
                    <>
                      <img
                        src={item}
                        className="w-[30%] border-2 border-gray-300 rounded-md cursor-pointer object-cover"
                        onClick={() => handleChangeImg(item)}
                      />
                    </>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div className="flex flex-col w-full">
                <div className="flex flex-wrap w-full justify-center lg:justify-start mt-10 gap-3">
                  <div className="flex flex-col gap-4 items-center lg:items-start p-4">
                    <p className="font-bold text-[20px]">{data.product_name}</p>
                    <div className="flex gap-2">
                      <p className="font-medium mr-2">
                        Jumlah Produk{" "}
                        <span className="font-medium">
                          {primaryStock(data.stock, data)}
                        </span>
                      </p>
                      <p
                        className="ml-1 text-[#007AF1] hover:cursor-pointer font-semibold"
                        onClick={() => setShowModal(true)}
                      >
                        Detail Stock
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                      <p className="font-semibold">Label Produk</p>
                      <p className="bg-blue-700 text-white px-3 py-[6px] rounded-xl">
                        {data.label_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="items-end font-bold text-[30px] p-5">
                {data.price?.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
          </div>
        ) : data && data === null ? (
          <p className="text-red-500">Tidak ada data</p>
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>

      {data && data !== null ? (
        <>
          <div className="my-4">
            <PriceContainer
              title="Harga Jual"
              titleArr={["HNA", "Primer", "Sekunder", "Tersier"]}
              dataArr={[
                data.hna,
                data.price,
                data.price_sekunder,
                data.price_tersier,
              ]}
            />
            <PriceContainer
              title="Harga Grosir"
              titleArr={
                data.price_grosir === null ||
                (data.price_grosir.length === 1 &&
                  data.price_grosir[0].minimum === 0 &&
                  data.price_grosir[0].maximum === 0)
                  ? []
                  : [
                      ...data.price_grosir.map(
                        (item) => item.minimum + " s/d " + item.maximum
                      ),
                    ]
              }
              dataArr={
                data.price_grosir === null
                  ? []
                  : [...data.price_grosir.map((item) => item.price_grosirs)]
              }
            />
            <PriceContainer
              title="Harga Jual Platform"
              titleArr={
                data.platform_price === null ||
                (data.platform_price.length === 1 &&
                  data.platform_price[0].price === 0 &&
                  data.platform_price[0].name === "")
                  ? []
                  : [...data.platform_price.map((item) => item.name)]
              }
              dataArr={
                data.platform_price === null
                  ? []
                  : [...data.platform_price.map((item) => item.price)]
              }
            />

            <div className="my-4">
              <p className="text-2xl">Deskripsi Produk</p>
              <p className="font-bold mt-4">Indikasi/Kegunaan</p>
              <p>
                {data.description.indication === ""
                  ? "-"
                  : data.description.indication}
              </p>
              <p className="font-bold mt-4">Kandungan/Komposisi</p>
              <p>
                {data.description.composition === ""
                  ? "-"
                  : data.description.composition}
              </p>
              <p className="font-bold mt-4">Dosis</p>
              <p>
                {data.description.dosage === "" ? "-" : data.description.dosage}
              </p>
              <p className="font-bold mt-4">Cara Pemakaian</p>
              <p>
                {data.description.usage === "" ? "-" : data.description.usage}
              </p>
              <p className="font-bold mt-4">Kemasan</p>
              <p>
                {data.description.packing === null
                  ? "-"
                  : data.packing.total_box +
                    " " +
                    data.packing.type_box +
                    " @ " +
                    data.packing.total_strip +
                    " " +
                    data.packing.type_strip +
                    " @ " +
                    data.packing.total_unit +
                    " " +
                    data.packing.type_unit}
              </p>
              <p className="font-bold mt-4">Golongan</p>
              <p>{data.label_name === "" ? "-" : data.label_name}</p>
              <p className="font-bold mt-4">Perlu Resep</p>
              <p>{data.description.require_prescription ? "Ya" : "Tidak"}</p>
              <p className="font-bold mt-4">
                Kontraindikasi/Jangan Digunakan Oleh
              </p>
              <p>
                {data.description.contraindication === ""
                  ? "-"
                  : data.description.contraindication}
              </p>
              <p className="font-bold mt-4">Efek Samping</p>
              <p>
                {data.description.side_effect === ""
                  ? "-"
                  : data.description.side_effect}
              </p>
              <p className="font-bold mt-4">Cara Penyimpanan</p>
              <p>
                {data.description.storage_description === ""
                  ? "-"
                  : data.description.storage_description}
              </p>
              <p className="font-bold mt-4">Principal</p>
              <p>
                {data.product_principal_name === ""
                  ? "-"
                  : data.product_principal_name}
              </p>
              <p className="font-bold mt-4">Nomor Izin Edar (NIE)</p>
              <p>{data.nie_number === "" ? "-" : data.nie_number}</p>
              <p className="font-bold mt-4">Nomor Barcode</p>
              <p>{data.barcode === "" ? "-" : data.barcode}</p>
            </div>
          </div>
          {batchData ? (
            <DetailStockConsignment
              setReload={setReload}
              showModal={showModal}
              isFetching={isFetching}
              setShowModal={setShowModal}
              stockArr={batchData}
              id={id}
            />
          ) : null}
        </>
      ) : data && data === null ? (
        <p className="text-red-500">Tidak ada data</p>
      ) : (
        <CircularProgress size={20} />
      )}
    </div>
  );
};

export default DetailConsignmentProduct;
