import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Autocomplete,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { FaSort, FaSortUp } from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import moment from "moment";
import { usePapaParse } from "react-papaparse";

import {
  fetchAllPrincipal,
  fetchTableReportingInventory,
  getReportInventory,
  getHistoryLog,
  getReportingNew,
} from "../../../../services/ReportingApi";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const FETCH_LIMIT = 10;

const toDate = (date) => moment(date).format("DD-MMM-YYYY");

const InventoryReporting = () => {
  const { jsonToCSV } = usePapaParse();
  const [offset, setOffset] = useState(1);
  const [offsetHistoryLog, setOffsetHistoryLog] = useState(1);
  const [principal, setPrincipal] = useState({
    id: 0,
    name: "Semua Principal",
  });
  const [principals, setPrincipals] = useState([]);
  const [principalInventoryData, setPrincipalInventoryData] = useState([]);
  const [rows, setRows] = useState([]);
  const [csv, setCsv] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    column: ["quantity", "total"],
    order: "asc",
  });
  const [search, setSearch] = useState("");

  const { data: penjualan, mutate: mutatePenjualan } = useMutation({
    mutationFn: fetchTableReportingInventory,
  });

  const localOption = [{ name: "Semua Principal", id: 0 }];

  const { mutate: mutatePrincipalData } = useMutation({
    mutationFn: fetchAllPrincipal,
    onSuccess: (data) => {
      setPrincipalInventoryData(data !== null ? [...localOption, ...data] : []);
    },
  });

  const { data: inventoryReporting } = useQuery({
    queryFn: () => getReportInventory(principal !== null ? principal.id : 0),
    queryKey: ["reporting-inventory", principal],
  });

  const { data: historyLogData } = useQuery({
    queryKey: ["history-log", offsetHistoryLog],
    queryFn: () => getHistoryLog(FETCH_LIMIT, offsetHistoryLog),
  });

  const { data: reportingNewData } = useQuery({
    queryKey: ["reporting-price"],
    queryFn: () => getReportingNew(principal.id),
  });

  const handleJsonToCsvExisting = (csvExisting) => {
    const csv = jsonToCSV(csvExisting);
    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "hasil_reporting_OLIN.csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const handleJsonToCsvExistingReport = (csvExisting) => {
    const csv = jsonToCSV(csvExisting?.data);
    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "hasil_reporting_OLIN.csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { refetch: exportCsvHistoryLog, isFetching: isCsvLogLoading } =
    useQuery({
      queryKey: ["history-log-csv"],
      queryFn: () => getHistoryLog(0, 0),
      enabled: false,
      onSuccess: (data) => handleJsonToCsvExisting(data),
    });

  const {
    mutate: mutateReportInventoryCsv,
    isLoading: isCsvReportingInventoryLoading,
  } = useMutation({
    mutationFn: fetchTableReportingInventory,
    onSuccess: (data) => {
      handleJsonToCsvExistingReport(data);
    },
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

  const pageCount = useMemo(() => {
    if (penjualan === undefined) return 0;
    else if (penjualan.count === undefined) return 0;
    else return Math.ceil(penjualan.count / FETCH_LIMIT);
  }, [penjualan]);

  const pageCountHistoryLog = useMemo(() => {
    if (historyLogData === undefined) return 0;
    else if (historyLogData.count === undefined) return 0;
    else return Math.ceil(historyLogData.count / FETCH_LIMIT);
  }, [historyLogData]);

  // const handleGetChart = async (currentPage = 1) => {
  //   const res = await getDataPrincipals(FETCH_LIMIT, currentPage, search);

  //   setPrincipals(res);
  // };

  // const handleGetPrincipal = async () => {
  //   const res = await getReportInventory(principalId);
  //   setPrincipal(res);
  // };

  const handleSort = (column) => {
    setLoading(true);

    setSortBy((prevSortBy) => {
      return {
        ...prevSortBy,
        column: [column],
        order: prevSortBy.order === "asc" ? "desc" : "asc",
      };
    });

    const newSortBy = {
      column: column,
      order: sortBy.order === "asc" ? "desc" : "asc",
    };
    setSortBy(newSortBy);
    const sortedRows = [...rows].sort((a, b) => {
      if (sortBy.order === "asc") {
        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
      } else {
        if (a[column] > b[column]) {
          return -1;
        }
        if (a[column] < b[column]) {
          return 1;
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const optionsSelect = [
    {
      id: 0,
      name: "Semua Principal",
    },
    ...principals,
  ];

  const handleDownload = () => {
    const canvas = document.getElementById("a");
    canvas.toBlob(function (blob) {
      saveAs(blob, "test.png");
    });
  };

  const allData = {
    labels:
      inventoryReporting && inventoryReporting !== undefined
        ? principal !== null && principal?.id === 0
          ? inventoryReporting.map((data) => data.principal_name)
          : inventoryReporting.map((data) => data.product_name)
        : [],
    datasets: [
      {
        label: "Stock",
        data:
          inventoryReporting && inventoryReporting !== undefined
            ? inventoryReporting.map((data) => data.stock)
            : [],
        backgroundColor: "lightgreen",
        borderColor: "lightgreen",
      },
    ],
  };

  const allOptions = {
    // responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  const datas = {
    labels: penjualan?.data?.map((item) => item.product_name),
    datasets: [
      {
        label: "Stock",
        data: penjualan?.data?.map((item) => item.quantity),
        backgroundColor: "lightgreen",
        borderColor: "lightgreen",
        // borderWidth: 1,
      },
    ],
  };

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 1,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  const headers = [
    { label: "Nama Obat", key: "product_name" },
    { label: "Stock", key: "quantity" },
    { label: "Principal", key: "principal_name" },
  ];

  const dataCSV =
    csv === null
      ? headers
      : csv.map((item) => {
          return {
            product_name: item.product_name,
            quantity: item.quantity,
            principal_name: item.principal_name,
          };
        });

  useEffect(() => {
    mutatePenjualan({
      limit: FETCH_LIMIT,
      offset,
      id: principal !== null ? principal.id : 0,
    });
  }, [offset, search, principal]);

  useEffect(() => {
    const timer = setTimeout(
      () =>
        mutatePrincipalData({ limit: FETCH_LIMIT, offset: 10, name: search }),
      500
    );
    return () => clearTimeout(timer);
  }, [search, offset]);

  return (
    <div className="container w-full">
      <LoadingBackdrop
        isLoading={isCsvReportingInventoryLoading || isCsvLogLoading}
      />

      <div className="mt-4 flex flex-col lg:flex-row justify-between items-center gap-3">
        <h1 className="font-bold text-center lg:text-start">Daftar Stock</h1>
        <button
          className="p-2 px-5 bg-blue-500 text-white rounded-lg"
          onClick={handleDownload}
        >
          Download Chart
        </button>
      </div>
      <Autocomplete
        className="mt-4"
        disablePortal
        disableClearable
        options={principalInventoryData}
        value={principal}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        fullWidth
        onChange={(_, inputValue) => {
          setPrincipal(inputValue);
        }}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === "input") {
            setSearch(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Pilih Principal" />
        )}
      />
      {reportingNewData && reportingNewData !== undefined ? (
        <div className="flex w-full">
          <div className="p-3 border rounded-lg mt-3 w-full">
            <p className="text-blue-500 font-bold">HNA</p>
            <hr className="my-3" />
            <p>
              {reportingNewData.hna.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
          <div className="p-3 border rounded-lg mt-3 w-full ml-2">
            <p className="text-blue-500 font-bold">Sell Price</p>
            <hr className="my-3" />
            <p>
              {reportingNewData.Sell_price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
        </div>
      ) : null}
      <h1 className="font-bold text-center mt-5">Daftar Stock</h1>
      <div style={{ width: "100%", height: "500px" }}>
        {/* <canvas ref={chartRef} id="a" className="block" /> */}
        <div className="w-full h-full">
          {/* {principalId === 0 ? (
            <Bar options={allOptions} data={allData} id="a" />
          ) : (
            <Bar options={options} data={datas} id="a" />
          )} */}
          <Bar options={allOptions} data={allData} id="a" />
        </div>
      </div>
      <div className="flex flex-row justify-between mt-5">
        <p className="font-bold" id="drop1">
          Laporan Penjualan
        </p>
        <button
          className="p-2 px-5 bg-blue-500 text-white rounded-lg"
          onClick={() =>
            mutateReportInventoryCsv({
              id: 0,
              limit: 1000000,
              offset: 1,
            })
          }
        >
          Export Table
        </button>
      </div>
      {penjualan && penjualan.data !== null ? (
        <>
          <TableContainer className="mt-5" component={Paper}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow className="cursor-pointer">
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Nama Obat
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                    onClick={() => handleSort("quantity")}
                  >
                    <span className="flex flex-row justify-center items-center gap-2">
                      Quantity
                      {sortBy.column.includes("quantity") &&
                      sortBy.order === "asc" ? (
                        <FaSortUp className="text-[10px] mt-1 font-semibold text-blue-500" />
                      ) : sortBy.column.includes("quantity") &&
                        sortBy.order === "desc" ? (
                        <FaSortUp className="rotate-180 text-[10px] mb-1 font-semibold text-blue-500" />
                      ) : (
                        <FaSort className="text-[10px] text-blue-500" />
                      )}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Principal
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penjualan && penjualan !== undefined
                  ? penjualan.data?.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {data.product_name}
                        </TableCell>
                        <TableCell align="center">{data.quantity}</TableCell>
                        <TableCell align="center">
                          {data.principal_name}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex justify-center mt-[10px]">
            <Pagination
              count={pageCount}
              page={offset}
              onChange={(_, value) => setOffset(value)}
              shape="rounded"
              size="large"
              color="primary"
            />
          </div>
        </>
      ) : (
        <p>Produk tidak ditemukan</p>
      )}
      {historyLogData && historyLogData !== undefined ? (
        <>
          <div className="flex flex-row justify-between mt-5">
            <p className="font-bold" id="drop1">
              History Log
            </p>
            <button
              className="p-2 px-5 bg-blue-500 text-white rounded-lg"
              onClick={() => exportCsvHistoryLog()}
            >
              Export Table
            </button>
          </div>
          <TableContainer className="mt-5" component={Paper}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#007AF1",
                }}
              >
                <TableRow className="cursor-pointer">
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                    onClick={() => handleSort("quantity")}
                  >
                    Nama Obat
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Initial Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Final Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyLogData.data !== null
                  ? historyLogData.data.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {toDate(data.date)}
                        </TableCell>
                        <TableCell align="center">
                          {data.product_name}
                        </TableCell>
                        <TableCell align="center">{data.action}</TableCell>
                        <TableCell align="center">
                          {data.initial_amount}
                        </TableCell>
                        <TableCell align="center">
                          {data.final_amount}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex justify-center mt-[10px]">
            <Pagination
              count={pageCountHistoryLog}
              page={offsetHistoryLog}
              onChange={(_, value) => setOffsetHistoryLog(value)}
              shape="rounded"
              size="large"
              color="primary"
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InventoryReporting;
