import React from "react";

const UserList = (props) => {
  const {
    item,
    statusCodeUser,
    statusUser,
    setUserEmail,
    setOpenDeactivateUserModal,
    setOpenActivateUserModal,
  } = props;

  return (
    <>
      {statusCodeUser === 1 && (
        <p className="p-2 bg-green-500 rounded-lg text-white font-semibold text-[14px]">
          {statusUser}
        </p>
      )}
      {statusCodeUser === 2 && (
        <p className="p-2 bg-[#D62839] rounded-lg text-white font-semibold text-[14px]">
          {statusUser}
        </p>
      )}
      {statusCodeUser === 3 && (
        <p className="p-2 bg-[#838383] rounded-lg text-white font-semibold">
          {statusUser}
        </p>
      )}
      {statusCodeUser === 4 && (
        <>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              id="tidak-aktif"
              checked={false}
              readOnly
              onClick={() => {
                setOpenActivateUserModal(true);
                setUserEmail(item.email);
              }}
            />
            <div className="w-11 h-6 bg-red-700 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-red-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-red-500 dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <p>{statusUser}</p>
        </>
      )}
      {statusCodeUser === 5 && item.title_id !== 1 && (
        <>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              id="aktif"
              checked={true}
              readOnly
              onClick={() => {
                setOpenDeactivateUserModal(true);
                setUserEmail(item.email);
              }}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:peer-focus:ring-blue-800 dark:bg-red-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <p>{statusUser}</p>
        </>
      )}
      {statusCodeUser === 5 && item.title_id === 1 && (
        <p className="p-2 bg-[#008887] rounded-lg text-white font-semibold text-[14px]">
          {statusUser}
        </p>
      )}
    </>
  );
};

export default UserList;
