import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";

const CollapsibleBox = ({ collapse, setCollapse, title, content, index }) => {
  const toggleCollapse = () => {
    var temp = [...collapse];
    temp[index] = !collapse[index];
    setCollapse(temp);
  };

  return (
    <>
      <div className="w-full border-2 border-[#d8d8d8] rounded-md p-3">
        <div className="flex justify-between w-full" onClick={toggleCollapse}>
          <div className="w-full font-bold">{title}</div>
          <div className="w-full text-right">
            {collapse[index] ? (
              <ExpandLess sx={{ width: "fit-content" }} />
            ) : (
              <ExpandMore sx={{ width: "fit-content" }} />
            )}
          </div>
        </div>
        <div>
          <Collapse in={collapse[index]} timeout="auto" unmountOnExit>
            <div className="my-2">
              <div className="flex justify-between w-full">
                {content}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default CollapsibleBox;
