import React, { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
  TableRow,
  Paper,
} from "@mui/material";

import { getGroups } from "../../../services/InventoryAPI";

const Label = (props) => {
  const { listId, setListId } = props;

  const { data: labels } = useQuery({
    queryKey: ["label"],
    queryFn: () => getGroups(),
  });

  const handleSelectList = useCallback(
    (selectedId) => {
      const selectedValue = listId.findIndex((id) => id === selectedId);

      let updatedList = cloneDeep(listId);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setListId(updatedList);
    },
    [listId]
  );

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableBody>
          {labels && labels !== undefined ? (
            <>
              {labels.map((label) => (
                <TableRow
                  key={label.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ paddingY: 0 }}>
                    <Checkbox
                      onClick={() => {
                        handleSelectList(label.id);
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="label" sx={{ paddingY: 0 }}>
                    {label.name}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Label;
