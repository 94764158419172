import React, { useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiOutlineFieldTime } from "react-icons/ai";

import {
  getHistorySearch,
  deleteHistorySearch,
} from "../../../services/purchaseApi";
import { SelectedProductContext } from "../../context/SelectedProductProvider";
import SearchedResultPurchase from "./SearchedResultPurchase";

const SearchProductPurchase = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    isSearchedProductLoading,
    setSearch,
    setSearchFocus,
    searchFocus,
    mutateSearchProduct,
  } = useContext(SelectedProductContext);

  const { data: historySearch, isLoading: isHistorySearchLoading } = useQuery({
    queryKey: ["history-search"],
    queryFn: () => getHistorySearch(),
  });

  const { mutate: mutateDeleteHistorySearch } = useMutation({
    mutationFn: deleteHistorySearch,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["history-search"] }),
  });

  return (
    <div>
      {isHistorySearchLoading || isSearchedProductLoading ? (
        <div className="h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {searchFocus ? (
            <>
              <div className="flex justify-between items-center mt-3">
                <p className="font-bold">Riwayat Pencarian</p>
                <p
                  className="text-red-500 hover:cursor-pointer"
                  onClick={() => mutateDeleteHistorySearch()}
                >
                  Hapus Riwayat
                </p>
              </div>
              <div className="p-3 font-medium">
                {historySearch && historySearch !== undefined
                  ? historySearch.history.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.history !== "" ? (
                          <div
                            className="flex gap-2 items-center rounded-md border border-black hover:cursor-pointer hover:bg-gray-100 my-2"
                            onClick={() => {
                              setSearch(data.history)
                              mutateSearchProduct({
                                search: data.history,
                                limit: 5,
                                offset: 1,
                              });
                              setSearchFocus(false);
                            }}
                          >
                            <AiOutlineFieldTime />
                            <p>{data.history}</p>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))
                  : null}
              </div>
              <p className="font-bold">5 produk terakhir dilihat</p>
              <div className="flex w-[30%] p-3 gap-x-2">
                {historySearch && historySearch !== undefined
                  ? historySearch.product.map((data, index) => (
                      <img
                        src={data.image !== null ? data.image : ""}
                        key={index}
                        alt=""
                        className="rounded-lg object-cover w-[50%] border border-black bg-white rounded-lg hover:cursor-pointer"
                        onClick={() => navigate(`/purchase/detail/${data.id}`)}
                      />
                    ))
                  : null}
              </div>
            </>
          ) : (
            <SearchedResultPurchase />
          )}
        </>
      )}
    </div>
  );
};

export default SearchProductPurchase;
