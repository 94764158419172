import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { BsUpload } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";
import {
  Snackbar,
  CircularProgress,
  IconButton,
  Backdrop,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  getTemplatePoExcel,
  createPoExcel,
} from "../../../services/purchaseOrderApi";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const ImportPurchaseOrder = () => {
  const navigate = useNavigate();
  const [xlsxFileTemplate, setXlsxFileTemplate] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isXlsxTemplateLoading, setIsXlsxTemplateLoading] = useState(false);

  const handleXlsxData = (xlsxData) => {
    const url = xlsxData;
    const a = document.createElement("a");
    a.download = "template-PO.xlsx";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { refetch: triggerXlsxTemplate } = useQuery({
    queryKey: ["xlsx-template"],
    queryFn: () => getTemplatePoExcel(),
    keepPreviousData: true,
    enabled: false,
    onSuccess: (data) => {
      setIsXlsxTemplateLoading(false);
      handleXlsxData(data);
    },
  });

  const { mutate: mutateCreateXlsx, isLoading: isMutateCreateXlsxLoading } =
    useMutation({
      mutationFn: createPoExcel,
      onSuccess: (data) => {
        setSuccessMessage(data.message.id);
        setXlsxFileTemplate(null);
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
      },
    });

  return (
    <>
      <LoadingBackdrop isLoading={isXlsxTemplateLoading} />
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() => navigate("/purchase-order")}
        >
          Purchase Order
        </p>
        <p>/</p>
        <p className="hover:cursor-pointer">Import Purchase Order</p>
      </div>

      <div className="mt-5 flex justify-center flex-col py-0 lg:py-4 lg:px-20 ">
        <h1 className="text-center font-medium text-2xl">Import PO</h1>
        <>
          <div>
            <ul className="list-decimal px-7 mt-4 text-lg">
              <li>
                Untuk menginput data baru, harap menggunakan template Excel yang
                telah di sediakan.
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    triggerXlsxTemplate();
                    setIsXlsxTemplateLoading(true);
                  }}
                >
                  {" "}
                  Download template Xlsx
                </span>
              </li>
            </ul>
            <div className="flex flex-wrap justify-center gap-5 my-5">
              {xlsxFileTemplate !== null ? (
                <>
                  <div className="cursor-pointer flex flex-col items-center justify-center h-64 w-[47%] border-2 rounded-xl">
                    {isMutateCreateXlsxLoading ? (
                      <CircularProgress />
                    ) : (
                      <div className="flex flex-col items-center justify-center p-5">
                        <div className="text-right">
                          <button
                            onClick={() => setXlsxFileTemplate(null)}
                            className="bg-[#F21B3F] p-1 rounded-full"
                          >
                            <CloseIcon sx={{ color: "white" }} />
                          </button>
                          <BiNotepad className="text-[90px] text-blue-500" />
                        </div>
                        <p className="font-medium text-l text-blue-400 mt-3">
                          {xlsxFileTemplate.name}
                        </p>
                        <div className="flex justify-center mt-[10px]">
                          <button
                            className="bg-[#007AF1] p-3 rounded-xl text-white"
                            type="button"
                            onClick={() => {
                              mutateCreateXlsx(xlsxFileTemplate);
                            }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center transform duration-200 hover:scale-105">
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-col items-center justify-center h-64 border-2 rounded-xl"
                    >
                      <div className="flex flex-col items-center justify-center p-5">
                        <BsUpload className="text-[90px] text-blue-500" />
                        <p className="font-medium text-l text-blue-400 mt-3">
                          Input New Data Here
                        </p>
                        <p className="text-center">
                          Saya sudah memiliki berkas <br /> Excel dengan data
                          produk yang siap diunggah
                        </p>
                      </div>
                      <input
                        id="dropzone-file1"
                        type="file"
                        hidden
                        accept=".xlsx, .xls, .xlsm"
                        onChange={(e) => setXlsxFileTemplate(e.target.files[0])}
                      />
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </div>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSuccessMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ImportPurchaseOrder;
