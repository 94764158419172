import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  FaChevronDown,
  FaChevronLeft,
  FaRegEdit,
  FaRegTrashAlt,
  FaRegUser,
} from "react-icons/fa";

import { RxCross1 } from "react-icons/rx";
import {
  getPatientCareSpecialization,
  setPatientCareDoctor,
} from "../../../../services/patientCare";
import { useFormik } from "formik";
import * as yup from "yup";
import { uploadImage } from "../../../../services/userManagementApi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import useDebounce from "../../../hooks/useDebounce";

const validationSchema = yup.object({
  name: yup.string().required(),
  email: yup.string(),
  phone: yup.string(),
  address: yup.string(),
  sip: yup.string(),
  ktp: yup.string(),
  image: yup.string(),
  specialization_id: yup.number(),
});

const ModalAddDoctor = ({
  setOpen,
  view,
  setView,
  setEditSpec,
  setIsEdit,
  setOpenDeleteSpec,
}) => {
  const [anchorElSpecialization, setAnchorElSpecialization] = useState(null);
  const [specializationSearch, setSpecializationSearch] = useState("");
  const [selectedSpecialization, setselectedSpecialization] = useState(null);

  const openSpecialization = Boolean(anchorElSpecialization);
  const debounce = useDebounce(specializationSearch, 500);

  const { mutate: mutateDoctor, isLoading } = useMutation({
    mutationFn: setPatientCareDoctor,
    onSuccess: () => {
      setView("add");
    },
  });

  const {
    mutate: mutateSpecialization,
    isLoading: isLoadingSpecialization,
    data: dataSpecialization,
  } = useMutation({
    mutationFn: getPatientCareSpecialization,
  });

  const { mutate: mutateUploadImage, isLoading: isLoadingImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => formik.setFieldValue("image", data.url),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      sip: "",
      ktp: "",
      image: "",
      specialization_id: 0,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      mutateDoctor(values);
    },
  });

  useEffect(() => {
    if (anchorElSpecialization)
      mutateSpecialization({
        search: debounce,
        limit: 10,
        offset: 1,
      });
  }, [debounce, anchorElSpecialization]);

  useEffect(() => {
    if (view !== "doctor") formik.resetForm();
  }, [view]);

  return (
    <>
      <div className="px-8 pt-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <FaChevronLeft
              className="hover:text-gray-300 cursor-pointer"
              onClick={() => {
                setView("add");
              }}
            />
            <p className="font-bold text-lg">Add Doctor</p>
          </div>
          <RxCross1
            className="hover:text-red-500 cursor-pointer w-[14px] h-full"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </div>
      <hr className="my-4" />
      <form onSubmit={formik.handleSubmit}>
        <div className="px-8 flex gap-4 items-start overflow-y-auto">
          <div className="relative">
            <div
              className={`w-[200px] h-[200px] rounded-md bg-gray-200 ${
                formik.values.image === "" ? "p-12" : ""
              }`}
            >
              {formik.values.image === "" ? (
                isLoadingImage ? (
                  <div className="w-full h-full flex justify-center items-center">
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  <FaRegUser className="w-full h-full text-white" />
                )
              ) : (
                <img
                  src={formik.values.image}
                  className="w-full h-full rounded-md object-cover"
                />
              )}
            </div>

            <input
              type="file"
              id="doctor-img-upload"
              hidden
              accept="accept='image/jpeg,image/png,image/jpg"
              onChange={(e) => {
                formik.setFieldValue("image", "");
                mutateUploadImage(e.target.files[0]);
              }}
            />
            <label for="doctor-img-upload">
              <div className="w-12 h-12 p-2.5 flex justify-center text-white items-center rounded-full bg-blue-500 absolute bottom-0 left-0 right-0 mx-auto cursor-pointer hover:bg-gray-300">
                <FaRegEdit className="w-full h-full" />
              </div>
            </label>
          </div>
          <div className="w-full flex-1">
            <p className="text-xs">
              Nama Dokter <span className="text-red-500">*</span>
            </p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            <p className="text-xs">Email</p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
            <p className="text-xs">Address</p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              multiline
              rows={4}
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.value)}
            />
            <p className="text-xs">Phone Number</p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
            />
            <p className="text-xs">No. SIP</p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              value={formik.values.sip}
              onChange={(e) => formik.setFieldValue("sip", e.target.value)}
            />
            <p className="text-xs">NIK</p>
            <TextField
              fullWidth
              variant="standard"
              sx={{ marginBottom: 2 }}
              value={formik.values.nik}
              onChange={(e) => formik.setFieldValue("nik", e.target.value)}
            />
            <div className="flex gap-4 items-center mb-4">
              <div className="w-full h-full">
                <div
                  className="flex justify-betwen items-center cursor-pointer"
                  onClick={(e) => setAnchorElSpecialization(e.currentTarget)}
                >
                  <p className="text-xs w-full py-3">
                    {selectedSpecialization
                      ? selectedSpecialization.name
                      : "Specialization"}
                  </p>
                  <FaChevronDown className="text-gray-500" />
                  <Popper
                    disablePortal
                    style={{ width: anchorElSpecialization?.clientWidth }}
                    open={openSpecialization}
                    anchorEl={anchorElSpecialization}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ width: "100%", zIndex: 1500 }}
                  >
                    <ClickAwayListener
                      onClickAway={() => setAnchorElSpecialization(null)}
                    >
                      <div className="w-full bg-white rounded-b-md shadow-md max-h-[30vh] overflow-y-auto">
                        <TextField
                          fullWidth
                          inputProps={{ style: { padding: 12 } }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            ),
                          }}
                          sx={{ padding: 2 }}
                          placeholder="Find Specialization"
                          value={specializationSearch}
                          onChange={(e) =>
                            setSpecializationSearch(e.target.value)
                          }
                        />
                        <hr className="" />
                        {dataSpecialization &&
                        !isLoadingSpecialization &&
                        dataSpecialization.count > 0 ? (
                          dataSpecialization.specialization.map((item) => {
                            return (
                              <div
                                className={`w-full py-2 px-8 border-b-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 ${
                                  selectedSpecialization &&
                                  selectedSpecialization.id === item.id
                                    ? "bg-gray-100"
                                    : ""
                                }`}
                              >
                                <p
                                  className="w-full"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "specialization_id",
                                      item.id
                                    );
                                    setselectedSpecialization(item);
                                    setAnchorElSpecialization(null);
                                  }}
                                >
                                  {item.name}
                                </p>{" "}
                                <div className="flex items-center gap-2">
                                  <FaRegTrashAlt
                                    className="text-red-500 cursor-pointer hover:text-gray-300"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "specialization_id",
                                        0
                                      );
                                      setselectedSpecialization(null);
                                      setAnchorElSpecialization(null);
                                      setEditSpec(item);
                                      setOpenDeleteSpec(true);
                                    }}
                                  />
                                  <FaRegEdit
                                    className="text-blue-500 cursor-pointer hover:text-gray-300"
                                    onClick={() => {
                                      setView("specialization");
                                      setIsEdit(true);
                                      setEditSpec(item);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : isLoadingSpecialization ? (
                          <div className="flex justify-center py-2">
                            <CircularProgress size={20} />
                          </div>
                        ) : (
                          <div className="flex justify-center py-2 text-gray-300">
                            Specialization not found
                          </div>
                        )}
                      </div>
                    </ClickAwayListener>
                  </Popper>
                </div>
                <hr className="" />
              </div>
              <div
                className="rounded-md p-2 flex justify-center items-center w-[48px] h-[48px] cursor-pointer hover:bg-gray-300 bg-blue-500 text-white"
                onClick={() => {
                  setView("specialization");
                  setIsEdit(false);
                }}
              >
                <IoMdAddCircleOutline className="w-full h-full" />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <Checkbox disabled />
              <p className="text-xs text-gray-300">
                Send email to create doctor account
              </p>
            </div>
          </div>
        </div>

        <div className="px-8 py-4 w-full ">
          <button
            type="submit"
            disabled={!formik.isValid || isLoading}
            className="disabled:bg-gray-300 w-full disabled:cursor-default p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 cursor-pointer w-full flex items-center gap-2 justify-center"
          >
            {isLoading ? <CircularProgress size={20} /> : <p>Save</p>}
          </button>
        </div>
      </form>
    </>
  );
};

export default ModalAddDoctor;
