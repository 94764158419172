import React from "react";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import SelectedCategoryById from "../../components/screen/purchase/purchaseComponents/CategorySection/SelectedCategoryById";
import { SIDEBAR_WIDTH } from "../../components/constants/constant";

const SelectedCategoryPage = () => {
  return (
    <div className="">
      <div className="flex">
          <SidebarComponents />       
        <div className={`w-[100%] lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] p-3 overflow-hidden`}>
          <Header />
          <SelectedCategoryById />
        </div>
      </div>
    </div>
  );
};

export default SelectedCategoryPage;
