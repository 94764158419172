import React, { useState, useMemo, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import useDebounce from "../../hooks/useDebounce";
import { getDiscount, handleChangeStatus } from "../../../services/DiscountApi";

const FETCH_LIMIT = 10;

const Discount = () => {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(4);
  const [dataBuffer, setDataBuffer] = useState([]);
  const [offset, setOffset] = useState(1);
  const [search, setSearch] = useState("");

  const debounce = useDebounce(search, 500);

  const { mutate: mutateStatus } = useMutation({
    mutationFn: handleChangeStatus,
    onSuccess: () => queryClient.invalidateQueries("discount"),
  });

  const { data: discounts, isLoading: isDiscountLoading } = useQuery({
    queryFn: () => getDiscount(offset, FETCH_LIMIT, debounce, status),
    queryKey: ["discount", debounce, offset, status],
    keepPreviousData: true,
  });

  const pageCount = useMemo(() => {
    if (discounts === undefined) return 0;
    else if (discounts.count === undefined) return 0;
    else return Math.ceil(discounts.count / FETCH_LIMIT);
  }, [discounts]);

  useEffect(() => {
    setDataBuffer(discounts && discounts !== undefined ? discounts.data : []);
  }, [discounts]);

  return (
    <div className="container mx-auto mt-16 lg:mt-0">
      <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
        Discount & Promo
      </h1>
      <div className="flex flex-wrap items-center justify-between mt-5">
        <h1 className="font-bold text-[20px]">List Discount & Promo</h1>
        <Link to={`/discount/add`}>
          <button className="p-2 lg:p-[12px] bg-blue-500 mb-3 px-4 lg:px-5 font-medium text-sm lg:text-base text-white rounded-xl hover:bg-blue-300">
            Add New Promo
          </button>
        </Link>
      </div>
      <hr className="border-2 mb-4 lg:mb-0" />
      <div className="flex flex-col lg:flex-row justify-around items-center gap-3">
        <div className="w-full shadow-md rounded-lg">
          <label htmlFor="search" className="sr-only">
            Search{" "}
          </label>
          <div methode="get" action="#" className="relative z-10">
            <button
              type="submit"
              id="searchsubmit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-3 border-2  rounded-md leading-5 bg-white text-black placeholder-gray-400  sm:text-sm transition duration-150 ease-in-out"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <FormControl className="w-full lg:w-[50%] shadow-md">
          <InputLabel id="demo-simple-select-label">Filter Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Filter Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={1}>Aktif</MenuItem>
            <MenuItem value={2}>Tidak Aktif</MenuItem>
            <MenuItem value={3}>Expired</MenuItem>
            <MenuItem value={4}>Semua Data</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="grid grid-cols-1">
        {isDiscountLoading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <>
            {dataBuffer && dataBuffer?.data !== null ? (
              <>
                <TableContainer component={Paper} className="mt-3">
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#007AF1" }}>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Nama promo
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Usages Limit
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Start
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          End
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataBuffer.map((discount, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          <TableCell component="th" scope="col" align="center">
                            {discount.promo_name}
                          </TableCell>
                          <TableCell align="center">
                            {discount.usage_limit !== -1
                              ? `Limited (${discount.usage_limit})`
                              : "Unlimited"}
                          </TableCell>
                          <TableCell align="center">
                            {discount.start_date}
                          </TableCell>
                          <TableCell align="center">
                            {discount.end_date}
                          </TableCell>
                          <TableCell align="center">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={discount.status === 1}
                                onChange={() => {
                                  if (discount.status === 1) {
                                    setDataBuffer(
                                      dataBuffer.map((d) => {
                                        return d.id === discount.id
                                          ? { ...discount, status: 2 }
                                          : d;
                                      })
                                    );
                                    mutateStatus({
                                      status: 2,
                                      discount_id: discount.id,
                                    });
                                  } else {
                                    setDataBuffer(
                                      dataBuffer.map((d) => {
                                        return d.id === discount.id
                                          ? { ...discount, status: 1 }
                                          : d;
                                      })
                                    );
                                    mutateStatus({
                                      status: 1,
                                      discount_id: discount.id,
                                    });
                                  }
                                }}
                              />
                              <div className="w-11 h-6 bg-red-700 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-red-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-red-500 dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex justify-center mt-[10px]">
                  <Pagination
                    count={pageCount}
                    page={offset}
                    onChange={(_, value) => setOffset(value)}
                    shape="rounded"
                    size="large"
                    color="primary"
                  />
                </div>
              </>
            ) : (
              <p>Tidak ditemukan!</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Discount;
