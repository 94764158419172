import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import OlinLogo from "../../../assets/icons/logoOlin.png";

import {
  getBillingManualDetail,
  getBillingPaymentMethod,
  payManualBilling,
} from "../../../services/billingAPI";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";

const DetailBillingManual = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const { id } = useParams();
  // const { status } = useParams();

  const [isCollapse, setIsCollapse] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [inputPayment, setInputPayment] = useState("");
  const [searchPayment, setSearchPayment] = useState("");

  const handleSave = () => {
    var body = {};
    if (selectedPayment?.payment_name === "Cash" || inputPayment !== "") {
      body = {
        billing_id: Number(id),
        payment_other: {
          payment_name: inputPayment === "" ? "Cash" : inputPayment,
        },
        warehouse: isWarehouse ? isWarehouse : false,
      };
    } else {
      body = {
        billing_id: Number(id),
        payment_generic: selectedPayment,
        warehouse: isWarehouse ? isWarehouse : false,
      };
    }
    mutatePayManualBilling(body);
  };

  const { mutate: mutatePayManualBilling } = useMutation({
    mutationFn: payManualBilling,
    onSuccess: () => {
      navigate(
        `${isWarehouse ? "/central-warehouse" : ""}/manual-billing/unpaid`
      );
    },
  });

  const { data, isFetching: isFetchingData } = useQuery({
    queryKey: ["detail-bill", id],
    queryFn: () => getBillingManualDetail(id ? id : 0, isWarehouse),
  });

  const { data: dataBank, isFetching } = useQuery({
    queryKey: ["payment-method", searchPayment],
    queryFn: () => getBillingPaymentMethod(searchPayment),
  });

  return (
    <>
      {data && data !== null && data.po_detail ? (
        <>
          <div className="w-full">
            <div
              className={`w-1/4 text-white font-bold text-center p-2 rounded-md my-4 ${
                data.po_detail.status === 3
                  ? "bg-green-500"
                  : data.po_detail.status === 2
                  ? "bg-red-500"
                  : "bg-amber-400"
              }`}
            >
              {data.po_detail.status === 3
                ? "Paid"
                : data.po_detail.status === 2
                ? "Overdue"
                : "Unpaid"}
            </div>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Tanggal PO</p>
            <p className="w-full text-right font-normal">
              {moment(data.po_detail.po_date).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Tanggal Jatuh Tempo PO</p>
            <p className="w-full text-right font-normal">
              {moment(data.po_detail.po_due_date).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Nomor PO</p>
            <p className="w-full text-right font-normal">
              {data.po_detail.po_number}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Nomor Invoice</p>
            <p className="w-full text-right font-normal">
              {data.po_detail.invoice_number}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Distributor</p>
            <p className="w-full text-right font-normal">
              {data.po_detail.distributor_name}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Pharmacist</p>
            <p className="w-full text-right font-normal">
              {data.po_detail.pharmacist_name}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Total PO</p>
            <p className="w-full text-right font-normal">
              {data.po_detail.total_amount.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>

          <hr className="my-8" />

          <p className="font-xl font-bold mb-4">Detail Produk</p>
          {data.product_detail &&
          data.product_detail.length > 0 &&
          data.product_detail !== null ? (
            data.product_detail.map((item, index) => (
              <div
                className="border-2 border-gray-200 rounded-md p-3 my-3 flex"
                key={index}
              >
                <div className="basis-1/5 flex items-center">
                  <img src={OlinLogo} className="w-full object-contain pr-4" />
                </div>
                <div className="basis-1/2 flex flex-wrap content-center items-center">
                  <p className="font-bold w-full">{item.product_name}</p>
                  <p className="w-full">
                    {item.quantity}x
                    {item.pharmacy_net_price
                      .toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                      .replaceAll(",", ".")}
                  </p>
                </div>
                <div className="basis-1/3 border-l pl-2 flex flex-wrap content-center items-center justify-center text-center">
                  <p className="w-full text-gray-400  ">Total Harga</p>
                  <p className="w-full font-bold">
                    {item.total_amount
                      .toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                      .replaceAll(",", ".")}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>Tidak ada produk pada PO ini</p>
          )}
        </>
      ) : isFetchingData ? (
        <CircularProgress />
      ) : (
        <p className="text-red-500">Terjadi kesalahan dalam mengambil data</p>
      )}

      <hr className="my-8" />

      <p className="font-xl font-bold mb-4">Rincian Pembayaran</p>
      {data && data.po_detail.status !== 3 ? (
        <>
          <div
            onClick={() => {
              setSelectedPayment({ payment_name: "Cash" });
              setInputPayment("");
            }}
            className="border-2 border-gray-200 rounded-md p-3 my-3 flex items-center justify-between cursor-pointer hover:bg-slate-100"
          >
            <p>Cash</p>
            <div className="w-6 h-6">
              {selectedPayment !== null &&
              selectedPayment.payment_name === "Cash" &&
              inputPayment === "" ? (
                <AiOutlineCheck className="text-blue-500 w-full h-full" />
              ) : null}
            </div>
          </div>

          <div
            className="border-2 border-gray-200 rounded-md p-3 my-3 flex items-center justify-between cursor-pointer hover:bg-slate-100"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <p>Metode Pembayaran</p>
            <div className="w-6 h-6">
              {isCollapse ? (
                <FiChevronDown className="w-full h-full" />
              ) : (
                <FiChevronUp className="w-full h-full" />
              )}
            </div>
          </div>

          {!isCollapse ? (
            <div className="h-[70vh] mx-12 overflow-y-auto">
              <TextField
                fullWidth
                value={searchPayment}
                onChange={(e) => {
                  setSearchPayment(e.target.value);
                }}
              />
              {dataBank &&
              dataBank !== null &&
              dataBank.data !== null &&
              dataBank.total > 0 ? (
                dataBank.data.map((item, index) => (
                  <div key={index}>
                    <div
                      className="flex items-center justify-between mr-8 hover:bg-slate-100 cursor-pointer"
                      onClick={() => {
                        setSelectedPayment({
                          payment_name: item.bank_name,
                          id_payment: item.bank_id,
                        });
                        setInputPayment("");
                      }}
                    >
                      <p className="py-2">{item.bank_name}</p>
                      <div className="w-6 h-6">
                        {selectedPayment !== null &&
                        selectedPayment.payment_name === item.bank_name &&
                        inputPayment === "" ? (
                          <AiOutlineCheck className="text-blue-500 w-full h-full " />
                        ) : null}
                      </div>
                    </div>
                    <hr className="my-2" />
                  </div>
                ))
              ) : !isFetching ? (
                <p className="text-red-500">
                  Tidak ada metode pembayaran lain yang ditemukan
                </p>
              ) : (
                <CircularProgress />
              )}
            </div>
          ) : null}

          <div className="bg-gray-100 p-4 my-8">
            <p>Others</p>
            <TextField
              fullWidth
              placeholder="Input jenis pembayaran"
              value={inputPayment}
              onChange={(e) => {
                setInputPayment(e.target.value);
                setSelectedPayment(null);
              }}
            />
          </div>

          <p className="text-blue-500 mb-8">
            <span className="font-bold text-black">Metode Pembayaran: </span>
            {inputPayment !== ""
              ? inputPayment
              : selectedPayment !== null
              ? selectedPayment.payment_name
              : ""}
          </p>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSave}
            disabled={inputPayment == "" && selectedPayment === null}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          {" "}
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Payment Method</p>
            <p className="w-full text-right font-normal">
              {data?.payment_detail.payment_method_name}
            </p>
          </div>
          <div className="flex justify-between w-full font-bold my-2">
            <p className="w-full">Tanggal Pembayaran</p>
            <p className="w-full text-right font-normal">
              {moment(data?.payment_detail.paid_at).format("DD MMMM YYYY")}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default DetailBillingManual;
