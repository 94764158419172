import React, { useContext } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";

import { SelectedProductContext } from "../../../../context/SelectedProductProvider";

const Principals = () => {
  const navigate = useNavigate();
  const { rankPrinciple } = useContext(
    SelectedProductContext
  );

  return (
    <div className="container mx-auto mt-5">
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-[20px]">Principal</h1>
        <p
          className="text-blue-500 hover:underline cursor-pointer"
          onClick={() =>
              navigate("/purchase/principals")
          }
        >
          Lihat detail
        </p>
      </div>
      <div className="flex flex-wrap justify-evenly">
        {rankPrinciple && rankPrinciple !== undefined
          ? rankPrinciple.map((principle, index) => (
              <div className="flex w-[10%] my-2" key={index}>
                <img
                  src={principle.logo}
                  onClick={() =>
                    navigate(
                      `/purchase/principal-detail/${principle.principal_id}`
                    )
                  }
                  className="rounded-full shadow-md hover:cursor-pointer"
                  alt=""
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Principals;
