import { useParams } from "react-router-dom";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import ReactToPrint from "react-to-print";
import React, { useEffect, useRef, useState } from "react";
import { LuPrinter } from "react-icons/lu";
import { MdMailOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Close as CloseIcon } from "@mui/icons-material";
import moment from "moment";

import PrescriptionPreview from "../patientCareDoctorAnalysis/PrescriptionPreview";
import { getDetailInventory } from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import {
  fetchProductListPatientCare,
  getPrescriptionHistoryDetail,
  mailRecipe,
} from "../../../../services/patientCare";
import CopyPrescriptionPreview from "./CopyPrescriptionPreview";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";

const FETCH_LIMIT = 10;

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const CopyPrescription = () => {
  const { id } = useParams();
  const componentRef = useRef();
  const filter = createFilterOptions();

  const [searchProduct, setSearchProduct] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [age, setAge] = useState(0);
  const [message, setMessage] = useState("");

  const debounce = useDebounce(searchProduct, 500);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleSendMail = () => {
    // reformat data to fit API body request
    mutateMail({
      queue_number: formik.values.queue_number,
      doctor_name: formik.values.doctor_name,
      created_at: moment(formik.values.created_at).format("DD/MM/YYYY"),
      note: formik.values.note,
      patient_name: formik.values.patient_name,
      patient_age: getAge(formik.values.patient_dob).toString(),
      patient_weight: formik.values.objective.weight,
      prescription_date: moment(formik.values.prescription_date).format(
        "DD/MM/YYYY"
      ),
      concoction_copy: formik.values.concoction_copy.map((item) => ({
        type_det: item.type_det,
        det_qty: item.det_qty,
        concoction: {
          iter: item.iter,
          signa: item.signa,
          misce_fiat: item.misce_fiat,
          products: item.product.map((prod) => ({
            name: prod.product_name,
            uom: prod.uom,
            qty: prod.quantity,
          })),
        },
      })),
      non_concoction_copy: formik.values.non_concoction_copy.map((item) => ({
        type_det: item.type_det,
        det_qty: item.det_qty,
        non_concoction: {
          iter: item.iter,
          signa: item.signa,
          product_name: item.product_name,
          uom: item.uom,
          qty: item.quantity,
        },
      })),
      pcc: formik.values.pcc,
    });
  };

  const fetchProducts = (searchStr, id) => {
    mutateActiveProducts({
      offset: 1,
      limit: FETCH_LIMIT,
      name: searchStr,
    });
  };

  const {
    mutate: mutateActiveProducts,
    data: products,
    isLoading,
  } = useMutation({
    mutationFn: fetchProductListPatientCare,
  });

  const {
    data: dataDetailProduct,
    isFetching: isFetchingDetailProduct,
    refetch: refetchDetailProduct,
  } = useQuery({
    queryKey: ["detail_product", selectedProduct],
    queryFn: () => getDetailInventory(selectedProduct),
    enabled: !!selectedProduct,
  });

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-prescription", id],
    queryFn: () => getPrescriptionHistoryDetail(id),
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateMail, isLoading: isLoadingMail } = useMutation({
    mutationFn: mailRecipe,
    onError: (err) => {
      setMessage(err.message.id ? err.message.id : err.message);
    },
    onSuccess: () => setMessage("Prescription copy sent to email!"),
  });

  const formik = useFormik({
    initialValues: {
      prescription_code: dataDetail ? dataDetail.prescription_code : "",
      queue_number: dataDetail ? dataDetail.queue_number : "",
      attachment: dataDetail ? dataDetail.attachment : "", //empty if I
      doctor_name: dataDetail ? dataDetail.doctor_name : "",
      sip: dataDetail ? dataDetail.sip : "",
      address: dataDetail ? dataDetail.address : "",
      phone: dataDetail ? dataDetail.phone : "",
      patient_name: dataDetail ? dataDetail.patient_name : "",
      patient_dob: dataDetail ? dataDetail.patient_dob : "",
      prescription_date: dataDetail ? dataDetail.prescription_date : "",
      objective: { weight: dataDetail ? dataDetail.weight : "" },
      regular: dataDetail ? dataDetail.regular : [],
      concoction: dataDetail ? dataDetail.concoction : [],
      non_concoction_copy: dataDetail ? dataDetail.regular : [],
      concoction_copy: dataDetail ? dataDetail.concoction : [],
      pcc: dataDetail ? dataDetail.pcc : "",
      note: "",
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchProducts(debounce);
  }, [debounce]);

  useEffect(() => {
    if (selectedProduct) {
      refetchDetailProduct();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (dataDetail)
      setAge(
        getAge(dataDetail.patient_dob === "" ? 0 : dataDetail.patient_dob)
      );
  }, [dataDetail]);

  return (
    <>
      <LoadingBackdrop isLoading={isLoadingMail} />
      <div className="w-full flex justify-end mb-4">
        <div>
          <p className="font-bold">Share this prescription copy</p>
          <div className="flex gap-2 justify-end">
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button
                  disabled={
                    !dataDetail ||
                    isFetching ||
                    !formik.values.concoction_copy.every(
                      (item) =>
                        (item.type_det !== "-det iter-" &&
                          item.det_qty === 0) ||
                        item.det_qty > 0
                    ) ||
                    !formik.values.non_concoction_copy.every(
                      (item) =>
                        (item.type_det !== "-det iter-" &&
                          item.det_qty === 0) ||
                        item.det_qty > 0
                    )
                  }
                  className="w-[36px] h-[36px] rounded-md bg-[#687B8E] text-white hover:bg-gray-300 p-1.5 disabled:bg-gray-300"
                >
                  <LuPrinter className="w-full h-full" />
                </button>
              )}
            />
            <button
              className="w-[36px] h-[36px] rounded-md bg-blue-500 text-white hover:bg-gray-300  p-1.5 disabled:bg-gray-300 "
              onClick={handleSendMail}
              disabled={
                !dataDetail ||
                isFetching ||
                !formik.values.concoction_copy.every(
                  (item) =>
                    (item.type_det !== "-det iter-" && item.det_qty === 0) ||
                    item.det_qty > 0
                ) ||
                !formik.values.non_concoction_copy.every(
                  (item) =>
                    (item.type_det !== "-det iter-" && item.det_qty === 0) ||
                    item.det_qty > 0
                )
              }
            >
              <MdMailOutline className="w-full h-full" />
            </button>
          </div>
        </div>
      </div>
      <div className="block lg:flex gap-4 lg:divide-x">
        <div className="w-full">
          <div className="pb-4 border-b-2 border-dashed">
            <div className="w-2/3 h-full border p-3">
              {dataDetail && !isFetching ? (
                formik.values.prescription_code[0] === "I" ? (
                  <PrescriptionPreview
                    formik={formik}
                    selectedDoctor={{
                      name: formik.values.doctor_name,
                      sip: formik.values.sip,
                      address: formik.values.address,
                      phone: formik.values.phone,
                    }}
                    selectedPatient={{
                      patient_name: formik.values.patient_name,
                      date_of_birth: formik.values.patient_dob,
                    }}
                  />
                ) : (
                  <div className="w-full">
                    {formik.values.attachment === "" ? (
                      <p>Tidak ada attachment</p>
                    ) : (
                      <img
                        src={formik.values.attachment}
                        className="w-full h-full object-contain"
                      />
                    )}
                  </div>
                )
              ) : isFetching ? (
                <div className="flex justify-center items-center">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <p>Terjadi kesalahan dalam mengambil data</p>
                </div>
              )}
            </div>
          </div>
          <div className="my-4">
            {dataDetail && !isFetching ? (
              <>
                <TextField
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  label="Doctor Name"
                  value={formik.values.doctor_name}
                  onChange={(e) =>
                    formik.setFieldValue("doctor_name", e.target.value)
                  }
                />
                <DesktopDatePicker
                  className="w-full"
                  onChange={(newDate) =>
                    formik.setFieldValue(
                      "prescription_date",
                      toDateApi(newDate)
                    )
                  }
                  value={
                    formik.values.prescription_date === ""
                      ? null
                      : formik.values.prescription_date
                  }
                  label={
                    <p>
                      Prescription Date<span className="text-red-800">*</span>
                    </p>
                  }
                  renderInput={(params) => {
                    return <TextField {...params} sx={{ marginBottom: 2 }} />;
                  }}
                />
                <TextField
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  label="Pro"
                  value={formik.values.patient_name}
                  onChange={(e) =>
                    formik.setFieldValue("patient_name", e.target.value)
                  }
                />
                <TextField
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  label="Age"
                  value={age}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)))
                      setAge(Number(e.target.value));
                  }}
                />
                <TextField
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  label="Notes"
                  value={formik.values.note}
                  onChange={(e) => {
                    if (e.target.value.length <= 200)
                      formik.setFieldValue("note", e.target.value);
                  }}
                />

                <div className="w-full my-4 rounded-md border-2 p-2">
                  {formik.values.non_concoction_copy.map((item, i) => (
                    <div className="rounded-md border-2 p-2 mb-2" key={i}>
                      <div className="w-full flex justify-end">
                        <div
                          className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 rounded-md p-1 text-white mb-2"
                          onClick={() =>
                            formik.setFieldValue("non_concoction_copy", [
                              ...formik.values.non_concoction_copy.slice(0, i),
                              ...formik.values.non_concoction_copy.slice(i + 1),
                            ])
                          }
                        >
                          <AiOutlineMinusCircle className="w-full h-full" />
                        </div>
                      </div>
                      <div className="flex items-center mb-4">
                        <span
                          className="mr-2 font-bold"
                          style={{ width: "30px" }}
                        >
                          R/
                        </span>
                        <div className="flex-grow w-full mr-2">
                          <Autocomplete
                            fullWidth
                            // freeSolo
                            loading={isLoading}
                            disablePortal
                            options={
                              products && products.product_data
                                ? products.product_data
                                : []
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              const isExisting = options.some(
                                (option) => inputValue === option.product_name
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  product_name: inputValue,
                                  product_id: 0,
                                });
                              }

                              return filtered;
                            }}
                            value={
                              formik.values.non_concoction_copy[i]?.product_name
                                ? {
                                    product_name:
                                      formik.values.non_concoction_copy[i]
                                        .product_name,
                                    product_id:
                                      formik.values.non_concoction_copy[i]
                                        .product_id,
                                  }
                                : null
                            }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                `non_concoction_copy[${i}]`,
                                {
                                  ...formik.values.non_concoction_copy[i],
                                  product_id: newValue
                                    ? newValue.product_id
                                    : "",
                                  product_name: newValue
                                    ? newValue.product_name
                                    : "",
                                  uom: "",
                                  quantity: 0,
                                }
                              );
                            }}
                            onOpen={() => fetchProducts("")}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option.product_name || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.product_id === value.product_id
                            }
                            onInputChange={(event, value) => {
                              if (value !== null) {
                                setSearchProduct(value);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {isLoading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="mr-2 w-1/2">
                          <Autocomplete
                            freeSolo
                            options={
                              dataDetailProduct && !isFetchingDetailProduct
                                ? [
                                    dataDetailProduct.packing.type_box,
                                    dataDetailProduct.packing.type_strip,
                                    dataDetailProduct.packing.type_unit,
                                  ]
                                : []
                            }
                            value={formik.values.non_concoction_copy[i].uom}
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                `non_concoction_copy[${i}].uom`,
                                newValue
                              );
                            }}
                            onInputChange={(event, newInputValue) => {
                              formik.setFieldValue(
                                `non_concoction_copy[${i}].uom`,
                                newInputValue
                              );
                            }}
                            onOpen={() => {
                              setSelectedProduct(
                                formik.values.non_concoction_copy[i].product_id
                                  ? formik.values.non_concoction_copy[i]
                                      .product_id
                                  : null
                              );
                            }}
                            onClose={() => setSelectedProduct(null)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="UOM"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {isLoading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="flex items-center space-x-2">
                          <IconButton
                            disabled={
                              formik.values.non_concoction_copy[i].quantity ===
                              0
                            }
                            color="error"
                            onClick={() =>
                              formik.setFieldValue(
                                `non_concoction_copy[${i}].quantity`,
                                formik.values.non_concoction_copy[i].quantity -
                                  1
                              )
                            }
                          >
                            <AiOutlineMinusCircle />
                          </IconButton>

                          <input
                            value={
                              formik.values.non_concoction_copy[i].quantity
                            }
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value)))
                                formik.setFieldValue(
                                  `non_concoction_copy[${i}].quantity`,
                                  Number(e.target.value)
                                );
                            }}
                            className="max-w-[24px] text-center"
                          />
                          <IconButton
                            color="primary"
                            onClick={() =>
                              formik.setFieldValue(
                                `non_concoction_copy[${i}].quantity`,
                                formik.values.non_concoction_copy[i].quantity +
                                  1
                              )
                            }
                          >
                            <AiOutlinePlusCircle />
                          </IconButton>
                        </div>
                      </div>

                      <div className="flex items-center mb-4">
                        <span
                          className="mr-2 font-bold"
                          style={{ width: "30px" }}
                        >
                          S
                        </span>
                        <div className="flex-grow">
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={formik.values.non_concoction_copy[i].signa}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `non_concoction_copy[${i}].signa`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end mb-2">
                        <div className="w-1/2 flex items-center gap-2">
                          <p className="w-full text-right">Iter Resep</p>
                          <TextField
                            value={formik.values.non_concoction_copy[i].iter}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value)))
                                formik.setFieldValue(
                                  `non_concoction_copy[${i}].iter`,
                                  Number(e.target.value)
                                );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex gap-2 items-center mb-4">
                        <p className="w-fit whitespace-nowrap">Tipe Det</p>
                        <Select
                          sx={{ width: "100%" }}
                          value={formik.values.non_concoction_copy[i].type_det}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `non_concoction_copy[${i}].det_qty`,
                              e.target.value === "-det iter-" ? 1 : 0
                            );
                            formik.setFieldValue(
                              `non_concoction_copy[${i}].type_det`,
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value="" disabled>
                            Pilih Tipe Det
                          </MenuItem>
                          <MenuItem value="-det orig-">Det Orig</MenuItem>
                          <MenuItem value="-det iter-">Det Iter</MenuItem>
                          <MenuItem value="-det-">Det</MenuItem>
                          <MenuItem value="-ne det-">Ne Det</MenuItem>
                        </Select>
                      </div>

                      {formik.values.non_concoction_copy[i].type_det !== "" &&
                      formik.values.non_concoction_copy[i].type_det ===
                        "-det iter-" ? (
                        <div className="flex items-center justify-end mb-2">
                          <div className="w-1/2 flex items-center gap-2">
                            <p className="w-full text-right">Det Quantity</p>
                            <TextField
                              value={
                                formik.values.non_concoction_copy[i].det_qty
                                  ? formik.values.non_concoction_copy[i].det_qty
                                  : 0
                              }
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value)))
                                  formik.setFieldValue(
                                    `non_concoction_copy[${i}].det_qty`,
                                    Number(e.target.value)
                                  );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className="flex items-center justify-end">
                    <div
                      className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                      onClick={() => {
                        formik.setFieldValue("non_concoction_copy", [
                          ...formik.values.non_concoction_copy,
                          {
                            product_id: 0,
                            product_name: "",
                            uom: "",
                            quantity: 0,
                            signa: "",
                            iter: 0,
                            type_det: "",
                          },
                        ]);
                      }}
                    >
                      <p className="w-full text-right">Add More</p>
                      <AiOutlinePlusCircle />
                    </div>
                  </div>
                </div>

                <div className="w-full my-4 rounded-md border-2 p-2">
                  {formik.values.concoction_copy.map((item, i) => (
                    <div className="rounded-md border-2 p-2 mb-2" key={i}>
                      <div className="w-full flex justify-between mb-2">
                        <p className="font-bold">Racikan {i + 1}</p>
                        <div
                          className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 rounded-md p-1 text-white mb-2"
                          onClick={() =>
                            formik.setFieldValue("concoction_copy", [
                              ...formik.values.concoction_copy.slice(0, i),
                              ...formik.values.concoction_copy.slice(i + 1),
                            ])
                          }
                        >
                          <AiOutlineMinusCircle className="w-full h-full" />
                        </div>
                      </div>
                      {item.product.map((concProd, productIndex) => (
                        <>
                          <div className="flex items-center mb-2">
                            <span
                              className="mr-2 font-bold"
                              style={{ width: "30px" }}
                            >
                              {productIndex === 0 ? "R/" : ""}
                            </span>
                            <div className="flex-grow mr-2">
                              <Autocomplete
                                fullWidth
                                loading={isLoading}
                                disablePortal
                                options={
                                  products && products.product_data
                                    ? products.product_data
                                    : []
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  const isExisting = options.some(
                                    (option) =>
                                      inputValue === option.product_name
                                  );
                                  if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      product_name: inputValue,
                                      product_id: 0,
                                    });
                                  }

                                  return filtered;
                                }}
                                onOpen={() => fetchProducts("")}
                                value={
                                  concProd?.product_name
                                    ? {
                                        product_name:
                                          formik.values.concoction_copy[i]
                                            .product[productIndex].product_name,
                                        product_id:
                                          formik.values.concoction_copy[i]
                                            .product[productIndex].product_id,
                                      }
                                    : null
                                }
                                onChange={(event, newValue) => {
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].product[${productIndex}]`,
                                    {
                                      ...formik.values.concoction_copy[i]
                                        .product[productIndex],
                                      product_id: newValue
                                        ? newValue.product_id
                                        : "",
                                      product_name: newValue
                                        ? newValue.product_name
                                        : "",
                                      uom: "",
                                      quantity: 0,
                                    }
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  typeof option === "string"
                                    ? option
                                    : option.product_name || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.product_id === value.product_id
                                }
                                onInputChange={(event, value) => {
                                  if (value !== null) {
                                    setSearchProduct(value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoading ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="mr-2">
                              <Autocomplete
                                freeSolo
                                options={
                                  dataDetailProduct && !isFetchingDetailProduct
                                    ? [
                                        dataDetailProduct.packing.type_box,
                                        dataDetailProduct.packing.type_strip,
                                        dataDetailProduct.packing.type_unit,
                                      ]
                                    : []
                                }
                                value={
                                  formik.values.concoction_copy[i].product[
                                    productIndex
                                  ].uom
                                }
                                onChange={(event, newValue) => {
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].product[${productIndex}].uom`,
                                    newValue
                                  );
                                }}
                                onInputChange={(event, newInputValue) => {
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].product[${productIndex}].uom`,
                                    newInputValue
                                  );
                                }}
                                onOpen={() => {
                                  setSelectedProduct(
                                    formik.values.concoction_copy[i].product[
                                      productIndex
                                    ].product_id
                                      ? formik.values.concoction_copy[i]
                                          .product[productIndex].product_id
                                      : null
                                  );
                                }}
                                onClose={() => setSelectedProduct(null)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="UOM"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoading ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="flex items-center space-x-2">
                              <IconButton
                                disabled={
                                  formik.values.concoction_copy[i].product[
                                    productIndex
                                  ].quantity === 0
                                }
                                color="error"
                                onClick={() =>
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].product[${productIndex}].quantity`,
                                    formik.values.concoction_copy[i].product[
                                      productIndex
                                    ].quantity - 1
                                  )
                                }
                              >
                                <AiOutlineMinusCircle />
                              </IconButton>

                              <input
                                value={
                                  formik.values.concoction_copy[i].product[
                                    productIndex
                                  ].quantity
                                }
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value)))
                                    formik.setFieldValue(
                                      `concoction_copy[${i}].product[${productIndex}].quantity`,
                                      Number(e.target.value)
                                    );
                                }}
                                className="max-w-[24px] text-center"
                              />
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].product[${productIndex}].quantity`,
                                    formik.values.concoction_copy[i].product[
                                      productIndex
                                    ].quantity + 1
                                  )
                                }
                              >
                                <AiOutlinePlusCircle />
                              </IconButton>
                            </div>
                            <div
                              className="w-[24px] h-[24px] bg-red-500 cursor-pointer hover:bg-red-700 text-white p-1 rounded-md"
                              onClick={() => {
                                formik.setFieldValue(
                                  `concoction_copy[${i}].product`,
                                  [
                                    ...formik.values.concoction_copy[
                                      i
                                    ].product.slice(0, productIndex),
                                    ...formik.values.concoction_copy[
                                      i
                                    ].product.slice(productIndex + 1),
                                  ]
                                );
                              }}
                            >
                              <FaRegTrashAlt className="w-full h-full" />
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="flex items-center justify-end mb-4">
                        <div
                          className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                          onClick={() => {
                            formik.setFieldValue(
                              `concoction_copy[${i}].product`,
                              [
                                ...formik.values.concoction_copy[i].product,
                                {
                                  product_id: 0,
                                  product_name: "",
                                  uom: 0,
                                  quantity: 0,
                                },
                              ]
                            );
                          }}
                        >
                          <p className="w-full text-right">Add More</p>
                          <AiOutlinePlusCircle />
                        </div>
                      </div>

                      <div className="flex items-center mb-4">
                        <span
                          className="mr-2 font-bold"
                          style={{ width: "30px" }}
                        >
                          m.f
                        </span>
                        <div className="flex-grow">
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={formik.values.concoction_copy[i].misce_fiat}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `concoction_copy[${i}].misce_fiat`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="flex items-center mb-4">
                        <span
                          className="mr-2 font-bold"
                          style={{ width: "30px" }}
                        >
                          S
                        </span>
                        <div className="flex-grow">
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={formik.values.concoction_copy[i].signa}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `concoction_copy[${i}].signa`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-end mb-2">
                        <div className="w-1/2 flex items-center gap-2">
                          <p className="w-full text-right">Iter Resep</p>
                          <TextField
                            value={formik.values.concoction_copy[i].iter}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value)))
                                formik.setFieldValue(
                                  `concoction_copy[${i}].iter`,
                                  Number(e.target.value)
                                );
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex gap-2 items-center mb-4">
                        <p className="w-fit whitespace-nowrap">Tipe Det</p>
                        <Select
                          sx={{ width: "100%" }}
                          value={formik.values.concoction_copy[i].type_det}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `concoction_copy[${i}].det_qty`,
                              e.target.value === "-det iter-" ? 1 : 0
                            );
                            formik.setFieldValue(
                              `concoction_copy[${i}].type_det`,
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value="" disabled>
                            Pilih Tipe Det
                          </MenuItem>
                          <MenuItem value="-det orig-">Det Orig</MenuItem>
                          <MenuItem value="-det iter-">Det Iter</MenuItem>
                          <MenuItem value="-det-">Det</MenuItem>
                          <MenuItem value="-ne det-">Ne Det</MenuItem>
                        </Select>
                      </div>

                      {formik.values.concoction_copy[i].type_det !== "" &&
                      formik.values.concoction_copy[i].type_det ===
                        "-det iter-" ? (
                        <div className="flex items-center justify-end mb-2">
                          <div className="w-1/2 flex items-center gap-2">
                            <p className="w-full text-right">Det Quantity</p>
                            <TextField
                              value={
                                formik.values.concoction_copy[i].det_qty
                                  ? formik.values.concoction_copy[i].det_qty
                                  : 0
                              }
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value)))
                                  formik.setFieldValue(
                                    `concoction_copy[${i}].det_qty`,
                                    Number(e.target.value)
                                  );
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className="flex items-center justify-end">
                    <div
                      className="w-1/2 flex items-center gap-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                      onClick={() => {
                        formik.setFieldValue("concoction_copy", [
                          ...formik.values.concoction_copy,
                          {
                            product: [
                              {
                                product_id: 0,
                                product_name: "",
                                uom: 0,
                                quantity: 0,
                              },
                            ],
                            misce_fiat: "",
                            signa: "",
                            iter: 0,
                            type_det: "",
                          },
                        ]);
                      }}
                    >
                      <p className="w-full text-right">Add More</p>
                      <AiOutlinePlusCircle />
                    </div>
                  </div>
                </div>
              </>
            ) : isFetching ? (
              <div className="flex justify-center items-center">
                <CircularProgress size={20} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full ">
          <p className="lg:pl-4">Resep</p>
          <div className="relative">
            <div ref={componentRef} className="w-full p-4">
              {dataDetail && !isFetching ? (
                <CopyPrescriptionPreview
                  formik={formik}
                  selectedPatient={{
                    patient_name: formik.values.patient_name,
                    patient_age: age,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={Boolean(message)}
        autoHideDuration={5000}
        onClose={() => setMessage("")}
        message={message}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default CopyPrescription;
