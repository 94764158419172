import { CircularProgress } from "@mui/material";
import { AiOutlineCheck } from "react-icons/ai";

const ShippingMethod = (props) => {
  const {
    isShippingMethodLoading,
    shippingMethodData,
    shipping,
    setShipping,
    selectedMethod,
    setSelectedMethod,
  } = props;
  return (
    <>
      {isShippingMethodLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {shippingMethodData && shippingMethodData !== undefined ? (
            shippingMethodData.map((shipping, index) => (
              <div
                className="flex w-full items-center mb-3 border border-black rounded-md p-2 hover:cursor-pointer hover:bg-gray-100"
                key={index}
                onClick={() => {
                  setSelectedMethod({
                    ...selectedMethod,
                    shipping_id: shipping.id,
                    shipping_price: shipping.price_shipping,
                  });
                  setShipping({
                    ...shipping,
                    name: shipping.name_shipping,
                    estimation: shipping.estimation_shipping,
                    price: shipping.price_shipping,
                  });
                }}
              >
                {/* <div className="flex w-[10%] mr-3">
                  <img src={shipping.logo} /> */}
                {/* </div> */}
                <div className="flex justify-between items-center w-full">
                  <div>
                    <p className="font-bold">{shipping.name_shipping}</p>
                    {shipping.price_shipping === 0 ? (
                      <div className="flex font-semibold">
                        <p>estimasi: {shipping.estimation_shipping}&nbsp;</p>
                        <p className="text-[#00B21D]">Gratis</p>
                      </div>
                    ) : (
                      <div className="flex font-semibold">
                        <p>estimasi: {shipping.estimation_shipping}&nbsp;</p>
                        <p>
                          {shipping.price_shipping.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      </div>
                    )}
                  </div>
                  {selectedMethod.shipping_id === shipping.id ? (
                    <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <p>kosong</p>
          )}
        </>
      )}
    </>
  );
};

export default ShippingMethod;
