import { Snackbar, CircularProgress, Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import BillingNavigation from "./BillingNavigation";
import { getBillingList } from "../../../services/billingAPI";
import BillingBreadcrumb from "./BillingBreadcrumb";
import logoOlin from "../../../assets/icons/logoOlin.png";

const FETCH_LIMIT = 10;

const PaidBilling = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [offset, setOffset] = useState(1);

  const { data, isFetching } = useQuery({
    queryFn: () => getBillingList(4, FETCH_LIMIT, offset),
    queryKey: ["billing-paid"],
  });

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <BillingBreadcrumb />
      <BillingNavigation />
      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>

      <>
        {data && data.detail !== null && data.detail.bills !== null ? (
          <>
            {data.detail.bills.map((item, index) => {
              return (
                <div
                  className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full"
                  key={index}
                >
                  <div className="flex items-center w-full">
                    <div className="w-full">
                      <div className="flex my-2">
                        <img
                          src={logoOlin}
                          alt="foto_product"
                          className="w-30 rounded-lg mx-2 p-2 object-contain"
                        />
                        <div className="flex justify-between items-center w-full">
                          <div className="flex flex-col text-sm lg:text-base gap-1 text-clip overflow-hidden w-full">
                            <p className="text-blue-500">
                              <Link
                                to={`/billing/paid/detail/${item.bills_id[0]}`}
                                className="hover:text-blue-700 cursor-pointer"
                              >
                                {item.transactions_id
                                  ? item.transactions_id[0]
                                  : null}
                              </Link>
                            </p>
                            <p className="font-bold">
                              {item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </p>
                            <p>
                              Tanggal Pembayaran:{" "}
                              {moment(item.payment_at).format("DD MMMM YYYY")}
                            </p>
                            <p>
                              Metode Pembayaran: {item.payment.detail.pg_name}
                            </p>
                          </div>
                          <div className="flex flex-col text-sm lg:text-base text-clip overflow-hidden w-full text-right">
                            <p className="text-red-500 font-bold">
                              <span className="hidden md:inline-block">
                                {item.tenor.total > 1
                                  ? item.tenor.current + "/" + item.tenor.total
                                  : ""}
                              </span>{" "}
                              Sudah Dibayar
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : isFetching ? (
          <CircularProgress />
        ) : data && data.detail === null && data.message ? (
          <p className="text-red-500 py-4 text-center">{data.message.id}</p>
        ) : null}
      </>
      <p className="text-red-500">{errorMessage}</p>

      <div className="flex justify-end">
        {data &&
        data.detail.bills?.length > 0 &&
        data.detail.info?.total > 0 ? (
          <Pagination
            count={Math.ceil(data.detail.info.total / FETCH_LIMIT)}
            page={offset}
            onChange={(_, value) => setOffset(value)}
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>

      <Snackbar
        open={isCopied}
        onClose={() => setIsCopied(false)}
        message={"Virtual Account Number Copied"}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default PaidBilling;
