import { useEffect } from "react";
import { TextField } from "@mui/material";
import { TbTicket } from "react-icons/tb";
import { AiOutlineCheck } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import moment from "moment";

const toDate = (date) => moment(date).format("DD-MMMM-YYYY");

const VoucherSubscription = (props) => {
  const {
    voucher,
    trigger,
    setTrigger,
    selectedPromo,
    setIsOpenAddVoucher,
    setSelectedPromo,
    paymentConfirmed,
    setPaymentConfirmed,
    isDetailVoucherOpen,
    setIsDetailVoucherOpen,
  } = props;

  useEffect(() => {
    setSelectedPromo({
      voucherId: [],
      voucherCode: "",
      discount: 0,
      isConfirmed: false,
    });
    setTrigger(false);
  }, []);

  return (
    <>
      {selectedPromo && selectedPromo !== undefined ? (
        <>
          <div className="flex w-full my-3">
            <TextField
              fullWidth
              label="Kode Promo"
              onChange={(e) => {
                setSelectedPromo({
                  ...selectedPromo,
                  voucherCode: e.target.value,
                });
              }}
              value={selectedPromo.voucherCode}
              inputProps={{ style: { textTransform: "uppercase" } }}
              helperText={trigger ? "test" : null}
              error={trigger}
            />
            <button
              className={`font-bold p-3 rounded-md ml-5 ${
                selectedPromo.voucherCode === ""
                  ? "bg-gray-100 text-gray-400"
                  : "bg-[#007AF1] text-white"
              }`}
              disabled={selectedPromo.voucherCode === ""}
              onClick={() => {
                if (trigger) {
                  setSelectedPromo({ ...selectedPromo, isConfirmed: false });
                  return;
                }
                setSelectedPromo({ ...selectedPromo, isConfirmed: true });
                setIsOpenAddVoucher(false);
              }}
            >
              Pakai
            </button>
          </div>
          <div className="flex w-full">
            <TbTicket className="text-[25px] mr-3" />
            <p className="text-[16px] font-bold">Voucher Saya</p>
          </div>
          {voucher && voucher !== undefined ? (
            <div className="text-[12px]">
              <p className="font-bold">Promo yang bisa dipakai</p>
              {voucher.response_voucher_can_use.map((voucher, index) => (
                <div
                  className={`w-full rounded-md p-2 font-semibold my-3 flex justify-between items-center hover:cursor-pointer ${
                    selectedPromo?.voucherId[0] === voucher.id
                      ? "bg-[#E3F1FF] border-2 border-[#007AF1]"
                      : "bg-white border border-black"
                  }`}
                  key={index}
                  onClick={() => {
                    if (
                      selectedPromo?.voucherId[0] === voucher.id ||
                      isDetailVoucherOpen.state
                    )
                      setSelectedPromo({
                        ...selectedPromo,
                        voucherId: [],
                        discount: 0,
                        voucherCode: "",
                        isConfirmed: false,
                      });
                    else
                      setSelectedPromo({
                        ...selectedPromo,
                        voucherId: [voucher.id],
                        discount: voucher.total_discount_calculate,
                        voucherCode: voucher.voucher_code,
                        isConfirmed: true,
                      });
                    setPaymentConfirmed({
                      ...paymentConfirmed,
                      code_voucher: voucher.voucher_code,
                      amount_voucher: voucher.total_discount_calculate,
                    });
                  }}
                >
                  <div className="flex items-center w-full">
                    {/* <div className="flex w-[10%] mr-3 justify-center">
                      <img src={voucher.image} />
                    </div> */}
                    <div>
                      <p>{voucher.voucher_name}</p>
                      <p className="mt-2">{voucher.short_description}</p>
                      <div className="flex my-2">
                        <p>Kode Voucher:&nbsp;</p>
                        <p className="text-[#007AF1]">{voucher.voucher_code}</p>
                      </div>
                      <div className="flex items-center w-full">
                        <BiTimeFive className="text-[25px]" />
                        <p className="mx-2">
                          Berlaku Hingga : {toDate(voucher.berlaku_hingga)}
                        </p>
                        <p
                          className="text-[#007AF1]"
                          onClick={() =>
                            setIsDetailVoucherOpen({
                              ...isDetailVoucherOpen,
                              state: true,
                              voucherId: voucher.id,
                            })
                          }
                        >
                          Lihat Detail
                        </p>
                      </div>
                    </div>
                  </div>
                  {selectedPromo?.voucherId.includes(voucher.id) ? (
                    <AiOutlineCheck className="text-[#007AF1] text-[25px]" />
                  ) : null}
                </div>
              ))}
              <p className="font-bold">Promo yang belum bisa dipakai</p>
              {voucher.response_voucher_cannot_use.map((voucher, index) => (
                <div
                  className="w-full border border-black rounded-md p-2 font-semibold my-3 bg-[#E5E6ED]"
                  key={index}
                >
                  <div className="flex items-center">
                    {/* <div className="flex w-[10%] mr-3 justify-center">
                      <img src={voucher.image} />
                    </div> */}
                    <div>
                      <p>{voucher.voucher_name}</p>
                      <p className="mt-2">{voucher.short_description}</p>
                      <div className="flex my-2">
                        <p>Kode Voucher:&nbsp;</p>
                        <p className="text-[#007AF1]">{voucher.voucher_code}</p>
                      </div>
                      <div className="flex items-center w-full">
                        <BiTimeFive className="text-[25px]" />
                        <p className="mx-2">
                          Berlaku Hingga : {toDate(voucher.berlaku_hingga)}
                        </p>
                        <p
                          className="text-[#007AF1]"
                          onClick={() =>
                            setIsDetailVoucherOpen({
                              ...isDetailVoucherOpen,
                              state: true,
                              voucherId: voucher.id,
                            })
                          }
                        >
                          Lihat Detail
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default VoucherSubscription;
