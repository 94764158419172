import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteCustomer } from "../../../services/customerApi";

const ModalDeleteCustomer = (props) => {
  const { showDeleteModal, setShowDeleteModal, image, name, updateValue } =
    props;

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: deleteCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"]);
      setShowDeleteModal(false);
    },
  });

  return (
    <>
      {showDeleteModal ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-row justify-between p-6">
                  <span>
                    <span className="animate-ping absolute"></span>
                    <img src={image} alt="" className=" w-16 animate-pulse" />
                  </span>
                  <p
                    className="text-gray-500 text-[20px] cursor-pointer"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    X
                  </p>
                </div>
                <div className="px-10 pb-4">
                  <p className="font-bold text-[20px]">
                    Hapus {updateValue !== undefined ? updateValue.name : ""}
                  </p>
                  <p className="text-start font-medium text-[20px]">{name}</p>
                </div>
                <div className="flex items-center justify-evenly p-3 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-white text-black border active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-[#D92D20] text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => mutate(updateValue.id)}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
};

export default ModalDeleteCustomer;
