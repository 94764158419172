import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getCongsignmentList = async (
  invoice,
  limit,
  offset,
  supplier,
  product
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/products?limit=${limit}&invoice=${invoice}&supplier_name=${supplier}&product_name=${product}&offset=${offset}`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getCongsignmentDetail = async (invoice, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/detail?product_id=${id}&invoice=${invoice}`,
      // url: `${process.env.REACT_APP_API_PRODUCT}consignment/detail?nie=MD 466628531007&product_id=38&invoice=NUV-613445`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getConsignmentDetailBatch = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/get-batch?product_id=${id}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewConsignment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/product/create-new`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};

export const returnConsignment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/return-stock`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};

export const exchangeConsignment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/exchange`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};

export const paymentConsignment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/payment`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};

export const getConsignmentDetailProduct = async (id, invoice) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/detail/consignment?id=${id}&invoice=${invoice}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setUpdateBatch = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/update-batch`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};


export const setUpdateProductConsignment = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}consignment/update`,
      data: body,
    };
    await axios(config);
  } catch (err) {
    throw err.response.data;
  }
};
