const PriceContainer = (props) => {
  const { title, titleArr, dataArr } = props;

  return (
    <div className="my-4    ">
      {titleArr.length > 0 ? (
        <div className="bg-green-100 p-2 text-center w-full font-bold">
          {title}
        </div>
      ) : null}
      <div className="bg-slate-100 text-center text-xl">
        <div className="flex justify-center flex-wrap">
          {titleArr.map((title, i) => {
            return (
              <div className="flex w-2/3 justify-between">
                <p className="w-[40%] text-left">{title}</p>
                <p>:</p>
                <p className="w-[40%] text-left">
                  {dataArr[i]?.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PriceContainer;
