import { Button, Divider, Snackbar, CircularProgress } from "@mui/material";
import { AccessTimeFilled as TimeIcon, ContentCopy } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import moment from "moment";

import { getBillingList } from "../../../services/billingAPI";
import BillingBreadcrumb from "./BillingBreadcrumb";

const WaitingBilling = () => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, isFetching } = useQuery({
    queryFn: () => getBillingList(2, 10, 1),
    queryKey: ["billing-paid"],
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <BillingBreadcrumb />
      <div
        className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 max-h-full"
        tabIndex="-1"
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasBottomLabel"
          >
            Offcanvas bottom
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto small">
          ...
        </div>
      </div>

      <>
        {data && data.detail !== null && data.detail.bills !== null ? (
          data.detail.bills.map((item) => {
            return (
              <div
                className="mt-3 flex items-center shadow-md rounded-xl p-3 border-2 justify-between w-full cursor-pointer"
                //   key={item.product_id}
              >
                <div className="flex items-center w-full">
                  <div className="w-full">
                    <div
                      className="flex items-center py-2 hover:bg-slate-100"
                      onClick={() => {
                        navigate(
                          `/billing/waiting-payment/detail/${item.bills_id[0]}`
                        );
                      }}
                    >
                      <div className="w-full">
                        <p className="text-gray-400 text-sm">Total tagihan</p>
                        <p className="font-bold">
                          {item.amount.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </p>
                      </div>
                      <div className="block md:flex items-center w-full justify-between md:justify-end ">
                        <p>Bayar sebelum</p>
                        <div className="border-amber-500 border-2 text-amber-500 bg-[#FFFAF7] md:ml-2 p-2 rounded-md flex items-center">
                          <TimeIcon className="text-amber-500 mr-2" />
                          {moment(item.payment.due_date).format(
                            "DD MMM, HH:MM"
                          )}
                        </div>
                      </div>
                    </div>
                    <Divider
                      sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        borderWidth: 1,
                      }}
                    />
                    <div className="flex w-full items-center justify-end">
                      <div className="basis-3/4 gap-2 items-center hidden md:flex">
                        <div className="w-[10%] lg:w-[8%]">
                          <img
                            src={item.payment.detail.logo}
                            className="w-full object-contain"
                          />
                        </div>
                        <div className="w-[40%]">
                          <p className="text-gray-400">Metode Pembayaran</p>
                          <p className="font-bold">
                            {item.payment.detail.pg_bank}
                          </p>
                        </div>
                        <div className="w-[40%]">
                          <p className="text-gray-400">Nomor Virtual Account</p>
                          <p className="font-bold" id="vaNum">
                            {item.payment.va}
                          </p>
                        </div>
                        <div className="w-fit">
                          <ContentCopy
                            className="cursor-pointer hover:text-gray-300"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                document.getElementById("vaNum").innerHTML
                              );
                              setIsCopied(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="basis-1/2 md:basis-1/4 float-right">
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() =>
                            navigate(
                              `/billing/waiting-payment/payment-summary/${btoa(
                                item.payment.va
                              )}`
                            )
                          }
                        >
                          Lihat Detail
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : isFetching ? (
          <CircularProgress />
        ) : errorMessage !== "" ? (
          <p className="text-red-500 py-4">{errorMessage}</p>
        ) : (
          <p className="text-red-500 py-4">Tidak ada data ditemukan</p>
        )}
      </>

      <Snackbar
        open={isCopied}
        onClose={() => setIsCopied(false)}
        message={"Virtual Account Number Copied"}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default WaitingBilling;
