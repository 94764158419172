import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  getGoodDoctorCredential,
  goodDoctorLogin,
  goodDoctorSync,
} from "../../../services/syncAccountApi";

const GoodDoctor = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [barMessage, setBarMessage] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const isValid = () => {
    return email !== "" && password !== "";
  };

  const {
    data: credential,
    isFetching: isFetchingCredential,
    refetch: refetchCredential,
  } = useQuery({
    queryKey: ["gd-cred"],
    queryFn: () => getGoodDoctorCredential(),
  });

  const {
    data: dataSync,
    isFetching: isFetchingSync,
    refetch: refetchSync,
  } = useQuery({
    queryKey: ["gd-sync"],
    queryFn: () => goodDoctorSync(),
    enabled: false,
  });

  const { mutate: mutateLogin, isLoading } = useMutation({
    mutationFn: goodDoctorLogin,
    onSuccess: (data) => {
      setBarMessage(
        data.status ? "Integrasi berhasil" : "User tidak ditemukan"
      );
      setEmail("");
      setPassword("");
      refetchCredential();
    },
    onError: (err) => {
      setBarMessage(err.message.id ? err.message.id : err.message);
    },
  });

  useEffect(() => {
    if (!isFetchingSync && dataSync && credential && credential.status) {
      setBarMessage(
        dataSync.status ? "Sinkronisasi berhasil" : "Sinkronisasi Gagal"
      );
    }
  }, [isFetchingSync, dataSync]);

  return (
    <>
      <div className="gap-4 grid grid-cols-1 md:grid-cols-2">
        <div className="w-full pt-8">
          {credential && !isFetchingCredential && !credential.status ? (
            <p className="mb-2 bg-blue-100 p-2 rounded-md mb-4 font-bold">
              Silahkan masukan credential untuk sinkronisasi stok anda
            </p>
          ) : null}
          <div className="border-2 rounded-lg p-4 mb-3">
            {credential && !isFetchingCredential ? (
              credential.status ? (
                <>
                  <p className="text-lg font-bold mb-4">
                    Integrasi Akun Good Doctor
                  </p>
                  <p className="mb-4">
                    Akun telah berhasil terintegrasi, silahkan mensinkronisasi
                    stok
                  </p>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-green-500 hover:bg-gray-300 text-white py-2 px-12 rounded-md font-bold disabled:bg-gray-300"
                      onClick={() => refetchSync()}
                      disabled={isFetchingSync}
                    >
                      {isFetchingSync ? <CircularProgress size={20} /> : "Sync"}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-lg font-bold mb-4">
                    Integrasi Akun Good Doctor
                  </p>
                  <TextField
                    fullWidth
                    name="email"
                    label="E-mail"
                    placeholder="example@mail.com"
                    autoComplete="false"
                    sx={{ marginBottom: 1 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    placeholder="Password"
                    autoComplete="false"
                    type={passwordVisibility ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() =>
                            setPasswordVisibility(!passwordVisibility)
                          }
                        >
                          {passwordVisibility ? <FaEyeSlash /> : <FaEye />}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginBottom: 2 }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-gray-300 text-white py-2 px-4 rounded-md font-bold disabled:bg-gray-300"
                      disabled={!isValid() || isLoading}
                      onClick={() =>
                        mutateLogin({ email: email, password: password })
                      }
                    >
                      {isLoading ? <CircularProgress size={20} /> : "Integrasi"}
                    </button>
                  </div>
                </>
              )
            ) : isFetchingCredential ? (
              <div className="flex items-center gap-4">
                <CircularProgress size={20} />
                <p>Data sedang diproses</p>
              </div>
            ) : null}
          </div>
          <p className="italic text-xs px-2 text-right text-blue-500">
            Powered by OLIN{" "}
          </p>
        </div>
      </div>

      <Snackbar
        open={Boolean(barMessage)}
        autoHideDuration={5000}
        onClose={() => setBarMessage("")}
        message={barMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setBarMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default GoodDoctor;
