import React, { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { CheckBox } from "@mui/icons-material";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import { ImProfile } from "react-icons/im";

import { getUserProfile } from "../../../services/authApi";
import AccountProfileNavigation from "./AccountProfileNavigation";
import { useNavigate } from "react-router-dom";
import { VerificationMethodContext } from "../../context/VerificationMethodProvider";

const AccountProfile = () => {
  const navigate = useNavigate();
  const {
    setIsVerificaitonMethodEmail,
    setIsVerificaitonMethodPassword,
    setIsVerificationMethodPhone,
    isVerificationMethodEmail,
    isVerificationMethodPassword,
    isVerificationMethodPhone,
  } = useContext(VerificationMethodContext);

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserProfile(),
  });

  useEffect(() => {
    sessionStorage.clear();
    setIsVerificaitonMethodEmail(false);
    setIsVerificaitonMethodPassword(false);
    setIsVerificationMethodPhone(false);
  }, []);

  return (
    <div className="w-full">
      <AccountProfileNavigation />
      {user && user !== undefined ? (
        <>
          <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
            <div className="flex flex-col">
              <p className="font-semibold">Nama Lengkap</p>
              <p>{user.full_name}</p>
            </div>
          </div>

          <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
            <div className="flex flex-col">
              <p className="font-semibold">Email</p>
              <p>{user.email}</p>
            </div>
            <p
              className="text-blue-500 hover:cursor-pointer"
              onClick={() => {
                navigate("/profile/verification");
                setIsVerificaitonMethodEmail(true);
                sessionStorage.setItem("email", user.email);
              }}
            >
              Ubah
            </p>
          </div>

          <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
            <div className="flex flex-col">
              <p className="font-semibold">Kata Sandi</p>
            </div>
            <p
              className="text-blue-500 hover:cursor-pointer"
              onClick={() => {
                navigate("/profile/verification");
                setIsVerificaitonMethodPassword(true);
                sessionStorage.setItem("email", user.email);
              }}
            >
              Ubah
            </p>
          </div>

          <div className="flex flex-row items-center rounded-lg shadow-md  p-3 justify-between">
            <div className="flex flex-col ">
              <p className="font-semibold">No HP</p>
              <p>{user.phone}</p>
            </div>
            <p
              className="text-blue-500 hover:cursor-pointer"
              onClick={() => {
                navigate("/profile/verification");
                setIsVerificationMethodPhone(true);
                sessionStorage.setItem("email", user.email);
              }}
            >
              Ubah
            </p>
          </div>
          <p className="text-[24px] font-bold my-3">Notifikasi Pesanan</p>
          <div className="flex font-semibold">
            <div className="flex mr-[100px] ">
              <CheckBox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, marginRight: 1 }}
              />
              <p>Whatsapp</p>
            </div>
            <div className="flex">
              <CheckBox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, marginRight: 1 }}
              />
              <p>Email</p>
            </div>
          </div>
          <p className="text-[24px] font-bold my-3">
            Informasi Pribadi Lainnya
          </p>
          <div
            className="flex flex-row items-center rounded-lg shadow-md p-3 justify-between font-bold bg-[#FEF8E2] mb-5 mt-3 hover:cursor-pointer"
            onClick={() => {
              navigate("/profile/detail");
            }}
          >
            <div className="flex items-center">
              <ImProfile className="text-[30px]" />
              <div className="flex flex-col ml-3">Detail Informasi</div>
            </div>
            <ArrowForwardIosIcon />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AccountProfile;
