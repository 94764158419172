const UserStatus = (props) => {
  const { userDetail } = props;
  return (
    <>
      {userDetail.status_code_user === 1 && (
        <div className="bg-[#007AF1] p-2 text-white rounded-md w-[25%] text-center">
          <p> {userDetail.status_user}</p>
        </div>
      )}
      {userDetail.status_code_user === 2 && (
        <div className="bg-[#F21B3F] p-2 text-white rounded-md w-[25%] text-center">
          <p> {userDetail.status_user}</p>
        </div>
      )}
      {userDetail.status_code_user === 3 && (
        <div className="bg-[#7D8A9C] p-2 text-white rounded-md w-[25%] text-center">
          <p> {userDetail.status_user}</p>
        </div>
      )}
      {userDetail.status_code_user === 4 && (
        <div className="bg-[#252525] p-2 text-white rounded-md w-[25%] text-center">
          <p> {userDetail.status_user}</p>
        </div>
      )}

      {userDetail.status_code_user === 5 && (
        <div className="bg-[#008887] p-2 text-white rounded-md w-[25%] text-center">
          <p> {userDetail.status_user}</p>
        </div>
      )}
    </>
  );
};

export default UserStatus;
