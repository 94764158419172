import { Box, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { setPatientCareServices } from "../../../../services/patientCare";
import { useFormik } from "formik";
import AddService from "./AddService";
import ModalAddEditCategory from "./ModalAddEditCategory";
import ModalDeleteCategory from "./ModalDeleteCategory";
import ModalAddDoctor from "./ModalAddDoctor";
import * as yup from "yup";
import ModalAddEditSpecialization from "./ModalAddEditSpecialization";
import ModalDeleteSpecialization from "./ModalDeleteSpecialization";

const validationSchema = yup.object({
  name: yup.string().required(),
  category_id: 0,
  doctor_id: 0,
  fee: yup.number().min(1),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
const ModalAddServices = ({ open, setOpen, refetch }) => {
  const [modalView, setModalView] = useState("add"); // receives string: add, category (for add category), doctor (for add doctor)
  const [isEdit, setIsEdit] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [editSpec, setEditSpec] = useState(null);
  const [openDeleteCat, setOpenDeleteCat] = useState(false);
  const [openDeleteSpec, setOpenDeleteSpec] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: mutateAddService, isLoading: isLoadingAdd } = useMutation({
    mutationFn: setPatientCareServices,
    onSuccess: () => {
      refetch();
      setOpen(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      category_id: 0,
      doctor_id: 0,
      fee: 0,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      mutateAddService(values);
    },
  });

  useEffect(() => {
    if (open) {
      setModalView("add");
      formik.resetForm();
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "80%",
            overflowY: "auto",
            overflowX: "wrap",
            minHeight: "80vh",
            maxHeight: "90vh",
            padding: 0,
          }}
          className="text-left text-base"
        >
          {modalView === "add" ? (
            <AddService
              formik={formik}
              setOpen={setOpen}
              setView={setModalView}
              isLoadingAdd={isLoadingAdd}
              setIsEdit={setIsEdit}
              setEditCategory={setEditCategory}
              setOpenDeleteCat={setOpenDeleteCat}
            />
          ) : modalView === "category" ? (
            <ModalAddEditCategory
              isEdit={isEdit}
              view={modalView}
              setView={setModalView}
              setOpen={setOpen}
              editCategory={editCategory}
              setEditCategory={setEditCategory}
            />
          ) : modalView === "doctor" ? (
            <ModalAddDoctor
              view={modalView}
              setView={setModalView}
              setOpen={setOpen}
              setIsEdit={setIsEdit}
              setEditSpec={setEditSpec}
              setOpenDeleteSpec={setOpenDeleteSpec}
            />
          ) : modalView === "specialization" ? (
            <ModalAddEditSpecialization
              isEdit={isEdit}
              view={modalView}
              setView={setModalView}
              setOpen={setOpen}
              editSpec={editSpec}
              setEditSpec={setEditSpec}
            />
          ) : null}
        </Box>
      </Modal>

      <ModalDeleteCategory
        open={openDeleteCat}
        setOpen={setOpenDeleteCat}
        cat={editCategory}
      />

      <ModalDeleteSpecialization
        open={openDeleteSpec}
        setOpen={setOpenDeleteSpec}
        spec={editSpec}
      />
    </>
  );
};

export default ModalAddServices;
