import React from "react";
import HOC from "../../components/HOC";
import Header from "../../components/ui/Header";
import SidebarComponents from "../../components/ui/SidebarComponents";
import DetailInvoiceReportSales from "../../components/screen/Reporting/allReport/DetailInvoiceReportSales";

const DetailInvoiceReportSalesPage = () => {
  return (
    <div className="">
      <div className="flex">
        <div className="w-50% h-screen sticky top-0 z-30">
          <SidebarComponents />
        </div>
        <div className="w-[100%] p-3">
          <Header />
          <DetailInvoiceReportSales />
        </div>
      </div>
    </div>
  );
};

export default HOC(DetailInvoiceReportSalesPage);
