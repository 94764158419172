import { useEffect } from "react";
import LogoOlin from "../../../assets/icons/logoOlin.png";

const PrintNota = (props) => {
  const {
    type,
    data,
    dataList,
    printRef,
    dataLabel,
    title,
    tableContent,
    totalPrice,
    NotaInfo,
    email,
    sia,
  } = props;

  return (
    <div ref={printRef} className="m-5 text-[12px]">
      {dataLabel && dataLabel !== undefined ? (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <div className="w-[3cm]">
                <img
                  src={
                    dataLabel.logo && dataLabel.logo !== ""
                      ? dataLabel.logo
                      : LogoOlin
                  }
                  className="w-full  object-cover"
                />
              </div>
              <div className="w-full">
                <p>
                  {dataLabel.name_outlet}{" "}
                  {type && type === "warehouse" ? "(Gudang)" : ""}
                </p>
                <p>No. Surat Izin Apotek: {dataLabel.sia}</p>
                <p>
                  {dataLabel.address}, {dataLabel.city}, {dataLabel.state}{" "}
                  {dataLabel.zipcode}
                </p>
                <p>
                  Telp: {dataLabel.phone}. Email: {email}
                </p>
              </div>
            </div>
            <p className="text-[20px] font-bold">{title}</p>
          </div>
          <div className="w-full border-[1px] border-black my-4"></div>
        </>
      ) : null}
      {data ? (
        <div>
          <NotaInfo />
          <div>
            {dataList && dataList !== undefined ? (
              <div className="rounded-md w-full">
                <table className="w-full border-separate border-spacing-0">
                  <thead>
                    <tr className="text-center bg-blue-500 text-white">
                      {tableContent.map((content, i) => (
                        <th
                          className={`p-2 ${
                            i === 0
                              ? "rounded-tl-md"
                              : i === tableContent.length - 1
                              ? "rounded-tr-md"
                              : ""
                          }`}
                        >
                          {content.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {dataList.map((data, index) => (
                    <tbody key={index}>
                      <tr className="text-center">
                        {tableContent.map((content, i) => (
                          <td
                            className={`p-2 border ${
                              index === dataList.length - 1
                                ? i === 0
                                  ? "rounded-bl-md"
                                  : i === tableContent.length - 1
                                  ? "rounded-br-md"
                                  : ""
                                : ""
                            }`}
                          >
                            {content.key === "INDEX"
                              ? index + 1
                              : content.key === "QTYRETUR"
                              ? data.quantity + " " + data.type_packaging
                              : content.key === "QTYPO"
                              ? data.product_quantity + " " + data.product_unit
                              : content.type && content.type === "currency"
                              ? data[content.key]?.toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })
                              : data[content.key]}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            ) : null}
            <div className="flex justify-between items-start my-4">
              <div>
                <p className="font-bold">Catatan:</p>
                <p className="min-h-[100px]"></p>
              </div>
              {totalPrice ? (
                <div>
                  <p>
                    Harga Total:{" "}
                    {totalPrice.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </p>
                </div>
              ) : null}
            </div>

            <div className="flex justify-between gap-[30%]">
              {type ? (
                type === "warehouse" ? (
                  <div className="w-full border-b border-black text-center pb-2">
                    <p>Disiapkan oleh:</p>
                    <p className="mb-[3cm]">{dataLabel.name_outlet} (Gudang)</p>
                    <p>{data.distributor_name}</p>
                  </div>
                ) : null
              ) : (
                <div className="w-full border-b border-black text-center pb-2">
                  <p className="mb-[3cm]">Distributor</p>
                  <p>{data.distributor_name}</p>
                </div>
              )}
              <div className="w-full border-b border-black text-center pb-2">
                <p className="mb-[3cm]">
                  {type && type === "warehouse"
                    ? "Yang menerima"
                    : dataLabel.name_outlet}
                </p>
                <p>
                  {type && type === "warehouse"
                    ? data?.header.target
                    : dataLabel.nama_apoteker}
                </p>
              </div>
            </div>
            <div className="flex justify-end text-center w-full mt-2">
              <p className="w-[35%]">
                {type && type === "warehouse"
                  ? "No SIA:" + sia
                  : "No. SIPA:" + dataLabel.sipa}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PrintNota;
